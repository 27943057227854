import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classes from '../new-project.module.scss';

import Pager from '../components/pager';

import Feature from '../components/feature';

export default function SelectStep({ back, onNext, projectName, featuresUpdate, data }) {
	const [featuresSelected, setFeaturesSelected] = useState(data.features || []);

	const [validationSelected, setValidationSelected] = useState(featuresSelected.includes('validation'));
	const [interestSelected, setInterestSelected] = useState(featuresSelected.includes('interest'));
	const [investorsSelected, setInvestorsSelected] = useState(featuresSelected.includes('investors'));
	const [prototypeSelected, setPrototypeSelected] = useState(featuresSelected.includes('prototype'));

	const [blinkingValidation, setBlinkingValidation] = useState(false);

	const [exposure, setExposure] = useState([]);

	useEffect(async () => {
		const selection = [];
		const exposureGroups = [];

		if (validationSelected) {
			selection.push('validation');
			exposureGroups.push('hardware experts');
		}

		if (interestSelected) {
			selection.push('interest');
			exposureGroups.push('community');
		}

		if (investorsSelected) {
			selection.push('investors');
			exposureGroups.push('investors/partners');
		}

		let validationBlinking = false;
		if (prototypeSelected) {
			selection.push('prototype');
			if (!validationSelected) {
				validationBlinking = true;
				setValidationSelected(true);
			}
		}

		setFeaturesSelected(selection);
		setExposure(exposureGroups);

		if (featuresUpdate) featuresUpdate(selection);

		if (validationBlinking) {
			setBlinkingValidation(true);
			await new Promise(r => setTimeout(r, 600));
			setBlinkingValidation(false);
		}
	}, [validationSelected, interestSelected, investorsSelected, prototypeSelected]);

	function joinArray(array = []) {
		if (!array || !array.length) return array;

		let string = '';

		array.forEach((item, index) => {
			string = string + (index + 1 !== array.length || array.length <= 1 ? '' : 'and ') + item + (index + 1 !== array.length ? ', ': '');
		});

		return string;
	}

	return (
		<>
			<Pager
				steps={[
					{ name: 'Offering', active: true },
					{ name: 'Details', active: true },
					{ name: 'Setup', active: true },
				]}
			/>

			<div className={classes.successMessage}>Your project was submitted succesfully</div>

			<h2 className={classes.title}>What do you want to do <span className={classes.yellowUnderline}>next</span>?</h2>
			<div className={classes.description}>You can edit this selection at any time</div>

			<div className={classes.selectBox}>
				<Feature
					name={'validation'}
					css={{ backgroundSize: '50px', marginTop: '-2px' }}
					selectable={true}
					border={true}
					selected={validationSelected}
					onClick={() => setValidationSelected(!validationSelected)}
					blinking={blinkingValidation}
				/>

				<Feature
					name={'interest'}
					selectable={true}
					border={true}
					selected={interestSelected}
					onClick={() => setInterestSelected(!interestSelected)}
				/>

				<Feature
					name={'investors'}
					selectable={true}
					border={true}
					selected={investorsSelected}
					onClick={() => setInvestorsSelected(!investorsSelected)}
				/>

				<Feature
					name={'prototype'}
					selectable={true}
					border={true}
					selected={prototypeSelected}
					onClick={() => setPrototypeSelected(!prototypeSelected)}
				/>


				{ exposure.length > 0 && 
					<div className={classes.exposure}>
						{projectName ? `"${projectName}"` : 'Your idea'} will be presented to LastBasic&apos;s {joinArray(exposure)}.&nbsp;
						{exposure.length === 1 ? 'This group has ' : 'All these groups have '}
						signed a Non-Disclosure Agreement. Therefore, your idea will always be protected.
					</div>
				}
			</div>

			<div className={classes.buttonWrapper}>
				<div className={classes.button + ' ' + classes.buttonGrayBack} style={{ display: 'none' }} onClick={back}>Back</div>
				{ featuresSelected.length > 0 && <div className={classes.button} onClick={onNext}>Continue</div> }
				{ featuresSelected.length === 0 && <div className={classes.button + ' ' + classes.buttonGray}>Continue</div> }
			</div>
		</>
	);
}

SelectStep.propTypes = {
	back: PropTypes.func,
	onNext: PropTypes.func,
	projectName: PropTypes.string,
	featuresUpdate: PropTypes.func,
	data: PropTypes.object,
};
