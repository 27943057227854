import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ContestCardImage from '../../components/project/contest-card-image';
import { serverAPI } from '../../utils';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import classes from './image-swiper.module.scss';

class ImageSwiper extends Component {
	render() {
		const { autoplay, mainImage, secondaryImages, urlLinks } = this.props;
		const mainImageUrl = mainImage ? serverAPI.getPreviewUrlByFile(mainImage) : null;

		return (
			<Swiper
				autoplay={autoplay}
				loop={true}
				modules={[Pagination, Navigation]}
				navigation={secondaryImages.length > 0}
				pagination={{ clickable: true }}
				slidesPerView={1}
				spaceBetween={30}
			>
				{mainImageUrl &&
					<SwiperSlide>
						<div className={classes.projectCard}>
							<ContestCardImage
								className={classes.projectCardImg}
								src={mainImageUrl}
							/>
						</div>
					</SwiperSlide>
				}
				{secondaryImages && secondaryImages.map((img, i) => (
					<SwiperSlide key={i}>
						<div className={classes.projectCard}>
							<ContestCardImage
								className={classes.projectCardImg}
								src={serverAPI.getPreviewUrlByFile(img)}
								type={'secondary'}
							/>
						</div>
					</SwiperSlide>
				))}
				{urlLinks && urlLinks.map((url, i) => (
					<SwiperSlide key={i}>
						<div className={classes.projectCard}>
							<ContestCardImage
								className={classes.projectCardImgSmall}
								src={url}
								type={'secondary'}
							/>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		);
	}
}

ImageSwiper.propTypes = {
	autoplay: PropTypes.bool,
	mainImage: PropTypes.object,
	secondaryImages: PropTypes.array,
	urlLinks: PropTypes.array,
};

ImageSwiper.defaultProps = {
	autoplay: false,
	mainImages: null,
	secondaryImages: [],
	urlLinks: [],
};

export default ImageSwiper;
