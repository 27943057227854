import { Mutation } from '@apollo/client/react/components';
import {
	Toggle,
	InlineNotification,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbSkeleton,
	SkeletonText,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import { GET_MY_PROFILE_INFO } from '../../../providers/queries';
import {
	ENABLE_NOTIFICATIONS,
	ENABLE_NOTIFICATIONS_SUBSCRIPTION,
	ENABLE_EMAIL_NOTIFICATIONS
} from '../../../providers/mutations';
import classes from '../../experts/internal-pages/payments/payments.module.scss';
import { pushNotifications } from '../../../utils';

class Notifications extends Component {
	constructor(props) {
		super(props);

		const notifications = pushNotifications.getNotificationsStates();

		this.refetchProfileInfo = () => {};
		this.state = {
			emailNotificationsEnabled: notifications.email,
			pushNotificationsEnabled: notifications.push,
		};
	}

	componentDidMount() {
		document.title = 'Notification settings - LastBasic';
	}

	async askPermission() {
		return new Promise(function(resolve, reject) {
			const permissionResult = Notification.requestPermission(function(result) {
				console.log('Push notifications permission ' + result);
				resolve(result);
			});

			if (permissionResult) {
				permissionResult.then(resolve, reject);
			}
		}).then(function(permissionResult) {
			if (permissionResult !== 'granted') {
				//throw new Error('We weren\'t granted permission.');
			}
		});
	}

	onEnableNotifications = async (enablePushNotifications, pushNotificationsSubscription) => {
		const pushNotificationsEnabled = !this.state.pushNotificationsEnabled;

		this.setState({ pushNotificationsEnabled });
		this.askPermission();

		const newNotifications = {
			email: this.state.emailNotificationsEnabled,
			push: pushNotificationsEnabled,
		};
		pushNotifications.storeNotificationsStates(newNotifications);

		let error;

		try {
			const result = await enablePushNotifications({
				variables: {
					enable: pushNotificationsEnabled,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error enabling push notifications');
		} else {
			if (!pushNotificationsEnabled) return;

			const subscriptionData = pushNotifications.getSubscriptionData();

			try {
				const result = await pushNotificationsSubscription({
					variables: {
						subscription: subscriptionData,
					}
				});
				if (!result) error = new Error('Something went wrong');
			} catch (e) {
				error = e;
			}

			console.log('Success enabling push notifications');
		}
	};

	onEnableEmailNotifications = async (enableEmailNotifications) => {
		const emailNotificationsEnabled = !this.state.emailNotificationsEnabled;

		this.setState({ emailNotificationsEnabled });

		const newNotifications = {
			email: emailNotificationsEnabled,
			push: this.state.pushNotificationsEnabled,
		};
		pushNotifications.storeNotificationsStates(newNotifications);

		try {
			await enableEmailNotifications({
				variables: {
					enable: emailNotificationsEnabled,
				}
			});
		} catch {
			// Ignore, show error inline
		}
	};

	onSelectContestOptions(e) {
		const selectedContests = e.selectedItems;

		window.Froged('set', { general_contests_subscriptions: selectedContests });
	}

	render() {
		const { emailNotificationsEnabled, pushNotificationsEnabled } = this.state;

		return (
			<ErrorBoundaryQuery query={GET_MY_PROFILE_INFO}>
				{({ loading, error, data, refetch: refetchProfileInfo }) => {
					this.refetchProfileInfo = refetchProfileInfo;

					if (loading) {
						return (
							<div className={classes.profileContainer}>
								<BreadcrumbSkeleton className={classes.breadcrumbBox}/>
								<SkeletonText style={{ width: '100px'}} />
								<div className={classes.section}>
									<SkeletonText style={{ width: '300px', margin: '16px 0'}} />
									<SkeletonText style={{ width: '300px', margin: '16px 0'}} />
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}
					// eslint-disable-next-line no-unused-vars
					const profile = (data && data.myProfileInfo ) || [];

					return (
						<div className={classes.profileContainer}>
							<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
								<BreadcrumbItem>
									<Link to="/account/settings">Account</Link>
								</BreadcrumbItem>
								<BreadcrumbItem isCurrentPage href="#">
									Notifications
								</BreadcrumbItem>
							</Breadcrumb>

							<div>
								<h2 className={classes.title}>Notification settings</h2>
								<div className={classes.section}>
									<div className={classes.sectionContent}>
										<h4 className={classes.secondaryTitle}>Email notifications</h4>
									</div>
									<div className={classes.sectionContent}>
										<p>Get email notifications from platform events</p>
										<Mutation mutation={ENABLE_EMAIL_NOTIFICATIONS}>
											{(enableEmailNotifications, { error }) => (
												<>
													{error && (
														<InlineNotification
															className={classes.notification}
															kind="error"
															title="Error enabling email notifications"
															subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																? error.graphQLErrors[0].message
																: (error.networkError)
																	? error.networkError.message
																	: error.message}
															lowContrast={true}
														/>
													)}
													<Toggle
														id="email-notifications-toggle"
														size="sm"
														className={classes.toggle}
														defaultToggled={emailNotificationsEnabled}
														aria-label="Enable email notifications"
														onToggle={() => this.onEnableEmailNotifications(enableEmailNotifications)}
													/>
												</>
											)}
										</Mutation>
									</div>
								</div>
								<div className={classes.section}>
									<div className={classes.sectionContent}>
										<h4 className={classes.secondaryTitle}>Browser notifications</h4>
									</div>
									<div className={classes.sectionContent}>
										<p>Get push notifications from your browser</p>
										<Mutation mutation={ENABLE_NOTIFICATIONS}>
											{(enablePushNotifications, { error }) => (
												<>
													{error && (
														<InlineNotification
															className={classes.notification}
															kind="error"
															title="Error enabling push notifications"
															subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																? error.graphQLErrors[0].message
																: (error.networkError)
																	? error.networkError.message
																	: error.message}
															lowContrast={true}
														/>
													)}
													<Mutation mutation={ENABLE_NOTIFICATIONS_SUBSCRIPTION}>
														{(pushNotificationsSubscription) => (
															<Toggle
																id="push-notifications-toggle"
																size="sm"
																className={classes.toggle}
																defaultToggled={pushNotificationsEnabled}
																disabled={true}
																aria-label="Enable push notifications"
																onToggle={() => this.onEnableNotifications(enablePushNotifications, pushNotificationsSubscription)}
															/>
														)}
													</Mutation>
												</>
											)}
										</Mutation>
									</div>
								</div>
							</div>

						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

Notifications.defaultProps = {
	userData: {}
};

Notifications.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(Notifications);