import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import classes from '../new-project.module.scss';

import Feature from '../components/feature';

export default function ItemsStep({ data, back, apiResponse, redirectToProjectPage, redirectProjectReservationLink, activeButton }) {
	const [features] = useState(data.features || []);
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		const newItems = [];
		features.forEach(feature => {
			if (feature === 'validation') newItems.push({ name: 'Validation', price: 0 });
			if (feature === 'interest') newItems.push({ name: 'Interest', price: 0 });
			if (feature === 'investors') newItems.push({ name: 'Investors', price: 0 });
			if (feature === 'prototype') newItems.push({ name: 'Create a prototype', price: (apiResponse.estimatedPrice.price.amountInCents / 100) });
		});

		setItems(newItems);

		// Total
		let newTotal = 0;
		newItems.forEach(item => newTotal = newTotal + item.price);
		setTotal(newTotal);
	}, [features]);

	function formatPrice(amount = 0) {
		return amount.toLocaleString('en-US');
	}

	return (
		<>
			<h2 className={classes.title}>Your items</h2>
			<div className={classes.description}>You can get the free features now {total > 0 && <>and decide<br/>if you want to pay later</>}</div>

			<div className={classes.selectBox}>
				<Feature
					name={'validation'}
					css={{ marginTop: '-7px' }}
					border={true}
					display={features.includes('validation')}
					small={true}
				/>

				<Feature
					name={'interest'}
					border={true}
					display={features.includes('interest')}
					small={true}
				/>

				<Feature
					name={'investors'}
					border={true}
					display={features.includes('investors')}
					small={true}
				/>

				<Feature
					name={'prototype'}
					border={true}
					display={features.includes('prototype')}
					small={true}
				/>
			</div>

			<div className={classes.editSelectionWrapper}>
				<div className={classes.editSelection} onClick={back}>Add/remove features</div>
			</div>

			<div className={classes.separatorWrapper + ' ' + classes.separatorWrapperBig}>
				<div className={classes.separator}></div>
			</div>

			<div className={classes.table}>
				<table>
					{ items.map((item, k) => {
						return (
							<tr key={k}>
								<td>{item.name}</td>
								<td>{(item.price > 0 ? formatPrice(item.price) + '€' : 'Free')}</td>
							</tr>
						);
					})}
					<tr style={{ opacity: 1 }}>
						<td>Total</td>
						<td>{formatPrice(total)}€</td>
					</tr>
				</table>
			</div>

			<div className={classes.separatorWrapper + ' ' + classes.separatorWrapperBig}>
				<div className={classes.separator}></div>
			</div>
			
			{ total === 0 &&
				<div className={classes.paymentInfo}>
					You can get the free features now and decide later if you want to create a fully functional prototype {(data?.details?.projectName ? `for "${data?.details?.projectName}"` : '')} with us.
					<br/><br/>
					However, we suggest to <span className={classes.clickableBold} onClick={back}>add the Create a prototype feature</span> and make the down payment of just 99€ to secure your spot.
					<br/><br/>
					You will get more benefits and we will fully refund you if your project is not valid, or you decide to not continue with us.
				</div>
			}

			{ total > 0 &&
				<div className={classes.paymentInfo}>
					We know this is a <Tooltip title={'We are still about 5 to 10 times cheaper than traditional engineering consultancies'}><span className={classes.extendedInformation}>large amount</span></Tooltip>, but you don&apos;t need to pay it now.
					You can now get the free features and decide later if you want to pay to create a fully functional prototype with us.
					<br/><br/>
					However, we have limited spots each month to create prototypes in our platform. If you want to secure your spot you can make a down payment of just 99€.
					<br/><br/>
					Additionally, we will offer you a 5% discount (-{formatPrice(total * 0.05)}€). We will refund the down payment of 99€ if your project is not valid, or you decide to not continue with us.
				</div>
			}

			{ total === 0 &&
				<div className={classes.buttonWrapperLong}>
					<div className={classes.button + ' ' + classes.buttonGrayFree} onClick={back}>Add more items</div>
					<div className={classes.button} onClick={redirectToProjectPage}>Get free items</div>
				</div>
			}

			{ total > 0 &&
				<div className={classes.buttonWrapperLong}>
					<div className={classes.button + ' ' + classes.buttonGrayFree} onClick={redirectToProjectPage}>Get free items and decide later</div>
					{ activeButton && <div className={classes.button} onClick={redirectProjectReservationLink}>Make reservation (99€)</div> }
					{ !activeButton && <div className={classes.button} style={{ cursor: 'inherit', opacity: 0.8 }}>Redirecting...</div> }
				</div>
			}

			<div className={classes.buffer}></div>
		</>
	);
}

ItemsStep.propTypes = {
	back: PropTypes.func,
	data: PropTypes.object,
	apiResponse: PropTypes.object,
	redirectToProjectPage: PropTypes.func,
	redirectProjectReservationLink: PropTypes.func,
	activeButton: PropTypes.bool,
};
