import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import { CONTEST } from '../../../constants';
import { ADD_CONTEST_SET_UP_ANSWERS } from '../../../providers/mutations';
import SetupQuestions from '../../../components/project/setup-questions/setup-questions';

class ContestSetupQuestion extends Component {
	constructor (props) {
		super(props);

		const { contest } = props;
		this.questions = (contest && contest.setupQuestions) || [];
	}

	submitAnswer = (sendContestSetUpAnswersMutation, answers) => {
		const { contest } = this.props;
		return sendContestSetUpAnswersMutation({
			variables: {
				contestId: contest.id,
				answers,
			}
		});
	};

	render() {
		const { contest, onQuestionsAnswered, onRefresh } = this.props;
		const contestType = contest.type;

		return (
			<Mutation mutation={ADD_CONTEST_SET_UP_ANSWERS}>
				{(sendContestSetUpAnswersMutation) => {
					return (
						<SetupQuestions
							questions={this.questions}
							submitAnswers={(answers) => this.submitAnswer(sendContestSetUpAnswersMutation, answers)}
							onQuestionsAnswered={onQuestionsAnswered}
							onRefresh={onRefresh}
							noQuestionsMessage="There are no setup questions for this phase."
							allQuestionsAnsweredMessage="You already answered all questions for the current phase."
							completedMessage={
								(contestType === CONTEST.TYPES.SKETCH)
									?	'Awesome, you have finished the set up questions. Click the NEXT btn in order to proceed'
									:	'Awesome, you have finished the set up questions. Stay tuned to let us prepare you the ideal contest for you to review.'
							}
						/>
					);
				}}
			</Mutation>
		);
	}
}

ContestSetupQuestion.propTypes = {
	contest: PropTypes.object.isRequired,
	onQuestionsAnswered: PropTypes.func,
	onRefresh: PropTypes.func,
};

ContestSetupQuestion.defaultProps = {
	contest: {},
	onQuestionsAnswered() {},
	onRefresh() {},
};

export default ContestSetupQuestion;