const LocalStore = window.localStorage;

export const SUBSCRIPTION_DATA = 'SUBSCRIPTION_DATA';
export const NOTIFICATIONS = 'NOTIFICATIONS';

const getSubscriptionData = () => {
	try {
		let subsData = LocalStore.getItem(SUBSCRIPTION_DATA);
		if (subsData) return JSON.parse(subsData);
		
		return JSON.parse(subsData);
	} catch (error) {
		return null;
	}
};

const storeSubscriptionData = (subscription) => {
	try {
		LocalStore.setItem(SUBSCRIPTION_DATA, JSON.stringify(subscription));
		return true;
	} catch (error) {
		return false;
	}
};

const removeSubscriptionData = () => {
	try {
		LocalStore.removeItem(SUBSCRIPTION_DATA);
		return true;
	} catch (error) {
		return false;
	}
};

const getNotificationsStates = () => {
	try {
		let notifications = LocalStore.getItem(NOTIFICATIONS);
		if (notifications) return JSON.parse(notifications);

		return JSON.parse(notifications);
	} catch (error) {
		return null;
	}
};

const storeNotificationsStates = (notifications) => {
	try {
		LocalStore.setItem(NOTIFICATIONS, JSON.stringify(notifications));
		return true;
	} catch (error) {
		return false;
	}
};

const removeNotificationsStates = () => {
	try {
		LocalStore.removeItem(NOTIFICATIONS);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getSubscriptionData,
	storeSubscriptionData,
	removeSubscriptionData,
	getNotificationsStates,
	storeNotificationsStates,
	removeNotificationsStates,
};
