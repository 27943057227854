import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import {
	Accordion,
	AccordionItem,
	Button,
	InlineLoading,
	OverflowMenu,
	OverflowMenuItem,
} from 'carbon-components-react';
import { Download20, Document20, SettingsAdjust20 } from '@carbon/icons-react';

import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import PhaseStatesBubbles from '../../../../components/project/phase-states-bubbles';
import PhaseContestDetails from '../components/phase-contest-details';
import PhaseViewSetupQuestions from '../../../../components/project/setup-questions/phase-view-setup-questions';
import RawMaterialImage from '../../../../components/project/raw-material-image';
import withRouter from '../../../../components/route/with-router';

import { CONTEST, PROJECT } from '../../../../constants';
import { fileTools, serverAPI } from '../../../../utils';
import { REMOVE_RAW_MATERIAL } from '../../../../providers/mutations';
import KEYS from '../../../../glossary';

import classes from '../client-project-page.module.scss';

const TABS = {
	CONTEST_CARD: 'contest_set_up',
	PROPOSALS: 'proposals',
	WINNER: 'winner',
};

const DEFAULT_FILTER = {
	phase: null,
	state: TABS.CONTEST_CARD,
};


class ProjectBoard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedState: null,
			previewFile: null,
			newRawMaterialFiles: [],
			errorRemovingRawMaterial: null,
			removingRawMaterial: null,
			switchIcons: false,
			showProjectImage: false,
		};
	}

	async componentDidMount() {
		const savedFilter = this.getInitialFilter();
		const phases = PROJECT.getContestStates();

		if (Object.keys(savedFilter).includes('contest') && phases.includes(savedFilter.contest)) {
			this.setState({
				selectedState: savedFilter.contest
			});
		}
		this.setInitialSearchParams();
	}

	setInitialSearchParams() {
		const { project, navigate } = this.props;
		const phaseStatus = CONTEST.getContestPhaseState(project, project.state);
		const contestActiveTab = (phaseStatus === 'Completed' || phaseStatus === 'Winner selection') ? TABS.WINNER : phaseStatus === 'Open contest' ? TABS.PROPOSALS : TABS.CONTEST_CARD;
		const notPhaseState = (project.state === PROJECT.STATE.PROJECT_SET_UP || project.state === PROJECT.STATE.CLOSED) && contestActiveTab === TABS.CONTEST_CARD;

		return navigate({
			search: '?' + new URLSearchParams({phase: project.state, state: notPhaseState ? 'active' : contestActiveTab}).toString()
		});
	}

	getSearchFilter() {
		const { location } = this.props;
		const searchQuery = new URLSearchParams(location.search);

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			if (!searchQuery.get(p)) continue;
			savedFilter[p] = searchQuery.get(p);
		}

		if (savedFilter.contest && typeof savedFilter.contest !== 'string') {
			delete savedFilter.contest;
		}

		if (savedFilter.state && !TABS[savedFilter.state]) {
			delete savedFilter.state;
		}

		return savedFilter;
	}

	getInitialFilter() {
		const searchFilter = this.getSearchFilter();

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			savedFilter[p] = searchFilter[p] || DEFAULT_FILTER[p];
		}

		return savedFilter;
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}
	
	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onSelectPhase(state, project) {
		const { navigate } = this.props;

		this.setState({ selectedState: state });

		const phaseStatus = CONTEST.getContestPhaseState(project, state);
		const contestActiveTab = (phaseStatus === 'Completed' || phaseStatus === 'Winner selection') ? TABS.WINNER : phaseStatus === 'Open contest' ? TABS.PROPOSALS : TABS.CONTEST_CARD;

		return navigate({
			search: '?' + new URLSearchParams({phase: state, state: contestActiveTab}).toString()
		});
	}

	removeRawMaterialOption(rawMaterials) {
		const { project } = this.props;
		const { removingRawMaterial, errorRemovingRawMaterial } = this.state;

		return (
			<Mutation mutation={REMOVE_RAW_MATERIAL}>
				{(removeRawMaterialMutation) => {
					return (
						rawMaterials.map((material, i) => (
							<div className={classes.rawMaterialContainer} key={i}>
								<div className={classes.materialCard}>
									<RawMaterialImage
										src={material.url}
										className={classes.cardImg}
										alt={material.name}
										onClick={material.fileType === 'application/zip'
											?	() => this.onOpenPreviewFile(material)
											:	undefined
										}
									/>
									<div className={classes.cardName}>
										<h5 className={classes.name}>{material.name.substring(0, 20)}</h5>
										{(removingRawMaterial !== null && (removingRawMaterial.remove && removingRawMaterial.fileId === material.id)) ? (
											<InlineLoading status="active" style={{ width: '20px'}} />
										) : (
											<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
												<OverflowMenuItem itemText="Download file" href={this.downloadFile(material)} target="_blank" rel="noopener noreferrer" />
												<OverflowMenuItem hasDivider isDelete itemText="Delete file" onClick={(e) => this.openDeleteFile(e, material, project, removeRawMaterialMutation)} />
											</OverflowMenu>
										)}
									</div>
								</div>
								{errorRemovingRawMaterial &&
									<small style={{ margin: '0 16px' }}>{errorRemovingRawMaterial}</small>
								}
							</div>
						))
					);
				}}
			</Mutation>
		);
	}

	async openDeleteFile(e, material, project, removeRawMaterialMutation) {
		e.preventDefault();
		let error;

		this.setState({ removingRawMaterial:
			{ fileId: material.id, remove: true }
		});

		try {
			const result = await removeRawMaterialMutation({
				variables: {
					projectId: project.id,
					fileId: material.id,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error deleting file');
			this.setState({ errorRemovingRawMaterial: error.toString() });
			setTimeout(() => {
				this.setState({ errorRemovingRawMaterial: null });
			}, 3000);
		} else {
			console.log('Success deleting file');
			window.location.reload();
			this.setState({ removingRawMaterial: null });
		}
		this.setState({ removingRawMaterial: null });
	}

	downloadFile(file) {
		return serverAPI.getDownloadUrlByFile(file);
	}

	displayProjectOnboardingAnswers(project) {
		return (
			<PhaseViewSetupQuestions
				projectId={project.id}
				phase={PROJECT.STATE.CLIENT_ONBOARDING}
				noAnswersMessage="You don't have any project onboarding answers."
				onboardingQuestionsDone={() => undefined}
			/>
		);
	}

	render() {
		const { project, userData: { token }, refetchProject } = this.props;
		const {
			selectedState,
			previewFile,
			newRawMaterialFiles,
			switchIcons,
			showProjectImage,
		} = this.state;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};
		const briefingUrls = fileTools.getUrls(briefing.url);

		const rawMaterial = {
			name: 'Client Sketch',
			url: serverAPI.getPublicSketchUrl(project.id, token.accessToken),
		};

		const phases = PROJECT.getContestStates();
		const indexPhase = phases.indexOf(selectedState !== null ? selectedState : project.state);

		let { rawMaterials } = project;
		rawMaterials = [...rawMaterials, ...newRawMaterialFiles];
		rawMaterials = rawMaterials.map(rawMaterial => {
			return {
				id: rawMaterial.id,
				name: rawMaterial.name,
				fileType: rawMaterial.mimeType,
				url: serverAPI.getThumbnailUrlByFile(rawMaterial),
			};
		});

		return (
			<Fragment>
				<h1 className={classes.title}>{KEYS.my_project_title}</h1>
				<div className={classes.configContainer}>
					<h4 style={{fontWeight: '400'}}>{project.name}</h4>
					<OverflowMenu
						renderIcon={SettingsAdjust20}
						direction="bottom"
						flipped
						light
					>
						<OverflowMenuItem disabled itemText={showProjectImage ? 'Hide project image' : 'Display project image'} onClick={() => this.setState({showProjectImage: !showProjectImage})} />
						<OverflowMenuItem itemText={'Show upsales options'} onClick={() => window.location.replace('/#add-ons')} />
						{/* <OverflowMenuItem itemText={'Change new version'} onClick={() => window.location.replace(`/project/${project.id}`)} /> */}
					</OverflowMenu>
				</div>
				<div style={{position: 'relative'}}>
					{showProjectImage &&
					<div style={{ position: 'absolute', top: '-58px', left: 0, right: 0 }}>
						<small
							className={classes.hideBtn}
							onClick={() => this.setState({showProjectImage: false})}
						>
							Hide image
						</small>
						<img
							className={classes.projectImage}
							src={project.image && serverAPI.getThumbnailUrlByFile(project.image)}
						/>
					</div>
					}
					{project.state !== PROJECT.STATE.PROJECT_SET_UP &&
					<Fragment>
						<div className={classes.contestsStates}>
							<PhaseStatesBubbles
								project={project}
								state={project.state}
								displayOnClient={true}
								selectState={selectedState || project.state}
								onClickPhase={e => this.onSelectPhase(e, project)}
								switcher={switchIcons}
							/>
						</div>
						<div className={classes.contestsStatesMobile}>
							<Button
								className={classes.btn}
								kind='primary'
								onClick={() => this.setState({ selectedState: phases[indexPhase+1] })}
							>
								Go to next phase
							</Button>
						</div>
					</Fragment>
					}
				</div>
				{(project.state === PROJECT.STATE.CLOSED && (selectedState !== PROJECT.STATE.CLOSED && selectedState !== null)) &&
					<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							className={classes.btn}
							kind='secondary'
							onClick={() => this.setState({ selectedState: project.state })}
						>
							Return to final step
						</Button>
					</div>
				}
				<PhaseContestDetails
					project={project}
					state={selectedState || project.state}
					activeState={project.state}
					onClickPreviewProposal={(file) => this.onOpenPreviewFile(file)}
					refetchProject={refetchProject}
					switcher={switchIcons}
				/>
				<Accordion align='start' className={classes.accordion}>
					<AccordionItem
						open={false}
						key='project-reference-documents'
						title={<h3>Project reference documents</h3>}
					>
						<div>
							<div className={classes.contentBlock}>
								<div className={classes.contentTitle}>
									<h4>{KEYS.project_onboarding_step_1_title}</h4>
									<p>{KEYS.project_onboarding_step_1_description}</p>
								</div>
								<div className={classes.elementBox}>
									<div className={classes.naming}>
										<Document20 />
										<p>{KEYS.project_onboarding_step_1_title}</p>
									</div>
									<div className={classes.actions}>
										<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>
											{KEYS.open_file}
										</span>
										<a href={briefingUrls.previsualizeUrl} target="_blank" rel="noopener noreferrer">
											<Download20 />
										</a>
									</div>
								</div>
							</div>
							<div className={classes.contentBlock}>
								<div className={classes.contentTitle}>
									<h4>{KEYS.project_onboarding_step_2_title}</h4>
									<p>{KEYS.project_onboarding_step_2_description}</p>
								</div>
							</div>
							<div className={classes.materialList}>
								{project.productSketch !== null &&
									<div className={classes.materialCard}>
										<RawMaterialImage
											src={rawMaterial.url}
											className={classes.cardImg}
											onClick={() => this.onOpenPreviewFile(rawMaterial.url)}
										/>
										<div className={classes.cardName}>
											<h5 className={classes.name}>{KEYS.first_sketch_file.substring(0, 20)}</h5>
											<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
												<OverflowMenuItem itemText="Download file" href={rawMaterial.url} target="_blank" rel="noopener noreferrer" />
												<OverflowMenuItem isDelete disabled itemText="Delete file" />
											</OverflowMenu>
										</div>
									</div>
								}
								{this.removeRawMaterialOption(rawMaterials)}
							</div>
							<div className={classes.contentBlock}>
								<div className={classes.contentTitle}>
									<h4>More info</h4>
									<p>Your project set up answers.</p>
								</div>
								{this.displayProjectOnboardingAnswers(project)}
							</div>
						</div>
					</AccordionItem>
				</Accordion>
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					onRequestClose={() => this.onClosePreviewFile()}
				/>
			</Fragment>
		);
	}
}

ProjectBoard.propTypes = {
	userData: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	refetchProject: PropTypes.func,
};

ProjectBoard.defaultProps = {
	refetchProject() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(ProjectBoard)));
