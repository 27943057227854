import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Download20, Document20, Attachment20 } from '@carbon/icons-react';
import PreviewFileModal from '../../../../../components/modals/preview-file-modal';

import { ProjectContestMaterial } from '../components';
import { PROJECT } from '../../../../../constants';
import { fileTools, serverAPI } from '../../../../../utils';
import KEYS from '../../../../../glossary';

import classes from './tabs.module.scss';

class MaterialsTab extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
		};
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	render() {
		const { project, userData } = this.props;
		const { rawMaterials } = project;
		const { previewFile } = this.state;

		const { token } = userData;

		const contest = PROJECT.getCurrentContest(project);
		const contestType = contest && contest.type;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};
		const { previsualizeUrl } = fileTools.getUrls(briefing.url);

		const rawMaterial = {
			name: 'Client Sketch',
			url: serverAPI.getPublicSketchUrl(project.id, token.accessToken),
		};
		const { downloadUrl } = fileTools.getUrls(rawMaterial.url);
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;

		return (
			<div className={classes.tabContentContainer}>
				{projectIsFinished
					?	<h3 className={classes.title}>Project materials</h3>
					:	<h3 className={classes.title}>Find all the initial material to get started</h3>
				}
				<div className={classes.materialsContent}>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{KEYS.project_briefing}</h4>
							<p>{KEYS.project_briefing_text}</p>
						</div>
						<div className={classes.elementBox}>
							<div className={classes.naming}>
								<Document20 />
								<p>{KEYS.project_briefing}</p>
							</div>
							<div className={classnames([classes.actions, classes.fileAction])}>
								<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>{KEYS.open_file}</span>
								<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer"><Download20 /></a>
							</div>
						</div>
					</div>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{KEYS.raw_material}</h4>
							<p>{KEYS.raw_material_text}</p>
						</div>
						{project.productSketch !== null &&
							<div className={classes.elementBox}>
								<div className={classes.naming}>
									<Attachment20 />
									<p>{KEYS.first_sketch_file}</p>
								</div>
								<div className={classnames([classes.actions, classes.fileAction])}>
									<span className={classes.link} onClick={() => this.onOpenPreviewFile(rawMaterial)}>{KEYS.open_file}</span>
									<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
										<Download20 />
									</a>
								</div>
							</div>
						}
						{rawMaterials.map((rawMaterialFile, i) => {
							const downloadUrl = serverAPI.getDownloadUrlByFile(rawMaterialFile);
							return (
								<div className={classes.elementBox} key={i}>
									<div className={classes.naming}>
										<Attachment20 />
										<p>{rawMaterialFile.name}</p>
									</div>
									<div className={classnames([classes.actions, classes.fileAction])}>
										<span className={classes.link} onClick={() => this.onOpenPreviewFile(rawMaterialFile)}>{KEYS.open_file}</span>
										<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
											<Download20 />
										</a>
									</div>
								</div>
							);
						})}
					</div>
					<ProjectContestMaterial
						project={project}
						contestType={contestType}
					/>
					<PreviewFileModal
						open={!!previewFile}
						file={previewFile}
						onRequestClose={() => this.onClosePreviewFile()}
					/>
				</div>
			</div>
		);
	}
}

MaterialsTab.defaultProps = {
	project: {},
};

MaterialsTab.propTypes = {
	project: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(MaterialsTab);