import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

// import { Locked32 } from '@carbon/icons-react';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import { Tag } from 'carbon-components-react';

import { PROJECT, CONTEST } from '../../../../constants';
import { serverAPI } from '../../../../utils';
import KEYS from '../../../../glossary';

import classes from './blocks.module.scss';

const PROPOSAL_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';

class WinningProposals extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
			showProposalInfo: false,
			linkActive: false,
		};
	}

	onOpenPreviewFile(file, url) {
		this.setState({ previewFile: { src: url } });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	render() {
		const { project, disabled } = this.props;
		const { previewFile } = this.state;

		const phases = PROJECT.getContestStates();
		const phasesOrder = Array.isArray(project.contestOrder) ? project.contestOrder : phases;

		return (
			<div className={classes.tabContentContainer}>
				<div className={classnames(classes.proposalsContainer, disabled && classes.disabledOption)}>
					{disabled &&
						<span className={classes.disabledMessage}>
							<p>Here you will see the winning proposals once you rate them</p>
						</span>
					}
					{phasesOrder.map((phase, i) => (
						this.displayProposalByPhase(phase, i)
					))}
					<PreviewFileModal
						open={!!previewFile}
						src={previewFile && previewFile.src}
						onRequestClose={() => this.onClosePreviewFile()}
					/>
				</div>
			</div>
		);
	}

	displayProposalByPhase(phaseType, i) {
		const phase = PROJECT.getStateByContestType(phaseType);

		return (
			<div key={i}>
				{this.renderItem(phase)}
			</div>
		);
	}

	renderItem(state) {
		const { project, onClickProposal, disabled } = this.props;
		const contest = PROJECT.getContestByState(project, state);
		const contestHasEndedAndHasAWinningProposal = contest && (CONTEST.isContestEnded(contest) && CONTEST.hasWinner(contest));

		return (
			<div className={classes.overviewCategoryBox}>
				{disabled
					?	<div style={{display: 'flex', flexWrap: 'wrap'}}>
						<div className={classes.proposalContainer}
							style={{display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '.5'}}
						>
							<img
								className={classes.winningProposalImg}
								src={PROPOSAL_PLACEHOLDER}
								alt={`Future ${KEYS.winning_proposal}`}
							/>
						</div>
					</div>
					: contestHasEndedAndHasAWinningProposal &&
					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						{contest.proposals.length > 0
							?	contest.proposals.map((proposal, i) => (
								this.renderProposal(proposal, i, contest, onClickProposal, state)
							))
							:	<small>No winners yet.</small>
						}
					</div>
				}
			</div>
		);
	}

	renderProposal(proposal, i, contest, onClickProposal, phase) {
		const { showProposalInfo, linkActive } = this.state;
		const { feedback } = proposal;
		const isWinner = CONTEST.isWinningProposal(contest, proposal);

		for (const proposalFile of proposal.files) {
			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			if (!approvedAndPublished) continue;

			const { file } = fileVersion;
			const downloadUrl = serverAPI.getDownloadUrlByFile(file);

			return (
				isWinner &&
					<div
						key={i}
						className={classes.proposalContainer}
						onClick={(!linkActive) ? () => onClickProposal(proposal, file, feedback) : {}}
						onMouseEnter={() => this.setState({ showProposalInfo: proposal.id })}
						onMouseLeave={() => this.setState({ showProposalInfo: false })}
					>
						<img
							className={classes.winningProposalImg}
							src={this.getProposalThumbnailUrl(proposal)}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = PROPOSAL_PLACEHOLDER;
							}}
							alt={KEYS.winning_proposal}
						/>
						<Tag
							className={classes.phaseTag}
							type="cool-gray"
							style={{backgroundColor: CONTEST.getPhaseColor(phase)}}
						>
							{PROJECT.stateToStringShort(phase)}
						</Tag>
						{(showProposalInfo && showProposalInfo === proposal.id) &&
							<div style={{ position: 'absolute', bottom: 0, width: '-webkit-fill-available', borderRadius: '0 0 7px 7px', background: '#fff'}}>
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px'}}>
									<h6>Proposal #{proposal.number}</h6>
									<a
										className={classes.link}
										onMouseEnter={() => this.setState({ linkActive: true })}
										onMouseLeave={() => this.setState({ linkActive: false })}
										href={downloadUrl}
										target="_blank" rel="noopener noreferrer"
									>
										<FileDownloadOutlined
											fontSize="small"
											style={{color: '#313131'}}
										/>
									</a>
								</div>
							</div>
						}
					</div>
			);
		}
	}

	getProposalThumbnailUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}
}

WinningProposals.defaultProps = {
	project: {},
	user: {},
	userData: {},
	disabled: false,
	onClickProposal() {},
};

WinningProposals.propTypes = {
	project: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	onClickProposal: PropTypes.func,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(WinningProposals);
