import React from 'react';
import PropTypes from 'prop-types';
import { TooltipDefinition } from 'carbon-components-react';

const ProgressBar = (props) => {
	const { lbCredits, isPayment } = props;

	const lbLimit = 400;
	const completed = ((lbCredits/lbLimit) * 100).toFixed();
	const completePercentage = completed > 100 ? 100 : completed;
	const minPercentage = completePercentage > 0 ? 11 : 0;
	const completeColor = completePercentage >= 100 ? '#24a148' : '#df3d3b';

	const mainBox = {
		display: 'flex',
		flexDirection: 'column',
		width: '300px'
	};
	const container = {
		width: '100%',
		backgroundColor: '#e0e0de',
		borderRadius: '16px',
		height: '16px',
		display: 'flex',
		alignItems: 'center'
	};
	const fillbar = {
		height: '100%',
		width: `${completePercentage}%`,
		minWidth: `${minPercentage}%`,
		backgroundColor: completeColor,
		borderRadius: 'inherit',
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'flex-end'
	};
	const label = {
		padding: 1,
		color: '#fff',
		fontWeight: 'bold'
	};
	const additionalText = {
		marginTop: '8px'
	};

	return (
		<div style={mainBox}>
			<div style={container}>
				<div style={fillbar}>
					<span style={label}>{`${completed} %`}</span>
				</div>
			</div>
			{isPayment &&
				<TooltipDefinition tooltipText="Once you reach 400 credits you will complete a level and LB will convert those credits into a payment of 400 euros">
					<small style={additionalText}>Know more</small>
				</TooltipDefinition>
			}
		</div>
	);
};

ProgressBar.propTypes = {
	lbCredits: PropTypes.number,
	isPayment: PropTypes.bool,
};

ProgressBar.defaultProps = {
	lbCredits: 0,
	isPayment: false,
};

export default ProgressBar;