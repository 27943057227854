import React from 'react';
import PropTypes from 'prop-types';

const ElectricalIconAlt = (props) => {
	const { size, active, color, type } = props;

	return (
		<svg id="electrical-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color !== '' || type === 'color' ? '#3AA936' : active || type === 'white' ? '#fff' : '#C7C7C7'}>
			<path d="M11,11V21H21V11Zm8,8H13V13h6Z" transform="translate(0)"/>
			<path d="M30,13V11H26V8a2,2,0,0,0-2-2H21V2H19V6H13V2H11V6H8A2,2,0,0,0,6,8v3H2v2H6v6H2v2H6v3a2,2,0,0,0,2,2h3v4h2V26h6v4h2V26h3a2,2,0,0,0,2-2V21h4V19H26V13ZM24,24H8V8H24Z" transform="translate(0)"/>
		</svg>
	);
};

ElectricalIconAlt.propTypes = {
	active: PropTypes.bool,
	size: PropTypes.number,
	color: PropTypes.string,
	type: PropTypes.string,
};

ElectricalIconAlt.defaultProps = {
	active: false,
	size: 20,
	color: '',
	type: 'white',
};

export default ElectricalIconAlt;