import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbSkeleton,
	Button,
	InlineLoading,
	InlineNotification,
	PasswordInput,
	SkeletonText
} from 'carbon-components-react';
import { Mutation } from '@apollo/client/react/components';

import { Locked16 } from '@carbon/icons-react';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';

import { GET_MY_PROFILE_INFO } from '../../../providers/queries';
import { UPDATE_PASSWORD } from '../../../providers/mutations';
import { dates } from '../../../utils';

import KEYS from '../../../glossary';
import classes from './../../experts/internal-pages/personal-information/personal-information.module.scss';

class LoginMethod extends Component {
	constructor(props) {
		super(props);

		this.refetchProfileInfo = () => {};
		this.state = {
			password: '',
			editPasswordSection: false,
			updatingPassword: false,
			updatingPasswordSuccessfully: false,
			updatingPasswordError: null,
			passwordRecentlyUpdated: false,
		};
	}

	componentDidMount() {
		document.title = 'Login method - LastBasic';
	}

	onChangePassword(e) {
		this.setState({ password: e.target.value });
	}

	onUpdatePassword = async (e, updatePassword) => {
		const { password } = this.state;
		e.preventDefault();

		this.setState({ updatingPassword: true });

		let error;
		try {
			const result = await updatePassword({
				variables: {
					password: password
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating password');
			this.setState({
				password: '',
				updatingPasswordError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					updatingPasswordError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating password');
			this.setState({ updatingPasswordSuccessfully: true, updatingPassword: false, passwordRecentlyUpdated: true });
			setTimeout(() => {
				this.setState({
					updatingPasswordSuccessfully: false,
					editPasswordSection: false,
				});
				this.refetchProfileInfo();
			}, 3000);
		}
		this.setState({ updatingPassword: false });
	};

	render() {
		const {
			editPasswordSection, password,
			updatingPassword, updatingPasswordSuccessfully,
			updatingPasswordError, passwordRecentlyUpdated
		} = this.state;

		return (
			<ErrorBoundaryQuery query={GET_MY_PROFILE_INFO}>
				{({ loading, error, data, refetch: refetchProfileInfo }) => {
					this.refetchProfileInfo = refetchProfileInfo;

					if (loading) {
						return (
							<div className={classes.profileContainer}>
								<BreadcrumbSkeleton className={classes.breadcrumbBox}/>
								<div className={classes.section}>
									<SkeletonText style={{ width: '150px'}} />
									<div className={classes.section}>
										<SkeletonText style={{ width: '100px'}} />
										<SkeletonText style={{ width: '300px', margin: '16px 0'}} />
										<SkeletonText style={{ width: '100px'}} />
										<SkeletonText style={{ width: '300px', margin: '16px 0'}} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const profile = (data && data.myProfileInfo ) || {};

					const passwordLastUpdatedAt = (profile.passwordLastUpdatedAt !== null)
						? dates.formattedNewDate(profile.passwordLastUpdatedAt)
						: null;

					return (
						<div className={classes.profileContainer}>
							<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
								<BreadcrumbItem>
									<Link to="/account/settings">Account</Link>
								</BreadcrumbItem>
								<BreadcrumbItem isCurrentPage href="#">
									Security
								</BreadcrumbItem>
							</Breadcrumb>

							<div>
								<h2 className={classes.title}>Security</h2>
								<p>Review and update your credentials</p>

								<div className={classes.section}>
									<div className={classes.sectionContent}>
										<h4 className={classes.secondaryTitle} style={{ display: 'flex', alignItems: 'center'}}>
											Username <Locked16 style={{marginLeft: '4px'}} />
										</h4>
									</div>
									<div className={classes.sectionContent}>
										<p>{profile.email}</p>
									</div>
								</div>
								<Mutation mutation={UPDATE_PASSWORD}>
									{(updatePassword, { error }) => (
										<div className={classes.section}>
											{editPasswordSection === false
												?	<div style={{ display: 'flex' }}>
													<h4 className={classes.secondaryTitle}>Password</h4>
													<Button
														className={classes.btn}
														style={{ marginLeft: '8px'}}
														onClick={() => this.setState({ editPasswordSection: true })}
														size='sm'
														kind='secondary'>
														{KEYS.update}
													</Button>
												</div>
												: 	<h4 className={classes.secondaryTitle}>Password</h4>
											}
											{error && (
												<InlineNotification
													className={classes.notification}
													kind="error"
													lowContrast
													hideCloseButton={false}
													title="Error"
													subtitle={(error.graphQLErrors && error.graphQLErrors.length)
														? error.graphQLErrors[0].message
														: (error.networkError)
															? error.networkError.message
															: error.message}
													timeout={5000}
												/>
											)}
											<div className={classes.infoElements}>
												{editPasswordSection
													?	<PasswordInput
														id="password-input"
														className={classes.textInput}
														style={{ marginLeft: '0'}}
														labelText="New password"
														value={password}
														onChange={(e) => this.onChangePassword(e)}
														required
														invalid={(updatingPasswordError !== null) ? true : false}
														// invalidText={updatingPasswordError}
														// helperText="Minimum 8 characters"
													/>
													:	<h5>●●●●●●●●</h5>
												}
											</div>
											{editPasswordSection &&
												<div className={classes.editBtn}>
													<Button
														className={classes.btn}
														style={{ marginRight: '4px'}}
														onClick={() => this.setState({ editPasswordSection: false })}
														disabled={updatingPassword || updatingPasswordSuccessfully}
														size='sm'
														kind='ghost'>
														{KEYS.cancel}
													</Button>
													{updatingPassword || updatingPasswordSuccessfully ? (
														<InlineLoading
															description={updatingPasswordSuccessfully ? 'Password updated successfully' : 'Updating password...'}
															status={updatingPasswordSuccessfully ? 'finished' : 'active'}
															style={{ marginLeft: '1rem', width: '200px' }}
														/>
													) : (
														<Button
															className={classes.btn}
															onClick={(e) => this.onUpdatePassword(e, updatePassword)}
															size='sm'
															kind='primary'>
															{KEYS.save}
														</Button>
													)}
												</div>
											}
											{(passwordLastUpdatedAt || passwordRecentlyUpdated) &&
												<div style={{ margin: '16px 0'}}>
													<small>Last updated: {passwordLastUpdatedAt ? passwordLastUpdatedAt : passwordRecentlyUpdated && 'Recently'}</small>
												</div>
											}
										</div>
									)}
								</Mutation>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

LoginMethod.defaultProps = {
	password: '',
};

LoginMethod.propTypes = {
	password: PropTypes.string,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(LoginMethod);