import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PROJECT } from '../../../constants';
import { GET_PROJECT_SETUP_QUESTIONS } from '../../../providers/queries';
import ViewSetupQuestions from '../../../components/project/setup-questions/view-setup-questions';
import ErrorBoundaryQuery from '../../graphql/queries-with-errors';

class ContestViewSetupQuestions extends Component {

	render() {
		const { contestType, noAnswersMessage, projectId } = this.props;

		return (
			<ErrorBoundaryQuery query={GET_PROJECT_SETUP_QUESTIONS} variables={{ id: projectId }}>
				{({ data }) => {
					const project = data && data.project;
					if (!project) return null;

					const contest = PROJECT.getContestByType(project, contestType);
					if (!contest) return null;

					const questions = contest.setupQuestions;

					return (
						<ViewSetupQuestions
							questions={questions}
							noAnswersMessage={noAnswersMessage}
						/>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ContestViewSetupQuestions.propTypes = {
	projectId: PropTypes.string.isRequired,
	contestType: PropTypes.string.isRequired,
	noAnswersMessage: PropTypes.string,
};

ContestViewSetupQuestions.defaultProps = {
	noAnswersMessage: 'You don\'t have any answers.'
};

export default ContestViewSetupQuestions;