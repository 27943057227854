import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'carbon-components-react';

import KEYS from '../../glossary';
import ProposalFeedbackContent from '../project/proposal-feedback-content';

class ViewFeedbackModal extends Component {
	render() {
		const { proposal, isWinningProposal, onPreviewProposalFile, ...restProps } = this.props;

		return (
			<Modal
				passiveModal
				modalHeading={KEYS.proposal_feedback_modal_label}
				{...restProps}>
				<ProposalFeedbackContent
					proposal={proposal}
					feedback={proposal.feedback}
					isWinningProposal={isWinningProposal}
					displayOnPublic={true}
					onPreviewProposalFile={onPreviewProposalFile}
				/>
			</Modal>
		);
	}
}

ViewFeedbackModal.propTypes = {
	proposal: PropTypes.object.isRequired,
	isWinningProposal: PropTypes.bool,
	onPreviewProposalFile: PropTypes.func
};

ViewFeedbackModal.defaultProps = {
	proposal: {},
	isWinningProposal: false,
	onPreviewProposalFile() {}
};

export default ViewFeedbackModal;
