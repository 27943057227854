import React from 'react';
import PropTypes from 'prop-types';

import classes from './feature.module.scss';

import ValidationIcon from '../assets/validation.svg';
import InterestIcon from '../assets/interest.svg';
import InvestorsIcon from '../assets/investors.svg';
import PrototypeIcon from '../assets/prototype.svg';

const features = {
	validation: {
		title: 'Validation',
		description: 'Hardware experts will validate your idea',
		icon: ValidationIcon,
	},
	interest: {
		title: 'Interest',
		description: 'See if your idea has interest in the market',
		icon: InterestIcon,
		pilot: true,
	},
	investors: {
		title: 'Investors',
		description: 'Access a pool of potential investors and partners',
		icon: InvestorsIcon,
		pilot: true,
	},
	prototype: {
		title: 'Create a prototype',
		description: 'Get a fully working prototype through the LastBasic platform',
		icon: PrototypeIcon,
	},
};

export default function Feature({ name, title, description, icon, css = {}, pilot, selectable, border, selected, onClick, display = true, blinking, small }) {
	if (!display) return <></>;

	if (features[name]) {
		title = features[name].title;
		description = features[name].description;
		icon = features[name].icon;
		pilot = features[name].pilot;
	}

	return (
		<div
			className={
				classes.feature +
				(border ? ' ' + classes.featureBorder : '') +
				(selectable ? ' ' + classes.selectable : '') +
				(selected ? ' ' + classes.selected : '') +
				(blinking ? ' ' + classes.blinkingBorders : '') +
				(small ? ' ' + classes.smallFeature : '')
			}
			onClick={onClick}
		>
			{ selectable && <div className={classes.selectableDot + ' ' + (selected ? classes.selectedDot : '')}></div> }
			<div className={classes.icon} style={{ backgroundImage: `url(${icon})`, ...css }}></div>
			<div className={classes.text}>
				<div className={classes.title}>
					{title}
					{ pilot && <div className={classes.pilot}>Pilot</div> }
				</div>
				<div className={classes.description}>{description}</div>
			</div>
		</div>
	);
}

Feature.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	icon: PropTypes.string,
	color: PropTypes.string,
	css: PropTypes.object,
	pilot: PropTypes.bool,
	selectable: PropTypes.bool,
	border: PropTypes.bool,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	display: PropTypes.bool,
	blinking: PropTypes.bool,
	small: PropTypes.bool,
};
