import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {
	Accordion,
	AccordionItem,
	TooltipIcon,
	Tag,
	InlineNotification,
	NotificationActionButton,
	AccordionSkeleton,
	// Button
} from 'carbon-components-react';
import {
	Download20,
	Document20,
	Certificate20,
	DocumentAttachment20
} from '@carbon/icons-react';

import ContestIcon from '../../../components/project/contest-icon';

import ViewFeedbackModal from '../../../components/modals/view-feedback-modal';
import PreviewFileModal from '../../../components/modals/preview-file-modal';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';

import { CONTEST, PROJECT, PROPOSAL, FILE } from '../../../constants';
import { GET_PROJECT_FILES } from '../../../providers/queries';
import { projectStorage, serverAPI } from '../../../utils';

import classes from './files.module.scss';

class FilesPage extends Component {
	constructor(props) {
		super(props);

		this.refetchRankings = () => {};
		this.refetchContest = () => {};
		this.state = {
			openProposalFeedbackModal: false,
			selectedProposal: null,
			previewFile: null,
			propFile: null,
			isWinningProposal: false,
		};
	}

	componentDidMount() {
		document.title = 'Files - LastBasic';
	}

	render() {
		const {
			openProposalFeedbackModal,
			selectedProposal,
			previewFile,
			propFile,
			isWinningProposal
		} = this.state;

		const selectedProject = projectStorage.getProject() || {};
		const queryVariables = {
			id: selectedProject
		};

		return (
			<div className={classes.filesContainer}>
				<h2 className={classes.title}>Files</h2>
				<ErrorBoundaryQuery query={GET_PROJECT_FILES} variables={queryVariables}
					fetchPolicy="cache-and-network" errorPolicy="ignore">
					{({ loading, error, data, refetch: refetchRankings }) => {
						this.refetchRankings = refetchRankings;

						if (loading) {
							return (
								<div>
									<AccordionSkeleton
										className={classes.accordion}
										align='start'
										open={true}
										count={1}
									/>
									<AccordionSkeleton
										className={classes.accordion}
										align='start'
										open={false}
										count={4}
									/>
								</div>
							);
						}

						if (error) {
							if (selectedProject.id) {
								return (
									<InlineNotification
										className={classes.notification}
										kind="error"
										title=""
										lowContrast
										hideCloseButton
										subtitle={(error.graphQLErrors && error.graphQLErrors.length)
											? error.graphQLErrors[0].message
											: (error.networkError)
												? error.networkError.message
												: error.message}
									/>
								);
							}

							return (
								<InlineNotification
									kind="info"
									lowContrast
									hideCloseButton
									iconDescription="Info"
									title=""
									subtitle={'You need to select a project'}
									actions={<NotificationActionButton><Link to="/projects">Choose a project</Link></NotificationActionButton>}
								/>
							);
						}

						const project = (data && data.project && data.project);
						const contests = (data && data.project && data.project.contests) || [];
						const contestOrder = PROJECT.getContestStates();
						const orderedContests = contests.slice().sort((a, b) => contestOrder.indexOf(a.type) - contestOrder.indexOf(b.type));

						return (
							<Fragment>
								<h4 className={classes.subtitle}>{project && project.name}</h4>
								<div className={classes.content}>
									<Accordion align='start' className={classes.accordion}>
										{orderedContests.map((contest) => (
											this.displayComplementContestInfo(contest, contest.type)
										))}
									</Accordion>
								</div>
							</Fragment>
						);
					}}
				</ErrorBoundaryQuery>
				{openProposalFeedbackModal && selectedProposal && selectedProposal.feedback && (
					<ViewFeedbackModal
						open={openProposalFeedbackModal}
						onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
						proposal={selectedProposal}
						isWinningProposal={isWinningProposal}
						onPreviewProposalFile={() => this.setState({ previewFile: propFile })}
					/>
				)}
				{previewFile && (
					<PreviewFileModal
						open={!!previewFile}
						file={previewFile}
						onRequestClose={this.onClosePreviewFile}
					/>
				)}
			</div>
		);
	}

	displayComplementContestInfo(contest, contestType) {
		const phases = PROJECT.getContestStates();

		for (const phase of phases) {
			const projectState = PROJECT.getContestTypeByState(phase);

			return (
				<AccordionItem
					key={contest.id}
					open={projectState === contestType ? true : false}
					title={this.renderContestFilesTitle(contest, projectState)}
				>
					{this.displayContestFiles(contest)}
				</AccordionItem>
			);
		}
	}

	renderContestFilesTitle(contest, projectState) {
		const hasAWinningProposal = contest && PROPOSAL.hasAWinningProposal(contest);
		const phase = contest && PROJECT.getStateByContestType(contest.type);
		const activeState = phase && (phase === PROJECT.getStateByContestType(projectState)) ? true : false;

		return (
			<div className={classes.accorditionTitle}>
				<div className={classes.accordionBlock}>
					<div className={classes.phaseBubble} style={activeState || hasAWinningProposal ? {backgroundColor: CONTEST.getPhaseColor(phase)}:{}}>
						<ContestIcon state={phase} size={20} active={projectState === contest.type ? true : false} />
					</div>
					<h4 className={classes.contestTitle}>{contest.type} contest</h4>
				</div>
				{this.getContestState(contest, projectState)}
			</div>
		);
	}

	getContestState(contest, projectState) {
		const isOpen = contest && CONTEST.isContestOpen(contest, projectState);
		const hasAWinningProposal = contest && PROPOSAL.hasAWinningProposal(contest);

		return (
			contest.proposals.length === 0
				? <Tag type="gray" className={classes.infoTag}>No files available</Tag>
				: hasAWinningProposal
					?	<Tag type="green" className={classes.infoTag}>Available files</Tag>
					:	isOpen
						&&	<Tag type="blue" className={classes.infoTag}>Receiving Proposals</Tag>
		);
	}

	displayContestFiles(contest) {
		const selectedProposalId = contest.selectedProposal !== null && contest.selectedProposal.id;

		return (
			<Fragment>
				<div className={classes.contestDetailsBlock}>
					{/* <Button
						className={classes.btn}
						size='sm'
						kind='tertiary'
						disabled={true}
						// onClick={}
					>
						Download all items
					</Button> */}
				</div>
				<div style={{ margin: '36px 0'}}>
					{this.renderContestInfo(contest)}
					{contest.proposals.map((proposal, i) => (
						<div key={i}>
							{this.renderWinningProposalFiles(proposal, selectedProposalId)}
						</div>
					))}
					{this.renderContestSharedFiles(contest)}
				</div>
			</Fragment>
		);
	}

	renderContestInfo(contest) {
		const contestRound = contest && PROJECT.getContestRound(contest);
		const { card } = contestRound !== undefined && contestRound;
		const { clientConfirmed } = card !== undefined && card;
		const confirmed = clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES;

		return (
			confirmed &&
			<div className={ classnames([classes.elementBox])}>
				<div className={classes.naming}>
					<Certificate20 />
					<h6 className={classes.fileName}>
						Contest card
					</h6>
				</div>
				<div className={classes.characteristics}>
					<div className={classnames([classes.checkbox, classes.checkboxInline])}>
						<TooltipIcon tooltipText="You have ranked this proposal">
							<Tag type="blue" className={classes.statusTag}>Contest info</Tag>
						</TooltipIcon>
					</div>
				</div>
				<div className={ classnames([classes.actions, classes.moreOptions])}>
					<a className={classes.link}
						href={this.onGetContestCardPdfUrl(contest)}
						target="_blank" rel="noopener noreferrer"
					>
						Open file
					</a>
					<TooltipIcon tooltipText="Download file">
						<a href={this.onGetContestCardPdfUrl(contest)} target="_blank" rel="noopener noreferrer">
							<Download20 style={{ fill: '#0062ff', margin: '0 16px', cursor: 'pointer' }} />
						</a>
					</TooltipIcon>
				</div>
			</div>
		);
	}

	onGetContestCardPdfUrl(contest) {
		return serverAPI.getContestCardPdfUrl(contest);
	}

	renderWinningProposalFiles(proposal, selectedProposalId) {
		const { number, feedback } = proposal;

		for (const proposalFile of proposal.files) {
			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const { file } = fileVersion;

			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			const downloadUrl = serverAPI.getDownloadUrlByFile(file);
			const winningProposal = proposal.id === selectedProposalId;

			return (
				(approvedAndPublished && winningProposal) &&
				<div className={ classnames([classes.elementBox])}>
					<div className={classes.naming}>
						<Document20 />
						<h6 className={classes.fileName}>
							Proposal #{number}
						</h6>
					</div>
					<div className={classes.characteristics}>
						<div className={classnames([classes.checkbox, classes.checkboxInline])}>
							{feedback &&
								<TooltipIcon tooltipText="You have ranked this proposal">
									<Tag type="green" className={classes.statusTag}>Winning proposal</Tag>
								</TooltipIcon>
							}
						</div>
					</div>
					<div className={ classnames([classes.actions, classes.moreOptions])}>
						{feedback &&
							<span className={classes.link} onClick={() => this.setState({ openProposalFeedbackModal: true, selectedProposal: proposal, propFile: file, isWinningProposal: winningProposal })}>
								Open proposal
							</span>
						}
						<TooltipIcon tooltipText="Download file">
							<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
								<Download20 style={{ fill: '#0062ff', margin: '0 16px', cursor: 'pointer' }} />
							</a>
						</TooltipIcon>
					</div>
				</div>
			);
		}
	}

	renderContestSharedFiles(contest) {

		const rows = [];
		if (contest) {
			const { sharedFiles } = contest;

			for (const sharedFile of sharedFiles) {
				if (sharedFile.approvalStatus === FILE.APPROVAL_STATUS.APPROVED) {
					const f = sharedFile.file;
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);

					const newRow = (
						<div className={ classnames([classes.elementBox])}>
							<div className={classes.naming}>
								<DocumentAttachment20 />
								<h6 className={classes.fileName}>
									{f.name !== null ? f.name : 'Shared file'}
								</h6>
							</div>
							<div className={classes.characteristics}>
								<div className={classnames([classes.checkbox, classes.checkboxInline])}>
									<TooltipIcon tooltipText="Shared file">
										<Tag type="cyan" className={classes.statusTag}>Shared file</Tag>
									</TooltipIcon>
								</div>
							</div>
							<div className={ classnames([classes.actions, classes.moreOptions])}>
								<span className={classes.link} onClick={() => this.onOpenPreviewFile(f)}>
									Open file
								</span>
								<TooltipIcon tooltipText="Download file">
									<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
										<Download20 style={{ fill: '#0062ff', margin: '0 16px', cursor: 'pointer' }} />
									</a>
								</TooltipIcon>
							</div>
						</div>
					);
					rows.push(newRow);
				}
			}
		}

		return (
			rows.length > 0 &&
				<div className={classes.contentBox}>{rows.map(r => r)}</div>
		);
	}

	onOpenPreviewFile = (file) => {
		this.setState({ previewFile: file });
	};

	onClosePreviewFile = () => {
		this.setState({ previewFile: null });
	};

}

export default FilesPage;