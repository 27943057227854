import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {
	Toggle,
	Tag,
	Breadcrumb,
	BreadcrumbItem,
	TooltipIcon,
	Button,
	Accordion,
	AccordionItem,
	InlineNotification,
	BreadcrumbSkeleton,
	SkeletonPlaceholder,
	SkeletonText,
	AccordionSkeleton,
	DataTableSkeleton,
	InlineLoading,
	TooltipDefinition,
	OverflowMenu,
	OverflowMenuItem
} from 'carbon-components-react';
import { Information16, Receipt20, Report20, DocumentAttachment16 } from '@carbon/icons-react';
import { Mutation } from '@apollo/client/react/components';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';
import ProgressBar from '../../../../components/project/progress-bar';
import ActivityTable from './components/payments-table';
import PreviewFormModal from '../../../../components/modals/preview-form-modal';
import PreviewInvoiceModal from '../../../../components/modals/preview-invoice-modal';
import PreviewFileModal from '../../../../components/modals/preview-file-modal';

import { GET_USER_PAYMENTS } from '../../../../providers/queries';
import { CHANGE_PAYOUT_STATUS } from '../../../../providers/mutations';
import { serverAPI, fileTools, dates } from '../../../../utils';
import { PAYMENTS } from '../../../../constants';
import KEYS from '../../../../glossary';

import classes from './payments.module.scss';

class Payments extends Component {
	constructor(props) {
		super(props);

		this.refetchPayments = () => {};
		this.state = {
			previewForm: false,
			previewFile: null,
			openReviewInvoice: null,
			changePayoutStatusError: null,
			changePayoutStatusSuccess: null,
			changingInvoiceStatus: null,
		};
	}

	componentDidMount() {
		document.title = 'Payments - LastBasic';
	}

	onOpenPreviewFile = (url) => {
		this.setState({ previewFile: url });
	};

	onClosePreviewFile = () => {
		this.setState({ previewFile: null });
	};

	invoiceStatusLabel(status) {
		switch (status) {
			case PAYMENTS.PAYOUT_STATUS.PENDING:
				return 'Pending confirmation';
			case PAYMENTS.PAYOUT_STATUS.APPROVED:
				return 'Approved';
			case PAYMENTS.PAYOUT_STATUS.PAID:
				return 'Paid';
			case PAYMENTS.PAYOUT_STATUS.PAYMENT_RECEIVED:
				return 'Payment received';
			case PAYMENTS.PAYOUT_STATUS.CANCELED:
				return 'Canceled';
			default:
				return 'Other';
		}
	}

	openInvoice(payout) {
		const { userData } = this.props;
		const { token } = userData;
		const invoiceUrl = serverAPI.getInvoiceUrl(payout.invoiceUrl, token.accessToken);

		this.setState({ openReviewInvoice: { url: invoiceUrl, payoutId: payout.id }});
	}

	getInvoices(payouts) {
		let filteredOpenInvoices = payouts.filter(payout => payout.status !== 'payment_received');
		let filteredClosedInvoices = payouts.filter(payout => payout.status === 'payment_received');

		return (
			<>
				{filteredOpenInvoices.length > 0 &&
					<div>
						<h5>Open invoices</h5>
						{filteredOpenInvoices.map((invoice) => (
							this.getInvoice(invoice)
						))}
					</div>
				}
				{filteredClosedInvoices.length > 0 &&
					<div style={{margin: '36px 0'}}>
						<h5>History</h5>
						{filteredClosedInvoices.map((invoice) => (
							this.getInvoice(invoice)
						))}
					</div>
				}
				{payouts.length > 0 &&
					<small>Email us at <a
						href="mailto:payments@lastbasic.com?subject=I%20need%20help%20with%20a%20payment"
						style={{ color: 'inherit' }}
					>
							payments@lastbasic.com
					</a> if you have any problems with your invoices</small>
				}
			</>
		);
	}

	getInvoice(invoice) {
		const { userData } = this.props;
		const { token } = userData;
		const { changePayoutStatusError, changePayoutStatusSuccess, changingInvoiceStatus } = this.state;

		const isPendingConfirmation = invoice.status === 'pending';
		const isPaidAndNeedReceiveConfirmation = invoice.status === 'paid';
		const isApprovedAndNotPaidYet = invoice.status === 'approved';
		const invoiceUrl = serverAPI.getInvoiceUrl(invoice.invoiceUrl, token.accessToken);

		const { downloadUrl } = fileTools.getUrls(invoiceUrl);
		const invoiceConcept = invoice.concept;

		return (
			<>
				<div className={classes.elementBox}>
					<div className={classes.naming}>
						<Receipt20 />
						<div>
							<TooltipDefinition tooltipText="Gross amount">
								<h5>{invoice.amount}€</h5>
							</TooltipDefinition>
							<TooltipDefinition tooltipText="Net amount">
								<small>{invoice.netAmount}€</small>
							</TooltipDefinition>
						</div>
						<small className={classes.fileName}>
							{invoiceConcept.length > 25
								?	invoiceConcept.substring(0, 20) + '...'
								:	invoiceConcept
							}
						</small>
					</div>
					<div className={classes.characteristics}>
						<small className={classes.invoiceDate}>{dates.formattedNewDate(invoice.createdAt)}</small>
						<div className={classnames([classes.checkbox, classes.checkboxInline])}>
							<Tag type={PAYMENTS.getStatusColorTag(invoice.status)} className={classes.statusTag}>{this.invoiceStatusLabel(invoice.status)}</Tag>
						</div>
					</div>
					<div className={ classnames([classes.actions, classes.moreOptions])}>
						{(isPendingConfirmation && invoice.invoiceAvailable) ?
							<Button
								className={classnames([classes.btn, classes.fileBtn])}
								onClick={() => this.openInvoice(invoice)}
								size='sm'
								kind='tertiary'>
									Review invoice
							</Button>
							: (!invoice.invoiceAvailable) && <Tag type='red' className={classes.statusTag}>Invoice unavailable</Tag>
						}
						{isApprovedAndNotPaidYet &&
							<Tag type='gray' className={classes.statusTag}>Payment in progress</Tag>
						}
						{(isPaidAndNeedReceiveConfirmation && invoice.invoiceAvailable) &&
							<div style={{ width: '180px' }}>
								<Mutation mutation={CHANGE_PAYOUT_STATUS}>
									{(changePayoutStatus) => (
										<>
											{(changingInvoiceStatus !== null && (changingInvoiceStatus.invoiceId === invoice.id))
												?	<InlineLoading description='Confirming payment status...' />
												:
												<Toggle
													id="payment-received-toggle"
													size="md"
													className={classes.toggle}
													defaultToggled={isPaidAndNeedReceiveConfirmation ? false : true}
													labelA="Got paid?"
													labelB="Received"
													aria-label="Payment received"
													onToggle={() => this.onConfirmPaymentReceived(invoice.id, changePayoutStatus)}
												/>
											}
										</>
									)}
								</Mutation>
							</div>
						}
						{(invoice.invoiceAvailable === true) &&
							<OverflowMenu style={{ marginLeft: '8px' }} direction="bottom" flipped light >
								<OverflowMenuItem itemText="Open invoice" onClick={() => this.onOpenPreviewFile(invoiceUrl)} />
								<OverflowMenuItem itemText="Download invoice" href={downloadUrl} target="_blank" rel="noopener noreferrer" />
							</OverflowMenu>
						}
					</div>
				</div>
				{(changePayoutStatusError && changePayoutStatusError.payoutId === invoice.id) &&
					<small style={{ color: 'red' }}>{changePayoutStatusError.error}</small>
				}
				{(changePayoutStatusSuccess && changePayoutStatusSuccess.payoutId === invoice.id) &&
					<small style={{ color: 'green' }}>{changePayoutStatusSuccess.message}</small>
				}
			</>
		);
	}

	async onConfirmPaymentReceived(payoutId, changePayoutStatus) {
		let error;

		this.setState({ changingInvoiceStatus: { invoiceId: payoutId, changing: true }});
		try {
			const result = await changePayoutStatus({
				variables: {
					payoutId: payoutId,
					status: 'payment_received',
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			setTimeout(() => {
				this.setState({ changePayoutStatusError: {payoutId: payoutId, error: error.toString() }});
			}, 3000);
			setTimeout(() => {
				this.setState({ changePayoutStatusError: null, changingInvoiceStatus: null });
				this.refetchPayments();
			}, 8000);
		} else {
			setTimeout(() => {
				this.setState({ changePayoutStatusSuccess: {payoutId: payoutId, message: 'You have confirmed us your payment received successfully' }});
			}, 3000);
			setTimeout(() => {
				this.setState({ changePayoutStatusSuccess: null, changingInvoiceStatus: null });
				this.refetchPayments();
			}, 8000);
		}
	}

	displayLegalInfo(paymentInformation) {
		return (
			<div className={classes.legalInfoContent}>
				<div className={classes.personalData}>
					<div className={classes.infoTitle}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<Report20 />
							<h4>Legal info</h4>
						</div>
						<span className={classes.link} onClick={() => this.setState({ previewForm: true })}>{KEYS.edit}</span>
					</div>
					<div className={classes.infoElements}>
						<h5>First name</h5>
						<p>{paymentInformation.firstName}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Last name</h5>
						<p>{paymentInformation.lastName}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Email</h5>
						<p>{paymentInformation.email}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Country of residence</h5>
						<p>{paymentInformation.countryResidence}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Invoice type</h5>
						<p>{paymentInformation.invoiceType}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>ID Number</h5>
						<p>{paymentInformation.idNumber}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Tax residence</h5>
						<p>{paymentInformation.taxResidence}</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Europe operator</h5>
						<p>{paymentInformation.europeOperator}</p>
					</div>
					{paymentInformation.taxDocument &&
						<div className={classes.infoElements}>
							<h5>Tax document</h5>
							<p>
								<a href={paymentInformation.taxDocument} target='_blank' rel="noopener noreferrer">
									{KEYS.open} document
								</a>
							</p>
						</div>
					}
					<div className={classes.infoElements}>
						<h5>VAT tax</h5>
						<p>{paymentInformation.vatTax}%</p>
					</div>
					<div className={classes.infoElements}>
						<h5>Retention tax</h5>
						<p>{paymentInformation.retentionTax}%</p>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { previewForm, previewFile, openReviewInvoice } = this.state;

		return (
			<ErrorBoundaryQuery query={GET_USER_PAYMENTS}>
				{({ loading, error, data, refetch: refetchPayments }) => {
					this.refetchPayments = refetchPayments;

					if (loading) {
						return (
							<div className={classes.profileContainer}>
								<BreadcrumbSkeleton className={classes.breadcrumbBox}/>
								<SkeletonText style={{ width: '100px'}} />
								<div className={classnames([classes.section, classes.summaryBlock])}>
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
								</div>
								<DataTableSkeleton />
								<div className={classes.section}>
									<SkeletonText style={{ width: '100px'}} />
									<SkeletonText style={{ width: '300px'}} />
									<SkeletonText style={{ width: '300px'}} />
								</div>
								<div className={classes.section}>
									<AccordionSkeleton
										className={classes.accordion}
										align='start'
										open={true}
										count={1}
									/>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const payments = (data && data.getMyPayments ) || [];

					const userCredits = payments.creditBalance;
					const openPayouts = payments.openPayouts;

					const paymentInformation = payments.paymentInfo;

					const payouts = payments.payouts.filter(p => p.status !== 'canceled');
					const totalGrossIncome = PAYMENTS.getTotalGrossEarnings(payouts);
					const totalNetIncome = PAYMENTS.getTotalNetEarnings(payouts);

					return (
						<div className={classes.profileContainer}>
							<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
								<BreadcrumbItem>
									<Link to="/account/settings">Account</Link>
								</BreadcrumbItem>
								<BreadcrumbItem isCurrentPage href="#">
								Payments
								</BreadcrumbItem>
							</Breadcrumb>

							<h2 className={classes.title}>Payments</h2>
							<p>Here you have your LastBasic financial history</p>

							<div className={classnames([classes.section, classes.summaryBlock])}>
								<div className={classes.summaryBox}>
									<h6>Total gross income</h6>
									<h4 className={classes.secondaryTitle}>{totalGrossIncome} €</h4>
									<div style={{marginTop: '6px'}}>
										<TooltipDefinition tooltipText="'Net income' is the amount of earnings received after subtracting taxes and other deductions from gross income">
											<small>Total net income: {totalNetIncome} €</small>
										</TooltipDefinition>
									</div>
								</div>
								<div className={classes.summaryBox}>
									<h6>Total credits</h6>
									<h4 className={classes.secondaryTitle}>{userCredits}</h4>
									<div style={{marginTop: '6px'}}>
										<TooltipDefinition tooltipText="Once you reach 400 credits you will level up and LastBasic will convert those credits into a payment of 400 euros">
											<small>Credit level: {payments.creditLevel}</small>
										</TooltipDefinition>
									</div>
								</div>
								<div className={classes.summaryBox}>
									<h6>Open invoices</h6>
									<h4 className={classes.secondaryTitle}>{openPayouts}</h4>
									<div style={{marginTop: '6px'}}>
										<small>Total invoices: {PAYMENTS.getTotalInvoices(payouts)}</small>
									</div>
								</div>
							</div>
							<div className={classes.section}>
								<ActivityTable
									data={payments}
								/>
							</div>
							<div className={classes.section}>
								<div className={classes.sectionContent}>
									<h4 className={classes.secondaryTitle}>Incentive credits</h4>
									<TooltipIcon
										tooltipText={'The incentive credit program is only applied to validated proposals sent by an expert on time.'}
										direction='right' align='start'>
										<Information16 style={{ marginLeft: '8px' }}/>
									</TooltipIcon>
								</div>
								<div className={classes.sectionContent}>
									<p>Total credits: </p>
									<h5 className={classes.creditElement}>{userCredits} credits</h5>
								</div>
								<div className={classnames([classes.sectionContent, classes.extension])}>
									<div className={classes.progressContainer}>
										{userCredits > 0 &&
											<ProgressBar lbCredits={userCredits} isPayment={true} />
										}
									</div>
								</div>
							</div>
							<div className={classes.section}>
								<Accordion align='start' className={classes.accordion}>
									<AccordionItem
										open={false}
										key='legal-information'
										title={<div style={{ display: 'flex'}}>
											<h4>Legal information</h4>
											{paymentInformation !== null
												?	<Tag type="green" style={{ marginLeft: '8px'}}>Completed</Tag>
												:	<Tag type="gray" style={{ marginLeft: '8px'}}>Pending</Tag>
											}
										</div>}>
										<div>
											<div className={classes.contentBlock}>
												<div className={classes.contentTitle}>
													{paymentInformation !== null
														?	this.displayLegalInfo(paymentInformation)
														:
														<>
															<InlineNotification
																className={classes.notification}
																kind="warning"
																lowContrast
																hideCloseButton
																iconDescription="Info"
																title="Pending information"
																subtitle={'You need to fill your legal information in order to receive payments'}
															/>
															<Button
																kind="primary"
																size='default'
																disabled={false}
																className={classes.btn}
																onClick={() => this.setState({ previewForm: true })}
															>
															Fill legal information
															</Button>
														</>
													}
												</div>
											</div>
										</div>
									</AccordionItem>
								</Accordion>
							</div>
							<PreviewFormModal
								open={!!previewForm}
								onRequestClose={() => this.setState({ previewForm: false }, () => {this.refetchPayments();})}
							/>
							<PreviewFileModal
								open={!!previewFile}
								file={previewFile}
								onRequestClose={this.onClosePreviewFile}
							/>
							<div className={classes.section}>
								<Accordion align='start' className={classes.accordion}>
									<AccordionItem
										open={false}
										key='invoices'
										title={<div style={{ display: 'flex'}}><h4>Invoices</h4>
											{openPayouts > 0 &&
												<Tag type='gray' style={{ marginLeft: '8px', backgroundColor: '#ffb400', color: '#fff'}} renderIcon={DocumentAttachment16}>
													New invoice status
												</Tag>
											}
										</div>}>
										<PreviewInvoiceModal
											open={!!openReviewInvoice}
											invoiceData={openReviewInvoice !== null && openReviewInvoice}
											onRequestSubmit={() => this.setState({ openReviewInvoice: null }, () => { refetchPayments();})}
											onSecondarySubmit={() => this.setState({ openReviewInvoice: null, previewForm: true })}
											onRequestClose={() => this.setState({ openReviewInvoice: null })}
										/>
										{paymentInformation === null
											?	<InlineNotification
												className={classes.notification}
												kind="warning"
												lowContrast
												hideCloseButton
												iconDescription="Info"
												title="Pending information"
												subtitle={'You need to fill your legal information in order to unlock invoices'}
											/>
											:	this.getInvoices(payouts)
										}
									</AccordionItem>
								</Accordion>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

Payments.defaultProps = {
	userData: {},
};

Payments.propTypes = {
	userData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(Payments);
