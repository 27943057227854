import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarAlt = (props) => {
	const { percentage, name } = props;

	const lbLimit = 100;
	const completed = ((percentage/lbLimit) * 100).toFixed();
	const completePercentage = completed > 100 ? 100 : completed;
	const minPercentage = completePercentage > 0 ? 11 : 0;
	const completeColor = '#24a148'; // completePercentage >= 100 ? '#24a148' : '#df3d3b';

	const mainBox = {
		display: 'flex',
		flexDirection: 'column',
		width: '126px'
	};

	const container = {
		width: '100%',
		backgroundColor: '#e0e0de',
		borderRadius: '16px',
		height: '8px',
		display: 'flex',
		alignItems: 'center'
	};

	const fillbar = {
		height: '100%',
		width: `${completePercentage}%`,
		minWidth: `${minPercentage}%`,
		backgroundColor: completeColor,
		borderRadius: 'inherit',
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'flex-end'
	};

	const additionalText = {
		marginBottom: '4px',
		textAlign: 'end',
		color: '#454A50',
		fontSize: '0.75rem'
	};

	return (
		<div style={mainBox}>
			<small style={additionalText}>{name}</small>
			<div style={container}>
				<div style={fillbar}></div>
			</div>
		</div>
	);
};

ProgressBarAlt.propTypes = {
	percentage: PropTypes.number,
	name: PropTypes.string,
};

ProgressBarAlt.defaultProps = {
	percentage: 0,
	name: '',
};

export default ProgressBarAlt;
