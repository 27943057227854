import React, { Component, Fragment } from 'react';

class HubspotMeetingScript extends Component {
	componentDidMount () {
		const scriptUrls = [
			'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
		];

		for (const scriptUrl of scriptUrls) {
			const script = document.createElement('script');
			script.id = 'hubspot-meeting';
			script.src = scriptUrl;
			document.getElementById('root').appendChild(script);
		}
	}

	render() {
		return (
			<Fragment>
			</Fragment>
		);
	}
}

export default HubspotMeetingScript;
