import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Tabs, Tab } from 'carbon-components-react';
import { Download20, Information16 } from '@carbon/icons-react';

import FileIcon from '../../../../../components/icons/file-icon';
import PreviewFileModal from '../../../../../components/modals/preview-file-modal';
import ProposalFeedback from '../../../../../components/project/proposal-feedback-content';

import { PROJECT, FILE } from '../../../../../constants';
import { serverAPI } from '../../../../../utils';
import KEYS from '../../../../../glossary';

import classes from './project-overview.module.scss';

class ProjectContestMaterial extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
			openModalWithProposalFeedback: false,
			feedback: null,
		};
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}
	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	renderItem(state) {
		const { project } = this.props;
		const contest = PROJECT.getContestByState(project, state);

		const rows = [];
		if (contest) {
			const { sharedFiles } = contest;

			for (const sharedFile of sharedFiles) {
				if (sharedFile.approvalStatus === FILE.APPROVAL_STATUS.APPROVED) {
					const f = sharedFile.file;
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);

					const newRow = (
						<div className={classes.elementBox} key={f.id}>
							<div className={classes.naming}>
								<FileIcon extension={f.extension} />
								<small className={classes.fileName}>
									{f.name}
								</small>
							</div>
							<div className={classes.actions}>
								<span className={classes.link} onClick={() => this.onOpenPreviewFile(f)}>{KEYS.open_file}</span>
								<a href={downloadUrl} target="_blank" rel="noopener noreferrer"><Download20 /></a>
							</div>
						</div>
					);
					rows.push(newRow);
				}
			}
		}

		return (
			<Tab
				key={'tab-' + PROJECT.stateToStringShort(state)}
				label={PROJECT.stateToStringShort(state) + ` [${rows.length}]`}
				className={classes.tab}
			>
				{rows.length > 0
					?   <div className={classes.contentBox}>{rows.map(r => r)}</div>
					:   <div className={classes.infoElementsBox}>
						<Information16 />
						<p>{KEYS.no_extra_files}</p>
					</div>
				}
			</Tab>
		);
	}

	render() {
		const { project, contestType } = this.props;
		const { previewFile, openModalWithProposalFeedback, feedback } = this.state;

		// Don't show if project finished or there are no previous contests
		const filteredContestStatesByContestType = PROJECT.getFilteredContestStatesByContestType(contestType);
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;
		if (!filteredContestStatesByContestType || projectIsFinished) return null;

		const contestStates = Object.values(filteredContestStatesByContestType);

		return (
			<div className={classes.contentBlock}>
				<div className={classes.contentTitle}>
					<h4>{KEYS.contest_materials}</h4>
					<p>{KEYS.contest_materials_text}</p>
				</div>
				<Tabs type='container'>
					{contestStates.map(s => this.renderItem(s))}
				</Tabs>
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					onRequestClose={() => this.onClosePreviewFile()}
				/>
				{openModalWithProposalFeedback && (
					<Modal
						open={openModalWithProposalFeedback}
						passiveModal={true}
						modalLabel={KEYS.proposal_feedback_modal_label}
						onRequestClose={() => this.setState({ openModalWithProposalFeedback: false, feedback: null })}
					>
						{feedback &&
							<ProposalFeedback feedback={feedback} />
						}
					</Modal>
				)}
			</div>
		);
	}
}

ProjectContestMaterial.propTypes = {
	project: PropTypes.object.isRequired,
	contestType: PropTypes.string
};

ProjectContestMaterial.defaultProps = {
	project: {},
	contestType: null
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectContestMaterial);
