import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import withRouter from '../route/with-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { TooltipIcon, Tag, TooltipDefinition } from 'carbon-components-react';
import { Locked16, Add24, MisuseOutline16 } from '@carbon/icons-react';

import ContestIcon from '../../components/project/contest-icon';
import ContestSetUpModal from '../../components/modals/contest-set-up-modal';
import ClientDeliveryAddressSetUpModal from '../../components/modals/client-delivery-address-set-up-modal';

import ErrorBoundaryQuery from '../../components/graphql/queries-with-errors';
import { GET_CLIENT_DELIVERY_ADDRESS } from '../../providers/queries';

import { PROJECT, CONTEST } from '../../constants';
import { FormUtils, strings } from '../../utils';

import classes from './phase-states-bubbles.module.scss';

class PhaseStatesBubbles extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openContestSetUpModal: null,
			setupQuestionsAnswered: false,
			openDeliveryAddressSetUpModal: null,
		};
	}

	getContestState(project, state) {
		if (!state) state = project.state;
		const phaseStatus = CONTEST.getContestPhaseState(project, state);
		const currentContest = PROJECT.getContestByState(project, state);
		const isPrototypingPhase = state === PROJECT.STATE.PROTOTYPING_CONTEST;

		return (
			<div className={classes.contestStatesBox}>
				{(phaseStatus === 'Completed' || phaseStatus === 'Open contest')
					?	<Tag type='green' className={classes.tag}>{phaseStatus === 'Completed' && isPrototypingPhase ? 'Active phase' : phaseStatus}</Tag>
					: (phaseStatus === 'Winner selection')
						?	<Tag type='green' className={classes.tag}>{isPrototypingPhase ? 'Active phase' : 'Open contest'}</Tag>
						: (!currentContest.active)
							?	<TooltipDefinition tooltipText="The phase is inactive as the project does not require it.">
								<Tag type='gray' className={classes.tag}>Inactive</Tag>
							</TooltipDefinition>
							:	(phaseStatus === 'Not open yet') &&
								<TooltipDefinition tooltipText="The contest has not been open as the card has not been approved or the contest phase has not been created yet.">
									<Tag type='gray' className={classes.tag}>{phaseStatus}</Tag>
								</TooltipDefinition>
				}
			</div>
		);
	}

	getContestStatus(project, phase) {
		const currentContest = PROJECT.getContestByState(project, phase);
		const phaseAdvancement = CONTEST.getPhaseAdvancement(currentContest, project);

		return (
			<>
				{(phase === PROJECT.getStateByContestType(currentContest.type) && phaseAdvancement === 0) &&
				<div className={classes.secondaryPhaseBubbleIcons}>
					<Locked16 style={{ fill: 'grey' }} />
				</div>
				}
				{(!currentContest.active) &&
				<div className={classes.secondaryPhaseBubbleIcons}>
					<TooltipIcon
						tooltipText="Inactive phase"
						direction='top' align='center'>
						<MisuseOutline16 style={{ fill: 'grey' }} />
					</TooltipIcon>
				</div>
				}
			</>
		);
	}

	getPhaseBubble(project, phase) {
		const { onClickPhase } = this.props;
		const currentContest = PROJECT.getContestByState(project, phase);
		const phaseAdvancement = CONTEST.getPhaseAdvancement(currentContest, project);
		const activePhase = project.state === phase ? true : false;

		return (
			<div className={classes.boxIcon} onClick={() => onClickPhase(phase)}>
				<div className={classes.phaseBubble}
					style={(phaseAdvancement !== 0 || activePhase)
						? { backgroundColor: CONTEST.getPhaseColor(phase) }
						: (!currentContest.active)
							? {backgroundColor: '#c5c5c5'} : {}}
				>
					<ContestIcon state={phase} size={30} active={activePhase} type={'white'} />
				</div>
			</div>
		);
	}

	getProgressInPhaseStatus(project, phase) {
		const { onClickPhase } = this.props;
		const currentContest = PROJECT.getContestByState(project, phase);
		const phaseAdvancement = CONTEST.getPhaseAdvancement(currentContest, project);
		const isPrototypingPhase = phase === PROJECT.STATE.PROTOTYPING_CONTEST;
		const prototypingPhaseAdvancement = CONTEST.getPrototypingPhaseAdvancement(currentContest);

		return (
			phaseAdvancement !== 0 || isPrototypingPhase
				?	<CircularProgress
					variant="determinate"
					value={isPrototypingPhase ? prototypingPhaseAdvancement : phaseAdvancement}
					color="inherit"
					className={classes.phaseBubbleProgressBar}
					style={{ color: CONTEST.getPhaseColor(phase) }}
					onClick={() => onClickPhase(phase)}
				/>
				:	<div className={classes.circle} onClick={() => onClickPhase(phase)}></div>
		);
	}

	getPhasesActiveBars(i, project, phasesOrder, projectState) {
		return (
			<Add24
				style={{ margin: '34px 0', fill: '#e1e4e8'}}
				className={classnames([
					(i < (phasesOrder.length) &&
					i < phasesOrder.indexOf(PROJECT.getContestTypeByState(projectState)) ||
					project.state === PROJECT.STATE.CLOSED) &&
					classes.activePlus])}
			/>
		);
	}

	displayContestSetUp(project, state) {
		const { setupQuestionsAnswered } = this.state;
		const isActiveContest = project.state === state;
		const activePhaseStatus = CONTEST.getContestPhaseState(project, state);
		const allPhases = PROJECT.getContestStates();
		const currentStateIndex = allPhases.indexOf(state);
		const nextContestToOpen = allPhases[currentStateIndex + 1];
		const nextPhaseStatus = CONTEST.getContestPhaseState(project, nextContestToOpen);
		const nextPhaseContest = PROJECT.getContestByState(project, nextContestToOpen);
		const isPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST;

		const setupQuestions = nextPhaseContest && nextPhaseContest.setupQuestions;
		const doesSetupQuestionsHasAnswer = setupQuestions && FormUtils.isAtLeastOneQuestionAnswered(setupQuestions);

		return (
			(nextContestToOpen &&
				activePhaseStatus === 'Completed' &&
				nextPhaseStatus === 'Not open yet' &&
				!doesSetupQuestionsHasAnswer &&
				setupQuestionsAnswered === false &&
				isActiveContest &&
				nextPhaseContest.active &&
				!!isPrototypingPhase) &&
			<div
				className={classnames([classes.contestSetUpBox, classes.shadow])}
				onClick={() => this.openContestSetUpModal(nextContestToOpen)}
			>
				<small>{strings.capitalizeFirstLetter(PROJECT.getContestTypeByState(nextContestToOpen))} contest set up</small>
			</div>
		);
	}

	displayPrototypingSetUp() {
		const { userData: {user} } = this.props;
		const { maker } = user;
		const isInventor = user && maker !== null;

		return (
			isInventor &&
			<ErrorBoundaryQuery query={GET_CLIENT_DELIVERY_ADDRESS}>
				{({ data }) => {
					const maker = (data && data.myProfileInfo.maker) || [];
					const { deliveryAddress } = maker;

					return (
						!deliveryAddress &&
						<div
							className={classnames([classes.contestSetUpBox, classes.shadow])}
							style={{ left: '-1px' }}
							onClick={() => this.openContestSetUpModal()}
						>
							<small>Prototype delivery set up</small> {/* set delivery address */}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	openContestSetUpModal() {
		this.setState({ openDeliveryAddressSetUpModal: { open: true } });
	}

	renderPhase(phaseType, phasesOrder, i) {
		const { project, selectState, displayOnClient, switcher } = this.props;
		const phase = PROJECT.getStateByContestType(phaseType);
		const isPrototypingPhase = phase === PROJECT.STATE.PROTOTYPING_CONTEST;
		const activePhase = project.state === phase ? true : false;

		return (
			<Fragment key={i}>
				<div className={classes.progressContainer} style={(displayOnClient ? {height: '148px', alignItems: 'center'} : {height: '132px'})}>
					<div className={classes.stateCircleContainer}>
						<div className={classes.bubblePhase}>
							{this.getProgressInPhaseStatus(project, phase)}
							{this.getPhaseBubble(project, phase, switcher)}
							{selectState === phase && this.getContestStatus(project, phase)}
						</div>
					</div>
					<div className={classnames([displayOnClient && classes.phaseStateName])}
						style={(selectState === phase && displayOnClient)
							?	{paddingTop: '8px', textAlign: 'center', border: '1px #e1e4e8 solid', borderBottomStyle: 'none'}
							:	(selectState === phase && !displayOnClient)
								?	{paddingTop: '8px', textAlign: 'center'}
								:	{}
						}>
						<small style={{ textTransform: 'uppercase'}}
							className={classnames([classes.phaseStep,
								((selectState === phase) && classes.active)])}>
							{selectState === phase && PROJECT.getContestTypeByState(phase)}
						</small>
						{(activePhase) && this.displayPrototypingSetUp()}
						{displayOnClient && (selectState === phase
							?	this.getContestState(project, phase)
							:	(!isPrototypingPhase) &&
								this.displayContestSetUp(project, phase)
						)}
					</div>
				</div>
				{phase !== PROJECT.STATE.PROTOTYPING_CONTEST &&
					this.getPhasesActiveBars(i, project, phasesOrder, project.state)
				}
			</Fragment>
		);
	}

	render() {
		const { project, displayOnClient } = this.props;
		const { openContestSetUpModal, openDeliveryAddressSetUpModal } = this.state;
		const phases = PROJECT.getContestStates();
		const phasesOrder = Array.isArray(project.contestOrder) ? project.contestOrder : phases;

		return (
			<>
				<div className={classes.stateContainer}>
					{phasesOrder.map((phase, i) => (
						this.renderPhase(phase, phasesOrder, i)
					))}
				</div>
				{displayOnClient &&
					<div className={classes.lineBottom}></div>
				}
				{(openContestSetUpModal && openContestSetUpModal.open) &&
					<ContestSetUpModal
						open={!!openContestSetUpModal}
						project={project}
						nextContestToOpen={openContestSetUpModal && openContestSetUpModal.nextContestToOpen}
						onRequestSubmit={() => this.setState({ openContestSetUpModal: null, setupQuestionsAnswered: true })}
						onRequestClose={() => this.setState({ openContestSetUpModal: null })}
					/>
				}
				{(openDeliveryAddressSetUpModal?.open) &&
					<ClientDeliveryAddressSetUpModal
						open={!!openDeliveryAddressSetUpModal}
						project={project}
						onRequestSubmit={() => this.setState({ openDeliveryAddressSetUpModal: null, setupQuestionsAnswered: true })}
						onRequestClose={() => this.setState({ openDeliveryAddressSetUpModal: null })}
					/>
				}
			</>
		);
	}
}

PhaseStatesBubbles.propTypes = {
	project: PropTypes.object,
	state: PropTypes.string.isRequired,
	selectState: PropTypes.string,
	displayOnClient: PropTypes.bool,
	switcher: PropTypes.bool,
	onClickPhase: PropTypes.func,
	userData: PropTypes.object,
};

PhaseStatesBubbles.defaultProps = {
	project: {},
	state: null,
	selectState: null,
	displayOnClient: false,
	switcher: false,
	onClickPhase() {},
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(PhaseStatesBubbles));
