import React, { Component } from 'react';

import AbstractRoute from './abstract-route';

class PrivateRoute extends Component {
	render() {
		return (
			<AbstractRoute isPrivate={true} redirectTo={'/login'} />
		);
	}
}

export default PrivateRoute;
