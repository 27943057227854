import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Locked16, CheckmarkFilled16, TrophyFilled32 } from '@carbon/icons-react';
import PreviewFileModal from '../../../../../components/modals/preview-file-modal';

import { PROJECT, CONTEST } from '../../../../../constants';
import { serverAPI } from '../../../../../utils';
import KEYS from '../../../../../glossary';

import classes from './tabs.module.scss';

class InspirationTab extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
		};
	}

	onOpenPreviewFile(file, url) {
		this.setState({ previewFile: { src: url } });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	displayProposalByPhase(project, phaseType, i) {
		const phase = PROJECT.getStateByContestType(phaseType);
		const contest = PROJECT.getContestByState(project, phase);
		const contestHasEndedAndHasAWinningProposal = contest && (CONTEST.isContestEnded(contest) && CONTEST.hasWinner(contest));

		return (
			<div className={classes.contentBlock} key={i}>
				<div className={classes.contentTitle}>
					<div style={{ display: 'flex', alignItems: 'center'}}>
						{contestHasEndedAndHasAWinningProposal
							?	<CheckmarkFilled16 style={{ fill: '#24a148', marginRight: '8px' }} />
							:	<Locked16 style={{ fill: 'grey', marginRight: '8px' }} />
						}
						<h4>{PROJECT.stateToStringShort(phase)}</h4>
					</div>
				</div>
				{this.renderItem(phase)}
			</div>
		);
	}

	renderItem(state) {
		const { project, onClickProposal } = this.props;
		const contest = PROJECT.getContestByState(project, state);
		const contestHasEndedAndHasAWinningProposal = contest && (CONTEST.isContestEnded(contest) && CONTEST.hasWinner(contest));

		return (
			<div className={classes.overviewCategoryBox}>
				{contestHasEndedAndHasAWinningProposal &&
					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						{contest.proposals.map((proposal, i) => (
							this.renderProposal(proposal, i, contest, onClickProposal)
						))}
					</div>
				}
			</div>
		);
	}

	renderProposal(proposal, i, contest, onClickProposal) {
		const { feedback } = proposal;
		const isWinner = CONTEST.isWinningProposal(contest, proposal);

		for (const proposalFile of proposal.files) {
			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			if (!approvedAndPublished) continue;

			const { file } = fileVersion;

			return (
				<div className={classes.proposalContainer} key={i}
					onClick={() => onClickProposal(proposal, file, feedback)}>
					<img
						className={classes.thumbnailImg}
						src={this.getProposalThumbnailUrl(proposal)}
						alt={KEYS.proposal_overview_alt}
					/>
					{isWinner && (
						<div className={classes.winnerContainer}>
							<TrophyFilled32 style={{ fill: '#ffc637' }} />
						</div>
					)}
				</div>
			);
		}
	}

	getProposalThumbnailUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	render() {
		const { project } = this.props;
		const { previewFile } = this.state;

		const phases = PROJECT.getContestStates();
		const phasesOrder = Array.isArray(project.contestOrder) ? project.contestOrder : phases;

		return (
			<div className={classes.tabContentContainer}>
				<h3 className={classes.title}>Get inspired by other phase proposals</h3>
				<div className={classes.materialsContent}>
					{phasesOrder.map((phase, i) => (
						this.displayProposalByPhase(project, phase, i)
					))}
					<PreviewFileModal
						open={!!previewFile}
						src={previewFile && previewFile.src}
						onRequestClose={() => this.onClosePreviewFile()}
					/>
				</div>
			</div>
		);
	}
}

InspirationTab.defaultProps = {
	project: {},
	user: {},
	userData: {},
	onClickProposal() {},
};

InspirationTab.propTypes = {
	project: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	onClickProposal: PropTypes.func,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(InspirationTab);