import { Mutation } from '@apollo/client/react/components';
import { Locked16 } from '@carbon/icons-react';
import { Tag, TooltipDefinition, TooltipIcon } from 'carbon-components-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Heart from '../../components/icons/heart';
import ErrorBoundaryQuery from '../../components/graphql/queries-with-errors';
import { PROJECT, CONTEST, EXPERT } from '../../constants';
import KEYS from '../../glossary';
import { GET_PROJECT_CONTESTS } from '../../providers/queries';
import { ADD_PROJECT_TO_FAVORITES } from '../../providers/mutations';
import { serverAPI, strings, userInfo } from '../../utils';

import classes from './project-element.module.scss';

const DEFAULT_PROJECT_IMG_URL = '/assets/images/logo-lb-project.png';

class ProjectElement extends Component {
	constructor (props) {
		super(props);

		this.state = {
			userInfoData: userInfo.getUserInfo(),
		};
	}
	
	onFavClick = async (value, addProjectToFavoritesMutation) => {
		const { project } = this.props;
		let isFavorite = value;

		try {
			await addProjectToFavoritesMutation({
				variables: {
					id: project.id,
					value: isFavorite,
				}
			});
		} catch (err) {
			isFavorite = !isFavorite;
		}

		this.setState({
			project: { ...project, isFavorite, }
		});
	};

	displayUrlLink(image) {
		return serverAPI.getThumbnailUrlByFile(image);
	}

	displaySummaryInfo(project) {
		return (
			<ErrorBoundaryQuery query={GET_PROJECT_CONTESTS} variables={{projectId: project.id}}>
				{({ loading, error, data }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.contests}>
								<p>Loading...</p>
							</div>
						</div>
					);

					// No data provided
					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const contests = (result && result.contests) || [];
					const projectContributors = PROJECT.getProjectContributors(contests) || ['Co', 'Co', 'Co', 'Co'];

					return (
						<div className={classes.iconTags}>
							<h6 className={classes.title}>Project contributors</h6>
							<div className={classes.contributorsBlock}>
								{projectContributors.length > 0 &&
									<>
										{projectContributors.slice(-3).map((contributor, i) => (
											<div className={classes.contributor} key={i}>
												{contributor}
											</div>
										))}
										{projectContributors.length > 3 &&
											<div className={classes.contributor}>
												+{projectContributors.length - 3}
											</div>
										}
									</>
								}
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayPhases(project, user) {
		return (
			<ErrorBoundaryQuery query={GET_PROJECT_CONTESTS} variables={{projectId: project.id}}>
				{({ loading, error, data }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.contests}>
								<p>Loading...</p>
							</div>
						</div>
					);

					// No data provided
					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const contests = (result && result.contests) || [];

					const phases = PROJECT.getContestStates();
					const phasesOrder = Array.isArray(project.contestOrder) ? project.contestOrder : phases;

					return (
						<div className={classes.phasesContainer}>
							{phasesOrder.map((phase, i) => (
								this.getStepsByPhase(contests, project, phase, phasesOrder.indexOf(project.state), i, user.id)
							))}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	getStepsByPhase(contests, project, phase, index, i, userId) {
		const phaseState = PROJECT.getStateByContestType(phase);
		const currentPhase = project.state === phaseState;
		const phaseContest = contests.filter((c) => c.type === phase);
		const phaseIsDesactive = contests.filter((c) => c.active === false);
		const phaseIsInOrder = index >= 0;
		const phaseHasWinner = phaseContest && !!phaseContest[0].selectedProposalId;
		const userHasParticipated = EXPERT.isAParticipantExpert(project, userId);

		return (
			<div key={i}
				className={classnames([classes.phaseBubble])}
				style={currentPhase
					? {backgroundColor: CONTEST.getPhaseColor(phaseState), width: '156px', zIndex: '2'}
					: (phaseHasWinner || userHasParticipated)
						? {backgroundColor: CONTEST.getPhaseColor(phaseState)}
						: (phaseIsDesactive.length > 0 || phaseIsInOrder)
							? {backgroundColor: '#c5c5c5', color: CONTEST.getPhaseColor(phaseState), border: `1px solid ${CONTEST.getPhaseColor(phaseState)}`}
							: {border: '1px solid #fff'}
				}
			>
				{phaseState === PROJECT.STATE.MODEL_CONTEST
					?	currentPhase
						?	'3D Modeling'
						:	'3D'
					:	currentPhase
						?	strings.capitalizeFirstLetter(PROJECT.getContestTypeByState(phaseState))
						:	phaseState.substring(0, 2).toUpperCase()
				}
			</div>
		);
	}

	render() {
		const { project, userData: { user } } = this.props;
		const { userInfoData } = this.state;
		const userHasSignedNDA = userInfoData && userInfoData.hasSignedNDA;

		const projectCode = '11-' + project.id.substr(project.id.length - 3).toUpperCase();
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;

		const projectDescription = `${strings.capitalizeFirstLetter(project.productNeed)}. ${strings.capitalizeFirstLetter(project.productSolution)}`;

		return (
			<div className={classes.projectCard} onClick={() => window.Froged('track', 'click_project_card')}>
				<Link to={'/project/' + project.id} className={classes.projectLink}>
					<div className={classes.imageContainer}>
						<img
							className={classes.cardImage}
							alt={KEYS.img_alt}
							src={project.image ? this.displayUrlLink(project.image) : DEFAULT_PROJECT_IMG_URL}
						/>
					</div>
					<div className={classes.content}>
						<div className={classes.titleAndButtons}>
							<div className={classes.titleContainer}>
								{(project.name.length > 20) ? (
									<TooltipDefinition tooltipText={strings.capitalizeFirstLetter(project.name)} direction="top">
										<h4>{strings.capitalizeFirstLetter(project.name.substring(0, 20)) + '...'}</h4>
									</TooltipDefinition>
								) : (
									<h4>{strings.capitalizeFirstLetter(project.name)}</h4>
								)}
								<span className={classes.subtitle}>
									{'Project ' + (project.code ? project.code : projectCode)}
								</span>
							</div>
							<div className={classes.favBtn}>
								<Mutation mutation={ADD_PROJECT_TO_FAVORITES}>
									{(addProjectToFavorites) => (
										<Heart
											active={project.isFavorite}
											onClick={(e, v) => this.onFavClick(v, addProjectToFavorites)}
										/>
									)}
								</Mutation>
							</div>
						</div>
						{projectIsFinished ? (
							<div style={{position: 'relative', margin: '8px 0 2px 0'}}>
								<img
									src={'/assets/images/gradient-phases.png'}
									className={classes.gradientPhases}
								/>
								<div className={classes.finishedTag}>{KEYS.finished}</div>
							</div>
						) : (
							this.displayPhases(project, user)
						)}
						<div className={classes.infoContainer}>
							<p className={classnames(classes.description, 'bx--text-truncate--end')}
								style={!userHasSignedNDA
									? { color: 'transparent', textShadow: '0 0 5px rgba(0,0,0,0.5)', position: 'relative'}
									: {}}
							>
								{projectDescription}
								{!userHasSignedNDA &&
									<TooltipIcon
										tooltipText={'Sign NDA to unlock more project’s info'}
										direction="bottom"
										style={{position: 'absolute', top: '1rem', right: '1rem', width: '16px'}}
									>
										<div className={classes.circleLocked}>
											<Locked16 />
										</div>
									</TooltipIcon>
								}
							</p>
							{projectIsFinished ? (
								this.displaySummaryInfo(project)
							) : (
								<div className={classes.iconTags}>
									<h6 className={classes.title}>Main features</h6>
									{project.prototypeSize &&
										<Tag type="cool-gray" title={PROJECT.getSizeToString(project.prototypeSize)}>
											{PROJECT.getSizeToString(project.prototypeSize)}
										</Tag>
									}
									{project.hasIOT && <Tag type="cool-gray" title={KEYS.has_iot}>{KEYS.has_iot}</Tag>}
									{project.hasActuators && <Tag type="cool-gray" title={KEYS.has_actuators}>{KEYS.has_actuators}</Tag>}
									{project.electronics && <Tag type="cool-gray" title={KEYS.electronics}>{KEYS.electronics}</Tag>}
									{project.productEnvironment &&
										<Tag type="cool-gray" title={PROJECT.getEnvironmentToString(project.productEnvironment)}>
											{PROJECT.getEnvironmentToString(project.productEnvironment)}
										</Tag>
									}
								</div>
							)}
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

ProjectElement.propTypes = {
	project: PropTypes.object.isRequired,
	userData: PropTypes.object,
};

ProjectElement.defaultProps = {
	project: {},
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectElement);
