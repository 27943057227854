import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
	Activity32,
	ChevronRight24,
	DocumentAdd32,
	Calendar32,
	Purchase32,
	ChatLaunch32,
} from '@carbon/icons-react';

import SalesCallModal from './../modals/sales-call-modal';

import classes from './card-action.module.scss';

class CardAction extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openWidget: false,
		};
	}

	startProject() {
		window.open('/new/project', '_self');
	}

	setCall() {
		this.setState({ openWidget: true });
	}

	completePayment(invoiceUrl) {
		window.open(invoiceUrl, '_blank');
	}

	render() {
		const { type, title, action, setupInvoice } = this.props;
		// type: recommended or optional
		// title: string
		// action: call, new project, other, chat
		const { openWidget } = this.state;

		return (
			<>
				<div className={classnames([classes.box, classes.blockBox, type === 'recommended' && classes.isMain])}
					onClick={action === 'new-project'
						? () => this.startProject()
						: (action === 'set-call')
							? () => this.setCall()
							: (action === 'payment' && setupInvoice)
								? () => this.completePayment(setupInvoice.url)
								: (action === 'open-chat')
									? () => window.Froged('open', 'inbox')
									: {}
					}
				>
					<div style={{ display: 'flex' }}>
						<div className={classes.icon}>
							{(action === 'new-project')
								? <DocumentAdd32 className={classes.bigIcon} />
								: (action === 'set-call')
									? <Calendar32 className={classes.bigIcon} />
									: (action === 'payment' && setupInvoice)
										? <Purchase32 className={classes.bigIcon} />
										: (action === 'open-chat')
											? <ChatLaunch32 className={classes.bigIcon} />
											: <Activity32 className={classes.bigIcon} />
							}
						</div>
						<div className={classes.contentBlock} >
							<small style={{ textTransform: 'capitalize'}}>{type}</small>
							<h4 style={{ color: '#dd0031'}}>{title}</h4>
						</div>
					</div>
					<div className={classes.chevronRight}>
						<ChevronRight24 className={classes.goArrow} />
					</div>
				</div>
				{openWidget && (
					<SalesCallModal
						open={openWidget}
						onRequestClose={() => this.setState({ openWidget: false })}
					/>
				)}
			</>
		);
	}
}

CardAction.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	action: PropTypes.string,
	setupInvoice: PropTypes.object,
};

CardAction.defaultProps = {
	type: 'optional',
	title: '',
	action: 'other',
	setupInvoice: null,
};

export default CardAction;