import { gql } from '@apollo/client';
import { contestFragment } from '../fragments/contest';
import { basicUserFragment } from '../fragments/user';

const setupInvoiceFragment = gql`
	fragment SetupInvoiceAmount on SetupInvoiceAmount {
		amountInCents,
		currency,
	}
	fragment SetupInvoice on SetupInvoice {
		id,
		amount {
			...SetupInvoiceAmount
		},
		description,
		status,
		url,
		paidAt,
	}
`;

const projectFragment = gql`
	${contestFragment}
	${setupInvoiceFragment}

	fragment ProjectDetails on Project {
		id,
		owner {
			...UserDetails
		},
		image {
			...FileDetails
		},
		code,
		name,
		state,
		states {
			...ProjectStates
		},
		isFavorite,
		rawMaterials {
			...FileDetails
		},
		contests {
			...Contest
		},
		contestOrder,
		electronics,
		productControl,
		productPower,
		complexity,
		complexityEe,
		complexityMe,
		prototypeSize,
		prototypeMaterial,
		productNeed,
		productSolution,
		competition,
		competitionExample,
		hasIOT,
		hidden,
		hasActuators,
		productAppearance,
		productSketch,
		productEnvironment,
		designDetails,
		readyToPay,
		score,
		feasibility,
		feasibilityReason,
		paid,
		hasPaidReservation,
		briefingCompletionPercentaje,
		estimatedPrice,
		setupInvoice {
			...SetupInvoice
		},
		createdAt,
		updatedAt,
		editions {
			...Editions
		}
	},
	fragment ProjectStates on ProjectStates {
		client_onboarding {
			...ProjectState
		},
		project_set_up {
			...ProjectState
		},
		sketch_contest {
			...ProjectState
		},
		model_contest {
			...ProjectState
		},
		electrical_contest {
			...ProjectState
		},
		mechanical_contest {
			...ProjectState
		},
		prototyping_contest {
			...ProjectState
		},
	},
	fragment ProjectState on ProjectState {
		setupQuestions {
			...SetupQuestion
		},
		startDate,
		endDate,
	}
	fragment SetupQuestion on SetupQuestion {
		id,
		type,
		question,
		options,
		answer,
		minSelectable,
		maxSelectable
	}
	fragment Editions on Editions {
		feasibility {
			...FeasibilityEdition
		}
	}
	fragment FeasibilityEdition on FeasibilityEdition {
		originalValue,
		updatedAt
	}
`;

const projectShortFragment = gql`
	fragment ProjectShort on Project {
		id,
		name
	}
`;

const projectBasicFragment = gql`
	${contestFragment}

	fragment ProjectBasic on Project {
		id,
		image {
			...FileDetails
		},
		name,
		state,
		contests {
			...Contest
		},
		feasibility,
		paid,
		briefingCompletionPercentaje
	}
`;

const projectUpdatesFragment = gql`
	fragment ProjectUpdates on Project {
		id,
		name,
		updates {
			...UpdateDetails
		}
	}
	fragment UpdateDetails on Update {
		type,
		details,
		createdAt
	}
`;

const projectInvoiceFragment = gql`
	${setupInvoiceFragment}

	fragment ProjectInvoice on Project {
		id,
		setupInvoice {
			...SetupInvoice
		},
	}
`;

const projectSetupQuestionsFragment = gql`
	${contestFragment}

	fragment SetupQuestion on SetupQuestion {
		id,
		type,
		question,
		options,
		answer,
		minSelectable,
		maxSelectable
	}
	fragment ProjectSetupQuestionState on ProjectState {
		setupQuestions {
			...SetupQuestion
		}
	}
	fragment ProjectSetupQuestionStates on ProjectStates {
		client_onboarding {
			...ProjectSetupQuestionState
		}
	}
	fragment ProjectSetupQuestions on Project {
		id,
		states {
			...ProjectSetupQuestionStates
		},
		contests {
			...Contest
		}
	}
`;

const projectFilesFragment = gql`
	${contestFragment}

	fragment ProjectFiles on Project {
		id,
		name,
		state,
		contests {
			...Contest
		}
	}
`;

const projectFeasibilityFragment = gql`
	${contestFragment}

	fragment ProjectFeasibility on Project {
		id,
		name,
		feasibility,
		paid,
	}
`;

const projectContestsFragment = gql`
	${basicUserFragment}

	fragment ProjectContests on RankingContest {
		id,
		type,
		selectedProposalId,
		proposals {
			...ContestProposal
		},
		active
	}
	fragment ContestProposal on RankingProposal {
		id,
		number,
		user {
			...BasicUserDetails
		},
	}
`;

const projectInfoFragment = gql`
	${contestFragment}

	fragment ProjectInfoDetails on Project {
		id,
		code,
		image {
			...FileDetails
		},
		name,
		contestOrder,
		productNeed,
		productSolution,
		contests {
			...Contest
		},
	}
`;

const projectBriefingFragment = gql`
	fragment ProjectBriefing on ProjectBriefing {
		fields
	}
`;

export {
	projectFragment,
	projectShortFragment,
	projectBasicFragment,
	projectUpdatesFragment,
	projectSetupQuestionsFragment,
	projectFilesFragment,
	projectInvoiceFragment,
	projectFeasibilityFragment,
	projectContestsFragment,
	projectInfoFragment,
	projectBriefingFragment,
};
