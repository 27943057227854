import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkmark16, Chat16, Report16, ArrowRight16, Locked16 } from '@carbon/icons-react';
import { Button, Tag, Tabs, Tab, Accordion, AccordionItem, TooltipDefinition, InlineNotification } from 'carbon-components-react';

import { ADDON } from '../../constants';
import { strings } from '../../utils';
import ImageSwiper from '../elements/image-swiper';
import ReactCountryFlag from 'react-country-flag';

import classes from './add-on.module.scss';

const DEFAULT_ADDON_IMG_URL = '/assets/images/lb-addon.png';

class AddOn extends Component {
	constructor (props) {
		super(props);

		this.state = {
			selectedTab: 0,
			viewMore: false,
		};
	}

	displayOptionContent(option) {
		const { onClick, platforms } = this.props;
		const isBudget = option.price === 'budget';
		const hasAnAsianPlatform = platforms && platforms.filter(p => (p === 'wadiz' || p === 'makuake')).length > 0;

		return (
			<Fragment>
				<div className={classnames([classes.paddingMd, classes.kpiContainer])}>
					<div>
						<div className={classes.flexCenter}>
							<Chat16 className={classes.icon} style={{ color: '#454A50' }} />
							<span className={classes.kpiText}>{option.sessions}</span>
						</div>
						<div className={classes.flexCenter}>
							<Report16 className={classes.icon} style={{ color: '#454A50' }} />
							<span className={classes.kpiText}>{option.extra}</span>
						</div>
					</div>
					<div
						className={classnames([classes.flexColumn, classes.flexEnd])}
						style={isBudget ? {flexDirection: 'column-reverse'} : {}}
					>
						<h3>
							{isBudget ? 'Budget' : `$${option.price}`}
						</h3>
						<small>
							{isBudget ? (
								'Subject to'
							) : (option.includeVAT) ? (
								'VAT included'
							) : (
								'VAT excluded'
							)}
						</small>
					</div>
				</div>
				<div>
					<span className={classes.subtitle}>Main features</span>
					<div style={{margin: '16px 8px'}}>
						{option.features.map((feature, i) => (
							<div className={classes.flexCenter} key={i}>
								<Checkmark16 className={classes.icon} style={{ color: '#24a148' }} />
								<small style={{width: '-webkit-fill-available'}}>{feature}</small>
							</div>
						))}
					</div>
				</div>
				{hasAnAsianPlatform &&
					<InlineNotification
						className={classes.notification}
						style={{ marginTop: '0'}}
						kind="warning"
						lowContrast
						hideCloseButton={true}
						title=""
						subtitle="May require safety certification for launching a campaign. Contact us for your specific case."
					/>
				}
				<div className={classes.bottomBlock}>
					<Button
						className={classes.btn}
						onClick={() => onClick({option})}
						kind='secondary'
						size='md'
						renderIcon={ArrowRight16}
					>
						Continue with {option.plan} package
					</Button>
				</div>
			</Fragment>
		);
	}

	render() {
		const { title, provider, logo, type, description, images, options, active, market } = this.props; // platforms
		const { selectedTab, viewMore } = this.state;

		if (!active) return null;

		const lowestPrice = ADDON.getLowestPackagePrice(options);

		return (
			<div className={classes.addon + (!active ? ' ' + classes.disabledAddon : '')}>
				<div className={classes.paddingMd} style={{ position: 'relative' }}>
					{active ? (
						<img src={logo ? logo : DEFAULT_ADDON_IMG_URL} className={classes.logo} />
					) : (
						<Locked16 className={classes.logo} style={{ fill: '#e1e4e8', padding: '8px', border: '2px dashed #f5f5f5' }} />
					)}
					{market.length > 0 &&
						market.map((m, i) => (
							<ReactCountryFlag
								key={i}
								svg
								className={classes.flag}
								style={i > 0 && {right : '4.2rem'}}
								countryCode={m}
								aria-label={m}
							/>
						))
					}
					<div className={classes.flexEnd}>
						<Tag type='cool-gray' className={classes.tag}>{type && active ? type : 'Add-on'}</Tag>
					</div>
					<div className={classes.flexColumn}>
						{(active && title.length > 21 && !viewMore) ? (
							<TooltipDefinition tooltipText={strings.capitalizeFirstLetter(title)} direction="bottom">
								<h3>{strings.capitalizeFirstLetter(title.substring(0, 18)) + '...'}</h3>
							</TooltipDefinition>
						) : (active && title.length <= 21 || viewMore) ? (
							<h3>{title}</h3>
						) : (
							<h3>{<div className={classes.placeholder} />}</h3>
						)}
						<small>
							By {active ? provider : <div className={classnames(classes.placeholder, classes.provider)} />}
						</small>
					</div>
				</div>
				<div className={classes.content}>
					<div style={{ position: 'relative' }}>
						{active && (
							<Fragment>
								{/* Exclusive use for crowdfunding mkt upsale */}
								{/* {platforms.length > 0 &&
									platforms.map((platSrc, i) => (
										<div key={i} className={classes.platform}>
											<img src={platSrc ? platSrc : DEFAULT_ADDON_IMG_URL} />
										</div>
									))
								} */}
								{(images.length > 1) ? (
									<div className={classes.swiperContainer}>
										<ImageSwiper
											autoplay={true}
											urlLinks={images}
										/>
									</div>
								) : (images.length === 1) ? (
									<img
										src={images ? images : DEFAULT_ADDON_IMG_URL}
										className={classes.img}
									/>
								) : (images.length === 0) && (
									<img
										src={DEFAULT_ADDON_IMG_URL}
										className={classnames(classes.img, classes.disabledImg)}
									/>
								)}
								<div className={classes.textSelected}>
									<h5>Featured</h5>
								</div>
							</Fragment>
						)}
					</div>
					<div className={classes.paddingMd}>
						<p>
							{active ? (
								(description.length > 86 && !viewMore) ? (
									description.substring(0, 80) + '...'
								) : (
									description
								)
							) : (
								<div className={classnames(classes.placeholder, classes.description)} />
							)}
						</p>
						{(active && options.length > 0) ? (
							<div className={classes.accordionContainer}>
								<Accordion>
									<AccordionItem
										className="toggleAddonContent"
										title={viewMore ? 'Hide details' : 'View more details'}
										onHeadingClick={() => this.setState({ viewMore: !viewMore })}
									>
										<Tabs
											className={classes.tabs}
											selected={selectedTab}
											scrollIntoView={true}
										>
											{options.map((option, i) => (
												<Tab
													key={i}
													id={option.name}
													onClick={() => this.setState({ selectedTab: i})}
													label={selectedTab === i ? <h5>{option.plan}</h5> : <p>{option.plan}</p>}>
													{this.displayOptionContent(option)}
												</Tab>
											))}
										</Tabs>
									</AccordionItem>
								</Accordion>
								{(!viewMore && (lowestPrice !== Infinity)) &&
									<div className={classes.priceBlock}>
										<small>From</small>
										<h4>$ {lowestPrice}</h4>
									</div>
								}
							</div>
						) : (active && (
							<div className={classes.bottomBlock} style={{ marginTop: '16px'}}>
								<Button
									className={classes.altBtn}
									onClick={''}
									kind='secondary'
									size='md'
								>
									Request add-on
								</Button>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

AddOn.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	provider: PropTypes.string,
	logo: PropTypes.string,
	price: PropTypes.string,
	options: PropTypes.array,
	market: PropTypes.array,
	platforms: PropTypes.array,
	onClick: PropTypes.func,
	images: PropTypes.array,
	type: PropTypes.string,
	active: PropTypes.bool,
	includeVAT: PropTypes.bool,
};

AddOn.defaultProps = {
	title: '',
	description: '',
	provider: '',
	logo: '',
	price: '',
	options: [],
	market: [],
	platforms: [],
	onClick() {},
	images: [],
	type: '',
	active: false,
	includeVAT: true,
};

export default AddOn;
