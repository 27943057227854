import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
	Breadcrumb,
	BreadcrumbItem,
	Select,
	SelectItem,
	SkeletonText,
	InlineNotification,
} from 'carbon-components-react';
import { Checkmark16 } from '@carbon/icons-react';
import VerifiedIcon from '@mui/icons-material/Verified';

import { GET_OWNED_PROJECTS } from '../../../providers/queries';
import { PROJECT } from '../../../constants';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';

import classes from './marketplace.module.scss';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

const DEFAULT_SERVICE_IMG_URL = '/assets/images/lb-addon.png';

class ServiceDetails extends Component {
	constructor(props) {
		super(props);

		const firstOptionDefault = this.props.service.options[0] || null;

		this.state = {
			selectedPackage: firstOptionDefault,
			selectedProject: null,
		};
	}

	componentDidMount() {
		const { service } = this.props;
		document.title = `${service.title} - LastBasic`;
	}

	render() {
		const { service, onClose } = this.props;

		return (
			<div className={classes.marketplaceContainer}>
				<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
					<BreadcrumbItem onClick={onClose}>
						<Link to="/marketplace">Marketplace</Link>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage href="#">
						{service.title}
					</BreadcrumbItem>
				</Breadcrumb>
				<div className={classes.serviceTopBlock}>
					{this.renderService(service)}
					<div style={{height: '40px'}}>
						<Button
							variant={'contained'}
							style={{width: '100%', textTransform: 'none', fontWeight: '600'}}
							onClick={() => this.scrollToPackageOptions()}
						>
							Request service
						</Button>
					</div>
				</div>
				{this.displayContent(service)}
				{this.displayPackageOptions(service)}
			</div>
		);
	}

	renderService(service) {
		return (
			<div className={classes.titleService}>
				<img
					src={service.logo || DEFAULT_SERVICE_IMG_URL}
					className={classes.logoImg}
				/>
				<div>
					<h3>{service.title}</h3>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<p style={{color: '#6b6b6b'}}>By {service.provider}</p>
						{service.curatedByLB &&
							<VerifiedIcon sx={{ width: '16px', height: '16px'}} style={{fill: '#df3d3b', marginLeft: '8px'}} />
						}
					</div>
				</div>
			</div>
		);	
	}

	displayContent(service) {
		return (
			<div className={classes.contentBlock}>
				<div className={classes.leftSide}>
					<h4 className={classnames(classes.secondaryTitle, classes.titleSpace)}>Description</h4>
					<p>{service.description}</p>
					<div className={classes.horizontalDivider}></div>
					<h4 className={classnames(classes.secondaryTitle, classes.titleSpace)}>Images</h4>
					<div className={classes.filterList}>
						{service.images.map((image, i) => (
							<img
								key={i}
								src={image}
								alt='Service'
								className={classes.serviceImg}
							/>
						))}
					</div>
				</div>
				<div className={classes.rightSide}>
					<div>
						<h5 style={{marginBottom: '16px'}}>Categories</h5>
						<span className={classes.tag}>{service.type}</span>
					</div>
					{service.curatedByLB &&
						<div style={{margin: '36px 0'}}>
							<div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
								<VerifiedIcon sx={{ width: '16px', height: '16px'}} style={{fill: '#df3d3b', marginRight: '8px'}} />
								<h5>Verified provider</h5>
							</div>
							<small style={{color: '#6b6b6b'}}>LastBasic has verified that this provider is legitimate for this service.</small>
						</div>
					}
				</div>
			</div>
		);
	}

	displayPackageOptions(service) {
		const { selectedPackage } = this.state;
		const options = service.options;

		return (
			<div id='package-options' className={classes.packageOptionsBlock}>
				<h4 className={classnames(classes.secondaryTitle, classes.titleSpace)}>Packages options</h4>
				<div className={classes.optionContent}>
					<div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
						{options.map((option, i) => (
							<div
								key={i}
								className={classnames(classes.optionSelector, selectedPackage && selectedPackage.plan === option.plan && classes.activeOption)}
								onClick={() => this.setState({ selectedPackage: option })}
							>
								<div>
									<div style={{display: 'flex', alignItems: 'start'}}>
										<h4 className={classes.titleSpace}>{option.plan}</h4>
										{option.recommended &&
											<Chip
												label={'Recommended'}
												variant={'outlined'}
												size='small'
												style={selectedPackage && selectedPackage.plan === option.plan ? {marginLeft: '8px', height: '16px', color: '#fff'} : {marginLeft: '8px', height: '16px'}}
											/>
										}
									</div>
									<small>{option.sessions} {option.extra && `+ ${option.extra}`}</small>
								</div>
								<h5 style={{ width: '80px', textAlign: 'end'}}>{option.price !== 'budget' && '$'}{option.price}</h5>
							</div>
						))}
					</div>
					{this.displayOptionContent(service)}
				</div>
			</div>
		);
	}

	displayOptionContent(service) {
		const { selectedPackage } = this.state;
		if (selectedPackage === null) return false;
		const isAsian = service && service.isAsian;

		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<div className={classes.packageDetailBox}>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<div>
							<span className={classes.label}>{service.provider}</span>
							<h4>{selectedPackage.plan} package</h4>
						</div>
						<img
							src={service.logo || DEFAULT_SERVICE_IMG_URL}
							style={{ width: '48px', height: '48px', borderRadius: '50%', objectFit: 'cover', border: '1px solid #ddd'}}
						/>
					</div>
					<div style={{margin: '16px 8px'}}>
						{selectedPackage &&
						selectedPackage.features.map((feature, i) => (
							<div className={classes.flexCenter} style={{margin: '8px 0'}} key={i}>
								<Checkmark16 className={classes.icon} style={{ color: '#24a148' }} />
								<small style={{width: '-webkit-fill-available'}}>{feature}</small>
							</div>
						))}
					</div>
					{isAsian &&
						<Alert
							severity="warning"
							style={{margin: '16px 0', width: 'fit-content'}}
						>
							May require safety certification for launching a campaign. Contact us for your specific case.
						</Alert>
					}
					<div className={classes.horizontalDivider} style={{margin: '16px 0'}}></div>
					<div style={selectedPackage.price === 'budget' ? {display: 'flex', flexDirection: 'column-reverse', alignItems: 'baseline'} : {display: 'flex', alignItems: 'baseline'}}>
						<h4 style={{paddingRight: '8px'}}>{selectedPackage.price} {selectedPackage.price !== 'budget' && 'USD'}</h4>
						<small className={classes.label}>
							{selectedPackage.price === 'budget' ? (
								'Subject to'
							) : (selectedPackage.includeVAT) ? (
								'VAT included'
							) : (
								'VAT excluded'
							)}
						</small>
					</div>
					<div className={classes.horizontalDivider} style={{margin: '16px 0'}}></div>
					{this.displayProjectSelector(service)}
				</div>
				<small style={{ marginTop: '16px', maxWidth: '400px', textAlign: 'center'}}>
					{service.title} is provided by a third-party and is governed by {service.provider} privacy policy.
				</small>
			</div>
		);
	}

	scrollToPackageOptions() {
		document.querySelector('#package-options').scrollIntoView({behavior: 'smooth'});
	}

	displayProjectSelector(service) {
		const queryVariables = {
			page: 0,
			pageSize: 20,
			withMoreDetails: false,
			onlyId: true,
			includeFeasibility: true
		};

		return (
			<>
				<ErrorBoundaryQuery query={GET_OWNED_PROJECTS} variables={queryVariables}>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<SkeletonText className={classes.subtitle} style={{ width: '150px' }} />
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const projects = (data && data.ownedProjects && data.ownedProjects.projects) || [];
						const filteredPaidProjects = projects.filter(p => p.feasibility === PROJECT.FEASIBILITY.YES && p.paid);
						const filteredAvailableProjectsForUpsales = filteredPaidProjects;
						const { selectedProject } = this.state;

						return (
							<Select
								id="select-a-project-upsell"
								disabled={filteredAvailableProjectsForUpsales.length === 0}
								labelText="Select a project"
								defaultValue={selectedProject && filteredAvailableProjectsForUpsales.findIndex(p => p.id === selectedProject)}
								onChange={(e) => this.onChangeSelectedProject(e, filteredAvailableProjectsForUpsales)}
							>
								<SelectItem
									value=""
									text="Choose an option"
								/>
								{filteredAvailableProjectsForUpsales.map((p, i) => (
									<SelectItem text={p.name} value={i} key={i} />
								))}
							</Select>
						);
					}}
				</ErrorBoundaryQuery>
				{this.displayActionBtn(service.title)}
			</>
		);
	}

	onChangeSelectedProject = (e, projects) => {
		const value = e.target.value;
		if (!value) {
			return this.setState({
				selectedProject: null,
			});
		}

		const selectedProject = projects[value];
		this.setState({
			selectedProject,
		});
	};

	displayActionBtn(title) {
		const { selectedProject, selectedPackage } = this.state;

		return (
			<div style={{marginTop: '16px', width: '100%'}}>
				<Button
					variant={'contained'}
					color={'success'}
					disabled={!selectedProject}
					style={{width: '100%', textTransform: 'none', fontWeight: '600'}}
					onClick={() => this.sendUpsellRequest(selectedPackage, title)}
				>
					{selectedProject
						?	`I am interested on "${selectedPackage.plan}" option`
						:	'Select a project in order to proceed'
					}
				</Button>
			</div>
		);
	}

	sendUpsellRequest(option, title) {
		const { selectedProject } = this.state;

		window.Froged('set', { tags: [ { name: 'sales' } ] });
		window.Froged('message', `Hey there, I am interested in the upsell ${title} + ${option.name} of $${option.price} to boost my project ${selectedProject.name}. Project id: ${selectedProject.id}`);
	}

}

ServiceDetails.defaultProps = {
	userData: {},
	service: {},
	onClose() {},
};

ServiceDetails.propTypes = {
	userData: PropTypes.object.isRequired,
	service: PropTypes.object,
	onClose: PropTypes.func,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ServiceDetails);
