import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Breadcrumb,
	BreadcrumbItem,
	Tile,
	ContentSwitcher,
	Switch,
	Tag
} from 'carbon-components-react';

import { EXPERT } from '../../../../constants';
import classes from './roles-and-responsibilities.module.scss';

class RolesAndResponsibilities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayExpertRole: true
		};
	}

	componentDidMount() {
		document.title = 'Roles and responsibilities - LastBasic';
	}

	render() {
		const { userData: {user} } = this.props;
		const { expert } = user;
		const { displayExpertRole } = this.state;

		return (
			<div className={classes.profileContainer}>
				<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
					<BreadcrumbItem>
						<Link to="/">Go back</Link>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage href="#">
						My roles and responsibilities
					</BreadcrumbItem>
				</Breadcrumb>

				<h2 className={classes.title}>My roles and responsibilities</h2>
				<p>Learn about your roles and responsibilities</p>

				<div className={classes.section}>
					<div className={classes.sectionBlock}>
						<Tile className={classes.tile}>
							<div className={classes.topBox}>
								<div className={classes.circle}>
									{EXPERT.getExpertIcon(expert.expertExpertise)}
								</div>
								{(expert.expertExpertise !== 'supplier' && expert.expertExpertise !== 'maker') &&
									<Tag type="blue" className={classes.expertTag}>Expert</Tag>
								}
								<h4 style={{ height: '36px' }}>{EXPERT.expertiseToString(expert.expertExpertise)}</h4>
							</div>
							{expert.expertExpertise !== EXPERT.EXPERT_EXPERTISE.PRODUCT_DESIGN &&
                                <p className={classes.expertText}>{EXPERT.getExpertRole(expert.expertExpertise)}</p>
							}
							{expert.expertExpertise === EXPERT.EXPERT_EXPERTISE.PRODUCT_DESIGN &&
								<div className={classes.contentSwitcherContainer}>
									<h6>Choose within this specialities:</h6>
									<ContentSwitcher
										style={{ margin: '16px 0'}}
										onChange={() => this.setState({ displayExpertRole: !displayExpertRole })}
										size='sm'
									>
										<Switch name="sketching" text="Sketching" />
										<Switch name="modeling" text="3D modeling" />
									</ContentSwitcher>
									<p>{EXPERT.getExpertRole(displayExpertRole === true ? 'sketch': 'modeling')}</p>
								</div>
							}
						</Tile>
					</div>
				</div>
			</div>
		);
	}
}

RolesAndResponsibilities.defaultProps = {
	userData: {}
};

RolesAndResponsibilities.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(RolesAndResponsibilities);
