import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import { PROJECT } from '../../../constants';
import { ADD_PHASE_SET_UP_ANSWERS } from '../../../providers/mutations';
import SetupQuestions from '../../../components/project/setup-questions/setup-questions';

class PhaseSetupQuestions extends Component {
	constructor (props) {
		super(props);

		const { phase, project } = props;
		const phaseObject = PROJECT.getStateByPhase(project, phase);
		this.questions = (phaseObject && phaseObject.setupQuestions) || [];
	}

	submitAnswer = (sendPhaseSetUpAnswersMutation, answers) => {
		const { phase, project } = this.props;
		return sendPhaseSetUpAnswersMutation({
			variables: {
				projectId: project.id,
				phase,
				answers,
			}
		});
	};

	render() {
		const { onQuestionsAnswered } = this.props;
		return (
			<Mutation mutation={ADD_PHASE_SET_UP_ANSWERS}>
				{(sendPhaseSetUpAnswersMutation) => {
					return (
						<SetupQuestions
							questions={this.questions}
							submitAnswers={(answers) => this.submitAnswer(sendPhaseSetUpAnswersMutation, answers)}
							onQuestionsAnswered={onQuestionsAnswered}
							noQuestionsMessage="There are no onboarding questions."
							allQuestionsAnsweredMessage="You already answered all the phase setup questions."
							completedMessage="Awesome, you have finished the phase setup questions."
						/>
					);
				}}
			</Mutation>
		);
	}
}

PhaseSetupQuestions.propTypes = {
	phase: PropTypes.string.isRequired,
	project: PropTypes.object.isRequired,
	onQuestionsAnswered: PropTypes.func,
};

PhaseSetupQuestions.defaultProps = {
	onQuestionsAnswered() {},
};

export default PhaseSetupQuestions;