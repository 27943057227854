import { gql } from '@apollo/client';
import { proposalFragment } from '../fragments/proposal';

const contestFragment = gql`
	${proposalFragment}

	fragment ContestRequestedChangeDetail on ContestCardRequestedChange {
		comment,
		createdAt,
	}
	fragment ContestCard on ContestCard {
		file {
			...FileDetails
		},
		clientConfirmed,
		specialAttention,
		description,
		requestedChanges {
			...ContestRequestedChangeDetail
		},
		mainImage {
			...FileDetails
		},
		secondaryImages {
			...FileDetails
		},
	}
	fragment ContestRound on ContestRound {
		id,
		card {
			...ContestCard
		},
		startDate,
		endDate
	}
	fragment SharedFile on SharedFile {
		file {
			...FileDetails
		},
		approved,
		approvalStatus,
		rejectionComment,
	}
	fragment Site on Site {
		maxValidProposals,
		maxValidProposalsPerUser,
		prizeAmount,
		rewardAmount
	}
	fragment ContestProject on ContestProject {
		id,
		state,
		name,
		image,
		description,
		paid,
	}
	fragment ClientReviewDetails on ClientReview {
		type,
		title,
		value
	}
	fragment SetupQuestion on SetupQuestion {
		id,
		type,
		question,
		options,
		answer,
		minSelectable,
		maxSelectable
	}
	fragment Contest on Contest {
		id,
		type,
		active,
		rounds {
			...ContestRound
		},
		proposals {
			...ProposalDetails
		},
		selectedProposal {
			...ProposalDetails
		},
		sharedFiles {
			...SharedFile
		},
		siteContest {
			...Site
		},
		maxSlots,
		availableSlots,
		maxApprovedProposals,
		availableApprovedProposals,
		project {
			...ContestProject
		},
		clientReview {
			...ClientReviewDetails
		},
		setupQuestions {
			...SetupQuestion
		},
		isOpen,
		steps {
			...StepDetail
		}
	}

	fragment StepDetail on Step {
		title,
		description,
		status,
		order,
		updates {
			...Updates
		}
	}
	fragment Updates on StepUpdate {
		title,
		content,
		files,
		type,
		createdAt,
	}
`;

const contestBasicFragment = gql`
	${proposalFragment}

	fragment ContestBasicCard on ContestCard {
		file {
			...FileDetails
		},
		clientConfirmed,
		specialAttention,
		description,
		mainImage {
			...FileDetails
		},
		secondaryImages {
			...FileDetails
		}
	}
	fragment ContestBasicRound on ContestRound {
		id,
		card {
			...ContestBasicCard
		},
		startDate,
		endDate
	}
	fragment ContestBasicProject on ContestProject {
		id,
		state,
		name,
		description,
		paid,
	}
	fragment ContestBasic on Contest {
		id,
		type,
		active,
		rounds {
			...ContestBasicRound
		},
		proposals {
			...ProposalDetails
		},
    	selectedProposal {
			...ProposalDetails
		},
		maxSlots,
		availableSlots,
		maxApprovedProposals,
		availableApprovedProposals,
		project {
			...ContestBasicProject
		},
		isOpen
	}
`;

const contestStepsFragment = gql`

	fragment ContestStepsDetails on Contest {
		steps {
			...StepDetail
		}
	}
	fragment StepDetail on Step {
		title,
		description,
		status,
		order,
		updates {
			...Updates
		}
	}
	fragment Updates on StepUpdate {
		title,
		content,
		files,
		type,
		createdAt,
	}
`;

const contestProposalsFragment = gql`
	${proposalFragment}

	fragment ContestProposalRound on ContestRound {
		id,
		startDate,
		endDate
	}

	fragment ContestProposals on Contest {
		type,
		rounds {
			...ContestProposalRound
		},
		selectedProposal {
			id
		},
		proposals {
			...ProposalDetails
		},
		sharedFiles {
			...SharedFile
		}
	}

	fragment SharedFile on SharedFile {
		file {
			...FileDetails
		},
		approved,
		approvalStatus,
		rejectionComment,
	}
`;

export {
	contestFragment,
	contestBasicFragment,
	contestStepsFragment,
	contestProposalsFragment,
};
