import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import {
	InlineNotification,
	NotificationActionButton,
	Tag,
	ToastNotification,
	TooltipIcon
} from 'carbon-components-react';
import { Calendar32, Information16 } from '@carbon/icons-react';

import ContestModal from '../../../../components/modals/contest-modal';
import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import ImageSwiper from '../../../../components/elements/image-swiper';
import withRouter from '../../../../components/route/with-router';

import { JOIN_CONTEST } from './../../../../providers/mutations';
import { CONTEST, PROJECT } from '../../../../constants';
import { strings, frogedAPI, dates } from '../../../../utils';
import KEYS from '../../../../glossary';

import classes from '../contest-page.module.scss';

class ContestCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openContestModal: false,
			successJoiningContest: false,
			previewFile: null,
			contactDataFromFroged: null,
			showSubscriptionNotification: false,
			userClickOnImages: false,
		};
	}

	async componentDidMount() {
		const contactDataFromFroged = await frogedAPI.getContactInfo();

		return this.setState({ contactDataFromFroged: contactDataFromFroged});
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onConfirmJoinProject = async (joinContestMutation, contest) => {
		let error;
		try {
			const result = await joinContestMutation({
				variables: {
					projectId: contest.project.id,
					contestType: contest.type,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error joining contest');
		} else {
			console.log('Success joined contest');
			// refetchContests
			this.setState({ successJoiningContest: true });
			this.openProject();
		}

		// Close modal
		this.setState({ openContestModal: false });
	};

	openProject() {
		const { contest, navigate } = this.props;
		const { project } = contest;

		navigate('/project/' + project.id);
	}

	getStepsByPhase(project, phase, i) {
		const activePhase = project.state === phase ? true : false;

		return (
			<div className={classes.contributorsBubble} key={i} style={activePhase ? {backgroundColor: CONTEST.getPhaseColor(phase), width: '100px', borderRadius: '4px', textAlign: 'center', color: '#fff'} : {}}>
				{phase === PROJECT.STATE.MODEL_CONTEST
					?	activePhase
						?	'3D Modeling'
						:	'3D'
					:	activePhase
						?	strings.capitalizeFirstLetter(PROJECT.getContestTypeByState(phase))
						:	phase.substring(0, 2).toUpperCase()
				}
			</div>
		);
	}

	render() {
		const { contest, userData: { user } } = this.props;
		const {
			openContestModal,
			successJoiningContest,
			previewFile,
			contactDataFromFroged,
			// eslint-disable-next-line no-unused-vars
			showSubscriptionNotification,
			userClickOnImages,
		} = this.state;

		const project = contest.project;
		const isContestOpen = CONTEST.isContestOpen(contest, project);
		const userHasProposal = CONTEST.hasProposalByUser(contest, user);
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;

		const round = contest && CONTEST.getLastRound(contest);
		const newEndDate = round && new Date(parseInt(round.endDate));
		const endDate = round && dates.substractDays(newEndDate, 1);
		const daysLeft = endDate && CONTEST.getContestDaysLeft(endDate) + 1;

		const newStartDate = round && new Date(parseInt(round.startDate));
		const startDate = (round && newStartDate !== undefined) && newStartDate.setDate(newStartDate.getDate());

		if (!contest || !contest.active || !round) {
			return (<div></div>);
		}

		const { card } = round;
		const { mainImage, secondaryImages } = card;

		const phases = PROJECT.getContestStates();

		const { attributes } = contactDataFromFroged !== null && contactDataFromFroged;
		// eslint-disable-next-line no-unused-vars
		const isUserSubscribedToContest = (attributes !== undefined && attributes.contest_subscribed) ? true : false;

		return (
			<Mutation mutation={JOIN_CONTEST}>
				{(joinContestMutation, { error }) => (
					<Fragment>
						<div
							className={classes.contestCard}
							onClick={(userClickOnImages && secondaryImages.length > 0)
								? ''
								: (userHasProposal)
									? () => this.openProject()
									: () => this.setState({ openContestModal: true })
							}
						>
							<div className={classes.topCard}>
								<div>
									<small style={{ textTransform: 'capitalize' }}>{contest.type}</small>
									<h3>{project.name}</h3>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'center'}}>
									{/* {(isUserSubscribedToContest || showSubscriptionNotification) &&
										<small>Subscribed</small>
									} */}
									{isContestOpen
										? 	<Tag type="green" className={classes.contestTag}>Open contest</Tag>
										: 	projectIsFinished
											?	<Tag type="red" className={classes.contestTag}>Project finished</Tag>
											:	<Tag type="red" className={classes.contestTag}>Closed contest</Tag>
									}
								</div>
							</div>
							<div className={classes.bodyCard}>
								<div
									onMouseEnter={() => this.setState({userClickOnImages: true})}
									onMouseLeave={() => this.setState({userClickOnImages: false})}
									style={{display: 'flex', alignItems: 'center'}}
								>
									<div className={classes.leftSideContent}>
										<ImageSwiper
											mainImage={mainImage}
											secondaryImages={secondaryImages}
										/>
									</div>
								</div>
								<div className={classes.rightSideContent}>
									{error && (
										<ToastNotification
											className={classes.notification}
											kind="error"
											title="Error joining project"
											subtitle={(error.graphQLErrors && error.graphQLErrors.length)
												? error.graphQLErrors[0].message
												: (error.networkError)
													? error.networkError.message
													: error.message}
											hideCloseButton={true}
											lowContrast={true}
											notificationType="inline"
											timeout={5000}
										/>
									)}
									{successJoiningContest && (
										<ToastNotification
											className={classes.notification}
											kind="success"
											title="Success joining project"
											actions={<NotificationActionButton><Link to={'/project/' + project.id}>Go to project</Link></NotificationActionButton>}
											hideCloseButton={true}
											lowContrast={true}
											notificationType="inline"
										/>
									)}
									{/* {showSubscriptionNotification && (
										<ToastNotification
											className={classes.notification}
											kind="success"
											title="You have subscribed to this contest. From now on you will receive more info about it."
											lowContrast={true}
											notificationType="inline"
										/>
									)} */}
									{userHasProposal && (
										<InlineNotification
											className={classes.notification}
											kind="success"
											title="You are an expert contributor of this contest"
											lowContrast
											hideCloseButton
										/>
									)}
									<div className={classes.contestInfo}>
										<Calendar32 />
										<div className={classes.dateBox}>
											<h5>
												{round
													? `${dates.formattedDate(startDate)} - ${dates.formattedDate(endDate)}`
													: 'N/D'
												}
											</h5>
											<small>
												{daysLeft > 1
													? `(${daysLeft} days left)`
													: daysLeft === 1
														? 'Last day!'
														: 'Contest ended'
												}
											</small>
										</div>
									</div>
									<div className={classes.description}>
										<p>{project.description}</p>
									</div>
									{!userHasProposal && (
										<div className={classes.features}>
											<h5>{KEYS.project_status_slots}</h5>
											<p>
												{contest.slots !== null
													? contest.availableSlots + '/' + contest.maxSlots
													: CONTEST.getContestMaxValidProposals(contest.type) + '/' + CONTEST.getContestMaxValidProposals(contest.type)
												}
											</p>
										</div>
									)}
									{userHasProposal && (
										<div className={classes.features}>
											<h5>Your approved proposals</h5>
											<TooltipIcon
												tooltipText={`You are generating ${CONTEST.getContestRewards(contest.type)} per validated proposal.`}
												direction='top' align='center'>
												<p>{contest.availableApprovedProposals}/{contest.maxApprovedProposals}</p>
												<Information16 style={{ marginLeft: '4px' }} />
											</TooltipIcon>
										</div>
									)}
									<div className={classes.features}>
										<h5>Contest winning prize</h5>
										<p>{CONTEST.getContestPrizes(contest.type)}</p>
									</div>
									<div style={{ margin: '16px'}}>
										{projectIsFinished
											?	<div className={classes.finishedTag}>{KEYS.finished}</div>
											:	<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												{phases.map((phase, i) => (
													this.getStepsByPhase(project, phase, i)
												))}
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						<ContestModal
							project={project}
							contest={contest}
							open={openContestModal}
							onRequestSubmit={() => this.onConfirmJoinProject(joinContestMutation, contest)}
							onSecondarySubmit={() => this.setState({ openContestModal: false })}
							onRequestClose={() => this.setState({ openContestModal: false })}
							onRequestSubscription={() => this.setState({ openContestModal: false, showSubscriptionNotification: true })}
						/>
						<PreviewFileModal
							open={!!previewFile}
							file={previewFile}
							onRequestClose={() => this.onClosePreviewFile()}
						/>
					</Fragment>
				)}
			</Mutation>
		);
	}
}

ContestCard.propTypes = {
	contest: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(ContestCard)));
