import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import PublicRoute from './components/route/public-route';
import PrivateRoute from './components/route/private-route';
import ImpersonatorPage from './pages/shared/tools/impersonator';
import LoginPage from './pages/shared/auth/login';
import LogoutPage from './pages/shared/auth/logout';
import ForgotPasswordPage from './pages/shared/auth/forgot-password';
import CreatePasswordPage from './pages/shared/auth/create-password';
import SelectUserType from './pages/shared/select-user-type';

import './App.scss';

class App extends Component {
	render() {
		return (
			<Routes>
				<Route path="/impersonator" element={<ImpersonatorPage />} />
				<Route element={<PublicRoute />}>
					<Route path="/login" element={<LoginPage />} />
					<Route path="/forgot-password" element={<ForgotPasswordPage />} />
					<Route path="/create-password" element={<CreatePasswordPage />} />
				</Route>
				<Route element={<PrivateRoute />}>
					<Route path="*" element={<SelectUserType />} />
					<Route path="/logout" element={<LogoutPage />} />
				</Route>
			</Routes>
		);
	}
}

export default App;
