import jwt from '../jwt';

const SessionStore = window.sessionStorage;
const LocalStore = window.localStorage;

export const TOKENS = 'TOKENS';

const getStoredTokens = () => {
	try {
		// Try LocalStorage
		let strTokens = LocalStore.getItem(TOKENS);
		if (strTokens) return JSON.parse(strTokens);
		
		// Try SessionStorage
		strTokens = SessionStore.getItem(TOKENS);
		if (strTokens) return JSON.parse(strTokens);

		return null;
	} catch (error) {
		return null;
	}
};

const getValidStoredTokens = () => {
	try {
		const data = getStoredTokens();
		if (!data) return null;
		if (!data.token || !data.token.accessToken) return null;

		if (jwt.isValidToken(data.token.accessToken)) return data;

		// Clean storage
		SessionStore.removeItem(TOKENS);
		LocalStore.removeItem(TOKENS);
		return null;
	} catch (error) {
		return null;
	}
};

const storeTokens = (tokens, useLocalStorage = false) => {
	try {
		if (useLocalStorage) {
			LocalStore.setItem(TOKENS, JSON.stringify(tokens));
		} else {
			SessionStore.setItem(TOKENS, JSON.stringify(tokens));
		}
		return true;
	} catch (error) {
		return false;
	}
};

const replaceTokens = (tokens) => {
	try {
		// Use whatever we are currently using
		let strTokens = LocalStore.getItem(TOKENS);
		return storeTokens(tokens, !!strTokens);
	} catch (error) {
		return null;
	}
};

const removeTokens = () => {
	try {
		SessionStore.removeItem(TOKENS);
		LocalStore.removeItem(TOKENS);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getStoredTokens,
	getValidStoredTokens,
	storeTokens,
	replaceTokens,
	removeTokens,
};
