import { Mutation } from '@apollo/client/react/components';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import VerifiedIcon from '@mui/icons-material/Verified';
import Alert from '@mui/material/Alert';
import confetti from 'canvas-confetti';
// import classnames from 'classnames';
import {
	InlineNotification,
	SkeletonPlaceholder,
	SkeletonText,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import PDFViewer from 'pdf-viewer-reactjs';

import withRouter from '../../../components/route/with-router';
import { GET_PROPOSALS } from '../../../providers/queries';
import { SELECT_WINNING_PROPOSAL } from '../../../providers/mutations';

import { serverAPI, strings } from '../../../utils';
import { PROPOSAL, CONTEST } from '../../../constants';
import KEYS from './../../../glossary';

import EditFeedbackModal from '../../../components/modals/edit-feedback-modal';
import SelectWinningProposalConfirmationModal from '../../../components/modals/select-winning-proposal-confirmation-modal';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import RatingValue from '../../../components/project/rating-value';
import ProposalSupportingFiles from './proposal-supporting-files';
import LBButton from '../../../components/elements/lb-button';

import classes from './proposal.module.scss';
// const PROPOSAL_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';

class ProposalView extends Component {
	constructor (props) {
		super(props);
		this.refetchProposal = () => {};

		this.state = {
			loupe: 'out',
			selectedProposal: '',
			openProposalFeedbackModal: false,
			proposalFeedback: null,
			expand: false,
			selectWinningProposalConfirmationModal: false,
			selectWinningProposalData: null,
			selectedWinningProposalId: null,
		};
	}

	componentDidMount() {
		document.title = 'Proposal page - LastBasic';
	}

	onSubmitWinningProposalActions() {
		this.setState({
			selectWinningProposalConfirmationModal: false
		});
		confetti({
			particleCount: 150,
			spread: 150,
			startVelocity: 35
		});
		this.refetchProposal();
	}

	onCloseSelectWinningProposalConfirmationModal = () => {
		this.setState({ selectWinningProposalConfirmationModal: false });
	};

	render() {
		const { match, userData } = this.props;
		const { projectId, contestId, proposalId } = match.params;
		const {
			loupe,
			openProposalFeedbackModal,
			proposalFeedback,
			expand,
			selectWinningProposalConfirmationModal,
			selectWinningProposalData,
			selectedWinningProposalId,
		} = this.state;

		return (
			<div className={classes.proposalViewPage}>
				<ErrorBoundaryQuery query={GET_PROPOSALS} variables={{ id: contestId }}>
					{({ loading, error, data, refetch: refetchProposal}) => {
						this.refetchProposal = refetchProposal;

						if (loading) {
							return (
								<div className={classes.mainContainer}>
									<div style={{width: '100%', padding: '26px'}}>
										<div style={{display: 'flex', justifyContent: 'space-between', background: '#fff', padding: '16px'}}>
											<div style={{display: 'flex', alignItems: 'center'}}>
												<SkeletonText style={{width: '30px', marginBottom: '0'}} />
												<SkeletonText style={{width: '20px', margin: '0 8px'}} />
												<SkeletonText style={{width: '30px', marginBottom: '0'}} />
											</div>
											<SkeletonText style={{width: '50px'}} />
										</div>
										<div className={classes.imgContainer} style={{alignItems: 'center'}}>
											<SkeletonPlaceholder style={{width: '500px', height: '500px'}} />
										</div>
									</div>
									<div className={classes.contentSide}>
										<div className={classes.sideContent}>
											<SkeletonText style={{width: '150px'}} />
											<SkeletonText style={{width: '30px'}} />
										</div>
										<div style={{ display: 'flex', alignItems: 'center', padding: '26px', borderBottom: '1px solid #ddd'}}>
											<SkeletonText style={{width: '30px', marginRight: '8px'}} />
											<SkeletonText style={{width: '200px'}} />
										</div>
										<div style={{ padding: '26px'}}>
											<SkeletonText style={{width: '150px'}} />
											<div className={classes.skeletonBlockContent}>
												<SkeletonText style={{width: '100px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
											</div>
											<div className={classes.skeletonBlockContent}>
												<SkeletonText style={{width: '100px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
											</div>
											<div className={classes.skeletonBlockContent}>
												<SkeletonText style={{width: '100px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '300px'}} />
											</div>
										</div>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const contestType = data && data.getContest.type;
						const contest = data && data.getContest;
						const proposals = data && data.getContest.proposals;
						const proposalsIdList = PROPOSAL.getProposalsId(proposals);
						const selectedProposal = contest && contest.selectedProposal;

						if (!proposals || (proposalId && proposals && proposalsIdList.indexOf(proposalId) < 0)) {
							return (<p>No proposal</p>);
						}

						const proposal = proposals.filter(p => p.id === proposalId)[0];
						const proposalIndex = proposalsIdList.indexOf(proposal.id);
						const feedback = proposal.feedback;
						const proposalUrl = this.getProposalUrl(proposal);

						const contestHasEnded = contest && CONTEST.isContestEnded(contest);
						const hasAWinningProposal = contest && CONTEST.hasWinner(contest);
						const isWinningProposal = selectedProposal && proposal.id === selectedProposal.id;

						return (
							<div className={classes.mainContainer}>
								<div style={{width: '100%', padding: '16px 36px'}}>
									<div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff', padding: '16px', borderBottom: '1px solid #ddd' }}>
										<div style={{display: 'flex', alignItems: 'center'}}>
											<Link to={proposalIndex !== 0 ? `/project/${projectId}/contest/${contestId}/proposal/${proposalsIdList[proposalIndex-1]}` : '#'} style={{textDecoration: 'none', color: '#313131'}}>
												<div className={classes.link} style={proposalIndex === 0 ? { cursor: 'not-allowed' } : {}}>
													<ChevronLeftIcon sx={{ width: '32px', height: '32px' }} />
												</div>
											</Link>
											<p>{proposalIndex+1} / {proposals.length}</p>
											<Link to={proposalIndex !== proposalsIdList.length - 1 ? `/project/${projectId}/contest/${contestId}/proposal/${proposalsIdList[proposalIndex+1]}` : '#'} style={{textDecoration: 'none', color: '#313131'}}>
												<div className={classes.link} style={proposalIndex === proposalsIdList.length - 1 ? { cursor: 'not-allowed' } : {}}>
													<ChevronRightIcon sx={{ width: '32px', height: '32px' }} />
												</div>
											</Link>
										</div>
										<div style={{textAlign: 'end'}}>
											<div style={{ display: 'none' }}>
												<ZoomOutIcon
													className={classes.secondaryIcon}
													sx={{ width: '32px', height: '32px'}}
													color={loupe === 'out' ? 'disabled' : 'tertiary'}
													onClick={() => this.setState({loupe: 'out'})}
												/>
												<ZoomInIcon
													className={classes.secondaryIcon}
													sx={{ width: '32px', height: '32px'}}
													color={loupe === 'in' ? 'disabled' : 'tertiary'}
													onClick={() => this.setState({loupe: 'in'})}
												/>
												{expand
													?	<FitScreenIcon
														onClick={() => this.setState({expand: false})}
														className={classes.secondaryIcon}
														sx={{ width: '32px', height: '32px'}}
														style={{marginLeft: '8px'}}
													/>
													:	<AspectRatioIcon
														onClick={() => this.setState({expand: true})}
														className={classes.secondaryIcon}
														sx={{ width: '32px', height: '32px'}}
														style={{marginLeft: '8px'}}
													/>
												}
											</div>
											<br></br>
											<small className={classes.externalLink} onClick={() => window.open(proposalUrl, '_blank')}>Open in a new tab</small>
										</div>
									</div>
									<div className={classes.imgContainer}>
										<div className={classes.imgContent}>
											<div style={{ display: 'none' }}>
												<PDFViewer
													document={{
														url: proposalUrl
													}}
													hideNavbar
												/>
											</div>
											<iframe src={proposalUrl} style={{ height: '100vh', width: '100%' }}></iframe>
											{/* // Old proposal view for images */}
											{/* <img
												className={classes.proposalImg}
												style={loupe === 'in' ? {width: '110%'} : loupe === 'out' ? {width: '100%'} : {}}
												src={proposalUrl}
												alt='Proposal'
												onError={({ currentTarget }) => {
													currentTarget.onerror = null;
													currentTarget.src = PROPOSAL_PLACEHOLDER;
												}}
											/> */}
										</div>
									</div>
								</div>
								{!expand &&
								<div className={classes.contentSide} style={{position: 'relative'}}>
									<div className={classes.sideContent}>
										<div>
											<Link to={`/project/${projectId}/contest/${contestId}`} style={{textDecoration: 'none', color: '#313131'}}>
												<span className={classes.typeLabel}>{strings.capitalizeFirstLetter(contestType)}</span>
											</Link>
											<h3>Proposal #{proposal.number}</h3>
										</div>
										<Link to={`/project/${projectId}/contest/${contestId}`} style={{textDecoration: 'none', color: '#313131'}}>
											<div className={classes.link}>
												<CloseIcon className={classes.closeIcon} />
											</div>
										</Link>
									</div>
									<div style={{ overflowY: 'scroll', height: 'calc(100% - 152px)', overflowX: 'hidden'}}>
										<div style={{ display: 'flex', alignItems: 'center', padding: '16px 26px', borderBottom: '1px solid #ddd'}}>
											<VerifiedIcon sx={{ width: '18px', height: '18px'}} style={{fill: 'green', marginRight: '8px'}} />
											Created on LastBasic by the community of experts
										</div>
										{isWinningProposal &&
											<div className={classes.winnerLabel}>
												<h6 style={{color: '#fff', letterSpacing: '2px', textTransform: 'uppercase'}}>Winner</h6>
											</div>
										}
										<div style={{ padding: '16px 26px'}}>
											{feedback
												?	<>
													<h4 className={classes.titleSpace}>Feedback</h4>
													<div>
														<div className={classes.feedbackContent}>
															<RatingValue rating={feedback.rating} />
														</div>
														{(feedback.info !== null && feedback.info.pros !== null &&
														feedback.info.pros.length > 0) &&
														<div className={classes.feedbackContent}>
															<h5>{KEYS.proposal_pros_title}</h5>
															<ul className={classes.listModal}>
																<li>
																	<ReactMarkdown>
																		{feedback.info.pros}
																	</ReactMarkdown>
																</li>
															</ul>
														</div>
														}
														{(feedback.info !== null && feedback.info.cons !== null &&
														feedback.info.cons.length > 0) &&
														<div className={classes.feedbackContent}>
															<h5>{KEYS.proposal_cons_title}</h5>
															<ul className={classes.listModal}>
																<li>
																	<ReactMarkdown>
																		{feedback.info.cons}
																	</ReactMarkdown>
																</li>
															</ul>
														</div>
														}
														{(feedback.info !== null && feedback.info.keepFeatures !== null &&
														feedback.info.keepFeatures.length > 0) &&
														<div className={classes.feedbackContent}>
															<h5>{KEYS.proposal_keepFeatures_title}</h5>
															<ul className={classes.listModal}>
																<li>
																	<ReactMarkdown>
																		{feedback.info.keepFeatures}
																	</ReactMarkdown>
																</li>
															</ul>
														</div>
														}
														{(feedback.info !== null &&  feedback.info.removeFeatures !== null &&
														feedback.info.removeFeatures.length > 0) &&
														<div className={classes.feedbackContent}>
															<h5>{KEYS.proposal_removeFeatures_title}</h5>
															<ul className={classes.listModal}>
																<li>
																	<ReactMarkdown>
																		{feedback.info.removeFeatures}
																	</ReactMarkdown>
																</li>
															</ul>
														</div>
														}
													</div>
												</>
												:	<>
													<Alert
														severity="info"
														style={{margin: '16px 0'}}
													>
														You need to give feedback to this proposal.
													</Alert>
													<LBButton
														title={KEYS.give_feedback}
														type={'primary'}
														disabled={contestHasEnded && hasAWinningProposal}
														onClick={() => this.setState({ openProposalFeedbackModal: true, proposalFeedback: proposal })}
														width={'100%'}
													/>
												</>
											}
										</div>
										{(contestHasEnded && !hasAWinningProposal && !isWinningProposal) &&
											<div style={{ padding: '16px 26px'}}>
												<Alert
													severity="info"
													style={{margin: '16px 0'}}
												>
													You need to select a winner.
												</Alert>
												<Mutation mutation={SELECT_WINNING_PROPOSAL}>
													{(selectWinningProposalMutation) => (
														<LBButton
															title={'Select as winner'}
															type={'primary'}
															disabled={!feedback}
															onClick={() => this.setState({ selectWinningProposalConfirmationModal: true, selectWinningProposalData: { proposal, selectWinningProposalMutation} })}
															width={'100%'}
														/>
													)}
												</Mutation>
											</div>
										}
										{isWinningProposal &&
											<div style={{padding: '26px', borderTop: '1px solid #dddddd'}}>
												<h4 className={classes.titleSpace}>Supporting files</h4>
												{contest.sharedFiles.length > 0
													? <ProposalSupportingFiles contest={contest} />
													: <Alert
														severity="info"
														style={{margin: '16px 0', width: 'fit-content'}}
													>
														Winning expert is pending to upload the supporting files of his proposal.
													</Alert>
												}
											</div>
										}
									</div>
									<div className={classes.fixedActions}>
										<Link to={`/project/${projectId}/contest/${contestId}`} style={{textDecoration: 'none'}}>
											<LBButton
												title={'Close proposal view'}
												type={'secondary'}
												disabled={false}
												style={{ color: '#8A8A8A' }}
											/>
										</Link>
										<span style={{ display: 'none' }}>
											<Link to={proposalIndex !== proposalsIdList.length - 1 ? `/project/${projectId}/contest/${contestId}/proposal/${proposalsIdList[proposalIndex+1]}` : '#'} style={{textDecoration: 'none'}}>
												<LBButton
													title={'Next proposal'}
													type={'primary'}
													disabled={proposalIndex === proposalsIdList.length - 1}
												/>
											</Link>
										</span>
									</div>
								</div>
								}
							</div>
						);
					}}
				</ErrorBoundaryQuery>
				{openProposalFeedbackModal && proposalFeedback && !proposalFeedback.feedback && (
					<EditFeedbackModal
						open={openProposalFeedbackModal}
						userData={userData}
						onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
						onSecondarySubmit={() => this.setState({ openProposalFeedbackModal: false })}
						onRequestSubmit={() => this.setState({ openProposalFeedbackModal: false }, () => { this.refetchProposal();})}
						proposal={proposalFeedback}
						displayImage={false}
					/>
				)}
				{selectWinningProposalConfirmationModal && (
					<SelectWinningProposalConfirmationModal
						open={selectWinningProposalConfirmationModal}
						proposal={selectWinningProposalData}
						proposalId={selectedWinningProposalId}
						onRequestSubmit={() => this.onSubmitWinningProposalActions()}
						onSecondarySubmit={this.onCloseSelectWinningProposalConfirmationModal}
						onRequestClose={this.onCloseSelectWinningProposalConfirmationModal}
					/>
				)}
			</div>
		);
	}


	getProposalUrl(proposal) {
		const fileId = PROPOSAL.getFileIdFromProposal(proposal);
		return serverAPI.getDisplayUrlByFileId(fileId);
	}
}

ProposalView.propTypes = {
	userData: PropTypes.object,
	match: PropTypes.object,
};

ProposalView.defaultProps = {
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(ProposalView));
