import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@rollbar/react';
import { Query } from '@apollo/client/react/components';

import ErrorComponent from '../../pages/shared/errors/error-component';

class ErrorBoundaryQuery extends Component {
	errorFallback = (error) => {
		return (
			<ErrorComponent errorMessage={error.message} />
		);
	};

	render() {
		const { query, variables, children, ...rest } = this.props;

		return (
			<ErrorBoundary fallbackUI={this.errorFallback}>
				<Query query={query} variables={variables} {...rest}>
					{children}
				</Query>
			</ErrorBoundary>
		);
	}
}

ErrorBoundaryQuery.propTypes = {
	children: PropTypes.func,
	query: PropTypes.object,
	variables: PropTypes.object,
};

ErrorBoundaryQuery.defaultProps = {
	children: () => null,
	query: null,
	variables: null,
};

export default ErrorBoundaryQuery;
