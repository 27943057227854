import React from 'react';
import PropTypes from 'prop-types';

const ElectricalExpertIcon = (props) => {
	const { size } = props;

	return (
		<svg id="electrical-expert-icon" height={size} viewBox="0 0 512 512" width={size} xmlns="http://www.w3.org/2000/svg">
			<path d="m300 200a12 12 0 1 0 -12-12 12.013 12.013 0 0 0 12 12z"/>
			<path d="m372 200a12 12 0 1 0 -12-12 12.013 12.013 0 0 0 12 12z"/>
			<path d="m296 408h16v16h-16z"/>
			<path d="m296 480h16v16h-16z"/>
			<path d="m312 248h16.934a115.037 115.037 0 0 0 33.385-4.9l-4.638-15.313a99.062 99.062 0 0 1 -28.747 4.213h-16.934z"/>
			<path d="m424 448h-64v16h64v32h16v-88h-16z"/>
			<path d="m232 408h16v88h-16z"/>
			<path d="m168 208h-112a8 8 0 0 0 -8 8v56a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8v-56a8 8 0 0 0 -8-8zm-8 56h-96v-40h96z"/>
			<path d="m56 296h16v16h-16z"/>
			<path d="m88 296h16v16h-16z"/>
			<path d="m120 296h16v16h-16z"/>
			<path d="m152 296h16v16h-16z"/>
			<path d="m168.568 358.059-22.627-22.628a8 8 0 0 0 -8-1.993 64.106 64.106 0 0 0 -84.507 84.509 8 8 0 0 0 1.993 7.994l22.627 22.628a8 8 0 0 0 8 1.993 64.106 64.106 0 0 0 84.507-84.509 8 8 0 0 0 -1.993-7.994zm-56.568-14.059a48.862 48.862 0 0 1 12.44 1.618l-58.822 58.822a48.856 48.856 0 0 1 -1.618-12.44 48.054 48.054 0 0 1 48-48zm-39.6 76.284 67.884-67.884 11.316 11.316-67.884 67.884zm39.6 19.716a48.862 48.862 0 0 1 -12.44-1.618l58.822-58.822a48.856 48.856 0 0 1 1.618 12.44 48.054 48.054 0 0 1 -48 48z"/>
			<path d="m48 448h16v16h-16z"/>
			<path d="m160 448h16v16h-16z"/>
			<path d="m447.919 322.192-38.674-5.524-25.245-16.935v-3.767a79.8 79.8 0 0 0 32-63.966v-8a31.985 31.985 0 0 0 21.547-55.637 24.562 24.562 0 0 0 -5.637-48.348 91.927 91.927 0 0 0 -66.416-84.423 32 32 0 0 0 -58.988 0 91.927 91.927 0 0 0 -66.416 84.423 24.562 24.562 0 0 0 -5.637 48.348 31.985 31.985 0 0 0 21.547 55.637v8a79.793 79.793 0 0 0 32 63.967v4.018l-25.522 19.023v-.008l-42.211 9.045a56.014 56.014 0 0 0 -12.267 4.194v-124.239a40.045 40.045 0 0 0 -40-40h-112a40.045 40.045 0 0 0 -40 40v248a40.045 40.045 0 0 0 40 40h112a40.045 40.045 0 0 0 40-40v-105.18a40.078 40.078 0 0 1 15.619-7.129l35.035-7.507 5.374 64.48a8 8 0 0 0 11.941 6.282l35.386-20.22 16.645 46.658v62.616h16v-62.616l16.645-46.658 35.386 20.22a8 8 0 0 0 11.941-6.282l5.6-67.215 32.084 4.583a40.2 40.2 0 0 1 34.344 39.598v118.37h16v-118.37a56.276 56.276 0 0 0 -48.081-55.438zm-255.919 133.808a24.027 24.027 0 0 1 -24 24h-112a24.027 24.027 0 0 1 -24-24v-248a24.027 24.027 0 0 1 24-24h112a24.027 24.027 0 0 1 24 24zm160.2-96h-32.4l-15.04-54.353a80.006 80.006 0 0 0 62.486 0zm-4.719 16-11.481 32.19-11.483-32.19zm68.519-168v-32a16 16 0 0 1 0 32zm-96-160a16 16 0 0 1 32 0v40h-32zm-48 29.266v26.734h16v-41.992a76.171 76.171 0 0 1 16-8.685v42.677a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-42.677a76.22 76.22 0 0 1 16 8.685v41.992h16v-26.735a75.309 75.309 0 0 1 15.9 42.735h-159.8a75.3 75.3 0 0 1 15.9-42.734zm-40 67.3a8.581 8.581 0 0 1 8.571-8.57h190.858a8.571 8.571 0 0 1 .611 17.12l-96.04 6.863-96.039-6.859a8.6 8.6 0 0 1 -7.961-8.55zm8 47.434a16.019 16.019 0 0 1 16-16v32a16.019 16.019 0 0 1 -16-16zm32 40v-60.551l63.43 4.53q.285.021.57.021t.57-.021l63.43-4.53v60.551a64 64 0 0 1 -128 0zm2.212 98.217 17.2-12.817 14.707 53.148-.513.186.256.705-26.932 15.387zm118.858 56.609-26.928-15.387.256-.705-.513-.186 14.868-53.727 17.19 11.532z"/>
		</svg>
	);
};

ElectricalExpertIcon.propTypes = {
	size: PropTypes.number
};

ElectricalExpertIcon.defaultProps = {
	size: 300
};

export default ElectricalExpertIcon;