import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TooltipIcon, InlineNotification } from 'carbon-components-react';
import { TrophyFilled32 } from '@carbon/icons-react';

import ReactMarkdown from 'react-markdown';
import RatingValue from './rating-value';

import { serverAPI } from '../../utils';
import KEYS from '../../glossary';

import classes from './proposal-feedback-contest.module.scss';

class ProposalFeedbackContent extends Component {

	getProposalThumbnailUrl(proposal, userData) {
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	render() {
		const {
			proposal,
			feedback,
			isWinningProposal,
			displayOnPublic,
			onPreviewProposalFile,
			userData,
			isAQuotation,
		} = this.props;

		const isWinner = isWinningProposal;
		const onlyForExpertPersonalView = (displayOnPublic === false && isWinner === true);

		return (
			<div className={classes.modalContent}>
				{(onlyForExpertPersonalView && !isAQuotation) && (
					<InlineNotification
						className={classes.notification}
						kind="success"
						lowContrast
						iconDescription={'Success'}
						title={KEYS.proposal_feedback_modal_alert_winner_expert_title}
						subtitle={<span>{KEYS.proposal_feedback_modal_alert_winner_expert_description}</span>}
						timeout={5000}
						caption=""
					/>
				)}
				{(isAQuotation && isWinner) && (
					<InlineNotification
						className={classes.notification}
						kind="success"
						lowContrast
						iconDescription={'Success'}
						title={'Congratulations! You quotation has been approved by client.'}
						subtitle={''}
						timeout={5000}
						caption=""
					/>
				)}
				<div className={classes.imgContainer}>
					<img
						className={classes.proposalImg}
						src={this.getProposalThumbnailUrl(proposal, userData)}
						alt='Proposal thumbnail'
						onClick={onPreviewProposalFile}
					/>
				</div>
				{isAQuotation
					? 	<>
						{isWinner &&
							<div className={classes.contestContent}>
								<h5>Status</h5>
								<div className={classes.ratingContent}>
									<p>Approved by client.</p>
								</div>
							</div>
						}
						<div className={classes.contestContent}>
							<h5>Comments</h5>
							<p>{feedback.info.pros.length > 0 ? feedback.info.pros : 'No comments were provided.'}</p>
						</div>
					</>
					: <>
						<div className={classes.contestContent}>
							<h5>{KEYS.proposal_feedback_modal_ratings}</h5>
							<div className={classes.ratingContent}>
								<div className={classes.ratingHearts}>
									<RatingValue rating={feedback.rating} />
								</div>
								{isWinner &&
									<TooltipIcon tooltipText={KEYS.winning_proposal}>
										<TrophyFilled32 />
									</TooltipIcon>
								}
							</div>
						</div>
						<div className={classes.contestContent}>
							<h5>{KEYS.proposal_feedback_modal_pros}</h5>
							<ul className={classes.listModal}>
								<li>
									<ReactMarkdown>
										{feedback.info.pros}
									</ReactMarkdown>
								</li>
							</ul>
						</div>
						<div className={classes.contestContent}>
							<h5>{KEYS.proposal_feedback_modal_cons}</h5>
							<ul className={classes.listModal}>
								<li>
									<ReactMarkdown>
										{feedback.info.cons}
									</ReactMarkdown>
								</li>
							</ul>
						</div>
						{(feedback.info.keepFeatures !== null &&
						feedback.info.keepFeatures.length > 0) &&
							<div className={classes.contestContent}>
								<h5>{KEYS.proposal_feedback_modal_features_keep}</h5>
								<ul className={classes.listModal}>
									<li>
										<ReactMarkdown>
											{feedback.info.keepFeatures}
										</ReactMarkdown>
									</li>
								</ul>
							</div>
						}
						{(feedback.info.removeFeatures !== null &&
						feedback.info.removeFeatures.length > 0) &&
							<div className={classes.contestContent}>
								<h5>{KEYS.proposal_feedback_modal_features_remove}</h5>
								<ul className={classes.listModal}>
									<li>
										<ReactMarkdown>
											{feedback.info.removeFeatures}
										</ReactMarkdown>
									</li>
								</ul>
							</div>
						}
						{onlyForExpertPersonalView &&
							<div className={classes.contestContent}>
								<h5>{KEYS.proposal_feedback_modal_next_steps}</h5>
								<p>{KEYS.proposal_feedback_modal_next_steps_description}</p>
							</div>
						}
					</>
				}
			</div>
		);
	}
}

ProposalFeedbackContent.propTypes = {
	feedback: PropTypes.object.isRequired,
	proposal: PropTypes.object,
	userData: PropTypes.object,
	isWinningProposal: PropTypes.bool,
	displayOnPublic: PropTypes.bool,
	onPreviewProposalFile: PropTypes.func,
	isAQuotation: PropTypes.bool,
};

ProposalFeedbackContent.defaultProps = {
	feedback: {},
	proposal: {},
	userData: {},
	isWinningProposal: false,
	displayOnPublic: true,
	onPreviewProposalFile() {},
	isAQuotation: false,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProposalFeedbackContent);