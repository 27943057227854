import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../../components/route/with-router';

import AddOn from '../../../components/project/add-on';
import UpsellModal from '../../../components/modals/upsell-modal';
import VerifiedIcon from '@mui/icons-material/Verified';
import Chip from '@mui/material/Chip';
import { TooltipDefinition } from 'carbon-components-react';
import ServiceDetails from './service-view';

import classes from './marketplace.module.scss';

const DEFAULT_SERVICE_IMG_URL = '/assets/images/lb-addon.png';

class Marketplace extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openUpsellModal: null,
			upsellInfo: null,
			filter: 'all',
			openService: null,
		};
	}

	componentDidMount() {
		document.title = 'Marketplace - LastBasic';
	}

	render() {
		const {
			openUpsellModal,
			upsellInfo,
			openService,
		} = this.state;

		return (
			<>
				{openService === null &&
				<div className={classes.marketplaceContainer}>
					<div id="add-ons">
						<div className={classes.spaceBetween} style={{position: 'relative', margin: '56px 0'}}>
							<div>
								<h2>Marketplace</h2>
								<p>Find services to complement your prototype journey</p>
							</div>
							<img
								src={'/assets/images/marketplace.gif'}
								className={classes.gifImage}
							/>
						</div>
						<div className={classes.horizontalDivider}></div>
						{/* {this.displayAddons()} */}
						{this.displayServices()}
					</div>
					<UpsellModal
						open={!!openUpsellModal}
						title={upsellInfo && upsellInfo.title}
						option={upsellInfo && upsellInfo.option}
						provider={upsellInfo && upsellInfo.provider}
						type={upsellInfo && upsellInfo.type}
						onRequestClose={() => this.setState({ openUpsellModal: null })}
					/>
				</div>
				}
				{openService !== null &&
				<ServiceDetails
					service={openService}
					onClose={() => this.setState({openService: null})}
				/>
				}
			</>
		);
	}

	displayServices() {
		const { filter } = this.state;

		const services = [
			{
				title: 'Business mentorship',
				alias: 'business-mentorship',
				provider: 'Matical innovation',
				type: 'Business',
				category: 'Business',
				level: 'beginner',
				curatedByLB: true,
				price: '575',
				logo: '/assets/add-ons/images/matical-logo.png',
				images: ['/assets/add-ons/images/krakenimages-376KN_ISplE-unsplash.jpg'],
				description: 'A fully personalized mentoring service to help you turn your initial idea into an actual project to attract investors and future buyers.',
				options: [
					{
						name: 'individual',
						plan: 'Basic',
						price: '575',
						includeVAT: false,
						sessions: '1 session',
						extra: '1 report',
						pdf: '/assets/add-ons/pdfs/business-mentorship-individual.pdf',
						features: [
							'Definition of the problem/opportunity',
							'Definition of the product/solution',
							'Let’s define your business model'
						],
						outcomes: []
					},
					{
						name: 'complete',
						plan: 'Pro',
						price: '2500',
						includeVAT: false,
						sessions: '5 sessions',
						extra: '1 project',
						pdf: '/assets/add-ons/pdfs/business-mentorship-complete.pdf',
						features: [
							'Business model definition',
							'Product roadmap',
							'Marketing & communication',
							'Financing needs identification',
							'Next steps'
						],
						outcomes: []
					}
				]
			},
			{
				title: 'Strategic IP Roadmap',
				alias: 'strategic-ip-roadmap',
				provider: 'Global Patent Solutions',
				price: '835',
				type: 'IP Patent',
				category: 'Legal',
				level: 'advance',
				curatedByLB:  true,
				logo: '/assets/add-ons/images/gps-logo.png',
				images: [
					'/assets/add-ons/images/patent.jpeg',
					'/assets/add-ons/images/gps.jpg'
				],
				description: 'Emerge with a document that outlines specific activities you need to execute to secure your valuable intellectual property. With this, you will also demonstrate to investors and other stakeholders that you have a grasp on how your intellectual property will contribute to optimizing the value of your company.',
				options: [
					{
						name: 'Strategic IP Roadmap',
						plan: 'Basic',
						price: '480',
						includeVAT: false,
						sessions: '2 sessions',
						extra: '1 report',
						pdf: '',
						features: [
							'A 30-minute needs assessment interview',
							'Completion of IP strategy Checklist',
							'Creation of Strategic IP Roadmap Summary Document',
							'1-hour roadmap review, briefing, and coaching session'
						],
						outcomes: [
							'Identify your IP needs, including potential copyrights, patents, trademarks, designs',
							'Identify jurisdictions needing IP protection and the resources required for execution',
							'Understand potential cost implications, in addition to benefits, of your IP activities',
							'Have a documented plan for securing your IP that can serve as an internal roadmap, while also enabling you to communicate your IP path to other stakeholders.'
						]
					},
					{
						name: 'Strategic IP Roadmap',
						plan: 'Pro',
						price: '798',
						includeVAT: false,
						sessions: '1 custom search',
						extra: '',
						pdf: '',
						features: [
							'A highly targeted search of patents and published applications in the country/jurisdiction of your choice',
							'Comprehensive report gives you the information to guide your next steps',
							'Proprietary search methodology leverages artificial intelligence, industry appropriate keywords, synonyms, and patent classifications to identify single-reference disclosures of the subject matter; emphasis on the “best” patent classifications',
							'Report includes bibliographic data and reference copies of any identified prior art'
						],
						outcomes: [
							'Obtain necessary information for determining if your invention is patentable in your target market',
							'Arm yourself with data that enables drafting of a patent application that will provide maximum protection for your invention and will optimize your patent’s value',
							'Take affordable proactive steps towards protecting your IP'
						]
					},
					{
						name: 'Strategic IP Roadmap',
						plan: 'Custom',
						price: 'budget',
						includeVAT: false,
						sessions: '1 custom search',
						extra: '',
						pdf: '',
						features: [
							'Obtain the search that best serves your needs. Options include: custom patentability search, freedom-to-operate search, R&D technology or competitor landscape search, design search, trademark search, copyright search.',
							'Confer with an expert to determine the appropriate search elements to give you the exact information that will best serve your needs.',
							'Focus worldwide or on the specific jurisdictions that best align with your target market.',
							'Comprehensive report can be custom formatted to deliver the information in the most understandable manner for your intended readers.'
						],
						outcomes: [
							'Obtain necessary information for determining your next steps for protecting your valuable IP',
							'Arm yourself with data that facilitates an effective filing process, thereby providing maximum protection for your invention and optimizing your company’s value',
							'Custom quote allows you to pay for exactly what you need.'
						]
					}
				]
			},
			{
				title: 'Crowdfunding 12 weeks program',
				alias: 'crowdfunding-12-weeks-program',
				provider: 'Miguel Moya',
				type: 'Crowdfunding',
				category: 'Marketing',
				level: 'beginner',
				price: '2400',
				curatedByLB: true,
				logo: '',
				images: ['/assets/add-ons/images/miguel-moya.jpeg'],
				description: 'Learn all the process, tips and tricks to validate the real market for your project, get the funds you need to make it real and get real feedback and numbers from real customers.',
				options: [
					{
						name: 'Basic',
						plan: 'Basic',
						price: '2400',
						includeVAT: false,
						sessions: '10 video lessons',
						extra: '12 weeks of valuable information',
						pdf: 'https://miguelmoya.notion.site/Miguel-Moya-Crowdfunding-12-Weeks-Program-c6c4e2ae57aa4e00b656f325eca5b0ca',
						features: [
							'Diagnosis',
							'Defining your goals',
							'Designing your campaign',
							'Pre-Campaign setup'
						],
						outcomes: []
					}
				]
			},
			{
				title: 'App development',
				alias: 'app-development',
				provider: 'Antt robotics ltd',
				price: '625',
				type: 'Development',
				category: 'Development',
				level: 'beginner',
				curatedByLB: true,
				logo: '/assets/add-ons/images/antt-logo.jpeg',
				images: ['/assets/add-ons/images/d-c-tr-nh-CXZz1NJgpO8-unsplash.jpg'],
				description: 'Have an IoT connected invention idea and need to create an App to make it work?',
				options: [
					{
						name: 'Mobile app',
						plan: 'Basic',
						price: '625',
						includeVAT: false,
						sessions: 'Designed for IoT devices',
						extra: 'Mobile App with UX/UI',
						pdf: '/assets/add-ons/pdfs/app-development-antt.pdf',
						features: [
							'UX/UI',
							'Responsive',
							'Bluetooth connection',
							'Control features'
						],
						outcomes: []
					},
					{
						name: 'Web app',
						plan: 'Pro',
						price: '750',
						includeVAT: false,
						sessions: 'Designed for IoT devices',
						extra: 'Web App with UX/UI',
						pdf: '/assets/add-ons/pdfs/app-development-antt.pdf',
						features: [
							'UX/UI',
							'Responsive',
							'Bluetooth connection',
							'Control features'
						],
						outcomes: []
					}
				]
			},
			{
				title: 'Crowdfunding marketing US market',
				alias: 'crowdfunding-marketing-us-market',
				provider: 'Starget',
				price: '9500',
				type: 'Marketing',
				category: 'Marketing',
				level: 'beginner',
				curatedByLB: true,
				logo: '/assets/add-ons/images/starget-logo.jpg',
				images: [
					'/assets/add-ons/images/kickstarter-indiegogo-video-production.jpg',
					'/assets/add-ons/images/kickstarter-indiegogo-marketing.jpg'
				],
				description: 'Take your crowdfunding campaign to the next level and set foot in the global market with proper storytelling, communication, and project page images! Focused on the American/European market.',
				options: [
					{
						name: 'Crowdfunding marketing',
						plan: 'Marketing',
						price: '9500',
						includeVAT: false,
						sessions: 'Marketing + page design',
						extra: 'Story writing proposal',
						pdf: '/assets/add-ons/pdfs/starget-basic.pdf',
						features: [
							'Campaign operation',
							'Story writing',
							'Campaign page design',
							'Press release',
							'Promotional Youtube reviews, media, newsletter marketing',
							'Pre-launch website production'
						],
						outcomes: [
							'Campaign launch'
						]
					},
					{
						name: 'Crowdfunding marketing',
						plan: 'Video',
						price: '9500',
						includeVAT: false,
						sessions: 'Video Production',
						extra: 'Image shooting & production',
						pdf: '/assets/add-ons/pdfs/starget-pro.pdf',
						recommended: true,
						features: [
							'Pre-production',
							'Filming & production',
							'Image shooting & production',
							'Post-production',
							'Other expenses'
						],
						outcomes: [
							'Campaign launch'
						]
					}
				]
			},
			{
				title: 'Crowdfunding marketing Asian market',
				alias: 'crowdfunding-marketing-asian-market',
				provider: 'Starget',
				price: '9500',
				type: 'Marketing',
				category: 'Marketing',
				level: 'beginner',
				curatedByLB: true,
				logo: '/assets/add-ons/images/starget-logo.jpg',
				images: [
					'/assets/add-ons/images/makuake.jpg',
					'/assets/add-ons/images/wadiz-basic.jpg',
					'/assets/add-ons/images/wadiz-pro.jpg'
				],
				description: 'Take your crowdfunding campaign to the next level and start your journey in Asia with proper storytelling, communication, and project page images. Focused on the South Korean/Japanese market.',
				isAsian: true,
				options: [
					{
						name: 'Crowdfunding marketing',
						plan: 'Makuake',
						price: '7500',
						includeVAT: false,
						sessions: 'Full Proposal',
						extra: 'Campaign design & production',
						pdf: '/assets/add-ons/pdfs/makuake-full-campaign.pdf',
						features: [
							'Story rewriting and planning',
							'Japanese translation',
							'Page design',
							'Makuake campaign management',
							'Pre-launch ad website design',
							'Social media ads',
							'Image production',
							'Campaign main video production'
						],
						outcomes: [
							'Campaign launch'
						]
					},
					{
						name: 'Crowdfunding marketing',
						plan: 'Wadiz Basic',
						price: '5000',
						includeVAT: false,
						sessions: 'Marketing campaign proposal',
						extra: 'Press release, Instagram and Blogger marketing',
						pdf: '/assets/add-ons/pdfs/wadiz-proposals.pdf',
						features: [
							'Wadiz campaign launch and management',
							'Korean translation',
							'Story writing',
							'Page design',
							'Image production',
							'Youtube reviews'
						],
						outcomes: [
							'Campaign launch'
						]
					},
					{
						name: 'Crowdfunding marketing',
						plan: 'Wadiz Pro',
						price: '8600',
						includeVAT: false,
						sessions: 'Marketing campaign full proposal',
						extra: 'Image, story, page design',
						pdf: '/assets/add-ons/pdfs/wadiz-proposals.pdf',
						recommended: true,
						features: [
							'Includes all services in Wadiz Basic',
							'Press release',
							'Instagram influencer marketing',
							'Blogger marketing',
							'Wadiz platform marketing',
							'Additional advertising / marketing services'
						],
						outcomes: [
							'Campaign launch'
						]
					}
				]
			},
			{
				title: 'Product photoshooting',
				alias: 'product-photoshooting',
				provider: 'Dani Ferrer',
				price: '835',
				type: 'Photograph',
				category: 'Product',
				level: 'advance',
				curatedByLB: true,
				logo: '/assets/add-ons/images/df-logo.jpg',
				images: [
					'/assets/add-ons/images/df-pic.jpg',
					'/assets/add-ons/images/dani-prod-1.jpg',
					'/assets/add-ons/images/dani-prod-2.jpg',
					'/assets/add-ons/images/dani-prod-3.jpg'
				],
				description: 'Need a professional photographer for your product? Dani is specialized in product, advertising and portrait photography. Dani will take your product, before we send it to you, and enhance its appearance, features and presence by creating an environmental display. You will receive 6 pictures (3 in fine art context and 3 as ecommerce pictures with a white background) to use on your web/landing page or social media profiles.',
				options: [
					{
						name: 'Product photoshooting',
						plan: 'Basic',
						price: '835',
						includeVAT: false,
						sessions: 'Photoshooting will be taken before we send you the prototype',
						extra: 'All pictures will be in high-res',
						pdf: '',
						features: [
							'3 photos fine art context',
							'3 ecomerce photos (no background)'
						],
						outcomes: []
					}
				]
			}
		];
		const filteredServices = filter === 'all' ? services : services.filter(s => s.category === filter);

		return (
			<>
				<h4 className={classes.secondaryTitle}>Services</h4>
				{this.renderFilters()}
				<div className={classes.servicesList}>
					{filteredServices.map((service, i) => (
						this.renderService(service, i)
					))}
				</div>
			</>
		);
	}

	renderFilters() {
		const { filter } = this.state;

		const serviceCategories = [
			'Business',
			'Legal',
			'Marketing',
			'Development',
			'Product'
		];

		return (
			<div style={{margin: '8px 0'}}>
				<div className={classes.filterList}>
					<Chip
						label={'All'}
						variant={filter !== 'all' ? 'outlined' : ''}
						size='small'
						onClick={() => this.setState({filter: 'all'})}
					/>
					{serviceCategories.map((category, i) => (
						<Chip
							key={i}
							label={category}
							variant={filter !== category ? 'outlined' : ''}
							size='small'
							onClick={() => this.setState({filter: category})}
						/>
					))}
				</div>
			</div>
		);
	}

	renderService(service, i) {
		return (
			<div 
				key={i}
				className={classes.serviceBlock}
				onClick={() => this.openService(service)}
			>
				<img
					src={service.logo || DEFAULT_SERVICE_IMG_URL}
					className={classes.serviceLogo}
				/>
				<div>
					<div>
						<h4>{service.title}</h4>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<small style={{color: '#6b6b6b'}}>By {service.provider}</small>
							{service.curatedByLB &&
								<VerifiedIcon sx={{ width: '16px', height: '16px'}} style={{fill: '#df3d3b', marginLeft: '8px'}} />
							}
						</div>
					</div>
					{service.description.length > 100
						?	<TooltipDefinition
							tooltipText={service.description}
							className={classes.tooltipText}
						>
							<p style={{margin: '8px 0'}}>{service.description.substring(0, 97) + '...'}</p>
						</TooltipDefinition>
						:	<p style={{margin: '8px 0', textAlign: 'start'}}>{service.description}</p>
					}
					<div style={{ display: 'flex', gap: '4px' }}>
						<span className={classes.tag}>{service.type}</span>
						<span className={classes.tag}>#{service.level}</span>
					</div>
				</div>
			</div>
		);
	}

	openService(service) {
		const { navigate } = this.props;
		this.setState({ openService: service });

		return navigate({
			search: '?' + new URLSearchParams({ service: service.alias}).toString()
		});
	}

	displayAddons() {
		return (
			<div className={classes.addonList}>
				<AddOn
					title="Business mentorship"
					provider="Matical innovation"
					type="Business"
					price="575"
					active={true}
					images={['/assets/add-ons/images/krakenimages-376KN_ISplE-unsplash.jpg']}
					logo="/assets/add-ons/images/matical-logo.png"
					description="A fully personalized mentoring service to help you turn your initial idea into an actual project to attract investors and future buyers."
					features={[
						'Precise definition of the problem or opportunity (SMART approach).',
						'Precise definition of the product/solution.',
						'Preliminary view of a potential business model.'
					]}
					market={[]}
					platforms={[]}
					options={[
						{
							name: 'individual',
							plan: 'Basic',
							price: '575',
							includeVAT: false,
							sessions: '1 session',
							extra: '1 report',
							pdf: '/assets/add-ons/pdfs/business-mentorship-individual.pdf',
							features: [
								'Definition of the problem/opportunity',
								'Definition of the product/solution',
								'Let’s define your business model'
							],
							outcomes: []
						},
						{
							name: 'complete',
							plan: 'Pro',
							price: '2500',
							includeVAT: false,
							sessions: '5 sessions',
							extra: '1 project',
							pdf: '/assets/add-ons/pdfs/business-mentorship-complete.pdf',
							features: [
								'Business model definition',
								'Product roadmap',
								'Marketing & communication',
								'Financing needs identification',
								'Next steps'
							],
							outcomes: []
						}
					]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Business mentorship',
						upsellInfo: {
							title: 'Business mentorship',
							option: e.option,
							provider: 'Matical innovation',
							type: 'business',
						}
					})}
				/>
				<AddOn
					title="Strategic IP Roadmap"
					provider="Global Patent Solutions"
					price="835"
					type="IP Patent"
					active={true}
					images={[
						'/assets/add-ons/images/patent.jpeg',
						'/assets/add-ons/images/gps.jpg'
					]}
					logo="/assets/add-ons/images/gps-logo.png"
					description="Emerge with a document that outlines specific activities you need to execute to secure your
					valuable intellectual property. With this, you will also demonstrate to investors and other
					stakeholders that you have a grasp on how your intellectual property will contribute to optimizing
					the value of your company."
					features={[
						'A 30-minute needs assessment interview',
						'Completion of IP strategy Checklist',
						'Creation of Strategic IP Roadmap Summary Document',
						'1-hour roadmap review, briefing, and coaching session'
					]}
					options={[
						{
							name: 'Strategic IP Roadmap',
							plan: 'Basic',
							price: '480',
							includeVAT: false,
							sessions: '2 sessions',
							extra: '1 report',
							pdf: '',
							features: [
								'A 30-minute needs assessment interview',
								'Completion of IP strategy Checklist',
								'Creation of Strategic IP Roadmap Summary Document',
								'1-hour roadmap review, briefing, and coaching session'
							],
							outcomes: [
								'Identify your IP needs, including potential copyrights, patents, trademarks, designs',
								'Identify jurisdictions needing IP protection and the resources required for execution',
								'Understand potential cost implications, in addition to benefits, of your IP activities',
								'Have a documented plan for securing your IP that can serve as an internal roadmap, while also enabling you to communicate your IP path to other stakeholders.'
							]
						},
						{
							name: 'Strategic IP Roadmap',
							plan: 'Pro',
							price: '798',
							includeVAT: false,
							sessions: '1 custom search',
							extra: '-',
							pdf: '',
							features: [
								'A highly targeted search of patents and published applications in the country/jurisdiction of your choice',
								'Comprehensive report gives you the information to guide your next steps',
								'Proprietary search methodology leverages artificial intelligence, industry appropriate keywords, synonyms, and patent classifications to identify single-reference disclosures of the subject matter; emphasis on the “best” patent classifications',
								'Report includes bibliographic data and reference copies of any identified prior art'
							],
							outcomes: [
								'Obtain necessary information for determining if your invention is patentable in your target market',
								'Arm yourself with data that enables drafting of a patent application that will provide maximum protection for your invention and will optimize your patent’s value',
								'Take affordable proactive steps towards protecting your IP'
							]
						},
						{
							name: 'Strategic IP Roadmap',
							plan: 'Custom',
							price: 'budget',
							includeVAT: false,
							sessions: '1 custom search',
							extra: '-',
							pdf: '',
							features: [
								'Obtain the search that best serves your needs. Options include: custom patentability search, freedom-to-operate search, R&D technology or competitor landscape search, design search, trademark search, copyright search.',
								'Confer with an expert to determine the appropriate search elements to give you the exact information that will best serve your needs.',
								'Focus worldwide or on the specific jurisdictions that best align with your target market.',
								'Comprehensive report can be custom formatted to deliver the information in the most understandable manner for your intended readers.'
							],
							outcomes: [
								'Obtain necessary information for determining your next steps for protecting your valuable IP',
								'Arm yourself with data that facilitates an effective filing process, thereby providing maximum protection for your invention and optimizing your company’s value',
								'Custom quote allows you to pay for exactly what you need.'
							]
						}
					]}
					market={[]}
					platforms={[]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Strategic IP Roadmap',
						upsellInfo: {
							title: 'Strategic IP Roadmap',
							option: e.option,
							provider: 'Global Patent Solutions',
							type: 'IP Patent',
							pdf: ''
						}
					})}
				/>
				<AddOn
					title="Crowdfunding 12 weeks program"
					provider="Miguel Moya"
					type="Crowdfunding"
					price="2400"
					active={true}
					images={['/assets/add-ons/images/miguel-moya.jpeg']}
					logo=""
					description="Learn all the process, tips and tricks to validate the real market for your project, get the funds you need to make it real and get real feedback and numbers from real customers."
					features={[]}
					options={[
						{
							name: 'Basic',
							plan: 'Basic',
							price: '2400',
							includeVAT: false,
							sessions: '10 video lessons',
							extra: '12 weeks of valuable information',
							pdf: 'https://miguelmoya.notion.site/Miguel-Moya-Crowdfunding-12-Weeks-Program-c6c4e2ae57aa4e00b656f325eca5b0ca',
							features: [
								'Diagnosis',
								'Defining your goals',
								'Designing your campaign',
								'Pre-Campaign setup'
							],
							outcomes: []
						}
					]}
					market={[]}
					platforms={[]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Crowdfunding 12 weeks program',
						upsellInfo: {
							title: 'Crowdfunding 12 weeks program',
							option: e.option,
							provider: 'Miguel Moya',
							type: 'crowdfunding',
							pdf: 'https://miguelmoya.notion.site/Miguel-Moya-Crowdfunding-12-Weeks-Program-c6c4e2ae57aa4e00b656f325eca5b0ca'
						}
					})}
				/>
				<AddOn
					title="App development"
					provider="Antt robotics ltd"
					price="625"
					type="Development"
					active={true}
					images={['/assets/add-ons/images/d-c-tr-nh-CXZz1NJgpO8-unsplash.jpg']}
					logo="/assets/add-ons/images/antt-logo.jpeg"
					description="Have an IoT connected invention idea and need to create an App to make it work?"
					features={[
						'UX/UI',
						'Responsive',
						'Bluetooth connection',
						'Control features'
					]}
					options={[
						{
							name: 'Mobile app',
							plan: 'Basic',
							price: '625',
							includeVAT: false,
							sessions: 'Designed for IoT devices',
							extra: 'Mobile App with UX/UI',
							pdf: '/assets/add-ons/pdfs/app-development-antt.pdf',
							features: [
								'UX/UI',
								'Responsive',
								'Bluetooth connection',
								'Control features'
							],
							outcomes: []
						},
						{
							name: 'Web app',
							plan: 'Pro',
							price: '750',
							includeVAT: false,
							sessions: 'Designed for IoT devices',
							extra: 'Web App with UX/UI',
							pdf: '/assets/add-ons/pdfs/app-development-antt.pdf',
							features: [
								'UX/UI',
								'Responsive',
								'Bluetooth connection',
								'Control features'
							],
							outcomes: []
						}
					]}
					market={[]}
					platforms={[]}
					onClick={(e) => this.setState({
						openUpsellModal: 'App development',
						upsellInfo: {
							title: 'App development',
							option: e.option,
							provider: 'Antt robotics ltd',
							type: 'development',
							pdf: ''
						}
					})}
				/>
				<AddOn
					title="Crowdfunding marketing"
					provider="Starget"
					price="9500"
					type="Marketing"
					active={true}
					images={[
						'/assets/add-ons/images/kickstarter-indiegogo-video-production.jpg',
						'/assets/add-ons/images/kickstarter-indiegogo-marketing.jpg'
					]}
					logo="/assets/add-ons/images/starget-logo.jpg"
					description="Take your crowdfunding campaign to the next level and set foot in the global market with proper storytelling, communication, and project page images! Focused on the American/European market."
					features={[
						'Marketing',
						'Page design',
						'Story writing proposal'
					]}
					options={[
						{
							name: 'Crowdfunding marketing',
							plan: 'Marketing',
							price: '9500',
							includeVAT: false,
							sessions: 'Marketing + page design',
							extra: 'Story writing proposal',
							pdf: '/assets/add-ons/pdfs/starget-basic.pdf',
							features: [
								'Campaign operation',
								'Story writing',
								'Campaign page design',
								'Press release',
								'Promotional Youtube reviews, media, newsletter marketing',
								'Pre-launch website production'
							],
							outcomes: [
								'Campaign launch'
							]
						},
						{
							name: 'Crowdfunding marketing',
							plan: 'Video',
							price: '9500',
							includeVAT: false,
							sessions: 'Video Production',
							extra: 'Image shooting & production',
							pdf: '/assets/add-ons/pdfs/starget-pro.pdf',
							features: [
								'Pre-production',
								'Filming & production',
								'Image shooting & production',
								'Post-production',
								'Other expenses'
							],
							outcomes: [
								'Campaign launch'
							]
						}
					]}
					market={['us']}
					platforms={[
						'kickstarter',
						'indiegogo'
					]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Crowdfunding marketing',
						upsellInfo: {
							title: 'Crowdfunding marketing',
							option: e.option,
							provider: 'Starget',
							type: 'Marketing',
							pdf: ''
						}
					})}
				/>
				<AddOn
					title="Crowdfunding marketing"
					provider="Starget"
					price="9500"
					type="Marketing"
					active={true}
					images={[
						'/assets/add-ons/images/makuake.jpg',
						'/assets/add-ons/images/wadiz-basic.jpg',
						'/assets/add-ons/images/wadiz-pro.jpg'
					]}
					logo="/assets/add-ons/images/starget-logo.jpg"
					description="Take your crowdfunding campaign to the next level and start your journey in Asia with proper storytelling, communication, and project page images. Focused on the South Korean/Japanese market."
					features={[
						'Marketing',
						'Page design',
						'Story writing proposal'
					]}
					options={[
						{
							name: 'Crowdfunding marketing',
							plan: 'Makuake',
							price: '7500',
							includeVAT: false,
							sessions: 'Full Proposal',
							extra: 'Campaign design & production',
							pdf: '/assets/add-ons/pdfs/makuake-full-campaign.pdf',
							features: [
								'Story rewriting and planning',
								'Japanese translation',
								'Page design',
								'Makuake campaign management',
								'Pre-launch ad website design',
								'Social media ads',
								'Image production',
								'Campaign main video production'
							],
							outcomes: [
								'Campaign launch'
							]
						},
						{
							name: 'Crowdfunding marketing',
							plan: 'Wadiz Basic',
							price: '5000',
							includeVAT: false,
							sessions: 'Marketing campaign proposal',
							extra: 'Press release, Instagram and Blogger marketing',
							pdf: '/assets/add-ons/pdfs/wadiz-proposals.pdf',
							features: [
								'Wadiz campaign launch and management',
								'Korean translation',
								'Story writing',
								'Page design',
								'Image production',
								'Youtube reviews'
							],
							outcomes: [
								'Campaign launch'
							]
						},
						{
							name: 'Crowdfunding marketing',
							plan: 'Wadiz Pro',
							price: '8600',
							includeVAT: false,
							sessions: 'Marketing campaign full proposal',
							extra: 'Image, story, page design',
							pdf: '/assets/add-ons/pdfs/wadiz-proposals.pdf',
							features: [
								'Includes all services in Wadiz Basic',
								'Press release',
								'Instagram influencer marketing',
								'Blogger marketing',
								'Wadiz platform marketing',
								'Additional advertising / marketing services'
							],
							outcomes: [
								'Campaign launch'
							]
						}
					]}
					market={['jp', 'kr']}
					platforms={[
						'wadiz',
						'makuake'
					]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Crowdfunding marketing',
						upsellInfo: {
							title: 'Crowdfunding marketing',
							option: e.option,
							provider: 'Starget',
							type: 'Marketing',
							pdf: ''
						}
					})}
				/>
				<AddOn
					title="Product photoshooting"
					provider="Dani Ferrer"
					price="835"
					type="Photograph"
					active={true}
					images={[
						'/assets/add-ons/images/df-pic.jpg',
						'/assets/add-ons/images/dani-prod-1.jpg',
						'/assets/add-ons/images/dani-prod-2.jpg',
						'/assets/add-ons/images/dani-prod-3.jpg'
					]}
					logo="/assets/add-ons/images/df-logo.jpg"
					description="Need a professional photographer for your product?
						Dani is specialized in product, advertising and portrait photography.
						Dani will take your product, before we send it to you, and enhance its appearance,
						features and presence by creating an environmental display.
						You will receive 6 pictures (3 in fine art context and 3 as ecommerce pictures with a white background) to use on your web/landing page or social media profiles."
					features={[
						'3 photos fine art context',
						'3 ecomerce photos (no background)'
					]}
					options={[
						{
							name: 'Product photoshooting',
							plan: 'Basic',
							price: '835',
							includeVAT: false,
							sessions: 'Photoshooting will be taken before we send you the prototype',
							extra: 'All pictures will be in high-res',
							pdf: '',
							features: [
								'3 photos fine art context',
								'3 ecomerce photos (no background)'
							],
							outcomes: []
						}
					]}
					market={[]}
					platforms={[]}
					onClick={(e) => this.setState({
						openUpsellModal: 'Product photoshooting',
						upsellInfo: {
							title: 'Product photoshooting',
							option: e.option,
							provider: 'Dani Ferrer',
							type: 'photograph',
							pdf: ''
						}
					})}
				/>
				<small className={classes.addonsBottomMessage}>Email us at <a
					href="mailto:sales@lastbasic.com?subject=I%20have%20a%20concern%20with%20an%20addon"
					style={{ color: 'inherit' }}
				>
						sales@lastbasic.com
				</a> if you have any questions about the addons</small>
			</div>
		);
	}
}

Marketplace.propTypes = {
	navigate: PropTypes.func,
};

Marketplace.defaultProps = {
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(Marketplace)));
