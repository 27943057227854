import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { InlineNotification, SkeletonText, TooltipIcon } from 'carbon-components-react';
import {
	List20,
	ChevronRight20,
	PresentationFile24,
	TaskStar24,
	Chat24,
	Email24,
	Credentials24,
	CheckmarkFilled16,
	RadioButton16,
	Locked16,
} from '@carbon/icons-react';

import ParticipatingProjectList from './../projects/components/participating-project-list';
import AlternativeOption from '../../../components/project/alternative-option';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import ExtraOption from '../../../components/elements/extra-option';
import ProgressBarAlt from '../../../components/elements/progress-bar-alt';

import LegalInfoFormModal from '../../../components/modals/preview-form-modal';
import ProfessionalInfoForm from '../../../components/modals/professional-info-form';

import Config from '../../../config';
import { CONTEST, EXPERT, PROJECT } from '../../../constants';
import {
	GET_PARTICIPATING_PROJECTS,
	GET_AVAILABLE_NEW_IN_PROJECT,
	GET_MY_PROFILE_INFO,
} from '../../../providers/queries';
import { serverAPI, strings, userInfo } from '../../../utils';

import classes from './../projects/projects-page.module.scss';

const CONTENT_TYPE = {
	ALL: 'all',
	FAVORITES: 'favorites',
};

const DEFAULT_PROJECT_IMG_URL = '/assets/images/logo-lb-project.png';

class HomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openProfessionalInfoForm: false,
			selectedContentType: CONTENT_TYPE.ALL,
			userHasSignedNDA: false,
			userInfoData: userInfo.getUserInfo(),
			viewPhaseSlots: null,
		};
	}

	componentDidMount() {
		document.title = 'Home - LastBasic';
	}

	renderCompleteProfileInfo() {
		const { openLegalInfoForm, userInfoData, openProfessionalInfoForm, userHasSignedNDA } = this.state;

		return (
			<ErrorBoundaryQuery query={GET_MY_PROFILE_INFO}>
				{({ loading, error, data, refetch: refetchProfileInfo }) => {
					this.refetchProfileInfo = refetchProfileInfo;

					if (loading) {
						return (
							<div className={classes.secondaryBtnContainer}>
								<div>
									<SkeletonText style={{ width: '120px'}} />
								</div>
								<div className={classes.completinessBlock}>
									<div className={classes.profileHeaderBlock}>
										<SkeletonText style={{ width: '100px', height: '40px'}} />
										<SkeletonText style={{ width: '100px'}} />
									</div>
									<div className={classes.listContainer}>
										<ul>
											<SkeletonText className={classes.listItem} style={{ width: '100px'}} />
											<SkeletonText className={classes.listItem} style={{ width: '100px'}} />
										</ul>
										<ul>
											<SkeletonText className={classes.listItem} style={{ width: '100px'}} />
											<SkeletonText className={classes.listItem} style={{ width: '100px'}} />
										</ul>
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const profile = data ? data.myProfileInfo : {};
					const { expert } = profile;

					userInfo.storeUserInfo({
						...userInfoData,
						hasAvatar: profile.hasAvatar,
						hasSignedNDA: expert.ndaSignedAt,
					});

					if (userHasSignedNDA !== !!expert.ndaSignedAt) {
						this.setState({ userHasSignedNDA: !!expert.ndaSignedAt });
					}

					const hasExpertAPendingTask = expert && EXPERT.hasExpertAPendingTask(profile);
					if (!hasExpertAPendingTask) return null;

					const userHasAvatar = profile && profile.hasAvatar;
					const userHasPaymentInfo = !!expert.paymentInfo;
					const userHasDetailInfo = !!(expert && expert.expertExpertise && expert.expertType);

					const profileCompletionProgress = EXPERT.getProfileCompletionProgress(profile);

					return (
						<div className={classes.secondaryBtnContainer}>
							<div>
								<h5 className={classes.subtitle}>Complete your profile</h5>
							</div>
							<div className={classes.completinessBlock}>
								<div className={classes.profileHeaderBlock}>
									<h2>{profileCompletionProgress}<small>%</small></h2>
									<ProgressBarAlt percentage={profileCompletionProgress} name="Completed" />
								</div>
								<div className={classes.listContainer}>
									<ul>
										<li className={classes.listItem}>
											{userHasSignedNDA
												?	<CheckmarkFilled16 className={classes.iconFilled} />
												:	<RadioButton16 className={classes.iconPending} />
											}
											<span
												className={(userHasSignedNDA ? classes.linkTextDisabled : classes.linkTextAction)}
												onClick={() => (userHasSignedNDA ? {} : window.open(Config.eversign.forms.ndaUrl, '_blank'))}
											>
												Sign NDA
											</span>
										</li>
										<li className={classes.listItem}>
											{userHasDetailInfo
												?	<CheckmarkFilled16 className={classes.iconFilled} />
												:	<RadioButton16 className={classes.iconPending} />
											}
											<span
												className={(userHasDetailInfo ? classes.linkTextDisabled : classes.linkTextAction)}
												onClick={() => (userHasDetailInfo ? {} : this.setState({openProfessionalInfoForm: true}))}
											>
												Set your professional details
											</span>
										</li>
									</ul>
									<ul>
										<li className={classes.listItem}>
											{userHasAvatar
												?	<CheckmarkFilled16 className={classes.iconFilled} />
												:	<RadioButton16 className={classes.iconPending} />
											}
											<span
												className={(userHasAvatar ? classes.linkTextDisabled : classes.linkTextAction)}
												onClick={() => (userHasAvatar ? {} : window.open('/account/settings', '_self'))}
											>
												Upload a profile picture
											</span>
										</li>
										<li className={classes.listItem}>
											{userHasPaymentInfo
												?	<CheckmarkFilled16 className={classes.iconFilled} />
												:	<RadioButton16 className={classes.iconPending} />
											}
											<span
												className={(userHasPaymentInfo ? classes.linkTextDisabled : classes.linkTextAction)}
												onClick={() => (userHasPaymentInfo ? {} : this.setState({openLegalInfoForm: true}))}
											>
												Set your payment information
											</span>
										</li>

									</ul>
								</div>
							</div>
							<LegalInfoFormModal
								open={!!openLegalInfoForm}
								onRequestClose={() => this.setState({ openLegalInfoForm: false }, () => {this.refetchProfileInfo();})}
							/>
							<ProfessionalInfoForm
								email={profile.email}
								open={!!openProfessionalInfoForm}
								onRequestClose={() => this.setState({ openProfessionalInfoForm: false }, () => {this.refetchProfileInfo();})}
							/>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	renderMyActivity() {
		return (
			<div className={classes.secondaryBtnContainer}>
				<div>
					<h5 className={classes.subtitle}>Your activity</h5>
				</div>
				<div className={classes.activityContainer}>
					{this.displayPersonalActivity()}
					{/* {this.displayContestOptions()} */}
				</div>
			</div>
		);
	}

	displayPersonalActivity() {
		const { userData: {user} } = this.props;
		const { expert } = user;
		const statistics = expert && expert.statistics;

		return (
			<div className={classes.experienceBlock}>
				<div>
					<h3>{statistics.joinedContests}</h3>
					<small>Joined contests</small>
				</div>
				<div className={classes.divider}></div>
				<div>
					<h3>{statistics.sentProposals}</h3>
					<small>Sent proposals</small>
				</div>
				<div className={classes.divider}></div>
				<div>
					<h3>{statistics.winningSubmissions}</h3>
					<small>Won proposals</small>
				</div>
			</div>
		);
	}

	displayContestOptions() {
		return (
			<Link to="/contests" style={{ textDecoration: 'none' }}>
				<AlternativeOption
					type="primary"
					title="Discover new contests"
					icon={<List20 />}
				/>
			</Link>
		);
	}

	renderParticipatingProjects() {
		return (
			<ParticipatingProjectList query={GET_PARTICIPATING_PROJECTS} />
		);
	}

	renderLastProject() {
		return (
			<div className={classes.secondaryBtnContainer} style={{ flexWrap: 'nowrap' }}>
				<div>
					<p className={classes.littleText}>Latest project</p>
				</div>
				{this.displayNewProject()}
			</div>
		);
	}

	displayNewProject() {
		const { userHasSignedNDA } = this.state;

		const queryVariables = {
			page: 0,
			pageSize: 3
		};

		return (
			<ErrorBoundaryQuery query={GET_AVAILABLE_NEW_IN_PROJECT} variables={queryVariables}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<small>Loading..</small>
						);
					}
					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const projects = (data && data.availableProjects) ? data.availableProjects.projects : [];
					const project = projects.sort((a, b) => b.code - a.code)[0];
					const projectDescription = `${strings.capitalizeFirstLetter(project.productNeed)}. ${strings.capitalizeFirstLetter(project.productSolution)}`;

					return (
						<div className={classes.bigHighlightedBlock}>
							<div className={classes.leftHighlightedBlock}>
								<img
									className={classes.highlightImg}
									alt={'Project image'}
									src={project.image ? this.displayUrlLink(project.image) : DEFAULT_PROJECT_IMG_URL}
								/>
							</div>
							<div className={classes.rightHighlightedBlock}>
								<div className={classes.featuredProjectContent}>
									<div>
										<small>Latest project</small>
										<h3>{project && project.name}</h3>
									</div>
								</div>
								<p
									style={!userHasSignedNDA ? (
										{ color: 'transparent', textShadow: '0 0 5px rgba(0,0,0,0.5)', position: 'relative'}
									) : (
										{}
									)}
								>
									{(projectDescription && projectDescription.length > 160)
										? projectDescription.substring(0, 154) + '...'
										: projectDescription
									}
									{!userHasSignedNDA &&
										<TooltipIcon
											tooltipText={'Sign NDA to unlock more project’s info'}
											direction="bottom"
											style={{position: 'absolute', top: '1rem', right: '1rem', width: '16px'}}
										>
											<div className={classes.circleLocked}>
												<Locked16 />
											</div>
										</TooltipIcon>
									}
								</p>
								<div style={{ margin : '16px 0' }}>
									<h6>Available slots for each phase</h6>
									{project.contestOrder && this.displayAvailableContestsOptions(project.contestOrder)}
								</div>
								<div className={classes.bottomBtnsContainer}>
									<Link to={`/project/${project.id}`} style={{textDecoration: 'none'}}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<h5 style={{color: '#df3d3b'}}>Explore project</h5>
											<ChevronRight20 />
										</div>
									</Link>
								</div>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayAvailableContestsOptions(contests) {
		const { viewPhaseSlots } = this.state;
		const uniqueContests = [...new Set(contests)];

		return (
			<div className={classes.contributorsList}>
				{uniqueContests.map((contestType, i) => (
					<div
						key={i}
						className={classnames([classes.phaseLines, (viewPhaseSlots && viewPhaseSlots.contest === contestType) && classes.openPhase])}
						onMouseEnter={() =>
							setTimeout(function() {
								this.setState({ viewPhaseSlots: { contest: contestType }});
							}.bind(this), 90)
						}
						onMouseLeave={() => {
							this.setState({ viewPhaseSlots: null });
							setTimeout(function() {
								const { viewPhaseSlots } = this.state;
								if (viewPhaseSlots.contest === contestType) {
									this.setState({ viewPhaseSlots: null });
								}
							}.bind(this), 94);
						}}
						style={{ backgroundColor: CONTEST.getPhaseColor(PROJECT.getStateByContestType(contestType)) }}
					>
						{(viewPhaseSlots && viewPhaseSlots.contest === contestType) ? (
							<Fragment>
								<span>{CONTEST.getContestMaxValidProposals(contestType)} slots available</span>
								<h6>{strings.capitalizeFirstLetter(contestType)}</h6>
							</Fragment>
						) : (
							<Fragment>
								<h6>{strings.capitalizeFirstLetter(contestType.substring(0, 2))}</h6>
								<span>{CONTEST.getContestMaxValidProposals(contestType)}</span>
							</Fragment>
						)}
					</div>
				))}
			</div>
		);
	}

	displayUrlLink(image) {
		return serverAPI.getThumbnailUrlByFile(image);
	}

	renderExtraOptions() {
		return (
			<div className={classes.secondaryBtnContainer}>
				<div>
					<h5 className={classes.subtitle}>Didn’t find what you are looking for?</h5>
				</div>
				<div className={classes.extraContainer}>
					<ExtraOption
						name={'Explore the case studies'}
						description={'Explore the case studies of the projects created by experts like you, where all processes and final prototypes are explained.'}
						icon={<TaskStar24 />}
						submitText={'Visit our case studies page'}
						redirectTo={'https://lastbasic.com/case-studies'}
						urlType={'new-page'}
					/>
					<ExtraOption
						name={'Learn from the Knowledge Hub'}
						description={'The Knowledge hHb content will help you understand better what steps you’re about to take with us.'}
						icon={<PresentationFile24 />}
						submitText={'Visit the knowledge hub page'}
						redirectTo={'https://lastbasic.com/knowledge-hub'}
						urlType={'new-page'}
					/>
					<ExtraOption
						name={'Contact support'}
						description={'We’re available Monday - Friday, 9 a.m - 7 p.m CEST'}
						icon={<Email24 />}
						submitText={'Open support chat request'}
						onClick={() => window.Froged('open', 'inbox')}
						urlType={'widget'}
					/>
					<ExtraOption
						name={'Connect with other experts'}
						description={'Connect on the Forum, share and get help from other experts like you.'}
						icon={<Chat24 />}
						submitText={'Go to LB Experts'}
						redirectTo={'https://lbexperts.lastbasic.com/'}
						urlType={'new-page'}
					/>
					<ExtraOption
						name={'Learn about your role as an expert'}
						description={'Learn about your role and how you can make the best of it.'}
						icon={<Credentials24 />}
						submitText={'Go to Role page'}
						redirectTo={'/roles-and-responsibilities'}
						urlType={'self'}
					/>
				</div>
			</div>
		);
	}
	
	render() {
		const { userData: { user } } = this.props;
		return (
			<div className={classes.projectList}>
				<h2 className={classes.title}>Hello {user.firstName}!</h2>
				<p>What are you going to make today?</p>
				{this.renderCompleteProfileInfo()}
				{this.renderMyActivity()}
				{this.renderLastProject()}
				{this.renderParticipatingProjects()}
				{this.renderExtraOptions()}
			</div>
		);
	}
}

HomePage.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(HomePage);
