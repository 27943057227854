import { gql } from '@apollo/client';

const rankingFragment = gql`
	fragment FeedbackInfoDetails on FeedbackInfo {
		pros,
		cons,
		keepFeatures,
		removeFeatures
	}
	fragment FeedbackDetails on Feedback {
		rating,
		info {
			...FeedbackInfoDetails
		}
	}
	fragment RankingProposal on RankingProposal {
		id,
		number,
		files,
		feedback {
			...FeedbackDetails
		},
	}
	fragment RankingContest on RankingContest {
		id,
		type,
		selectedProposalId,
		proposals {
			...RankingProposal
		},
	}
`;

export {
	rankingFragment,
};