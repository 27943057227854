import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Information16 } from '@carbon/icons-react';
import classnames from 'classnames';

import { FormUtils } from '../../../utils';

import classes from './setup-questions.module.scss';

class ViewSetupQuestions extends Component {
	render() {
		const { questions, noAnswersMessage, onOpenQuestionsModal } = this.props;

		const doOnboardingQuestionsHaveAnswer = FormUtils.isAtLeastOneQuestionAnswered(questions);
		if (!doOnboardingQuestionsHaveAnswer) {
			if (!noAnswersMessage) return null;

			return (
				<div className={classes.marginHeightMedium}>
					<div style={{display: 'flex', alignItems: 'baseline'}}>
						<h5 className={classes.titleSpace}>Set up questions</h5>
						<span
							className={classes.secondaryLink}
							style={{marginLeft: '8px'}}
							onClick={onOpenQuestionsModal}
						>
							Complete set up
						</span>
					</div>
					<div className={classes.messageIcon}>
						<Information16 />
						<small>{noAnswersMessage}</small>
					</div>
				</div>
			);
		}

		return (
			<Fragment>
				{questions.map((question) => (
					<div className={classnames(classes.marginHeightMedium, classes.width)} key={question.id}>
						<h5 className={classes.titleSpace}>{question.question}</h5>
						<div className={classes.marginHeightShort}>
							{(question.answer.length > 1) ?	(
								<ul>
									{question.answer.map((a, i) => (
										<p key={i}>
											{a}
										</p>
									))}
								</ul>
							) : (
								<p>
									{(question.answer.length === 0)
										? 'N/A'
										: question.answer[0]
									}
								</p>
							)}
						</div>
					</div>
				))}
			</Fragment>
		);
	}
}

ViewSetupQuestions.propTypes = {
	questions: PropTypes.array.isRequired,
	noAnswersMessage: PropTypes.string,
	onOpenQuestionsModal: PropTypes.func,
};

ViewSetupQuestions.defaultProps = {
	questions: [],
	noAnswersMessage: 'You don\'t have any answers.',
	onOpenQuestionsModal() {},
};

export default ViewSetupQuestions;
