import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

import ErrorBoundaryQuery from '../../components/graphql/queries-with-errors';

import { Tag, TooltipDefinition, TooltipIcon, SkeletonPlaceholder } from 'carbon-components-react';
import { ChevronRight16 } from '@carbon/icons-react';

import { PROJECT, CONTEST, EXPERT } from '../../constants';
import { GET_CONTESTS_INVOLVED } from '../../providers/queries';
import { serverAPI, strings } from '../../utils';
import KEYS from '../../glossary';

import classes from './project-element.module.scss';

const DEFAULT_PROJECT_IMG_URL = '/assets/images/logo-lb-project.png';

class ProjectWithParticipationInfo extends Component {
	constructor (props) {
		super(props);

		this.state = {

		};
	}

	displayUrlLink(image) {
		return serverAPI.getThumbnailUrlByFile(image);
	}

	displayContestsLines(contestsExpertHasParticipated) {
		const contestPhases = Object.values(PROJECT.getContestStates());

		return (
			<div className={classes.contributorsList}>
				{contestPhases.map((contestState, i) => (
					<TooltipIcon key={i}
						tooltipText={`${strings.capitalizeFirstLetter(PROJECT.getContestTypeByState(contestState))} contest`}
						direction="bottom"
					>
						<div
							className={classes.phaseLines}
							style={contestsExpertHasParticipated.indexOf(PROJECT.getContestTypeByState(contestState)) >= 0
								? {backgroundColor: CONTEST.getPhaseColor(PROJECT.getStateByContestType(PROJECT.getContestTypeByState(contestState)))}
								: {}
							}
						>
							{strings.capitalizeFirstLetter(contestState.substring(0, 2))}
						</div>
					</TooltipIcon>
				))}
			</div>
		);
	}

	render() {
		const { project, userData: { user } } = this.props;

		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;
		const projectIsInActivePhase = project.state !== PROJECT.STATE.CLOSED && project.state !== PROJECT.STATE.CLIENT_ONBOARDING;

		const variables = {
			filter: '',
			page: 0,
			pageSize: 20,
		};

		return (
			<ErrorBoundaryQuery query={GET_CONTESTS_INVOLVED} variables={variables}>
				{({ loading, error, data }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.contests}>
								<SkeletonPlaceholder className={classnames([classes.projectCard])} style={{ minHeight: 'fit-content' }} />
								<SkeletonPlaceholder className={classnames([classes.projectCard])} style={{ minHeight: 'fit-content' }} />
							</div>
						</div>
					);

					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];
					const result = data[keyName];

					const contests = (result && result.contests) || [];
					const contestsExpertHasParticipated = EXPERT.getContestsExpertHasParticipated(contests, user.id, project.id);
					const totalEarningsExpertHasWonInThisProject = EXPERT.getTotalEarningsExpertHasWonInThisProject(contests, user.id, project.id);
					const totalCreditsExpertHasWonInThisProject = EXPERT.getTotalCreditsExpertHasWonInThisProject(contests, project.id);

					return (
						<div className={classes.projectCard}
							// to be defined
							// onClick={() => window.Froged('track', 'click_project_card')}
							style={{ minHeight: 'fit-content' }}
						>
							<Link
								to={'/project/' + project.id}
								className={classes.projectLink}
							>
								<div className={classes.imageContainer + ' ' + classes.smallerImageContainer}>
									<img
										className={classes.cardImage}
										style={{ height: '96px' }}
										alt={KEYS.img_alt}
										src={project.image ? this.displayUrlLink(project.image) : DEFAULT_PROJECT_IMG_URL}
									/>
								</div>
								<div className={classes.bottomBlock}>
									<div className={classes.content}>
										<div className={classes.titleAndButtons} style={{alignItems: 'center'}}>
											<div className={classes.titleContainer}>
												{(project.name.length > 15)
													? <TooltipDefinition tooltipText={strings.capitalizeFirstLetter(project.name)} direction="top">
														<h4>{strings.capitalizeFirstLetter(project.name.substring(0, 13)) + '...'}</h4>
													</TooltipDefinition>
													: <h4>{strings.capitalizeFirstLetter(project.name)}</h4>
												}
											</div>
											<Tag type="blue" style={{borderRadius: '4px', color: '#fff', backgroundColor: CONTEST.getPhaseColor(project.state)}}>{PROJECT.stateToStringShort(project.state)}</Tag>
										</div>
										<div className={classes.extraContent}>
											<div className={classes.infoElement}>
												<small>
													{projectIsFinished
														?	'Your participation'
														:	'Status'
													}
												</small>
												<h4>
													{projectIsFinished
														?  this.displayContestsLines(contestsExpertHasParticipated)
														: (projectIsInActivePhase) && 'Active'
													}
												</h4>
											</div>
											{(projectIsInActivePhase) &&
												<>
													<div className={classes.infoElement}>
														<small>Reward</small>
														<h4>{CONTEST.getContestCredits(PROJECT.getContestTypeByState(project.state))}</h4>
													</div>
													<div className={classes.infoElement}>
														<small>Prize</small>
														<h4>{CONTEST.getContestPrizes(PROJECT.getContestTypeByState(project.state))}</h4>
													</div>
												</>
											}
											{projectIsFinished &&
												<div className={classes.infoElement}>
													<small>Your total earnings</small>
													<h4>
														{totalEarningsExpertHasWonInThisProject > 0
															? `${totalEarningsExpertHasWonInThisProject} EUR`
															: `${totalCreditsExpertHasWonInThisProject} credits`
														}
													</h4>
												</div>
											}
										</div>
									</div>
									<div className={classes.actionBox}
										style={{ padding: '8px 16px', backgroundColor: CONTEST.getPhaseColor(project.state), borderRadius: '0 0 8px 8px' }}
									>
										<h5 className={classes.white}>
											Open project
										</h5>
										<ChevronRight16 className={classes.white} />
									</div>
								</div>
							</Link>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ProjectWithParticipationInfo.propTypes = {
	project: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

ProjectWithParticipationInfo.defaultProps = {
	project: {},
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectWithParticipationInfo);
