import { gql } from '@apollo/client';

const fileFragment = gql`
	fragment FileDetails on File {
		id,
		name,
		size,
		extension,
		mimeType,
		url,
		createdAt,
		updatedAt
	}
`;

export {
	fileFragment,
};