export default {

	// EXPERT SIDE
	// NAVBAR
	navbar_home: 'Home',
	navbar_board: 'My Board',
	navbar_explore: 'Explore projects',
	navbar_contests: 'Open contests',
	navbar_community: 'LB Experts',
	navbar_how_it_works: 'How it works',
	navbar_projects: 'My projects',
	navbar_project: 'My project',
	navbar_rankings: 'Rankings',
	navbar_files: 'All files',
	navbar_updates: 'Updates',
	navbar_start_here: 'Getting started',
	navbar_referral: 'Invites',
	navbar_validate_projects: 'Validate projects',

	navbar_option_profile: 'Profile',
	navbar_option_account: 'Account',
	navbar_option_my_projects: 'My projects',
	navbar_option_logout: 'Log out',

	navbar_user_type_maker: 'Inventor',
	navbar_user_type_expert: 'Expert',

	// HOME
	sketch_role_description: 'You are a visionary. You can take the client’s idea and bring that idea to life in a visual way. You take the client’s needs and provide them with sketch proposals that have clear and understandable user experiences to them. Your sketches communicate clearly the product itself as well the features required to make the idea a reality. You provide the client with options for how the product will look and be used by the customer. You love to communicate technical ideas through simple and intuitive sketches.',
	modeling_role_description: 'You are a creator. You can take the winning sketch proposal and bring it closer to reality in a tangible way. You understand the constraints of product design and what is feasible from a material and construction perspective. You are in charge of defining the product size and location of all the external features of the product in 3D. You think in a spatial way and you can provide the client with clear proposals on the size, shape, and feel of the product with all the intended features. You love bringing ideas to life in 3D.',
	electrical_engineer_role_description: 'You are a problem solver. You can take a client feature list and communicate the electrical components required to build the prototype. You think in a systematic way and understand how your design decisions will affect the product cost, battery life, and how the product will interact with the user. You can provide the client with technical solutions for their user experience that equate to the use of either readily available electronics or custom PCBs. You love solving hardware problems with elegant and simple solutions.',
	mechanical_engineer_role_description: 'You are an integrator. You can take all the given constraints and bring them all together into a functional solution. You are a systems thinker and you know how products are made and assembled. You understand the level of detail required to achieve a functional prototype that will be reliable and easy to use for the client. You can take electrical requirements and integrate them into enclosures so that the intended design and user experience is maintained. You love to take a design to the finish line.',
	production_engineer_role_description: 'You are a builder. You can take final design files and provide a clear proposal on how the prototype will be made, assembled, and finished. You are nimble and agile and communicate clearly options to the client on what is feasible for prototyping versus production intent designs. You can troubleshoot issues that come up during the manufacturing process. You follow through on your quote by delivering a high quality prototype in the proposed delivery time and cost. You love to build and bring new ideas to life.',

	// PROJECTS LIST PAGE --> /projects
	project_list_title: 'Project List',
	explore_title: 'Explore projects',

	// Filter tags
	all: 'All',
	favorites: 'My favorites',

	// Project card
	participating: 'Participating',
	open_contest: 'Open contest',
	close_contest: 'Closed contest',
	img_alt: 'Project image',
	finished: 'Finished',
	review_contest_card: 'Review contest',

	// Project specs
	has_iot: 'IoT',
	has_actuators: 'Actuators',
	electronics: 'Electronics',

	// PROJECT DETAILS PAGE --> /project/:id
	breadcrumb_index: 'Project List',
	project_cover_alt: 'Project cover image',
	project_code: 'Code',
	project_complexity: 'Product complexity',
	project_Me_complexity: 'Mechanical complexity',
	project_creation_date: 'Creation date',
	project_country: 'Idea from',

	// Project description
	project_description_title: 'Product description',
	project_description_text_1: 'The client would like to make a functional prototype of their idea for a ',
	project_description_text_2: ' including the ideation, design, and development of their original vision for the product.',
	project_description_text_3: 'The ',
	project_description_text_4: ' is intended to solve the problem that ',

	// Project status
	project_status_title: 'Project status',
	project_status_phase: 'Phase',
	project_message_no_contest_available: 'No contest available',
	project_status_condition: 'Condition',
	project_status_slots: 'Available slots left',
	project_status_contest_deadline: 'Deadline',
	project_status_contest_deadline_nd: 'N/D',
	project_status_contest_status: 'Status',
	project_status_contest_status_upload: 'Upload your proposals',
	project_status_contest_status_open: 'Open to join',
	project_status_contest_status_close: 'Contest is closed',
	project_status_starting_kit: 'Starting kit',
	project_status_starting_kit_link: 'Open kit',
	project_status_join_contest: 'Join contest',

	// Project status notifications (Errors)
	project_status_notification_success: 'You’ve already joined this contest',
	project_status_notification_contest_close: 'Submissions are closed',
	project_status_notification_no_contest_available: 'No contest available',
	project_status_notification_error_joining: 'Error joining project',

	// Contributors section
	project_contributors_title: 'Participants',

	// Overview section
	overview_title: 'Overview',
	proposal_overview_alt: 'Contest proposal thumbnail',
	proposal_open: 'Open Contest: Upload your proposals.',
	proposal_empty: 'No proposals yet',
	quotation_empty: 'No quotations yet.',
	contest_not_open_yet: 'Contest not open yet',

	// Tabs
	tab_materials: 'Materials',
	tab_guidelines: 'Guidelines',
	tab_updates: 'Updates',
	tab_entries: 'Upload proposal', // Your uploads
	tab_inspiration: 'Inspiration',

	// More details section (contests is open, expert is not in)
	project_more_details_title: 'More details',
	project_more_details_join_contest_text: 'Join contest to unlock everything!',

	// Materials tab section
	project_briefing: 'Project briefing',
	project_briefing_text: 'Document with all of the details regarding the project briefing.',
	raw_material: 'Client’s raw materials',
	raw_material_text: 'Materials uploaded by the client',
	first_sketch_file: 'First sketch file',
	contest_materials: 'Contest materials',
	contest_materials_text: 'Shared files from the previous contests’ winning proposals.',
	no_extra_files: 'No shared files available.',

	// Guidelines tab section
	guidelines_text: 'Follow the LastBasic process to create a winning proposal.',
	templates_text: 'Follow the proper LastBasic templates for making your proposal.',
	contest_guide_file: 'Contest guide',
	integration_guide_file: 'Integration guide',
	contest_template_file: 'Contest proposal template',

	// Entries tab section
	my_proposals: 'My proposals',
	my_quotations: 'My quotations',
	proposals_text: 'Upload your proposal. It is possible to upload more than one proposal, but the client will only choose one winner.',
	quotation_text: 'Upload your quotation. It is possible to upload more than one quotation, but the client will only choose one winner.',

	drag_and_drop_text: 'Upload your proposal',
	download_proposal_template: 'Download the proposal template',

	all_documents: 'All my documents',
	all_documents_text: 'Proposals and files uploaded by you.',

	winning_proposal: 'Winning proposal',
	proposal_status_approved: 'Approved',
	proposal_status_approved_tooltip: 'Your proposal has been approved',
	proposal_status_pending_client_feedback: 'Pending client feedback',
	proposal_status_pending_client_feedback_tooltip: 'Waiting for client feedback',
	proposal_status_rejected: 'Rejected',
	proposal_status_rejected_tooltip: 'Review our comments to improve your proposal',
	proposal_status_pending_validation: 'Pending validation',
	proposal_status_pending_validation_tooltip: 'Your proposal is pending validation',

	shared_files: 'Shared files',
	shared_files_text: 'As you have a winning proposal, here you can upload the necessary supplementary files.',
	add_more_files: 'Add more files',
	add_more_files_description: 'Maximum size per file of 100 MB',
	files_error_exceeds_limit: 'File size exceeds limit',
	files_alert_contest_close: 'You are not able to upload more files because the contest is closed.',

	proposal_feedback_comment: 'Read carefully the LastBasic review to improve your proposal',

	// Entries notifications (errors)
	upload_failed: 'Upload failed',
	entries_notification_upload_file_error: 'The file was not uploaded correctly',
	upload_success: 'Upload success',
	entries_notification_upload_file_success: 'The file was uploaded correctly.',
	notification_join_contest_to_upload_files: 'You need to join the contest to upload files.',
	notification_files_contest_close: 'You are not able to upload files because the contest is closed.',

	// Contest modal
	contest_modal_title: 'This is your starting point',
	contest_modal_label: 'Contest info',
	contest_modal_alt: 'Contest modal',
	contest_modal_button_1: 'Confirm & join contest!',
	contest_modal_button_2: 'Join contest',

	contest_modal_title_1: 'The challenge',
	contest_modal_go_back: 'Go back',

	contest_modal_title_2: 'You are about to join this contest. Remember:',
	contest_modal_text_1: 'Read the contest card to understand the client requirements.',
	contest_modal_text_2: 'Use correctly the LastBasic template.',
	contest_modal_text_3: 'Follow the how-to guidelines for each contest.',
	contest_modal_text_4: 'Meet the contest deadlines and submit your proposal on time.',
	contest_modal_text_5: 'LastBasic will apply the incentives program to this contest.',
	contest_modal_text_6: 'Remind yourself you are saving your spot in this contest, spots are limited.',
	contest_modal_text_7: 'You are accepting LastBasic’s ',
	contest_modal_text_8: 'for participation.',

	contest_modal_title_3: 'By joining this contest you are accepting the following terms:',
	contest_modal_text_9: 'You can send 2 different proposals to be reviewed and validated.',
	contest_modal_text_10: 'Only proposals sent within the deadline will be reviewed and validated.',
	contest_modal_text_11: 'You will be informed about the status of your submission/s.',
	contest_modal_text_12: 'Once your proposal/s are validated you will generate a corresponding LastBasic credit.',
	contest_modal_text_13: 'You can check your credit and the conditions of the incentives program in your personal account profile.',
	contest_modal_text_14: 'The materials you will use are confidential and protected by the NDA agreement you have signed.',
	contest_modal_text_15: 'The client will receive all the validated proposals, will provide feedback on each and select a winner.',
	contest_modal_text_16: 'You will be informed about the contest results.',
	contest_modal_text_17: 'You will be able to see the feedback received in your portfolio.',
	contest_modal_text_18: 'You will be able to review all the proposals from other participants and their client’s feedback once the contest is closed and only if you are active participant.',

	// Proposal feedback modal
	proposal_feedback_modal_label: 'Proposal feedback',
	proposal_feedback_modal_no_data: 'No data available.',
	proposal_feedback_modal_ratings: 'Rating',
	proposal_feedback_modal_rating_description: 'Rate this proposal between 1-5 stars, being 5 the highest rate.',
	proposal_feedback_modal_pros: 'Pros',
	proposal_feedback_modal_pros_description: 'Highlight to the expert the 3 things you like most about the proposal.',
	proposal_feedback_modal_pros_textarea_placeholder: 'Color, size, concept...',
	proposal_feedback_modal_cons: 'Cons',
	proposal_feedback_modal_cons_description: 'Highlight to the expert the 3 things you think could be improved about the proposal.',
	proposal_feedback_modal_cons_textarea_placeholder: 'Design, UX, clarity...',
	proposal_feedback_modal_invalid_value: 'It is required to add a response',
	proposal_feedback_modal_features_keep: 'Features that are missing from your initial design vision',
	proposal_feedback_modal_features_remove: 'Features that you would like the expert to remove from the proposal',
	proposal_feedback_modal_next_steps: 'Your next steps',
	proposal_feedback_modal_next_steps_description: 'Now you can upload the necessary files to supplement your proposal. Please see contest guide for details.',
	proposal_feedback_modal_next_steps_action: 'Close and upload new files',
	proposal_feedback_modal_alert_winner_expert_title: 'Congratulations, your proposal has been selected as the winning one!',
	proposal_feedback_modal_alert_winner_expert_description: 'You have earn money for your hard work.',

	// Starting kit modal
	starting_kit_modal_header: 'What to do next',

	starting_kit_modal_content_title_1: 'What you will find in this project page',
	starting_kit_modal_content_overview_subtitle: 'OVERVIEW:',
	starting_kit_modal_content_overview_text: 'where you will see all the contest proposals of the project.',
	starting_kit_modal_content_materials_subtitle: 'MATERIALS:',
	starting_kit_modal_content_materials_text: 'where you will find the project briefing, raw materials, and shared files of the project.',
	starting_kit_modal_content_guidelines_subtitle: 'GUIDELINES:',
	starting_kit_modal_content_guidelines_text: 'where you will find the contest phase templates and contest guides.',
	starting_kit_modal_content_updates_subtitle: 'UPDATES:',
	starting_kit_modal_content_updates_text: 'where you will see the project updates.',
	starting_kit_modal_content_entries_subtitle: 'UPLOAD PROPOSAL:',
	starting_kit_modal_content_entries_text: 'where you can upload files and proposals.',

	starting_kit_modal_content_title_2: 'Recommendations',
	starting_kit_modal_content_recommendation_text_1: 'Read carefully the project information to have a whole context of the project.',
	starting_kit_modal_content_recommendation_text_2: 'Pay attention to deadlines.',
	starting_kit_modal_content_recommendation_text_3: 'Use our templates and guidelines to upload proposals.',
	starting_kit_modal_content_recommendation_text_4: 'Review your proposal feedback in "UPLOAD PROPOSAL".',

	starting_kit_modal_content_title_3: 'How to upload a proposal',
	starting_kit_modal_content_upload_text_1: 'Check the information in "GUIDELINES".',
	starting_kit_modal_content_upload_text_2: 'Create a proposal following the requirements.',
	starting_kit_modal_content_upload_text_3: 'Upload your file in the "UPLOAD PROPOSAL" tab.',
	starting_kit_modal_content_upload_text_4: 'If you have a winning proposal, you will be able to upload more files to supplement your proposal.',

	// CLIENT SIDE
	// How it works page --> /how-it-works
	how_it_works_title: 'How it works',
	how_it_works_subtitle: 'How does the platform work',
	how_it_works_section_1_title: 'Projects and Contests',
	how_it_works_section_1_description: 'Main concepts',
	how_it_works_section_1_step_1: 'A complete LastBasic project has 5 phases.',
	how_it_works_section_1_step_2: 'The order of the contests are: Sketch, 3D modeling, electrical, mechanical, prototyping.',
	how_it_works_section_1_step_3: 'Once a contest is opened, any qualified LastBasic expert can join and submit proposals.',
	how_it_works_section_1_step_4: 'Submitted proposals are then rated by you.',
	how_it_works_section_2_title: 'Ratings',
	how_it_works_section_2_description: 'How to rate experts’ proposals',
	how_it_works_section_2_step_1: 'Review each proposal individually and give feedback to each one.',
	how_it_works_section_2_step_2: 'Rate the proposal with 1 to 5 stars.',
	how_it_works_section_2_step_3: 'You can at any time see other proposals to compare them.',
	how_it_works_section_3_title: 'Feedback and selecting a winner',
	how_it_works_section_3_description: 'How to provide feedback to the experts',
	how_it_works_section_3_step_1: 'Once a proposal is submitted, you will be able to rate and provide feedback on them.',
	how_it_works_section_3_step_2: 'In feedback you can highlight the pros and cons of the proposal, and what features you think are missing and those that need to be removed.',
	how_it_works_section_3_step_3: 'After all the proposals are received, rated, and with feedback, you will be able to select the one you like the most as the winner.',

	// How it works page (Expert side)
	how_it_works_section_1_step_5: 'Submitted proposals are then ranked by the client.',
	how_it_works_section_2_title_expert: 'Validation, ranking, feedback, winner',
	how_it_works_section_2_description_expert: 'How does the client provide rating to the proposals?',
	how_it_works_section_2_step_1_expert: 'Your submitted proposals are validated by the LastBasic Team and then shared with the client.',
	how_it_works_section_2_step_2_expert: 'The client provides individual feedback and rating to each proposal.',
	how_it_works_section_2_step_3_expert: 'The winning proposal will be notified of next steps.',
	how_it_works_section_2_step_4_expert: 'All rated proposals are then published for the participating experts to view.',
	how_it_works_section_3_title_expert: 'Winning a contest',
	how_it_works_section_3_description_expert: 'What do I need to do if I win a contest?',
	how_it_works_section_3_step_1_expert: 'You will be notified of your reward and how to get paid by the LastBasic Team.',
	how_it_works_section_3_step_2_expert: 'Depending on the contest, you will be required to finalize the design proposal and upload the supplementary files to the Shared Files section of the platform.',

	// My project page --> /project/:id/owner
	my_project_title: 'My project',

	// Project feasibility
	project_feasibility_pending_payment_alert: 'Your project is feasible! In order to proceed and start your project journey we need you to complete the payment.',
	project_feasibility_pending_feasibility_message: 'Please allow us up to 48 hours to validate your project and continue with the process.',
	project_feasibility_pending_feasibility_message_call: 'However, we can start talking about your project and explore how can we make it possible together (your requirements, advice, and financing).',
	project_feasibility_pending_feasibility_message_call2: 'Imagine: you in 6 months with a fully functional prototype of your invention.',
	project_feasibility_pending_feasibility_message_call3: 'Let\'s talk and make it happen!',
	project_feasibility_non_feasible_message_option_A: 'Please don\'t feel discouraged for your project not being feasible. We may just be lacking some additional information and we would recommend that you close a video call with us to find out what requirements are needed to make it feasible. Please reserve a slot with us below. Thank you.',
	project_feasibility_non_feasible_message_option_B: 'Sadly, we couldn\'t set your project as feasible for us. Please read what we can and what we can\'t do and fill out another briefing form following those requirements. Thank you.',

	// Pending payment
	project_pending_payment_message: 'Our engineers have marked your project as feasible and we would love to make it a reality together.',
	project_pending_payment_message2: 'In an average of 6 months, you can have a full working prototype of your idea sent to your doorstep.',
	project_pending_payment_message3: 'However, you can explore more economic options, by doing it in stages: first getting a sketch design  to visualize your project and even add a 3D design to make it look nicer, leaving the engineering and assembly for a later stage.',
	project_pending_payment_message4: 'Then you can decide if you continue with the development of the whole prototype, or you just want to have materials for a crowdfunding campaign, landing page, showing friends...',

	// Project onboarding
	project_onboarding_description: 'It is time to start your prototyping journey, but first we need you to review and accept the documents below.',
	project_onboarding_step_1: 'Project briefing',
	project_onboarding_step_2: 'Raw materials',
	project_onboarding_step_3: 'More details',
	project_onboarding_step_4: 'Confirmation',
	project_onboarding_step_1_title: 'Project briefing',
	project_onboarding_step_1_description: 'Document with all the details regarding your project.',
	project_onboarding_step_2_title: 'Raw materials',
	project_onboarding_step_2_description: 'Any additional materials uploaded by you.',
	project_onboarding_step_4_title: 'What is next?',
	project_onboarding_step_4_description: 'You have confirmed the requirements to set up your project. Please stay tune to your inbox to receive the notification to the contest card.',

	// Project onboarding modal
	project_onboarding_title: 'Onboarding',
	project_onboarding_modal_header: 'Project set up confirmation',
	project_onboarding_modal_title: 'Are you sure?',
	project_onboarding_modal_description: 'Welcome to the Onboarding phase for your project. The purpose of this phase is to align LastBasic with your prototype requirements. These requirements will then be converted into contest cards, which will guide our experts to give you the best proposals. These main requirements should stay consistent along the project, but you can make small adjustments after each expert contest.',

	// Phase contest details --> /project/:id/owner
	phase_contest_details_project_set_up_description: 'Thank you for confirming your project. Now you are in the project setup phase where the LastBasic team is generating your Contest Card. You will be notified when the Contest Card is ready.',
	phase_contest_details_sketch_description: 'The purpose of the Sketch contest is to give you a clear visual direction for your prototype, taking into account your desired user experience and product feature requirements. LastBasic Sketching experts will soon be upload proposals so you can review them, provide feedback, and select a winner.',
	phase_contest_details_model_description: 'Welcome to the 3D Design phase of your project! In this contest you will have a clear 3D direction for your prototype taking into account your desired form and function of your product, feature set, and the winning sketch from the previous phase. Our experts in 3D modeling will be be uploading proposals. You then will be able to review them, provide feedback, and select the one you like the most as the winning one.',
	phase_contest_details_electrical_description: 'The Electrical contest will give you a clear direction for your prototype taking into account your desired user experience that require electrical solutions. LastBasic Electrical experts, based on the 3D modeling winning proposal, will be uploading their proposals. After rating them, you will be able to select a winner and move to the next phase.',
	phase_contest_details_mechanical_description: 'This is the Mechanical Design phase of your project. Here you will gain a clear direction of how the design proposals will be integrated into a functional prototype. Our Mechanical Experts will soon be uploading proposals so you can rate them, select a winner, and move into the next phase.',
	phase_contest_details_prototyping_description: 'This is the last phase! Here all the knowledge gained during the previous phases is gathererd. In this Prototyping phase, you will have a clear direction on how your prototype will be made, sourced, and assembled. LastBasic Prototyping experts will soon be uploading proposals so you can review them, rate them, provide feedback, and select a winner.',

	phase_contest_details_sketch_description_alt: 'The intent of the sketch contest is to help you visualize your idea with all your defined and desired features in a clear way and how the user will interact with it (the ideal user experience). The proposals should merge the original vision from you combined with the expert\'s perspective on the best way to develop your prototype given the technical constraints of your design choices.',
	phase_contest_details_model_description_alt: 'The intent of the 3D contest is to convert the winning sketch vision into a realistic 3D model and include the proper size and space for the necessary electronics provided by the winning EE proposal to achieve the desired user experience. The goal of the 3D Design contest is to give you a realistic picture of what your prototype will look like.',
	phase_contest_details_electrical_description_alt: 'The intent of the electrical engineering contest is to clearly define the system architecture of the device including how it will produce and maintain the required product user experience. The goal of the electrical engineering contest is to provide you with a clear proposal on the electronics required to achieve your desired user experience.',
	phase_contest_details_mechanical_description_alt: 'The intent of the Mechanical Engineering Contest is to provide you with a finalized proposal on how the winning EE design and 3D design will be integrated into a functional prototype. The goal of the contest is to do the detailed engineering work to achieve the design intent, including communicating what will be their material and finish for prototyping, and any additional design updates that are needed to make the design fully functional.',
	phase_contest_details_prototyping_description_alt: 'The intent of the prototyping contest is to provide you with a clear RFQ (Request for Quote) proposal for how the whole prototype will be sourced, fabricated, and assembled.  The goal of the contest is to deliver you a final prototype that meets your requirements and the quality levels defined throughout the development process.',

	// Contest card and modal
	contest_card_header: 'Contest card',
	contest_card_confirm_btn: 'Confirm contest card',
	contest_card_date: 'Dates',
	contest_card_special_attention: 'Pay special attention to',
	open_contest_card: 'Open contest card',
	contest_card_alt_img: 'Contest card',
	contest_card_confirmation_alert: 'Please confirm the contest card to start the contest.',
	contest_card_confirmation_btn: 'Open card details to confirm',

	// Proposals section on project
	proposals_header: 'Proposals',
	proposals_waiting_alert: 'The LastBasic experts are joining your contest and preparing their proposals for you to rank. As soon as their proposals are validated, you will start seeing them here',
	proposal_pros_title: 'Pros',
	proposal_cons_title: 'Cons',
	proposal_keepFeatures_title: 'Features missing from my original idea',
	proposal_removeFeatures_title: 'Features I would like to discuss with the team',
	proposal_check_feedback_title: 'Check my feedback',
	proposal_title: 'Proposal',
	proposals_winning_proposal_notification: 'Congrats! You have selected a winning proposal for this contest!',

	// Quotations on prototyping phase
	quotation_title: 'Quotation',

	// Rankings page --> /rankings
	project_rankings_title: 'Rankings',

	// Updates page --> /updates
	project_updates_title: 'Updates',
	project_updates_empty: 'There are no updates yet.',

	// Tag labels
	soon: 'Soon',

	// Global actions
	open: 'Open',
	open_file: 'Open file',
	download_file: 'Download file',
	show_feedback: 'Show feedback',
	give_feedback: 'Give feedback',
	show_comments: 'Show comments',
	cancel: 'Cancel',
	submit_feedback: 'Submit feedback',
	request_change: 'Request change',
	not_ranked: 'Not rated',
	next: 'Next',
	confirm: 'Confirm',
	confirm_project: 'Confirm and start!',
	confirm_data: 'Looks good!',
	previous: 'Go back',
	save: 'Save',
	update: 'Update',
	edit: 'Edit',
};
