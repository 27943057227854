import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'carbon-components-react';
import KEYS from '../../glossary';

import classes from './modals.module.scss';

class ProjectSetUpConfirmationModal extends Component {
	render() {
		const {
			open,
			onRequestClose,
			onSecondarySubmit,
			onRequestSubmit
		} = this.props;

		return (
			<Modal
				open={open}
				passiveModal={false}
				modalLabel={KEYS.project_onboarding_title}
				primaryButtonText={KEYS.confirm_data}
				secondaryButtonText={KEYS.cancel}
				modalHeading={KEYS.project_onboarding_modal_header}
				onRequestSubmit={onRequestSubmit}
				onSecondarySubmit={onSecondarySubmit}
				onRequestClose={onRequestClose}
			>
				<div className={classes.modalContent}>
					<div className={classes.contestContent}>
						<h5>{KEYS.project_onboarding_modal_title}</h5>
						<p>{KEYS.project_onboarding_modal_description}</p>
					</div>
				</div>
			</Modal>
		);
	}
}

ProjectSetUpConfirmationModal.propTypes = {
	open: PropTypes.bool,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

ProjectSetUpConfirmationModal.defaultProps = {
	open: false,
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {}
};

export default ProjectSetUpConfirmationModal;