import Config from '../../config';
import { auth } from '../../utils';
import { CONTEST } from '../../constants';

const getToken = () => {
	const tokenData = auth.getValidStoredTokens();
	return (tokenData && tokenData.token) ? tokenData.token.accessToken : null;
};

const getPublicSketchUrl = (id, token) => {
	return Config.lastbasic.api.host + '/public/project/' + id + '/getPublicSketch?accessToken=' + token;
};

const getPublicBriefingUrl = (id, token) => {
	if (!token) token = getToken();
	return Config.lastbasic.api.host + '/public/project/' + id + '/getPublicBriefing?accessToken=' + token;
};

const getProposalThumbnailUrl = (id, token, size = 300) => {
	return Config.lastbasic.api.host + '/public/proposal/' + id + '/thumbnail?size=' + size + '&accessToken=' + token;
};

const getDisplayUrlByFile = (file) => {
	if (!file || !file.id) return false;

	const token = getToken();
	return Config.lastbasic.api.host + '/public/file/' + file.id + '/display?accessToken=' + token;
};

const getDisplayUrlByFileId = (fileId) => {
	if (!fileId) return false;

	const token = getToken();
	return Config.lastbasic.api.host + '/public/file/' + fileId + '/display?accessToken=' + token;
};

const getPreviewUrlByFile = (file) => {
	if (!file || !file.id) return false;

	const token = getToken();
	return Config.lastbasic.api.host + '/public/file/' + file.id + '/preview?accessToken=' + token;
};

const getThumbnailUrlByFile = (file) => {
	if (!file || !file.id) return false;

	return Config.lastbasic.api.host + '/public/file/' + file.id + '/thumbnail';
};

const getDownloadUrlByFile = (file) => {
	if (!file || !file.id) return false;

	const token = getToken();
	return Config.lastbasic.api.host + '/public/file/' + file.id + '?accessToken=' + token;
};

const getInvoiceUrl = (url, token) => {
	return url + '?accessToken=' + token;
};

const getAvatarUrl = (id) => {
	return Config.lastbasic.api.host + '/public/user/' + id + '/avatar';
};

const getContestCardPdfUrl = (contest) => {
	const contestRound = contest && CONTEST.getLastRound(contest);
	if (!contestRound) return null;

	const token = getToken();
	return Config.lastbasic.api.host + '/public/contest/' + contest.id + '/round/' + contestRound.id + '/contest-card?accessToken=' + token;
};

export default {
	getPublicSketchUrl,
	getPublicBriefingUrl,
	getProposalThumbnailUrl,
	getDisplayUrlByFile,
	getDisplayUrlByFileId,
	getPreviewUrlByFile,
	getThumbnailUrlByFile,
	getDownloadUrlByFile,
	getInvoiceUrl,
	getAvatarUrl,
	getContestCardPdfUrl,
};
