import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'carbon-components-react';
import { PROJECT } from '../../../constants';
import { GET_PROJECT_SETUP_QUESTIONS } from '../../../providers/queries';
import ViewSetupQuestions from '../../../components/project/setup-questions/view-setup-questions';
import PhaseSetupQuestions from '../../../components/project/setup-questions/phase-setup-questions';
import ErrorBoundaryQuery from '../../graphql/queries-with-errors';

import classes from './setup-questions.module.scss';

class PhaseViewSetupQuestions extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openSetupQuestionsModal: false,
		};
	}

	render() {
		const { noAnswersMessage, phase, projectId, onboardingQuestionsDone } = this.props;
		const { openSetupQuestionsModal } = this.state;

		return (
			<ErrorBoundaryQuery query={GET_PROJECT_SETUP_QUESTIONS} variables={{ id: projectId }}>
				{({ data }) => {
					const project = data && data.project;
					if (!project) return null;

					const phaseObject = PROJECT.getStateByPhase(project, phase);
					if (!phaseObject) return null;

					const questions = phaseObject.setupQuestions;

					return (
						<>
							<ViewSetupQuestions
								questions={questions}
								noAnswersMessage={noAnswersMessage}
								onOpenQuestionsModal={() => this.setState({openSetupQuestionsModal: true})}
							/>
							<Modal
								passiveModal={true}
								open={!!openSetupQuestionsModal}
								modalLabel={'Set up questions'}
								onRequestClose={() => this.setState({ openSetupQuestionsModal: false })}
							>
								<div className={classes.contentTitle}>
									<h4>More details</h4>
									<p>Tell us more about your project for further understanding.</p>
								</div>
								<div>
									<h5>Onboarding questions</h5>
									<PhaseSetupQuestions
										project={project}
										phase={PROJECT.STATE.CLIENT_ONBOARDING}
										onQuestionsAnswered={() => this.setState({ openSetupQuestionsModal: false }, () => { onboardingQuestionsDone();})}
									/>
								</div>
							</Modal>
						</>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

PhaseViewSetupQuestions.propTypes = {
	phase: PropTypes.string.isRequired,
	projectId: PropTypes.string.isRequired,
	noAnswersMessage: PropTypes.string,
	onboardingQuestionsDone: PropTypes.func,
};

PhaseViewSetupQuestions.defaultProps = {
	noAnswersMessage: 'You don\'t have any answers.'
};

export default PhaseViewSetupQuestions;
