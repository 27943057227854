import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classes from '../new-project.module.scss';

import Pager from '../components/pager';

import sendInsight from '../../../../utils/insights';

const MIN_WORDS = 5;

export default function OfferingStep({ onNext, detailsUpdate, activeButton }) {
	const [name, setName] = useState('');
	const [need, setNeed] = useState('');
	const [solution, setSolution] = useState('');
	const [electronics, setElectronics] = useState('');
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (detailsUpdate) detailsUpdate({
			projectName: name,
			productNeed: need,
			productSolution: solution,
			electronics,
		});
	}, [name, need, solution, electronics]);

	useEffect(() => {
		sendInsight('view', 'new_project_details_page', null, '', '');
	}, []);

	function checkData() {
		setErrors([]);

		let errors = [];

		if (!name || !need || !solution) {
			errors.push('Please complete the full form');
		}
		if (need && need.split(' ').length - 1 < MIN_WORDS - 1) {
			errors.push('Elaborate more on the product need');
		}

		if (solution && solution.split(' ').length - 1 < MIN_WORDS - 1) {
			errors.push('Elaborate more on the product solution');
		}

		if (errors.length) return setErrors(errors);

		return onNext();
	}

	return (
		<>
			<Pager
				steps={[
					{ name: 'Offering', active: true },
					{ name: 'Details', active: true },
					{ name: 'Validation', active: false },
				]}
			/>
			<h2 className={classes.title}>Explain your <span className={classes.yellowUnderline}>idea</span></h2>
			<div className={classes.description}>Tell us the basics of your idea so we can validate it.<br/>Your idea will always belong to you</div>

			<div className={classes.formBox}>
				{/* Project name */}
				<div className={classes.question}>
					<div className={classes.inputTitle}>Project name</div>
					<input type={'text'} name={'project_name'} placeholder={'Speed meter for surfers'} autoCapitalize={'true'} onChange={(e) => setName(e.target.value)} />
				</div>

				{/* Product need (Problem) */}
				<div className={classes.question}>
					<div className={classes.inputTitle}>Product need (Problem)</div>
					<textarea type={'text'} name={'product_need'} placeholder={'When surfing, athletes cannot track accurately key metrics such as speed...'} autoCapitalize={'true'} onChange={(e) => setNeed(e.target.value)} />
				</div>

				{/* Product solution (How it works) */}
				<div className={classes.question}>
					<div className={classes.inputTitle}>Product solution (How it works)</div>
					<textarea type={'text'} name={'product_solution'} placeholder={'A speed camera that stays on ground and follows a tracker placed on the surfboard...'} autoCapitalize={'true'} onChange={(e) => setSolution(e.target.value)} />
				</div>

				{/* Electronics */}
				<div className={classes.question}>
					<div className={classes.inputTitle}>Desired electronics</div>
					<select onChange={(e) => setElectronics(e.target.value)}>
						<option value={'none'}>None</option>
						<option value={'off_the_shelf'}>Comercial electronics (bigger and reliable)</option>
						<option value={'custom'}>Custom designed electronics (smaller and production oriented)</option>
						{/* <option value={'not_sure'}>I do not know</option> */}
					</select>
				</div>
			</div>
			
			{ errors.length > 0 && errors.map((error, k) => <div key={k} className={classes.error}>{error}</div>)}
			<div className={classes.buttonWrapper} style={{ marginTop: '50px' }}>
				{ activeButton && <div className={classes.button} onClick={() => {
					sendInsight('click', 'new_project_save_and_continue_button', null, '', '');
					checkData();
				}}>Save and continue</div> }
				{ !activeButton && <div className={classes.button} style={{ cursor: 'inherit', opacity: 0.8 }}>Saving...</div> }
			</div>
		</>
	);
}

OfferingStep.propTypes = {
	onNext: PropTypes.func,
	detailsUpdate: PropTypes.func,
	activeButton: PropTypes.bool,
};
