import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classes from './benefit.module.scss';

class Benefit extends Component {
	constructor (props) {
		super(props);

		this.state = {
		};
	}

	render() {
		const { name, icon, description } = this.props;

		return (
			<div className={classes.benefitContainer}>
				<span className={classes.icon}>{icon}</span>
				<div className={classes.content}>
					<h4 className={classes.name}>{name}</h4>
					<small>{description}</small>
				</div>
			</div>
		);
	}
}

Benefit.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	icon: PropTypes.func,
};

Benefit.defaultProps = {
	name: '',
	description: '',
	icon() {},
};

export default Benefit;
