import React, { Component } from 'react';
import PropTypes from 'prop-types';

const RAW_MATERIAL_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';

class RawMaterialImage extends Component {
	render() {
		const { src, alt, ...rest } = this.props;
		return (
			<img
				src={src || RAW_MATERIAL_PLACEHOLDER}
				alt={alt ? alt : 'Raw material'}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = RAW_MATERIAL_PLACEHOLDER;
				}}
				{...rest}
			/>
		);
	}
}

RawMaterialImage.defaultProps = {
};

RawMaterialImage.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
};

export default RawMaterialImage;