import config from '../../config';
import auth from '../auth';

import axios from 'axios';

const INSIGHTS_ENDPOINT = 'https://insights.lastbasic.net/v1/events';

export default async function sendInsight(type, name, actor, target, data) {
	if (config.lastbasic.api.host !== 'https://api.lastbasic.com') return true;

	try {
		const storedToken = auth.getStoredTokens();
		const userId = storedToken?.user?.id || '';
	
		const url = INSIGHTS_ENDPOINT + `?type=${type || ''}&name=${name || ''}&actor=${actor || userId}&target=${target || ''}&data=${data || ''}`;
	
		await axios.get(url);
	} catch (e) {
		console.error(e);
	}

	return true;
}
