function getUrls (url) {
	let isGoogleDrive = false;
	let previsualizeUrl = 'https://drive.google.com/viewer?embedded=true&url=' + encodeURIComponent(url);
	let downloadUrl = url;
	let displayUrl = url;
	let getThumbnailUrl = () => url;

	if (url.indexOf('google.com') > -1) {
		const matches = url.match(/\/d\/(.*)\//);
		if (matches && matches.length >= 2)  {
			const id = matches[1];
			isGoogleDrive = true;
			previsualizeUrl = 'https://drive.google.com/file/d/' + id + '/preview';
			downloadUrl = 'https://drive.google.com/uc?export=download&id=' + id;
			displayUrl = 'https://drive.google.com/uc?id=' + id;		
			getThumbnailUrl = function (width = 300) {
				return 'https://lh3.googleusercontent.com/d/' + id + '=w' + width;
			};
		}
	}
	
	return {
		isGoogleDrive,
		previsualizeUrl,
		downloadUrl,
		displayUrl,
		getThumbnailUrl,
	};
}

export default {
	getUrls	
};
