import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
	Tag,
	Breadcrumb,
	BreadcrumbItem,
	InlineNotification,
} from 'carbon-components-react';
import {
	Download20,
	Document20,
	Timer20,
	CheckmarkFilled20,
	CloseFilled20,
} from '@carbon/icons-react';

import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import SalesCallModal from '../../../../components/modals/sales-call-modal';
import CardAction from '../../../../components/project/card-action';
import withRouter from '../../../../components/route/with-router';

import { PROJECT } from '../../../../constants';
import { fileTools, serverAPI, dates } from '../../../../utils';
import config from '../../../../config';
import KEYS from '../../../../glossary';

import classes from './../project-onboarding/project-onboarding.module.scss';

class ProjectFeasibility extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
			openWidget: false,
		};
	}

	componentDidMount() {
		this.setInitialStepsParams();
		document.title = 'Project feasibility - LastBasic';
		this.sendAttributesToFroged();
	}

	componentDidUpdate(prevProps) {
		const { project } = this.props;

		if (project !== prevProps.project) {
			this.sendAttributesToFroged();
		}
	}

	setInitialStepsParams() {
		const { project, navigate } = this.props;

		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsPendingPayment = project.feasibility === PROJECT.FEASIBILITY.YES && !project.paid;
		const activeState = projectIsPendingFeasibility ? 'on_validation' : projectIsNonFeasible ? 'non_feasible' : projectIsPendingPayment && 'pending_payment';

		return navigate({
			search: '?' + new URLSearchParams({ phase: 'project_feasibility', state: activeState }).toString()
		});
	}

	sendAttributesToFroged() {
		const { project } = this.props;

		const feasibilityInfo = project.editions.feasibility;
		const projectId = project.id;
		const projectName = project.name;
		const paid = project.paid;
		const price = project.setupInvoice ? project.setupInvoice.amount.amountInCents / 100 : null;
		const today = dayjs();
		const lastUpdate = dayjs(feasibilityInfo.updatedAt);
		const hoursFromFeasibility = today.diff(lastUpdate, 'hour');
		const currentLobbyStage = PROJECT.getProjectLobbyStage(hoursFromFeasibility, project.paid);

		window.Froged('set', {
			selected_project_name: projectName,
			selected_project_id: projectId,
			project_feasibility: project.feasibility,
			project_package_price: price,
			project_feasibility_update: hoursFromFeasibility,
			project_is_paid: paid,
			project_feasibility_stage: currentLobbyStage
		});
	}

	onOpenPreviewFile = (file) => {
		this.setState({ previewFile: file });
	};

	onClosePreviewFile = () => {
		this.setState({ previewFile: null });
	};

	displayContestStatusInfo(project) {
		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsFeasible = project.feasibility === PROJECT.FEASIBILITY.YES;
		const projectIsPendingPayment = projectIsFeasible && !project.paid;

		return (
			<div className={classes.contestSideContainer + ' ' + classes.setupCallSide}>
				<div className={classes.projectBox}>
					<div className={classes.projectStatus}>
						<div className={classes.contestStatusBox}>
							<h4>Project state</h4>
							{projectIsPendingFeasibility
								? <Timer20 className={classes.statusIcon} />
								: (projectIsPendingPayment)
									? <CheckmarkFilled20 className={classes.statusIcon} style={{fill: '#24a148'}} />
									: <CloseFilled20 className={classes.statusIcon} style={{fill: '#df3d3b'}} />
							}
						</div>
						<div className={classes.projectStatusBox}>
							<div className={classes.projectDetails}>
								<h5>{KEYS.project_status_phase}</h5>
								<p>Project validation</p>
							</div>
							<div className={classes.projectDetails}>
								<h5>Status</h5>
								{projectIsPendingFeasibility
									? <Tag type="gray" style={{borderRadius: '4px'}}>Pending feasibility</Tag>
									: (projectIsPendingPayment)
										? <Tag type="green" style={{borderRadius: '4px'}}>Feasible project</Tag>
										: (projectIsNonFeasible) &&
											<Tag type="red" style={{borderRadius: '4px'}}>No feasible project</Tag>
								}
							</div>
							{(projectIsPendingPayment && project.setupInvoice) &&
								<Fragment>
									{/* <div className={classes.projectDetails}>
										// Define packages section
										<h5>Package plan</h5>
										<a className={classes.link}><p>PRO + extras</p></a>
									</div> */}
									<div className={classes.projectDetails}>
										<h5>Amount</h5>
										<p>{project.setupInvoice.amount.amountInCents / 100} {project.setupInvoice.amount.currency}</p>
									</div>
									<div className={classes.projectDetails}>
										<h5>Action</h5>
										<p>{project.setupInvoice.status === PROJECT.FEASIBILITY.PENDING ? 'Pending payment' : 'Ready for next phase'}</p>
									</div>
								</Fragment>
							}

							{ project.hasPaidReservation &&
								<div className={classes.projectDetails}>
									<h5>Reservation paid</h5>
									<p>{project.hasPaidReservation ? 'Yes' : 'No'}</p>
								</div>
							}

							<div className={classes.projectDetails}>
								<h5>Created at</h5>
								<p>{dates.formattedNewDate(project.createdAt)}</p>
							</div>
						</div>
					</div>
					<div className={classes.projectStatusSecondaryAction}>
						<div className={classes.callLuis} onClick={() => this.setState({ openWidget: true })}>
							<div className={classes.callLuisText}>
								Set up a call with Luis
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	displayPendingFeasibilityContent() {
		return (
			<div className={classes.contentBlock}>
				<div className={classnames([classes.contentBlock, classes.maxSpace])}>
					<div className={classes.contentTitle}>
						<h4>What to expect?</h4>
					</div>
					<p>{KEYS.project_feasibility_pending_feasibility_message}</p>
					<br/>
					<p>{KEYS.project_feasibility_pending_feasibility_message_call}</p>
					<br/>
					<p>{KEYS.project_feasibility_pending_feasibility_message_call2}</p>
					<br/>
					<p className={classes.callLuisLink} onClick={() => this.setState({ openWidget: true })}>{KEYS.project_feasibility_pending_feasibility_message_call3}</p>
				</div>
				<div className={classes.payOptionSeparator}></div>
				  { this.displayPayOptions(true) }
			</div>
		);
	}

	displayNonFeasibleContent(feasibilityReason) {
		const reason = PROJECT.getFeasibilityRejectReason(feasibilityReason);

		return (
			<div className={classnames([classes.contentBlock, classes.maxSpace])}>
				<div className={classes.contentTitle}>
					<h4>Not-feasible explanation</h4>
				</div>
				<p>{reason}</p>
			</div>
		);
	}

	displayPayOptions(preFeasibility) {
		const { project } = this.props;
		
		const price = project.setupInvoice ? project.setupInvoice.amount.amountInCents / 100 : null;
		const reservationPaid = project.hasPaidReservation;

		const preFeasibilityMessage = 'To go faster, you can select one of these options before having the feasibility. Guaranteed refund if your project is not feasible.';
		const feasibilityMessage = 'Select an option to start right away!';

		function openPaymentLink(option) {
			return window.open(config.lastbasic.api.host + '/pay/project/' + project.id + '/' + option, '_blank');
		}

		return (
			<div>
				<div className={classes.payOptionsTitle}><p>{(preFeasibility ? preFeasibilityMessage : feasibilityMessage)}</p></div>
				<div className={classes.payOptions}>
					<div className={classes.payOption} style={{ backgroundColor: 'rgb(2, 178, 218)' }} onClick={() => openPaymentLink('sketch')}>
						<div className={classes.payOptionTop}>
							<div className={classes.payOptionTitle}>Sketch</div>
							<div className={classes.payOptionPrice}>{(reservationPaid ? 243 : 360)}€</div>
						</div>
						<div className={classes.payOptionDescription}>Get sketch proposals and all the files for your invention. Best for seeing the concept and landing pages.</div>

						<div className={classes.payOptionButton}>Get sketches</div>
					</div>

					<div className={classes.payOption} style={{ backgroundColor: 'rgb(217, 180, 30)' }} onClick={() => openPaymentLink('sketch-model')}>
						<div className={classes.payOptionTop}>
							<div className={classes.payOptionTitle}>Sketch + 3D</div>
							<div className={classes.payOptionPrice}>{(reservationPaid ? 775 : 920)}€</div>
						</div>
						<div className={classes.payOptionDescription}>Get sketch and 3D model proposals and all the files for your invention. Best for crowdfunding and videos.</div>

						<div className={classes.payOptionButton}>Get sketches + 3D</div>
					</div>

					<div className={classes.payOption} style={{ backgroundColor: '#454A50' }} onClick={() => openPaymentLink('projectSetup')}>
						<div className={classes.payOptionTop}>
							<div className={classes.payOptionTitle}>Prototype</div>
							<div className={classes.payOptionPrice}>{price ? `${price}€` : ''}</div>
						</div>
						<div className={classes.payOptionDescription}>Get sketch, 3D model, electrical, mechanical, and prototyping proposals and all the files. Best for having a fully functional prototype.</div>

						<div className={classes.payOptionButton}>Get full prototype</div>
					</div>
				</div>
			</div>
		);
	}

	displayPendingPaymentContent() {
		return (
			<div className={classes.contentBlock}>
				<div className={classnames([classes.contentBlock, classes.maxSpace])}>
					<div className={classes.contentTitle}>
						<h4>What to expect?</h4>
					</div>
					<p>{KEYS.project_pending_payment_message}</p>
					<br/>
					<p>{KEYS.project_pending_payment_message2}</p>
					<br/>
					<p>{KEYS.project_pending_payment_message3}</p>
				</div>

				{ this.displayPayOptions() }
			</div>
		);
	}

	displayNextStepsOptions(isPendingPayment, isNonFeasible, setupInvoice) {
		return (
			<div className={classes.contentBlock}>
				{(isNonFeasible) ? (
					<Fragment>
						<div className={classes.contentTitle}>
							<h4>What you can do now?</h4>
						</div>
						<div className={classes.blocksListContainer}>
							<CardAction
								type={'recommended'}
								title={'Set a call'}
								action={'set-call'}
							/>
							{/* <CardAction
								type={'optional'}
								title={'Change briefing'}
								action={'new-project'}
							/> */}
							{/* <CardAction type={'optional'} title={'Other'} action={'other'} /> */}
						</div>
					</Fragment>
				) : (isPendingPayment) && (
					<Fragment>
						<div className={classes.contentTitle}>
							<h4>Next steps</h4>
						</div>
						<div className={classes.blocksListContainer}>
							{setupInvoice &&
								<CardAction
									type={'recommended'}
									title={'Get full prototype'}
									action={'payment'}
									setupInvoice={setupInvoice}
								/>
							}
							<CardAction
								type={'optional'}
								title={'Set a call'}
								action={'set-call'}
							/>
						</div>
					</Fragment>
				)}
			</div>
		);
	}

	render() {
		const { project, userData: { token } } = this.props;
		const { previewFile, openWidget } = this.state;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};
		const { previsualizeUrl } = fileTools.getUrls(briefing.url);

		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsFeasible = project.feasibility === PROJECT.FEASIBILITY.YES;
		const projectIsPendingPayment = projectIsFeasible && !project.paid;

		return (
			<div>
				<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
					<BreadcrumbItem>
						<Link to="/projects">Projects</Link>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage href="#">
						{project.name}
					</BreadcrumbItem>
				</Breadcrumb>
				<h1 className={classes.title}>{project.name}</h1>
				<div className={classes.twoBlocks}>
					<div>
						<div className={classes.contentBlock}>
							{false && projectIsPendingPayment &&
								<InlineNotification
									kind="success"
									lowContrast
									hideCloseButton={false}
									style={{width: '600px'}}
									iconDescription="Info"
									subtitle={KEYS.project_feasibility_pending_payment_alert}
									title=""
								/>
							}
							<div className={classnames([classes.contentBlock, classes.maxSpace])}>
								<div className={classes.contentTitle}>
									<h4>{KEYS.project_onboarding_step_1_title}</h4>
									<p>{KEYS.project_onboarding_step_1_description}</p>
								</div>
								<div className={classes.elementBox}>
									<div className={classes.naming}>
										<Document20 />
										<p>{KEYS.project_onboarding_step_1_title}</p>
									</div>
									<div className={classes.actions}>
										<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>
											{KEYS.open_file}
										</span>
										<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer">
											<Download20 />
										</a>
									</div>
								</div>
							</div>
						</div>
						{projectIsPendingFeasibility && this.displayPendingFeasibilityContent()}
						{projectIsPendingPayment && this.displayPendingPaymentContent(project)}
						{projectIsNonFeasible && this.displayNonFeasibleContent(project.feasibilityReason)}
					</div>
					{this.displayContestStatusInfo(project)}
				</div>
				{this.displayNextStepsOptions(projectIsPendingPayment, projectIsNonFeasible, project.setupInvoice)}
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					onRequestClose={this.onClosePreviewFile}
				/>
				{openWidget && (
					<SalesCallModal
						open={openWidget}
						onRequestClose={() => this.setState({ openWidget: false })}
					/>
				)}
			</div>
		);
	}
}

ProjectFeasibility.propTypes = {
	navigate: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(ProjectFeasibility)));
