const LocalStore = window.localStorage;

export const USER_INFO = 'USER_INFO';

const getUserInfo = () => {
	try {
		let strUserInfo = LocalStore.getItem(USER_INFO);
		if (strUserInfo) return JSON.parse(strUserInfo);
		
		return JSON.parse(strUserInfo);
	} catch (error) {
		return null;
	}
};

const storeUserInfo = (info) => {
	try {
		LocalStore.setItem(USER_INFO, JSON.stringify({
			...getUserInfo(),
			...info,
		}));
		return true;
	} catch (error) {
		return false;
	}
};

const removeUserInfo = () => {
	try {
		LocalStore.removeItem(USER_INFO);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getUserInfo,
	storeUserInfo,
	removeUserInfo,
};