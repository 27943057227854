import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	Modal,
	Button,
	DataTable,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableHeader,
	TableBody,
	TableCell,
	Select,
	SelectItem,
	SkeletonText,
	InlineNotification,
} from 'carbon-components-react';
import { GET_OWNED_PROJECTS } from '../../providers/queries';
import { PROJECT } from '../../constants';
import ErrorBoundaryQuery from '../../components/graphql/queries-with-errors';


import classes from './modals.module.scss';

class UpsellModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedProject: null,
		};
	}

	render() {
		const { open, title, provider, type, option, ...rest } = this.props;

		return (
			<Modal
				passiveModal
				className={classes.previewFileModal}
				open={open}
				modalLabel={`Proposed by ${provider}`}
				modalHeading={title}
				{...rest}
			>
				{type === 'business' ?
					<>
						<div className={classes.modalContent}>
							<div className={classes.contestContent}>
								<div className={classes.modalContent}>
									{this.displayAddonMainInfo(option, type)}
								</div>
								<div className={classes.modalContent}>
									{this.displayFeaturesContent(option)}
								</div>
								<div className={classes.modalContent}>
									{this.displayPdfFile(option)}
								</div>
								<div className={classes.modalContent}>
									{this.displayProjectSelector()}
								</div>
							</div>
						</div>
						<div className={classes.modalContent}>
							{this.displayActionBtn(option, title)}
						</div>
					</>
					: (type !== 'business' && option !== null) &&
					<>
						<div className={classes.modalContent}>
							<div className={classes.contestContent}>
								<div className={classes.modalContent}>
									{this.displayAddonMainInfo(option, type)}
								</div>
								<div className={classes.modalContent}>
									{this.displayFeaturesContent(option)}
								</div>
								<div className={classes.modalContent}>
									{this.displayOutcomesContent(option)}
								</div>
								<div className={classes.modalContent}>
									{this.displayPdfFile(option)}
								</div>
								<div className={classes.modalContent}>
									{this.displayProjectSelector()}
								</div>
							</div>
						</div>
						<div className={classes.modalContent}>
							{this.displayActionBtn(option, title)}
						</div>
					</>
				}
			</Modal>
		);
	}

	displayAddonMainInfo(option, type) {
		if (option === null) return false;
		const headers = [
			{
				key: 'concept',
				header: 'Concept',
			},
			{
				key: 'details',
				header: 'Details',
			}
		];

		const rows = [
			{
				id: 'a',
				concept: 'Package',
				details: option.plan,
			},
			{
				id: 'b',
				concept: 'Boosting',
				details: type,
			},
			{
				id: 'c',
				concept: 'Price',
				details: `${option.price} *`,
			}
		];

		return (
			<>
				<DataTable rows={rows} headers={headers} useZebraStyles>
					{({ rows, getTableProps, getHeaderProps, getRowProps }) => (
						<TableContainer title="Important info">
							<Table {...getTableProps()}>
								<TableHead>
									<TableRow>
										{headers.map((header, i) => (
											<TableHeader key={i} {...getHeaderProps({ header })}>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row, i) => (
										<TableRow key={i} {...getRowProps({ row })}>
											{this.getPersonalizedRowContent(row)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DataTable>
				<small><sup>*</sup>Prices are set in USD</small>
			</>
		);
	}

	getPersonalizedRowContent(row) {
		const concept = row.cells.find(e => e.info.header === 'concept').value;
		const details = row.cells.find(e => e.info.header === 'details').value;

		return (
			<>
				<TableCell key={concept}>{concept}</TableCell>
				<TableCell key={details}>{details}</TableCell>
			</>
		);
	}

	displayFeaturesContent(option) {
		if (option === null) return false;
		return (
			option &&
			<div className={classes.contestContent}>
				<h5>This package includes</h5>
				<div className={classes.contestImgContainer}>
					<ul>
						{option.features.map((feature, i) => (
							<li key={i}>✓ {feature}</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	displayOutcomesContent(option) {
		if (option === null || option.outcomes.length === 0) return false;
		return (
			<div className={classes.contestContent}>
				<h5>The final outcomes</h5>
				<div className={classes.contestImgContainer}>
					<ul>
						{option.outcomes.map((outcome, i) => (
							<li key={i}>✓ {outcome}</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	displayPdfFile(option) {
		const pdfUrl = option.pdf ? option.pdf : 'javascript:void(0)';

		return (
			option &&
			<div className={classes.contestContent}>
				<h5>Download pdf with more details</h5>
				<div className={classes.contestImgContainer}>
					<a
						className={classes.link}
						href={pdfUrl}
						target="_blank" rel="noopener noreferrer"
					>
						Pdf file
					</a>
				</div>
			</div>
		);
	}

	displayProjectSelector() {
		const queryVariables = {
			page: 0,
			pageSize: 20,
			withMoreDetails: false,
			onlyId: true,
			includeFeasibility: true
		};

		return (
			<ErrorBoundaryQuery query={GET_OWNED_PROJECTS} variables={queryVariables}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<SkeletonText className={classes.subtitle} style={{ width: '150px' }} />
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const projects = (data && data.ownedProjects && data.ownedProjects.projects) || [];
					const filteredPaidProjects = projects.filter(p => p.feasibility === PROJECT.FEASIBILITY.YES && p.paid);
					const filteredAvailableProjectsForUpsales = filteredPaidProjects;
					const { selectedProject } = this.state;

					return (
						<Select
							id="select-a-project-upsell"
							disabled={filteredAvailableProjectsForUpsales.length === 0}
							labelText="Select a project"
							defaultValue={selectedProject && filteredAvailableProjectsForUpsales.findIndex(p => p.id === selectedProject)}
							onChange={(e) => this.onChangeSelectedProject(e, filteredAvailableProjectsForUpsales)}>
							<SelectItem
								value=""
								text="Choose an option"
							/>
							{filteredAvailableProjectsForUpsales.map((p, i) => (
								<SelectItem text={p.name} value={i} key={i} />
							))}
						</Select>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	onChangeSelectedProject = (e, projects) => {
		const value = e.target.value;
		if (!value) {
			return this.setState({
				selectedProject: null,
			});
		}

		const selectedProject = projects[value];
		this.setState({
			selectedProject,
		});
	};

	displayActionBtn(option, title) {
		const { selectedProject } = this.state;

		return (
			<div className={classes.contestContent} style={{ display: 'flex', justifyContent: 'flex-end'}}>
				<Button
					className={classnames([classes.btn, classes.large])}
					onClick={() => this.sendUpsellRequest(option, title)}
					kind='primary'
					size='lg'
					disabled={!selectedProject}
				>
					{selectedProject
						?	`I am interested on "${option.plan}" option`
						:	'Select a project in order to proceed'
					}
				</Button>
			</div>
		);
	}

	sendUpsellRequest(option, title) {
		const { selectedProject } = this.state;

		window.Froged('set', { tags: [ { name: 'sales' } ] });
		window.Froged('message', `Hey there, I am interested in the upsell ${title} + ${option.name} of $${option.price} to boost my project ${selectedProject.name}. Project id: ${selectedProject.id}`);
	}
}

UpsellModal.propTypes = {
	open: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.string,
	type: PropTypes.string,
	option: PropTypes.object,
	provider: PropTypes.string,
};

UpsellModal.defaultProps = {
	open: false,
	onClick() {},
	title: '',
	type: '',
	option: {},
	provider: '',
};

export default UpsellModal;
