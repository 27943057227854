import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';

class RatingValue extends Component {
	render() {
		const { rating, type, readOnly } = this.props;
		// type: star, line
    
		return (
			<div>
				{type === 'star'
					?	<Rating
						name="simple-controlled"
						value={rating}
						readOnly={true}
					/>
					: (type === 'line') &&
					<Rating
						name="customized-color"
						value={rating}
						icon={<HorizontalRuleRoundedIcon fontSize="large" />}
						emptyIcon={<HorizontalRuleRoundedIcon fontSize="large" />}
						readOnly={readOnly}
					/>
				}
			</div>
		);
	}
}

RatingValue.propTypes = {
	rating: PropTypes.number.isRequired,
	type: PropTypes.string,
	readOnly: PropTypes.bool,
};

RatingValue.defaultProps = {
	rating: 1,
	type: 'star',
	readOnly: true,
};

export default RatingValue;