import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
	Modal,
	InlineLoading,
	Button
} from 'carbon-components-react';
import { ChevronLeft16, Search32 } from '@carbon/icons-react';

import ReactMarkdown from 'react-markdown';
import PreviewFileModal from './preview-file-modal';
import ContestCardImage from '../project/contest-card-image';

import { PROJECT, CONTEST } from '../../constants';
import { serverAPI, frogedAPI, dates } from '../../utils';
import KEYS from '../../glossary';

import classes from './contest-modal.module.scss';

class ContestModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			joinContestVerificationMessage: false,
			showContestTermsAndConditions: false,
			previewFile: null,
			reOpenModal: false,
			requestToJoin: false,
			contactDataFromFroged: null,
		};
	}

	async componentDidMount() {
		const contactDataFromFroged = await frogedAPI.getContactInfo();

		return this.setState({ contactDataFromFroged: contactDataFromFroged});
	}

	onOpenPreviewFile(file) {
		const { onRequestClose } = this.props;

		this.setState({ previewFile: file });
		onRequestClose();
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onClosePreviewFileAndReopenContestCard() {
		this.setState({ previewFile: null, reOpenModal: true });
	}

	displayContestInfo(contest) {
		const contestRound = CONTEST.getOpenRound(contest);
		const { card } = contestRound !== undefined && contestRound;
		const { clientConfirmed, mainImage, secondaryImages, specialAttention } = card !== undefined && card;
		const mainImageUrl = mainImage !== undefined && serverAPI.getPreviewUrlByFile(mainImage);

		const newEndDate = contestRound && new Date(parseInt(contestRound.endDate));
		const endDate = contestRound && dates.substractDays(newEndDate, 1);
		const contestDuration = contestRound && CONTEST.getContestDaysDuration(contestRound);

		const newStartDate = contestRound && new Date(parseInt(contestRound.startDate));
		const startDate = (contestRound && newStartDate !== undefined) && newStartDate.setDate(newStartDate.getDate());

		if (clientConfirmed !== CONTEST.CONTEST_CARD_CONFIRMATION.YES) {
			return (
				<Fragment>
					Contest card not confirmed
				</Fragment>
			);
		}

		return (
			<Fragment>
				<div className={classes.contestContent}>
					<h4>Important information</h4>
					<div className={classes.contextContent}>
						<div className={classes.ccardBtn}>
							<Button
								className={classnames([classes.btn, classes.aBtn])}
								href={serverAPI.getContestCardPdfUrl(contest)}
								kind='secondary'
								size='sm'
								target="_blank" rel="noopener noreferrer"
							>
								Open as a contest card
							</Button>
						</div>
						<p><strong>General description:</strong> {card.description}</p>
						<p style={{color: '#3e3e3e'}}>
							<strong>Dates:</strong> {dates.formattedDate(startDate)} - {dates.formattedDate(endDate)}
						</p>
						<p><strong>Expected duration:</strong> {contestDuration} {(contestDuration === 1 ? 'day' : 'days')}</p>
						{specialAttention &&
							<div>
								<p><strong>Pay special attention to:</strong></p>
								<div className={classes.listSpecialAttention}>
									<ReactMarkdown>
										{specialAttention}
									</ReactMarkdown>
								</div>
							</div>
						}
						{(mainImage || secondaryImages.length > 0) &&
							<div>
								<p><strong>Guiding files:</strong></p>
								<div style={{ display: 'flex', flexWrap: 'wrap'}}>
									{mainImage &&
										<div className={classes.contestImgContainer}
											onClick={() => this.onOpenPreviewFile(mainImage)}>
											<ContestCardImage
												className={classes.contestImg}
												src={mainImageUrl}
											/>
											<div className={classes.contestImgOverlay}>
												<div>
													<Search32 />
													Preview view
												</div>
											</div>
										</div>
									}
									{secondaryImages.length > 0 &&
										secondaryImages.map((img, i) => (
											<div className={classes.contestImgContainer} key={i}
												onClick={() => this.onOpenPreviewFile(img)}>
												<ContestCardImage
													className={classes.contestImg}
													src={serverAPI.getPreviewUrlByFile(img)}
													type={'secondary'}
												/>
												<div className={classes.contestImgOverlay}>
													<div>
														<Search32 />
														Preview view
													</div>
												</div>
											</div>
										))
									}
								</div>
							</div>
						}
					</div>
				</div>
			</Fragment>
		);
	}

	displayProjectBriefing(project) {
		const { userData: {token} } = this.props;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};

		return (
			<div className={classes.contestContent}>
				<h5>Review the project briefing</h5>
				<div className={classes.contestImgContainer}>
					<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>
						Open project briefing
					</span>
				</div>
			</div>
		);
	}

	displaySubcriptionOption(contest) {
		return (
			<div className={classes.contestContent}>
				<h5>Subcribe to receive more info</h5>
				<Button
					id='contest_subscription_cta'
					className={classes.btn}
					onClick={() => this.onSubscribeToContest(contest)}
					size='sm'
					kind="secondary"
				>
					Subcribe to contest
				</Button>
			</div>
		);
	}

	onSubscribeToContest(contest) {
		const { onRequestSubscription } = this.props;
		const contestId = contest.id;

		window.Froged('track', 'click_subscribe_contest');
		window.Froged('set', {
			contest_subcription: true,
			contest_subscribed: contestId
		});
		onRequestSubscription();
	}

	requestJoinContest(onRequestSubmit) {
		this.setState({
			reOpenModal: false,
			requestToJoin: true
		}, async () => {
			onRequestSubmit();

			setTimeout(() => {
				this.setState({
					joinContestVerificationMessage: false,
					requestToJoin: false
				});
			}, 3000);
		});
	}

	render() {
		const {
			contest,
			project,
			open,
			passiveModal,
			onRequestSubmit,
			onSecondarySubmit,
			onRequestClose
		} = this.props;

		const {
			joinContestVerificationMessage,
			showContestTermsAndConditions,
			previewFile,
			reOpenModal,
			requestToJoin,
			contactDataFromFroged
		} = this.state;

		const { attributes } = contactDataFromFroged !== null && contactDataFromFroged;
		// eslint-disable-next-line no-unused-vars
		const isUserSubscribedToContest = (attributes !== undefined && attributes.contest_subscribed) ? true : false;
		const hasAvailableSlots = contest.availableSlots > 0 || false;

		const primaryButtonText =
				joinContestVerificationMessage
					?	KEYS.contest_modal_button_1
					:	requestToJoin === true
						?	<InlineLoading description='Joining...' />
						:	KEYS.contest_modal_button_2;

		return (
			<Fragment>
				<Modal
					open={open || reOpenModal}
					passiveModal={!hasAvailableSlots || passiveModal}
					hasScrollingContent
					primaryButtonText={primaryButtonText}
					secondaryButtonText={KEYS.cancel}
					modalHeading={PROJECT.stateToString(project.state)}
					modalLabel={KEYS.contest_modal_label}
					onRequestSubmit={joinContestVerificationMessage
						? () => this.setState({ reOpenModal: false, requestToJoin: true }, onRequestSubmit)
						: () => this.setState({ joinContestVerificationMessage: true })}
					onSecondarySubmit={() => this.setState({ reOpenModal: false }, onSecondarySubmit)}
					onRequestClose={() => this.setState({ reOpenModal: false }, onRequestClose)}
					aria-label={KEYS.contest_modal_alt}
				>
					<div>
						{joinContestVerificationMessage ?
							<Fragment>
								<div className={classes.returnActionModal} onClick={() => this.setState({ joinContestVerificationMessage: false })} style={{position: 'relative'}}>
									<ChevronLeft16 />
									<span>{KEYS.contest_modal_go_back}</span>
								</div>
								<h5>{KEYS.contest_modal_title_2}</h5>
								<ol className={classes.listModal} style={{ listStyleType: 'decimal' }}>
									<li>{KEYS.contest_modal_text_1}</li>
									<li>{KEYS.contest_modal_text_2}</li>
									<li>{KEYS.contest_modal_text_3}</li>
									<li>{KEYS.contest_modal_text_4}</li>
									<li>{KEYS.contest_modal_text_5}</li>
									<li>{KEYS.contest_modal_text_6}</li>
									<li>
										{KEYS.contest_modal_text_7}
										<span
											className={classes.linkModal}
											onClick={() => this.setState({ showContestTermsAndConditions: !showContestTermsAndConditions})}>
											terms and conditions
										</span>
										&nbsp;
										{KEYS.contest_modal_text_8}
									</li>
								</ol>

								{showContestTermsAndConditions &&
									<Fragment>
										<h5>{KEYS.contest_modal_title_3}</h5>
										<ul className={classes.listModal}>
											<li>{KEYS.contest_modal_text_9}</li>
											<li>{KEYS.contest_modal_text_10}</li>
											<li>{KEYS.contest_modal_text_11}</li>
											<li>{KEYS.contest_modal_text_12}</li>
											<li>{KEYS.contest_modal_text_13}</li>
											<li>{KEYS.contest_modal_text_14}</li>
											<li>{KEYS.contest_modal_text_15}</li>
											<li>{KEYS.contest_modal_text_16}</li>
											<li>{KEYS.contest_modal_text_17}</li>
											<li>{KEYS.contest_modal_text_18}</li>
										</ul>
									</Fragment>
								}
							</Fragment>
							:
							<>
								<div className={classes.modalContent}>
									{this.displayContestInfo(contest)}
								</div>
								<div className={classes.modalContent}>
									{this.displayProjectBriefing(project)}
								</div>
								{/* {!isUserSubscribedToContest &&
									<div className={classes.modalContent}>
										{this.displaySubcriptionOption(contest)}
									</div>
								} */}
							</>
						}
					</div>
				</Modal>
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					goBackToContest={() => this.onClosePreviewFileAndReopenContestCard()}
					onRequestClose={() => this.onClosePreviewFile()}
				/>
			</Fragment>
		);
	}
}

ContestModal.propTypes = {
	open: PropTypes.bool,
	passiveModal: PropTypes.bool,
	project: PropTypes.object.isRequired,
	contest: PropTypes.object,
	userData: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	goBackToContest: PropTypes.func,
	onRequestSubscription: PropTypes.func,
};

ContestModal.defaultProps = {
	open: false,
	passiveModal: false,
	project: {},
	contest: {},
	userData: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
	goBackToContest() {},
	onRequestSubscription() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ContestModal);