import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FrogedCustomerSupportScript from '../../components/private-scripts/froged';

import ExpertsPage from '../experts';
import ClientsPage from '../clients';

import sendInsight from '../../utils/insights';

class SelectUserType extends Component {
	renderPage() {
		const { userData: { user } } = this.props;
		const { expert, maker } = user;
		
		let page;
		
		if (expert !== null) {
			page = <ExpertsPage />;
		} else if (maker !== null) {
			page = <ClientsPage />;
		} else {
			page = <ClientsPage />;
		}

		return page;
	}
	
	render() {
		// Send ping
		sendInsight('ping', 'platform', null, null, null);

		return (
			<Fragment>
				{this.renderPage()}
				<FrogedCustomerSupportScript />
			</Fragment>
		);
	}
}

SelectUserType.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(SelectUserType);
