import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SketchIconAlt from '../icons/sketching-alt';
import ModelingIconAlt from '../icons/modeling-alt';
import ElectricalIconAlt from '../icons/electrical-alt';
import MechanicalIconAlt from '../icons/mechanical-alt';
import PrototypingIconAlt from '../icons/prototyping-alt';

import { PROJECT } from '../../constants';

class ContestIcon extends Component {
	renderIcon(state, size, active, color, type) {
		// type: white or color
		const { STATE } = PROJECT;
		switch (state) {
			case STATE.SKETCH_CONTEST:
				return <SketchIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
			case STATE.MODEL_CONTEST:
				return <ModelingIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
			case STATE.ELECTRICAL_CONTEST:
				return <ElectricalIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
			case STATE.MECHANICAL_CONTEST:
				return <MechanicalIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
			case STATE.PROTOTYPING_CONTEST:
				return <PrototypingIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
			default:
				return <PrototypingIconAlt size={active ? size*1.2 : size} active={active} color={color} type={type} />;
		}
	}

	render() {
		const { state, size, active, color, type} = this.props;
		return this.renderIcon(state, size, active, color, type);
	}
}

ContestIcon.defaultProps = {
	state: null,
	size: 20,
	color: '',
	active: false,
	type: 'white',
};

ContestIcon.propTypes = {
	state: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired,
	color: PropTypes.string,
	active: PropTypes.bool,
	type: PropTypes.string,
};

export default ContestIcon;