import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
	ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import Config from './config';
import rootReducer from './reducers';
import * as actions from './reducers/user/actions';
import { auth } from './utils';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import ScrollToTop from './components/elements/scroll-to-top';
import ErrorComponent from './pages/shared/errors/error-component';

import './index.scss';

const authLink = new ApolloLink((operation, forward) => {
	// Get the authentication token from local storage if it exists
	const tokenData = auth.getValidStoredTokens();
	// Return the headers to the context so httpLink can read them	
	operation.setContext(({ headers }) => ({
		headers: {
			...headers,
			authorization: (tokenData && tokenData.token) ? `Bearer ${tokenData.token.accessToken}` : '',
		}
	}));
	return forward(operation);
});

const resetToken = onError(({ response }) => {
	if (response && response.errors) {
		const unauthorized = response.errors.find(e => e.status === 401);
		if (unauthorized) {
			store.dispatch(actions.logout());
		}
	}
});

const uploadLink = createUploadLink({
	uri: `${Config.lastbasic.api.host}/graphql`,
});

const link = from([
	authLink,
	resetToken,
	uploadLink,
]);

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link,
	defaultOptions: {
		watchQuery: {
			//fetchPolicy: 'network-only',
			fetchPolicy: 'no-cache',
			//errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			//errorPolicy: 'all',
		},
	}
});

const store = createStore(combineReducers(rootReducer), applyMiddleware(thunk));

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<RollbarProvider config={Config.rollbar}>
				<ErrorBoundary
					fallbackUI={(error) => (
						<ErrorComponent errorMessage={error.message} />
					)}
				>
					<BrowserRouter basename={Config.basePath}>
						<ScrollToTop>
							<App />
						</ScrollToTop>
					</BrowserRouter>
				</ErrorBoundary>
			</RollbarProvider>
		</Provider>
	</ApolloProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
