import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TooltipIcon, TooltipDefinition } from 'carbon-components-react';

import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { CONTEST, FILE, PROJECT } from '../../../constants';
import { dates, serverAPI, strings } from '../../../utils';

import classes from '../project/blocks/blocks.module.scss';

class ProposalSupportingFiles extends Component {
	render() {
		const { contest } = this.props;

		return (
			<div className={classes.flexColumn} style={{margin: '16px 0', gap: '12px', flexWrap: 'wrap'}}>
				{this.renderContestSharedFiles(contest)}
			</div>
		);
	}

	renderContestSharedFiles(contest) {
		const rows = [];
		const { sharedFiles } = contest;

		for (const sharedFile of sharedFiles) {
			if (sharedFile.approvalStatus === FILE.APPROVAL_STATUS.APPROVED) {
				const f = sharedFile.file;
				const downloadUrl = serverAPI.getDownloadUrlByFile(f);

				const newRow = (
					<div
						className={classes.elementBox}
						style={{ cursor: 'pointer' }}
					>
						<div className={classes.naming}>
							<AttachFileIcon
								sx={{width: 20, height: 20 }}
								style={{color: '#454A50'}}
							/>
							<div style={{margin: '0 8px'}}>
								<h6 className={classes.fileName}>
									{f.name !== null
										? (f.name.length > 20)
											?	<TooltipDefinition
												tooltipText={strings.capitalizeFirstLetter(f.name)}
												className={classes.tooltipText}
											>{f.name.substring(0, 17) + '...'}</TooltipDefinition>
											: f.name
										: 'Supporting file'
									}
								</h6>
								<small>{dates.formattedNewDateShort(f.createdAt)}</small>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div
								className={classes.phaseBubble}
								style={{ backgroundColor: CONTEST.getPhaseColorWithContestType(contest.type) }}
							>
								{contest.type === PROJECT.CONTEST_TYPES.MODEL
									?	'3D'
									:	contest.type.substring(0, 2).toUpperCase()
								}
							</div>
							<TooltipIcon tooltipText="Download file">
								<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
									<FileDownloadOutlined
										sx={{width: 22, height: 22 }}
										style={{fill: '#0062ff', margin: '4px', verticalAlign: 'middle'}}
									/>
								</a>
							</TooltipIcon>
						</div>
					</div>
				);
				rows.push(newRow);
			}
		}
		return rows.length > 0 && rows.map(r => r);
	}
}

ProposalSupportingFiles.defaultProps = {
	contest: {},
	userData: {},
};

ProposalSupportingFiles.propTypes = {
	contest: PropTypes.object.isRequired,
	userData: PropTypes.object,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProposalSupportingFiles);
