import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '../../../../components/route/with-router';

import { Button, Tag, CodeSnippet, InlineNotification } from 'carbon-components-react';
import {
	ChevronUp20,
	ChevronDown20,
	// CheckmarkFilled32,
	// RadioButton32,
	CheckboxIndeterminate20,
	Information16,
	TaskComplete20,
	NextOutline20,
} from '@carbon/icons-react';
import { PROJECT, CONTEST, PROPOSAL } from '../../../../constants';
import SelectWinningProposalConfirmationModal from '../../../../components/modals/select-winning-proposal-confirmation-modal';

import { serverAPI, dates } from '../../../../utils';
import KEYS from '../../../../glossary';

import classes from './phase-contest-details.module.scss';

const DEFAULT_IMG_URL = '/assets/images/logo-lb-project.png';
const FILE_PLACEHOLDER_IMG = '/assets/images/contest-card-placeholder.jpg';

class ContestStep extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openStep: null,
			selectWinningProposalConfirmationModal: false,
			selectWinningProposalData: null,
		};
	}

	componentDidMount() {
		const { activeStep } = this.props;
		if (activeStep) this.setState({ openStep: {active: activeStep} });
	}

	render() {
		const { project, state, title, type, step, status } = this.props;
		// type: contest or informative
		// status: pending, in_progress, done
		const { openStep, selectWinningProposalConfirmationModal, selectWinningProposalData } = this.state;
		const contest = project && PROJECT.getContestByState(project, state);
		const contestRound = contest && CONTEST.getLastRound(contest);
		const isEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		return (
			<div>
				<div className={classnames([classes.stepContainer, (openStep && openStep.active === step) && classes.isActive])}>
					<div className={classes.headerSteps}
						onClick={() => this.setState({ openStep: (openStep && openStep.active === step) ? null : {active: step} })}>
						<div className={classes.alignContainer}>
							{type === CONTEST.CONTEST_STEP_TYPES.INFORMATIVE &&
								<div className={classes.stepIcon}
									style={status === CONTEST.CONTEST_STEP_STATUSES.DONE
										? {background: '#017cf6'}
										: status === CONTEST.CONTEST_STEP_STATUSES.IN_PROGRESS
											? {background: '#24a148'}
											: {}}
								>
									{status === CONTEST.CONTEST_STEP_STATUSES.IN_PROGRESS
										? <NextOutline20 className={classes.whiteLink} />
										: status === CONTEST.CONTEST_STEP_STATUSES.DONE
											? <TaskComplete20 className={classes.whiteLink} />
											: <CheckboxIndeterminate20 className={classes.whiteLink} />
									}
								</div>
							}
							{type === CONTEST.CONTEST_STEP_TYPES.CONTEST &&
								<div className={classes.stepIcon}
									style={hasAWinningProposal
										? {background: '#017cf6'}
										: isEnded && !hasAWinningProposal
											? {background: '#24a148'}
											: {}}
								>
									{isEnded && !hasAWinningProposal
										? <NextOutline20 className={classes.whiteLink} />
										: hasAWinningProposal
											? <TaskComplete20 className={classes.whiteLink} />
											: <CheckboxIndeterminate20 className={classes.whiteLink} />
									}
								</div>
							}
							<div>
								<small>Step {step === '-1' ? 1 : step}</small>
								<h4>{title}</h4>
							</div>
						</div>
						<div className={classes.projectSubtitle} style={{padding: '0'}}>
							{/* <div className={classes.alignContainer}>
								<small>Step type:</small>
								<h5 style={{ margin: '0 36px 0 6px' }}>{type === CONTEST.CONTEST_STEP_TYPES.CONTEST ? 'Contest' : 'Announcements'}</h5>
							</div> */}
							{(openStep && openStep.active === step)
								?	<ChevronUp20 style={{ fill: '#3e3e3e' }} />
								:	<ChevronDown20 style={{ fill: '#3e3e3e' }} />
							}
						</div>
					</div>
					{(openStep && openStep.active === step) &&
						<div style={{margin: '16px'}}>
							{type === CONTEST.CONTEST_STEP_TYPES.CONTEST &&
								<>
									{contestRound
										? this.displayRound(contest, contestRound)
										: <p>No contest open yet.</p>
									}
								</>
							}
							{type === CONTEST.CONTEST_STEP_TYPES.INFORMATIVE && this.displayUpdates()}
						</div>
					}
				</div>
				<SelectWinningProposalConfirmationModal
					open={selectWinningProposalConfirmationModal}
					proposal={selectWinningProposalData}
					onRequestSubmit={() => this.onSubmitWinningProposalActions(contest)}
					onSecondarySubmit={this.onCloseSelectWinningProposalConfirmationModal}
					onRequestClose={this.onCloseSelectWinningProposalConfirmationModal}
				/>
			</div>
		);
	}

	displayRound(contest, contestRound) {
		const selectedProposal = contest.selectedProposal !== null && contest.selectedProposal.id;
		const contestCardIsApproved = contest && PROJECT.isContestCardApprovedByClient(contest);
		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		const newEndDate = contestRound && new Date(parseInt(contestRound.endDate));
		const endDate = contestRound && dates.substractDays(newEndDate, 1);
		const daysLeft = endDate && CONTEST.getContestDaysLeft(endDate) + 1;

		return (
			contestCardIsApproved
				?	<div>
					{contest.proposals.length > 0 && contestHasEnded && !hasAWinningProposal
						?	<p>You need to select and approve one quote in order to proceed.</p>
						:	(hasAWinningProposal)
							?	<p>You have approved the below quote for suppliers.</p>
							:	(contest.proposals.length === 0)
								?	<p>Contest is open for suppliers to upload their quotations.</p>
								:	<p>You will be able to select one option once the contest ends <strong>in {daysLeft} days</strong>.</p>
					}
					<div className={classes.quotationContainer}>
						{contest.proposals.map((quotation, i) => (
							this.renderQuotations(quotation, i, selectedProposal, contestHasEnded, hasAWinningProposal)
						))}
					</div>
				</div>
				:	<p>Contest for experts has not been opened yet.</p>
		);
	}

	renderQuotations(quotation, i, selectedProposalId, contestHasEnded, hasAWinningProposal) {
		const { onClickPreviewProposal, openProposalFeedbackModal } = this.props;

		for (const proposalFile of quotation.files) {
			const proposalUrl = this.getProposalUrl(quotation);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;

			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;
			const feedback = quotation.feedback;
			const isWinningProposal = quotation.id === selectedProposalId;

			if (!approvedAndPublished) continue;

			return (
				<div className={classes.proposalCard} key={i} style={{margin: '6px'}}>
					<div className={classes.imageContainer}>
						<img
							key={f.id}
							className={classes.proposalImg}
							src={proposalUrl ? proposalUrl : DEFAULT_IMG_URL}
							onClick={() => onClickPreviewProposal(f)}
							alt='Quotation'
						/>
						{/* // Winner selector option
							<div
								id={f.id}
								className={classes.winningProposalSpace}
								style={{backgroundColor: 'transparent'}}
								onClick={() => openProposalFeedbackModal(quotation)}
							>
								{isWinningProposal
									? <CheckmarkFilled32 className={classnames([classes.selectIcon, classes.successColor])} />
									: (contestHasEnded && !hasAWinningProposal) &&
										<RadioButton32 className={classnames([classes.selectIcon, classes.pendingColor])} />
								}
						</div> */}
					</div>
					<div className={classes.proposalCardContent}>
						<div className={classes.proposalHeader}>
							<h4>{KEYS.quotation_title} #{quotation.number}</h4>
							{isWinningProposal && (
								<Tag
									type={isWinningProposal ? 'green' : 'blue'}
									style={{borderRadius: '4px'}}
								>
									{isWinningProposal ? 'Approved' : (feedback && !isWinningProposal) ? 'Rated' : 'Not rated'}
								</Tag>
							)}
						</div>
						<div className={classes.feedbackToggle} style={{justifyContent: 'flex-start'}}>
							{(feedback && hasAWinningProposal) ? (
								<div>
									<h5>Comments</h5>
									<CodeSnippet type="inline" hideCopyButton>
										{(feedback.info !== null && feedback.info.pros !== null && feedback.info.pros.length > 0) && (
											feedback.info.pros
										)}
									</CodeSnippet>
								</div>
							) : (contestHasEnded && !hasAWinningProposal) ? (
								<Button
									className={classes.btn}
									// disabled={contestHasEnded && hasAWinningProposal}
									onClick={() => openProposalFeedbackModal(quotation)}
								>
									Select as winner {/* // Select as winner and add comment */}
								</Button>
							) : (
								<small>You will be able to select one option once the contest ends.</small>
							)}
						</div>
					</div>
				</div>
			);
		}
	}

	getProposalUrl(quotation) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(quotation.id, token.accessToken, 1000);
	}

	displayUpdates() {
		const { updates, description } = this.props;

		return (
			<div>
				<p>{description}</p>
				<div style={{ padding: '16px' }}>
					{updates && updates.length > 0
						? updates.map((update, i) => (
							<div key={i} className={classes.updateBlock}>
								<span className={classes.line}></span>
								<div style={{ width: '100%' }}>
									<div className={classes.alignItems}>
										<div className={classes.alignItems}>
											<span style={{ marginRight: '8px' }}>📣</span>
											<h5>{update.title}</h5>
										</div>
										<small style={{ marginLeft: '16px' }}>{dates.formattedNewDate(update.createdAt)}</small>
									</div>
									{update.type === CONTEST.CONTEST_STEP_UPDATE_TYPES.ALERT
										?	<InlineNotification
											className={classes.notification}
											style={{margin: '8px 0 0'}}
											kind="warning"
											lowContrast
											hideCloseButton={true}
											title="Alert"
											subtitle={update.content}
										/>
										: (update.type === CONTEST.CONTEST_STEP_UPDATE_TYPES.INFORMATION) &&
										<p>{update.content}</p>
									}
									{(update.files && update.files.length > 0) &&
										<div className={classnames([classes.filesList])}>
											{update.files.map((fileId, i) => (
												<a
													key={i}
													href={serverAPI.getDisplayUrlByFileId(fileId)}
													rel="noreferrer" target="_blank"
												>
													<img
														src={serverAPI.getDisplayUrlByFileId(fileId)}
														className={classes.updateImg}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null;
															currentTarget.src = FILE_PLACEHOLDER_IMG;
														}}
													/>
												</a>
												// for link files
												// file.substring(0, 5) === 'https'
												// 	?	<a href={file} target='_blank' rel='noreferrer'>Check out this link!</a>
												// 	:	<img key={i} src={file ? serverAPI.getDisplayUrlByFileId(file) : DEFAULT_IMG_URL} className={classes.updateImg} />
											))}
										</div>
									}
								</div>
							</div>
						))
						:	<div className={classes.alignContainer}>
							<Information16 style={{ marginRight: '6px' }} />
							<small>No updates yet.</small>
						</div>
					}
					{/* <div className={classes.rateBlock}>
						<small>Rate us</small>
					</div> */}
				</div>
			</div>
		);
	}
}

ContestStep.propTypes = {
	project: PropTypes.object,
	state: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	action: PropTypes.string,
	step: PropTypes.number,
	description: PropTypes.string,
	status: PropTypes.string,
	activeStep: PropTypes.number,
	updates: PropTypes.array,
	onClickPreviewProposal: PropTypes.func,
	openProposalFeedbackModal: PropTypes.func,
	userData: PropTypes.object.isRequired,
};

ContestStep.defaultProps = {
	project: {},
	state: 'prototyping_contest',
	title: '',
	type: 'informative',
	description: '',
	action: '',
	step: 1,
	status: 'pending',
	activeStep: 1,
	updates: [],
	onClickPreviewProposal() {},
	openProposalFeedbackModal() {},
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(ContestStep));
