import React, { useState, useEffect } from 'react';
import withRouter from '../../../../components/route/with-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import config from '../../../../config';

import classes from './validation.module.scss';

import SalesCallModal from '../../../../components/modals/sales-call-modal';

import Pager from '../../../clients/new-project/components/pager';

//const result = JSON.parse('{"feasible":"no","votes":[{"userId":"5ee0ac24865152e06ca78886","feasible":"yes","comment":"Love it! Congrats on the idea. I think it has a lot of potential", "answeredAt": "2022-12-12T11:13:01.000Z"}, {"userId":"5ee0ac24865152e06ca78886","feasible":"yes","comment":"Love it!", "answeredAt": "2022-12-12T12:19:07.000Z"}, {"userId":"5ee0ac24865152e06ca78886","feasible":"yes","comment":"", "answeredAt": "2022-12-12T13:14:07.000Z"}]}');

const isProd = !!('app.lastbasic.com').includes(window.location.hostname);
const feasibilityApiUrl = `https://api${isProd ? '' : '.dev'}.lastbasic.com/v1/apps/feasibility/public/projects`;

function ValidationPage({ match }) {
	document.title = 'Project Validation - LastBasic';

	const [projectId, setProjectId] = useState(null);
	const [result, setResult] = useState(null);
	const [firstTimeResult, setFirstTimeResult] = useState(null);

	const [requestCount, setRequestCount] = useState(0);

	const [salesCallModalOpen, setSalesCallModalOpen] = useState(false);

	useEffect(() => {
		const id = match?.params?.id;

		if (!id) return;
		setProjectId(id);
		getResult(id, true);
	}, []);

	useEffect(() => {
		if (result && result.feasible !== 'pending') return;

		if (projectId) getResult(projectId);
		const timer = setTimeout(() => setRequestCount(requestCount + 1), 5000);
		return () => clearTimeout(timer);
	}, [requestCount]);

	function getAvatarUrl(userId) {
		const apiUrl = isProd ? config.lastbasic.api.host : 'https://dev.api.lastbasic.com';
		return `${apiUrl}/public/user/${userId}/avatar`;
	}

	function translateFeasibilityToHuman(feasibility) {
		switch (feasibility) {
			case 'yes':
				return 'feasible';
			case 'no':
				return 'not feasible';
			case 'not_sure':
				return 'unsure';
			default:
				return 'reviewed';
		}
	}

	function timeToHuman(answeredAt) {
		const date = new Date(answeredAt);

		const minutes = date.getMinutes();

		return `${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
	}

	async function getResult(id, firstTimeResult) {
		try {
			const response = await axios.get(`${feasibilityApiUrl}/${id}`);
			setResult(response.data);

			if (firstTimeResult) {
				setFirstTimeResult(response.data);
			}
		} catch (e) {
			if (e?.response?.status === 404 && projectId) {
				window.location.replace('/project/' + projectId);
			} else {
				// alert(e?.response?.data?.error?.message || 'An error ocurred');
			}
		}
	}

	// If not loaded, show Loading
	if (!projectId) return <>Loading...</>;

	return (
		<>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Pager
						steps={[
							{ name: 'Offering', active: true },
							{ name: 'Details', active: true },
							{ name: 'Validation', active: true },
						]}
					/>
					<div className={classes.title}>
						{ firstTimeResult?.feasible === 'pending' && 'Waiting for validation...' }
						{ firstTimeResult?.feasible !== 'pending' && 'Validation' }
					</div>

					<div className={classes.descriptionWrapper}>
						<div className={classes.description}>
							{ firstTimeResult?.feasible === 'pending' &&
								<>
									Now a team of experts is reviewing your project to check if we can do it at LastBasic.
									You can safely skip this page and go to your new <Link to={'/project/' + projectId}>project page</Link> (the validation will be done in the background)
								</>
							}

							{ firstTimeResult?.feasible !== 'pending' &&
								<>
									A team of experts has reviewed your project to check if we can do it at LastBasic.
									You can now go to your <Link to={'/project/' + projectId}>project page</Link>
								</>
							}
						</div>
					</div>

					<div className={classes.votes}>
						{result && result.votes &&
							result.votes.slice(0, 3).map(vote => {
								return (
									<div className={classes.vote} key={vote.userId}>
										<div>
											<div className={classes.voteImage} style={{ backgroundImage: `url(${getAvatarUrl(vote.userId)})`}}></div>
										</div>
										<div>
											<div className={classes.voteResult}>Marked as {translateFeasibilityToHuman(vote.feasible)} {vote.answeredAt && <span>· {timeToHuman(vote.answeredAt)}</span>}</div>
											{ vote.comment && <div className={classes.voteComment}>{vote.comment}</div> }
										</div>
									</div>
								);
							})
						}
					</div>

					<div className={classes.loading}>
						{ (result?.feasible === 'pending' || !result) &&
							<>
								<CircularProgress color={'inherit'} />
								{ result && result.votes.length > 0 &&
									<div className={classes.lookingForMore}>Waiting for more experts...</div>
								}
							</>
						}

						{ result?.feasible === 'yes' &&
							<svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className={classes.checkmark__circle} cx="26" cy="26" r="25" fill="none"/> <path className={classes.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
						}

						{ result?.feasible === 'no' &&
							<svg className={classes.checkmark_red} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className={classes.checkmark__circle_red} cx="26" cy="26" r="25" fill="none"/> <line fill="none" className={classes.checkmark__check_red} x1="35.01" y1="16.74" x2="15.42" y2="36.33"/><line fill="none" className={classes.checkmark__check_red} x1="15.42" y1="16.74" x2="35.01" y2="36.33"/> </svg>
						}
					</div>

					<div className={classes.messageWrapper}>
						{ result?.feasible === 'pending' &&
							<div className={classes.message + ' ' + (requestCount > 2 ? classes.showMessage : classes.hideMessage) + ' ' + classes.alternativeMessage}>
								Looks like it is taking more than expected. We will continue the validation in the background
								<br/>
								<Link to={'/project/' + projectId}>
									<div className={classes.button}>Go to your project page</div>
								</Link>
							</div>
						}

						{ result?.feasible === 'yes' &&
							<div className={classes.message}>
								🎉 Congratulations! Your project is feasible for us. That means we can totally make it a reality! Now go to your project page and unlock it!
								<br/>
								<Link to={'/project/' + projectId}>
									<div className={classes.button}>Go to your project page</div>
								</Link>
							</div>
						}

						{ result?.feasible === 'no' &&
							<div className={classes.message}>
								Looks like your project does not meet our standards. You can create a new project or contact us on the chat
								<br/>
								<Link to={'/new/project'}>
									<div className={classes.button}>Create new project</div>
								</Link>
							</div>
						}
					</div>

					<div className={classes.blocks}>
						<div className={classes.block}>
							<div className={classes.blockTitle}>Did you know...</div>
							<div className={classes.blockDescription}>You can save up to 80,000€ when creating a prototype with us instead of with traditional consultancies</div>
						</div>

						<div className={classes.block}>
							<div className={classes.blockTitle}>Guarantees</div>
							<div className={classes.blockDescription}>You can pay the whole functional prototype bit by bit, with no commitment at all and with the LastBasic Quality</div>
						</div>

						<div className={classes.block}>
							<div className={classes.blockTitle}>Human</div>
							<div className={classes.blockDescription}>
								We are as human as one can be.
								If you want to meet with us, you can <span className={classes.bookSlotText} onClick={() => setSalesCallModalOpen(true)}>book a slot with us</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<SalesCallModal open={salesCallModalOpen} onRequestClose={() => setSalesCallModalOpen(false)} />
		</>
	);
}

// Props configuration
ValidationPage.propTypes = {
	match: PropTypes.object,
};

ValidationPage.defaultProps = {
	match: {},
};

export default withRouter(ValidationPage);
