import { LOGIN_SUCCESS, LOGOUT } from './types';
import { auth, pushNotifications, userInfo } from '../../utils';

export const loginSuccess = data => ({
	type: LOGIN_SUCCESS,
	data,
});

export const logoutAction = {
	type: LOGOUT,
};

export const storeNewUser = (data, rememberMe = false) => (dispatch) => {
	if (!data) return false;
	const { token, user } = data;
	if (!token || !user) return false;	
	
	const notifications = {
		email: user.emailNotificationsEnabled,
		push: user.pushNotificationsEnabled,
	};

	const info = {
		hasAvatar: data.user.hasAvatar,
		hasSignedNDA: data.user.expert ? !!data.user.expert.ndaSignedAt : false,
		paymentInfo: (data.user.expert && data.user.expert.paymentInfo)
			? data.user.expert.paymentInfo.invoiceType
			: null,
	};

	try {
		// Store tokens
		auth.storeTokens(data, rememberMe);
		pushNotifications.storeNotificationsStates(notifications);
		userInfo.storeUserInfo(info);

		dispatch(loginSuccess(data));

		return true;
	} catch (e) {
		return false;
	}
};

export const login = (mutation, data, rememberMe = false) => async (dispatch) => {
	try {
		const result = await mutation({
			variables: {
				...data,
				rememberMe,
			}
		});

		if (!result || !result.data || !result.data.login || result.status >= 400) {
			return false;
		}

		// Store new user
		const resData = result.data.login;
		dispatch(storeNewUser(resData, rememberMe));
		
		return true;
	} catch (e) {
		return false;
	}
};

export const logout = () => (dispatch) => {
	try {
		// Store tokens
		auth.removeTokens();
		pushNotifications.removeNotificationsStates();
		userInfo.removeUserInfo();
		window.localStorage.clear();

		dispatch(logoutAction);
		return true;
	} catch (e) {
		return false;
	}
};

export const forgotPassword = (mutation, data) => async () => {
	try {
		const result = await mutation({
			variables: {
				...data,
			}
		});
		if (!result || !result.data || !result.data.forgotPassword || result.status >= 400)  {
			return false;
		}
		
		return true;
	} catch (e) {
		return false;
	}
};

export const createPassword = (mutation, data) => async () => {
	try {
		const result = await mutation({
			variables: {
				...data,
			}
		});
		if (!result || !result.data || !result.data.createPassword || result.status >= 400)  {
			return false;
		}
		
		return true;
	} catch (e) {
		return false;
	}
};