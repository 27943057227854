import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'carbon-components-react';

import classes from '../auth/pages.module.scss';

function ErrorComponent (props) {
	const { errorMessage } = props;

	const messageTitles = [
		'Oops! Something went wrong',
		'Looks like something went wrong',
		'Something unexpected happened',
	];

	const messageTitle = messageTitles[Math.floor(Math.random()*messageTitles.length)];

	return (
		<div className={classes.alertWrapper}>
			<div className={classes.alertBox}>
				<h4 style={{ lineHeight: '1.1' }}>{messageTitle}</h4>
				<div style={{ margin: '10px 0'}}>
					We are working to get this fixed as soon as possible. Please try again or wait until we fix it
				</div>
				<div style={{ margin: '16px 0 25px' }}>
					<div style={{ fontFamily: 'monospace', opacity: 0.6, fontSize: '12px' }}>
						{errorMessage}
					</div>
				</div>
				<Button
					className={classes.btn}
					kind='primary'
					onClick={() => window.location.reload()}
				>
					Try again
				</Button>
			</div>
		</div>
	);
}

ErrorComponent.propTypes = {
	errorMessage: PropTypes.string,
};

ErrorComponent.defaultProps = {
	errorMessage: null,
};

export default ErrorComponent;
