import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	InlineNotification,
	InlineLoading,
	Modal,
	Button,
	TextArea,
	Form,
	// RadioButtonGroup,
	// RadioButton
} from 'carbon-components-react';

import { GIVE_FEEDBACK_TO_PROPOSAL } from '../../providers/mutations';
import { serverAPI } from '../../utils';
import KEYS from '../../glossary';

import classes from './edit-feedback-modal.module.scss';

class QuotationVotationModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			rating: 1,
			comment: 'Not specified',
			sendingFeedback: false,
			success: false,
			errorValidatingFeedback: null,
		};
	}

	onSetRating = (number) => {
		this.setState({ rating: number });
	};

	onSetQuotationComment = (e) => {
		this.setState({ comment: e.target.value });
	};

	async onSubmitFeedbackToProposal(e, proposal, giveFeedbackToProposalMutation) {
		const { onRequestSubmit } = this.props;
		const { rating, comment } = this.state;

		e.preventDefault();
		let error;
		this.setState({ sendingFeedback: true });

		try {
			const result = await giveFeedbackToProposalMutation({
				variables: {
					proposalId: proposal.id,
					feedback: {
						rating: rating,
						info: {
							pros: comment,
							cons: comment,
							keepFeatures: '-',
							removeFeatures: '-'
						}
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			this.setState({ errorValidatingFeedback: error.toString() });
			setTimeout(() => {
				this.setState({ errorValidatingFeedback: null });
			}, 3000);
		} else {
			console.log('Success giving feedback to proposal');
			this.setState({
				success: true,
				sendingFeedback: false
			});
			(rating === 5) (
				setTimeout(() => {
					onRequestSubmit(proposal.id);
				}, 1000)
			);
		}
		this.setState({ sendingFeedback: false });
	}

	getProposalThumbnailUrl(proposal, userData) {
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	render() {
		const { proposal, onRequestClose, userData, onClickPreviewProposal, ...restProps } = this.props;
		const {
			rating,
			comment,
			sendingFeedback,
			success,
			errorValidatingFeedback,
		} = this.state;

		const fileVersion = proposal.files[0].versions[0];
		const f = fileVersion.file;

		return (
			<Mutation mutation={GIVE_FEEDBACK_TO_PROPOSAL}>
				{(giveFeedbackToProposalMutation, { error }) => {
					return (
						<Modal
							passiveModal={true}
							hasScrollingContent
							modalHeading={'Quotation selection'}
							aria-label={'Quotation selection'}
							primaryButtonText={sendingFeedback ? 'Sending feedback ...' : KEYS.submit_feedback}
							secondaryButtonText={KEYS.cancel}
							onRequestClose={onRequestClose}
							{...restProps}>
							{error && (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton={false}
									title="Error"
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							)}
							<Form>
								<div className={classes.imgContainer}>
									<img
										className={classes.proposalImg}
										src={this.getProposalThumbnailUrl(proposal, userData)}
										alt={KEYS.proposal_overview_alt}
										onClick={() => onClickPreviewProposal(f)}
									/>
								</div>
								<div className={classes.modalContent}>
									{/* <div className={classes.contestContent}>
										<h5>Select as winner <small>*</small></h5>
										<p>Approving this quote means the selection of winning quotation and it cannot be reverted.</p>
										<div style={{ margin: '16px' }}>
											<RadioButtonGroup
												className={classes.radioButtonGroup}
												orientation={'horizontal'}
												name={'quotation-approve-selector'}
											>
												<RadioButton
													labelText={'Confirm this quotation'}
													value={'Confirm this quotation'}
													disabled={rating < 1}
													onClick={() => this.onSetRating(5)}
												/>
												// <RadioButton
												// 	labelText={'Not approve'}
												// 	value={'Not approve'}
												// 	disabled={rating < 1}
												// 	onClick={() => this.onSetRating(1)}
												// />
											</RadioButtonGroup>
										</div>
									</div> */}
									<div className={classes.contestContent}>
										<h5>Add a comment for supplier <small>(optional)</small></h5>
										<p></p>
										<div>
											<TextArea
												id="comment-quotation-input"
												labelText=""
												helperText="Minimum 8 characters"
												invalid={(errorValidatingFeedback !== null && comment.length < 8) ? true : false}
												invalidText={errorValidatingFeedback}
												minLength="8"
												cols={30}
												rows={2}
												required
												placeholder={'Any detail you want to emphasize'}
												onChange={(e) => this.onSetQuotationComment(e)}
											/>
										</div>
									</div>
								</div>
								<div className={classes.btnBox}>
									<Button type="submit" className={classes.btn} onClick={onRequestClose} kind="secondary" disabled={sendingFeedback || success}>
										{KEYS.cancel}
									</Button>
									{sendingFeedback || success ? (
										<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={success ? 'Pending winner selection' : 'Selecting winner...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorValidatingFeedback
										? 	<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={'Error validating feedback'}
											status={'error'}
										/>
										:	(
											<Button
												className={classes.btn}
												disabled={rating === 0}
												onClick={(e) => this.onSubmitFeedbackToProposal(e, proposal, giveFeedbackToProposalMutation)}
											>
												Select this quote as winner
											</Button>
										)}
								</div>
							</Form>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

QuotationVotationModal.propTypes = {
	open: PropTypes.bool.isRequired,
	proposal: PropTypes.object.isRequired,
	userData: PropTypes.object,
	onRequestClose: PropTypes.func.isRequired,
	onSecondarySubmit: PropTypes.func.isRequired,
	onRequestSubmit: PropTypes.func.isRequired,
	onClickPreviewProposal: PropTypes.func,
};

QuotationVotationModal.defaultProps = {
	open: false,
	proposal: {},
	userData: {},
	onRequestClose: () => {},
	onSecondarySubmit: () => {},
	onRequestSubmit: () => {},
	onClickPreviewProposal: () => {},
};

export default QuotationVotationModal;
