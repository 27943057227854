import React from 'react';
import PropTypes from 'prop-types';

import classes from './pager.module.scss';

export default function Page({ steps }) {
	return (
		<div className={classes.pager}>
			{ steps.map((step, k) => {
				return (
					<div className={classes.step} key={k}>
						<div className={classes.bubble + (!step.active ? ' ' + classes.gray : '')}>{k + 1}</div>
						<div className={classes.name + (!step.active ? ' ' + classes.nameGray : '')}>{step.name}</div>
						{ (k !== steps.length - 1) && <div className={classes.separator} /> }
					</div>
				);
			})}
		</div>
	);
}

Page.propTypes = {
	steps: PropTypes.array,
};
