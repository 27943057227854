import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import classnames from 'classnames';
import {
	Accordion,
	AccordionItem,
	Button,
	InlineNotification,
	Tag,
	ProgressIndicator,
	ProgressStep,
	Tabs,
	Tab,
	TooltipDefinition,
	TextArea,
	InlineLoading,
	SkeletonText,
	TooltipIcon,
} from 'carbon-components-react';
import {
	Information16, TrophyFilled32,
	RadioButtonChecked20, Locked20,
	CheckmarkFilled20, Notification20,
	Document20, Hashtag20, Bot20,
	MisuseOutline20, Time20,
	TaskView24, TaskView32,
	Close32, ChevronDown24,
	ChevronDown20, ChevronUp20,
	ChevronUp24, Unlocked20,
	Calendar20, Folders20,
	ListDropdown20, Certificate20,
	Result20, NumberSmall_124,
	NumberSmall_224, NumberSmall_324,
	NumberSmall_424, NumberSmall_524,
	NumberSmall_624, NumberSmall_724,
	NumberSmall_824, NumberSmall_924,
	ProgressBar16, ProgressBarRound16,
	TaskView16
} from '@carbon/icons-react';
import Rating from '@mui/material/Rating';
import ReactMarkdown from 'react-markdown';
import confetti from 'canvas-confetti';

import ContestIcon from '../../../../components/project/contest-icon';
import Heart from '../../../../components/icons/heart';
import RatingValue from '../../../../components/project/rating-value';
import withRouter from '../../../../components/route/with-router';

import ContestCardModal from '../../../../components/modals/contest-card-modal';
import EditFeedbackModal from '../../../../components/modals/edit-feedback-modal';
import QuotationVotationModal from '../../../../components/modals/quotation-votation-modal';
import UpsellModal from '../../../../components/modals/upsell-modal';

import RequestChangeContestCardModal from '../../../../components/modals/request-change-contest-card-modal';
import SelectWinningProposalConfirmationModal from '../../../../components/modals/select-winning-proposal-confirmation-modal';
import ContestSetupQuestions from '../../../../components/project/setup-questions/contest-setup-questions';
import ViewSetupQuestions from '../../../../components/project/setup-questions/view-setup-questions';
import ContestCardImage from '../../../../components/project/contest-card-image';
import UpsaleAction from '../../../../components/project/upsale-action';

import ContestStep from './contest-step';
import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import { PROJECT, CONTEST, PROPOSAL } from '../../../../constants';
import {
	CONFIRM_CONTEST_CARD,
	SELECT_WINNING_PROPOSAL,
	ADD_CONTEST_REVIEW
} from '../../../../providers/mutations';
import { GET_PROJECT_UPDATES, GET_PHASE_STEPS } from '../../../../providers/queries';

import { FormUtils, serverAPI, strings, dates } from '../../../../utils';
import KEYS from '../../../../glossary';

import classes from './phase-contest-details.module.scss';

class PhaseContestDetails extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openContestCard: false,
			contestCard: null,
			openProposalFeedbackModal: false,
			openQuotationFeedbackModal: false,
			proposalFeedback: null,
			selectWinningProposalConfirmationModal: false,
			winnerSelectedConfirmation: null,
			selectWinningProposalData: null,
			requestChangeContestCard: false,
			hideProposals: false,
			showTrophy: {show: false, number: 0},
			activeTab: 0,
			phaseRating: 0,
			errorAddingPhaseFeedback: null,
			showPhaseFeedbackBoxModal: false,
			showNextContestBoxModal: false,
			qualityOfProposal: 0,
			successOfTheWinner: 0,
			processesUnderstanding: 0,
			teamSupport: 0,
			comments: '',
			success: false,
			showPhaseFeedback: false,
			currentContestStep: 1,
			contestSetUpCompleted: false,
			sendingConfirmContestRound: false,
			successConfirmingContest: false,
			errorConfirmingContestRound: null,
			confirmedContest: null,
			showBoosters: false,
			openUpsellModal: null,
			upsellInfo: null,
			selectedWinningProposalId: null,
		};
	}

	componentDidMount() {
		const { location } = this.props;
		const searchParams = location.search;
		const queryString = searchParams.substring(searchParams.lastIndexOf('state='));
		const tabIndex = queryString.includes('proposals') ? 1 : queryString.includes('winner') ? 2 : 0;

		this.setState({ activeTab: tabIndex });
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		const searchParams = location.search;
		const queryString = searchParams.substring(searchParams.lastIndexOf('state='));
		const tabIndex = queryString.includes('proposals') ? 1 : queryString.includes('winner') ? 2 : 0;

		if (searchParams !== prevProps.location.search) {
			this.setState({ activeTab: tabIndex });
		}
	}

	getProposalUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken, 1000);
	}

	onCloseSelectWinningProposalConfirmationModal = () => {
		this.setState({ selectWinningProposalConfirmationModal: false });
	};

	renderProposalFiles(proposal, i, selectedProposalId, contestHasEnded, hasAWinningProposal) {
		const { onClickPreviewProposal } = this.props;

		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;
			
			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;
			const feedback = proposal.feedback;
			const isWinningProposal = proposal.id === selectedProposalId;

			if (!approvedAndPublished) continue;

			return (
				<div className={classes.proposalCard} key={i}>
					<Mutation mutation={SELECT_WINNING_PROPOSAL}>
						{(selectWinningProposalMutation) => (
							<div className={classes.imageContainer}>
								<img
									key={f.id}
									className={classes.proposalImg}
									src={proposalUrl}
									onClick={() => onClickPreviewProposal(f)}
									alt='Proposal'
								/>
								{(feedback && contestHasEnded && !hasAWinningProposal) &&
									<div
										className={classes.winningProposalSpace}
										onMouseEnter={() => this.setState({ showTrophy: {show: true, number: f.id} })}
										onMouseLeave={() => this.setState({ showTrophy: {show: false, number: f.id} })}
										onClick={() => this.setState({ selectWinningProposalConfirmationModal: true, selectWinningProposalData: { proposal, selectWinningProposalMutation} })}
										id={f.id}
									>
										{(this.state.showTrophy.show === true && this.state.showTrophy.number === f.id)
											?	<TrophyFilled32 style={{ margin: '4px'}} />
											: 	<Heart active={isWinningProposal} />
										}
									</div>
								}
							</div>
						)}
					</Mutation>
					<div className={classes.proposalCardContent}>
						<div className={classes.proposalHeader}>
							<h4>{KEYS.proposal_title} #{proposal.number}</h4>
							<Tag type={feedback ? 'green' : 'blue'} style={{borderRadius: '4px'}}>{feedback ? 'Ranked' : 'Pending review'}</Tag>
						</div>
						{feedback
							? <RatingValue rating={feedback.rating} />
							: <small>{KEYS.not_ranked}</small>
						}
						<div className={classes.feedbackToggle}>
							{feedback ? (
								<Accordion>
									<AccordionItem
										className="toggleTitleProjectCard"
										title={KEYS.proposal_check_feedback_title}
									>
										{(feedback.info !== null && feedback.info.pros !== null &&
											feedback.info.pros.length > 0) &&
											<div className={classes.contestContent}>
												<h5>{KEYS.proposal_pros_title}</h5>
												<ul className={classes.listModal}>
													<li>
														<ReactMarkdown>
															{feedback.info.pros}
														</ReactMarkdown>
													</li>
												</ul>
											</div>
										}
										{(feedback.info !== null && feedback.info.cons !== null &&
											feedback.info.cons.length > 0) &&
											<div className={classes.contestContent}>
												<h5>{KEYS.proposal_cons_title}</h5>
												<ul className={classes.listModal}>
													<li>
														<ReactMarkdown>
															{feedback.info.cons}
														</ReactMarkdown>
													</li>
												</ul>
											</div>
										}
										{(feedback.info !== null && feedback.info.keepFeatures !== null &&
											feedback.info.keepFeatures.length > 0) &&
											<div className={classes.contestContent}>
												<h5>{KEYS.proposal_keepFeatures_title}</h5>
												<ul className={classes.listModal}>
													<li>
														<ReactMarkdown>
															{feedback.info.keepFeatures}
														</ReactMarkdown>
													</li>
												</ul>
											</div>
										}
										{(feedback.info !== null &&  feedback.info.removeFeatures !== null &&
											feedback.info.removeFeatures.length > 0) &&
											<div className={classes.contestContent}>
												<h5>{KEYS.proposal_removeFeatures_title}</h5>
												<ul className={classes.listModal}>
													<li>
														<ReactMarkdown>
															{feedback.info.removeFeatures}
														</ReactMarkdown>
													</li>
												</ul>
											</div>
										}
									</AccordionItem>
								</Accordion>
							) : (
								<Button
									className={classes.btn}
									disabled={contestHasEnded && hasAWinningProposal}
									onClick={() => this.setState({ openProposalFeedbackModal: true, proposalFeedback: proposal })}
								>
									{KEYS.give_feedback}
								</Button>
							)}
						</div>
					</div>
				</div>
			);
		}
	}

	displayContestProposals(project, state) {
		const { hideProposals } = this.state;
		if (!state) state = project.state;
		const contest = project && PROJECT.getContestByState(project, state);
		const selectedProposal = contest.selectedProposal !== null && contest.selectedProposal.id;

		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);
		const selectWinnerProposal = (contestHasEnded && !hasAWinningProposal && contest.proposals.length > 0) ? true : false;
		const giveProposalFeedbackBeforeSelectingWinner = selectWinnerProposal && PROPOSAL.hasPendingReviewFile(contest.proposals);
		const contestCardIsApprovedByClient = contest && PROJECT.isContestCardApprovedByClient(contest);

		return (
			<div className={classes.contestContentBlock}>
				<div className={classes.flexColumn}>
					<div className={classes.stepPhaseBox}>
						<h4>{KEYS.proposals_header}</h4>
					</div>
					<ul>
						<li className={classes.listItem} onClick={() => window.Froged('doc', '62629b0b4f3af575738fce35')}>
							<Link to='#'>
								How to rate proposals
							</Link>
						</li>
						<li className={classes.listItem} onClick={() => window.Froged('doc', '626430ac3dfba9d107b9c6b7')}>
							<Link to='#'>
								What should I check for in each phase?
							</Link>
						</li>
					</ul>
					{/* <small style={{ color: '#0062ff' }} onClick={() => this.setState({ hideProposals: !hideProposals })}>{hideProposals ? 'Show proposals' : 'Hide proposals'}</small> */}
				</div>
				{(contestCardIsApprovedByClient && contest.proposals.length === 0) ? (
					<>
						{project.state === state ? (
							!hideProposals && (
								<>
									<InlineNotification
										kind="info"
										className={classes.notification}
										lowContrast
										hideCloseButton
										iconDescription="Info"
										subtitle={<span>{KEYS.proposals_waiting_alert}</span>}
										title=""
									/>
									<div className={classnames([classes.proposalCard, classes.skeletonProposal])}>
										<div className={classes.imageCardSkeleton}></div>
										<div className={classes.proposalCardContent}>
											<div className={classes.proposalHeader}>
												<h4>{KEYS.proposal_title} #</h4>
												<Tag type={'blue'} style={{borderRadius: '4px'}}>{'In progress'}</Tag>
											</div>
											<RatingValue rating={0} />
											<div className={classes.feedbackToggle} style={{ marginTop: '36px' }}></div>
										</div>
									</div>
								</>
							)
						) : (
							<div className={classes.overviewMessageBox}>
								<Information16 />
								<small>{KEYS.close_contest}: {KEYS.proposal_empty}</small>
							</div>
						)}
					</>
				) : (
					!contestCardIsApprovedByClient && (
						<div className={classes.overviewMessageBox}>
							<Information16 />
							<small>No proposals yet</small>
						</div>
					)
				)}
				{!hideProposals && (
					<>
						{selectWinnerProposal && (
							<InlineNotification
								kind="info"
								lowContrast
								hideCloseButton={false}
								iconDescription="Info"
								subtitle={'Provide feedback on all the proposals and then select the winner by clicking on the heart (❤️).'}
								title=""
							/>
						)}
						{giveProposalFeedbackBeforeSelectingWinner && (
							<InlineNotification
								kind="info"
								lowContrast
								hideCloseButton={false}
								iconDescription="Info"
								subtitle={'You need to provide feedback on all proposals in order to select a winner.'}
								title=""
							/>
						)}
						<div className={classes.proposalsContainer}>
							{contest.proposals.map((proposal, i) => (
								this.renderProposalFiles(proposal, i, selectedProposal, contestHasEnded, hasAWinningProposal)
							))}
						</div>
					</>
				)}
			</div>
		);
	}

	renderWinningProposal(proposal, selectedProposalId) {
		const { onClickPreviewProposal } = this.props;

		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;

			const feedback = proposal.feedback;
			const isWinningProposal = proposal.id === selectedProposalId;

			return (
				isWinningProposal &&
				<Fragment key={proposal.number}>
					<div className={classes.proposalsContainer}>
						<div className={classes.proposalCard}>
							<div className={classes.imageContainer}>
								<img
									key={f.id}
									className={classes.proposalImg}
									src={proposalUrl}
									onClick={() => onClickPreviewProposal(f)}
									alt='Proposal'
								/>
								<div className={classnames([classes.winningProposalSpace, classes.winningContainer])}>
									<TrophyFilled32 style={{ fill: '#ffc637' }} />
								</div>
							</div>
							<div className={classes.proposalCardContent}>
								<div className={classes.proposalHeader}>
									<h4>{KEYS.proposal_title} #{proposal.number}</h4>
									<Tag type='gray' style={{borderRadius: '4px', backgroundColor: '#ffc637', color: '#fff'}}>Winner</Tag>
								</div>
								<RatingValue rating={feedback.rating} />
								<div className={classes.feedbackToggle}>
									<Accordion>
										<AccordionItem
											className="toggleTitleProjectCard"
											title={KEYS.proposal_check_feedback_title}
										>
											{(feedback.info !== null && feedback.info.pros !== null &&
											feedback.info.pros.length > 0) &&
												<div className={classes.contestContent}>
													<h5>{KEYS.proposal_pros_title}</h5>
													<ul className={classes.listModal}>
														<li>
															<ReactMarkdown>
																{feedback.info.pros}
															</ReactMarkdown>
														</li>
													</ul>
												</div>
											}
											{(feedback.info !== null && feedback.info.cons !== null &&
											feedback.info.cons.length > 0) &&
												<div className={classes.contestContent}>
													<h5>{KEYS.proposal_cons_title}</h5>
													<ul className={classes.listModal}>
														<li>
															<ReactMarkdown>
																{feedback.info.cons}
															</ReactMarkdown>
														</li>
													</ul>
												</div>
											}
											{(feedback.info !== null && feedback.info.keepFeatures !== null &&
											feedback.info.keepFeatures.length > 0) &&
												<div className={classes.contestContent}>
													<h5>{KEYS.proposal_keepFeatures_title}</h5>
													<ul className={classes.listModal}>
														<li>
															<ReactMarkdown>
																{feedback.info.keepFeatures}
															</ReactMarkdown>
														</li>
													</ul>
												</div>
											}
											{(feedback.info !== null && feedback.info.removeFeatures !== null &&
											feedback.info.removeFeatures.length > 0) &&
												<div className={classes.contestContent}>
													<h5>{KEYS.proposal_removeFeatures_title}</h5>
													<ul className={classes.listModal}>
														<li>
															<ReactMarkdown>
																{feedback.info.removeFeatures}
															</ReactMarkdown>
														</li>
													</ul>
												</div>
											}
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}
	}

	displayWinningContestProposal(project, state) {
		const { winnerSelectedConfirmation } = this.state;

		if (!state) state = project.state;
		const contest = project && PROJECT.getContestByState(project, state);
		const contestType = contest && PROJECT.getStateByContestTypeShort(contest.type);

		const contestHasEnded = contest && PROJECT.hasContestPassed(contest);
		const selectedProposal = contest.selectedProposal !== null && contest.selectedProposal.id;
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);
		const selectWinnerProposal = (contestHasEnded && !hasAWinningProposal && contest.proposals.length > 0) ? true : false;

		return (
			<div className={classes.contestContentBlock}>
				<div className={classes.stepPhaseBox}>
					<div className={classes.stepPhaseBox}>
						<h4>
							{hasAWinningProposal ? `This is the ${contestType} winning proposal` : 'Winner'}
						</h4>
					</div>
				</div>
				{(winnerSelectedConfirmation !== null && winnerSelectedConfirmation.contestType === contest.type) &&
					<InlineNotification
						kind="success"
						lowContrast
						hideCloseButton={false}
						iconDescription="Info"
						subtitle={`Congratulations! You have now finished the ${contestType}. Stay tuned to your inbox for next steps.`}
						title=""
					/>
				}
				{hasAWinningProposal ?
					contest.proposals.map(proposal => (
						this.renderWinningProposal(proposal, selectedProposal)
					))
					: 	selectWinnerProposal
						? 	<div>
							<InlineNotification
								kind="info"
								lowContrast
								hideCloseButton={false}
								iconDescription="Info"
								subtitle={'The contest deadline is over. Please select a proposal as a winner to finish this contest phase.'}
								title=""
							/>
							{/* <InlineNotification
										kind="info"
										lowContrast
										hideCloseButton={false}
										iconDescription="Info"
										subtitle={'You need to give feedback to all the proposals in order to be able to vote a winner.'}
										title=""
									/> */}
							<div style={{ display: 'flex', flexWrap: 'wrap'}}>
								{contest.proposals.map((proposal, i) => (
									this.renderProposalsForVoting(proposal, i)
								))}
							</div>
						</div>
						:	<div className={classes.overviewMessageBox}>
							<Information16 />
							<small>No winning proposal yet.</small>
						</div>
				}
			</div>
		);
	}

	renderProposalsForVoting(proposal, i) {
		const { onClickPreviewProposal } = this.props;

		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;

			const feedback = proposal.feedback;

			return (
				feedback !== null &&
				<Mutation mutation={SELECT_WINNING_PROPOSAL} key={i}>
					{(selectWinningProposalMutation) => (
						<Fragment key={proposal.number}>
							<div className={classes.proposalsContainer} style={{ margin: '0'}}>
								<div className={classes.proposalCard} style={{display: 'flex'}}>
									<div className={classes.imageContainer}>
										<img
											key={f.id}
											className={classnames([classes.proposalImg, classes.votingImg])}
											src={proposalUrl}
											onClick={() => onClickPreviewProposal(f)}
											alt='Proposal'
										/>
									</div>
									<div className={classes.proposalCardContent} style={{ width: '286px'}}>
										<div className={classes.proposalHeader}>
											<h4>{KEYS.proposal_title} #{proposal.number}</h4>
										</div>
										<RatingValue rating={feedback.rating} />
										<div className={classes.feedbackToggle}>
											<Button
												className={classes.btn}
												onClick={() => this.setState({ selectWinningProposalConfirmationModal: true, selectWinningProposalData: { proposal, selectWinningProposalMutation} })}
												kind='primary'
											>
												Select as winner
											</Button>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</Mutation>
			);
		}
	}

	displayContestStatusInfo(contest, project, state) {
		const isContestOpen = contest && CONTEST.isContestOpen(contest, project);
		const hasAWinningProposal = contest && PROPOSAL.hasAWinningProposal(contest);
		const isEnded = contest && CONTEST.isContestEnded(contest);

		const contestRound = contest && CONTEST.getLastRound(contest);
		const activePhase = project.state === state ? true : false;
		const isContestPhaseActive = contest && contest.active;

		const isPrototypingPhase = state === PROJECT.STATE.PROTOTYPING_CONTEST;

		return (
			isPrototypingPhase && activePhase
				?	this.displayPrototypingPhaseExpectations(contest)
				:	<div className={classes.contestSideContainer}>
					<div className={classes.projectStatusBox}>
						{(!isEnded && (!isContestOpen && !activePhase) && !hasAWinningProposal) &&
							<div className={classes.projectStatus}>
								{/* {((contestRound !== null) && (hasAWinningProposal || (!isContestOpen && (isEnded && !hasAWinningProposal)))) &&
								<> */}
								<div className={classes.contestStatusBox}>
									<h4>Contest status</h4>
									{isContestPhaseActive
										?	<Locked20 style={{ fill: 'grey', margin: '4px'  }} />
										:	<MisuseOutline20 style={{ fill: 'grey', margin: '4px'  }} />
									}
								</div>
								<div className={classes.projectStatusBox}>
									<div className={classes.projectDetails}>
										<h5>{KEYS.project_status_phase}</h5>
										<Tag type="gray" style={{borderRadius: '4px'}}>{PROJECT.stateToString(state)}</Tag>
									</div>
									<div className={classes.projectDetails}>
										<h5>Condition</h5>
										<p>{isContestOpen
											?	'Open contest'
											:	isEnded && hasAWinningProposal
												?	'Contest ended'
												:	isEnded && !hasAWinningProposal
													? 	'Winner selection'
													: !isContestPhaseActive
														?	'Inactive'
														:	'Closed contest'
										}</p>
									</div>
									{((contestRound !== null) && (hasAWinningProposal || (!isContestOpen && (isEnded && !hasAWinningProposal)))) &&
											<div className={classes.projectDetails}>
												<h5>Dates</h5>
												<p>
													{contestRound.startDate !== null
														?	dates.formattedNewDate(contestRound.startDate)
														:	KEYS.project_status_contest_deadline_nd
													} - {contestRound.endDate !== null
														?	dates.formattedNewDate(contestRound.endDate)
														:	KEYS.project_status_contest_deadline_nd
													}
												</p>
											</div>
									}
								</div>
								{/* </>
								} */}
							</div>
						}
						{((!isContestOpen && !hasAWinningProposal && activePhase && project.state !== PROJECT.STATE.CLOSED) || isContestOpen)
							?	this.displayContestChecklist(contest, contestRound, activePhase)
							:	(hasAWinningProposal || (isEnded && hasAWinningProposal)) && // && activePhase !== isPrototypingPhase
								this.displayContestSummary(state, contest)
						}
					</div>
				</div>
		);
	}

	displayContestChecklist(contest, contestRound, activePhase) {
		const { card } = contestRound !== undefined && contestRound;
		const { clientConfirmed } = card !== undefined && card;

		const setupQuestions = contest !== undefined && contest.setupQuestions;
		const doesSetupQuestionsHasAnswer = setupQuestions && FormUtils.isAtLeastOneQuestionAnswered(setupQuestions);
		const pendingTasks = [];

		if (doesSetupQuestionsHasAnswer || clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES) {
			pendingTasks.push({task: 'Set up contest', status: 'completed'});
		} else {
			pendingTasks.push({task: 'Set up contest', status: 'pending'});
		}

		if (clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES) {
			pendingTasks.push({task: 'Confirm contest', status: 'completed'});
		} else if ((contestRound === false || !doesSetupQuestionsHasAnswer) || clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.DRAFT) {
			pendingTasks.push({task: 'Confirm contest', status: 'soon'});
		} else {
			pendingTasks.push({task: 'Confirm contest', status: 'pending'});
		}

		const totalPendingReviewFiles = contest.proposals && PROPOSAL.getTotalPendingReviewFiles(contest.proposals);
		const hasPendingReviewFile = contest.proposals && PROPOSAL.hasApprovedAndPublishedFile(contest.proposals);

		if (clientConfirmed !== CONTEST.CONTEST_CARD_CONFIRMATION.YES || contest.proposals.length === 0) {
			pendingTasks.push({task: 'Review proposals', status: 'soon'});
		} else if (hasPendingReviewFile && totalPendingReviewFiles > 0) {
			pendingTasks.push({task: `Review proposals (${totalPendingReviewFiles} new proposals)`, status: 'pending'});
		} else {
			pendingTasks.push({task: 'Review proposals', status: 'completed'});
		}

		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		if (contestHasEnded && !hasAWinningProposal && clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES && contest.proposals.length > 0) {
			pendingTasks.push({task: 'Select a winner', status: 'pending'});
		} else if (contestHasEnded && hasAWinningProposal) {
			pendingTasks.push({task: 'Select a winner', status: 'completed'});
		} else {
			pendingTasks.push({task: 'Select a winner', status: 'soon'});
		}

		const myTotalPendingTasks = pendingTasks.filter(task => task.status === 'pending').length;

		return (
			<div className={classes.projectStatus}>
				<div className={classes.projectTitle}>
					<h4>Contest checklist</h4>
					<RadioButtonChecked20 style={{ fill: '#24a148', margin: '4px'  }} />
				</div>
				<div className={classes.projectStatusBox} id="checklist">
					<div className={classes.tasksContainer}>
						{((Array.isArray(contest.proposals) && contest.proposals.length === 0) && activePhase && clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES) &&
							<InlineNotification
								kind="info"
								className={classes.notification}
								style={{minWidth: '-webkit-fill-available'}}
								lowContrast
								hideCloseButton
								iconDescription="Info"
								subtitle={<span>Experts are joining your contest and preparing their proposals</span>}
								title=""
							/>
						}
						{pendingTasks.map((task, i) => (
							<div className={classes.checklistItem} key={i}>
								{task.status === 'completed' &&
									<CheckmarkFilled20 style={{ fill: '#24a148', marginRight: '8px' }} />
								}
								{task.status === 'pending' &&
									<div className={classes.bubbleIcons} style={{ background: '#24a148' }}>
										<Unlocked20 style={{ fill: '#fff', margin: '4px' }} />
									</div>
								}
								{task.status === 'soon' &&
									<div className={classes.bubbleIcons}>
										<Locked20 style={{ fill: '#fff', margin: '4px' }} />
									</div>
								}
								<small style={task.status === 'completed' ? {textDecoration: 'line-through', color: '#24a148'} : (task.status === 'pending') ? {color: '#313131'} : {color: '#c5c5c5'}}>{task.task}</small>
							</div>
						))}
					</div>
				</div>
				{card !== undefined &&
					<div className={classes.projectStatusQuickAccess}>
						<small>{myTotalPendingTasks === 0 ? 'Up to date! 🎉 ' : `You have ${myTotalPendingTasks} pending ${myTotalPendingTasks !== 1 ? 'tasks' : 'task'}`}</small>
					</div>
				}
			</div>
		);
	}

	displayContestSummary(state, contest) {
		const { showPhaseFeedback } = this.state;
		const clientReview = contest.clientReview;
		const filteredClientReviewWithText = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.TEXT);
		const filteredClientReviewWithRating = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.RATING);

		return (
			<div className={classes.projectStatus}>
				<div className={classes.projectTitle}>
					<h4>Contest status</h4>
					<CheckmarkFilled20 style={{ fill: '#24a148', margin: '4px' }} />
				</div>
				<div className={classes.projectStatusBox}>
					<div className={classes.projectDetails}>
						<h5>{KEYS.project_status_phase}</h5>
						<Tag type="green" style={{borderRadius: '4px', color: '#fff', backgroundColor: CONTEST.getPhaseColor(state)}}>{PROJECT.stateToString(state)}</Tag>
					</div>
					<div className={classes.projectDetails}>
						<h5>Condition</h5>
						<p>Contest has a winner</p>
					</div>
				</div>
				{(clientReview !== undefined && clientReview.length > 0)
					?	<div className={classes.boxDivider}>
						<div className={classes.projectSubtitle}
							onClick={() => this.setState({ showPhaseFeedback: !showPhaseFeedback})}>
							{showPhaseFeedback
								?	<><h4>Your phase feedback</h4><ChevronUp24 style={{ fill: '#3e3e3e' }} /></>
								:	<><h5>Your phase feedback</h5><ChevronDown24 style={{ fill: '#3e3e3e' }} /></>
							}
						</div>
						{showPhaseFeedback &&
							<div className={classes.projectStatusBox}>
								{filteredClientReviewWithRating.map((review, i) => (
									<div className={classes.clientFeedbackRating} key={i}>
										<small>{review.title}</small>
										<RatingValue rating={review.value} />
									</div>
								))}
								{(filteredClientReviewWithText.length > 0 && filteredClientReviewWithText[0].value !== '-') &&
									<div>
										<small>Comments</small>
										<div className={classes.clientFeedbackComment}>
											{filteredClientReviewWithText[0].value}
										</div>
									</div>
								}
							</div>
						}
					</div>
					:	this.displayPhaseFeedbackRatingVotation(contest)
				}
			</div>
		);
	}

	displayPrototypingPhaseExpectations(contest) {
		const { showBoosters, openUpsellModal, upsellInfo } = this.state;

		const steps = contest && contest.steps;
		const stepsCompleted = PROJECT.getCompletedSteps(steps);
		const allStepsCompleted = stepsCompleted === steps.length ? true : false;

		return (
			allStepsCompleted
				?	this.displayPrototypingSummary(contest)
				:	<div className={classes.flexColumn}>
					<div className={classes.projectStatus}>
						<div className={classes.projectTitle}>
							<h4>What to expect</h4>
							<TooltipIcon tooltipText='Know more' direction='top'>
								<Information16 style={{ cursor: 'pointer', fill: '#0062ff'}} onClick={() => this.openExpectationsDocKB()} />
							</TooltipIcon>
						</div>
						<div className={classes.projectStatusBox} id="expectations-list">
							<div className={classes.tasksContainer}>
								<div className={classes.checklistItem}>
									<NumberSmall_124 style={{color: '#682384'}} />
									<small>Quotation selection</small>
								</div>
								{steps.map((step, i) => (
									<div className={classes.checklistItem} key={i}>
										{i === 0 && <NumberSmall_224 style={{color: '#682384'}} />}
										{i === 1 && <NumberSmall_324 style={{color: '#682384'}} />}
										{i === 2 && <NumberSmall_424 style={{color: '#682384'}} />}
										{i === 3 && <NumberSmall_524 style={{color: '#682384'}} />}
										{i === 4 && <NumberSmall_624 style={{color: '#682384'}} />}
										{i === 5 && <NumberSmall_724 style={{color: '#682384'}} />}
										{i === 6 && <NumberSmall_824 style={{color: '#682384'}} />}
										{i === 7 && <NumberSmall_924 style={{color: '#682384'}} />}
										<small>{step.title}</small>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className={classes.projectStatus} style={{margin: '0'}}>
						<div className={classes.projectSubtitle}
							style={{padding: '0'}}
							onClick={() => this.setState({ showBoosters: !showBoosters})}>
							{showBoosters
								?	<><h4>Select a booster </h4><ChevronUp20 style={{ fill: '#3e3e3e' }} /></>
								:	<>
									<div>
										<h5>Want a booster?</h5>
										<small style={{fontSize: '8pt'}}>Explore options</small>
									</div>
									<ChevronDown20 style={{ fill: '#3e3e3e' }} />
								</>
							}
						</div>
						{showBoosters &&
							<div className={classes.projectStatusBox}>
								<div className={classes.clientFeedbackRating} style={{margin: '0'}}>
									<UpsaleAction
										type={'dark'}
										title={'Crowdfunding program'}
										optionNumber={'1'}
										onClick={() => this.setState({
											openUpsellModal: 'Crowdfunding 12 weeks program',
											upsellInfo: {
												title: 'Crowdfunding 12 weeks program',
												option: {
													name: 'Basic',
													plan: 'Basic',
													price: '2400',
													includeVAT: false,
													sessions: '10 video lessons',
													extra: '12 weeks of valuable information',
													pdf: 'https://miguelmoya.notion.site/Miguel-Moya-Crowdfunding-12-Weeks-Program-c6c4e2ae57aa4e00b656f325eca5b0ca',
													features: [
														'Diagnosis',
														'Defining your goals',
														'Designing your campaign',
														'Pre-Campaign setup'
													],
													outcomes: []
												},
												provider: 'Miguel Moya',
												type: 'crowdfunding',
											}
										})}
									/>
									<UpsaleAction
										type={'light'}
										title={'App development'}
										optionNumber={'2'}
										onClick={() => this.setState({
											openUpsellModal: 'App development',
											upsellInfo: {
												title: 'App development',
												option: {
													name: 'Mobile app',
													plan: 'Basic',
													price: '625',
													includeVAT: false,
													sessions: 'Designed for IoT devices',
													extra: 'Mobile App with UX/UI',
													pdf: '/assets/add-ons/pdfs/app-development-antt.pdf',
													features: [
														'UX/UI',
														'Responsive',
														'Bluetooth connection',
														'Control features'
													],
													outcomes: []
												},
												provider: 'Antt robotics ltd',
												type: 'development',
												pdf: ''
											}
										})}
									/>
								</div>
							</div>
						}
					</div>
					<UpsellModal
						open={!!openUpsellModal}
						title={upsellInfo && upsellInfo.title}
						option={upsellInfo && upsellInfo.option}
						provider={upsellInfo && upsellInfo.provider}
						type={upsellInfo && upsellInfo.type}
						pdf={upsellInfo && upsellInfo.pdf}
						onRequestClose={() => this.setState({ openUpsellModal: null })}
					/>
				</div>
		);
	}

	displayPrototypingSummary(contest) {
		const { showPhaseFeedback } = this.state;
		const state = PROJECT.STATE.PROTOTYPING_CONTEST;
		const clientReview = contest.clientReview;
		const filteredClientReviewWithText = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.TEXT);
		const filteredClientReviewWithRating = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.RATING);

		return (
			<div className={classes.projectStatus}>
				<div className={classes.projectTitle}>
					<h4>Phase status</h4>
					<CheckmarkFilled20 style={{ fill: '#24a148', margin: '4px' }} />
				</div>
				<div className={classes.projectStatusBox}>
					<div className={classes.projectDetails}>
						<h5>{KEYS.project_status_phase}</h5>
						<Tag type="green" style={{borderRadius: '4px', color: '#fff', backgroundColor: CONTEST.getPhaseColor(state)}}>{PROJECT.stateToString(state)}</Tag>
					</div>
					<div className={classes.projectDetails}>
						<h5>Condition</h5>
						<p>All steps are completed</p>
					</div>
				</div>
				{(clientReview !== undefined && clientReview.length > 0)
					?	<div className={classes.boxDivider}>
						<div className={classes.projectSubtitle}
							onClick={() => this.setState({ showPhaseFeedback: !showPhaseFeedback})}>
							{showPhaseFeedback
								?	<><h4>Your phase feedback</h4><ChevronUp24 style={{ fill: '#3e3e3e' }} /></>
								:	<><h5>Your phase feedback</h5><ChevronDown24 style={{ fill: '#3e3e3e' }} /></>
							}
						</div>
						{showPhaseFeedback &&
							<div className={classes.projectStatusBox}>
								{filteredClientReviewWithRating.map((review, i) => (
									<div className={classes.clientFeedbackRating} key={i}>
										<small>{review.title}</small>
										<RatingValue rating={review.value} />
									</div>
								))}
								{(filteredClientReviewWithText.length > 0 && filteredClientReviewWithText[0].value !== '-') &&
									<div>
										<small>Comments</small>
										<div className={classes.clientFeedbackComment}>
											{filteredClientReviewWithText[0].value}
										</div>
									</div>
								}
							</div>
						}
					</div>
					:	this.displayPhaseFeedbackRatingVotation(contest)
				}
			</div>
		);
	}

	openExpectationsDocKB() {
		window.Froged('doc', '628e2ae5f774e6ee763c7799');
	}

	displayPhaseFeedbackRatingVotation(contest) {
		const {
			errorAddingPhaseFeedback, showPhaseFeedbackBoxModal,
			// eslint-disable-next-line no-unused-vars
			sendingPhaseFeedback, showNextContestBoxModal,
			qualityOfProposal, successOfTheWinner,
			processesUnderstanding, teamSupport,
			comments, success
		} = this.state;

		return (
			<>
				{showPhaseFeedbackBoxModal
					? 	<Mutation mutation={ADD_CONTEST_REVIEW}>
						{(addPhaseFeedbackMutation) => (
							<>
								<div className={classnames([classes.projectStatus, classes.shadow, classes.phaseFeedbackRating])}>
									<div className={classes.projectTitle}>
										<div>
											<small>{strings.capitalizeFirstLetter(contest.type)}</small>
											<h4>Give us your feedback</h4>
										</div>
										<TaskView24 style={{ margin: '8px' }} />
									</div>
									{errorAddingPhaseFeedback &&
									<InlineNotification
										className={classes.notification}
										kind="error"
										lowContrast
										hideCloseButton={false}
										title=""
										subtitle={errorAddingPhaseFeedback}
									/>
									}
									<div className={classes.projectStatusBox}>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Quality of proposals</p>
											<Rating
												name={qualityOfProposal}
												value={qualityOfProposal}
												onChange={(e) => this.onSetRatingToQualityOfProposal(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Success of the winner</p>
											<Rating
												name={successOfTheWinner}
												value={successOfTheWinner}
												onChange={(e) => this.onSetRatingToSuccessOfTheWinner(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Processes understanding</p>
											<Rating
												name={processesUnderstanding}
												value={processesUnderstanding}
												onChange={(e) => this.onSetRatingToProcessesUnderstanding(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Team support</p>
											<Rating
												name={teamSupport}
												value={teamSupport}
												onChange={(e) => this.onSetRatingToTeamSupport(e)}
											/>
										</div>
										<div className={classes.marginBottom}>
											<p style={{marginBottom: '8px'}}>Comments</p>
											<TextArea
												className={classes.textInput}
												labelText={'Please specify. Apply if necessary.'}
												type="text"
												cols={30}
												rows={1}
												value={comments}
												onChange={(e) => this.onSetExtraComment(e, comments)}
												id={comments}
											/>
										</div>
										<div>
											{sendingPhaseFeedback || success ? (
												<InlineLoading
													description={success ? 'Feedback sent successfully' : 'Sending feedback ...'}
													status={success ? 'finished' : 'active'}
													style={{ marginLeft: '1rem', width: '200px' }}
												/>
											) : errorAddingPhaseFeedback
												? 	<InlineLoading
													description={'Error validating feedback'}
													status={'error'}
													style={{ marginLeft: '1rem', width: '200px' }}
												/>
												:	(
													<Button
														className={classes.btn}
														onClick={() => this.onAddPhaseFeedbackMutation(contest, addPhaseFeedbackMutation)}
														size='sm'
														kind='primary'>
														Submit feedback
													</Button>
												)}
										</div>
									</div>
								</div>
								<div className={classnames([classes.feedbackBtn, classes.shadow])}
									onClick={() => this.setState({ showPhaseFeedbackBoxModal: !showPhaseFeedbackBoxModal})}
								>
									<Close32 style={{ margin: '4px' }} />
								</div>
							</>
						)}
					</Mutation>
					:	<div className={classes.feedbackBtn}
						onClick={() => this.setState({ showPhaseFeedbackBoxModal: !showPhaseFeedbackBoxModal, showNextContestBoxModal: false})}>
						<TaskView32 style={{ margin: '4px' }} />
					</div>
				}
			</>
		);
	}

	onSetRatingToQualityOfProposal(e) {
		this.setState({ qualityOfProposal: e.target.value });
	}

	onSetRatingToSuccessOfTheWinner(e) {
		this.setState({ successOfTheWinner: e.target.value });
	}

	onSetRatingToProcessesUnderstanding(e) {
		this.setState({ processesUnderstanding: e.target.value });
	}

	onSetRatingToTeamSupport(e) {
		this.setState({ teamSupport: e.target.value });
	}

	onSetExtraComment(e) {
		this.setState({ comments: e.target.value });
	}

	async onAddPhaseFeedbackMutation(contest, addPhaseFeedbackMutation) {
		const { refetchProject } = this.props;
		const {
			qualityOfProposal,
			successOfTheWinner,
			processesUnderstanding,
			teamSupport,
			comments
		} = this.state;

		this.setState({ sendingPhaseFeedback: true });

		let error;
		try {
			const result = await addPhaseFeedbackMutation({
				variables: {
					contestId: contest.id,
					feedback: [
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Quality of proposals',
							value: qualityOfProposal,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Success of the winner',
							value: successOfTheWinner,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Processes understanding',
							value: processesUnderstanding,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Team support',
							value: teamSupport,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.TEXT,
							title: 'Comments',
							value: comments !== '' ? comments : 'N/S',
						}
					]
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error adding phase feedback');
			this.setState({ errorAddingPhaseFeedback: error.toString() });
			setTimeout(() => {
				this.setState({ errorAddingPhaseFeedback: null });
			}, 3000);
		} else {
			console.log('Success adding phase feedback');
			this.setState({ success: true, sendingPhaseFeedback: false });
			setTimeout(() => {
				refetchProject();
			}, 3000);
		}
		this.setState({ sendingPhaseFeedback: false });
	}

	getContestStateNotification(projectId) {
		return (
			<ErrorBoundaryQuery query={GET_PROJECT_UPDATES} variables={{ id: projectId }}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<div>
								<SkeletonText style={{ width: '150px' }} />
								<div className={classes.updatesContentContainer}>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						if (projectId) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									title=""
									lowContrast
									hideCloseButton
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}
					}

					const project = data && data.project;
					const updates = project.updates;
					const sortedUpdates = (updates && updates.length > 0) && updates.slice(-2).sort((a, b) => b.createdAt - a.createdAt);

					return (
						<div>
							<h4>Latest updates</h4>
							<div className={classes.updatesContentContainer}>
								{sortedUpdates.map((update, i) => (
									<div className={classes.lastUpdateBox} key={i}>
										<div className={classes.naming}>
											<Notification20 />
											<p>
												{(update.details !== null && Object.keys(update.details).includes('title')) ? update.details.title : PROJECT.getUpdateString(update.type)}
												{update.details !== null
													?   Object.keys(update.details).includes('title')
														?   `: ${update.details.description}`
														:   `: ${PROJECT.getUpdatesDetails(Object.values(update.details)[0])}`
													:   ''
												}
											</p>
										</div>
										<div className={classes.actions}>
											<small>
												<strong>
													{dates.formattedNewDate(update.createdAt)}
												</strong>
											</small>
										</div>
									</div>
								))}
							</div>
							{updates.length === 0 && (
								<div className={classes.updates}>
									<Information16 />
									<small>{KEYS.project_updates_empty}</small>
								</div>
							)}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayPhaseSummaryBoxes(project, state) {
		if (!state) state = project.state;
		const isPrototypingPhase = state === PROJECT.STATE.PROTOTYPING_CONTEST;
		// if (isPrototypingPhase) return;
		const contest = project && PROJECT.getContestByState(project, state);
		const isEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		const contestRound = (contest && isEnded) && CONTEST.getLastRound(contest);

		const contestProposalsPresented = contest && PROPOSAL.getTotalApprovedAndPublishedProposals(contest.proposals);
		const winningProposalNumber = contest && PROPOSAL.getWinningProposalNumber(contest);
		const contestDuration = contestRound && CONTEST.getContestDaysDuration(contestRound);

		return (
			(isEnded && hasAWinningProposal && !isPrototypingPhase) &&
				<div className={classes.summaryBlock}>
					<h5>Phase summary</h5>
					<div className={classes.experienceBlock}>
						<div>
							<div className={classes.elementsSummaryBox}>
								<Document20 />
								<h3>{contestProposalsPresented}</h3>
							</div>
							<TooltipDefinition tooltipText="Total number of proposals presented on the phase">
								<small>Proposals</small>
							</TooltipDefinition>
						</div>
						<div className={classes.divider}></div>
						<div>
							<div className={classes.elementsSummaryBox}>
								<Time20 />
								<h3>{contestDuration}</h3>
							</div>
							<TooltipDefinition tooltipText="Duration measured in days">
								<small>Days</small>
							</TooltipDefinition>
						</div>
						<div className={classes.divider}></div>
						<div>
							<div className={classes.elementsSummaryBox}>
								<Hashtag20 />
								<h3>{winningProposalNumber}</h3>
							</div>
							<TooltipDefinition tooltipText="Winning proposal number">
								<small>Winning proposal</small>
							</TooltipDefinition>
						</div>
					</div>
				</div>
		);
	}

	displayPrototypingPhaseStatistics(contest) {
		const steps = contest && contest.steps;
		const activeStep = PROJECT.getActiveStep(steps);
		// eslint-disable-next-line no-unused-vars
		const filteredStep = steps.filter(step => step.order === activeStep);
		// const filteredStep = steps.filter(step => step[activeStep]);
		const activeStepName = PROJECT.getActiveStepName(steps);
		const stepsCompleted = PROJECT.getCompletedSteps(steps);
		const stepsLength = steps && steps.length + 1;

		const isEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		return (
			stepsLength > 1 &&
			<div className={classes.summaryBlock}>
				<h5 style={{margin: '0'}}>Statistics</h5>
				<div className={classes.experienceBlock}>
					<div style={{ display: 'flex' }}>
						<ProgressBar16 className={classes.statisticIcon} />
						<div>
							<h6>Active step</h6>
							<div className={classes.statisticContent}>
								<small>{activeStepName}</small>
							</div>
						</div>
					</div>
					<div className={classes.divider}></div>
					<div style={{ display: 'flex' }}>
						<ProgressBarRound16 className={classes.statisticIcon} />
						<div>
							<h6>Steps completed</h6>
							<div className={classes.statisticContent}>
								<small>{stepsCompleted} out of {stepsLength}</small>
							</div>
						</div>
					</div>
					<div className={classes.divider}></div>
					<div style={{ display: 'flex' }}>
						<TaskView16 className={classes.statisticIcon} />
						<div>
							<h6>Needs my attention</h6>
							<div className={classes.statisticContent} style={{ width: '150px'}}>
								<small>
									{(isEnded && !hasAWinningProposal)
										? 'On contest votation'
										: `Stay tuned for new updates on ${activeStepName} step`
									}
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	displayPrototypingActiveSteps(project, state, contest) {
		const { onClickPreviewProposal } = this.props;

		return (
			<ErrorBoundaryQuery query={GET_PHASE_STEPS} variables={{ id: contest.id }}>
				{({ loading, error, data }) => {

					if (loading) {
						return (
							<div className={classes.contestContentBlock}>
								<div>
									<SkeletonText style={{ width: '150px' }} />
									<SkeletonText style={{ width: '100px' }} />
								</div>
								<div className={classes.updatesContentContainer}>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								title=""
								lowContrast
								hideCloseButton
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];
					const result = data[keyName];

					const res = (result && result.steps) || [];
					const steps = res.sort((a, b) => a.order - b.order);

					const contest = project && PROJECT.getContestByState(project, state);
					const contestHasEnded = contest && CONTEST.isContestEnded(contest);
					const hasAWinningProposal = contest && CONTEST.hasWinner(contest);
					const activeStep = (contestHasEnded && hasAWinningProposal) ? PROJECT.getActiveStep(steps) : '-1';
					const stepsLength = steps && steps.length + 1;

					return (
						<div className={classes.contestContentBlock}>
							<div>
								<h4>Track the last mile of your prototype</h4>
								{stepsLength > 1 &&
									<small>{stepsLength} steps included</small>
								}
							</div>
							{stepsLength > 1
								?	<>
									<ContestStep
										project={project}
										state={state}
										title={'Quotation selection'}
										type={'contest'}
										step={'-1'}
										status={'in_progress'} // pending, in_progress, done
										activeStep={activeStep}
										onClickPreviewProposal={onClickPreviewProposal}
										openProposalFeedbackModal={(quotation) => this.setState({ openQuotationFeedbackModal: true, proposalFeedback: quotation })}
									/>
									{steps.map((step, i) => (
										<ContestStep
											key={i}
											project={project}
											state={state}
											title={step.title}
											description={step.description}
											type={'informative'}
											step={step.order+1}
											status={step.status} // pending, in_progress, done
											activeStep={activeStep+1}
											updates={step.updates} // update type information, alert
										/>
									))}
								</>
								:	<div className={classes.experienceBlock}>
									<div className={classes.alignContainer}>
										<Information16 style={{ marginRight: '6px' }} />
										<p>Soon you will see the proper steps defined for your project in this phase.</p>
									</div>
								</div>
							}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayActivePhase(project, state) {
		const { activeTab } = this.state;
		if (!state) state = project.state;
		const contest = project && PROJECT.getContestByState(project, state);
		const contestType = contest && PROJECT.getStateByContestTypeShort(contest.type);

		const isContestOpen = contest && CONTEST.isContestOpen(contest, project);
		const isEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		const contestRound = contest && CONTEST.getLastRound(contest);
		const { card } = contestRound !== undefined && contestRound;

		const activePhase = project.state === state ? true : false;

		const allPhases = PROJECT.getContestStates();
		const currentStateIndex = allPhases.indexOf(state);
		const nextContestToOpen = allPhases[currentStateIndex];

		return (
			(isContestOpen || activePhase || (isEnded && hasAWinningProposal)) &&
				<div className={classes.contestContentBlock}>
					<Tabs className={classes.tabs}
						selected={activeTab}
						scrollIntoView={true}
					>
						{this.displayContestTab(nextContestToOpen, contestRound, card, contestType)}
						<Tab id="proposals" label={<h5>Proposals</h5>}>
							{this.displayContestProposals(project, state)}
						</Tab>
						<Tab id="winner" label={<h5>Winner</h5>}>
							{this.displayWinningContestProposal(project, state)}
						</Tab>
					</Tabs>
				</div>
		);
	}

	displayContestTab(nextContestToOpen, contestRound, card, contestType) {
		const { onClickPreviewProposal, project, refetchProject } = this.props;
		const {
			currentContestStep,
			confirmedContest,
			contestSetUpCompleted,
			sendingConfirmContestRound,
			successConfirmingContest,
			errorConfirmingContestRound
		} = this.state;

		const {
			clientConfirmed,
			requestedChanges,
			specialAttention,
			mainImage,
			secondaryImages
		} = card !== undefined && card;

		const mainImageUrl = mainImage !== undefined && serverAPI.getPreviewUrlByFile(mainImage);

		const currentStep = clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES ? 3 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.PENDING ? 2 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.NO ? 2 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.DRAFT ? 1 : currentContestStep;
		const contestObject = PROJECT.getContestByState(project, nextContestToOpen);
		const setupQuestions = contestObject !== undefined && contestObject.setupQuestions;
		const doesSetupQuestionsHasAnswer = setupQuestions && FormUtils.areAllQuestionsAnswered(setupQuestions);

		const newEndDate = contestRound && new Date(parseInt(contestRound.endDate));
		const endDate = contestRound && dates.substractDays(newEndDate, 1);

		const newStartDate = contestRound && new Date(parseInt(contestRound.startDate));
		const startDate = (contestRound && newStartDate !== undefined) && dates.addDays(newStartDate, 1);

		const contestDuration = contestRound && CONTEST.getContestDaysDuration(contestRound);

		const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const isMobile = (width < 600);

		return (
			<Tab id="contest" label={<h5>Contest</h5>}>
				<div className={classnames([classes.contestContentBlock, classes.contestStepsBlock])}>
					{(currentStep !== 3) &&
						<ProgressIndicator
							currentIndex={(currentStep === currentContestStep) ? currentContestStep : currentStep}
							className={classes.stepsContainer}
							vertical={!isMobile}
						>
							<h5 className={classes.stepTitle}>Follow this steps to open the contest:</h5>
							<ProgressStep label={'Set up'} />
							<ProgressStep label={'Review'} />
							<ProgressStep label={'Confirm'} />
						</ProgressIndicator>
					}
					<div>
						{(currentContestStep === 1 && currentStep === 1) &&
						(clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.DRAFT || card === undefined) &&
							<div>
								<div className={classes.contentTitle}>
									<h4 style={{marginTop: '0'}}>Contest set up</h4>
									{((!doesSetupQuestionsHasAnswer) && (setupQuestions !== undefined && setupQuestions.length > 0)) &&
										<p>In order to be more aligned with your project, you need to fill out these questions to help us make the most accurate contest for your project.</p>
									}
								</div>
								{(contestSetUpCompleted || doesSetupQuestionsHasAnswer)
									?	<InlineNotification
										kind="success"
										lowContrast
										hideCloseButton
										iconDescription="Info"
										subtitle={<span>Thanks for give us more info about your project. Soon you will be able to see the contest info to review it and confirm it.</span>}
										title=""
									/>
									:	<div className={classes.projectStatusBox} style={{ margin: '16px 0'}}>
										<ContestSetupQuestions
											contest={contestObject}
											onRefresh={() => refetchProject()}
											onQuestionsAnswered={() => refetchProject()}
										/>
									</div>
								}
								{(contestSetUpCompleted || doesSetupQuestionsHasAnswer) &&
									<div>
										<h5>Your answers</h5>
										<ViewSetupQuestions
											questions={setupQuestions}
											noAnswersMessage={null}
										/>
									</div>
								}
							</div>
						}
						{clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.NO && requestedChanges.length > 0 &&
							<div>
								<div className={classes.contentTitle}>
									<h4 style={{marginTop: '0'}}>Contest info</h4>
								</div>
								<InlineNotification
									kind="info"
									lowContrast
									hideCloseButton
									iconDescription="Info"
									subtitle={<span>We are updating the contest based on your request.</span>}
									title=""
								/>
								<div className={classes.projectStatusBox}>
									<h5>Your requested changes</h5>
									<div className={classes.clientFeedbackComment}>
										{requestedChanges[0] !== undefined && requestedChanges[0].comment}
									</div>
								</div>
							</div>
						}
						{(currentContestStep === 2 || currentStep === 2) &&
						clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.PENDING &&
							<div>
								<div className={classes.contentTitle}>
									<h4 style={{marginTop: '0'}}>Contest info</h4>
									<p>Find all the info related to the contest that will be opened to experts.</p>
								</div>
								<div className={classes.projectStatusBox}>
									<div className={classes.marginBottom}>
										<div className={classes.flexCenter}>
											<Calendar20 className={classes.iconText} />
											<h5>Contest dates and duration</h5>
										</div>
										<div className={classes.contextContent}>
											{(contestRound !== undefined) ? (
												<p className={classes.contestText}>
													This contest will start on <strong><em>{dates.formattedDate(startDate)}</em></strong> and ends on <strong><em>{dates.formattedDate(endDate)}</em></strong> with an expected duration of <strong><em>{contestDuration} {contestDuration > 1 ? 'days' : 'day'}</em></strong>.
												</p>
											) : (
												<p className={classes.contestText}>
													This contest is not opened yet.
												</p>
											)}
										</div>
									</div>
									{specialAttention &&
										<div className={classes.marginBottom}>
											<div className={classes.flexCenter}>
												<ListDropdown20 className={classes.iconText} />
												<h5>What experts should pay special attention to</h5>
											</div>
											<div className={classes.listSpecialAttention}>
												<ReactMarkdown>
													{specialAttention}
												</ReactMarkdown>
											</div>
										</div>
									}
									{(mainImage || secondaryImages.length > 0) &&
										<div className={classes.marginBottom}>
											<div className={classes.flexCenter}>
												<Folders20 className={classes.iconText} />
												<h5>Useful files</h5>
											</div>
											<div className={classes.imagesContainer}>
												{mainImage &&
													<div className={classes.imgContainer}>
														<ContestCardImage
															className={classes.thumbnailImg}
															src={mainImageUrl}
															onClick={() => onClickPreviewProposal(mainImage)}
														/>
													</div>
												}
												{secondaryImages.length > 0 &&
													secondaryImages.map((img, i) => (
														<div className={classes.imgContainer} key={i}
															onClick={() => this.onClickPreviewProposal(img)}
														>
															<ContestCardImage
																className={classes.thumbnailImg}
																src={serverAPI.getPreviewUrlByFile(img)}
																type={'secondary'}
															/>
														</div>
													))}
											</div>
										</div>
									}
								</div>
								<div className={classes.btnBox}>
									<Button
										className={classes.btn}
										style={{marginRight: '16px'}}
										onClick={() => this.onClickRequestChangeContestCard()}
										kind="secondary"
										disabled={sendingConfirmContestRound || successConfirmingContest}>
											Request change
									</Button>
									{sendingConfirmContestRound || successConfirmingContest ? (
										<InlineLoading
											description={successConfirmingContest ? 'Contest confirmed successfully' : 'Confirming contest...'}
											status={successConfirmingContest ? 'finished' : 'active'}
											style={{ margin: '0 1rem', width: '200px' }}
										/>
									) : errorConfirmingContestRound
										? 	<InlineLoading
											description={'Error confirming contest'}
											status={'error'}
											style={{ margin: '0 1rem', width: '200px' }}
										/>
										:	(
											<Mutation mutation={CONFIRM_CONTEST_CARD}>
												{(confirmContestRoundMutation) => (
													<Button
														className={classes.btn}
														onClick={() => this.onConfirmContestRound(contestObject, confirmContestRoundMutation)}
													>
														Confirm and open the contest
													</Button>
												)}
											</Mutation>
										)}
								</div>
							</div>
						}
						{(currentContestStep === 3 || (currentContestStep === 1 && currentStep === 3)) &&
						clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES &&
							<div>
								<div className={classes.contentTitle}>
									<div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
										<div className={classes.bubbleIcons} style={{ width: '36px', height: '36px', marginRight: '16px' }}>
											<Locked20 style={{ fill: '#24a148', margin: '4px' }} />
										</div>
										<div className={classes.flexColumn}>
											<h4 style={{margin: '0 0 8px 0'}}>This is the official contest for {contestType}</h4>
											<small>This means it can&apos;t be modified as it has been approved.</small>
										</div>
									</div>
									{(confirmedContest !== null && confirmedContest.contestId === contestObject.id) &&
										<InlineNotification
											className={classes.notification}
											kind="success"
											lowContrast
											hideCloseButton={false}
											title=""
											subtitle={'Congrats! 🎉 We have just created a new contest for your project.'}
										/>
									}
								</div>
								<div className={classes.projectStatusBox}>
									{contestRound.card.description &&
										<div className={classes.marginBottom}>
											<div className={classes.flexCenter}>
												<Result20 className={classes.iconText} />
												<h5>General project description</h5>
											</div>
											<div className={classnames([classes.contextContent, classes.colorBlock])}>
												<p>{contestRound.card.description}</p>
											</div>
										</div>
									}
									<div className={classes.marginBottom}>
										<div className={classes.flexCenter}>
											<Calendar20 className={classes.iconText} />
											<h5>Important dates and duration</h5>
										</div>
										<div className={classnames([classes.contextContent, classes.colorBlock])}>
											<p style={{color: '#3e3e3e'}}>
												<strong><em>{dates.formattedDate(startDate)}</em></strong> - <strong><em>{dates.formattedDate(endDate)}</em></strong>
											</p>
											<p>Expected duration: <strong><em>{contestDuration} {contestDuration > 1 ? 'days' : 'day'}</em></strong></p>
										</div>
									</div>
									{specialAttention && (
										<div className={classes.marginBottom}>
											<div className={classes.flexCenter}>
												<ListDropdown20 className={classes.iconText} />
												<h5>What experts should pay special attention to</h5>
											</div>
											<div className={classnames([classes.listSpecialAttention, classes.colorBlock])}>
												<ReactMarkdown>
													{specialAttention}
												</ReactMarkdown>
											</div>
										</div>
									)}
									{(mainImage || secondaryImages.length > 0) &&
										<div className={classes.marginBottom}>
											<div className={classes.flexCenter}>
												<Folders20 className={classes.iconText} />
												<h5>Extra files</h5>
											</div>
											<div className={classes.imagesContainer}>
												{mainImage &&
													<div className={classes.imgContainer}>
														<ContestCardImage
															className={classes.thumbnailImg}
															src={mainImageUrl}
															onClick={() => onClickPreviewProposal(mainImage)}
														/>
													</div>
												}
												{secondaryImages.length > 0 &&
													secondaryImages.map((img, i) => (
														<div className={classes.imgContainer} key={i}
															onClick={() => onClickPreviewProposal(img)}
														>
															<ContestCardImage
																className={classes.thumbnailImg}
																src={serverAPI.getPreviewUrlByFile(img)}
																type={'secondary'}
															/>
														</div>
													))}
											</div>
										</div>
									}
									<div className={classes.marginBottom}>
										<div className={classes.flexCenter}>
											<Certificate20 className={classes.iconText} />
											<h5>Contest card</h5>
										</div>
										<ul>
											<li className={classes.listItem} onClick={() => window.Froged('doc', '6262c984617dab4f9b1e6ba1')}>
												<Link to='#'>
													What is a Contest Card?
												</Link>
											</li>
										</ul>
										<div style={{margin: '26px 16px'}}>
											<Button
												className={classnames([classes.btn, classes.aBtn])}
												href={this.onGetContestCardPdfUrl(contestObject)}
												kind='secondary'
												size='sm'
												target="_blank" rel="noopener noreferrer"
											>
												Preview contest as a contest card
											</Button>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</Tab>
		);
	}

	onGetContestCardPdfUrl(contest) {
		return serverAPI.getContestCardPdfUrl(contest);
	}

	async onConfirmContestRound(contest, confirmContestRoundMutation) {
		const { refetchProject } = this.props;
		const contestRound = contest && CONTEST.getLastRound(contest);

		this.setState({ sendingConfirmContestRound: true });

		let error;
		try {
			const result = await confirmContestRoundMutation({
				variables: {
					contestId: contest.id,
					roundId: contestRound.id,
					confirmation: {
						approved: true
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error confirming contest');
			this.setState({ errorConfirmingContestRound: error.toString() });
			setTimeout(() => {
				this.setState({ errorConfirmingContestRound: null });
			}, 3000);
		} else {
			console.log('Success confirming contest');
			this.setState({ successConfirmingContest: true, sendingConfirmContestRound: false});
			setTimeout(() => {
				refetchProject();
				this.setState({ confirmedContest: {confirmed: true, contestId: contest.id} });
			}, 1500);
		}

		this.setState({ confirmedContest: null, successConfirmingContest: true });
	}

	displayProjectSetUpPhase() {
		return (
			<>
				<p>For every phase, there is a contest between our experts. Here is an outline of your role during each one:</p>
				<div className={classes.indicatorContainer}>
					<ProgressIndicator currentIndex={4} vertical>
						<ProgressStep
							className={classes.stepItem}
							label={<h5 className={classes.stepTitle}>Step 1: Review and accept contest card</h5>}
							description="Before opening a contest, we present you a contest card where you will need to acept it in order to start the contest with our experts."
							secondaryLabel={<p>Before opening a contest, we present you a contest card where you will need to acept it in order to start the contest with our experts.</p>}
						/>
						<ProgressStep
							className={classes.stepItem}
							label={<h5 className={classes.stepTitle}>Step 2: Receive proposals</h5>}
							description="Once the contest is opened, the expert will join and will start sending proposals for your idea."
							secondaryLabel={<p>Once the contest is opened, the expert will join and will start sending proposals for your idea.</p>}
						/>
						<ProgressStep
							className={classes.stepItem}
							label={<h5 className={classes.stepTitle}>Step 3: Rank and give feedback</h5>}
							description="When you have several proposals and the contest is close to be closed, you will need to review them and give them a feedback to let us know if the idea covers your need."
							secondaryLabel={<p>When you have several proposals and the contest is close to be closed, you will need to review them and give them a feedback to let us know if the idea covers your need.</p>}
						/>
						<ProgressStep
							className={classes.stepItem}
							label={<h5 className={classes.stepTitle}>Step 4: Select a winner and start a new contest</h5>}
							description="Finally, you need to choose only a winner to move foward and start the next contest."
							secondaryLabel={<p>Finally, you need to choose only a winner to move foward and start the next contest.</p>}
						/>
					</ProgressIndicator>
				</div>
			</>
		);
	}

	displayClosedPhase() {
		return (
			<>
				{/* eslint-disable-next-line */}
				<p>Congrats! 🎉 You have now finished your prototyping process.</p>
				<p>You can review all the files, proposals and statistics by clicking on each phases states, or by reviewing them <Link to={'/files'}>here</Link>.</p>
				<p>You will soon receive your prototyping at your place.</p>
			</>
		);
	}

	async onClickConfirmContestCard(project, contestRound, confirmContestCardMutation, confirmed) {
		const { refetchProject } = this.props;
		const contest = project && PROJECT.getContestByState(project, project.state);

		let error;
		try {
			const result = await confirmContestCardMutation({
				variables: {
					contestId: contest.id,
					roundId: contestRound.id,
					confirmation: {
						approved: confirmed,
						...(confirmed === false && {comment: '-'})
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error confirming contest card');
		} else {
			console.log('Success confirming contest card');
			await refetchProject();
			if (confirmed === false) {
				this.setState({ requestChangeContestCard: true });
			}
		}

		this.setState({ openContestCard: false });
	}

	async onClickRequestChangeContestCard() {
		this.setState({ requestChangeContestCard: true });
	}

	onCloseContestCardModal = () => {
		this.setState({ openContestCard: false });
	};

	displayStateDescription(state) {
		switch (state) {
			case PROJECT.STATE.PROJECT_SET_UP:
				return KEYS.phase_contest_details_project_set_up_description;
			case PROJECT.STATE.SKETCH_CONTEST:
				return KEYS.phase_contest_details_sketch_description;
			case PROJECT.STATE.MODEL_CONTEST:
				return KEYS.phase_contest_details_model_description;
			case PROJECT.STATE.ELECTRICAL_CONTEST:
				return KEYS.phase_contest_details_electrical_description;
			case PROJECT.STATE.MECHANICAL_CONTEST:
				return KEYS.phase_contest_details_mechanical_description;
			case PROJECT.STATE.PROTOTYPING_CONTEST:
				return KEYS.phase_contest_details_prototyping_description;
			default:
				return '';
		}
	}

	getPhaseTitle(project, state, activeState, switcher) {
		const activePhase = project.state === state ? true : false;
		const selectedContest = project && PROJECT.getContestByState(project, state);
		const selectedContestHasAWinningProposal = selectedContest && PROPOSAL.hasAWinningProposal(selectedContest);
		return (
			<>
				<div className={classes.contributorsBubble} style={activePhase || selectedContestHasAWinningProposal ? {backgroundColor: CONTEST.getPhaseColor(state)} : {}}>
					{switcher
						?	<ContestIcon state={state} size={20} active={false} />
						:	(state === PROJECT.STATE.MODEL_CONTEST)
							?	'3D'
							:	state.substring(0, 2).toUpperCase()
					}
				</div>
				<h3 style={{ margin: '0 8px' }}>{state ? PROJECT.stateToString(state) : PROJECT.stateToString(activeState)}</h3>
			</>
		);
	}

	onSubmitWinningProposalActions(contest) {
		const { refetchProject } = this.props;

		this.setState({
			selectWinningProposalConfirmationModal: false,
			winnerSelectedConfirmation: {contestType: contest.type},
		});
		refetchProject();
		confetti({
			particleCount: 150,
			spread: 150,
			startVelocity: 35
		});

		setTimeout(() => {
			this.setState({
				showPhaseFeedbackBoxModal: true
			});
		}, 5000);
	}

	render() {
		const {
			project,
			state,
			activeState,
			refetchProject,
			onClickPreviewProposal,
			userData,
			switcher
		} = this.props;

		const {
			openContestCard,
			contestCard,
			openProposalFeedbackModal,
			openQuotationFeedbackModal,
			proposalFeedback,
			selectWinningProposalConfirmationModal,
			selectWinningProposalData,
			requestChangeContestCard,
			selectedWinningProposalId,
		} = this.state;

		const contest = project && PROJECT.getContestByState(project, state);
		const activePhase = project.state === state ? true : false;
		const isEnded = contest && CONTEST.isContestEnded(contest);
		const phaseAdvancement = contest && CONTEST.getPhaseAdvancement(contest, project);
		const isPrototypingPhase = state === PROJECT.STATE.PROTOTYPING_CONTEST;

		return (
			<>
				<div className={classes.contentBlock}>
					<div className={classes.twoBlocks}>
						<div className={classes.contentTitle}>
							<div className={classes.contentContent}>
								<div className={classes.stepPhaseBox}>
									{state !== PROJECT.STATE.CLOSED
										?	this.getPhaseTitle(project, state, activeState, switcher)
										:	<>
											<Bot20 />
											<h3 style={{ margin: '0 8px' }}>Happy prototyping</h3>
										</>
									}
								</div>
							</div>
							<p>{this.displayStateDescription(state)}</p>
							{(activePhase && (state !== PROJECT.STATE.CLOSED && state !== PROJECT.STATE.PROJECT_SET_UP) && !isEnded && phaseAdvancement > 0 && !isPrototypingPhase)
								? this.getContestStateNotification(project.id)
								// : (isPrototypingPhase)
								// 	? this.displayPrototypingPhaseStatistics(contest)
								: (isEnded && phaseAdvancement === 100) &&
										this.displayPhaseSummaryBoxes(project, state)
							}
						</div>
						{state !== PROJECT.STATE.PROJECT_SET_UP &&
							this.displayContestStatusInfo(contest, project, state)
						}
					</div>
					{state === PROJECT.STATE.PROJECT_SET_UP
						?	this.displayProjectSetUpPhase()
						:	(state === PROJECT.STATE.CLOSED)
							?	this.displayClosedPhase()
							:	(isPrototypingPhase)
								?	this.displayPrototypingActiveSteps(project, state, contest)
								:	this.displayActivePhase(project, state)
					}
				</div>
				{openContestCard &&
					<Mutation mutation={CONFIRM_CONTEST_CARD}>
						{(confirmContestCardMutation) => (
							<ContestCardModal
								open={openContestCard}
								contestCard={contestCard}
								project={project}
								contest={contest}
								onRequestSubmit={() => this.onClickConfirmContestCard(project, contestCard.contest, confirmContestCardMutation, true)}
								onSecondarySubmit={() => this.onClickRequestChangeContestCard()}
								onRequestClose={this.onCloseContestCardModal}
							/>
						)}
					</Mutation>
				}
				{openProposalFeedbackModal && proposalFeedback && !proposalFeedback.feedback && (
					<EditFeedbackModal
						open={openProposalFeedbackModal}
						userData={userData}
						onClickPreviewProposal={onClickPreviewProposal}
						onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
						onSecondarySubmit={() => this.setState({ openProposalFeedbackModal: false })}
						onRequestSubmit={() => this.setState({ openProposalFeedbackModal: false }, () => { refetchProject();})}
						proposal={proposalFeedback}
					/>
				)}
				{(openQuotationFeedbackModal && proposalFeedback) && (
					<QuotationVotationModal
						open={openQuotationFeedbackModal}
						userData={userData}
						onClickPreviewProposal={onClickPreviewProposal}
						onRequestClose={() => this.setState({ openQuotationFeedbackModal: false })}
						onSecondarySubmit={() => this.setState({ openQuotationFeedbackModal: false })}
						onRequestSubmit={(proposalId) => this.setState({ openQuotationFeedbackModal: false, selectWinningProposalConfirmationModal: true, selectedWinningProposalId: proposalId })}
						proposal={proposalFeedback}
					/>
				)}
				{selectWinningProposalConfirmationModal && (
					<SelectWinningProposalConfirmationModal
						open={selectWinningProposalConfirmationModal}
						proposal={selectWinningProposalData}
						proposalId={selectedWinningProposalId}
						onRequestSubmit={() => this.onSubmitWinningProposalActions(contest)}
						onSecondarySubmit={this.onCloseSelectWinningProposalConfirmationModal}
						onRequestClose={this.onCloseSelectWinningProposalConfirmationModal}
					/>
				)}
				{requestChangeContestCard && (
					<RequestChangeContestCardModal
						open={requestChangeContestCard}
						contest={contest}
						onRequestSubmit={() => this.setState({ requestChangeContestCard: false, openContestCard: false }, () => { refetchProject();})}
						onSecondarySubmit={() => this.setState({ requestChangeContestCard: false })}
						onRequestClose={() => this.setState({ requestChangeContestCard: false })}
					/>
				)}
			</>
		);
	}
}

PhaseContestDetails.propTypes = {
	state: PropTypes.string.isRequired,
	activeState: PropTypes.string.isRequired,
	project: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	switcher: PropTypes.bool,
	onClickPreviewProposal: PropTypes.func,
	refetchProject: PropTypes.func,
};

PhaseContestDetails.defaultProps = {
	switcher: false,
	onClickPreviewProposal() {},
	refetchProject() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(PhaseContestDetails));
