import React, { Component, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Content } from 'carbon-components-react';

import Config from '../../config';
import ForceReload from '../../components/route/force-reload';
import Navbar from '../../components/navbar';

import HomePage from './home';
import ProjectsPage from './projects';
import GetBriefingPage from './projects/project-view/get-briefing';
import GetSketchPage from './projects/project-view/get-sketch';
import ProjectViewPage from './projects/project-view';
import ProjectPage from '../shared/project/project-view';
import ContestsPage from './contests';
import ContestViewPage from './contests/contest-view-page';
import HowItWorksPage from './how-it-works';
import AccountPage from '../shared/account/account';
import Payments from './internal-pages/payments';
import RolesAndResponsibilities from './internal-pages/roles-and-responsibilities';
import PersonalInformation from './internal-pages/personal-information';
import Notifications from '../shared/account/notifications';
import Security from '../shared/account/security';
import ReferralPage from '../shared/referral/referral';
import ProjectValidationPage from './project-validation/project-validation';
import NotFoundPage from '../shared/errors/not-found';

import classes from '../shared/auth/pages.module.scss';

class ExpertsPage extends Component {
	render() {
		return (
			<Fragment>
				<Navbar />
				<Content className={classes.mainContent}>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/how-it-works/experts" element={<HowItWorksPage />} />
						<Route path="/roles-and-responsibilities" element={<RolesAndResponsibilities />} />
						<Route path="/explore" element={<ProjectsPage />} />
						<Route path="/project/:id" element={<ProjectViewPage />} />
						<Route path="/project/new/:id" element={<ProjectPage />} />
						<Route path="/project/:id/getBriefing" element={<GetBriefingPage />} />
						<Route path="/project/:id/getSketch" element={<GetSketchPage />} />
						<Route path="/contests" element={<ContestsPage />} />
						<Route path="/contest/:id" element={<ContestViewPage />} />
						<Route path="/community" element={<ForceReload path={Config.lastbasic.community.host} />} />
						<Route path="/account/settings" element={<AccountPage />} />
						<Route path="/account/payments" element={<Payments />} />
						<Route path="/account/personal-information" element={<PersonalInformation />} />
						<Route path="/account/notifications" element={<Notifications />} />
						<Route path="/account/security" element={<Security />} />
						<Route path="/invites" element={<ReferralPage />} />
						<Route path="/validate" element={<ProjectValidationPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</Content>
			</Fragment>
		);
	}
}

export default ExpertsPage;
