import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	Modal,
	Button,
	InlineLoading,
	InlineNotification,
} from 'carbon-components-react';

import { UPDATE_MY_PROJECT } from '../../providers/mutations';

import classes from './modals.module.scss';

class EraseProjectModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			sendingProjectErase: false,
			success: false,
			errorErasingProject: null,
		};
	}

	async onEraseProject(e, projectId, updateMyProjectMutation) {
		const { onRequestSubmit } = this.props;

		e.preventDefault();
		let error;

		try {
			const result = await updateMyProjectMutation({
				variables: {
					projectId: projectId,
					fields: {
						deleted: true
					},
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}
		if (error) {
			this.setState({ errorErasingProject: error.toString() });
			setTimeout(() => {
				this.setState({ errorErasingProject: null });
			}, 3000);
		} else {
			this.setState({ success: true, sendingProjectErase: false });
			setTimeout(() => {
				this.setState({ success: false });
				onRequestSubmit();
			}, 2000);
		}
		this.setState({ sendingProjectErase: false });
	}

	render() {
		const { open, project, onSecondarySubmit, onRequestClose, ...rest } = this.props;
		const { sendingProjectErase, success, errorErasingProject } = this.state;

		const projectName = project !== null ? project.name : 'Project xx';

		return (
			<Mutation mutation={UPDATE_MY_PROJECT}>
				{(updateMyProjectMutation, { error }) => {
					return (
						<Modal
							passiveModal={true}
							open={open}
							modalHeading="Delete your project"
							modalLabel={projectName}
							onRequestClose={onRequestClose}
							{...rest}
						>
							<div className={classes.modalContent}>
								{error &&
									<InlineNotification
										className={classes.notification}
										kind="error"
										lowContrast
										title=""
										subtitle={(error.graphQLErrors && error.graphQLErrors.length)
											? error.graphQLErrors[0].message
											: (error.networkError)
												? error.networkError.message
												: error.message}
									/>
								}
								<h4>Are you sure you want to delete &quot;{projectName}&quot;?</h4>
								<InlineNotification
									kind="warning"
									lowContrast
									iconDescription="Warning"
									subtitle={'This action is permanent'}
									title=""
								/>
								<div className={classes.btnBox}>
									<Button
										type="submit"
										className={classes.btn}
										onClick={onSecondarySubmit}
										kind="secondary"
										disabled={sendingProjectErase || success}>
											Cancel
									</Button>
									{sendingProjectErase || success ? (
										<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={success ? 'Project deleted successfully' : 'Deleting project...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorErasingProject
										? 	<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={'Error deleting project'}
											status={'error'}
										/>
										:	(
											<Button
												className={classes.btn}
												disabled={error}
												onClick={(e) => this.onEraseProject(e, project.id, updateMyProjectMutation)}>
													Delete my project
											</Button>
										)}
								</div>
							</div>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

EraseProjectModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

EraseProjectModal.defaultProps = {
	open: false,
	project: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
};

export default EraseProjectModal;
