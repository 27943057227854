import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';

import {
	Modal,
	Button,
	Select,
	SelectItemGroup,
	SelectItem,
	TextInput,
	InlineLoading,
	InlineNotification
} from 'carbon-components-react';

import { PROJECT, COUNTRIES } from '../../constants';
import { strings } from '../../utils';
import { UPDATE_MAKER_INFO } from '../../providers/mutations';

import KEYS from '../../glossary';

import classes from './modals.module.scss';

class ClientDeliveryAddressSetUpModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			countryResidence: null,
			selectedCountry: null,
			address: null,
			city: null,
			postalCode: null,
			phoneNumber: null,
			editAddressSection: false,
			updatingMakerDeliveryAddressInfo: false,
			updatingMakerDeliveryAddressInfoSuccessfully: false,
		};
	}

	onChangeAddress(e) {
		this.setState({ address: e.target.value });
	}

	onChangeCity(e) {
		this.setState({ city: e.target.value });
	}

	onChangeZipCode(e) {
		this.setState({ postalCode: e.target.value });
	}

	onChangePhoneContact(e) {
		this.setState({ phoneNumber: e.target.value });
	}

	onUpdateMakerInfo = async (e, updateMakerInformation) => {
		const { userData: { user }, onRequestSubmit } = this.props;
		const { maker } = user;
		const { selectedCountry, address, city, postalCode, phoneNumber } = this.state;

		e.preventDefault();
		this.setState({ updatingMakerDeliveryAddressInfo: true });

		const addressInfo = {
			country: selectedCountry !== null ? selectedCountry : maker.countryResidence,
			street: address !== null ? address : maker.deliveryAddress.street,
			city: city !== null ? city : maker.deliveryAddress.city,
			postalCode: postalCode !== null ? parseInt(postalCode) : maker.deliveryAddress.postalCode,
			phoneNumber: phoneNumber !== null ? phoneNumber.toString() : maker.deliveryAddress.phoneNumber,
		};

		let error;
		try {
			const result = await updateMakerInformation({
				variables: {
					information: {
						deliveryAddress: addressInfo,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating maker info');
			this.setState({
				selectedCountry: null,
				address: null,
				city: null,
				postalCode: null,
				phoneNumber: null,
				makerInfoEditingError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					makerInfoEditingError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating maker info');
			this.setState({ updatingMakerDeliveryAddressInfoSuccessfully: true, updatingMakerDeliveryAddressInfo: false });
			setTimeout(() => {
				this.setState({
					updatingMakerDeliveryAddressInfoSuccessfully: false,
					editAddressSection: false,
				});
				onRequestSubmit();
			}, 1000);
		}
		this.setState({ updatingMakerDeliveryAddressInfo: false });
	};

	render() {
		const { open, project, onRequestClose, onRequestSubmit, userData: {user} } = this.props;
		const { maker } = user;
		const {
			editAddressSection,
			address,
			city,
			postalCode,
			phoneNumber,
			updatingMakerDeliveryAddressInfo,
			updatingMakerDeliveryAddressInfoSuccessfully,
			makerInfoEditingError,
		} = this.state;

		return (
			<Modal
				open={!!open}
				passiveModal={true}
				modalLabel={PROJECT.stateToString(project.state)}
				modalHeading={'Delivery address set up'}
				onRequestSubmit={onRequestSubmit}
				onRequestClose={onRequestClose}
				aria-label="Delivery address set up modal"
			>
				<Mutation mutation={UPDATE_MAKER_INFO}>
					{(updateMakerInformation, { error }) => (
						<>
							{error && (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton={false}
									title="Error"
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							)}
							{makerInfoEditingError &&
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton={false}
									title="Error"
									subtitle={makerInfoEditingError}
								/>
							}
							<div className={classes.modalContent}>
								<p>In order to speed up your project, we need you to give us an address to deliver the prototype once this phase ends and you received as soon as possible.</p>
								<div className={classes.contestContent}>
									<h4 className={classes.secondaryTitle}>Your current delivery address</h4>
									<div className={classes.infoElements}>
										<h5>Country</h5>
										{editAddressSection
											?	<Select
												id="select-country"
												hideLabel={true}
												defaultValue={COUNTRIES.getEntryByCountry(maker.countryResidence).name}
												onChange={(e) => this.onChangeCountry(e, COUNTRIES)}
											>
												<SelectItemGroup label="Choose a country">
													{COUNTRIES.getEntries().map((c, i) => (
														<SelectItem text={c.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} value={i} key={i} />
													))}
												</SelectItemGroup>
											</Select>
											:	<p>{strings.capitalizeFirstLetter(maker.countryResidence)}</p>
										}
									</div>
									<div className={classes.infoElements}>
										<h5>Address</h5>
										{editAddressSection
											?	<TextInput
												id="address-input"
												className={classes.textInput}
												type="text"
												value={address}
												onChange={(e) => this.onChangeAddress(e)}
												required
											/>
											:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.street : 'N/A'}</p>
										}
									</div>
									<div className={classes.infoElements}>
										<h5>City</h5>
										{editAddressSection
											?	<TextInput
												id="city-input"
												className={classes.textInput}
												type="text"
												value={city}
												onChange={(e) => this.onChangeCity(e)}
												required
											/>
											:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.city : 'N/A'}</p>
										}
									</div>
									<div className={classes.infoElements}>
										<h5>Zip code</h5>
										{editAddressSection
											?	<TextInput
												id="zip-code-input"
												className={classes.textInput}
												type="number"
												value={postalCode}
												onChange={(e) => this.onChangeZipCode(e)}
												required
											/>
											:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.postalCode : 'N/A'}</p>
										}
									</div>
									<div className={classes.infoElements}>
										<h5>Contact phone number</h5>
										{editAddressSection
											?	<TextInput
												id="phone-contact-input"
												className={classes.textInput}
												type="number"
												value={phoneNumber}
												onChange={(e) => this.onChangePhoneContact(e)}
												required
											/>
											:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.phoneNumber : 'N/A'}</p>
										}
									</div>
									<div className={classes.btnContainer}>
										{editAddressSection
											?	<>
												<Button
													className={classes.btn}
													style={{ marginRight: '4px'}}
													onClick={() => this.setState({ editAddressSection: false })}
													disabled={updatingMakerDeliveryAddressInfo || updatingMakerDeliveryAddressInfoSuccessfully}
													size='sm'
													kind='ghost'>
													{KEYS.cancel}
												</Button>
												{updatingMakerDeliveryAddressInfo || updatingMakerDeliveryAddressInfoSuccessfully ? (
													<InlineLoading
														description={updatingMakerDeliveryAddressInfoSuccessfully ? 'Info updated successfully' : 'Updating info...'}
														status={updatingMakerDeliveryAddressInfoSuccessfully ? 'finished' : 'active'}
														style={{ marginLeft: '1rem', width: '200px' }}
													/>
												) : (
													<Button
														className={classes.btn}
														onClick={(e) => this.onUpdateMakerInfo(e, updateMakerInformation)}
														size='sm'
														kind='primary'>
														{KEYS.save}
													</Button>
												)}
											</>
											:	<Button
												className={classes.btn}
												onClick={() => this.setState({ editAddressSection: true })}
												size='sm'
												kind='secondary'>
												Change address
											</Button>
										}
									</div>
								</div>
							</div>
						</>
					)}
				</Mutation>
			</Modal>
		);
	}
}

ClientDeliveryAddressSetUpModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	userData: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

ClientDeliveryAddressSetUpModal.defaultProps = {
	open: false,
	project: {},
	userData: {},
	onRequestSubmit() {},
	onRequestClose() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ClientDeliveryAddressSetUpModal);
