import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import {
	Button,
	InlineNotification,
	FileUploaderItem,
	ProgressIndicator,
	ProgressStep,
	InlineLoading,
	OverflowMenu,
	OverflowMenuItem,
	Breadcrumb,
	BreadcrumbItem,
} from 'carbon-components-react';
import {
	Download20,
	Document20,
	Chat16,
	NextOutline16
} from '@carbon/icons-react';

import ContestSetupQuestions from '../../../../components/project/setup-questions/contest-setup-questions';
import ContestViewSetupQuestions from '../../../../components/project/setup-questions/contest-view-setup-questions';
import PhaseViewSetupQuestions from '../../../../components/project/setup-questions/phase-view-setup-questions';
import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import PhaseSetupQuestions from '../../../../components/project/setup-questions/phase-setup-questions';
import RawMaterialImage from '../../../../components/project/raw-material-image';
import withRouter from '../../../../components/route/with-router';

import { CONTEST, PROJECT } from '../../../../constants';
import { ADD_RAW_MATERIAL, REMOVE_RAW_MATERIAL } from '../../../../providers/mutations';
import { fileTools, serverAPI } from '../../../../utils';
import KEYS from '../../../../glossary';

import classes from './project-onboarding.module.scss';

const STEPS = {
	STEP_1: 'project_briefing',
	STEP_2: 'raw_materials',
	STEP_3: 'more_details',
	STEP_4: 'confirmation',
};


class ProjectOnboarding extends Component {
	constructor (props) {
		super(props);

		this.inputFileRef = React.createRef();

		const { project } = props;
		const contestObject = PROJECT.getContestByState(project, PROJECT.STATE.SKETCH_CONTEST);
		const setupQuestions = contestObject !== undefined && contestObject.setupQuestions;

		this.state = {
			currentStep: 0,
			uploadingRawMaterialFile: null,
			previewFile: null,
			newRawMaterialFiles: [],
			uploadSuccess: false,
			uploadError: false,
			errorRemovingRawMaterial: null,
			removingRawMaterial: null,
			selectedOptions: [],
			questions: setupQuestions || [],
			textComment: '',
			isOtherOptionSelected: false,
			onboardingQuestionsDone: false,
			sketchSetupQuestionsDone: false,
		};
	}

	componentDidMount() {
		this.setInitialStepsParams();
	}

	setInitialStepsParams() {
		const { location, navigate, project } = this.props;

		const searchParams = location.search;
		const queryString = searchParams.substring(searchParams.lastIndexOf('step='));
		const stepIndex = queryString.includes(STEPS.STEP_3) ? 2 : queryString.includes(STEPS.STEP_2) ? 1 : 0;
		const stepString = stepIndex === 2 ? STEPS.STEP_3 : (stepIndex === 1) ? STEPS.STEP_2 : STEPS.STEP_1;

		this.setState({ currentStep: stepIndex });

		return navigate({
			search: '?' + new URLSearchParams({ phase: project.state, step: stepString }).toString()
		});
	}

	onClickUploadRawMaterialFile = async (e, project, uploadRawMaterialFileMutation) => {
		const { target: { validity, files: [file] } } = e;

		if (!validity.valid) return;

		this.setState({
			uploadingRawMaterialFile: {
				filename: file.name,
				size: file.size,
			}
		}, async () => {
			const result = await uploadRawMaterialFileMutation({
				variables: { projectId: project.id, name: file.name, file }
			});

			if (result && result.data && result.data.addRawMaterial) {
				const { newRawMaterialFiles } = this.state;
				newRawMaterialFiles.push({
					id: result.data.addRawMaterial.id,
					name: result.data.addRawMaterial.name,
				});
				this.setState({
					uploadSuccess: true,
					newRawMaterialFiles: newRawMaterialFiles,
				});
			} else {
				this.setState({
					uploadError: true,
				});
			}
			this.setState({
				uploadingRawMaterialFile: null
			});
			setTimeout(() => {
				this.setState({
					uploadSuccess: false,
					uploadError: false
				});
			}, 5000);
		});
	};

	onOpenPreviewFile = (file) => {
		this.setState({ previewFile: file });
	};

	onClosePreviewFile = () => {
		this.setState({ previewFile: null });
	};

	async openDeleteFile(e, material, project, removeRawMaterialMutation) {
		const { navigate } = this.props;

		e.preventDefault();
		let error;

		this.setState({
			removingRawMaterial: {
				fileId: material.id,
				remove: true
			}
		});

		try {
			const result = await removeRawMaterialMutation({
				variables: {
					projectId: project.id,
					fileId: material.id,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error deleting file');
			this.setState({ errorRemovingRawMaterial:
				{error: error.toString(), fileId: material.id }
			});
			setTimeout(() => {
				this.setState({ errorRemovingRawMaterial: null });
			}, 3000);
		} else {
			console.log('Success deleting file');
			window.location.reload();
			navigate({
				search: '?' + new URLSearchParams({ phase: project.state, step: STEPS.STEP_2 }).toString()
			});
			this.setState({ removingRawMaterial: null });
		}
		this.setState({ removingRawMaterial: null });
	}

	downloadFile(file) {
		return serverAPI.getDownloadUrlByFile(file);
	}

	nextStep() {
		const { project, navigate } = this.props;
		const { currentStep } = this.state;

		const newStep = currentStep + 1;
		const stepString = newStep === 0 ? STEPS.STEP_1 : (newStep === 1) ? STEPS.STEP_2 : (newStep === 2) ? STEPS.STEP_3 : STEPS.STEP_4;

		this.setState({ currentStep: newStep });

		return navigate({
			search: '?' + new URLSearchParams({ phase: project.state, step: stepString }).toString()
		});
	}

	previousStep() {
		const { project, navigate } = this.props;
		const { currentStep } = this.state;

		const newStep = currentStep - 1;
		const stepString = newStep === 0 ? STEPS.STEP_1 : (newStep === 1) ? STEPS.STEP_2 : (newStep === 2) ? STEPS.STEP_3 : STEPS.STEP_4;

		this.setState({ currentStep: newStep });

		return navigate({
			search: '?' + new URLSearchParams({ phase: project.state, step: stepString }).toString()
		});
	}

	render() {
		const { project, projectOnboardingConfirmed, userData: { token } } = this.props;
		const {
			currentStep,
			uploadingRawMaterialFile,
			previewFile,
			newRawMaterialFiles,
			uploadSuccess,
			uploadError,
			errorRemovingRawMaterial,
			removingRawMaterial,
			onboardingQuestionsDone,
			sketchSetupQuestionsDone
		} = this.state;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};
		const { previsualizeUrl } = fileTools.getUrls(briefing.url);

		const rawMaterial = {
			name: 'Client Sketch',
			url: serverAPI.getPublicSketchUrl(project.id, token.accessToken),
		};

		let { rawMaterials } = project;
		rawMaterials = [...rawMaterials, ...newRawMaterialFiles];
		rawMaterials = rawMaterials.map(rawMaterial => {
			return {
				id: rawMaterial.id,
				name: rawMaterial.name,
				fileType: rawMaterial.mimeType,
				url: serverAPI.getThumbnailUrlByFile(rawMaterial),
			};
		});

		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const isMobile = (width < 600);

		const contestObject = PROJECT.getContestByState(project, PROJECT.STATE.SKETCH_CONTEST);

		return (
			<Fragment>
				<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
					<BreadcrumbItem>
						<Link to="/projects">Projects</Link>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage href="#">
						{project.name}
					</BreadcrumbItem>
				</Breadcrumb>
				<h1 className={classes.title}>{project.name}</h1>
				<div className={classes.contentTitle}>
					<div className={classes.contentContent}>
						<div className={classes.stepPhaseBox}>
							<h3>Project onboarding</h3>
						</div>
					</div>
				</div>
				<div>
					<p>{KEYS.project_onboarding_description}</p>
					<ProgressIndicator currentIndex={currentStep} spaceEqually className={classes.stepsContainer} vertical={isMobile}>
						<ProgressStep
							label={KEYS.project_onboarding_step_1}
							onClick={() => this.setState({ currentStep: 0 })}
						/>
						<ProgressStep
							label={KEYS.project_onboarding_step_2}
							onClick={() => this.setState({ currentStep: 1 })}
						/>
						<ProgressStep
							label={KEYS.project_onboarding_step_3}
							onClick={() => this.setState({ currentStep: 2 })}
						/>
						<ProgressStep
							label={KEYS.project_onboarding_step_4}
							onClick={() => this.setState({ currentStep: 3 })}
						/>
					</ProgressIndicator>
					{currentStep === 0 && (
						<div className={classnames([classes.contentBlock, classes.contentSpace])}>
							<div className={classes.contentTitle}>
								<h4>{KEYS.project_onboarding_step_1_title}</h4>
								<p>{KEYS.project_onboarding_step_1_description}</p>
							</div>
							<div className={classes.elementBox}>
								<div className={classes.naming}>
									<Document20 />
									<p>{KEYS.project_onboarding_step_1_title}</p>
								</div>
								<div className={classes.actions}>
									<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>
										{KEYS.open_file}
									</span>
									<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer">
										<Download20 />
									</a>
								</div>
							</div>
							<div className={classnames([classes.btnContainer, classes.unique])}>
								<Button
									className={classes.btn}
									onClick={() => this.nextStep()}
								>
									{KEYS.next}
								</Button>
							</div>
						</div>
					)}
					{currentStep === 1 && (
						<div className={classnames([classes.contentBlock, classes.contentSpace, classes.twoBtns])}>
							<div className={classes.contentTitle}>
								<h4>{KEYS.project_onboarding_step_2_title}</h4>
								<p>{KEYS.project_onboarding_step_2_description}</p>
							</div>
							{uploadSuccess &&
								<InlineNotification
									className={classes.notification}
									kind="success"
									lowContrast
									hideCloseButton
									title={'Your file was uploaded successfully'}
									subtitle=""
								/>
							}
							{uploadError &&
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title="Error uploading file"
									subtitle=""
								/>
							}
							<div>
								<Mutation mutation={ADD_RAW_MATERIAL}>
									{(uploadRawMaterialFileMutation) => {
										return uploadingRawMaterialFile ? (
											<FileUploaderItem
												name={uploadingRawMaterialFile.filename}
												errorSubject={KEYS.files_error_exceeds_limit}
												status='uploading'
											/>
										) : (
											<div className={classes.materialCard} style={{border: 'none', textAlign: 'center'}}>
												<div
													className={classes.uploaderBox}
													onClick={() => this.inputFileRef.current.click()}
												>
													Upload new file
												</div>
												<input
													id="proposal-file-uploader"
													name="proposal-file-uploader"
													ref={this.inputFileRef}
													type="file"
													accept="image/*, .pdf"
													onChange={(e) => this.onClickUploadRawMaterialFile(e, project, uploadRawMaterialFileMutation)}
													style={{ display: 'none' }}
												/>
											</div>
										);
									}}
								</Mutation>
								<div className={classes.materialList}>
									{project.productSketch !== null &&
										<div className={classes.materialCard}>
											<RawMaterialImage
												src={rawMaterial.url}
												className={classes.cardImg}
												onClick={() => this.onOpenPreviewFile(rawMaterial.url)}
											/>
											<div className={classes.cardName}>
												<h5 className={classes.name}>{KEYS.first_sketch_file.substring(0, 20)}</h5>
												<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
													<OverflowMenuItem itemText="Download" href={rawMaterial.url} target="_blank" rel="noopener noreferrer" />
													<OverflowMenuItem isDelete disabled itemText="Delete" />
												</OverflowMenu>
											</div>
										</div>
									}
									<Mutation mutation={REMOVE_RAW_MATERIAL}>
										{(removeRawMaterialMutation) => {
											return (
												rawMaterials.map((material, i) => (
													<div className={classes.rawMaterialContainer} key={i}>
														<div className={classes.materialCard}>
															<RawMaterialImage
																src={material.url}
																className={classes.cardImg}
																alt={material.name}
																onClick={material.fileType === 'application/zip'
																	?	() => this.onOpenPreviewFile(material)
																	:	undefined
																}
															/>
															<div className={classes.cardName}>
																<h5 className={classes.name}>{material.name.substring(0, 20)}</h5>
																{(removingRawMaterial !== null && (removingRawMaterial.remove && removingRawMaterial.fileId === material.id))
																	?	<InlineLoading status="active" style={{ width: '20px'}} />
																	:	<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
																		<OverflowMenuItem itemText="Download" href={this.downloadFile(material)} target="_blank" rel="noopener noreferrer" />
																		<OverflowMenuItem hasDivider isDelete itemText="Delete" onClick={(e) => this.openDeleteFile(e, material, project, removeRawMaterialMutation)} />
																	</OverflowMenu>
																}
															</div>
														</div>
														{(errorRemovingRawMaterial !== null && (errorRemovingRawMaterial.error && errorRemovingRawMaterial.fileId === material.id)) &&
															<small style={{ margin: '0 16px' }}>{errorRemovingRawMaterial.error}</small>
														}
													</div>
												))
											);
										}}
									</Mutation>
								</div>
							</div>
							<div className={classes.btnContainer}>
								<Button
									className={classes.btn}
									kind="ghost"
									onClick={() => this.previousStep()}
								>
									{KEYS.previous}
								</Button>
								<Button
									className={classes.btn}
									onClick={() => this.nextStep()}
								>
									{KEYS.next}
								</Button>
							</div>
						</div>
					)}
					{currentStep === 2 && (
						<div className={classnames([classes.contentBlock, classes.contentSpace, classes.twoBtns])}>
							<div className={classes.contentTitle}>
								<h4>More details</h4>
								<p>Tell us more about your project for further understanding.</p>
							</div>
							{(!onboardingQuestionsDone) ? (
								<Fragment>
									<h5>Onboarding questions</h5>
									<PhaseSetupQuestions
										project={project}
										phase={PROJECT.STATE.CLIENT_ONBOARDING}
										onQuestionsAnswered={() => {
											this.setState({ onboardingQuestionsDone: true });
										}}
									/>
								</Fragment>
							) : (!sketchSetupQuestionsDone) ? (
								<Fragment>
									<h5>In order to start the first phase (Sketch)</h5>
									<ContestSetupQuestions
										contest={contestObject}
										onQuestionsAnswered={() => {
											this.setState({ sketchSetupQuestionsDone: true });
										}}
									/>
								</Fragment>
							) : (
								<div>
									<h5>Your project setup answers</h5>
									<PhaseViewSetupQuestions
										projectId={project.id}
										phase={PROJECT.STATE.CLIENT_ONBOARDING}
										onboardingQuestionsDone={() => undefined}
									/>
									<h5>Your sketch setup contest answers</h5>
									<ContestViewSetupQuestions
										projectId={project.id}
										contestType={CONTEST.TYPES.SKETCH}
									/>
								</div>
							)}
							<div className={classes.btnContainer}>
								<Button
									className={classes.btn}
									kind="ghost"
									onClick={() => this.previousStep()}
								>
									{KEYS.previous}
								</Button>
								<Button
									className={classes.btn}
									disabled={!onboardingQuestionsDone || !sketchSetupQuestionsDone}
									onClick={() => this.nextStep()}
								>
									{KEYS.next}
								</Button>
							</div>
						</div>
					)}
					{currentStep === 3 && (
						<div className={classnames([classes.contentBlock, classes.contentSpace, classes.twoBtns])}>
							<div className={classes.contentTitle}>
								<h4><NextOutline16 style={{ marginRight: '6px'}} />{KEYS.project_onboarding_step_4_title}</h4>
								<p>{KEYS.project_onboarding_step_4_description}</p>
							</div>
							<div className={classes.contentTitle}>
								<h4><Chat16 style={{ marginRight: '6px'}} /> We are here for you</h4>
								<p>If you have any questions, don’t hesitate to contact us. We are here to help you and give you guidance throughout the process.</p>
							</div>
							<div className={classes.btnContainer}>
								<Button
									className={classes.btn}
									kind="ghost"
									onClick={() => this.previousStep()}
								>
									{KEYS.previous}
								</Button>
								<Button
									className={classes.btn}
									onClick={() => projectOnboardingConfirmed()}
								>
									{KEYS.confirm_project}
								</Button>
							</div>
						</div>
					)}
					<PreviewFileModal
						open={!!previewFile}
						file={previewFile}
						onRequestClose={this.onClosePreviewFile}
					/>
				</div>
			</Fragment>
		);
	}
}

ProjectOnboarding.propTypes = {
	location: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	projectOnboardingConfirmed: PropTypes.func,
	userData: PropTypes.object.isRequired,
};

ProjectOnboarding.defaultProps = {
	projectOnboardingConfirmed() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(ProjectOnboarding)));
