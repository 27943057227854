import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SkeletonPlaceholder } from 'carbon-components-react';
import { Information16 } from '@carbon/icons-react';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';
import ContestCard from './contest-card';
import BackToTopButton from '../../../../components/elements/back-to-top-btn';

import classes from './contest-list.module.scss';

class ContestList extends Component {

	render() {
		const { query, filter, page, pageSize } = this.props;

		const variables = {
			filter,
			page,
			pageSize,
		};

		return (
			<ErrorBoundaryQuery query={query} variables={variables}>
				{({ loading, error, data }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.contests}>
								<SkeletonPlaceholder className={classnames([classes.contestCard, classes.cardSkeleton])} />
								<SkeletonPlaceholder className={classnames([classes.contestCard, classes.cardSkeleton])} />
								<SkeletonPlaceholder className={classnames([classes.contestCard, classes.cardSkeleton])} />
							</div>
						</div>
					);

					// No data provided
					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const contests = (result && result.contests) || [];
					const totalCount = (result && result.totalCount) || 0;

					if (!totalCount) {
						return (
							<div className={classes.noContent}>
								<Information16 />
								<p>No contests found.</p>
							</div>
						);
					}

					return (
						<div className={classes.contests}>
							{contests.map((contest, i) => (
								<ContestCard contest={contest} key={i} />
							))}
							{contests.length > 3 &&
								<div className={classes.btnBottom}>
									<BackToTopButton />
								</div>
							}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ContestList.defaultProps = {
	query: {},
	filter: {},
	page: 0,
	pageSize: 20,
};

ContestList.propTypes = {
	query: PropTypes.object.isRequired,
	filter: PropTypes.object,
	page: PropTypes.number,
	pageSize: PropTypes.number,
};

export default ContestList;