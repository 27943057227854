const LocalStore = window.localStorage;

export const FIRST_PROJECT = 'FIRST_PROJECT';
export const CELEBRATION_SHOWN = 'CELEBRATION_SHOWN';

const getProjectFeasibility = () => {
	try {
		let strProject = LocalStore.getItem(FIRST_PROJECT);
		if (strProject) return strProject;

		return null;
	} catch (error) {
		return null;
	}
};

const getProjectFeasibilityDone = () => {
	try {
		let strProject = LocalStore.getItem(CELEBRATION_SHOWN);
		if (strProject) return strProject;

		return null;
	} catch (error) {
		return null;
	}
};

const storeProjectFeasibility = (bool) => {
	try {
		LocalStore.setItem(FIRST_PROJECT, bool);
		return true;
	} catch (error) {
		return false;
	}
};

const storeProjectFeasibilityDone = (bool) => {
	try {
		LocalStore.setItem(CELEBRATION_SHOWN, bool);
		return true;
	} catch (error) {
		return false;
	}
};

const removeProjectFeasibility = () => {
	try {
		LocalStore.removeItem(FIRST_PROJECT);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getProjectFeasibility,
	getProjectFeasibilityDone,
	storeProjectFeasibility,
	removeProjectFeasibility,
	storeProjectFeasibilityDone,
};
