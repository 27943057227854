import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal } from 'carbon-components-react';
import NewProjectOption from './../project/new-project-options';

import classes from './preview-file-modal.module.scss';

class StartProjectModal extends Component {
	render() {
		const { open, ...rest } = this.props;
		
		return (
			<Modal
				passiveModal
				className={classnames([classes.previewFileModal, classes.moreContent])}
				open={open}
				modalLabel="New project"
				modalHeading="Project briefing"
				size="lg"
				{...rest}
			>
				{open && (
					<NewProjectOption />
				)}
			</Modal>
		);
	}
}

StartProjectModal.propTypes = {
	open: PropTypes.bool,
};

StartProjectModal.defaultProps = {
	open: false,
};

export default StartProjectModal;
