import React, { useEffect, useState } from 'react';

import classes from './scroll-indicator.module.scss';

const ScrollIndicator = () => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		function onScroll () {
			const Scrolled = document.documentElement.scrollTop;
			const MaxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
			const ScrollPercent = (Scrolled / MaxHeight) * 100;
			setScroll(ScrollPercent);
		}
		
		window.addEventListener('scroll', onScroll);
		
		// Trigger first time
		onScroll();
	
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.progressBar} style={{ width: `${scroll}%` }}></div>
		</div>
	);
};

export default ScrollIndicator;
