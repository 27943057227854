import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loading, Modal } from 'carbon-components-react';

import Config from '../../config';
import classes from './preview-file-modal.module.scss';

class ProfessionalInfoForm extends Component {
	render() {
		const { open, onRequestClose, email } = this.props;
		
		return (
			<Modal
				passiveModal
				className={classes.previewFileModal}
				open={open}
				onRequestClose={onRequestClose}
				modalHeading="Professional details"
			>
				{open && (
					<Fragment>
						<Loading
							className={classes.loading}
							withOverlay={false} />
						<iframe title="LastBasic Expert" src={Config.typeform.forms.expertInformation + '?typeform-source=app.lastbasic.com/home#email=' + email} />
					</Fragment>
				)}
			</Modal>
		);
	}
}

ProfessionalInfoForm.propTypes = {
	open: PropTypes.bool,
	onRequestClose: PropTypes.func,
	email: PropTypes.string,
};

ProfessionalInfoForm.defaultProps = {
	open: false,
};

export default ProfessionalInfoForm;
