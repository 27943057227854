import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from 'carbon-components-react';
import { Search32 } from '@carbon/icons-react';

import { PROJECT } from '../../constants';
import { serverAPI } from '../../utils';
import KEYS from '../../glossary';
import PreviewFileModal from './preview-file-modal';
import ContestCardImage from '../../components/project/contest-card-image';

import classes from './modals.module.scss';

class ContestCardModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
			reOpenModal: false,
		};
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onClosePreviewFileAndReopenContestCard() {
		this.setState({ previewFile: null, reOpenModal: true });
	}

	displayContestInfo(contest) {
		for (const contestRound of contest.rounds) {
			const { card } = contestRound;
			const { mainImage } = card;
			const mainImageUrl = mainImage !== undefined && serverAPI.getPreviewUrlByFile(mainImage);

			return (
				<Fragment>
					<div className={classes.contestContent}>
						<h5>This is your starting point</h5>
						<div className={classes.contestImgContainer}
							onClick={() => this.onOpenPreviewFile(mainImage)}>
							<ContestCardImage
								className={classes.contestImg}
								src={mainImageUrl}
							/>
							<div className={classes.contestImgOverlay}>
								<div>
									<Search32 />
									Open preview
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}
	}

	displayProjectBriefing(project) {
		const { userData: {token} } = this.props;

		const briefing = {
			name: 'Briefing File',
			url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
		};

		return (
			<div className={classes.contestContent}>
				<h5>Review the project briefing</h5>
				<div className={classes.contestImgContainer}>
					<span className={classes.link} onClick={() => this.onOpenPreviewFile(briefing)}>
						Open project briefing
					</span>
				</div>
			</div>
		);
	}

	render() {
		const {
			open,
			project,
			contest,
			contestCard,
			onRequestClose,
			onSecondarySubmit,
			onRequestSubmit
		} = this.props;
		const { previewFile, reOpenModal } = this.state;

		const label = PROJECT.stateToString(contestCard.state) !== 'Unknown'
			? PROJECT.stateToString(contestCard.state)
			: PROJECT.getStateByContestTypeShort(contestCard.state);

		return (
			<Fragment>
				<Modal
					open={open || reOpenModal}
					passiveModal={contestCard.contest.card.clientConfirmed === 'yes' ? true : false}
					hasScrollingContent
					modalLabel={label}
					primaryButtonText={KEYS.contest_card_confirm_btn}
					secondaryButtonText={KEYS.request_change}
					modalHeading={KEYS.contest_card_header}
					onRequestSubmit={() => this.setState({ reOpenModal: false }, onRequestSubmit)}
					onSecondarySubmit={() => this.setState({ reOpenModal: false }, onSecondarySubmit)}
					onRequestClose={() => this.setState({ reOpenModal: false }, onRequestClose)}
					aria-label="Contest Card Modal"
				>
					<div className={classes.modalContent}>
						{this.displayContestInfo(contest)}
					</div>
					<div className={classes.modalContent}>
						{this.displayProjectBriefing(project)}
					</div>
				</Modal>
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					goBackToContest={() => this.onClosePreviewFileAndReopenContestCard()}
					onRequestClose={() => this.onClosePreviewFile()}
				/>
			</Fragment>
		);
	}
}

ContestCardModal.propTypes = {
	open: PropTypes.bool,
	contestCard: PropTypes.object,
	error: PropTypes.object,
	userData: PropTypes.object,
	project: PropTypes.object,
	contest: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
	goBackToContest: PropTypes.func,
};

ContestCardModal.defaultProps = {
	open: false,
	contestCard: {},
	error: {},
	userData: {},
	project: {},
	contest: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
	goBackToContest() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ContestCardModal);