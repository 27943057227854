import React, { Component } from 'react';
import { Button } from 'carbon-components-react';
import { UpToTop16 } from '@carbon/icons-react';

class BackToTopButton extends Component {

	scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	render() {
		return (
			<Button
				renderIcon={UpToTop16}
				kind='secondary'
				aria-label='Back to top'
				iconDescription="Back to top"
				hasIconOnly
				onClick={this.scrollToTop}
				style={{ borderRadius: '2px', width: '150px' }}
			>
				Back to top
			</Button>
		);
	}
}

export default BackToTopButton;
