import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import Config from '../../config';
import ForceReload from '../../components/route/force-reload';
import withRouter from '../../components/route/with-router';
import * as actions from '../../reducers/user/actions';
import { auth } from '../../utils';

class AbstractRoute extends Component {

	redirectToRoute() {
		const { isPrivate, redirectTo, location } = this.props;

		let lastRoute = location.pathname;

		if (isPrivate) {
			// Some paths should not be redirected
			const noRedirectionPaths = ['/', '/logout'];
			if (noRedirectionPaths.includes(lastRoute)) {
				lastRoute = null;
			}

			const platformHost = Config.lastbasic.platform.host;
			return (
				<ForceReload
					path={`${redirectTo}${lastRoute ? `?next=${platformHost}${lastRoute}` : ''}`}
				/>
			);
		}
		
		return (
			<Navigate to={redirectTo} />
		);
	}

	getLoginData() {
		const { isPrivate, logout, loginSuccess, userData } = this.props;

		const tokens = auth.getValidStoredTokens();

		if (userData && !tokens) {
			logout();
			return null;
		} else if (!userData && tokens) {
			loginSuccess(tokens);
			return null;
		}

		const isLoggedIn = !!(tokens && userData);
		const needChange = ((isLoggedIn && !isPrivate) || (!isLoggedIn && isPrivate));

		return {
			needChange,
		};
	}

	render() {
		const res = this.getLoginData();
		if (!res) return null;

		return (
			res.needChange
				? this.redirectToRoute()
				: <Outlet />
		);
	}
}

AbstractRoute.propTypes = {
	isPrivate: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	loginSuccess: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	redirectTo: PropTypes.string.isRequired,
	userData: PropTypes.shape({}),
};

AbstractRoute.defaultProps = {
	userData: null,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

const mapDispatchToProps = dispatch => ({
	loginSuccess: (data) => dispatch(actions.loginSuccess(data)),
	logout: () => dispatch(actions.logout()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AbstractRoute));
