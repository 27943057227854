import React, { useState } from 'react';

import { userInfo } from '../../../utils';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';

import confetti from 'canvas-confetti';

import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import { Mutation } from '@apollo/client/react/components';

import { GET_PROJECTS_TO_VALIDATE } from '../../../providers/queries';
import { VALIDATE_PROJECT } from '../../../providers/mutations';

import StringUtils from '../../../utils/strings';
import ServerAPI from '../../../utils/server-api';

import PreviewFileModal from '../../../components/modals/preview-file-modal';

import classes from './project-validation.module.scss';
import validatorIllustration from './assets/validator.svg';
import projectValidationDoneIllustration from './assets/project-validation-done.svg';

export default function ProjectValidationPage() {
	const userData = userInfo.getUserInfo();
	const canValidateProjects = userData?.expert?.canValidateProjects;

	const [newProjects, setNewProjects] = useState(null);
	const [totalValidated, setTotalValidated] = useState(0);

	const [project, setProject] = useState(null);
	const [displayProjectBriefingModal, setDisplayProjectBriefingModal] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const [countAnimation, setCountAnimation] = useState(false);
	const [projectAnimation, setProjectAnimation]= useState(false);

	// Form
	const [feasible, setFeasible] = useState(null);
	const [comment, setComment] = useState('');

	function text(string) {
		string = StringUtils.capitalizeFirstLetter(string);

		if (string.length > 120) {
			string = string.slice(0, 123) + '...';
		}

		return string;
	}

	async function getRandomProject(animate = true) {
		if (animate) await animateDice();
		
		const projects = newProjects;
		projects.shift();
		setNewProjects(projects);
		setProject(projects[0] || null);
	}

	async function incrementTotalValidated() {
		const currentTotalValidated = totalValidated;
		const newTotal = currentTotalValidated + 1;
		setTotalValidated(newTotal);

		setCountAnimation(true);

		if (newTotal % 10 === 0) {
			confetti({
				particleCount: 60,
				spread: 90,
				startVelocity: 45
			});
		}
		
		await new Promise(r => setTimeout(r, 3000));
		setCountAnimation(false);
	}

	function setupProjects(projects) {
		setNewProjects(projects);
		setProject(projects[0] || null);
	}

	function handleActionClick(event, feasible) {
		setAnchorEl(event.currentTarget);
		setFeasible(feasible);
	}

	function handleActionClose() {
		setAnchorEl(null);
		setFeasible(null);
		setComment('');
	}

	function handleSendValidation(validateProject) {
		validateProject({
			variables: {
				projectId: project.id,
				feasible: (feasible === 'feasible' ? true : false),
				comment,
			}
		});

		getRandomProject();
		setAnchorEl(null);
		setFeasible(null);
		setComment('');
		incrementTotalValidated();
	}

	async function animateDice() {
		setProjectAnimation(true);
		await new Promise(r => setTimeout(r, 400));
		setProjectAnimation(false);
	}

	function onEnter(event, handler) {
		if (!event || !handler) return;
		if (event.key === 'Enter') return handler();
	}

	// Not approved to validate projects
	if (!canValidateProjects) {
		document.title = 'Platform - LastBasic';

		return (
			<>
				<h2>Page not available yet</h2>
			</>
		);
	}

	// Document title
	document.title = 'Project validation - LastBasic';

	if (!newProjects) {
		return (
			<ErrorBoundaryQuery query={GET_PROJECTS_TO_VALIDATE}>
				{({ data }) => {
					if (!data) return <></>;

					const projects = data?.getProjectsToValidate?.projects || [];
					const projectsValidated = data?.getProjectsToValidate?.projectsValidated || 0;

					setupProjects(projects);
					setTotalValidated(projectsValidated);

					return <></>;
				}}
			</ErrorBoundaryQuery>
		);
	}

	// Set displayed project
	// Project data
	let image;
	let projectBriefing;
	if (project) {
		if (project.image && project.image.id) {
			image = ServerAPI.getDisplayUrlByFile(project.image);
		}

		projectBriefing = ServerAPI.getPublicBriefingUrl(project.id);
	}

	return (
		<div className={classes.startingContainer}>
			<div className={classes.top}>
				<h2 className={classes.title} style={{ textAlign: 'center' }}>
					<div>
						<img src={validatorIllustration} />
					</div>
					Validate inventors’ ideas
				</h2>
				<div className={classes.descriptionWrapper}>
					<div className={classes.description}>
						Use your expertise to check the feasibility of incoming projects and access the newest ideas
					</div>
				</div>
			</div>
			<div className={classes.contentWrapper}>
				<div className={classes.content + ' ' + (projectAnimation ? classes.contentHidden : '')}>
					{ !project &&
						<div className={classes.empty}>
							<img src={projectValidationDoneIllustration} />
							<div className={classes.emptyText}>No more projects to validate for now!</div>
						</div>
					}
					{ project &&
						<>
							<div className={classes.left} style={(image ? { backgroundImage: `url(${image})` } : {})}></div>
							<div className={classes.right}>
								<div className={classes.projectTitle}>{text(project.name)}</div>

								<div className={classes.projectDescription}>
									<div className={classes.sectionName}>Description</div>
									<div className={classes.sectionContent} style={{ marginTop: '-4px' }}>{text(project.productSolution)}</div>

									<div className={classes.sectionName}>Full project briefing</div>
									<div className={classes.sectionContent}>
										<div className={classes.openProjectBriefing} onClick={() => setDisplayProjectBriefingModal(true)}>
											<div className={classes.openProjectBriefingContent}>
												<ArticleOutlinedIcon /> Read project briefing
											</div>
										</div>
									</div>

									<div className={classes.actionsSection}>
										<div className={classes.sectionName}>Actions</div>
										<div className={classes.sectionContent}>
											<div className={classes.actions}>
												<Tooltip title={'Set as not feasible'}>
													<div className={classes.action} onClick={(e) => handleActionClick(e, 'not-feasible')}>
														<CloseOutlinedIcon fontSize='inherit' htmlColor='#FA2B23' />
													</div>
												</Tooltip>

												<Tooltip title={'Get random project'}>
													<div className={classes.action} onClick={() => getRandomProject()}>
														<CasinoOutlinedIcon fontSize='inherit' htmlColor={'#1892FA'} />
													</div>
												</Tooltip>

												<Tooltip title={'Set as feasible'}>
													<div className={classes.action} id={'feasible'} onClick={(e) => handleActionClick(e, 'feasible')}>
														<DoneOutlinedIcon fontSize='inherit' htmlColor='#00B212'/>
													</div>
												</Tooltip>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
				</div>
			</div>

			<div className={classes.totalsWrapper}>
				<div className={classes.totals}>
					You have validated
					<span className={classes.count + ' ' + (countAnimation ? classes.countAnimation : '')}>
						&nbsp;<span style={{ fontWeight: 700 }}>{totalValidated}</span> project{(totalValidated !== 1 ? 's' : '')}
					</span>
				</div>
			</div>

			<Popover
				open={anchorEl}
				anchorEl={anchorEl}
				onClose={handleActionClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={classes.popover}>
					<Mutation mutation={VALIDATE_PROJECT}>
						{(validateProject, { error }) => {
							if (error) return <>An error occurred</>;

							return (
								<>
									<div className={classes.popoverTitle}>
										Set {feasible && (feasible === 'feasible' ? 'as feasible' : 'as not feasible') || 'a feasibility'}
									</div>
									<div className={classes.popoverForm}>
										<input
											placeholder='Comments'
											spellCheck={false}
											onChange={(e) => setComment(e.target.value)}
											onKeyDown={(e) => onEnter(e, () => handleSendValidation(validateProject))}
										/>
										<div className={classes.popoverButton} onClick={() => handleSendValidation(validateProject)}>
											<SendIcon color='inherit' fontSize='inherit' />
										</div>
									</div>
								</>
							);
						}}
					</Mutation>
				</div>
			</Popover>

			<PreviewFileModal
				open={displayProjectBriefingModal}
				src={projectBriefing}
				onRequestClose={() => setDisplayProjectBriefingModal(false)}
			/>
		</div>
	);
}
