import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	Breadcrumb,
	BreadcrumbItem,
	InlineNotification,
	SkeletonPlaceholder
} from 'carbon-components-react';

import { GET_CONTEST } from '../../../providers/queries';
import ContestCard from './components/contest-card';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import withRouter from '../../../components/route/with-router';

import classes from './contest-page.module.scss';

class ContestViewPage extends Component {

	componentDidMount() {
		document.title = 'Contest details - LastBasic';
	}

	render() {
		const { match } = this.props;
		const { id } = match.params;

		const contestId = id;

		return (
			<div className={classes.contestList}>
				<h2 className={classes.title}>Contest Detail</h2>
				<ErrorBoundaryQuery query={GET_CONTEST} variables={{ id: contestId }}>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<div className={classes.contests}>
										<SkeletonPlaceholder className={classnames([classes.contestCard, classes.cardSkeleton])} />
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const contest = data && data.getContest;

						return (
							<Fragment>
								<Breadcrumb noTrailingSlash className={classnames([classes.breadcrumbBox, classes.block])}>
									<BreadcrumbItem href="/contests">Back to contests list</BreadcrumbItem>
									<BreadcrumbItem isCurrentPage href="#">New open contest</BreadcrumbItem>
								</Breadcrumb>
								<ContestCard contest={contest} />
							</Fragment>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}
}

ContestViewPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(ContestViewPage);
