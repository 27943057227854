import smallSizeImage from '../pages/shared/project/assets/small-size.png';
import mediumSizeImage from '../pages/shared/project/assets/medium-size.png';
import largeSizeImage from '../pages/shared/project/assets/large-size.png';
import futuristicImage from '../pages/shared/project/assets/futuristic.png';
import classicImage from '../pages/shared/project/assets/classic.png';
import durableImage from '../pages/shared/project/assets/durable.png';
import delicateImage from '../pages/shared/project/assets/delicate.png';
import functionalImage from '../pages/shared/project/assets/functional.png';
import aestheticImage from '../pages/shared/project/assets/aesthetic.png';

export default {
	// LOOK AND FEEL
	SIZE: {
		SMALL: '5x5cm',
		MEDIUM: '15x15cm',
		LARGE: '25x25cm'
	},
	LOOKS: {
		FUTURISTIC: 'Futuristic',
		CLASSIC: 'Classic'
	},
	SOLIDITY: {
		DURABLE: 'Durable',
		DELICATE: 'Delicate'
	},
	FUNCTION: {
		FUNCTIONAL: 'Functional',
		AESTHETIC : 'Aesthetic'
	},
	// TECHNOLOGY & PARTS
	PARTS_NUMBER: {
		ONE_10: '1-10 parts (e.g. key fob)',
		ELEVEN_30: '11-30 parts (e.g. thermostat)',
		MORE_30: 'More than 30 parts (e.g. drone)',
		NOT_SURE: 'Not sure'
	},
	PARTS: {},
	SENSORS: {
		AIR_QUALITY: 'Air quality',
		ELECTRICAL: 'Electrical',
		WATER_QUALITY: 'Water quality',
		FORCE_PRESSURE: 'Force pressure?',
		HUMIDITY: 'Humidity',
		OPTICAL: 'Optical',
		ACCELEROMETER: 'Accelerometer',
		PROXIMITY: 'Proximity',
		GYROSCOPE: 'Gyroscope',
		TEMPERATURE: 'Temperature',
		ACOUSTIC: 'Acoustic',
		GPS: 'GPS',
		MOTION: 'Motion',
		OTHER: 'Other'
	},
	ELECTRONIC_DESIGN: {
		OFF_THE_SHELF: 'Off the shelf (comercial, bigger, realiable)',
		CUSTOM: 'Custom design (production oriented, smaller)',
		NOT_SURE: 'Not sure'
	},
	POWER_SOURCES: {
		DISPOSABLE_BATTERY: 'Disposable battery',
		RECHARGEABLE_BATTERY: 'Rechargeable battery',
		WIRED_POWER: 'Wired power',
		WIRELESS_POWER: 'Wireless power'
	},
	// BUSINESS
	PROJECT_STAGES: {
		GATHERING_INFO: 'Just gathering information',
		PROJECT_DEFINITION: 'Defining project',
		NOT_ENGINEERED_PROTOTYPE: 'Project defined without engineering prototype',
		ENGINEERED_PROTOTYPE: 'Engineered prototype',
		NEW_PRODUCT_INTRODUCTION: 'New product introduction'
	},
	INTENTIONS: {
		JUST_TESTING: 'Just testing',
		PERSONAL_PROJECT: 'Personal project',
		STANDALONE_PROJECT: 'Standalone project',
		PART_OF_BIGGER_PROJECT: 'Part of a bigger project',
		ENTERPRISE: 'Enterprise',
		CROWDFUNDING_INVESTMENT_PROJECT: 'Crowdfunding/Investment oriented project'
	},
	PRIORITIES: {},
	getSizeImage: function (size) {
		switch(size) {
			case this.SIZE.SMALL:
				return smallSizeImage;
			case this.SIZE.MEDIUM:
				return mediumSizeImage;
			case this.SIZE.LARGE:
				return largeSizeImage;	
			default:
				return smallSizeImage;
		}	
	},
	getLooksImage: function (looks) {
		switch(looks) {
			case this.LOOKS.FUTURISTIC:
				return futuristicImage;
			case this.LOOKS.CLASSIC:
				return classicImage;
			default:
				return futuristicImage;
		}	
	},
	getSolidityImage: function (solidity) {
		switch(solidity) {
			case this.SOLIDITY.DURABLE:
				return durableImage;
			case this.SOLIDITY.DELICATE:
				return delicateImage;
			default:
				return durableImage;
		}	
	},
	getFunctionImage: function (funcionality) {
		switch(funcionality) {
			case this.FUNCTION.FUNCTIONAL:
				return functionalImage;
			case this.FUNCTION.AESTHETIC:
				return aestheticImage;
			default:
				return functionalImage;
		}
	},
	getNumberPartsText: function (parts) {
		switch(parts) {
			case '1_10':
				return this.PARTS_NUMBER.ONE_10;
			case '11_30':
				return this.PARTS_NUMBER.ELEVEN_30;
			case '30_more':
				return this.PARTS_NUMBER.MORE_30;
			case 'not_sure':
				return this.PARTS_NUMBER.NOT_SURE;
			default:
				return 'N/D';
		}
	},
	getProjectStageText: function (stage) {
		switch(stage) {
			case 'gathering_info':
				return this.PROJECT_STAGES.GATHERING_INFO;
			case 'project_definition':
				return this.PROJECT_STAGES.PROJECT_DEFINITION;
			case 'not_engineered_prototype':
				return this.PROJECT_STAGES.NOT_ENGINEERED_PROTOTYPE;
			case 'engineered_prototype':
				return this.PROJECT_STAGES.ENGINEERED_PROTOTYPE;
			case 'new_product_introduction':
				return this.PROJECT_STAGES.NEW_PRODUCT_INTRODUCTION;
			default:
				return 'N/D';
		}
	},
	getElectronicDesignText: function (electronic_design) {
		switch(electronic_design) {
			case 'off_the_shelf':
				return this.ELECTRONIC_DESIGN.OFF_THE_SHELF;
			case 'custom':
				return this.ELECTRONIC_DESIGN.CUSTOM;
			case 'not_sure':
				return this.ELECTRONIC_DESIGN.NOT_SURE;
			default:
				return 'N/D';
		}
	},
	getPowerSourceText: function (power) {
		switch(power) {
			case 'disposable_battery':
				return this.POWER_SOURCES.DISPOSABLE_BATTERY;
			case 'rechargeable_battery':
				return this.POWER_SOURCES.RECHARGEABLE_BATTERY;
			case 'wired_power':
				return this.POWER_SOURCES.WIRED_POWER;
			case 'wireless_power':
				return this.POWER_SOURCES.WIRELESS_POWER;
			default:
				return 'N/D';
		}
	},
	getIntentionsText: function (intention) {
		switch(intention) {
			case 'just_testing':
				return this.INTENTIONS.JUST_TESTING;
			case 'personal_project':
				return this.INTENTIONS.PERSONAL_PROJECT;
			case 'standalone_project':
				return this.INTENTIONS.STANDALONE_PROJECT;
			case 'part_of_bigger_project':
				return this.INTENTIONS.PART_OF_BIGGER_PROJECT;
			case 'enterprise':
				return this.INTENTIONS.ENTERPRISE;
			case 'crowdfunding_investment_project':
				return this.INTENTIONS.CROWDFUNDING_INVESTMENT_PROJECT;
			default:
				return 'N/D';
		}
	},
	getSensorText: function (sensor) {
		switch(sensor) {
			case 'air_quality':
				return this.SENSORS.AIR_QUALITY;
			case 'electrical':
				return this.SENSORS.ELECTRICAL;
			case 'water_quality':
				return this.SENSORS.WATER_QUALITY;
			case 'force_pressure':
				return this.SENSORS.FORCE_PRESSURE;
			case 'humidity':
				return this.SENSORS.HUMIDITY;
			case 'optical':
				return this.SENSORS.OPTICAL;
			case 'accelerometer':
				return this.SENSORS.ACCELEROMETER;
			case 'proximity':
				return this.SENSORS.PROXIMITY;
			case 'gyroscope':
				return this.SENSORS.GYROSCOPE;
			case 'temperature':
				return this.SENSORS.TEMPERATURE;
			case 'acoustic':
				return this.SENSORS.ACOUSTIC;
			case 'gps':
				return this.SENSORS.GPS;
			case 'motion':
				return this.SENSORS.MOTION;
			case 'other':
				return this.SENSORS.OTHER;
			default:
				return 'N/D';
		}
	}
};
