import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import withRouter from '../../components/route/with-router';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		const { location } = this.props;
		if (location !== prevProps.location) {
			if (typeof window !== 'undefined') window.scrollTo(0, 0);
		}
	}

	render() {
		const { children } = this.props;
		
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	}
}

ScrollToTop.propTypes = {
	location: PropTypes.object.isRequired,
	children: PropTypes.object,
};

ScrollToTop.defaultProps = {
	children: {},
};

export default withRouter(ScrollToTop);