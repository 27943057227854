import { gql } from '@apollo/client';

const paymentFragment = gql`
	fragment CreditTransaction on CreditTransaction {
		id,
		amount,
		concept,
		metadata,
		type,
		createdAt
	}
	fragment WalletTransaction on WalletTransaction {
		id,
		amount,
		concept,
		metadata,
		type,
		createdAt
	}
	fragment Payout on Payout {
		id,
		amount,
		netAmount,
		concept,
		type,
		status,
		metadata,
 		invoiceUrl,
		invoiceAvailable,
		paymentInfo,
		createdAt
	}
	fragment PaymentDetails on Payment {
		id,
		firstName,
		lastName,
		email,
		paymentInfo,
		creditTransactions {
			...CreditTransaction
		},
		walletTransactions {
			...WalletTransaction
		},
		payouts {
			...Payout
		},
		minCreditUntilPayment,
		creditBalance,
		walletBalance,
		payoutBalance,
		creditLevel,
		openPayouts
	}
`;

export {
	paymentFragment,
};