import { LOGIN_SUCCESS, LOGOUT } from './types';

const initialState = {
	data: null,
	error: null,
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				data: action.data,
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
}
