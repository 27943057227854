import { gql } from '@apollo/client';
import {
	clientAddress,
	contestBasicFragment,
	contestFragment,
	contestProposalsFragment,
	contestStepsFragment,
	imagesFragment,
	paymentFragment,
	privateUserFragment,
	projectFragment,
	projectShortFragment,
	projectBasicFragment,
	projectUpdatesFragment,
	projectInvoiceFragment,
	projectSetupQuestionsFragment,
	projectFeasibilityFragment,
	projectFilesFragment,
	projectContestsFragment,
	projectInfoFragment,
	projectBriefingFragment,
	rankingFragment,
} from './fragments';

const GET_MY_PROFILE_INFO = gql`
	${privateUserFragment}

	query myProfileInfo {
		myProfileInfo {
			...PrivateUserDetails
		}
	}
`;

const GET_CLIENT_DELIVERY_ADDRESS = gql`
	${clientAddress}

	query myProfileInfo {
		myProfileInfo {
			...PrivateUserDeliveryAddressDetails
		}
	}
`;

const GET_PROJECT = gql`
	${projectFragment}

	query project($id: String!) {
		project(id: $id) {
			...ProjectDetails
		}
	}
`;

const GET_PROJECT_UPDATES = gql`
	${projectUpdatesFragment}

	query project($id: String!) {
		project(id: $id) {
			...ProjectUpdates
		}
	}
`;

const GET_PROJECT_SETUP_QUESTIONS = gql`
	${projectSetupQuestionsFragment}

	query project($id: String!) {
		project(id: $id) {
			...ProjectSetupQuestions
		}
	}
`;

const GET_OWNED_PROJECTS = gql`
	${projectShortFragment}
	${projectBasicFragment}
	${projectInvoiceFragment}
	${projectFeasibilityFragment}

	query ownedProjects($page: Int, $pageSize: Int, $withMoreDetails: Boolean!, $onlyId: Boolean!, $includeFeasibility: Boolean!) {
		ownedProjects(page: $page, pageSize: $pageSize) {
			projects @include(if: $onlyId){
				...ProjectShort,
				@include(if: $includeFeasibility) ...ProjectFeasibility
			},
			projects @include(if: $withMoreDetails){
				...ProjectBasic,
				...ProjectInvoice
			},
			totalCount
		}
	}
`;

const GET_PARTICIPATING_PROJECTS = gql`
	${projectFragment}

	query participatingProjects($page: Int, $pageSize: Int) {
		participatingProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectDetails
			},
			totalCount
		}
	}
`;

const GET_AVAILABLE_PROJECTS = gql`
	${projectFragment}

	query availableProjects($page: Int, $pageSize: Int) {
		availableProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectDetails
			},
			totalCount
		}
	}
`;

const GET_AVAILABLE_NEW_IN_PROJECT = gql`
	${projectInfoFragment}

	query availableProjects($page: Int, $pageSize: Int) {
		availableProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectInfoDetails
			},
			totalCount
		}
	}
`;

const GET_AVAILABLE_AND_CLOSED_PROJECTS = gql`
	${projectFragment}

	query availableAndClosedProjects($page: Int, $pageSize: Int) {
		availableAndClosedProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectDetails
			},
			totalCount
		}
	}
`;

const GET_CLOSED_PROJECTS = gql`
	${projectFragment}

	query closedProjects($page: Int, $pageSize: Int) {
		closedProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectDetails
			},
			totalCount
		}
	}
`;

const GET_FAVORITE_PROJECTS = gql`
	${projectFragment}

	query favoriteProjects($page: Int, $pageSize: Int) {
		favoriteProjects(page: $page, pageSize: $pageSize) {
			projects {
				...ProjectDetails
			},
			totalCount
		}
	}
`;

const GET_CONTEST = gql`
	${contestFragment}

	query getContest($id: String!) {
		getContest(id: $id) {
			...Contest
		}
	}
`;

const GET_CONTEST_CARD_INFO = gql`
	${contestBasicFragment}

	query getContest($id: String!) {
		getContest(id: $id) {
			...ContestBasic
		}
	}
`;

const GET_CONTESTS_INVOLVED = gql`
	${contestBasicFragment}

	query getContestsInvolved($page: Int, $pageSize: Int) {
		getContestsInvolved(page: $page, pageSize: $pageSize) {
			contests {
				...ContestBasic
			},
			totalCount
		}
	}
`;

const GET_CONTESTS_BY_STATE = gql`
	${contestFragment}

	query getContests($filter: ContestListFilter, $page: Int, $pageSize: Int) {
		getContests(filter: $filter, page: $page, pageSize: $pageSize) {
			contests {
				...Contest
			},
			totalCount
		}
	}
`;

const GET_PROPOSALS = gql`
	${contestProposalsFragment}

	query getContest($id: String!) {
		getContest(id: $id) {
			...ContestProposals
		}
	}
`;

const GET_RANKINGS = gql`
	${rankingFragment}

	query getRanking($projectId: String!) {
		getRanking(projectId: $projectId) {
			contests {
				...RankingContest
			}
		}
	}
`;

const GET_PROJECT_CONTESTS = gql`
	${projectContestsFragment}

	query getRanking($projectId: String!) {
		getRanking(projectId: $projectId) {
			contests {
				...ProjectContests
			}
		}
	}
`;

const GET_PROJECT_FILES = gql`
	${projectFilesFragment}

	query project($id: String!) {
		project(id: $id) {
			...ProjectFiles
		}
	}
`;

const GET_PROJECT_EXTRA_INFO = gql`
	${projectBriefingFragment}

	query getProjectBriefing($projectId: String!) {
		getProjectBriefing(projectId: $projectId) {
			...ProjectBriefing
		}
	}
`;

const GET_PHASE_STEPS = gql`
	${contestStepsFragment}

	query getContest($id: String!) {
		getContest(id: $id) {
			...ContestStepsDetails
		}
	}
`;

const AVAILABLE_PROJECT_IMAGE_LIBRARY = gql`
	${imagesFragment}

	query availableProjectImageLibrary($term: String, $pageSize: Int) {
		availableProjectImageLibrary(term: $term, pageSize: $pageSize) {
			...ProjectImageLibraryImage
		}
	}
`;

const GET_USER_PAYMENTS = gql`
	${paymentFragment}

	query getMyPayments {
		getMyPayments {
			...PaymentDetails
		}
	}
`;

const GET_HOW_MANY_REFERRED = gql`
	query getNumberReferred {
		getNumberReferred
	}
`;

const GET_PROJECTS_TO_VALIDATE = gql`
	query getProjectsToValidate {
		getProjectsToValidate {
			projectsValidated,
			projects {
				id, name, productNeed, productSolution, image { id }
			}
		}
	}
`;

export {
	// Project
	GET_PROJECT,
	GET_PROJECT_UPDATES,
	GET_PROJECT_SETUP_QUESTIONS,
	GET_OWNED_PROJECTS,
	GET_PARTICIPATING_PROJECTS,
	GET_AVAILABLE_PROJECTS,
	GET_AVAILABLE_NEW_IN_PROJECT,
	GET_AVAILABLE_AND_CLOSED_PROJECTS,
	GET_CLOSED_PROJECTS,
	GET_FAVORITE_PROJECTS,
	AVAILABLE_PROJECT_IMAGE_LIBRARY,
	GET_PHASE_STEPS,
	GET_PROJECT_EXTRA_INFO,

	// Contest
	GET_CONTEST,
	GET_CONTEST_CARD_INFO,
	GET_CONTESTS_BY_STATE,
	GET_CONTESTS_INVOLVED,
	GET_PROJECT_CONTESTS,

	// Proposals
	GET_PROPOSALS,

	// Rankings
	GET_RANKINGS,

	// Project files
	GET_PROJECT_FILES,

	// User
	GET_MY_PROFILE_INFO,
	GET_CLIENT_DELIVERY_ADDRESS,
	GET_HOW_MANY_REFERRED,

	// Payments
	GET_USER_PAYMENTS,

	// Project validation
	GET_PROJECTS_TO_VALIDATE,
};
