import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { InlineLoading, Modal, Button } from 'carbon-components-react';

import withRouter from '../../components/route/with-router';
import { SELECT_WINNING_PROPOSAL } from '../../providers/mutations';
import { serverAPI } from '../../utils';
import KEYS from '../../glossary';

import classes from './modals.module.scss';

class SelectWinningProposalConfirmationModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			sendingWinningConfirmation: false,
			success: false,
			errorConfirmingWinningProposal: null,
		};
	}

	async onSelectWinningProposal(e, selectWinningProposalMutation) {
		const { onRequestSubmit, proposal, proposalId } = this.props;
		const idProposal = proposal !== null ? proposal.proposal.id : proposalId;

		e.preventDefault();
		let error;
		this.setState({ sendingWinningConfirmation: true });

		try {
			const result = await selectWinningProposalMutation({
				variables: {
					proposalId: idProposal
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			this.setState({ errorConfirmingWinningProposal: error.toString() });
			setTimeout(() => {
				this.setState({ errorConfirmingWinningProposal: null });
			}, 3000);
		} else {
			this.setState({ success: true, sendingWinningConfirmation: false });
			setTimeout(() => {
				onRequestSubmit();
			}, 1500);
		}
		this.setState({ sendingWinningConfirmation: false });
	}

	getProposalThumbnailUrl(proposal, userData) {
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	render() {
		const { open, proposal, onRequestClose } = this.props; // userData
		const { sendingWinningConfirmation, success, errorConfirmingWinningProposal } = this.state;
		const proposalNumber = proposal !== null && `Proposal #${proposal.proposal.number}`;

		return (
			<Mutation mutation={SELECT_WINNING_PROPOSAL}>
				{(selectWinningProposalMutation) => {
					return (
						<Modal
							open={open}
							passiveModal={true}
							modalLabel={proposalNumber}
							modalHeading='Winning proposal confirmation'
							onRequestSubmit={(e) => this.onSelectWinningProposal(e, selectWinningProposalMutation)}
							onRequestClose={onRequestClose}
							modalAriaLabel="Select winning proposal confirmation modal"
						>
							{/* <div className={classes.imgContainer}>
								<img
									className={classes.proposalImg}
									src={this.getProposalThumbnailUrl(proposal !== null && proposal.proposal, userData)}
									alt='Proposal thumbnail'
								/>
							</div> */}
							<div className={classes.modalContent}>
								<div className={classes.contestContent}>
									<p>Are you sure you want to select <strong>proposal #{proposal !== null && proposal.proposal.number}</strong> as the winning one?</p>
								</div>
							</div>
							<div className={classes.btnBox}>
								<Button type="submit" className={classes.btn} onClick={onRequestClose} kind="secondary" disabled={sendingWinningConfirmation || success}>
									{KEYS.cancel}
								</Button>
								{sendingWinningConfirmation || success ? (
									<InlineLoading
										style={{ marginLeft: '1rem', width: '200px' }}
										description={success ? 'Winning proposal confirmed successfully' : 'Confirming winning proposal ...'}
										status={success ? 'finished' : 'active'}
									/>
								) : errorConfirmingWinningProposal
									? 	<InlineLoading
										style={{ marginLeft: '1rem', width: '200px' }}
										description={'Error confirming winning proposal'}
										status={'error'}
									/>
									:	(
										<Button
											className={classes.btn}
											onClick={(e) => this.onSelectWinningProposal(e, selectWinningProposalMutation)}
										>
											Yes, select as winner!
										</Button>
									)}
							</div>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

SelectWinningProposalConfirmationModal.propTypes = {
	open: PropTypes.bool,
	proposal: PropTypes.object,
	proposalId: PropTypes.string,
	userData: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

SelectWinningProposalConfirmationModal.defaultProps = {
	open: false,
	proposal: null,
	proposalId: null,
	userData: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(SelectWinningProposalConfirmationModal));
