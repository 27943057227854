import config from '../../../../config';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	InlineNotification,
	// Modal,
	SkeletonPlaceholder,
	SkeletonText,
} from 'carbon-components-react';

import { GET_PROJECT } from '../../../../providers/queries';
import withRouter from '../../../../components/route/with-router';

import { serverAPI, strings } from '../../../../utils';

import ScrollIndicator from '../../../../components/elements/scroll-indicator';
import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import classes from './unlock-project.module.scss';

import openInnovationImage from './assets/open-innovation.svg';
import flexibleImage from './assets/flexible.svg';
import affordableImage from './assets/affordable.svg';

import { Tooltip } from '@mui/material';

import sendInsight from '../../../../utils/insights';

const features = [
	{
		title: '🗒 Idea Digest',
		description: 'Analysis and briefing creation of you project',
		active: true,
	},
	{
		title: '👏 Community',
		description: 'Feedback from the community of experts ',
		active: true,
	},
	{
		title: '✏️ Sketches',
		description: 'Up to 10 high quality sketch proposals of your idea',
	},
	{
		title: '🖼 3D Models',
		description: 'Up to 5 high quality 3D model proposals',
	},
	{
		title: '⚙️ Mechanics',
		description: 'Up to 4 mechanical proposals made by engineers',
	},
	{
		title: '⚡️ Electronics',
		description: 'Up to 4 electronic proposals made by engineers',
		type: 'electrical',
	},
	{
		title: '✋ Manufacturing',
		description: 'Assembly of the physical prototype of your idea',
	},
	{
		title: '🤝 Support', //🛟
		description: 'Support at any time for any need you have in the process',
		active: true,
	},
	{
		title: '📦 Delivery',
		description: 'Receive your prototype no matter where you are',
	},
	{
		title: '🔐 IP Protection',
		description: 'All our team, designers, and engineers sign NDAs',
		active: true,
	},
];

class UnlockProjectPage extends Component {
	constructor (props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		document.title = 'Unlock project - LastBasic';
	}

	displayFeature(title, description, active, type, project) {
		if (type) {
			const activeContests = project.contests.filter(c => c.active);
			if (!activeContests.find(c => c.type === type)) {
				return <></>;
			}
		}

		return (
			<div className={classes.feature}>
				{ active && <div className={classes.featureActive}>Active</div> }
				<div className={classes.featureTitle}>{title}</div>
				<div className={classes.featureDescription}>{description}</div>
			</div>
		);
	}

	displayContestHeader(project) {
		return (
			<div className={classes.spaceBetween}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					{this.displayProjectImage(project)}
					<div className={classes.flexColumn}>
						<Link
							to={`/project/${project.id}`}
							className={classes.breadcrumb}
						>
							<h5 style={{color:'#df3d3b'}}>{strings.capitalizeFirstLetter(project.name)}</h5>
						</Link>
						<h3>Unlock project</h3>
					</div>
				</div>
			</div>
		);
	}

	displayProjectImage(project) {
		return (
			<Link
				to={`/project/${project.id}`}
				style={{textDecoration: 'none'}}
			>
				<img
					className={classes.projectThumbnail}
					src={this.renderProjectImageUrl(project)}
					alt={'Project thumbnail'}
				/>
			</Link>
		);
	}

	renderProjectImageUrl(project) {
		const { image } = project;
		const DefaultImg = '/assets/images/logo-lb-project.png';

		if (!image) return DefaultImg;
		return serverAPI.getThumbnailUrlByFile({id: image.id });
	}

	getPayUrl(project, type) {
		if(!['full', 'sketch', 'design'].includes(type)) {
			throw Error('Pay URL must be "full" or "sketch"');
		}

		const projectId = project.id;
		const baseUrl = `${config.lastbasic.api.host}/pay/project/${projectId}`;

		if (type === 'full') {
			return baseUrl + '/projectSetup';
		}

		if (type === 'sketch') {
			return baseUrl + '/sketch';
		}

		if (type === 'design') {
			return baseUrl + '/sketch-model';
		}
	}

	onClickPayButton(project, type) {
		const url = this.getPayUrl(project, type);
		sendInsight('click', 'unlock_pay_button_' + type, null, project.id, `:owner:${project.owner.id}:`);
		window.open(url, '_blank');
	}

	render() {
		const { match } = this.props;
		const { id } = match.params;

		return (
			<div className={classes.contestViewPage}>
				<ErrorBoundaryQuery query={GET_PROJECT} variables={{ id: id }}>
					{({ loading, error, data, refetch: refetchContest }) => {
						this.refetchContest = refetchContest;

						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<div className={classes.betweenBlock} style={{ alignItems: 'end'}}>
										<div style={{display: 'flex', alignItems: 'center'}}>
											<SkeletonPlaceholder className={classes.projectThumbnail} />
											<div style={{display: 'flex', flexDirection: 'column'}}>
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '200px'}} />
											</div>
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<div className={classes.betweenBlock} style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ width: '90%', maxWidth: '800px' }}>
												<div>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
												</div>
												<div>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
													<SkeletonText style={{width: '100%'}}/>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}
						const project = data?.project;
						const estimatedPrice = (project?.estimatedPrice?.price?.amountInCents || 800000) / 100;

						if (project.paid) {
							window.location.replace(config.lastbasic.platform.host + '/project/' + project.id);
						}

						sendInsight('view', 'unlock_project', null, project.id, `:owner:${project.owner.id}:`);

						return (
							<div>
								<ScrollIndicator />
								{/* Contest main header */}
								{this.displayContestHeader(project)}

								<div className={classes.horizontalDivider}></div>

								{/* UnlockContent */}
								<div className={classes.unlockContentWrapper}>
									<div className={classes.unlockContent}>
										<div className={classes.unlockSectionDescription}>
											Creating a prototype with LastBasic is the best choice in the market because we are:
										</div>

										<div className={classes.unlockCenter}>
											<div className={classes.vision}>
												<div className={classes.visionItem}>
													<div className={classes.unlockCenter}>
														<div className={classes.visionImage} style={{ backgroundImage: `url(${openInnovationImage})`}}></div>
													</div>
													<div className={classes.visionTitle}>Open innovation</div>
													<div className={classes.visionDescription}>
														The best designers and engineers from all over the world will participate in your project providing their best ideas to you
													</div>
												</div>

												<div className={classes.visionItem}>
													<div className={classes.unlockCenter}>
														<div className={classes.visionImage} style={{ backgroundImage: `url(${affordableImage})`}}></div>
													</div>
													<div className={classes.visionTitle}>Affordable</div>
													<div className={classes.visionDescription}>
														Our method is up to 90% more affordable than traditional engineering consultancies. Oh! And more transparent and fast
													</div>
												</div>

												<div className={classes.visionItem}>
													<div className={classes.unlockCenter}>
														<div className={classes.visionImage} style={{ backgroundImage: `url(${flexibleImage})`}}></div>
													</div>
													<div className={classes.visionTitle}>Flexible</div>
													<div className={classes.visionDescription}>
														We are here for you, so we are flexible with your requirements, branding, and even in how you pay for the different features
													</div>
												</div>
											</div>
										</div>

										<div className={classes.unlockSectionDescription}>
											Create&nbsp;
											{ project?.name && <>&quot;{project.name.trim()}&quot;</>}
											{ !project?.name && <>your project</>}
											&nbsp;in LastBasic for just
											&nbsp;<span style={{ textDecoration: 'line-through' }}>{(estimatedPrice + 2000).toLocaleString()}€</span>&nbsp;
											<Tooltip title={`Based on your briefing you are saving up to ${(40000).toLocaleString()}€ compared with traditional consultancies`}>
												<span style={{ borderBottom: '1px dashed #333', cursor: 'pointer' }}>{estimatedPrice.toLocaleString()}€</span>
											</Tooltip>
											&nbsp;and <span style={{ fontWeight: 600 }}>get all</span> this:
										</div>

										<div className={classes.features}>
											{ features.map(f => this.displayFeature(f.title, f.description, f.active, f.type, project))}
										</div>

										<div className={classes.unlockSectionDescription} style={{ display: 'none', justifyContent: 'center', }}>
											<div className={classes.unlockLink}>
												See a demo project created in LastBasic
											</div>
										</div>

										<div className={classes.unlockCenter}>
											<div className={classes.pricing}>
												<div className={classes.unlockCenter}>
													<div className={classes.fullSection}>
														<div className={classes.fullButton} onClick={() => this.onClickPayButton(project, 'full')}>Unlock the full prototype creation for {(estimatedPrice).toLocaleString()}€*</div>
														<div className={classes.fullButtonDescription}>
															*You get a {(2000).toLocaleString()}€ discount for being one of 20 first inventors to close a project with us this month - don’t hesitate too much
															<div className={classes.specialOffer}>Special offer</div>
														</div>
													</div>
												</div>

												<div className={classes.pricingDivider}>
													<div className={classes.pricingDividerLine}></div>
													<div className={classes.pricingDividerText}>or</div>
												</div>
												
												<div className={classes.unlockCenter}>
													<div className={classes.secondButtonDescription} style={{ maxWidth: '320px', lineHeight: 1.1 }}>You can now pay for the full design package, or just Sketches and then decide if you want more</div>
												</div>

												<div className={classes.unlockCenter}>
													<div className={classes.secondSection}>
														<div className={classes.secondButton} onClick={() => this.onClickPayButton(project, 'design')}>Unlock full design (Sketches + 3D Model) for 995€</div>
													</div>
												</div>
												<div className={classes.unlockCenter} style={{ marginTop: '20px' }}>
													<div className={classes.secondSection}>
														<div className={classes.secondButton} onClick={() => this.onClickPayButton(project, 'sketch')}>Unlock Sketches for 399€</div>
													</div>
												</div>
											</div>
										</div>

										<div className={classes.unlockCenter}>
											<div className={classes.unlockFooter}>The final amount might include VAT if applicable. When paying as you go, we will only charge you further if you decide to go on with us and with your consent. You can get a refund in the first 30 days if you are not satisfied (but you will)</div>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}
}

UnlockProjectPage.propTypes = {
	userData: PropTypes.object,
	match: PropTypes.object,
	refetchContest: PropTypes.func
};

UnlockProjectPage.defaultProps = {
	refetchContest() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(UnlockProjectPage));
