import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	Modal,
	Button,
	InlineLoading,
	InlineNotification,
	SkeletonText,
	Search,
} from 'carbon-components-react';

import { ChevronLeft16 } from '@carbon/icons-react';

import { AVAILABLE_PROJECT_IMAGE_LIBRARY } from '../../providers/queries';
import { UPDATE_MY_PROJECT } from '../../providers/mutations';


import classes from './modals.module.scss';
import ErrorBoundaryQuery from '../graphql/queries-with-errors';

import sendInsight from '../../utils/insights';

class UpdateProjectImageModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			sendingProjectImageUpdate: false,
			success: false,
			errorUpdatingProjectImage: null,
			searchTerm: '',
			newImageSelected: null,
			hoverShowImageCredits: null,
		};
	}

	async updateProjectImage(e, projectId, updateMyProjectMutation) {
		const { onRequestSubmit } = this.props;
		const { newImageSelected } = this.state;

		e.preventDefault();
		let error;

		try {
			const result = await updateMyProjectMutation({
				variables: {
					projectId: projectId,
					fields: {
						image: {
							id: newImageSelected.id,
							source: newImageSelected.source
						}
					},
				}
			});
			if (!result) error = new Error('Something went wrong');

			sendInsight('action', 'update_project_image', null, projectId, '');
		} catch (e) {
			error = e;
		}
		if (error) {
			this.setState({ errorUpdatingProjectImage: error.toString() });
			setTimeout(() => {
				this.setState({ errorUpdatingProjectImage: null });
			}, 3000);
		} else {
			this.setState({ success: true, sendingProjectImageUpdate: false });
			setTimeout(() => {
				this.setState({ success: false, searchTerm: '', newImageSelected: null });
				onRequestSubmit();
			}, 1500);
		}
		this.setState({ sendingProjectImageUpdate: false });
	}

	displaySelectedImage() {
		const { newImageSelected } = this.state;

		return (
			<div>
				<div className={classes.returnActionModal}>
					<ChevronLeft16 />
					<span onClick={() => this.setState({ newImageSelected: null })}>
						Return to image library
					</span>
				</div>

				<div
					className={classes.projectLibraryImage + ' ' + classes.projectLibrarySelectedImage}
					style={{ backgroundImage: `url(${newImageSelected.urls.regular})` }}
				/>
			</div>
		);
	}

	displayImageLibrary(images) {
		const { hoverShowImageCredits } = this.state;

		return (
			<div className={classes.libraryContainer}>
				{images.map((image, i) => (
					<div
						key={i}
						className={classes.projectLibraryImageWrapper}
						onMouseEnter={() => this.setState({ hoverShowImageCredits: { imageId: image.id }})}
						onMouseLeave={() => this.setState({ hoverShowImageCredits: null })}
					>
						<div
							className={classes.projectLibraryImage}
							style={{ backgroundImage: `url(${image.urls.small})`}}
							onClick={() => this.setState({ newImageSelected: image })}
						/>

						{(hoverShowImageCredits && hoverShowImageCredits.imageId === image.id) &&
							<a href={image.credits.url} target='_blank' rel='noreferrer'>
								<div className={classes.imageCredits}>
									<small className={classes.credits}>
										{image.credits.name}
									</small>
								</div>
							</a>
						}
					</div>
				))}
			</div>
		);
	}

	onSearchTerm(e) {
		const term = e.target.value;
		this.setState({ searchTerm: term });
	}

	render() {
		const { open, project, onSecondarySubmit, onRequestClose, ...rest } = this.props;
		const { sendingProjectImageUpdate, success, errorUpdatingProjectImage, searchTerm, newImageSelected } = this.state;

		const projectName = project !== null ? project.name : 'Project xx';
		const isSubmitButtonDisabled = sendingProjectImageUpdate;

		const newTerm = searchTerm.length > 2 ? searchTerm : '';

		const queryVariables = {
			term: newTerm,
			pageSize: 9
		};

		return (
			<Modal
				passiveModal={true}
				open={open}
				modalHeading="Select an image"
				modalLabel={projectName}
				onRequestClose={onRequestClose}
				{...rest}
			>
				<ErrorBoundaryQuery query={AVAILABLE_PROJECT_IMAGE_LIBRARY} variables={queryVariables}>
					{({ loading, error, data }) => {

						if (loading) {
							return (
								<>
									<Search
										id="images-searcher"
										style={{ marginBottom: '16px'}}
										value={searchTerm}
										onChange={(e) => this.onSearchTerm(e)}
										autoFocus
									/>
									<div className={classes.libraryContainer}>
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
										<SkeletonText className={classes.libraryImage} style={{ width: '200px', height: '150px' }} />
									</div>
								</>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const images = data.availableProjectImageLibrary || [];

						return (
							<Mutation mutation={UPDATE_MY_PROJECT}>
								{(updateMyProjectMutation, { error }) => (
									<>
										{error &&
										<InlineNotification
											className={classes.notification}
											kind="error"
											lowContrast
											hideCloseButton
											title=""
											subtitle={(error.graphQLErrors && error.graphQLErrors.length)
												? error.graphQLErrors[0].message
												: (error.networkError)
													? error.networkError.message
													: error.message}
										/>
										}
										<div className={classes.modalContent}>
											{newImageSelected
												?	<>
													{this.displaySelectedImage()}
													<div className={classes.btnBox}>
														<Button
															type="submit"
															className={classes.btn}
															onClick={onSecondarySubmit}
															kind="secondary"
															disabled={sendingProjectImageUpdate || success}>
																Cancel
														</Button>
														{sendingProjectImageUpdate || success ? (
															<InlineLoading
																style={{ margin: '0 1rem', width: '200px' }}
																description={success ? 'Project image updated successfully' : 'Updating project image...'}
																status={success ? 'finished' : 'active'}
															/>
														) : errorUpdatingProjectImage
															? 	<InlineLoading
																style={{ margin: '0 1rem', width: '200px' }}
																description={'Error updating project image'}
																status={'error'}
															/>
															:	(
																<Button
																	className={classes.btn}
																	disabled={isSubmitButtonDisabled}
																	onClick={(e) => this.updateProjectImage(e, project.id, updateMyProjectMutation)}>
																		Update project image
																</Button>
															)}
													</div>
												</>
												:	<>
													<Search
														id="images-searcher"
														value={searchTerm}
														placeholder={'Search images'}
														spellCheck={false}
														onChange={(e) => this.onSearchTerm(e)}
														autoFocus
													/>

													<div className={classes.unsplashAttribution}>
														Images from <a 
															href='https://unsplash.com/?utm_source=lastbasic&utm_medium=referral'
															target='_blank'
															rel='noreferrer'
														>Unsplash</a>
													</div>

													{this.displayImageLibrary(images)}
												</>
											}
										</div>
									</>
								)}
							</Mutation>
						);
					}}
				</ErrorBoundaryQuery>
			</Modal>
		);
	}
}

UpdateProjectImageModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

UpdateProjectImageModal.defaultProps = {
	open: false,
	project: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
};

export default UpdateProjectImageModal;
