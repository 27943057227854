import React, { Component, Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Content } from 'carbon-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HowItWorksPage from '../../pages/clients/how-it-works';
import ClientProjectPage from '../../pages/clients/project';
import AccountPage from '../../pages/shared/account/account';
import FilesPage from '../../pages/clients/files';
import MakerPersonalInformation from '../../pages/clients/internal-pages/personal-information';
import Notifications from '../../pages/shared/account/notifications';
import Security from '../../pages/shared/account/security';
import ClientProjects from '../../pages/clients/internal-pages/client-projects';
import ProjectPage from '../../pages/shared/project/project-view';
import UnlockProjectPage from '../../pages/shared/project/unlock-project';
import ValidationPage from '../shared/project/validation';
import ContestPage from '../../pages/shared/contest/contest-view';
import ProposalPage from '../../pages/shared/proposal/proposal-view';
import NewProjectPage from './new-project/new-project';
import StartPage from '../../pages/clients/start';
import Marketplace from '../../pages/shared/marketplace/marketplace-main-view';
import ServiceDetails from '../../pages/shared/marketplace/service-view';
import ReferralPage from '../../pages/shared/referral/referral';
import NotFoundPage from '../../pages/shared/errors/not-found';

import ForceReload from '../../components/route/force-reload';
import Navbar from '../../components/navbar';
import HubspotMeetingScript from '../../components/private-scripts/hubspot-meeting';

import { projectStorage, auth } from './../../utils';

class ClientsPage extends Component {

	projectBuilderSelector() {
		const userData = auth.getStoredTokens()?.user;
		const userId = userData?.id;
		
		if (userId && userId.charCodeAt(userId.length - 1) % 2 === 1) {
			return  <Navigate to="/new-project" />;
		}
		return <Navigate to="/project-builder" />;
	}

	render() {
		const { userData: { user } } = this.props;
		const { maker } = user;
		const { ownedProjectIds } = maker;

		if (!projectStorage.getProject() && ownedProjectIds && ownedProjectIds.length > 1) {
			return <Navigate to="/projects" />;
		}

		return (
			<Fragment>
				<Navbar />
				<Content>
					<Routes>
						<Route path="/" element={<ClientProjects />} />
						<Route path="/getting-started" element={<StartPage />} />
						<Route path="/how-it-works/inventors" element={<HowItWorksPage />} />
						<Route path="/new/project" element={this.projectBuilderSelector()} />
						<Route path="/project-builder" element={<ForceReload />} />
						<Route path="/project-builder/:id" element={<ForceReload />} />
						<Route path="/projects" element={<ClientProjects />} />
						<Route path="/project/:id/old_version=true" element={<ClientProjectPage />} />
						<Route path="/project/:id" element={<ProjectPage />} />
						<Route path="/project/:id/unlock" element={<UnlockProjectPage />} />
						<Route path="/project/:id/validation" element={<ValidationPage />} />
						<Route path="/account/settings" element={<AccountPage />} />
						<Route path="/account/personal-information" element={<MakerPersonalInformation />} />
						<Route path="/account/notifications" element={<Notifications />} />
						<Route path="/account/security" element={<Security />} />
						<Route path="/contest/:id" element={<ContestPage />} />
						<Route path="/project/:projectId/contest/:id" element={<ContestPage />} />
						<Route path="/proposal/:proposalId" element={<ProposalPage />} />
						<Route path="/project/:projectId/contest/:contestId/proposal/:proposalId" element={<ProposalPage />} />
						<Route path="/files" element={<FilesPage />} />
						<Route path="/invites" element={<ReferralPage />} />
						<Route path="/marketplace" element={<Marketplace />} />
						<Route path="/marketplace/:serviceName" element={<ServiceDetails />} />
						<Route path="/new-project" element={<NewProjectPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
					<HubspotMeetingScript />
				</Content>
			</Fragment>
		);
	}
}

ClientsPage.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ClientsPage);
