import { Camera16, TrashCan16 } from '@carbon/icons-react';
import { Mutation } from '@apollo/client/react/components';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import { Button, InlineLoading } from 'carbon-components-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withRouter from '../../../components/route/with-router';
import {
	UPLOAD_PROFILE_AVATAR,
	REMOVE_PROFILE_AVATAR
} from '../../../providers/mutations';
import { serverAPI, userInfo } from '../../../utils';

import classes from './account.module.scss';

class AccountPage extends Component {
	constructor(props) {
		super(props);
		this.inputFileRef = React.createRef();

		this.state = {
			hasAvatar: false,
			userInfo: userInfo.getUserInfo(),
			uploadingAvatar: false,
		};
	}

	componentDidMount() {
		document.title = 'Account - LastBasic';
	}

	getUserNameInitials = () => {
		const { userData: { user } } = this.props;
		return user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
	};

	getUserFullname = () => {
		const { userData: { user } } = this.props;
		return (user.firstName || '') + ' ' + (user.lastName || '');
	};

	async onUploadAvatar(e, userId, uploadProfileAvatar) {
		const { target: { validity, files: [file] } } = e;

		if (!validity.valid) return;

		this.setState({
			uploadingAvatar: true
		}, async () => {
			const result = await uploadProfileAvatar({
				variables: { file }
			});

			if (result && result.data && result.data.updateProfileAvatar) {
				this.setState({
					uploadingAvatar: false,
					hasAvatar: true,
				});

				const userData = userInfo.getUserInfo();
				userInfo.storeUserInfo({ ...userData, hasAvatar: true });
				window.location.reload();
			} else {
				this.setState({
					uploadingAvatar: false,
				});
			}
		});
	}

	getAvatarUrl(userId) {
		return serverAPI.getAvatarUrl(userId);
	}

	async onDeleteAvatar(e, onDeleteAvatar) {
		this.setState({
			hasAvatar: false,
		});

		try {
			await onDeleteAvatar();
		} finally {
			const userData = userInfo.getUserInfo();
			userInfo.storeUserInfo({ ...userData, hasAvatar: false });
			window.location.reload();
		}
	}

	render() {
		const { userData: {user} } = this.props;
		const { expert } = user;
		const { userInfo, uploadingAvatar, hasAvatar } = this.state;

		const userAvatar = userInfo && userInfo.hasAvatar;

		return (
			<div className={classes.profileContainer}>
				<h3 className={classnames(classes.title, classes.titleSpace)}>Account</h3>
				<div className={classes.section} style={{ marginTop: '0' }}>
					<h4 className={classes.secondaryTitle}>Profile</h4>
					<div className={classes.profileInfoContent}>
						<div className={classes.avatarContainer}>
							<div style={{ position: 'relative'}}>
								{userAvatar || hasAvatar
									?	<img src={this.getAvatarUrl(user.id)} className={classes.avatarImg} alt='Profile picture' />
									:	uploadingAvatar
										?	<div className={classes.uploadingAvatar}>
											<InlineLoading description="Uploading..." status='active' style={{justifyContent: 'center'}} />
										</div>
										:	<div className={classes.profileAvatar}>
											{this.getUserNameInitials()}
										</div>
								}
								{userAvatar || hasAvatar
									?	<Mutation mutation={REMOVE_PROFILE_AVATAR}>
										{(onDeleteAvatar) => {
											return (
												<Button
													hasIconOnly
													renderIcon={TrashCan16}
													tooltipAlignment="center"
													tooltipPosition="bottom"
													iconDescription="Remove profile picture"
													size='small'
													className={classes.uploadAvatarBtn}
													onClick={(e) => this.onDeleteAvatar(e, onDeleteAvatar)}
												/>
											);
										}}
									</Mutation>
									:	<Button
										hasIconOnly
										renderIcon={Camera16}
										tooltipAlignment="center"
										tooltipPosition="bottom"
										iconDescription="Upload a profile picture"
										size='small'
										kind='secondary'
										className={classes.uploadAvatarBtn}
										onClick={() => this.inputFileRef.current.click()}
									/>
								}
							</div>
							<Mutation mutation={UPLOAD_PROFILE_AVATAR}>
								{(uploadProfileAvatar) => {
									return (
										<input
											ref={this.inputFileRef}
											onChange={(e) => this.onUploadAvatar(e, user.id, uploadProfileAvatar)}
											type="file"
											accept=".png, .jpg, .jpeg"
											id="upload-avatar"
											name="upload-avatar"
											style={{ display: 'none' }}
										/>
									);
								}}
							</Mutation>
							<h4>{this.getUserFullname()}</h4>
						</div>
					</div>
				</div>
				<div className={classes.section}>
					<h4 className={classes.secondaryTitle}>Settings</h4>
					<div className={classes.blocksListContainer}>
						<div className={classes.sectionContent}>
							<Link to="/account/personal-information" className={classes.box}>
								<BadgeRoundedIcon sx={{ width: '28px', height: '28px'}} style={{fill: '#313131'}} />
								<div>
									<h5 className={classes.blockTitle}>Personal info</h5>
									<p>Provide your personal details</p>
								</div>
							</Link>
						</div>
						{expert &&
							<div className={classes.sectionContent}>
								<Link to="/account/payments" className={classes.box}>
									<PaymentsRoundedIcon sx={{ width: '28px', height: '28px'}} style={{fill: '#313131'}} />
									<div>
										<h5 className={classes.blockTitle}>Payments</h5>
										<p>Control your financials</p>
									</div>
								</Link>
							</div>
						}
						<div className={classes.sectionContent}>
							<Link to="/account/notifications" className={classes.box}>
								<CampaignRoundedIcon sx={{ width: '28px', height: '28px'}} style={{fill: '#313131'}} />
								<div>
									<h5 className={classes.blockTitle}>Notifications</h5>
									<p>Choose your notifications preferences</p>
								</div>
							</Link>
						</div>
						<div className={classes.sectionContent}>
							<Link to="/account/security" className={classes.box}>
								<ShieldRoundedIcon sx={{ width: '28px', height: '28px'}} style={{fill: '#313131'}} />
								<div>
									<h5 className={classes.blockTitle}>Security</h5>
									<p>Update your password</p>
								</div>
							</Link>
						</div>
					</div>
				</div>
				<div className={classes.bottomActions}>
					<h5 style={{marginRight: '12px'}}>Follow us</h5>
					<div className={classes.blocksListContainer} style={{gap: '8px'}}>
						<FacebookIcon
							className={classes.followIcon}
							sx={{ width: '20px', height: '20px'}}
							onClick={() => window.open('https://www.facebook.com/LastBasic/', '_blank')}
						/>
						<InstagramIcon
							className={classes.followIcon}
							sx={{ width: '20px', height: '20px'}}
							onClick={() => window.open('https://www.instagram.com/lastbasic_/', '_blank')}
						/>
						<TwitterIcon
							className={classes.followIcon}
							sx={{ width: '20px', height: '20px'}}
							onClick={() => window.open('https://twitter.com/lastbasic', '_blank')}
						/>
						<YouTubeIcon
							className={classes.followIcon}
							sx={{ width: '20px', height: '20px'}}
							onClick={() => window.open('https://www.youtube.com/channel/UCU5RtX5HKWFx6JrVa6cm4Tw', '_blank')}
						/>
						<LinkedInIcon
							className={classes.followIcon}
							sx={{ width: '20px', height: '20px'}}
							onClick={() => window.open('https://www.linkedin.com/company/lastbasic/', '_blank')}
						/>
					</div>
				</div>
			</div>
		);
	}
}

AccountPage.defaultProps = {
	userData: {},
};

AccountPage.propTypes = {
	userData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(AccountPage));
