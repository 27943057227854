import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SkeletonText, InlineNotification, InlineLoading } from 'carbon-components-react';
import { Notification20, Information16 } from '@carbon/icons-react';

import { GET_PROJECT_UPDATES } from '../../../../providers/queries';
import KEYS from '../../../../glossary';
import { PROJECT } from '../../../../constants';
import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import classes from './blocks.module.scss';
import { dates } from '../../../../utils';

class ProjectUpdates extends Component {
	constructor(props) {
		super(props);

		const { page, pageSize } = this.props;

		this.state = {
			page,
			pageSize,
			filterBy: null,
			updatesList: [],
		};
	}

	loadMoreUpdates(fetchMore, projectId, keyName) {
		const { pageSize } = this.state;
		this.setState({onLoadingMoreProjects: true});

		const newPageSize = pageSize + 3;
		const moreItems = fetchMore({
			variables: {
				id: projectId,
				pageSize: newPageSize,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				if (!fetchMoreResult[keyName] || fetchMoreResult[keyName].updates.length === 0) {
					return previousResult;
				}

				const newUpdates = fetchMoreResult[keyName].updates;
				this.setState({
					updatesList: newUpdates,
					pageSize: newPageSize,
					onLoadingMoreUpdates: false,
				});

				const allUpdates = [previousResult.length > 0 ? previousResult[keyName].updates : {}, ...newUpdates];
				const totalCount = fetchMoreResult[keyName].totalCount;

				return {
					[keyName]: {
						__typename: 'UpdatesList',
						updates: allUpdates,
						totalCount,
					}
				};
			}
		}).catch((err) => {
			// Ignore error
			console.log('Error: ', err);
		});
		return moreItems;
	}

	render() {
		const { projectId } = this.props;
		const { updatesList, onLoadingMoreUpdates } = this.state;

		return (
			<ErrorBoundaryQuery query={GET_PROJECT_UPDATES} variables={{ id: projectId }}>
				{({ loading, error, data, fetchMore }) => {
					if (loading) {
						return (
							<div className={classes.tabContentContainer}>
								<SkeletonText className={classes.subtitle} style={{ width: '300px' }} />
								<div className={classes.contentBlock}>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<div className={classes.tabContentContainer}>
								<InlineNotification
									className={classes.notification}
									kind="error"
									title=""
									lowContrast
									hideCloseButton
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							</div>
						);
					}

					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const project = data && data.project;
					const updates = project.updates;
					const totalCount = (updates && updates.length) || 0;

					const sortedUpdates = updates.slice(-4).sort((a, b) => b.createdAt - a.createdAt);
					const completeList = (updatesList.length < totalCount) ? sortedUpdates : updatesList;

					const hasMoreUpdates = updatesList.length < totalCount;
					const areAllUpdatesListed = totalCount && totalCount > 4;

					return (
						<div className={classes.tabContentContainer}>
							<div className={classes.materialsContent}>
								<div className={classes.contentBlock}>
									{completeList && (
										<div className={classes.updatesContentContainer}>
											{completeList.map((update, i) => (
												<div className={classes.updateBox} key={i}>
													<div className={classes.naming}>
														<Notification20 />
														<p>
															{(update.details !== null && Object.keys(update.details).includes('title')) ? update.details.title : PROJECT.getUpdateString(update.type)}
															{update.details !== null
																?   Object.keys(update.details).includes('title')
																	?   `: ${update.details.description}`
																	:   `: ${PROJECT.getUpdatesDetails(Object.values(update.details)[0])}`
																:   ''
															}
														</p>
													</div>
													<div className={classes.actions}>
														<strong>{dates.formattedNewDate(update.createdAt)}</strong>
													</div>
												</div>
											))}
										</div>
									)}
									{totalCount === 0 && (
										<div className={classes.updates}>
											<Information16 />
											<small>{KEYS.project_updates_empty}</small>
										</div>
									)}
									<div className={classes.loadMore}>
										{hasMoreUpdates ? (
											(onLoadingMoreUpdates)
												?	<InlineLoading description='Loading more updates...' />
												:	(totalCount > 3 ) &&
												<span
													className={classes.link}
													onClick={() => this.loadMoreUpdates(fetchMore, projectId, keyName)}
												>
													Load more
												</span>
										) : (areAllUpdatesListed) &&
											<>
												<small>
													{/* eslint-disable-next-line */}
													Yay 🎉 You have seen it all!
												</small>
												<span
													className={classes.link}
													style={{marginLeft: '16px'}}
													onClick={() => this.setState({updatesList: sortedUpdates})}
												>
													Hide updates
												</span>
											</>
										}
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ProjectUpdates.defaultProps = {
	projectId: '',
	page: 0,
	pageSize: 6,
};

ProjectUpdates.propTypes = {
	projectId: PropTypes.string.isRequired,
	page: PropTypes.number,
	pageSize: PropTypes.number,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectUpdates);
