import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withRouter from '../../../../components/route/with-router';
import { serverAPI } from '../../../../utils';

class GetSketchPage extends Component {
	render() {
		const { match, userData: { token } } = this.props;
		const { id } = match.params;

		window.location = serverAPI.getPublicSketchUrl(id, token.accessToken); 

		return (<div>Downloading sketch...</div>);
	}
}

GetSketchPage.propTypes = {
	match: PropTypes.object.isRequired,
	userData: PropTypes.object,
};

GetSketchPage.defaultProps = {
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(GetSketchPage));
