import KEYS from './../glossary';
import { projectFeasibility } from './../utils';

export default {
	PRODUCT_SIZE: {
		MINI: 'mini',
		SMALL: 'small',
		MEDIUM: 'medium',
		BIG: 'big',
	},
	PRODUCT_ENVIRONMENT: {
		DOMESTIC: 'domestic',
		INDUSTRIAL: 'industrial',
		HOSPITAL: 'hospital',
		OUTDOOR: 'outdoor',
	},
	PRODUCT_COMPLEXITY: {
		ELECTRONIC_HARDWARE: 'electronic_hardware',
		ELECTRONIC_CASING: 'hardware_and_casing',
		ELECTRONIC_COMPLEX_CASING: 'electronics_and_complex_casing',
	},
	STATE: {
		DRAFT: 'draft',
		CLIENT_ONBOARDING: 'client_onboarding',
		PROJECT_SET_UP: 'project_set_up',
		SKETCH_CONTEST: 'sketch_contest',
		MODEL_CONTEST: 'model_contest',
		ELECTRICAL_CONTEST: 'electrical_contest',
		MECHANICAL_CONTEST: 'mechanical_contest',
		PROTOTYPING_CONTEST: 'prototyping_contest',
		CLOSED: 'closed',
	},
	CONTEST_TYPES: {
		SKETCH: 'sketch',
		MODEL: 'model',
		ELECTRICAL: 'electrical',
		MECHANICAL: 'mechanical',
		PROTOTYPING: 'prototyping',
	},
	UPDATE_TYPES: {
		PROJECT_CREATED: 'project_created',
		PROJECT_ENDED: 'project_ended',
		CONTEST_STARTED: 'contest_started',
		CONTEST_CLOSED: 'contest_closed',
		WINNER_SELECTED: 'winner_selected',
		PROJECT_STATE_CHANGED: 'project_state_changed',
		CUSTOM: 'custom',
	},
	SPECIAL: {
		USER_EXPERIENCE: 'user_experience',
		APPEARANCE: 'appearance',
		PERFORMANCE: 'performance',
		QUALITY: 'quality',
	},
	DESIGN_DETAILS: {
		MORE_DETAILS: 'more_details',
		GIVE_EXPERT_FREEDOM: 'expert_freedom',
	},
	PRODUCT_CONTROL: {
		NONE: 'none',
		LOCALLY: 'locally',
		REMOTELY: 'remotely',
	},
	PRODUCT_POWER: {
		REPLACEABLE_BATTERY: 'replaceable_battery',
		RECHARGEABLE_BATTERY: 'rechargeable_battery',
		ELECTRICAL_NETWORK: 'electrical_network',
	},
	BATTERY_LIFE: {
		LOW: 'low',
		MEDIUM: 'medium',
		HIGH: 'high',
	},
	CLIENT_REVIEW_TYPES: {
		TEXT: 'text',
		RATING: 'rating',
	},
	FEASIBILITY: {
		PENDING: 'pending',
		YES: 'yes',
		NO: 'no',
	},
	FEASIBILITY_REASON: {
		NONE: 'none',
		NOT_FOR_US_NOW: 'not_for_us_now',
		TOO_COMPLEX: 'too_complex',
		NOT_ENOUGH_INFO: 'not_enough_info',
		NOT_FOR_LASTBASIC: 'not_for_lastbasic',
	},
	PROJECT_FEASIBILITY_STATE: {
		DRAFT: 'Draft',
		PENDING_FEASIBILITY: 'Pending feasibility',
		PENDING_PAYMENT: 'Pending payment',
		NON_FEASIBLE: 'Not feasible',
		ACTIVE: 'Active',
	},
	getProjectStates: function () {
		return {
			DRAFT: this.PROJECT_FEASIBILITY_STATE.DRAFT,
			PENDING_FEASIBILITY: this.PROJECT_FEASIBILITY_STATE.PENDING_FEASIBILITY,
			PENDING_PAYMENT: this.PROJECT_FEASIBILITY_STATE.PENDING_PAYMENT,
			NON_FEASIBLE: this.PROJECT_FEASIBILITY_STATE.NON_FEASIBLE,
			ACTIVE: this.PROJECT_FEASIBILITY_STATE.ACTIVE,
		};
	},
	getContestStates: function () {
		return [
			this.STATE.SKETCH_CONTEST,
			this.STATE.MODEL_CONTEST,
			this.STATE.ELECTRICAL_CONTEST,
			this.STATE.MECHANICAL_CONTEST,
			this.STATE.PROTOTYPING_CONTEST,
		];
	},
	getContestTypes: function () {
		return [
			this.CONTEST_TYPES.SKETCH,
			this.CONTEST_TYPES.MODEL,
			this.CONTEST_TYPES.ELECTRICAL,
			this.CONTEST_TYPES.MECHANICAL,
			this.CONTEST_TYPES.PROTOTYPING,
		];
	},
	getFilteredContestStatesByContestType: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return null;
			case this.CONTEST_TYPES.MODEL:
				return {
					SKETCH_CONTEST: this.STATE.SKETCH_CONTEST,
					ELECTRICAL_CONTEST: this.STATE.ELECTRICAL_CONTEST
				};
			case this.CONTEST_TYPES.ELECTRICAL:
				return {
					SKETCH_CONTEST: this.STATE.SKETCH_CONTEST,
					MODEL_CONTEST: this.STATE.MODEL_CONTEST
				};
			case this.CONTEST_TYPES.MECHANICAL:
				return {
					SKETCH_CONTEST: this.STATE.SKETCH_CONTEST,
					MODEL_CONTEST: this.STATE.MODEL_CONTEST,
					ELECTRICAL_CONTEST: this.STATE.ELECTRICAL_CONTEST
				};
			case this.CONTEST_TYPES.PROTOTYPING:
				return {
					SKETCH_CONTEST: this.STATE.SKETCH_CONTEST,
					MODEL_CONTEST: this.STATE.MODEL_CONTEST,
					ELECTRICAL_CONTEST: this.STATE.ELECTRICAL_CONTEST,
					MECHANICAL_CONTEST: this.STATE.MECHANICAL_CONTEST
				};
			default:
				return null;
		}
	},
	getContestTypeByState: function (state) {
		switch (state) {
			case this.STATE.SKETCH_CONTEST:
				return this.CONTEST_TYPES.SKETCH;
			case this.STATE.MODEL_CONTEST:
				return this.CONTEST_TYPES.MODEL;
			case this.STATE.ELECTRICAL_CONTEST:
				return this.CONTEST_TYPES.ELECTRICAL;
			case this.STATE.MECHANICAL_CONTEST:
				return this.CONTEST_TYPES.MECHANICAL;
			case this.STATE.PROTOTYPING_CONTEST:
				return this.CONTEST_TYPES.PROTOTYPING;
			default:
				return null;
		}
	},
	getStateByPhase: function (project, phase) {
		return project.states[phase];
	},
	getStateByContestType: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return this.STATE.SKETCH_CONTEST;
			case this.CONTEST_TYPES.MODEL:
				return this.STATE.MODEL_CONTEST;
			case this.CONTEST_TYPES.ELECTRICAL:
				return this.STATE.ELECTRICAL_CONTEST;
			case this.CONTEST_TYPES.MECHANICAL:
				return this.STATE.MECHANICAL_CONTEST;
			case this.CONTEST_TYPES.PROTOTYPING:
				return this.STATE.PROTOTYPING_CONTEST;
			default:
				return null;
		}
	},
	getStateByContestTypeShort: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return this.stateToString(this.STATE.SKETCH_CONTEST);
			case this.CONTEST_TYPES.MODEL:
				return this.stateToString(this.STATE.MODEL_CONTEST);
			case this.CONTEST_TYPES.ELECTRICAL:
				return this.stateToString(this.STATE.ELECTRICAL_CONTEST);
			case this.CONTEST_TYPES.MECHANICAL:
				return this.stateToString(this.STATE.MECHANICAL_CONTEST);
			case this.CONTEST_TYPES.PROTOTYPING:
				return this.stateToString(this.STATE.PROTOTYPING_CONTEST);
			default:
				return 'Unknown contest';
		}
	},
	getContestByType: function (project, contestType) {
		return project.contests.find(c => c.type === contestType);
	},
	getContestByState: function (project, state) {
		const contestType = this.getContestTypeByState(state);
		if (!contestType) return null;
		return this.getContestByType(project, contestType);
	},
	getCurrentContest: function (project) {
		return this.getContestByState(project, project.state);
	},
	getContestRound: function (contest) {
		const contestRound = contest && contest.rounds.find(round => {
			return round;
		});
		return contestRound;
	},
	getUpdateString: function (updateType) {
		switch (updateType) {
			case this.UPDATE_TYPES.PROJECT_CREATED:
				return 'Project created';
			case this.UPDATE_TYPES.PROJECT_ENDED:
				return 'Project ended';
			case this.UPDATE_TYPES.CONTEST_STARTED:
				return 'Contest started';
			case this.UPDATE_TYPES.CONTEST_CLOSED:
				return 'Contest closed';
			case this.UPDATE_TYPES.WINNER_SELECTED:
				return 'Winner selected';
			case this.UPDATE_TYPES.PROJECT_STATE_CHANGED:
				return 'Project phase changed';
			case this.UPDATE_TYPES.CUSTOM:
				return 'Other';
			default:
				return 'N/D';
		}
	},
	getUpdatesDetails: function (updateDetail) {
		switch (updateDetail) {
			case this.STATE.CLIENT_ONBOARDING:
				return 'Client Onboarding';
			case this.CONTEST_TYPES.SKETCH:
				return 'Sketch';
			case this.STATE.SKETCH_CONTEST:
				return 'Sketch';
			case this.CONTEST_TYPES.MODEL:
				return '3D Modeling';
			case this.STATE.MODEL_CONTEST:
				return '3D Modeling';
			case this.CONTEST_TYPES.ELECTRICAL:
				return 'Electrical';
			case this.STATE.ELECTRICAL_CONTEST:
				return 'Electrical';
			case this.CONTEST_TYPES.MECHANICAL:
				return 'Mechanical';
			case this.STATE.MECHANICAL_CONTEST:
				return 'Mechanical';
			case this.CONTEST_TYPES.PROTOTYPING:
				return 'Prototyping';
			case this.STATE.PROTOTYPING_CONTEST:
				return 'Prototyping';
			case this.STATE.CLOSED:
				return 'Closed';
			default:
				return 'N/D';
		}
	},
	stateToString: function (state) {
		switch (state) {
			case this.STATE.CLIENT_ONBOARDING:
				return 'Client Onboarding';
			case this.STATE.PROJECT_SET_UP:
				return 'Project Set Up';
			case this.STATE.SKETCH_CONTEST:
				return 'Sketch Contest';
			case this.STATE.MODEL_CONTEST:
				return '3D Modeling Contest';
			case this.STATE.ELECTRICAL_CONTEST:
				return 'Electrical Engineer Contest';
			case this.STATE.MECHANICAL_CONTEST:
				return 'Mechanical Engineer Contest';
			case this.STATE.PROTOTYPING_CONTEST:
				return 'Prototype Contest';
			case this.STATE.CLOSED:
				return 'Closed';
			default:
				return 'Unknown';
		}
	},
	stateToStringShort: function (state) {
		switch (state) {
			case this.STATE.DRAFT:
				return 'Draft';
			case this.STATE.CLIENT_ONBOARDING:
				return 'Client Onboarding';
			case this.STATE.PROJECT_SET_UP:
				return 'Project Set Up';
			case this.STATE.SKETCH_CONTEST:
				return 'Sketch';
			case this.STATE.MODEL_CONTEST:
				return '3D Modeling';
			case this.STATE.ELECTRICAL_CONTEST:
				return 'Electrical Engineering';
			case this.STATE.MECHANICAL_CONTEST:
				return 'Mechanical Engineering';
			case this.STATE.PROTOTYPING_CONTEST:
				return 'Prototyping';
			case this.STATE.CLOSED:
				return 'Finished';
			default:
				return 'Unknown';
		}
	},
	stateToStatus: function (state) {
		switch (state) {
			case this.STATE.DRAFT:
				return 'Draft';
			case this.STATE.CLIENT_ONBOARDING:
				return 'Onboarding';
			case this.STATE.PROJECT_SET_UP:
				return 'Setting up';
			case this.STATE.SKETCH_CONTEST:
				return 'Sketch';
			case this.STATE.MODEL_CONTEST:
				return '3D Modeling';
			case this.STATE.ELECTRICAL_CONTEST:
				return 'Electrical Engineering';
			case this.STATE.MECHANICAL_CONTEST:
				return 'Mechanical Engineering';
			case this.STATE.PROTOTYPING_CONTEST:
				return 'Prototyping';
			case this.STATE.CLOSED:
				return 'Finished';
			default:
				return 'Unknown';
		}
	},
	// getCurrentRound: function (contest) {
	// 	const today = new Date();
	// 	const currentRound = contest && contest.rounds.find(r => {
	// 		return r.startDate <= today && r.endDate >= today;
	// 	});
	// 	return currentRound;
	// },
	// isContestAvailable: function (contest) {
	// 	const currentRound = this.getCurrentRound(contest);
	// 	return !!currentRound;
	// },
	isContestCardApprovedByClient: function (contest) {
		const contestRound = contest && contest.rounds.find(round => {
			return round.card.clientConfirmed === 'yes';
		});
		return !!contestRound;
	},
	hasContestPassed: function (contest) {
		const today = new Date();
		const contestRound = contest && contest.rounds.find(round => {
			return round.endDate <= today;
		});
		return !!contestRound;
	},
	getSizeToString: function (size) {
		switch (size) {
			case this.PRODUCT_SIZE.MINI:
				return 'Mini';
			case this.PRODUCT_SIZE.SMALL:
				return 'Small';
			case this.PRODUCT_SIZE.MEDIUM:
				return 'Medium';
			case this.PRODUCT_SIZE.BIG:
				return 'Big';
			default:
				return 'Medium';
		}
	},
	getProjectSizeDetailed: function (size) {
		switch (size) {
			case this.PRODUCT_SIZE.MINI:
				return 'Mini size, contained in a cube of 10cm (4 in) side';
			case this.PRODUCT_SIZE.SMALL:
				return 'Small size, contained in a cube of 15cm (6 in) side';
			case this.PRODUCT_SIZE.MEDIUM:
				return 'Medium size, contained in a cube of 20cm (8 in) side';
			case this.PRODUCT_SIZE.BIG:
				return 'Big size, doesnt fit in a cube of 20cm (8 in) side';
			default:
				return 'Medium size, contained in a cube of 20cm (8 in) side';
		}
	},
	getEnvironmentToString: function (environment) {
		switch (environment) {
			case this.PRODUCT_ENVIRONMENT.DOMESTIC:
				return 'Domestic';
			case this.PRODUCT_ENVIRONMENT.INDUSTRIAL:
				return 'Industrial';
			case this.PRODUCT_ENVIRONMENT.HOSPITAL:
				return 'Medical';
			case this.PRODUCT_ENVIRONMENT.OUTDOOR:
				return 'Outdoor';
			default:
				return 'Domestic';
		}
	},
	getProjectComplexity: function (complexity) {
		switch (complexity) {
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_HARDWARE:
				return 'Just electronic hardware and no casing.';
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_CASING:
				return 'Electronic hardware and a simple casing.';
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_COMPLEX_CASING:
				return 'Electronic hardware and a complex casing.';
			default:
				return 'Not specified.';
		}
	},
	getProjectComplexityShort: function (complexity) {
		switch (complexity) {
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_HARDWARE:
				return 'Low';
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_CASING:
				return 'Medium';
			case this.PRODUCT_COMPLEXITY.ELECTRONIC_COMPLEX_CASING:
				return 'High';
			default:
				return 'N/S';
		}
	},
	getProjectMEComplexity: function (complexity) {
		switch (complexity) {
			case 'assemblies':
				return 'Assemblies';
			case 'simple_structures':
				return 'Simple structures';
			default:
				return 'N/S';
		}
	},
	getFeasibilityRejectReason: function (feasibilityReason) {
		switch (feasibilityReason) {
			case this.FEASIBILITY_REASON.NONE:
				return KEYS.project_feasibility_non_feasible_message_option_A;
			case this.FEASIBILITY_REASON.NOT_ENOUGH_INFO:
				return KEYS.project_feasibility_non_feasible_message_option_A;
			case this.FEASIBILITY_REASON.NOT_FOR_LASTBASIC:
				return KEYS.project_feasibility_non_feasible_message_option_B;
			case this.FEASIBILITY_REASON.NOT_FOR_US_NOW:
				return KEYS.project_feasibility_non_feasible_message_option_A;
			case this.FEASIBILITY_REASON.TOO_COMPLEX:
				return KEYS.project_feasibility_non_feasible_message_option_B;
			default:
				return KEYS.project_feasibility_non_feasible_message_option_A;
		}
	},
	isFirstFeasibleProject: function (projects) {
		const projectsByLastUpdated = projects.sort((a, b) => b.updatedAt - a.updatedAt);

		for (const project of projectsByLastUpdated) {
			const projectIsFeasible = project.feasibility === this.FEASIBILITY.YES;
			const projectIsPendingPayment = projectIsFeasible && !project.paid;

			if (projectIsPendingPayment) {
				projectFeasibility.storeProjectFeasibility(true);
				return true;
			}
		}
		projectFeasibility.storeProjectFeasibility(false);
		return false;
	},
	areProjectsAvailableForAddons: function (projects) {
		for (const project of projects) {
			const projectIsFeasible = project.feasibility === this.FEASIBILITY.YES;
			const projectIsPaid = projectIsFeasible && project.paid;
			const projectIsFinished = project.state === this.STATE.CLOSED;
			const projectIsInActivePhase = !projectIsFinished && project.state !== this.STATE.CLIENT_ONBOARDING;

			if (projectIsPaid && (projectIsInActivePhase && !projectIsFinished)) {
				return true;
			}
		}
		return false;
	},
	isProjectsAvailableForAddonFromOnboardingPhase: function (projects) {
		for (const project of projects) {
			const projectIsFeasible = project.feasibility === this.FEASIBILITY.YES;
			const projectIsPaid = projectIsFeasible && project.paid;
			const projectIsOnOnboarding = project.state == this.STATE.CLIENT_ONBOARDING;
			const projectIsFinished = project.state === this.STATE.CLOSED;
			const projectIsInActivePhase = !projectIsFinished && project.state !== this.STATE.CLIENT_ONBOARDING;

			if (projectIsPaid && (projectIsOnOnboarding || projectIsInActivePhase)) {
				return true;
			}
		}
		return false;
	},
	isProjectsAvailableForAddonFromMechanicalPhase: function (projects) {
		for (const project of projects) {
			const projectIsFeasible = project.feasibility === this.FEASIBILITY.YES;
			const projectIsPaid = projectIsFeasible && project.paid;
			const projectIsFinished = project.state === this.STATE.CLOSED;
			const projectIsInActivePhase = !projectIsFinished && project.state !== this.STATE.CLIENT_ONBOARDING;
			const projectIsActiveAndInMechanicalPhase = projectIsInActivePhase && (project.state === this.STATE.MECHANICAL_CONTEST || project.state === this.STATE.PROTOTYPING_CONTEST);

			if (projectIsPaid && projectIsActiveAndInMechanicalPhase) {
				return true;
			}
		}
		return false;
	},
	getProjectLobbyStage: function (hoursFromFeasibility, paid) {
		if (paid === true) {
			return 'el_dorado';
		} else if (hoursFromFeasibility > 0 && hoursFromFeasibility < 48) {
			return 'stage_1';
		} else if (hoursFromFeasibility > 47 && hoursFromFeasibility < 96) {
			return 'stage_2';
		} else if (hoursFromFeasibility > 95 && hoursFromFeasibility < 144) {
			return 'stage_3';
		} else if (hoursFromFeasibility > 143 && hoursFromFeasibility < 192) {
			return 'stage_4';
		} else if (hoursFromFeasibility > 191 && hoursFromFeasibility < 240) {
			return 'stage_5';
		} else if (hoursFromFeasibility >= 240) {
			return 'dark_room';
		} else {
			return 'not_defined';
		}
	},
	getProjectContributors: function (contests) {
		let projectContributors = [];
		for (const contest of contests) {
			for (const proposal of contest.proposals) {
				if (proposal.user) {
					projectContributors.push('Co');
				}
			}
		}
		return projectContributors;
	},
	getActiveStep: function (steps) {
		let activeStep = -1;
		for (const step of steps) {
			const order = step.order;
			const status = step.status;
			const stepLength = steps.length;

			if (order <= stepLength && status === 'in_progress') {
				return activeStep = order;
			}
		}
		return activeStep;
	},
	getActiveStepName: function (steps) {
		let activeStepName = 'Contest';
		for (const step of steps) {
			const order = step.order;
			const status = step.status;
			const stepLength = steps.length;

			if (order <= stepLength && status === 'in_progress') {
				return activeStepName = step.title;
			}
		}
		return activeStepName;
	},
	getCompletedSteps: function (steps) {
		let completedSteps = 0;
		for (const step of steps) {
			const status = step.status;
			if (status === 'done') {
				completedSteps++;
			}
		}
		return completedSteps;
	},
	hasStarted: function (project) {
		const contestStates = this.getContestStates();
		return [...contestStates, this.STATE.CLOSED].includes(project.state);
	}
};
