import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {
	Tag,
	OverflowMenu,
	OverflowMenuItem,
	ProgressIndicator,
	ProgressStep,
	TooltipDefinition,
} from 'carbon-components-react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ChevronRight16, CircleSolid16, Purchase16, Add16 } from '@carbon/icons-react';

import { PROJECT, CONTEST } from '../../constants';
import { serverAPI, strings } from '../../utils';
import KEYS from '../../glossary';

import classes from './project-element.module.scss';

const DEFAULT_PROJECT_IMG_URL = '/assets/images/logo-lb-project.png';

class ProjectCard extends Component {
	constructor (props) {
		super(props);

		this.state = {
		};
	}

	displayUrlLink(image) {
		return serverAPI.getThumbnailUrlByFile(image);
	}

	displayPhasesCompleted(contests) {
		const { project } = this.props;

		const contestsCompleted = CONTEST.getTotalContestsCompleted(contests);
		const totalActivePhases = CONTEST.getTotalActivePhases(contests) || 5;

		return (
			<small className={classes.complementInfo}>
				{ 
					project.state === PROJECT.STATE.DRAFT
						? <>Project pending to be submitted</>
						: <>{contestsCompleted}/{totalActivePhases} phases completed</>
				}
			</small>
		);
	}

	getStepsByPhase(project, phase, i) {
		const activePhase = project.state === phase ? true : false;

		return (
			<div className={classes.contributorsBubble} key={i} style={activePhase ? {backgroundColor: CONTEST.getPhaseColor(phase), width: '100px', borderRadius: '4px', textAlign: 'center', color: '#fff'} : {}}>
				{phase === PROJECT.STATE.MODEL_CONTEST
					?	activePhase
						?	'3D Modeling'
						:	'3D'
					:	activePhase
						?	strings.capitalizeFirstLetter(PROJECT.getContestTypeByState(phase))
						:	phase.substring(0, 2).toUpperCase()
				}
			</div>
		);
	}

	displayStepper(project) {
		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsFeasible = project.feasibility === PROJECT.FEASIBILITY.YES;
		const projectIsPaid = project.paid;

		const projectIsPendingPayment = projectIsFeasible && !project.paid;
		const projectIsInOnboarding = project.state === PROJECT.STATE.CLIENT_ONBOARDING;

		const currentStep = (projectIsPendingFeasibility || projectIsNonFeasible) ? 0
			: (projectIsPendingPayment) ? 1
				: (projectIsInOnboarding && projectIsPaid) ? 2
					: 0;

		return (
			<div className={classes.stepperBox}>
				<ProgressIndicator
					currentIndex={currentStep}
					className={classes.stepsContainer}
				>
					<ProgressStep label={'Validation'} invalid={projectIsNonFeasible} />
					<ProgressStep label={'Unlock'} disabled={projectIsNonFeasible} />
					<ProgressStep label={'Onboarding'} disabled={projectIsNonFeasible} />
				</ProgressIndicator>
				<div className={classes.actionBox} style={{ marginTop: '26px' }}>
					{(currentStep === 0 && projectIsPendingFeasibility)
						? <h5 className={classes.link}>Check validation status</h5>
						: (currentStep === 1 || (projectIsPendingPayment))
							? <h5 className={classes.link}>Proceed with the payment</h5>
							: (projectIsNonFeasible)
								? <h5 className={classes.link}>Review comments</h5>
								: <h5 className={classes.link}>Continue onboarding</h5>
					}
					<ChevronRight16 className={classes.link} />
				</div>
			</div>
		);
	}

	onChangeProjectName() {
		const { openModal } = this.props;
		openModal();
	}

	onChangeProjectImage(e) {
		const { openImageModal } = this.props;
		e.preventDefault();
		openImageModal();
	}

	onEraseProject(e) {
		const { openEraseProjectModal } = this.props;
		e.preventDefault();
		openEraseProjectModal();
	}

	generateProjectUrl(project) {
		if (!project) return null;

		if (project.state === PROJECT.STATE.DRAFT) {
			return '/project-builder/' + project.id;
		} else {
			return '/project/' + project.id;
		}
	}

	displayCircularProgressWithLabel(props) {
		return (
			<Box sx={{ position: 'relative', display: 'inline-flex' }}>
				<CircularProgress variant="determinate" color={'inherit'} {...props} thickness={10} />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography
						variant="caption"
						component="div"
						color="text.secondary"
						fontSize={8}
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	render() {
		const { project, isDemo } = this.props;

		if (isDemo) return this.displayDemoProject(project);

		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsFeasible = project.feasibility === PROJECT.FEASIBILITY.YES;
		const projectIsInOnboarding = project.state === PROJECT.STATE.CLIENT_ONBOARDING;
		const projectIsInActivePhase = project.state !== PROJECT.STATE.CLOSED && project.state !== PROJECT.STATE.CLIENT_ONBOARDING;
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;
		const projectIsPendingPayment = projectIsFeasible && !project.paid;
		const projectIsDraft = project.state === PROJECT.STATE.DRAFT;

		const briefingCompletion = projectIsDraft && project.briefingCompletionPercentaje && project.briefingCompletionPercentaje;

		return (
			<div className={classes.projectCard}>
				<Link
					className={classes.projectLink}
					to={this.generateProjectUrl(project)}
				>
					<div className={classes.imageContainer} style={{ position: 'relative'}}>
						<img
							className={classes.cardImage}
							style={project.image ? {} : {filter: 'blur(2px)'}}
							alt={KEYS.img_alt}
							src={project.image
								? this.displayUrlLink(project.image)
								: DEFAULT_PROJECT_IMG_URL}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = DEFAULT_PROJECT_IMG_URL;
							}}
						/>
						{!project.image &&
							<div className={classes.altImgBtn} onClick={(e) => this.onChangeProjectImage(e)}>
								<Add16 className={classes.bigIcon} />
								<small>Add image</small>
							</div>
						}
						{projectIsPendingPayment &&
							<div className={classes.altSign}>
								<Purchase16 className={classes.bigIcon} />
							</div>
						}
					</div>
					<div className={classes.content}>
						<div className={classes.titleAndButtons}>
							<div className={classes.titleContainer} style={{ flexDirection: 'column'}}>
								<div className={classnames([classes.title, classes.complementIcon])}>
									<h4>
										{(project.name.length > 20)
											? <TooltipDefinition tooltipText={strings.capitalizeFirstLetter(project.name)} direction="top">
												<h4>{strings.capitalizeFirstLetter(project.name.substring(0, 20)) + '...'}</h4>
											</TooltipDefinition>
											: strings.capitalizeFirstLetter(project.name)
										}
									</h4>
									{projectIsInActivePhase && !projectIsDraft &&
										<CircleSolid16 className={classes.activeState} />
									}
								</div>
								{projectIsInActivePhase
									? 	this.displayPhasesCompleted(project.contests)
									: (projectIsFinished)
										?	<small className={classes.complementInfo}>Prototype ready</small>
										: (projectIsNonFeasible)
											?	<Tag type="red" title={'Not feasible'} className={classes.flatTag}>
													Not feasible
											</Tag>
											: (projectIsFeasible)
												?	<Tag type="green" title={'Feasible'} className={classes.flatTag}>
														Feasible
												</Tag>
												: (projectIsPendingFeasibility) &&
													<Tag type="gray" title={'Feasible'} className={classes.flatTag}>
														Pending feasibility
													</Tag>
								}
							</div>
							<a href="javascript: void(0)">
								<div className={classes.favBtn}>
									{projectIsDraft &&
										<OverflowMenu direction="top" flipped light>
											<OverflowMenuItem itemText={'Change image'} onClick={(e) => this.onChangeProjectImage(e)} />
											<OverflowMenuItem itemText={'Delete project'} isDelete onClick={(e) => this.onEraseProject(e)} />
										</OverflowMenu>
									}
									{!projectIsInActivePhase && !projectIsFinished && 
										<OverflowMenu direction="top" flipped light>
											{(projectIsPendingFeasibility || projectIsPendingPayment || projectIsInOnboarding) &&
												<>
													<OverflowMenuItem itemText={'Change image'} onClick={(e) => this.onChangeProjectImage(e)} />
													<OverflowMenuItem itemText={'Edit name'} onClick={() => this.onChangeProjectName()} />
												</>
											}
											{(projectIsPendingFeasibility) &&
													<OverflowMenuItem itemText={'Delete project'} isDelete disabled onClick={(e) => this.onEraseProject(e)} />
											}
										</OverflowMenu>
									}
								</div>
							</a>
						</div>
						{projectIsFinished
							?	<>
								<div className={classes.finishedTag}>{KEYS.finished}</div>
								<div className={classes.actionBox} style={{ marginTop: '16px' }}>
									<h5 className={classes.link}>Review project</h5>
									<ChevronRight16 className={classes.link} />
								</div>
							</>
							: ((projectIsInOnboarding || projectIsPendingFeasibility || projectIsPendingPayment || projectIsNonFeasible) && !projectIsDraft)
								?	this.displayStepper(project)
								: (projectIsInActivePhase) &&
									<>
										<div className={classes.finishedTag} style={{ background: CONTEST.getPhaseColor(project.state)}}>
											{PROJECT.stateToStringShort(project.state)}
										</div>
										<div className={classes.actionBox} style={{ marginTop: '16px' }}>
											{briefingCompletion &&
												<div className={classes.progressCircle}>
													{this.displayCircularProgressWithLabel({ value: briefingCompletion })}
												</div>
											}
											<h5 className={classes.link}>
												{project.state === PROJECT.STATE.PROJECT_SET_UP
													? 'Learn the process before starting'
													: (project.state === PROJECT.STATE.DRAFT ? (briefingCompletion !== 100 ? 'Edit draft' : 'Submit draft') : 'Open project')
												}</h5>

											<ChevronRight16 className={classes.link} />
										</div>
									</>
						}
					</div>
				</Link>
			</div>
		);
	}

	displayDemoProject(project) {
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;

		return (
			<div className={classes.projectCard} style={{border: '2px solid #fe6601'}}>
				<Link
					className={classes.projectLink}
					to={'/project/demo'}
				>
					<div className={classes.imageContainer} style={{ position: 'relative'}}>
						<img
							className={classes.cardImage}
							style={project.image ? {} : {filter: 'blur(2px)'}}
							alt={KEYS.img_alt}
							src={project.image
								? this.displayUrlLink(project.image)
								: DEFAULT_PROJECT_IMG_URL}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = DEFAULT_PROJECT_IMG_URL;
							}}
						/>
						<div className={classes.demoTag}>Demo</div>
					</div>
					<div className={classes.content}>
						<div className={classes.titleAndButtons}>
							<div className={classes.titleContainer} style={{ flexDirection: 'column'}}>
								<div className={classnames([classes.title, classes.complementIcon])}>
									<h4>
										{project.name ? strings.capitalizeFirstLetter(project.name) : 'Demo project'}
									</h4>
								</div>
								<small className={classes.complementInfo}>Prototype ready</small>
							</div>
							<a href="javascript: void(0)">
								<div className={classes.favBtn}>
									<OverflowMenu direction="top" flipped light>
										<OverflowMenuItem itemText={'Change name'} disabled />
										<OverflowMenuItem itemText={'Change image'} disabled />
									</OverflowMenu>
								</div>
							</a>
						</div>
						{projectIsFinished
							?	<>
								<div className={classes.finishedTag}>{KEYS.finished}</div>
								<div className={classes.actionBox} style={{ marginTop: '16px' }}>
									<h5 className={classes.link}>Review project</h5>
									<ChevronRight16 className={classes.link} />
								</div>
							</>
							: 	<>
								<div className={classes.finishedTag} style={{ background: CONTEST.getPhaseColor(project.state)}}>
									{PROJECT.stateToStringShort(project.state) === 'Unknown' ? 'DEMO' : PROJECT.stateToStringShort(project.state)}
								</div>
								<div className={classes.actionBox} style={{ marginTop: '16px' }}>

									<h5 className={classes.link}>Open project</h5>
									<ChevronRight16 className={classes.link} />
								</div>
							</>
						}
					</div>
				</Link>
			</div>
		);
	}
}

ProjectCard.propTypes = {
	project: PropTypes.object.isRequired,
	openModal: PropTypes.func,
	openImageModal: PropTypes.func,
	openEraseProjectModal: PropTypes.func,
	isDemo: PropTypes.bool,
};

ProjectCard.defaultProps = {
	project: {},
	openModal() {},
	openImageModal() {},
	openEraseProjectModal() {},
	isDemo: false,
};

export default ProjectCard;
