import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Header,
	HeaderGlobalAction,
	HeaderGlobalBar,
	HeaderNavigation,
	HeaderMenuButton,
	HeaderMenuItem,
	HeaderSideNavItems,
	HeaderMenu,
	SideNav,
	SideNavItems,
	SideNavLink,
	Switcher,
	SwitcherItem,
} from 'carbon-components-react/es/components/UIShell';
import { Tag } from 'carbon-components-react';
import { UserAvatar24, Help20, Gift20 } from '@carbon/icons-react';

import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';

import KEYS from '../../glossary';
import { serverAPI, userInfo, projectStorage } from '../../utils';

import classes from './navbar.module.scss';

const EXPERT_NAV_LINKS = [
	{
		link: '/',
		text: KEYS.navbar_home,
	},
	{
		link: '/explore',
		text: KEYS.navbar_explore,
	},
	{
		link: '/contests',
		text: KEYS.navbar_contests,
	},
	{
		link: '/how-it-works/experts',
		text: KEYS.navbar_how_it_works,
	},
	...(userInfo.getUserInfo()?.expert?.canValidateProjects ? (
		[{
			link: '/validate',
			text: KEYS.navbar_validate_projects,
		}]
	) : []),
	{
		link: 'https://lbexperts.lastbasic.com',
		text: KEYS.navbar_community,
		isExternal: true,
		isNew: true,
		target: '_blank'
	}
];

const MAKER_NAV_LINKS = [
	{
		link: '/projects',
		text: KEYS.navbar_home,
	},
	{
		link: '/marketplace',
		text: 'Marketplace',
	},
	{
		text: 'More',
		options: [
			{
				text: KEYS.navbar_start_here,
				link: '/getting-started',
				icon: <FlagRoundedIcon style={{color: '#24a148'}} />
			},
			{
				link: '/how-it-works/inventors',
				text: KEYS.navbar_how_it_works,
				icon: <DescriptionRoundedIcon style={{color: '#02B2DA'}} />
			},
			{
				link: 'https://lastbasic.com/blog',
				text: 'Blog',
				icon: <ContentPasteSearchRoundedIcon />,
				target: '_blank'
			},
			// {
			// 	link: '/project/638e0ab8ba63cf1b178b74fb',
			// 	text: 'Demo project',
			// 	icon: <ContentPasteSearchRoundedIcon />
			// },
		]
	},
];

class Navbar extends Component {
	constructor (props) {
		super(props);

		this.state = {
			showMenuOption: false,
			displayLeftSideMenuOptions: false,
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = (e) => {
		const targetElement = e.target;
		const userOptionMenu = document.getElementById('user-option-menu');
		const userOptionAvatar = document.getElementById('user-option-avatar');

		if (userOptionMenu && (
			userOptionMenu.contains(targetElement) || userOptionAvatar.contains(targetElement)
		)) {
			return;
		} else {
			this.setState({ showMenuOption: false });
		}
	};

	onToggleLeftMenu = () => {
		const { displayLeftSideMenuOptions } = this.state;
		this.setState({
			displayLeftSideMenuOptions: !displayLeftSideMenuOptions,
		});
	};

	getUserFullName = () => {
		const { userData: { user } } = this.props;
		return user.firstName + ' ' + user.lastName;
	};

	getAvatarUrl = () => {
		const { userData: { user } } = this.props;
		return serverAPI.getAvatarUrl(user.id);
	};

	displaySideNavItems = (linkList) => {
		return (
			<Fragment>
				{linkList.map((item, i) => (
					<HeaderMenuItem key={i}>
						<SideNavLink onClick={this.onToggleLeftMenu}>
							{item.isExternal ? (
								<a
									className={classes.sideNavLink}
									href={item.link}
									target={item.target || undefined}
								>
									{item.text}
								</a>
							) : (
								<Link
									className={classes.sideNavLink}
									to={item.link}
									target={item.target || undefined}
								>
									{item.text}
								</Link>
							)}
						</SideNavLink>
					</HeaderMenuItem>
				))}
			</Fragment>
		);
	};

	displayHeaderMenuItems = (linkList) => {
		return (
			<Fragment>
				{linkList.map((item, i) => (
					<div key={i} className={classes.menuItemContainer}>
						<Fragment>
							{item.text === 'More'
								?	<HeaderMenu
									aria-label={item.text}
									menuLinkName={item.text}
								>
									<div id="menu-more-options" className={classes.menuOptions}>
										{item.options.map((option, i) => (
											option.text === 'Blog'
												?	<a
													key={i}
													href={option.link}
													target={option.target}
													style={{textDecoration: 'none'}}
												>
													<div className={classes.optionItem}>
														<div className={classes.iconMenu}>
															{option.icon}
														</div>
														<h5 className={classes.itemTitle}>{option.text}</h5>
													</div>
												</a>
												:	<Link
													key={i}
													to={option.link}
													element={Link}
													target={item.target || undefined}
													style={{textDecoration: 'none'}}
												>
													<div className={classes.optionItem}>
														<div className={classes.iconMenu}>
															{option.icon}
														</div>
														<h5 className={classes.itemTitle}>{option.text}</h5>
													</div>
												</Link>
										))}
									</div>
								</HeaderMenu>	
								:	<HeaderMenuItem
									className={classes.headerMenuLink}
									{...(item.isExternal ? ({
										element: ({ children, ...rest }) => <a {...rest}>{children}</a>,
										href: item.link
									}) : ({
										element: Link,
										to: item.link
									}))}
									target={item.target || undefined}
								>
									{item.text}
								</HeaderMenuItem>
							}
							{item.isNew && (
								<Tag type="blue" className={classnames([classes.alphaTag])}>NEW</Tag>
							)}
						</Fragment>
					</div>
				))}
			</Fragment>
		);
	};
	
	onClickLogout = () => {
		window.open('/logout', '_self');
	};

	openAboutLastBasic() {
		window.open('https://lastbasic.com/about-us', '_blank');
	}

	openHelpCenter() {
		window.open('https://lastbasic.froged.help/', '_blank');
	}

	openKnowledgeHub() {
		window.open('https://lastbasic.com/knowledge-hub', '_blank');
	}

	render() {
		const { userData: { user } } = this.props;
		const { displayLeftSideMenuOptions, showMenuOption } = this.state;

		const userFullName = this.getUserFullName().toUpperCase();
		
		return (
			<Header className={classes.header} aria-label="LastBasic Platform">
				<HeaderMenuButton
					aria-label="Open menu"
					className={classes.menuOpenIcon}
					onClick={this.onToggleLeftMenu}
					isActive={displayLeftSideMenuOptions}
				/>
				<Link to="/" className={'bx--header__name '}>
					<img
						className={classes.logo}
						src="/assets/images/lb-logo.png"
						alt="LastBasic logo"
					/>
				</Link>
				<HeaderNavigation className={classes.headerNavigation} aria-label="Nav">
					{user.expert ? (
						this.displayHeaderMenuItems(EXPERT_NAV_LINKS)
					) : projectStorage.getProject() || user.maker ? (
						this.displayHeaderMenuItems(MAKER_NAV_LINKS)
					) : (
						null
					)}
				</HeaderNavigation>
				<HeaderGlobalBar className={classes.rightNavAction}>
					{user.maker && (
						<div className={classes.menuItemContainer} style={{ top: '-4px' }}>
							<HeaderMenuItem
								aria-label="Start a new project"
								className={classnames(classes.headerMenuLink, classes.newProjectWide)}
								element={Link}
								to={'/new/project'}
							>
								New project
							</HeaderMenuItem>
							<HeaderMenuItem
								aria-label="Start a new project"
								className={classnames(classes.headerMenuLink, classes.newProjectNarrow)}
								element={Link}
								to={'/new/project'}
							>
								+
							</HeaderMenuItem>
						</div>
					)}
					<HeaderGlobalAction
						style={{ display: 'none' }}
						aria-label="Referral"
						className={classes.inviteBtn}
					>
						<Link to={'/invites'} style={{color: '#fff'}}>
							<div className={classes.referral}>
								<Gift20 />
							</div>
						</Link>
					</HeaderGlobalAction>
					<HeaderGlobalAction
						aria-label="Help"
						onClick={() => window.Froged('find')}
					>
						<Help20 style={{ color: '#c5c5c5'}} />
					</HeaderGlobalAction>
					<HeaderGlobalAction
						aria-label="Account settings"
						tooltipAlignment="end"
						id="user-option-avatar"
						onClick={() => this.setState({ showMenuOption: !showMenuOption })}
					>
						{(user.hasAvatar) ? (
							<img src={this.getAvatarUrl()} className={classes.avatarIcon} alt='User avatar' />
						) : (
							<UserAvatar24 style={{ fill: '#313131'}} />
						)}
					</HeaderGlobalAction>
				</HeaderGlobalBar>
				{displayLeftSideMenuOptions &&
					<SideNav
						isChildOfHeader={true}
						aria-label="Side navigation"
						expanded={displayLeftSideMenuOptions}
						isPersistent={false}
					>
						<SideNavItems>
							<HeaderSideNavItems>
								{(user.expert) ? (
									this.displaySideNavItems(EXPERT_NAV_LINKS)
								) : (projectStorage.getProject()) || (user.maker) ? (
									this.displaySideNavItems(MAKER_NAV_LINKS)
								) : (
									null
								)}
							</HeaderSideNavItems>
						</SideNavItems>
					</SideNav>
				}
				{showMenuOption &&
					<Switcher
						className={classes.switcher}
						aria-label="Settings"
					>
						<div id="user-option-menu" style={{minWidth: '150px'}}>
							<h6 className={classes.userName}>
								{userFullName}
								{user.maker && <small>{KEYS.navbar_user_type_maker}</small>}
								{user.expert && <small>{KEYS.navbar_user_type_expert}</small>}
							</h6>
							<SwitcherItem aria-label="Account settings" element={Link} to="/account/settings">
								{KEYS.navbar_option_account}
							</SwitcherItem>
							{user.maker && (
								<SwitcherItem aria-label="profile" element={Link} to="/projects">
									{KEYS.navbar_option_my_projects}
								</SwitcherItem>
							)}
							<SwitcherItem aria-label="invites" element={Link} to="/invites">
								{KEYS.navbar_referral}
							</SwitcherItem>
							<SwitcherItem aria-label="help" onClick={() => this.openHelpCenter()}>
								Help center
							</SwitcherItem>
							<SwitcherItem aria-label="logout" onClick={this.onClickLogout} className={classes.lastAction}>
								{KEYS.navbar_option_logout}
							</SwitcherItem>
							<div className={classes.lastNavbarBlock}>
								<a href={'https://lastbasic.com/privacy-policy'} target='_blank' rel='noopener noreferrer'>
									<small>Terms</small>
								</a>
								•
								<a href={'https://lastbasic.com/cookie-policy'} target='_blank' rel='noopener noreferrer'>
									<small>Privacy</small>
								</a>
							</div>
						</div>
					</Switcher>
				}
			</Header>
		);
	}
}

Navbar.propTypes = {
	userData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(Navbar);
