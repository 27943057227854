import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import withRouter from '../../../../components/route/with-router';
import { Link } from 'react-router-dom';

import config from '../../../../config';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';

import classes from './validation-chip.module.scss';

const isProd = !!('app.lastbasic.com').includes(window.location.hostname);
const feasibilityApiUrl = `https://api${isProd ? '' : '.dev'}.lastbasic.com/v1/apps/feasibility/public/projects`;

function ValidationChip({ projectId, mainFeasibility }) {
	const [result, setResult] = useState();
	const [check, setCheck] = useState(true);

	useEffect(() => {
		getResult();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			getResult();
		}, 5000);

		return () => clearInterval(interval);
	}, [check]);

	function getAvatarUrl(userId) {
		const apiUrl = isProd ? config.lastbasic.api.host : 'https://dev.api.lastbasic.com';
		return `${apiUrl}/public/user/${userId}/avatar`;
	}

	async function getResult() {
		if (!check) return;

		let response;
		try {
			response = await axios.get(`${feasibilityApiUrl}/${projectId}`);
			const data = response.data;

			setResult(data);

			if (data.feasible !== 'pending') setCheck(false);
		} catch (e) { console.error(e); }

		if (!response) setCheck(false);
	}

	if (!result && !mainFeasibility) return <></>;

	if (!result && mainFeasibility) {
		return (
			<div className={classes.chipWrapper}>
				<div className={classes.chip} style={{ cursor: 'inherit' }}>
					<div className={classes.result} style={{ paddingLeft: '10px' }}>
						{ mainFeasibility === 'pending' && 'Waiting for validation from experts...'}
						{ mainFeasibility === 'yes' && 'Your project is feasible for us!'}
						{ mainFeasibility === 'no' && 'Your project does not meet our standards'}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.chipWrapper}>
			<Tooltip title={'Validation process. Click to see details'}>
				<Link to={`/project/${projectId}/validation`} style={{ textDecoration: 'none' }}>
					<div className={classes.chip}>
						<div className={classes.avatars}>
							<AvatarGroup
								max={3}
								spacing={'medium'}
								sx={{
									'& .MuiAvatar-root': { width: 30, height: 30, fontSize: 15 },
								}}
							>
								{ result && result.votes.length === 0 && <Avatar sx={{ height: '30px', width: '30px' }} src={''} /> }
								{ result && result.votes.map(vote => <Avatar key={vote.userId} sx={{ height: '30px', width: '30px' }} src={getAvatarUrl(vote.userId)} />)}
							</AvatarGroup>
						</div>

						<div className={classes.result}>
							{ result && result.feasible === 'pending' && result.votes.length === 0 && 'Waiting for validation from experts...'}
							{ result && result.feasible === 'pending' && result.votes.length !== 0 && 'Waiting for more experts...'}
							{ result && result.feasible === 'yes' && 'Your project is feasible for us!'}
							{ result && result.feasible === 'no' && 'Your project does not meet our standards'}
						</div>
					</div>
				</Link>
			</Tooltip>
		</div>
	);
}

ValidationChip.defaultProps = {
	projectId: null,
	mainFeasibility: null,
};

ValidationChip.propTypes = {
	projectId: PropTypes.string.isRequired,
	mainFeasibility: PropTypes.string,
};

export default withRouter(ValidationChip);
