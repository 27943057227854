const LocalStore = window.localStorage;

export const ROUTE_HISTORY = 'ROUTE_HISTORY';

const getRoute = () => {
	try {
		let strRoute = LocalStore.getItem(ROUTE_HISTORY);
		if (strRoute) return JSON.parse(strRoute);
		
		return null;
	} catch (error) {
		return null;
	}
};

const storeRoute = (route) => {
	try {
		LocalStore.setItem(ROUTE_HISTORY, JSON.stringify(route));
		return true;
	} catch (error) {
		return false;
	}
};

const removeRoute = () => {
	try {
		LocalStore.removeItem(ROUTE_HISTORY);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getRoute,
	storeRoute,
	removeRoute,
};