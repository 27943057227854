import React from 'react';
import { Link } from 'react-router-dom';

import classes from '../auth/pages.module.scss';

function NotFoundPage () {
	return (
		<div className={classes.alertWrapper}>
			<div className={classes.alertBox}>
				<h4 style={{ lineHeight: '1.1' }}>
					Page not found
				</h4>
				<Link className={classes.btn} to="/">
					Go back to home
				</Link>
			</div>
		</div>
	);
}

export default NotFoundPage;
