import React from 'react';

import { PROJECT } from '.';
import KEYS from './../glossary';
import SketchExpertIcon from './../components/icons/experts/sketch-expert';
import ElectricalExpertIcon from './../components/icons/experts/electrical-expert';
import MechanicalExpertIcon from './../components/icons/experts/mechanical-expert';
import PrototypeExpertIcon from './../components/icons/experts/prototyping-expert';

export default {
	EXPERT_EXPERTISE: {
		PRODUCT_DESIGN: 'product_design',
		FIRMWARE_ENGINEERING: 'firmware_engineering',
		MECHANICAL_ENGINEERING: 'mechanical_engineering',
		HARDWARE_ENGINEERING: 'hardware_engineering',
		ELECTRICAL_ENGINEERING: 'electrical_engineering',
		PRODUCTION_ENGINEERING: 'production_engineering',
		MAKER: 'maker',
		SUPPLIER: 'supplier',
	},
	YEARS_EXPERIENCE: {
		Y_0_STUDENT: 'y_0_student',
		Y_0_1: 'Y_0_1',
		Y_1_5: 'y_1_5',
		Y_5_10: 'y_5_10',
		Y_MORE_10: 'y_mode_10',
	},
	CONTEST_PRIZES: {
		SKETCH: 200,
		MODEL: 300,
		ELECTRICAL: 500,
		MECHANICAL: 500,
		PROTOTYPING: 500,
	},
	CONTEST_REWARDS: {
		SKETCH: 20,
		MODEL: 30,
		ELECTRICAL: 40,
		MECHANICAL: 40,
		PROTOTYPING: 0,
	},
	expertiseToString: function (type) {
		switch (type) {
			case this.EXPERT_EXPERTISE.PRODUCT_DESIGN:
				return 'Product Design';
			case this.EXPERT_EXPERTISE.MECHANICAL_ENGINEERING:
				return 'Mechanical Engineering';
			case this.EXPERT_EXPERTISE.ELECTRICAL_ENGINEERING:
				return 'Electrical Engineering';
			case this.EXPERT_EXPERTISE.PRODUCTION_ENGINEERING:
				return 'Production Engineering';
			case this.EXPERT_EXPERTISE.MAKER:
				return 'Maker';
			case this.EXPERT_EXPERTISE.SUPPLIER:
				return 'Supplier';
			default:
				return 'Unknown';
		}
	},
	stringToExpertise: function (type) {
		switch (type) {
			case 'Product Design':
				return this.EXPERT_EXPERTISE.PRODUCT_DESIGN;
			case 'Mechanical Engineering':
				return this.EXPERT_EXPERTISE.MECHANICAL_ENGINEERING;
			case 'Electrical Engineering':
				return this.EXPERT_EXPERTISE.ELECTRICAL_ENGINEERING;
			case 'Production Engineering':
				return this.EXPERT_EXPERTISE.PRODUCTION_ENGINEERING;
			case 'Maker':
				return this.EXPERT_EXPERTISE.MAKER;
			case 'Supplier':
				return this.EXPERT_EXPERTISE.SUPPLIER;
			default:
				return this.EXPERT_EXPERTISE.PRODUCT_DESIGN;
		}
	},
	yearOfExperienceToString: function (years) {
		switch (years) {
			case this.YEARS_EXPERIENCE.Y_0_STUDENT:
				return '0 - Student';
			case this.YEARS_EXPERIENCE.Y_0_1:
				return '1 year';
			case this.YEARS_EXPERIENCE.Y_1_5:
				return '1 - 5 years';
			case this.YEARS_EXPERIENCE.Y_5_10:
				return '5 - 10 years';
			case this.YEARS_EXPERIENCE.Y_MORE_10:
				return 'More than 10 years';
			default:
				return 'N/D';
		}
	},
	isAParticipantExpert: function (project, userId) {
		const { contests } = project;
		for (const contest of contests) {
			const { proposals, selectedProposal } = contest;
			const userIsWinner = (selectedProposal !== null && selectedProposal.user.id === userId);
			const userIsParticipating = userIsWinner || proposals.some(p => p.user.id === userId);
			if (userIsParticipating) return true;
		}
		return false;
	},
	getStatesExpertIsParticipating: function (project, userId) {
		const participatingStates = [];
		const { contests } = project;
		for (const contest of contests) {
			const { proposals, selectedProposal } = contest;
			const userIsWinner = (selectedProposal !== null && selectedProposal.user.id === userId);
			const userIsParticipating = userIsWinner || proposals.some(p => p.user.id === userId);
			if (userIsParticipating) {
				participatingStates.push(PROJECT.getStateByContestType(contest.type));
			}
		}
		return participatingStates;
	},
	getExpertRole: function (expertise) {
		switch (expertise) {
			case 'sketch':
				return KEYS.sketch_role_description;
			case 'modeling':
				return KEYS.modeling_role_description;
			case this.EXPERT_EXPERTISE.ELECTRICAL_ENGINEERING:
				return KEYS.electrical_engineer_role_description;
			case this.EXPERT_EXPERTISE.MECHANICAL_ENGINEERING:
				return KEYS.mechanical_engineer_role_description;
			case this.EXPERT_EXPERTISE.MAKER:
				return KEYS.mechanical_engineer_role_description;
			case this.EXPERT_EXPERTISE.PRODUCTION_ENGINEERING:
				return KEYS.production_engineer_role_description;
			case this.EXPERT_EXPERTISE.SUPPLIER:
				return KEYS.production_engineer_role_description;
			default:
				return 'Not defined. Go to profile to set up your expertise!';
		}
	},
	getExpertIcon: function (expertise) {
		switch (expertise) {
			case this.EXPERT_EXPERTISE.PRODUCT_DESIGN:
				return <SketchExpertIcon />;
			case this.EXPERT_EXPERTISE.ELECTRICAL_ENGINEERING:
			case this.EXPERT_EXPERTISE.FIRMWARE_ENGINEERING:
				return <ElectricalExpertIcon />;
			case this.EXPERT_EXPERTISE.MECHANICAL_ENGINEERING:
			case this.EXPERT_EXPERTISE.HARDWARE_ENGINEERING:
				return <MechanicalExpertIcon />;
			case this.EXPERT_EXPERTISE.MAKER:
				return <MechanicalExpertIcon />;
			case this.EXPERT_EXPERTISE.PRODUCTION_ENGINEERING:
				return <PrototypeExpertIcon />;
			case this.EXPERT_EXPERTISE.SUPPLIER:
				return <PrototypeExpertIcon />;
			default:
				return <SketchExpertIcon />;
		}
	},
	getContestsExpertHasParticipated: function (contests, userId, projectId) {
		let contestsExpertParticipated = [];
		for (const contest of contests) {
			const currentProjectId = contest.project.id;
			for (const proposal of contest.proposals) {
				const proposalUserId = proposal.user.id;
				if (proposalUserId === userId && currentProjectId === projectId) {
					if (contestsExpertParticipated.indexOf(contest.type) < 0) {
						contestsExpertParticipated.push(contest.type);
					}
				}
			}
		}
		return contestsExpertParticipated;
	},
	getContestPrize: function (contestType) {
		switch (contestType) {
			case PROJECT.CONTEST_TYPES.SKETCH:
				return this.CONTEST_PRIZES.SKETCH;
			case PROJECT.CONTEST_TYPES.MODEL:
				return this.CONTEST_PRIZES.MODEL;
			case PROJECT.CONTEST_TYPES.ELECTRICAL:
				return this.CONTEST_PRIZES.ELECTRICAL;
			case PROJECT.CONTEST_TYPES.MECHANICAL:
				return this.CONTEST_PRIZES.MECHANICAL;
			case PROJECT.CONTEST_TYPES.PROTOTYPING:
				return this.CONTEST_PRIZES.PROTOTYPING;
			default:
				return 0;
		}
	},
	getContestReward:  function (contestType) {
		switch (contestType) {
			case PROJECT.CONTEST_TYPES.SKETCH:
				return this.CONTEST_REWARDS.SKETCH;
			case PROJECT.CONTEST_TYPES.MODEL:
				return this.CONTEST_REWARDS.MODEL;
			case PROJECT.CONTEST_TYPES.ELECTRICAL:
				return this.CONTEST_REWARDS.ELECTRICAL;
			case PROJECT.CONTEST_TYPES.MECHANICAL:
				return this.CONTEST_REWARDS.MECHANICAL;
			case PROJECT.CONTEST_TYPES.PROTOTYPING:
				return this.CONTEST_REWARDS.PROTOTYPING;
			default:
				return 0;
		}
	},
	getTotalEarningsExpertHasWonInThisProject: function (contests, userId, projectId) {
		let contestParticipated = [];
		for (const contest of contests) {
			const currentProjectId = contest.project.id;
			const selectedProposalUserId = contest.selectedProposal && contest.selectedProposal.user.id;
			if (selectedProposalUserId === userId && currentProjectId === projectId) {
				contestParticipated.push(this.getContestPrize(contest.type));
			}
		}
		return contestParticipated.reduce((a, b) => a + b, 0) || 0;
	},
	getTotalCreditsExpertHasWonInThisProject: function (contests, projectId) {
		let contestParticipated = [];
		for (const contest of contests) {
			const currentProjectId = contest.project.id;
			if (currentProjectId == projectId) {
				contestParticipated.push(this.getContestReward(contest.type));
			}
		}
		return contestParticipated.reduce((a, b) => a + b, 0) || 0;
	},
	isUserInLimboStage: function (projects) {
		let userIsInLimbo = false;
		const projectsLength = projects.length;
		const draftProjects = projects.filter(p => p.state === 'draft');
		const validatedButNotFeasibleProjects = projects.filter(p => (p.feasibility === 'pending' || p.feasibility === 'no') && p.paid === false && p.state !== 'draft');

		if ((draftProjects.length + validatedButNotFeasibleProjects.length) >= projectsLength) {
			return true;
		}
		return userIsInLimbo;
	},
	hasExpertAPendingTask: function (profile) {
		const completion = this.getProfileCompletionProgress(profile);
		return completion !== 100;
	},
	getProfileCompletionProgress: function (profile) {
		if (!profile || !profile.expert) return 0;

		const expert = profile.expert;

		const userHasAvatar = profile.hasAvatar;
		const userHasSignedNDA = !!expert.ndaSignedAt;
		const userHasPaymentInfo = !!expert.paymentInfo;
		const userHasDetailInfo = !!(expert.expertExpertise && expert.expertType);

		let progress = 0;
		if (userHasAvatar) progress++;
		if (userHasSignedNDA) progress++;
		if (userHasPaymentInfo) progress++;
		if (userHasDetailInfo) progress++;

		return progress * 25;
	}
};
