import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Add32 } from '@carbon/icons-react';

import StartProjectModal from '../modals/start-project-modal';

import classes from './project-element.module.scss';


class StartProjectOption extends Component {
	constructor (props) {
		super(props);

		this.state = {
			openForm: false,
		};
	}

	render() {
		const { openForm } = this.state;
		const { redirectToProjectBuilder } = this.props;

		return (
			<>
				<div className={classnames([classes.projectCard, classes.startBox])}
					onClick={() => (redirectToProjectBuilder ? redirectToProjectBuilder() : this.setState({ openForm: true }))}>
					<Add32 />
					<h4>Start a new project</h4>
				</div>
				{openForm && (
					<StartProjectModal
						open={openForm}
						onRequestClose={() => this.setState({ openForm: false })}
					/>
				)}
			</>
		);
	}
}

StartProjectOption.propTypes = {
	project: PropTypes.object.isRequired,
	redirectToProjectBuilder: PropTypes.func,
};

StartProjectOption.defaultProps = {
	project: {},
};

export default StartProjectOption;
