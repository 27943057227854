import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Mutation } from '@apollo/client/react/components';
import {
	AccordionSkeleton,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbSkeleton,
	Button,
	InlineNotification,
	Modal,
	Tabs,
	Tab,
	Tag,
	ToastNotification,
	TagSkeleton,
	TooltipIcon,
	SkeletonPlaceholder,
	SkeletonText,
	InlineLoading,
	ExpandableTile,
	TileBelowTheFoldContent,
} from 'carbon-components-react';
import { Locked32, Information16, TrophyFilled20, Locked16 } from '@carbon/icons-react';

import ReactCountryFlag from 'react-country-flag';
import Badge from '@mui/material/Badge';

import { PROJECT, COUNTRIES, CONTEST, PROPOSAL, EXPERT } from '../../../../constants';
import { GET_PROJECT } from '../../../../providers/queries';
import { JOIN_CONTEST, ADD_FILE_TO_PROPOSAL, CREATE_PROPOSAL } from '../../../../providers/mutations';
import withRouter from '../../../../components/route/with-router';

import { serverAPI, strings, dates, userInfo } from '../../../../utils';
import KEYS from './../../../../glossary';

import {
	EntriesTab,
	GuidelinesTab,
	RawMaterialsTab,
	MaterialsTab,
	UpdatesTab,
	InspirationTab
} from './tabs';

import PhaseStatesBubbles from '../../../../components/project/phase-states-bubbles';
import ProposalFeedback from '../../../../components/project/proposal-feedback-content';
import ContestModal from '../../../../components/modals/contest-modal';
import PreviewFileModal from '../../../../components/modals/preview-file-modal';
import ScrollIndicator from '../../../../components/elements/scroll-indicator';
import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import classes from './project-page.module.scss';

class ProjectViewPage extends Component {
	constructor (props) {
		super(props);
		this.inputFileRef = React.createRef();
		this.refetchProject = () => {};

		this.state = {
			openContestModal: false,
			openProposalFeedbackModal: false,
			openStartingKitModal: false,
			proposalFeedback: null,
			proposalFile: null,
			previewFile: null,
			activeTab: 0,
			selectState: null,
			uploading: null,
			uploadError: null,
			uploadSuccess: false,
			hideCoverImg: false,
			selectedProposal: null,
			userInfo: userInfo.getUserInfo(),
		};
	}

	componentDidMount() {
		document.title = 'Project details - LastBasic';
	}

	async onConfirmJoinProject(JoinContestMutation, contest, refetchProject, projectId) {
		let error;
		try {
			const result = await JoinContestMutation({
				variables: {
					projectId: projectId,
					contestType: contest.type,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			// Error => Ignore
		} else {
			setTimeout(() => {
				this.setState({ openStartingKitModal: true });
			}, 1000);
			await refetchProject();
		}
		this.setState({ openContestModal: false });
	}

	async onClickUpload(e, projectId, contest, uploadFileMutation, createProposalMutation) {
		const { userData: { user } } = this.props;
		const { target: { validity, files: [file] } } = e;
		const contestType = contest.type;
		if (!validity.valid) return;

		const emptyProposalId = PROPOSAL.hasFilesInProposal(contest, user.id);

		this.setState({
			uploading: {
				filename: file.name,
				size: file.size,
			}
		}, async () => {
			if (emptyProposalId === false) {
				const proposal = await createProposalMutation({
					variables: { projectId: projectId, contestType: contestType }
				});

				if (proposal && proposal.data && proposal.data.createProposal) {
					const proposalId = proposal.data.createProposal.id;

					const result = await uploadFileMutation({
						variables: { proposalId: proposalId, file }
					});

					if (result && result.data && result.data.addFileToProposal) {
						this.setState({
							uploadSuccess: true,
						});
						await this.refetchProject();
						setTimeout(() => {
							this.setState({ uploadSuccess: false });
							this.scrollToBottom();
						}, 3000);
					} else {
						this.setState({
							uploadError: true,
						});
					}
				}
			} else {
				const result = await uploadFileMutation({
					variables: { proposalId: emptyProposalId, file }
				});

				if (result && result.data && result.data.addFileToProposal) {
					this.setState({
						uploadSuccess: true,
					});
					await this.refetchProject();
					setTimeout(() => {
						this.setState({ uploadSuccess: false });
						this.scrollToBottom();
					}, 3000);
				} else {
					this.setState({
						uploadError: true,
					});
				}
			}

			this.setState({
				uploading: null,
			});
		});
	}

	scrollToBottom = () => {
		window.scrollTo(0, document.body.scrollHeight);
	};

	getProposalUserInitials = (proposals) => {
		const userInitials = [];
		for (const proposal of proposals) {
			const firstLetter = (proposal.user.firstName || 'X').charAt(0).toUpperCase();
			const secondLetter = (proposal.user.lastName || 'X').charAt(0).toUpperCase();
			const initials = firstLetter + secondLetter;
			if (userInitials.includes(initials) === false) {
				userInitials.push(initials);
			}
		}
		return userInitials.map((userIni, i) => (
			<div className={classes.contributorsBubble} key={i}>
				{userIni}
			</div>
		));
	};

	openContest = async () => {
		this.setState({ openContestModal: true });
		window.Froged('track', 'click_join_contest_from_project_page');
	};

	displayProjectImageUrl(project) {
		const { image } = project;
		const DefaultImg = '/assets/images/logo-lb-project.png';

		if (!image) return DefaultImg;
		return serverAPI.getDisplayUrlByFile(image);
	}

	displayContestSlots(contest) {
		const availableSlots = contest ? contest.availableSlots : CONTEST.getContestMaxValidProposals(contest.type);
		const contestMaxValidProposals = CONTEST.getContestMaxValidProposals(contest.type);

		return (
			<Fragment>
				{`${availableSlots}/${contestMaxValidProposals}`}
			</Fragment>
		);
	}

	getOtherTotalParticipants(contest) {
		const otherTotalParticipants = contest.maxSlots - contest.availableSlots;

		return (
			otherTotalParticipants > 1 &&
			<div className={classes.contributorsBubble}>
				{`+${otherTotalParticipants - 1}`}
			</div>
		);
	}

	renderProposalsSubmittedByExpert(contest) {
		// const totalSubmittedProposalsByExpert = PROPOSAL.getTotalApprovedAndPublishedProposals(contest.proposals)
		const totalSubmittedProposalsByExpert = contest.maxApprovedProposals - contest.availableApprovedProposals;

		return (
			<small>You have {totalSubmittedProposalsByExpert} / {contest.maxApprovedProposals} approved proposals</small>
		);
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onClosePreviewFileAndReopenProposalFeedback() {
		this.setState({ previewFile: null, openProposalFeedbackModal: true });
	}

	getPastParticipatingStatesList(project, statesExpertIsParticipating) {
		return statesExpertIsParticipating.filter(s => s !== project.state);
	}

	render() {
		const { match, userData: { user }, justJoinedContest } = this.props;
		const {
			openContestModal,
			openProposalFeedbackModal,
			proposalFeedback,
			activeTab,
			selectState,
			openStartingKitModal,
			uploading,
			previewFile,
			proposalFile,
			hideCoverImg,
			uploadSuccess,
			selectedProposal,
			userInfo
		} = this.state;
		const { id } = match.params;

		if (justJoinedContest === true) {
			this.setState({ openStartingKitModal: true });
		}

		return (
			<div className={classes.projectViewPage}>
				<ErrorBoundaryQuery query={GET_PROJECT} variables={{ id }}>
					{({ loading, error, data, refetch: refetchProject }) => {
						this.refetchProject = refetchProject;

						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<BreadcrumbSkeleton className={classes.breadcrumbBox} />
									<SkeletonPlaceholder className={classes.imgSkeleton} />
									<div className={classes.containerSkeleton}>
										<SkeletonText />
									</div>
									<div className={classes.containerSkeleton}>
										<SkeletonText />
										<SkeletonText />
										<SkeletonText />
										<SkeletonText />
									</div>
									<div className={classes.tags}>
										<TagSkeleton />
										<TagSkeleton />
									</div>
									<div className={classes.containerSkeleton}>
										<SkeletonText />
										<AccordionSkeleton
											className={classes.accordion}
											align='start'
											open={true}
											count={1}
										/>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const project = data && data.project;
						if (!project) {
							return (<div>No project</div>);
						}

						const projectIsFinished = project.state === PROJECT.STATE.CLOSED;
						const projectIsInPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST;
						const projectCode = project.code || '11-' + project.id.substr(project.id.length - 3).toUpperCase();

						const contest = PROJECT.getContestByState(project, project.state);
						const isContestEnded = contest && CONTEST.isContestEnded(contest);
						const isContestJoinable = contest && CONTEST.isContestJoinable(contest, project);
						const isContestOpen = contest && CONTEST.isContestOpen(contest, project);
						const isContestStarting = contest && CONTEST.isContestStarting(contest, project);
						const contestHasWinner = contest && CONTEST.hasWinner(contest);
						const contestHasRound = contest && CONTEST.hasRound(contest);

						const hasProposal = contest && contest.proposals.find(p => p.user.id === user.id);
						const contestProposals = (contest && contest.proposals) || [];

						const currentRound = contest && CONTEST.getOpenRound(contest);
						const newEndDate = currentRound && new Date(parseInt(currentRound.endDate));
						const endDate = currentRound && dates.substractDays(newEndDate, 1);
						const daysLeft = endDate && CONTEST.getContestDaysLeft(endDate) + 1;

						const countryCode = (project.owner.maker.countryResidence !== null)
							? COUNTRIES.getCountryCodeByCountry(project.owner.maker.countryResidence)
							: null;

						const unseenFileVersionsCount = contestProposals.reduce((acc, p) => acc + PROPOSAL.getUnseenFileVersions(p).length, 0);

						const isAParticipantExpert = EXPERT.isAParticipantExpert(project, user.id);
						const statesExpertIsParticipating = EXPERT.getStatesExpertIsParticipating(project, user.id);

						const contestHasWinningProposal = contest && CONTEST.hasWinner(contest);
						const winnerUserId = contest && PROPOSAL.getWinningProposalUserId(contest);
						const userIsWinnerOfContest = contestHasWinningProposal && (user.id === winnerUserId);

						const sharedFiles = contest && contest.sharedFiles;
						const hasSubmittedSharedFiles = sharedFiles && sharedFiles.length > 0;
						const sharedFilesHaveBeenValidated = hasSubmittedSharedFiles && PROPOSAL.sharedFilesHaveBeenValidated(sharedFiles);

						const pastParticipatingStates = this.getPastParticipatingStatesList(project, statesExpertIsParticipating);

						const userHasSignedNDA = userInfo && userInfo.hasSignedNDA ? true : false;

						return (
							<Mutation mutation={JOIN_CONTEST}>
								{(JoinContestMutation, { error }) => (
									<Fragment>
										<ScrollIndicator />
										<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
											<BreadcrumbItem>
												<Link to="/explore">
													{KEYS.breadcrumb_index}
												</Link>
											</BreadcrumbItem>
											<BreadcrumbItem isCurrentPage href="#">
												{project.name}
											</BreadcrumbItem>
										</Breadcrumb>
										<div className={classnames([classes.imgContainer, hideCoverImg && classes.hide])}>
											<img
												className={classes.cardImage}
												src={this.displayProjectImageUrl(project)}
												alt={KEYS.project_cover_alt}
											/>
										</div>
										<div className={classes.projectInfoContainer}>
											<div className={classes.infoContainer}>
												<div className={classes.moreInfo}>
													<div className={classes.projectInfo} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
														<h2>{strings.capitalizeFirstLetter(project.name)}</h2>
														<div className={classes.tags}>
															{project.prototypeSize && <Tag type="cool-gray" title={PROJECT.getSizeToString(project.prototypeSize)}>
																{PROJECT.getSizeToString(project.prototypeSize)}
															</Tag>}
															{project.hasIOT && <Tag type="cool-gray" title={KEYS.has_iot}>{KEYS.has_iot}</Tag>}
															{project.hasActuators && <Tag type="cool-gray" title={KEYS.has_actuators}>{KEYS.has_actuators}</Tag>}
															{project.electronics && <Tag type="cool-gray" title={KEYS.electronics}>{KEYS.electronics}</Tag>}
															{project.productEnvironment && <Tag type="cool-gray" title={PROJECT.getEnvironmentToString(project.productEnvironment)}>
																{PROJECT.getEnvironmentToString(project.productEnvironment)}
															</Tag>}
														</div>
													</div>
													<div className={classes.extraInfoBlock} style={{ position: 'relative' }}>
														<div className={classes.description}>
															<p style={!userHasSignedNDA ? { color: 'transparent', textShadow: '0 0 5px rgba(0,0,0,0.5)'}: {}}>{KEYS.project_description_text_1}{project.name}{KEYS.project_description_text_2}</p>
															<p style={!userHasSignedNDA ? { color: 'transparent', textShadow: '0 0 5px rgba(0,0,0,0.5)'}: {}}>{KEYS.project_description_text_3}{project.name}{KEYS.project_description_text_4}{strings.lowerFirstLetter(project.productNeed)}. {strings.capitalizeFirstLetter(project.productSolution)}</p>
														</div>
														{!userHasSignedNDA &&
															<TooltipIcon
																tooltipText={'Sign NDA to unlock more project’s info'}
																direction="bottom"
																style={{position: 'absolute', top: '1rem', right: '1rem', width: '16px'}}
															>
																<div className={classes.circleLocked}>
																	<Locked16 />
																</div>
															</TooltipIcon>
														}
													</div>
												</div>
												{isAParticipantExpert && (
													<div>
														<ExpandableTile className={classes.tile} expanded={false}>
															<div className={classes.topBox}>
																<small>{projectCode}</small>
																<h4 style={{ height: '36px' }}>Project details</h4>
															</div>
															<TileBelowTheFoldContent>
																{project.complexity &&
																	<div className={classes.infoElements}>
																		<h6>Complexity</h6>
																		<TooltipIcon
																			tooltipText={PROJECT.getProjectComplexity(project.complexity)}
																			direction='bottom' align='center'>
																			<p>{PROJECT.getProjectComplexityShort(project.complexity)}</p>
																			<Information16 style={{ marginLeft: '4px' }} />
																		</TooltipIcon>
																	</div>
																}
																{project.complexityMe &&
																	<div className={classes.infoElements}>
																		<h6>{KEYS.project_Me_complexity}</h6>
																		<p>{PROJECT.getProjectMEComplexity(project.complexityMe)}</p>
																	</div>
																}
																<div className={classes.infoElements}>
																	<h6>{KEYS.project_creation_date}</h6>
																	<p>{dates.formattedNewDate(project.createdAt)}</p>
																</div>
																<div className={classes.infoElements}>
																	<h6>{KEYS.project_country}</h6>
																	<ReactCountryFlag
																		svg
																		style={{fontSize: '1.5em' }}
																		countryCode={countryCode}
																		aria-label={project.owner.maker.countryResidence}
																	/>
																</div>
															</TileBelowTheFoldContent>
														</ExpandableTile>
													</div>
												)}
												{(!projectIsFinished && !isAParticipantExpert) &&
													<div className={classes.stateContainer}>
														<PhaseStatesBubbles
															project={project}
															state={project.state}
															displayOnClient={false}
															selectState={selectState || project.state}
															onClickPhase={(state) => this.setState({ selectState: state })}
														/>
													</div>
												}
											</div>
											<div className={classes.sideInfo}>
												<div className={classes.projectStatus}>
													<div className={classes.projectStatusTitle}>
														<h4>{KEYS.project_status_title}</h4>
														{userIsWinnerOfContest &&
															<TrophyFilled20 style={{ fill: '#ffc637'}} />
														}
													</div>
													{isContestStarting && (
														<InlineNotification
															className={classes.notification}
															kind="warning"
															lowContrast
															hideCloseButton
															title="Contest will open soon"
														/>
													)}
													{(isContestOpen && hasProposal) && (
														<InlineNotification
															className={classes.notification}
															kind="success"
															lowContrast
															hideCloseButton
															title={KEYS.project_status_notification_success}
														/>
													)}
													{isContestEnded && (
														<InlineNotification
															className={classes.notification}
															kind="error"
															lowContrast
															hideCloseButton
															title={KEYS.project_status_notification_contest_close}
														/>
													)}
													{projectIsFinished && (
														<InlineNotification
															className={classes.notification}
															kind="success"
															lowContrast
															hideCloseButton
															title="Project has finished"
														/>
													)}
													{(contest && !contestHasRound) && (
														<InlineNotification
															className={classes.notification}
															kind="warning"
															lowContrast
															hideCloseButton
															title={KEYS.project_status_notification_no_contest_available}
														/>
													)}
													{error && (
														<ToastNotification
															className={classes.notification}
															kind="error"
															title={KEYS.project_status_notification_error_joining}
															subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																? error.graphQLErrors[0].message
																: (error.networkError)
																	? error.networkError.message
																	: error.message}
															hideCloseButton={true}
															lowContrast={true}
															notificationType="inline"
															timeout={5000}
														/>
													)}
													<div className={classes.projectStatusBox}>
														<div className={classes.projectDetails}>
															<h5>{KEYS.project_status_phase}</h5>
															{project.state !== null ? (
																<Tag
																	type="blue"
																	style={{
																		borderRadius: '4px',
																		color: '#fff',
																		backgroundColor: CONTEST.getPhaseColor(project.state)
																	}}
																>
																	{PROJECT.stateToString(project.state)}
																</Tag>
															) : (
																<p>{KEYS.project_message_no_contest_available}</p>
															)}
														</div>
														<div className={classes.projectDetails}>
															<h5>{KEYS.project_status_contest_status}</h5>
															<p>
																{(isContestStarting) ? (
																	'Contest will open soon'
																) : (isContestOpen) ? (
																	hasProposal ? (
																		(contest.availableApprovedProposals > 0) ? (
																			'You can upload more proposals'
																		) : (
																			'You have reached the maximum approved proposals'
																		)
																	) : (
																		(isContestJoinable) ? (
																			KEYS.project_status_contest_status_open
																		) : (
																			'No available slots'
																		)
																	)
																) : (isContestEnded) ? (
																	(contestHasWinningProposal) ? (
																		'The contest has a winner'
																	) : (
																		'Wait till the client selects a winner'
																	)
																) : (projectIsFinished) ? (
																	'Project has finished'
																) : (
																	'No contest available'
																)}
															</p>
														</div>
														{contest && (
															<Fragment>
																{(isContestStarting || isContestOpen) && (
																	<div className={classes.projectDetails}>
																		<h5>{KEYS.project_status_slots}</h5>
																		<p>{this.displayContestSlots(contest)}</p>
																	</div>
																)}
																<div className={classes.projectDetails}>
																	<h5>Winning prize</h5>
																	<p>{CONTEST.getContestPrizes(contest.type)}</p>
																</div>
																{userIsWinnerOfContest && (
																	<div className={classes.projectDetails}>
																		<h5>Actions</h5>
																		<p>
																			{(!hasSubmittedSharedFiles) ? (
																				'You need to submit deliverables'
																			) : (!sharedFilesHaveBeenValidated) ? (
																				'Pending files validation from LastBasic'
																			) :	(
																				'Ready to start the payment process'
																			)}
																		</p>
																	</div>
																)}
															</Fragment>
														)}
														{(isContestOpen && endDate) &&
															<div className={classes.projectDetails}>
																<h5>{KEYS.project_status_contest_deadline}</h5>
																<p>
																	{dates.formattedDate(endDate)}
																	<small>
																		{daysLeft > 1
																			? ` (${daysLeft} days left)`
																			: daysLeft === 1
																				? ' (Last day!)'
																				: ' (Ended)'
																		}
																	</small>
																</p>
															</div>
														}
														{(isContestOpen && hasProposal) &&
															<div className={classes.projectDetails}>
																<h5>What to do next</h5>
																<span
																	className={classes.link}
																	onClick={() => this.setState({ openStartingKitModal: true })}
																>
																	Open instructions
																</span>
															</div>
														}
													</div>
													<div className={classes.actions}>
														{hasProposal ? (
															<Fragment>
																<Button
																	className={classes.btn}
																	style={{ marginBottom: '16px' }}
																	onClick={() => this.openContest()}
																	kind='tertiary'
																>
																	{KEYS.review_contest_card}
																</Button>
																{!contestHasWinner && (
																	<Mutation mutation={CREATE_PROPOSAL}>
																		{(createProposalMutation, {error}) => (
																			<Mutation mutation={ADD_FILE_TO_PROPOSAL}>
																				{(uploadFileMutation) => {
																					return (
																						<Fragment>
																							{error && (
																								<InlineNotification
																									kind="error"
																									lowContrast
																									title="Error"
																									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																										? error.graphQLErrors[0].message
																										: (error.networkError)
																											? error.networkError.message
																											: error.message}
																								/>
																							)}
																							<Button
																								className={classnames([classes.btn, (uploading || uploadSuccess) && classes.loadingBtn])}
																								kind='secondary'
																								style={{ marginBottom: '16px' }}
																								disabled={!isContestOpen ||
																									PROPOSAL.getTotalApprovedAndPublishedProposals(contest.proposals) >= 3 ||
																									contest.availableApprovedProposals === 0}
																								onClick={() => this.inputFileRef.current.click()}
																							>
																								{!isContestOpen ? (
																									'Contest closed'
																								) : (uploading) ? (
																									<InlineLoading
																										className={classes.inlineLoading}
																										description={uploading.filename}
																										status={'active'}
																									/>
																								) : (uploadSuccess) ? (
																									<InlineLoading
																										className={classes.inlineLoading}
																										description={'Proposal uploaded successfully'}
																										status={'finished'}
																									/>
																								) : (
																									projectIsInPrototypingPhase ? 'Upload a quotation' : 'Upload a proposal'
																								)}
																							</Button>
																							<input
																								id="upload-file"
																								name="upload-file"
																								ref={this.inputFileRef}
																								type="file"
																								accept=".pdf"
																								onChange={(e) => this.onClickUpload(e, project.id, contest, uploadFileMutation, createProposalMutation)}
																								style={{ display: 'none' }}
																							/>
																						</Fragment>
																					);
																				}}
																			</Mutation>
																		)}
																	</Mutation>
																)}
															</Fragment>
														) : (isContestJoinable &&
															<Button
																className={classes.btn}
																style={{ marginBottom: '8px'}}
																onClick={() => this.openContest()}
															>
																{KEYS.project_status_join_contest}
															</Button>
														)}
													</div>
													{hasProposal &&
														<div className={classes.projectStatusQuickAccess}>
															{!contestHasWinner
																?	this.renderProposalsSubmittedByExpert(contest)
																:	<small>Well done, you have unlocked new credits!</small>
															}
														</div>
													}
												</div>
												{isAParticipantExpert &&
													<div className={classes.contributorsContainer}>
														<h4 className={classes.title}>Your involvement</h4>
														<div style={{ display: 'flex' }}>
															{pastParticipatingStates.map((state, i) => (
																<div className={classes.contributorsBubble} style={{ backgroundColor: CONTEST.getPhaseColor(state) }} key={i}>
																	{state === PROJECT.STATE.MODEL_CONTEST
																		?	'3D'
																		:	state.substring(0, 2).toUpperCase()
																	}
																</div>
															))}
														</div>
													</div>
												}
												{contestProposals.length > 0 &&
													<div className={classes.contributorsContainer}>
														<h4 className={classes.title}>{KEYS.project_contributors_title}</h4>
														<div className={classes.contributorsList}>
															{this.getProposalUserInitials(contestProposals)}
															{this.getOtherTotalParticipants(contest)}
														</div>
													</div>
												}
											</div>
										</div>
										{(hasProposal || (isAParticipantExpert && !projectIsFinished)) ? (
											<div className={classes.tabContainer}>
												<Tabs
													className={classes.tabs}
													selectionMode="manual"
													selected={activeTab}
												>
													<Tab
														id="tab-entries"
														label={
															<Badge
																badgeContent={unseenFileVersionsCount}
																color="secondary"
																variant="dot"
															>
																{projectIsInPrototypingPhase
																	? 'My quotations'
																	: KEYS.tab_entries
																}
															</Badge>
														}
													>
														<EntriesTab
															contest={contest}
															contestAvailable={isContestOpen}
															hasProposal={hasProposal}
															isAParticipantExpert={isAParticipantExpert}
															onFileUploaded={() => refetchProject()}
															onSharedFileUploaded={() => refetchProject()}
															onOpenContest={() => this.setState({openContestModal: !openContestModal})}
															project={project}
															statesExpertIsParticipating={statesExpertIsParticipating}
															user={user}
														/>
													</Tab>
													<Tab id="tab-materials" label={KEYS.tab_materials}>
														<MaterialsTab
															project={project}
															user={user}
														/>
													</Tab>
													<Tab id="tab-guidelines" label={KEYS.tab_guidelines}>
														<GuidelinesTab
															project={project}
															user={user}
															// uncomment line below to show guidelines of participating contest only
															//statesExpertIsParticipating={statesExpertIsParticipating}
														/>
													</Tab>
													<Tab id="tab-updates" label={KEYS.tab_updates}>
														<UpdatesTab projectId={project.id} />
													</Tab>
													<Tab id="tab-inspiration" label={'Inspiration'}>
														<InspirationTab
															project={project}
															user={user}
															onClickProposal={(proposal, file, feedback) => this.setState({ openProposalFeedbackModal: true, proposalFeedback: feedback, proposalFile: file, selectedProposal: proposal })}
														/>
													</Tab>
												</Tabs>
											</div>
										) : (isContestJoinable &&
											<div className={classes.tabContainer} style={{ margin: '36px 0' }}>
												<h3 className={classes.title}>{KEYS.project_more_details_title}</h3>
												<Tabs className={classes.tabs} style={{ filter: 'blur(8px)', pointerEvents: 'none'}}>
													<Tab id="tab-1" label={KEYS.tab_materials}></Tab>
													<Tab id="tab-2" label={KEYS.tab_guidelines}></Tab>
													<Tab id="tab-3" label={KEYS.tab_updates}></Tab>
													<Tab id="tab-4" label={KEYS.tab_entries}></Tab>
												</Tabs>
												<div className={classes.lockContainer}>
													<div className={classes.lockContent}>
														<RawMaterialsTab
															project={project}
															user={user}
														/>
													</div>
													<div className={classes.lockBox}>
														<Locked32 />
														<h5 className={classes.lockInfo}>{KEYS.project_more_details_join_contest_text}</h5>
														<Button
															className={classes.btn}
															onClick={() => this.openContest()}
														>
															{KEYS.project_status_join_contest}
														</Button>
													</div>
												</div>
											</div>
										)}
										{(projectIsFinished && isAParticipantExpert) &&
											<div className={classes.tabContainer}>
												<Tabs className={classes.tabs}>
													<Tab id="tab-entries"
														label="All my entries">
														<EntriesTab
															contest={contest}
															contestAvailable={isContestOpen}
															hasProposal={hasProposal}
															isAParticipantExpert={isAParticipantExpert}
															onFileUploaded={() => refetchProject()}
															onOpenContest={() => this.setState({ openContestModal: !openContestModal })}
															onSharedFileUploaded={() => refetchProject()}
															project={project}
															statesExpertIsParticipating={statesExpertIsParticipating}
															user={user}
														/>
													</Tab>
													<Tab id="tab-materials" label={KEYS.tab_materials}>
														<MaterialsTab
															project={project}
															user={user}
														/>
													</Tab>
													<Tab id="tab-updates" label={KEYS.tab_updates}>
														<UpdatesTab projectId={project.id} />
													</Tab>
												</Tabs>
											</div>
										}
										{contest &&
											<ContestModal
												project={project}
												contest={contest}
												passiveModal={!!hasProposal}
												open={openContestModal}
												onRequestSubmit={() => this.onConfirmJoinProject(JoinContestMutation, contest, refetchProject, project.id)}
												onSecondarySubmit={() => this.setState({ openContestModal: false })}
												onRequestClose={() => this.setState({ openContestModal: false })}
											/>
										}
										{openProposalFeedbackModal &&
											<Modal
												passiveModal={true}
												open={openProposalFeedbackModal}
												modalLabel={KEYS.proposal_feedback_modal_label}
												onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
											>
												{proposalFeedback ?
													<ProposalFeedback
														proposal={selectedProposal}
														feedback={proposalFeedback}
														displayOnPublic={true}
														onPreviewProposalFile={() => this.setState({ openProposalFeedbackModal: false, previewFile: proposalFile })}
													/>
													: <p>{KEYS.proposal_feedback_modal_no_data}</p>
												}
											</Modal>
										}
										<PreviewFileModal
											open={!!previewFile}
											file={previewFile}
											goBackToProposal={() => this.onClosePreviewFileAndReopenProposalFeedback()}
											onRequestClose={() => this.onClosePreviewFile()}
										/>
										{openStartingKitModal &&
											<Modal
												passiveModal={true}
												open={openStartingKitModal}
												modalHeading={KEYS.starting_kit_modal_header}
												onRequestClose={() => this.setState({ openStartingKitModal: false })}
											>
												<div className={classes.modalContent}>
													<div className={classes.contestContent}>
														<h5>{KEYS.starting_kit_modal_content_title_1}</h5>
														<ol className={classes.listModal} style={{ listStyleType: 'decimal' }}>
															<li><strong>{KEYS.starting_kit_modal_content_overview_subtitle}</strong> {KEYS.starting_kit_modal_content_overview_text}</li>
															<li><strong>{KEYS.starting_kit_modal_content_materials_subtitle}</strong> {KEYS.starting_kit_modal_content_materials_text}</li>
															<li><strong>{KEYS.starting_kit_modal_content_guidelines_subtitle}</strong> {KEYS.starting_kit_modal_content_guidelines_text}</li>
															<li><strong>{KEYS.starting_kit_modal_content_updates_subtitle}</strong> {KEYS.starting_kit_modal_content_updates_text}</li>
															<li><strong>{KEYS.starting_kit_modal_content_entries_subtitle}</strong> {KEYS.starting_kit_modal_content_entries_text}</li>
														</ol>
													</div>
													<div className={classes.contestContent}>
														<h5>{KEYS.starting_kit_modal_content_title_2}</h5>
														<ol className={classes.listModal} style={{ listStyleType: 'disc' }}>
															<li>{KEYS.starting_kit_modal_content_recommendation_text_1}</li>
															<li>{KEYS.starting_kit_modal_content_recommendation_text_2}</li>
															<li>{KEYS.starting_kit_modal_content_recommendation_text_3}</li>
															<li>{KEYS.starting_kit_modal_content_recommendation_text_4}</li>
														</ol>
													</div>
													<div className={classes.contestContent}>
														<h5>{KEYS.starting_kit_modal_content_title_3}</h5>
														<ol className={classes.listModal} style={{ listStyleType: 'decimal' }}>
															<li>{KEYS.starting_kit_modal_content_upload_text_1}</li>
															<li>{KEYS.starting_kit_modal_content_upload_text_2}</li>
															<li>{KEYS.starting_kit_modal_content_upload_text_3}</li>
															<li>{KEYS.starting_kit_modal_content_upload_text_4}</li>
														</ol>
													</div>
												</div>
											</Modal>
										}
									</Fragment>
								)}
							</Mutation>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}
}

ProjectViewPage.propTypes = {
	userData: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	justJoinedContest: PropTypes.bool,
};

ProjectViewPage.defaultProps = {
	justJoinedContest: false,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(ProjectViewPage));
