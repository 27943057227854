import React, { Component } from 'react';
import PropTypes from 'prop-types';

const CONTEST_CARD_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';

class ContestCardImage extends Component {
	render() {
		const { src, type, ...rest } = this.props;
		return (
			<img
				src={src || CONTEST_CARD_PLACEHOLDER}
				alt={type === 'primary' ? 'Contest main file' : 'Contest secondary file'}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = CONTEST_CARD_PLACEHOLDER;
				}}
				{...rest}
			/>
		);
	}
}

ContestCardImage.propTypes = {
	src: PropTypes.string.isRequired,
	type: PropTypes.string,
};

ContestCardImage.defaultProps = {
	type: 'primary',
};

export default ContestCardImage;
