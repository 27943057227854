const COUNTRIES = [
	{ name: 'afghanistan', countryCode: 'af' },
	{ name: 'aland islands', countryCode: 'ax' },
	{ name: 'albania', countryCode: 'al' },
	{ name: 'algeria', countryCode: 'dz' },
	{ name: 'american samoa', countryCode: 'as' },
	{ name: 'andorra', countryCode: 'ad' },
	{ name: 'angola', countryCode: 'ao' },
	{ name: 'anguilla', countryCode: 'ai' },
	{ name: 'antigua', countryCode: 'ag' },
	{ name: 'argentina', countryCode: 'ar' },
	{ name: 'armenia', countryCode: 'am' },
	{ name: 'aruba', countryCode: 'aw' },
	{ name: 'australia', countryCode: 'au' },
	{ name: 'austria', countryCode: 'at' },
	{ name: 'azerbaijan', countryCode: 'az' },
	{ name: 'bahamas', countryCode: 'bs' },
	{ name: 'bahrain', countryCode: 'bh' },
	{ name: 'bangladesh', countryCode: 'bd' },
	{ name: 'barbados', countryCode: 'bb' },
	{ name: 'belarus', countryCode: 'by' },
	{ name: 'belgium', countryCode: 'be' },
	{ name: 'belize', countryCode: 'bz' },
	{ name: 'benin', countryCode: 'bj' },
	{ name: 'bermuda', countryCode: 'bm' },
	{ name: 'bhutan', countryCode: 'bt' },
	{ name: 'bolivia', countryCode: 'bo' },
	{ name: 'bosnia', countryCode: 'ba' },
	{ name: 'botswana', countryCode: 'bw' },
	{ name: 'bouvet island', countryCode: 'bv' },
	{ name: 'brazil', countryCode: 'br' },
	{ name: 'british virgin islands', countryCode: 'vg' },
	{ name: 'brunei', countryCode: 'bn' },
	{ name: 'bulgaria', countryCode: 'bg' },
	{ name: 'burkina faso', countryCode: 'bf' },
	{ name: 'burma', countryCode: 'mm', alias: 'Myanmar' },
	{ name: 'burundi', countryCode: 'bi' },
	{ name: 'caicos islands', countryCode: 'tc' },
	{ name: 'cambodia', countryCode: 'kh' },
	{ name: 'cameroon', countryCode: 'cm' },
	{ name: 'canada', countryCode: 'ca' },
	{ name: 'cape verde', countryCode: 'cv' },
	{ name: 'cayman islands', countryCode: 'ky' },
	{ name: 'central african republic', countryCode: 'cf' },
	{ name: 'chad', countryCode: 'td' },
	{ name: 'chile', countryCode: 'cl' },
	{ name: 'china', countryCode: 'cn' },
	{ name: 'christmas island', countryCode: 'cx' },
	{ name: 'cocos islands', countryCode: 'cc' },
	{ name: 'colombia', countryCode: 'co' },
	{ name: 'comoros', countryCode: 'km' },
	{ name: 'congo', countryCode: 'cd' },
	{ name: 'congo brazzaville', countryCode: 'cg' },
	{ name: 'cook islands', countryCode: 'ck' },
	{ name: 'costa rica', countryCode: 'cr' },
	{ name: 'cote divoire', countryCode: 'ci' },
	{ name: 'croatia', countryCode: 'hr' },
	{ name: 'cuba', countryCode: 'cu' },
	{ name: 'cyprus', countryCode: 'cy' },
	{ name: 'czech republic', countryCode: 'cz' },
	{ name: 'denmark', countryCode: 'dk' },
	{ name: 'djibouti', countryCode: 'dj' },
	{ name: 'dominica', countryCode: 'dm' },
	{ name: 'dominican republic', countryCode: 'do' },
	{ name: 'ecuador', countryCode: 'ec' },
	{ name: 'egypt', countryCode: 'eg' },
	{ name: 'el salvador', countryCode: 'sv' },
	{ name: 'england', countryCode: 'gb eng' },
	{ name: 'equatorial guinea', countryCode: 'gq' },
	{ name: 'eritrea', countryCode: 'er' },
	{ name: 'estonia', countryCode: 'ee' },
	{ name: 'ethiopia', countryCode: 'et' },
	{ name: 'europeanunion', countryCode: 'eu' },
	{ name: 'falkland islands', countryCode: 'fk' },
	{ name: 'faroe islands', countryCode: 'fo' },
	{ name: 'fiji', countryCode: 'fj' },
	{ name: 'finland', countryCode: 'fi' },
	{ name: 'france', countryCode: 'fr' },
	{ name: 'french guiana', countryCode: 'gf' },
	{ name: 'french polynesia', countryCode: 'pf' },
	{ name: 'french territories', countryCode: 'tf' },
	{ name: 'gabon', countryCode: 'ga' },
	{ name: 'gambia', countryCode: 'gm' },
	{ name: 'georgia', countryCode: 'ge' },
	{ name: 'germany', countryCode: 'de' },
	{ name: 'ghana', countryCode: 'gh' },
	{ name: 'gibraltar', countryCode: 'gi' },
	{ name: 'greece', countryCode: 'gr' },
	{ name: 'greenland', countryCode: 'gl' },
	{ name: 'grenada', countryCode: 'gd' },
	{ name: 'guadeloupe', countryCode: 'gp' },
	{ name: 'guam', countryCode: 'gu' },
	{ name: 'guatemala', countryCode: 'gt' },
	{ name: 'guinea', countryCode: 'gn' },
	{ name: 'guinea-bissau', countryCode: 'gw' },
	{ name: 'guyana', countryCode: 'gy' },
	{ name: 'haiti', countryCode: 'ht' },
	{ name: 'heard island', countryCode: 'hm' },
	{ name: 'honduras', countryCode: 'hn' },
	{ name: 'hong kong', countryCode: 'hk' },
	{ name: 'hungary', countryCode: 'hu' },
	{ name: 'iceland', countryCode: 'is' },
	{ name: 'india', countryCode: 'in' },
	{ name: 'indian ocean territory', countryCode: 'io' },
	{ name: 'indonesia', countryCode: 'id' },
	{ name: 'iran', countryCode: 'ir' },
	{ name: 'iraq', countryCode: 'iq' },
	{ name: 'ireland', countryCode: 'ie' },
	{ name: 'israel', countryCode: 'il' },
	{ name: 'italy', countryCode: 'it' },
	{ name: 'jamaica', countryCode: 'jm' },
	{ name: 'jan mayen', countryCode: 'sj', alias: 'Svalbard' },
	{ name: 'japan', countryCode: 'jp' },
	{ name: 'jordan', countryCode: 'jo' },
	{ name: 'kazakhstan', countryCode: 'kz' },
	{ name: 'kenya', countryCode: 'ke' },
	{ name: 'kiribati', countryCode: 'ki' },
	{ name: 'kuwait', countryCode: 'kw' },
	{ name: 'kyrgyzstan', countryCode: 'kg' },
	{ name: 'laos', countryCode: 'la' },
	{ name: 'latvia', countryCode: 'lv' },
	{ name: 'lebanon', countryCode: 'lb' },
	{ name: 'lesotho', countryCode: 'ls' },
	{ name: 'liberia', countryCode: 'lr' },
	{ name: 'libya', countryCode: 'ly' },
	{ name: 'liechtenstein', countryCode: 'li' },
	{ name: 'lithuania', countryCode: 'lt' },
	{ name: 'luxembourg', countryCode: 'lu' },
	{ name: 'macau', countryCode: 'mo' },
	{ name: 'macedonia', countryCode: 'mk' },
	{ name: 'madagascar', countryCode: 'mg' },
	{ name: 'malawi', countryCode: 'mw' },
	{ name: 'malaysia', countryCode: 'my' },
	{ name: 'maldives', countryCode: 'mv' },
	{ name: 'mali', countryCode: 'ml' },
	{ name: 'malta', countryCode: 'mt' },
	{ name: 'marshall islands', countryCode: 'mh' },
	{ name: 'martinique', countryCode: 'mq' },
	{ name: 'mauritania', countryCode: 'mr' },
	{ name: 'mauritius', countryCode: 'mu' },
	{ name: 'mayotte', countryCode: 'yt' },
	{ name: 'mexico', countryCode: 'mx' },
	{ name: 'micronesia', countryCode: 'fm' },
	{ name: 'moldova', countryCode: 'md' },
	{ name: 'monaco', countryCode: 'mc' },
	{ name: 'mongolia', countryCode: 'mn' },
	{ name: 'montenegro', countryCode: 'me' },
	{ name: 'montserrat', countryCode: 'ms' },
	{ name: 'morocco', countryCode: 'ma' },
	{ name: 'mozambique', countryCode: 'mz' },
	{ name: 'namibia', countryCode: 'na' },
	{ name: 'nauru', countryCode: 'nr' },
	{ name: 'nepal', countryCode: 'np' },
	{ name: 'netherlands', countryCode: 'nl' },
	{ name: 'netherlandsantilles', countryCode: 'an' },
	{ name: 'new caledonia', countryCode: 'nc' },
	{ name: 'new guinea', countryCode: 'pg' },
	{ name: 'new zealand', countryCode: 'nz' },
	{ name: 'nicaragua', countryCode: 'ni' },
	{ name: 'niger', countryCode: 'ne' },
	{ name: 'nigeria', countryCode: 'ng' },
	{ name: 'niue', countryCode: 'nu' },
	{ name: 'norfolk island', countryCode: 'nf' },
	{ name: 'north korea', countryCode: 'kp' },
	{ name: 'northern mariana islands', countryCode: 'mp' },
	{ name: 'norway', countryCode: 'no' },
	{ name: 'oman', countryCode: 'om' },
	{ name: 'pakistan', countryCode: 'pk' },
	{ name: 'palau', countryCode: 'pw' },
	{ name: 'palestine', countryCode: 'ps' },
	{ name: 'panama', countryCode: 'pa' },
	{ name: 'paraguay', countryCode: 'py' },
	{ name: 'peru', countryCode: 'pe' },
	{ name: 'philippines', countryCode: 'ph' },
	{ name: 'pitcairn islands', countryCode: 'pn' },
	{ name: 'poland', countryCode: 'pl' },
	{ name: 'portugal', countryCode: 'pt' },
	{ name: 'puerto rico', countryCode: 'pr' },
	{ name: 'qatar', countryCode: 'qa' },
	{ name: 'reunion', countryCode: 're' },
	{ name: 'romania', countryCode: 'ro' },
	{ name: 'russia', countryCode: 'ru' },
	{ name: 'rwanda', countryCode: 'rw' },
	{ name: 'saint helena', countryCode: 'sh' },
	{ name: 'saint kitts and nevis', countryCode: 'kn' },
	{ name: 'saint lucia', countryCode: 'lc' },
	{ name: 'saint pierre', countryCode: 'pm' },
	{ name: 'saint vincent', countryCode: 'vc' },
	{ name: 'samoa', countryCode: 'ws' },
	{ name: 'san marino', countryCode: 'sm' },
	{ name: 'sandwich islands', countryCode: 'gs' },
	{ name: 'sao tome', countryCode: 'st' },
	{ name: 'saudi arabia', countryCode: 'sa' },
	{ name: 'scotland', countryCode: 'gb sct' },
	{ name: 'senegal', countryCode: 'sn' },
	{ name: 'serbia', countryCode: 'cs' },
	{ name: 'serbia', countryCode: 'rs' },
	{ name: 'seychelles', countryCode: 'sc' },
	{ name: 'sierra leone', countryCode: 'sl' },
	{ name: 'singapore', countryCode: 'sg' },
	{ name: 'slovakia', countryCode: 'sk' },
	{ name: 'slovenia', countryCode: 'si' },
	{ name: 'solomon islands', countryCode: 'sb' },
	{ name: 'somalia', countryCode: 'so' },
	{ name: 'south africa', countryCode: 'za' },
	{ name: 'south korea', countryCode: 'kr' },
	{ name: 'spain', countryCode: 'es' },
	{ name: 'sri lanka', countryCode: 'lk' },
	{ name: 'sudan', countryCode: 'sd' },
	{ name: 'suriname', countryCode: 'sr' },
	{ name: 'swaziland', countryCode: 'sz' },
	{ name: 'sweden', countryCode: 'se' },
	{ name: 'switzerland', countryCode: 'ch' },
	{ name: 'syria', countryCode: 'sy' },
	{ name: 'taiwan', countryCode: 'tw' },
	{ name: 'tajikistan', countryCode: 'tj' },
	{ name: 'tanzania', countryCode: 'tz' },
	{ name: 'thailand', countryCode: 'th' },
	{ name: 'timorleste', countryCode: 'tl' },
	{ name: 'togo', countryCode: 'tg' },
	{ name: 'tokelau', countryCode: 'tk' },
	{ name: 'tonga', countryCode: 'to' },
	{ name: 'trinidad', countryCode: 'tt' },
	{ name: 'tunisia', countryCode: 'tn' },
	{ name: 'turkey', countryCode: 'tr' },
	{ name: 'turkmenistan', countryCode: 'tm' },
	{ name: 'tuvalu', countryCode: 'tv' },
	{ name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
	{ name: 'uganda', countryCode: 'ug' },
	{ name: 'ukraine', countryCode: 'ua' },
	{ name: 'united kingdom', countryCode: 'gb', alias: 'uk' },
	{ name: 'united states of america', countryCode: 'us', alias: 'America' },
	{ name: 'uruguay', countryCode: 'uy' },
	{ name: 'US Minor islands', countryCode: 'um' },
	{ name: 'US Virgin islands', countryCode: 'vi' },
	{ name: 'uzbekistan', countryCode: 'uz' },
	{ name: 'vanuatu', countryCode: 'vu' },
	{ name: 'vatican city', countryCode: 'va' },
	{ name: 'venezuela', countryCode: 've' },
	{ name: 'vietnam', countryCode: 'vn' },
	{ name: 'wales', countryCode: 'gb wls' },
	{ name: 'wallis and futuna', countryCode: 'wf' },
	{ name: 'western sahara', countryCode: 'eh' },
	{ name: 'yemen', countryCode: 'ye' },
	{ name: 'zambia', countryCode: 'zm' },
	{ name: 'zimbabwe', countryCode: 'zw' },
];

export default {
	getCountryCodeByCountry: function (country) {
		const entry = COUNTRIES.find(c => c.name.toLowerCase() === country);
		if (!entry) return null;
		return entry.countryCode;
	},
	getCountryByCountryCode: function (countryCode) {
		const entry = COUNTRIES.find(c => c.countryCode === countryCode);
		if (!entry) return null;
		return entry.name;
	},
	getEntries: function () {
		return COUNTRIES;
	},
	getEntryByCountry: function (country) {
		return COUNTRIES.find(c => c.name.toLowerCase() === country);
	}
};
