import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	Modal,
	Button,
	InlineLoading,
	InlineNotification,
} from 'carbon-components-react';

import { SUBMIT_PROJECT_DRAFT } from '../../providers/mutations';

import classes from './modals.module.scss';

class SubmitProjectModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			success: false,
		};
	}

	async onSubmitProject(e, projectId, submitProject) {
		const { onRequestSubmit } = this.props;
		e.preventDefault();

		try {
			await submitProject({
				variables: {
					projectId: projectId,
					fields: {
						submitted: true
					},
				}
			});
		} finally {
			this.setState({ success: true });
			setTimeout(() => {
				this.setState({ success: false });
				onRequestSubmit();
			}, 1000);
		}
	}

	render() {
		const { open, project, onRequestClose, ...rest } = this.props;
		const { success } = this.state;

		const projectName = project !== null ? project.name : 'Untitled project';

		return (
			<Mutation mutation={SUBMIT_PROJECT_DRAFT}>
				{(submitProject, { error }) => {
					return (
						<Modal
							passiveModal={true}
							open={open}
							modalHeading="Submit your project draft"
							modalLabel={projectName}
							onRequestClose={onRequestClose}
							{...rest}
						>
							<div className={classes.modalContent}>
								{error &&
									<InlineNotification
										className={classes.notification}
										kind="error"
										lowContrast
										title=""
										subtitle={(error.graphQLErrors && error.graphQLErrors.length)
											? error.graphQLErrors[0].message
											: (error.networkError)
												? error.networkError.message
												: error.message}
									/>
								}
								<div style={{ fontSize: '17px', marginBottom: '20px' }}>
									Your project &quot;{projectName}&quot; is ready to be submitted!
									Submit it now and we will check if it a feasible project for <span style={{ fontWeight: 700 }}>free</span> (monthly spots available).
									Remember that <span style={{ fontWeight: 700 }}>the intellectual property of your project belongs 100% to you</span>.
								</div>
								<div className={classes.btnBox}>
									{ success &&
										<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={'Project submitted!'}
											status={'finished'}
										/>
									}
									{ !success &&
										<Button
											className={classes.btn}
											onClick={(e) => this.onSubmitProject(e, project.id, submitProject)}>
												Submit project
										</Button>
									}
								</div>
							</div>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

SubmitProjectModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

SubmitProjectModal.defaultProps = {
	open: false,
	project: {},
	onRequestSubmit() {},
	onRequestClose() {},
};

export default SubmitProjectModal;
