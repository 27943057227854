import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';

import {
	SkeletonText,
	CodeSnippetSkeleton,
	InlineNotification,
	NotificationActionButton,
	ProgressIndicatorSkeleton,
	SkeletonPlaceholder
} from 'carbon-components-react';

import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import ProjectSetUpConfirmationModal from '../../../components/modals/project-setup-modal';
import ProjectOnboarding from './project-onboarding';
import ProjectFeasibility from './project-feasibility';
import ProjectBoard from './project-board';

import { GET_PROJECT } from '../../../providers/queries';
import { CONFIRM_PROJECT_ONBOARDING } from '../../../providers/mutations';

import withRouter from '../../../components/route/with-router';

import { PROJECT } from '../../../constants';
import { projectStorage } from '../../../utils';

import classes from './client-project-page.module.scss';

class ClientProjectPage extends Component {
	constructor (props) {
		super(props);

		this.state = {
			alertProjectOnboardingConfirmation: false,
		};
	}

	componentDidMount() {
		document.title = 'My project - LastBasic';
	}

	onCloseProjectOnboardingModal = () => {
		this.setState({ alertProjectOnboardingConfirmation: false });
	};

	onSubmitProjectOnboarding = async (confirmProjectOnboarding, project, refetchProject) => {
		let error;
		try {
			const result = await confirmProjectOnboarding({
				variables: {
					projectId: project.id,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		this.setState({
			alertProjectOnboardingConfirmation: false,
		});

		if (error) {
			// Error => Ignore
		} else {
			await refetchProject();
		}
	};

	render() {
		const { alertProjectOnboardingConfirmation } = this.state;
		const { match } = this.props;

		const selectedProject = match?.params?.id || projectStorage.getProject();
		projectStorage.storeProject(selectedProject);

		return (
			<div className={classes.projectContainer}>
				<div className={classes.content}>
					<ErrorBoundaryQuery query={GET_PROJECT} variables={{ id: selectedProject }}>
						{({ loading, error, data, refetch: refetchProject }) => {
							if (loading) {
								return (
									<div>
										<SkeletonText className={classes.subtitle} style={{ width: '150px' }} />
										<div className={classes.contestsStates}>
											<div className={classes.skeletonSteps}>
												<SkeletonPlaceholder className={classes.skeletonBubble} />
												<SkeletonPlaceholder className={classes.skeletonBubble} />
												<SkeletonPlaceholder className={classes.skeletonBubble} />
												<SkeletonPlaceholder className={classes.skeletonBubble} />
												<SkeletonPlaceholder className={classes.skeletonBubble} />
											</div>
										</div>
										<SkeletonText className={classes.subtitle} style={{ width: '250px' }} />
										<CodeSnippetSkeleton type="multi" style={{ margin: '16px 0' }} />
										<div className={classes.skeletonProgressIndicator}>
											<ProgressIndicatorSkeleton />
										</div>
										<div className={classes.skeletonContestContent}>
											<SkeletonPlaceholder className={classes.skeletonContestCard} />
											<SkeletonPlaceholder className={classes.skeletonContestBox} />
										</div>
									</div>
								);
							}

							if (error) {
								if (selectedProject.id) {
									return (
										<InlineNotification
											className={classes.notification}
											kind="error"
											title=""
											lowContrast
											hideCloseButton
											subtitle={(error.graphQLErrors && error.graphQLErrors.length)
												? error.graphQLErrors[0].message
												: (error.networkError)
													? error.networkError.message
													: error.message}
										/>
									);
								}

								return (
									<InlineNotification
										kind="info"
										lowContrast
										hideCloseButton
										iconDescription="Info"
										title=""
										subtitle={'You need to select a project'}
										actions={<NotificationActionButton><Link to="/projects">Choose a project</Link></NotificationActionButton>}
									/>
								);
							}

							const project = data && data.project;
							if (!project) {
								return (<div>No project</div>);
							}

							const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
							const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
							const projectIsPendingPayment = project.feasibility === PROJECT.FEASIBILITY.YES && !project.paid;

							const projectIsInActivePhase = project.state !== PROJECT.STATE.CLOSED && project.state !== PROJECT.STATE.CLIENT_ONBOARDING;

							if (projectIsPendingFeasibility || projectIsNonFeasible || (projectIsPendingPayment && !projectIsInActivePhase)) {
								return (
									<ProjectFeasibility project={project} />
								);
							}

							const isClientOnboarding = project.state === PROJECT.STATE.CLIENT_ONBOARDING;

							if (isClientOnboarding) {
								return (
									<Mutation mutation={CONFIRM_PROJECT_ONBOARDING}>
										{(confirmProjectOnboarding) => (
											<Fragment>
												<ProjectOnboarding
													project={project}
													projectOnboardingConfirmed={() => this.setState({ alertProjectOnboardingConfirmation: true })}
												/>
												{alertProjectOnboardingConfirmation && (
													<ProjectSetUpConfirmationModal
														open={alertProjectOnboardingConfirmation}
														onRequestSubmit={() => this.onSubmitProjectOnboarding(confirmProjectOnboarding, project, refetchProject)}
														onSecondarySubmit={this.onCloseProjectOnboardingModal}
														onRequestClose={this.onCloseProjectOnboardingModal}
													/>
												)}
											</Fragment>
										)}
									</Mutation>
								);
							}

							return (
								<ProjectBoard project={project} refetchProject={refetchProject} />
							);
						}}
					</ErrorBoundaryQuery>
				</div>
			</div>
		);
	}
}

ClientProjectPage.propTypes = {
	userData: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

ClientProjectPage.defaultProps = {
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(ClientProjectPage));
