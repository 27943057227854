import PropTypes from 'prop-types';

function ForceReload ({ path }) {
	if (path) {
		window.location.href = path;
	} else {
		window.location.reload();
	}
	return null;
}

ForceReload.propTypes = {
	path: PropTypes.string
};

export default ForceReload;
