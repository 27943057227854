import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import Grid from '@mui/material/Grid';
import {
	Button,
	Checkbox,
	InlineNotification,
	NotificationActionButton,
	PasswordInput,
	TextInput
} from 'carbon-components-react';

import Config from '../../../config';
import withRouter from '../../../components/route/with-router';
import { LOGIN_MUTATION } from '../../../providers/mutations';
import * as actions from '../../../reducers/user/actions';

import classes from './pages.module.scss';

const LB_LOGO_URL = '/assets/images/lastbasic-logo.png';

class LoginPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			rememberMe: true,
		};
	}

	onChangeUsername(event) {
		this.setState({ username: event.target.value });
	}

	onChangePassword(event) {
		this.setState({ password: event.target.value });
	}
	
	onChangeRememberMe(value) {
		this.setState({ rememberMe: value });
	}

	onSubmit = async (e, loginMutation) => {
		e.preventDefault();
		
		const { login, searchParams } = this.props;
		const { username, password, rememberMe } = this.state;

		const isSuccess = await login(loginMutation, {
			username,
			password
		}, rememberMe);

		if (isSuccess) {
			window.Froged('track', 'on_login');

			if (searchParams.get('next')) {
				window.location.href = searchParams.get('next');
			}
		}
	};

	render() {
		const { searchParams } = this.props;
		const { username, password, rememberMe } = this.state;

		const passwordCreated = searchParams.has('passwordCreated');

		return (
			<div className={classes.root}>
				<Grid
					className={classes.parentGrid}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<div className={classes.brandContainer}>
						<img className={classes.logo} src={LB_LOGO_URL} alt="LastBasic logo" />
					</div>
					<Grid item>
						<div className={classes.cardContent}>
							<h2 className={classes.title}>
								Login
							</h2>
							<Mutation mutation={LOGIN_MUTATION}>
								{(loginMutation, { loading, error }) => {
									let alertComponent;
									
									if (error) {
										alertComponent = (
											<Fragment>
												<InlineNotification
													className={classes.notification}
													kind="error"
													lowContrast
													hideCloseButton
													title=""
													subtitle={(error.graphQLErrors && error.graphQLErrors.length)
														? error.graphQLErrors[0].message
														: (error.networkError)
															? error.networkError.message
															: error.message}
												/>
												{error.graphQLErrors && error.graphQLErrors.length > 0 &&
													(error.graphQLErrors[0].code === 403) &&
													(error.graphQLErrors[0].message.indexOf('NDA') > -1) &&
													<InlineNotification
														className={classes.notification}
														kind="warning"
														lowContrast
														hideCloseButton
														iconDescription="Info"
														title="Unauthorized"
														subtitle={'You need to sign NDA to continue'}
														actions={
															<NotificationActionButton>
																<a
																	href={Config.eversign.forms.ndaUrl}
																	target='_blank'
																	rel="noreferrer"
																>
																	Click here to sign NDA
																</a>
															</NotificationActionButton>
														}
													/>
												}
												{error.graphQLErrors && error.graphQLErrors.length > 0 &&
													(error.graphQLErrors[0].code === 403) &&
													(error.graphQLErrors[0].message.indexOf('project briefing') > -1) &&
													<InlineNotification
														className={classes.notification}
														kind="warning"
														lowContrast
														hideCloseButton
														iconDescription="Info"
														title="Unauthorized"
														subtitle={(error.graphQLErrors && error.graphQLErrors.length)
															? error.graphQLErrors[0].message
															: (error.networkError)
																? error.networkError.message
																: error.message}
														actions={
															<NotificationActionButton>
																<a
																	href={Config.lastbasic.web.host + '/start-a-project'}
																	target='_blank'
																	rel="noreferrer"
																>
																	Start project briefing
																</a>
															</NotificationActionButton>
														}
													/>
												}
												{error.graphQLErrors && error.graphQLErrors.length > 0 &&
													(error.graphQLErrors[0].code === 402) &&
													<InlineNotification
														className={classes.notification}
														kind="warning"
														lowContrast
														hideCloseButton
														iconDescription="Info"
														title="Payment required"
														subtitle={'Contact our sales team to close the payment process of your project to get access to our platform'}
														actions={
															<NotificationActionButton>
																<a href="mailto:luisocleiry@lastbasic.com?Subject=I%20want%20to%20reactivate%20my%20account&body=Let%20me%20know%20the%20next%20steps%20to%20proceed%20with%20my%20payment.">
																	Click here to contact us
																</a>
															</NotificationActionButton>
														}
													/>
												}
											</Fragment>
										);
									} else if (passwordCreated) {
										alertComponent = (
											<InlineNotification
												className={classes.notification}
												kind="success"
												lowContrast
												hideCloseButton
												title=""
												subtitle={'Password changed. Go ahead and login'}
											/>	
										);
									}
									
									return (
										<Fragment>
											{alertComponent}
											<form className={classes.form} onSubmit={(e) => this.onSubmit(e, loginMutation)}>
												<TextInput
													id="email-input"
													className={classes.textInput}
													labelText="Email"
													type="email"
													value={username}
													onChange={(e) => this.onChangeUsername(e)}
													required
												/>
												<PasswordInput
													id="password-input"
													className={classes.textInput}
													labelText="Password"
													value={password}
													autoComplete="current-password"
													onChange={(e) => this.onChangePassword(e)}
													required
												/>
												<Checkbox
													id="remember-me"
													className={classes.rememberMe}
													defaultChecked={rememberMe}
													onChange={(e) => this.onChangeRememberMe(e)}
													labelText="Remember me"
												/>
												<div className={classes.btnBox}>
													<Button
														className={classes.submitButton}
														type="submit"
														disabled={loading}>
														{loading ? 'Loading...' : 'Log in'}
													</Button>
												</div>
												<Link
													className={classes.additionalLinks}
													to="/forgot-password">
													Forgot Password?
												</Link>
											</form>
										</Fragment>
									);
								}}
							</Mutation>
						</div>
					</Grid>
					<a
						className={classes.additionalLinks}
						href={Config.lastbasic.web.host + '/expertbriefing'}
						target='_blank'
						rel="noreferrer"
					>
						Not registered yet? Sign up as an expert!
					</a>
				</Grid>
			</div>
		);
	}
}

LoginPage.propTypes = {
	location: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	searchParams: PropTypes.object.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string,
	}),
};

LoginPage.defaultProps = {
	user: {},
	login() {},
	location: {},
};

const mapStateToProps = ({ user }) => ({
	user,
});

const mapDispatchToProps = dispatch => ({
	login: (mutation, data, rememberMe) => dispatch(actions.login(mutation, data, rememberMe)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(LoginPage));
