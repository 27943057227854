import { gql } from '@apollo/client';

const basicUserFragment = gql`
	fragment BasicUserDetails on User {
		firstName,
		lastName,
	}
`;

const privateUserFragment = gql`
	fragment Statistics on ExpertStatistics {
		sentProposals,
		joinedContests,
		winningSubmissions,
	}

	fragment PrivateExpertDetails on PrivateExpert {
		countryResidence,
		expertType,
		expertExpertise,
		expertSecondaryExpertises,
		yearsExperience,
		statistics {
			...Statistics
		},
		subscribeNewsletter,
		paymentInfo {
			...PaymentInfoDetails
		},
		ndaSignedAt,
		canValidateProjects,
	}

	fragment PaymentInfoDetails on PaymentInfoDetails {
		invoiceType,
	}

	fragment PrivateMakerDetails on PrivateMaker {
		countryResidence,
		ownedProjectIds,
		deliveryAddress {
			...DeliveryAddress
		},
		subscribeNewsletter
	}

	fragment DeliveryAddress on DeliveryAddress {
		street,
		postalCode,
		city,
		country,
		phoneNumber,
	}

	fragment PrivateAdminDetails on PrivateAdmin {
		role,
	}

	fragment PrivateUserDetails on PrivateUser {
		id,
		firstName,
		lastName,
		email,
		hasAvatar,
		expert {
			...PrivateExpertDetails,
		},
		maker {
			...PrivateMakerDetails,
		},
		admin {
			...PrivateAdminDetails,
		},
		emailNotificationsEnabled,
		pushNotificationsEnabled,
		passwordLastUpdatedAt,
		referralCode,
		createdAt,
	}
`;

const userFragment = gql`
	fragment ExpertDetails on Expert {
		countryResidence,
		expertType,
		expertExpertise,
	}

	fragment MakerDetails on Maker {
		countryResidence,
	}

	fragment AdminDetails on Admin {
		role,
	}

	fragment UserDetails on User {
		id,
		firstName,
		lastName,
		expert {
			...ExpertDetails
		},
		maker {
			...MakerDetails
		},
		admin {
			...AdminDetails
		},
	}
`;

const clientAddress = gql`
	fragment PrivateMakerDeliveryAddressDetails on PrivateMaker {
		countryResidence,
		deliveryAddress {
			...DeliveryAddress
		}
	}

	fragment PrivateUserDeliveryAddressDetails on PrivateUser {
		maker {
			...PrivateMakerDeliveryAddressDetails,
		}
	}

	fragment DeliveryAddress on DeliveryAddress {
		street,
		postalCode,
		city,
		country,
		phoneNumber,
	}
`;

export {
	basicUserFragment,
	clientAddress,
	privateUserFragment,
	userFragment,
};
