import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import config from '../../../config';

import { SUBMIT_PROJECT_DRAFT, CREATE_PROJECT_RESERVATION_LINK } from '../../../providers/mutations';

import classes from './new-project.module.scss';

import OfferingStep from './steps/offering';
import DetailsStep from './steps/details';
import SelectStep from './steps/select';
import ItemsStep from './steps/items';

const OFFERING_STEP = 'offering';
const DETAILS_STEP = 'details';
const SELECTION_STEP = 'selection';
const ITEMS_STEP = 'items';

export default function NewProjectPage() {
	document.title = 'New project - LastBasic';

	const [activeSaveButton, setActiveSaveButton] = useState(true);
	const [activeStep, setActiveStep] = useState(OFFERING_STEP);
	const [projectId, setProjectId] = useState(null);
	const [data, setData] = useState({
		features: ['validation', 'prototype'],
	});

	const [submitProject] = useMutation(SUBMIT_PROJECT_DRAFT);
	const [createProjectReservationLink] = useMutation(CREATE_PROJECT_RESERVATION_LINK);
	const [apiResponse, setApiResponse] = useState({});

	function buildPayload() {
		const details = data && data.details || {};
		const features = data && data.features || [];

		const payload = { submitted: true };

		if (details.projectName) payload.project_name = details.projectName;
		if (details.productNeed) payload.product_need = details.productNeed;
		if (details.productSolution) payload.product_solution = details.productSolution;
		if (details.electronics) payload.has_electronics = details.electronics !== 'none';
		if (details.electronics && details.electronics !== 'none') payload.electronic_design = details.electronics;

		if (features.length) payload.features = features;

		return payload;
	}

	async function saveProject() {
		const payload = buildPayload();

		const variables = { fields: payload };
		if (projectId) variables.projectId = projectId;

		const result = await submitProject({ variables });

		const response = result.data.createProjectBriefing;
		if (!response) return alert('Error saving project');

		setApiResponse(response);
		setProjectId(response.projectId);

		return response.projectId;
	}

	function updateData(key, input) {
		const newData = data;
		newData[key] = input;
		setData(newData);
	}

	function navigatePage(page) {
		setActiveStep(page);
		window.scrollTo(0, 0);
	}

	function redirectToProjectPage() {
		return window.location.replace('/project/' + projectId + '/validation');
	}

	async function redirectProjectReservationLink() {
		setActiveSaveButton(false);
		const result = await createProjectReservationLink({ variables: { projectId } });
		setActiveSaveButton(true);

		const link = result.data.createProjectReservationLink;
		if (!link) return alert('Error creating project reservation link');

		return window.location.replace(link);
	}

	async function saveAndNext() {
		setActiveSaveButton(false);
		const newProjectId = await saveProject();
		window.location.href = config.lastbasic.platform.host + '/project/' + newProjectId + '/validation';
	}

	return (
		<div className={classes.page}>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					{ activeStep === OFFERING_STEP && <OfferingStep onNext={() => navigatePage(DETAILS_STEP)} /> }
					{ activeStep === DETAILS_STEP && <DetailsStep activeButton={activeSaveButton} onNext={saveAndNext} detailsUpdate={(data) => updateData('details', data) } /> }
					{ activeStep === SELECTION_STEP && <SelectStep data={data} projectName={data.details && data.details.projectName || ''} back={() => navigatePage(DETAILS_STEP)} featuresUpdate={(data) => updateData('features', data) } onNext={() => { navigatePage(ITEMS_STEP); saveProject(); }} />}
					{ activeStep === ITEMS_STEP && <ItemsStep activeButton={activeSaveButton} data={data} back={() => navigatePage(SELECTION_STEP)} apiResponse={apiResponse} redirectToProjectPage={redirectToProjectPage} redirectProjectReservationLink={redirectProjectReservationLink} />}
				</div>
			</div>
		</div>
	);
}
