import React, { Component } from 'react';
import {
	Tag,
	Select,
	SelectItem,
	SelectItemGroup
} from 'carbon-components-react';

import {
	GET_CONTESTS_INVOLVED,
	GET_CONTESTS_BY_STATE
} from '../../../providers/queries';

import ContestList from './components/contest-list';
import { PROJECT } from '../../../constants';
import KEYS from '../../../glossary';

import classes from './contest-page.module.scss';

const CONTENT_TYPE = {
	ALL: 'all',
	INVOLVED: 'involved',
};

class ContestsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedContentType: CONTENT_TYPE.ALL,
			filterBy: null
		};
	}

	componentDidMount() {
		document.title = 'Open contests - LastBasic';
	}

	onClickTag(contentType) {
		this.setState({ selectedContentType: contentType });
	}

	onChangeFilteredPhase = (e) => {
		const value = e.target.value;

		this.setState({
			filterBy: value,
			selectedContentType: value,
		});
		if (!value) {
			return this.setState({
				filterBy: null,
			});
		}
	};

	renderContestList() {
		const { selectedContentType } = this.state;

		switch (selectedContentType) {
			case CONTENT_TYPE.INVOLVED:
				return <ContestList query={GET_CONTESTS_INVOLVED} />;
			case PROJECT.STATE.SKETCH_CONTEST:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: 'sketch', status: 'open'}} />;
			case PROJECT.STATE.MODEL_CONTEST:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: 'model', status: 'open'}} />;
			case PROJECT.STATE.ELECTRICAL_CONTEST:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: 'electrical', status: 'open'}} />;
			case PROJECT.STATE.MECHANICAL_CONTEST:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: 'mechanical', status: 'open'}} />;
			case PROJECT.STATE.PROTOTYPING_CONTEST:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: 'prototyping', status: 'open'}} />;
			case CONTENT_TYPE.ALL:
			default:
				return <ContestList query={GET_CONTESTS_BY_STATE} filter={{type: '', status: 'open'}} />;
		}
	}

	render() {
		const { filterBy } = this.state;
		const phases = PROJECT.getContestStates();

		return (
			<div className={classes.contestList}>
				<h2 className={classes.title}>Open contests</h2>
				<div className={classes.filtersContainer}>
					<Tag type="teal" onClick={() => this.onClickTag(CONTENT_TYPE.ALL)}>{KEYS.all}</Tag>
					<Tag type="green" onClick={() => this.onClickTag(CONTENT_TYPE.INVOLVED)}>Participating</Tag>
				</div>
				<div className={classes.filterPhaseBox}>
					<Select
						id="filter-contest-by-phase-selector"
						labelText=""
						light
						defaultValue={filterBy && filterBy}
						onChange={(e) => this.onChangeFilteredPhase(e)}>
						<SelectItem
							value=""
							text="All phases"
						/>
						<SelectItemGroup label="Contest phases">
							{phases.map((p, i) => (
								<SelectItem text={PROJECT.stateToString(p)} value={p} key={i} />
							))}
						</SelectItemGroup>
					</Select>
				</div>
				{this.renderContestList()}
			</div>
		);
	}
}

export default ContestsPage;