import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	DataTable,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableHeader,
	TableBody,
	TableSelectAll,
	TableSelectRow,
	TableCell,
	TableBatchActions,
	TableBatchAction,
	TableToolbar,
	TableToolbarSearch,
	TableToolbarContent,
	Tag
} from 'carbon-components-react';
import { Download16 as Download } from '@carbon/icons-react';
import RatingValue from '../../../../../components/project/rating-value';
import { PAYMENTS } from '../../../../../constants';
import { dates } from '../../../../../utils';

class ActivityTable extends Component {

	getPersonalizedRowContent(row) {
		const concept = row.cells.filter(c => (c.info.header === 'concept')).map(c => c.value);
		const date = row.cells.filter(c => (c.info.header === 'date')).map(c => c.value);
		const type = row.cells.filter(c => (c.info.header === 'type')).map(c => c.value);
		const rating = row.cells.filter(c => (c.info.header === 'rating')).map(c => c.value);
		const credit = row.cells.filter(c => (c.info.header === 'credit')).map(c => c.value);
		const income = row.cells.filter(c => (c.info.header === 'income')).map(c => c.value);

		return (
			<>
				<TableCell>{concept}</TableCell>
				<TableCell>{date}</TableCell>
				<TableCell><Tag type={PAYMENTS.getTypeColorTag(type[0])} style={{ borderRadius: '4px'}}>{PAYMENTS.getTransactionType(type[0])}</Tag></TableCell>
				<TableCell>{rating > 0 ? <RatingValue rating={Number(rating)} /> : '-'}</TableCell>
				<TableCell>{credit} credits</TableCell>
				<TableCell>{income}€</TableCell>
			</>
		);
	}

	downloadContentAsCSV(headers, selectedRows) {
		let csvHeaders = headers.map(h => h.header);
		let newSelectedRows = selectedRows.map(r => r.cells);
		let newFilteredSelectedRows = newSelectedRows.map((r) => r.map(v => v.value));
		newFilteredSelectedRows.unshift(csvHeaders);
		let csvContent = 'data:text/csv;charset=utf-8,' + newFilteredSelectedRows.map(e => e.join(',')).join('\n');

		const formattedCsvContent = csvContent.split('#').join('No.');
		const downloadDate = new Date().toLocaleDateString();
		const encodedUri = encodeURI(formattedCsvContent);
		const link = document.createElement('a');

		link.setAttribute('href', encodedUri);
		link.setAttribute('download', `My_LastBasic_Activity-${downloadDate}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	render() {
		const { data } = this.props;
		const creditTransactions = data.creditTransactions.filter(data => (data.type !== PAYMENTS.LB_CREDIT_TRANSACTION_TYPES.CREDIT_CONVERSION));
		const walletWinningTransactions = data.walletTransactions.filter(data => data.type !== PAYMENTS.WALLET_TRANSACTION_TYPES.CREDIT_CONVERSION);
		const walletConversionsTransactions = walletWinningTransactions.filter(data => data.type !== PAYMENTS.WALLET_TRANSACTION_TYPES.WALLET_CONVERSION);
		const walletTransactions = walletConversionsTransactions.filter(data => data.type !== PAYMENTS.WALLET_TRANSACTION_TYPES.OTHER);


		const headers = [
			{
				key: 'concept',
				header: 'Concept',
			},
			{
				key: 'date',
				header: 'Date',
			},
			{
				key: 'type',
				header: 'Type',
			},
			{
				key: 'rating',
				header: 'Rating',
			},
			{
				key: 'credit',
				header: 'Generated credit',
			},
			{
				key: 'income',
				header: 'Income (€)',
			},
		];

		const rows = [];

		walletTransactions.filter(w => w.type === 'contest_won' || w.type === 'credit_conversion').forEach((t) => {
			const { metadata } = t;
			const hasWon = (metadata.contest !== undefined && (metadata.proposalId === metadata.contest.selectedProposal)) ? 'Yes' : 'No';
			const detailedConcept = hasWon === 'Yes' ? ` (#${metadata.proposal.number})` : '';
			const concept = t.concept + detailedConcept;
			const rating = hasWon === 'Yes' ? metadata.proposal.rating : '0';
			const date = dates.formattedNewDate(t.createdAt);
			const type = metadata.contest !== undefined && metadata.contest.type;
			const credits = hasWon === 'Yes'
				? PAYMENTS.getCreditsAmount(type)
				: (t.type === 'payout_wallet_automatic_transaction')
					?	`(${t.amount})`
					: 	'0';
			const incomeAmount = t.amount;

			return (
				rows.unshift({
					id: t.id,
					concept: concept,
					date: date,
					type: t.type,
					rating: rating,
					credit: credits,
					income: incomeAmount,
					proposalId: metadata.proposalId,
				})
			);
		});

		const winningProposalIds = rows.filter(v => (v.type === 'contest_won')).map(v => v.proposalId);

		creditTransactions.forEach((t) => {
			const { metadata } = t;
			const detailedConcept = metadata.project !== undefined ? (metadata && metadata.proposal && metadata.proposal.number ? ` (#${metadata.proposal.number})` : '') : '';
			const concept = t.concept + detailedConcept;
			const rating = metadata.proposal !== undefined ? metadata.proposal.rating : '0';
			const date = dates.formattedNewDate(t.createdAt);
			const addCredit = ((winningProposalIds.includes(metadata.proposalId) === false) || (t.type === 'other') || (t.type === 'proposal_approved' && (winningProposalIds.includes(metadata.proposalId) === false)));

			if (addCredit) {
				return (
					rows.unshift({
						id: t.id,
						concept: concept,
						date: date,
						type: t.type,
						rating: rating,
						credit: t.amount,
						income: '0',
						proposalId: metadata.proposalId,
					})
				);
			}
		});

		const sortedRows = rows.sort((a, b) => new Date(b.date) - new Date(a.date));
		// const selectedRows = [];

		return (
			<DataTable rows={sortedRows} headers={headers}>
				{({
					rows,
					headers,
					selectedRows,
					getHeaderProps,
					getRowProps,
					getTableProps,
					getSelectionProps,
					getTableContainerProps,
					getBatchActionProps,
					getToolbarProps,
					onInputChange,
				}) => (
					<TableContainer
						title="Your activity"
						description="Work and other transactions"
						{...getTableContainerProps()}
					>
						<TableToolbar {...getToolbarProps()}>
							<TableBatchActions {...getBatchActionProps()}>
								<TableBatchAction
									tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
									renderIcon={Download}
									onClick={() => this.downloadContentAsCSV(headers, selectedRows)}>
                                    Download as CSV
								</TableBatchAction>
							</TableBatchActions>
							<TableToolbarContent>
								<TableToolbarSearch
									defaultExpanded
									placeholder="Filter movements"
									tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
									onChange={onInputChange}
								/>
							</TableToolbarContent>
						</TableToolbar>
						<Table {...getTableProps()} size='normal'>
							<TableHead>
								<TableRow>
									<TableSelectAll {...getSelectionProps()} />
									{headers.map((header, i) => (
										<TableHeader key={i} {...getHeaderProps({ header })} isSortable>
											{header.header}
										</TableHeader>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, i) => (
									<TableRow key={i} {...getRowProps({ row })}>
										<TableSelectRow {...getSelectionProps({ row })} />
										{this.getPersonalizedRowContent(row)}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</DataTable>
		);
	}
}

ActivityTable.propTypes = {
	data: PropTypes.object,
};

ActivityTable.defaultProps = {
	data: {},
};

export default ActivityTable;