import { gql } from '@apollo/client';
import { privateUserFragment } from './fragments';

const FORGOT_PASSWORD_MUTATION = gql`
	mutation forgotPassword($username: String!) {
		forgotPassword(username: $username)
	}
`;

const CREATE_PASSWORD_MUTATION = gql`
	mutation createPassword($token: String!, $password: String!) {
		createPassword(token: $token, password: $password)
	}
`;

const LOGIN_MUTATION = gql`
	${privateUserFragment}

	mutation login($username: String!, $password: String!, $rememberMe: Boolean!) {
		login(username: $username, password: $password, rememberMe: $rememberMe) {
			token {
				tokenType,
				accessToken,
				expiresIn,
				refreshToken,
			}
			user {
				...PrivateUserDetails
			}
		}
	}
`;

const ADD_PROJECT_TO_FAVORITES = gql`
	mutation addProjectToFavorites($id: String!, $value: Boolean!) {
		addProjectToFavorites(id: $id, value: $value)
	}
`;

const JOIN_CONTEST = gql`
	mutation joinContest($projectId: String!, $contestType: String!) {
		joinContest(projectId: $projectId, contestType: $contestType)
	}
`;

const CREATE_PROPOSAL = gql`
	mutation createProposal($projectId: String!, $contestType: String!) {
		createProposal(projectId: $projectId, contestType: $contestType) {
			id
		}
	}
`;

const ADD_FILE_TO_PROPOSAL = gql`
	mutation addFileToProposal($proposalId: String!, $file: Upload!) {
		addFileToProposal(proposalId: $proposalId, file: $file)
	}
`;

const ADD_SHAREDFILE_TO_CONTEST = gql`
	mutation addSharedFile($id: String!, $contestType: String!, $name: String!, $file: Upload!) {
		addSharedFile(id: $id, contestType: $contestType, name: $name, file: $file) {
			id,
			name,
		}
	}
`;

const ADD_RAW_MATERIAL = gql`
	mutation addRawMaterial($projectId: String!, $name: String!, $file: Upload!) {
		addRawMaterial(projectId: $projectId, name: $name, file: $file) {
			id,
			name,
		}
	}
`;

const CONFIRM_PROJECT_RAW_MATERIALS = gql`
	mutation confirmProjectRawMaterials($projectId: String!) {
		confirmProjectRawMaterials(projectId: $projectId)
	}
`;

const REMOVE_RAW_MATERIAL = gql`
	mutation removeRawMaterial($projectId: String!, $fileId: String!) {
		removeRawMaterial(projectId: $projectId, fileId: $fileId)
	}
`;

const CONFIRM_PROJECT_ONBOARDING = gql`
	mutation confirmProjectOnboarding($projectId: String!) {
		confirmProjectOnboarding(projectId: $projectId)
	}
`;

const CONFIRM_CONTEST_CARD = gql`
	mutation confirmContestCard($contestId: String!, $roundId: String!, $confirmation: ContestCardConfirmationInput!) {
		confirmContestCard(contestId: $contestId, roundId: $roundId, confirmation: $confirmation)
	}
`;

const SELECT_WINNING_PROPOSAL = gql`
	mutation selectWinningProposal($proposalId: String!) {
		selectWinningProposal(proposalId: $proposalId)
	}
`;

const GIVE_FEEDBACK_TO_PROPOSAL = gql`
	mutation giveFeedbackToProposal($proposalId: String!, $feedback: FeedbackInput!) {
		giveFeedbackToProposal(proposalId: $proposalId, feedback: $feedback)
	}
`;

const ENABLE_NOTIFICATIONS = gql`
	mutation enablePushNotifications($enable: Boolean!) {
		enablePushNotifications(enable: $enable)
	}
`;

const ENABLE_NOTIFICATIONS_SUBSCRIPTION = gql`
	mutation pushNotificationsSubscription($subscription: PushNotificationSubscriptionInput!) {
		pushNotificationsSubscription(subscription: $subscription)
	}
`;

const ENABLE_EMAIL_NOTIFICATIONS = gql`
	mutation enableEmailNotifications($enable: Boolean!) {
		enableEmailNotifications(enable: $enable)
	}
`;

const UPLOAD_PROFILE_AVATAR = gql`
	mutation updateProfileAvatar($file: Upload!) {
		updateProfileAvatar(file: $file)
	}
`;

const REMOVE_PROFILE_AVATAR = gql`
	mutation removeProfileAvatar {
		removeProfileAvatar
	}
`;

const UPDATE_PASSWORD = gql`
	mutation updatePassword($password: String!) {
		updatePassword(password: $password)
	}
`;

const CHANGE_PAYOUT_STATUS = gql`
	mutation changePayoutStatus($payoutId: String!, $status: String!) {
		changePayoutStatus(payoutId: $payoutId, status: $status)
	}
`;

const UPDATE_EXPERT_INFO = gql`
	mutation updateExpertInformation($information: ExpertUpdateInput!) {
		updateExpertInformation(information: $information)
	}
`;

const UPDATE_MAKER_INFO = gql`
	mutation updateMakerInformation($information: MakerUpdateInput!) {
		updateMakerInformation(information: $information)
	}
`;

const ADD_CONTEST_REVIEW = gql`
	mutation addContestReview($contestId: String!, $feedback: [ClientReviewInput]!) {
		addContestReview(contestId: $contestId, feedback: $feedback)
	}
`;

const ADD_PHASE_SET_UP_ANSWERS = gql`
	mutation answerPhaseSetupQuestions($projectId: String!, $phase: String!, $answers: [SetupQuestionInput]!) {
		answerPhaseSetupQuestions(projectId: $projectId, phase: $phase, answers: $answers)
	}
`;

const ADD_CONTEST_SET_UP_ANSWERS = gql`
	mutation answerSetupQuestions($contestId: String!, $answers: [SetupQuestionInput]!) {
		answerSetupQuestions(contestId: $contestId, answers: $answers)
	}
`;

const UPDATE_MY_PROJECT = gql`
	mutation updateMyProject($projectId: String!, $fields: UpdateMyProjectInput!) {
		updateMyProject(projectId: $projectId, fields: $fields)
	}
`;

const SUBMIT_PROJECT_DRAFT = gql`
	mutation createProjectBriefing($projectId: String, $fields: Object) {
		createProjectBriefing(projectId: $projectId, fields: $fields) {
			projectId
			fields
			estimatedPrice
		}
	}
`;

const CREATE_PROJECT_RESERVATION_LINK = gql`
	mutation createProjectReservationLink($projectId: String) {
		createProjectReservationLink(projectId: $projectId)
	}
`;

const VALIDATE_PROJECT = gql`
	mutation validateProject($projectId: String!, $feasible: Boolean!, $comment: String) {
		validateProject(projectId: $projectId, feasible: $feasible, comment: $comment)
	}
`;

export {
	// Project
	ADD_PROJECT_TO_FAVORITES,
	ADD_PHASE_SET_UP_ANSWERS,
	ADD_RAW_MATERIAL,
	CONFIRM_PROJECT_ONBOARDING,
	CONFIRM_PROJECT_RAW_MATERIALS,
	CREATE_PROJECT_RESERVATION_LINK,
	REMOVE_RAW_MATERIAL,
	SUBMIT_PROJECT_DRAFT,
	UPDATE_MY_PROJECT,
	VALIDATE_PROJECT,
	
	// Proposal
	ADD_FILE_TO_PROPOSAL,
	CREATE_PROPOSAL,
	SELECT_WINNING_PROPOSAL,
	GIVE_FEEDBACK_TO_PROPOSAL,

	// Contest
	JOIN_CONTEST,
	CONFIRM_CONTEST_CARD,
	ADD_SHAREDFILE_TO_CONTEST,
	ADD_CONTEST_REVIEW,
	ADD_CONTEST_SET_UP_ANSWERS,

	// Account
	ENABLE_NOTIFICATIONS,
	ENABLE_NOTIFICATIONS_SUBSCRIPTION,
	ENABLE_EMAIL_NOTIFICATIONS,
	UPDATE_EXPERT_INFO,
	UPDATE_MAKER_INFO,
	UPLOAD_PROFILE_AVATAR,
	REMOVE_PROFILE_AVATAR,
	UPDATE_PASSWORD,
	
	// User
	LOGIN_MUTATION,
	FORGOT_PASSWORD_MUTATION,
	CREATE_PASSWORD_MUTATION,

	// Payments
	CHANGE_PAYOUT_STATUS,
};
