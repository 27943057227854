import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApolloConsumer } from '@apollo/client';

import withRouter from '../../../components/route/with-router';
import { GET_MY_PROFILE_INFO } from '../../../providers/queries';
import * as actions from '../../../reducers/user/actions';
import { auth } from '../../../utils';

class Impersonator extends Component {
	constructor(props) {
		super(props);
		this.client = null;
	}

	async componentDidMount() {
		const { searchParams } = this.props;
		const token = searchParams.get('token');
		if (!token || !this.client) return;
		
		const { navigate, storeNewUser } = this.props;

		const previousTokens = auth.getValidStoredTokens();
		const newTokens = Object.assign({}, previousTokens);
		newTokens.token = {
			accessToken: token,
		};
		auth.replaceTokens(newTokens);

		// Try the new token
		let res;
		try {
			res = await this.client.query({
				query: GET_MY_PROFILE_INFO
			});
			if (!res || !res.data || !res.data.myProfileInfo) throw new Error('No data');

			// Store new tokens
			newTokens.user = res.data.myProfileInfo;
			storeNewUser(newTokens);
		} catch (e) {
			// Remove token from params
			auth.replaceTokens(previousTokens);
		}
		
		return navigate('/');
	}
	
	render() {
		const { searchParams } = this.props;
		const token = searchParams.get('token');
		if (!token) return <Navigate to="/" />;
		
		return (
			<ApolloConsumer>
				{client => {
					this.client = client;
					return (
						<div>Loading token...</div>	
					);
				}}
			</ApolloConsumer>
		);
	}
}

Impersonator.propTypes = {
	navigate: PropTypes.func.isRequired,
	searchParams: PropTypes.object.isRequired,
	storeNewUser: PropTypes.func.isRequired,
};

Impersonator.defaultProps = {
	storeNewUser() {},
};

const mapDispatchToProps = dispatch => ({
	storeNewUser: (data) => dispatch(actions.storeNewUser(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(Impersonator));
