import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '../../../reducers/user/actions';

class Logout extends Component {
	componentDidMount() {
		const { logout } = this.props;

		window.Froged('logout');
		logout();
	}
	
	render() {
		return (
			<Fragment>
				Login out...
			</Fragment>
		);
	}
}

Logout.propTypes = {
	logout: PropTypes.func.isRequired,
};

Logout.defaultProps = {
	logout: () => {},
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(actions.logout()),
});

export default connect(
	null,
	mapDispatchToProps,
)(Logout);
