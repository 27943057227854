export default {
	APPROVED_STATUS: {
		PENDING: 'pending',
		YES: 'yes',
		NO: 'no',
	},
	// getLastVersion: function (proposalFile) {
	// 	if (proposalFile.versions.length === 0) return false;
	// 	return proposalFile.versions[proposalFile.versions.length - 1];
	// },
	getUnseenFileVersions: function (proposal) {
		const res = [];
		for (const file of proposal.files) {
			for (const version of file.versions) {
				if (version.approvedStatus === this.APPROVED_STATUS.PENDING) {
					res.push(version);
				}
			}
		}
		return res;
	},
	hasApprovedFile: function (proposal) {
		for (const file of proposal.files) {
			for (const version of file.versions) {
				if (version.approvedStatus === this.APPROVED_STATUS.YES) {
					return true;
				}
			}
		}
		return false;
	},
	getFileIdFromProposal: function (proposal) {
		let filesIds = [];
		for (const file of proposal.files) {
			for (const version of file.versions) {
				filesIds.push(version.file.id);
			}
		}
		return filesIds[0];
	},
	hasApprovedAndPublishedFile: function (proposals) {
		for (const proposal of proposals) {
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && version.published === true) {
						return true;
					}
				}
			}
			return false;
		}
	},
	hasPendingReviewFile: function (proposals) {
		for (const proposal of proposals) {
			const feedback = proposal.feedback;
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && (!feedback || !feedback.rating)) {
						return true;
					}
				}
			}
			return false;
		}
	},
	hasVotationProposals: function (proposals, hasEnded, hasWinner) {
		for (const proposal of proposals) {
			const feedback = proposal.feedback;
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && feedback && !hasWinner && hasEnded) {
						return true;
					}
				}
			}
			return false;
		}
	},
	getTotalVotationProposals: function (proposals, hasEnded, hasWinner) {
		let count = 0;
		for (const proposal of proposals) {
			const feedback = proposal.feedback;
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && feedback && !hasWinner && hasEnded) {
						count++;
					}
				}
			}
			return count;
		}
	},
	getTotalPendingReviewFiles: function (proposals) {
		let count = 0;
		for (const proposal of proposals) {
			const feedback = proposal.feedback;
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && (!feedback || !feedback.rating)) {
						count++;
					}
				}
			}
		}
		return count;
	},
	getTotalApprovedAndPublishedProposals: function (proposals) {
		let count = 0;
		for (const proposal of proposals) {
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && version.published === true) {
						count++;
					}
				}
			}
		}
		return count;
	},
	// getTotalParticipantsPerContest: function (proposals) {
	// 	const userInitials = [];
	// 	for (const proposal of proposals) {
	// 		const firstLetter = (proposal.user.firstName || 'X').charAt(0).toUpperCase();
	// 		const secondLetter = (proposal.user.lastName || 'X').charAt(0).toUpperCase();
	// 		const initials = firstLetter + secondLetter;
	// 		if (userInitials.includes(initials) === false) {
	// 			userInitials.push(initials);
	// 		}
	// 	}
	// 	return userInitials.length;
	// },
	hasFilesInProposal: function (contest, userId) {
		const { proposals } = contest;
		for (const proposal of proposals) {
			const proposalUserId = proposal.user.id;
			if ((proposal.files.length === 0) && (proposalUserId === userId)) {
				return proposal.id;
			}
		}
		return false;
	},
	isUserProposalWithFile: function (proposals, userId) {
		for (const proposal of proposals) {
			const proposalUserId = proposal.user.id;
			for (const file of proposal.files) {
				if (proposalUserId === userId && file) {
					return true;
				}
			}
		}
		return false;
	},
	hasAWinningProposal: function (contest) {
		const { proposals, selectedProposal } = contest;
		for (const proposal of proposals) {
			if (proposal.id === (selectedProposal !== null && selectedProposal.id)) {
				return true;
			}
		}
		return false;
	},
	getWinningProposalUserId: function (contest) {
		const { proposals, selectedProposal } = contest;
		for (const proposal of proposals) {
			if (proposal.id === (selectedProposal !== null && selectedProposal.id)) {
				return proposal.user.id;
			}
		}
		return null;
	},
	getWinningProposalNumber: function (contest) {
		const { proposals, selectedProposal } = contest;
		for (const proposal of proposals) {
			if (proposal.id === (selectedProposal !== null && selectedProposal.id)) {
				return proposal.number;
			}
		}
		return null;
	},
	getProposalUserInitials: function (proposals) {
		const userInitials = [];
		for (const proposal of proposals) {
			const firstLetter = (proposal.user.firstName || 'X').charAt(0).toUpperCase();
			const secondLetter = (proposal.user.lastName || 'X').charAt(0).toUpperCase();
			const initials = `${firstLetter}${secondLetter}`;
			if (userInitials.includes(initials) === false) {
				userInitials.push(initials);
			}
		}
		return userInitials;
	},
	sharedFilesHaveBeenValidated: function (sharedFiles) {
		for (const sharedFile of sharedFiles) {
			const fileApproved = sharedFile.approvalStatus;
			if (fileApproved === 'approved' || fileApproved === 'rejected') {
				return true;
			}
		}
		return false;
	},
	hasASharedFileApproved: function (sharedFiles) {
		if (sharedFiles.length === 0) return false;
		for (const sharedFile of sharedFiles) {
			const fileApproved = sharedFile.approvalStatus;
			if (fileApproved === 'approved') {
				return true;
			}
		}
		return false;
	},
	getSharedFilesApproved: function (contests) {
		let approvedFiles = 0;
		for (const contest of contests) {
			const { sharedFiles } = contest;
			for (const sharedFile of sharedFiles) {
				const fileApproved = sharedFile.approvalStatus;
				if (fileApproved === 'approved') {
					approvedFiles++;
				}
			}
		}
		return approvedFiles;
	},
	isProposalFromThisContest: function (thisProposal, contest) {
		const { proposals } = contest;
		if (proposals.includes(thisProposal)) {
			return true;
		}
		return false;
	},
	getProposalsId: function (proposals) {
		let ids = [];
		for (const proposal of proposals) {
			ids.push(proposal.id);
		}
		return ids;
	}
};
