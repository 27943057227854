import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Download32, ViewFilled32 } from '@carbon/icons-react';
import {
	StructuredListBody,
	StructuredListCell,
	StructuredListHead,
	StructuredListRow,
	StructuredListWrapper,
	TooltipIcon,
} from 'carbon-components-react';

import PreviewFileModal from '../../../../../components/modals/preview-file-modal';
import { serverAPI } from '../../../../../utils';

import classes from './tabs.module.scss';

class RawMaterialsTab extends Component {
	constructor (props) {
		super(props);
				
		this.state = {
			previewFile: null,
		};
	}
	
	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}
	
	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}
	
	renderFiles(files) {
		return (
			<Fragment>
				{files.map(f => {
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);
					return (
						<StructuredListRow key={f.name}>
							<StructuredListCell>
								{f.name}
							</StructuredListCell>
							<StructuredListCell>
								<div className={classes.additionalButtons}>
									{f.isWebsite ? (
										<div>
											<TooltipIcon tooltipText="Preview file">
												<a href={f.url} target="_blank" rel="noopener noreferrer">
													<ViewFilled32 />
												</a>
											</TooltipIcon>
										</div>
									) : (
										<div>
											<TooltipIcon tooltipText="Preview file">
												<span onClick={() => this.onOpenPreviewFile(f)}>
													<ViewFilled32 />
												</span>
											</TooltipIcon>
											<TooltipIcon tooltipText="Preview file">
												<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
													<Download32 />
												</a>
											</TooltipIcon>
										</div>
									)}
								</div>
							</StructuredListCell>
						</StructuredListRow>
					);
				})}
			</Fragment>
		);
	}
	
	render() {
		const { project, userData } = this.props;
		const { previewFile } = this.state;
		
		const { token } = userData;
		
		const files = [
			...(project.productSketch ? [{
				isWebsite: false,
				name: 'Client Sketch',
				url: serverAPI.getPublicSketchUrl(project.id, token.accessToken),
			}] : []),
			...(project.competitionExample ? [{
				isWebsite: true,
				name: 'Competitor Website',
				url: project.competitionExample,
			}] : []),
			{
				isWebsite: false,
				name: 'Briefing File',
				url: serverAPI.getPublicBriefingUrl(project.id, token.accessToken),
			},
		];
		
		return (
			<Fragment>
				<StructuredListWrapper>
					<StructuredListHead>
						<StructuredListRow head>
							<StructuredListCell head>
								Name
							</StructuredListCell>
							<StructuredListCell head>
								Actions
							</StructuredListCell>
						</StructuredListRow>
					</StructuredListHead>
					<StructuredListBody>
						{this.renderFiles(files)}
					</StructuredListBody>
				</StructuredListWrapper>
				{previewFile && (
					<PreviewFileModal
						open={!!previewFile}
						file={previewFile}
						onRequestClose={() => this.onClosePreviewFile()}
					/>
				)}
			</Fragment>
		);
	}
}

RawMaterialsTab.propTypes = {
	project: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(RawMaterialsTab);
