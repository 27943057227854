import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Modal,
	Button,
	ProgressIndicator,
	ProgressStep
} from 'carbon-components-react';

import classes from './modals.module.scss';

class ProcessModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			currentStep: 0,
		};
	}

	render() {
		const { open, onRequestClose, ...restProps } = this.props;
		const { currentStep } = this.state;

		const steps = ['Quote submission', 'LB validation', 'Inventor’s votation', 'Payment'];
		const stepsDescriptions = [
			'The first step is to prepare the quote and upload it to our platform.',
			'After submitted, our internal team in LastBasic will review it and confirm it matches the client’s requirements.',
			'Once accepted, the quotation will be shown to the inventor and he will choose a winner among all the participants.',
			'If your quotation proposal was accepted and selected as the winner by the inventor, you officially are the winner of the contest and can start the payment process.'
		];
		const isFirstOne = currentStep === 0;
		const isLastOne = currentStep === 3;

		return (
			<Modal
				passiveModal={true}
				open={open}
				modalLabel={'Learn the process'}
				modalHeading={'Upload a quotation'}
				onRequestClose={onRequestClose}
				{...restProps}
			>
				<h5>The process is the following:</h5>
				<ProgressIndicator
					className={classes.progressContainer}
					currentIndex={currentStep}
					spaceEqually
				>
					<ProgressStep label={'Step 1'} invalid={false} />
					<ProgressStep label={'Step 2'} disabled={false} />
					<ProgressStep label={'Step 3'} disabled={false} />
					<ProgressStep label={'Step 4'} disabled={false} />
				</ProgressIndicator>
				<div className={classes.contestContent}>
					<div>
						<h6>{currentStep+1}. {steps[currentStep]}</h6>
						<p>{stepsDescriptions[currentStep]}</p>
					</div>
				</div>
				<div className={classes.btnBox}>
					<Button type="submit" className={classes.btn} onClick={() => this.setState({currentStep: currentStep - 1})} kind="secondary" disabled={isFirstOne}>
						Return
					</Button>
					<Button className={classes.btn} onClick={() => isLastOne ? onRequestClose() : this.setState({currentStep: currentStep + 1})}>
						{isLastOne ? 'Done' : 'Next'}
					</Button>
				</div>
			</Modal>
		);
	}
}

ProcessModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};

ProcessModal.defaultProps = {
	open: false,
	onRequestClose: () => {},
};

export default ProcessModal;
