import React, { Component } from 'react';
import KEYS from '../../../glossary';
import { Tabs, Tab } from 'carbon-components-react';

import classes from './how-it-works.module.scss';

class HowItWorksPage extends Component {

	componentDidMount() {
		document.title = 'How it works - LastBasic';
	}

	render() {
		return (
			<div className={classes.startingContainer}>
				<h2 className={classes.title}>{KEYS.how_it_works_title}</h2>
				<Tabs className={classes.tabs}>
					<Tab id="tab-experts" label={<h5>Experts</h5>} disabled></Tab>
				</Tabs>
				<div className={classes.content}>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{KEYS.how_it_works_section_1_title}</h4>
							<p>{KEYS.how_it_works_section_1_description}</p>
						</div>
						<div className={classes.descriptionContent}>
							<ul className={classes.listContent}>
								<li>{KEYS.how_it_works_section_1_step_1}</li>
								<li>{KEYS.how_it_works_section_1_step_2}</li>
								<li>{KEYS.how_it_works_section_1_step_3}</li>
								<li>{KEYS.how_it_works_section_1_step_5}</li>
							</ul>
						</div>
					</div>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{KEYS.how_it_works_section_2_title_expert}</h4>
							<p>{KEYS.how_it_works_section_2_description_expert}</p>
						</div>
						<div className={classes.descriptionContent}>
							<ul className={classes.listContent}>
								<li>{KEYS.how_it_works_section_2_step_1_expert}</li>
								<li>{KEYS.how_it_works_section_2_step_2_expert}</li>
								<li>{KEYS.how_it_works_section_2_step_3_expert}</li>
								<li>{KEYS.how_it_works_section_2_step_4_expert}</li>
							</ul>
						</div>
					</div>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{KEYS.how_it_works_section_3_title_expert}</h4>
							<p>{KEYS.how_it_works_section_3_description_expert}</p>
						</div>
						<div className={classes.descriptionContent}>
							<ul className={classes.listContent}>
								<li>{KEYS.how_it_works_section_3_step_1_expert}</li>
								<li>{KEYS.how_it_works_section_3_step_2_expert}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HowItWorksPage;
