import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	InlineNotification,
	InlineLoading,
	Modal,
	Button,
	TextArea,
	Form
} from 'carbon-components-react';

import { CONFIRM_CONTEST_CARD } from '../../providers/mutations';
import { CONTEST } from '../../constants';
import KEYS from '../../glossary';

import classes from './modals.module.scss';

class RequestChangeContestCardModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			errorValidatingChanges: false,
			sendingRequest: false,
			success: false,
			comment: '',
		};
	}

	onSetComment(e) {
		this.setState({ comment: e.target.value });
	}

	async onSubmitRequestChangeContestCard(e, contest, confirmContestCardMutation, onRequestSubmit) {
		const { comment } = this.state;
		const contestRound = contest && CONTEST.getLastRound(contest);

		this.setState({ sendingRequest: true });
		let error;
		try {
			const result = await confirmContestCardMutation({
				variables: {
					contestId: contest.id,
					roundId: contestRound.id,
					confirmation: {
						approved: false,
						comment: comment,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error on request change contest card');
			setTimeout(() => {
				this.setState({ errorValidatingChanges: null });
			}, 3000);
		} else {
			console.log('Success on request change contest card');
			this.setState({ success: true, sendingRequest: false });
			setTimeout(() => {
				onRequestSubmit();
			}, 3000);
		}
		
		this.setState({ sendingRequest: false });
	}

	render() {
		const { contest, onRequestClose, onRequestSubmit, ...restProps } = this.props;
		const { comment, sendingRequest, success, errorValidatingChanges } = this.state;

		const isSubmitButtonDisabled = comment.length < 8;

		return (
			<Mutation mutation={CONFIRM_CONTEST_CARD}>
				{(confirmContestCardMutation, { error }) => {
					return (
						<Modal
							passiveModal={true}
							hasScrollingContent
							modalHeading='Contest card request'
							aria-label='Contest card request'
							primaryButtonText={sendingRequest ? 'Sending changes request ...' : 'Request change'}
							secondaryButtonText={KEYS.cancel}
							onRequestClose={onRequestClose}
							{...restProps}>
							{error && (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton={false}
									title="Error"
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							)}
							<Form>
								<div className={classes.modalContent}>
									<div className={classes.contestContent}>
										<h5>Change request</h5>
										<div>
											<TextArea
												id="request-change-comment-input"
												labelText=""
												helperText="Minimum 8 characters"
												invalid={(errorValidatingChanges !== null && comment.length < 8) ? true : false}
												invalidText={'Text must be minimum 8 characters'}
												minLength="8"
												cols={30}
												rows={4}
												required
												placeholder='Tell us your requirements here...'
												onChange={(e) => this.onSetComment(e)}
											/>
										</div>
									</div>
								</div>
								<div className={classes.btnBox}>
									<Button type="submit" className={classes.btn} onClick={onRequestClose} kind="secondary" disabled={sendingRequest || success}>
										{KEYS.cancel}
									</Button>
									{sendingRequest || success ? (
										<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={success ? 'Request change sent successfully' : 'Sending request change ...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorValidatingChanges
										? 	<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={'Error validating changes'}
											status={'error'}
										/>
										:	(
											<Button
												className={classes.btn}
												disabled={isSubmitButtonDisabled}
												onClick={(e) => this.onSubmitRequestChangeContestCard(e, contest, confirmContestCardMutation, onRequestSubmit)}>
													Submit change requests
											</Button>
										)}
								</div>
							</Form>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

RequestChangeContestCardModal.propTypes = {
	open: PropTypes.bool.isRequired,
	proposal: PropTypes.object.isRequired,
	contest: PropTypes.object,
	onRequestSubmit: PropTypes.func.isRequired,
	onSecondarySubmit: PropTypes.func.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};

RequestChangeContestCardModal.defaultProps = {
	open: false,
	proposal: {},
	contest: {},
	onRequestSubmit: () => {},
	onSecondarySubmit: () => {},
	onRequestClose: () => {},
};

export default RequestChangeContestCardModal;