import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import confetti from 'canvas-confetti';

import {
	InlineNotification,
	SkeletonPlaceholder,
	SkeletonText,
	Button,
	Tag,
} from 'carbon-components-react';

import {
	Add16, SettingsAdjust32,
	DocumentProtected32, Rule32,
	Money32, ResultNew32, PiggyBank32
} from '@carbon/icons-react';

import { GET_OWNED_PROJECTS } from '../../../../providers/queries';
import { PROJECT } from '../../../../constants'; // EXPERT
import { projectStorage, serverAPI } from '../../../../utils'; // projectFeasibility
import config from '../../../../config';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';
import ProjectCard from '../../../../components/project/project-card';
import StartProjectOption from '../../../../components/project/start-project-option';
import UpdateProjectNameModal from '../../../../components/modals/update-project-name-modal';
import UpdateProjectImageModal from '../../../../components/modals/update-project-image-modal';
import EraseProjectModal from '../../../../components/modals/erase-project-modal';
import StartProjectModal from '../../../../components/modals/start-project-modal';
import SubmitProjectModal from '../../../../components/modals/submit-project-modal';
import Benefit from '../../../../components/project/benefit';

import classes from './client-projects.module.scss';

const DEFAULT_PROJECT_IMG_URL = '/assets/images/logo-lb-project.png';

class ClientProjects extends Component {
	constructor(props) {
		super(props);

		this.refetchProjects = () => {};
		this.state = {
			openUpdateProjectNameModal: false,
			openUpdateProjectImageModal: false,
			selectedProject: null,
			startNewProject: false,
			filterBy: null,
			openEraseProjectModal: false,
			openSubmitProjectModal: null,
		};
	}

	componentDidMount() {
		document.title = 'My projects - LastBasic';
	}

	// displayConfetti() {
	// 	var duration = 10 * 500;
	// 	var animationEnd = Date.now() + duration;
	// 	var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

	// 	function randomInRange(min, max) {
	// 		return Math.random() * (max - min) + min;
	// 	}

	// 	var interval = setInterval(function() {
	// 		var timeLeft = animationEnd - Date.now();

	// 		if (timeLeft <= 0) {
	// 			return clearInterval(interval);
	// 		}

	// 		var particleCount = 50 * (timeLeft / duration);
	// 		// since particles fall down, start a bit higher than random
	// 		confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
	// 		confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
	// 	}, 250);
	// 	projectFeasibility.storeProjectFeasibilityDone(true);
	// }

	onChangeSelectedProject = (project) => {
		projectStorage.storeProject(project.id);
	};

	displayUrlLink(image) {
		return serverAPI.getThumbnailUrlByFile(image);
	}

	onChangeProjectImage(e, project) {
		e.preventDefault();
		this.setState({
			selectedProject: project,
			openUpdateProjectImageModal: true
		});
	}

	displayFeasiblesProjectsPendingPayments(project) {
		const hasInvoice = project && project.setupInvoice ? true : false;

		return (
			<div className={classes.bigHighlightedBlock}>
				<div className={classes.leftHighlightedBlock}>
					<img
						className={classes.highlightImg}
						alt={''}
						src={project.image
							? this.displayUrlLink(project.image)
							: DEFAULT_PROJECT_IMG_URL}
					/>
					{!project.image &&
						<div className={classes.altImgBtn} onClick={(e) => this.onChangeProjectImage(e, project)}>
							<Add16 className={classes.bigIcon} />
							<small>Add image</small>
						</div>
					}
				</div>
				<div className={classes.rightHighlightedBlock}>
					<div className={classes.featuredProjectContent}>
						<div>
							<small>Congrats, your project is feasible!</small>
							<h3>{project.name}</h3>
						</div>
						<div>
							<Tag type="green" className={classes.flatTag}>Feasible</Tag>
						</div>
					</div>
					<p>We have carefully reviewed your project and it is feasible for us. It&apos;s your turn to move forwards by completing the payment and starting your project journey.</p>
					<div className={classes.bottomBtnsContainer}>
						<Button
							className={classes.btn}
							style={{ marginRight: '8px'}}
							onClick={() => this.openDocument()}
							size='sm'
							kind='tertiary'>
								About this process
						</Button>
						<Button
							className={classes.btn}
							onClick={hasInvoice ? () => this.completePayment(project.setupInvoice.url) : ''}
							disabled={!hasInvoice}
							size='sm'
							kind='secondary'>
							{hasInvoice
								? 'Pay and start project'
								: 'Wait for payment'
							}
						</Button>
					</div>
				</div>
			</div>
		);
	}

	openDocument() {
		// Inside chat options --> window.Froged('open', 'documents');
		// In full modal --> window.Froged('doc', 'documentId');
		window.Froged('doc', '624587ec62d09fd9e43be18a');
	}

	completePayment(invoiceUrl) {
		window.open(invoiceUrl, '_blank');
	}

	displayFilteredProjects(projects) {
		const { filterBy } = this.state;

		const filteredDraftProjects = projects.filter(p => p.state === PROJECT.STATE.DRAFT);
		const filteredPendingFeasibilityProjects = projects.filter(p => p.feasibility === PROJECT.FEASIBILITY.PENDING && p.state !== PROJECT.STATE.DRAFT);
		const filteredNonFeasibleProjects = projects.filter(p => p.feasibility === PROJECT.FEASIBILITY.NO);
		const filteredPendingPaymentProjects = projects.filter(p => p.feasibility === PROJECT.FEASIBILITY.YES && !p.paid);
		const filteredActiveProjects = projects.filter(p => p.state !== PROJECT.STATE.CLOSED && p.state !== PROJECT.STATE.CLIENT_ONBOARDING && p.state !== PROJECT.STATE.DRAFT);

		if (filterBy === PROJECT.PROJECT_FEASIBILITY_STATE.PENDING_FEASIBILITY) {
			return filteredPendingFeasibilityProjects.map((project, i) => (
				<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(project)}>
					<ProjectCard
						project={project}
						openModal={() => this.setState({ selectedProject: project, openUpdateProjectNameModal: true })}
						openImageModal={() => this.setState({ selectedProject: project, openUpdateProjectImageModal: true })}
						openEraseProjectModal={() => this.setState({ selectedProject: project, openEraseProjectModal: true })}
					/>
				</div>
			));
		}

		if (filterBy === PROJECT.PROJECT_FEASIBILITY_STATE.PENDING_PAYMENT) {
			return filteredPendingPaymentProjects.map((project, i) => (
				<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(project)}>
					<ProjectCard
						project={project}
						openModal={() => this.setState({ selectedProject: project, openUpdateProjectNameModal: true })}
						openImageModal={() => this.setState({ selectedProject: project, openUpdateProjectImageModal: true })}
					/>
				</div>
			));
		}

		if (filterBy === PROJECT.PROJECT_FEASIBILITY_STATE.NON_FEASIBLE) {
			return filteredNonFeasibleProjects.map((project, i) => (
				<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(project)}>
					<ProjectCard
						project={project}
						openModal={() => this.setState({ selectedProject: project, openUpdateProjectNameModal: true })}
						openImageModal={() => this.setState({ selectedProject: project, openUpdateProjectImageModal: true })}
						openEraseProjectModal={() => this.setState({ selectedProject: project, openEraseProjectModal: true })}
					/>
				</div>
			));
		}

		if (filterBy === PROJECT.PROJECT_FEASIBILITY_STATE.ACTIVE) {
			return filteredActiveProjects.map((project, i) => (
				<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(project)}>
					<ProjectCard
						project={project}
						openModal={() => this.setState({ selectedProject: project, openUpdateProjectNameModal: true })}
						openImageModal={() => this.setState({ selectedProject: project, openUpdateProjectImageModal: true })}
					/>
				</div>
			));
		}

		if (filterBy === PROJECT.PROJECT_FEASIBILITY_STATE.DRAFT) {
			return filteredDraftProjects.map((project, i) => (
				<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(project)}>
					<ProjectCard
						project={project}
						openModal={() => this.setState({ selectedProject: project, openUpdateProjectNameModal: true })}
						openImageModal={() => this.setState({ selectedProject: project, openUpdateProjectImageModal: true })}
						openEraseProjectModal={() => this.setState({ selectedProject: project, openEraseProjectModal: true })}
					/>
				</div>
			));
		}
	}

	displayLimboStage() {
		return (
			<div className={classes.flexColumn} style={{margin: '36px 0'}}>
				<p className={classes.littleText}>Benefits of creating a project with us</p>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					<Benefit
						name={'Free validation of your project'}
						description={'We review your project idea within 48hs and validate if it\'s feasible or not.'}
						icon={<ResultNew32 />}
					/>
					<Benefit
						name={'You have full control over your project'}
						description={'Experts will come to you with their proposals and desired approach for your new product idea, you select the winner to move forward.'}
						icon={<SettingsAdjust32 />}
					/>
					<Benefit
						name={'Guaranteed refund'}
						description={'If required, LastBasic compromises to refund you in full, no questions asked within the first 30 days and before selecting the sketching winner.'}
						icon={<Money32 />}
					/>
					<Benefit
						name={'Your IP is safe and protected with us'}
						description={'Every idea submitted in the LastBasic platform is protected by a comprehensive non-disclosure agreement. The IP is always yours.'}
						icon={<DocumentProtected32 />}
					/>
					<Benefit
						name={'Professionals you can trust'}
						description={'Our community of experts have been vetted having proven their worthy of working for you with industry accreditations and qualifications.'}
						icon={<Rule32 />}
					/>
					<Benefit
						name={'More economical than traditional consultancies'}
						description={'Our sharing economy model makes us very competitive with prices being up to 75% lower than traditional agencies.'}
						icon={<PiggyBank32 />}
					/>
				</div>
			</div>
		);
	}

	displayDemoProject(demoProject) {
		return (
			<div
				className={classes.gridItem}
				onClick={() => this.onChangeSelectedProject(demoProject)}
			>
				<ProjectCard
					project={demoProject}
					isDemo={true}
				/>
			</div>
		);
	}

	scrollToAddons() {
		document.querySelector('#add-ons').scrollIntoView({behavior: 'smooth'});
	}

	redirectToProjectBuilder() {
		window.location.href = '/new/project';
		return null;
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const { userData: {user} } = this.props;
		const {
			openUpdateProjectNameModal,
			openUpdateProjectImageModal,
			selectedProject,
			startNewProject,
			filterBy,
			openEraseProjectModal,
			openSubmitProjectModal,
		} = this.state;

		const queryVariables = {
			page: 0,
			pageSize: 25,
			withMoreDetails: true,
			onlyId: false,
			includeFeasibility: false,
		};

		return (
			<ErrorBoundaryQuery query={GET_OWNED_PROJECTS} variables={queryVariables}>
				{({ loading, error, data, refetch: refetchProjects }) => {
					this.refetchProjects = refetchProjects;

					if (loading) {
						return (
							<div className={classes.selectProjectContainer}>
								<SkeletonText className={classes.title} style={{ width: '200px' }} />
								<div className={classes.projectsList}>
									<SkeletonPlaceholder className={classes.projectCard} style={{ width: '300px', height: '300px', margin: 0 }} />
									<SkeletonPlaceholder className={classes.projectCard} style={{ width: '300px', height: '300px', margin: 0 }} />
									<SkeletonPlaceholder className={classes.projectCard} style={{ width: '300px', height: '300px', margin: 0 }} />
								</div>
							</div>
						);
					}
					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}
					const projects = (data && data.ownedProjects && data.ownedProjects.projects) || [];
					const pendingPaymentProject = projects.filter(p => (p.feasibility === PROJECT.FEASIBILITY.YES && !p.paid));

					// const isFirstFeasibleProject = PROJECT.isFirstFeasibleProject(projects);
					// const hasAlreadyCelebrateFistFeasibleProject = projectFeasibility.getProjectFeasibilityDone() !== null ? projectFeasibility.getProjectFeasibilityDone() : false;
					// if (isFirstFeasibleProject && !hasAlreadyCelebrateFistFeasibleProject) this.displayConfetti();

					// const isInLimboStage = EXPERT.isUserInLimboStage(projects);

					if (!projects.length) return this.redirectToProjectBuilder();

					const readyToSubmit = data.ownedProjects.projects.find(p => p.state === PROJECT.STATE.DRAFT && p.briefingCompletionPercentaje && p.briefingCompletionPercentaje >= 100);
					if (readyToSubmit && openSubmitProjectModal === null) {
						this.setState({ openSubmitProjectModal: true });
					}

					const demoProjectId = config.lastbasic.demoProjectId;
					const demoProject = projects.filter(p => p.id === demoProjectId)[0];

					return (
						<div className={classes.selectProjectContainer}>
							<h2 className={classes.title}>My projects</h2>
							{pendingPaymentProject.length > 0 && false &&
								<div className={classes.flexColumn} style={{margin: '16px 0'}}>
									<p className={classes.littleText}>Needs my attention</p>
									{pendingPaymentProject.map(project => (
										this.displayFeasiblesProjectsPendingPayments(project)
									))}
									<p className={classes.bottomMessage}>Over 700+ ideas shared with us!</p>
								</div>
							}
							<div className={classes.projectsList}>
								<div className={classes.gridItem}>
									<StartProjectOption redirectToProjectBuilder={this.redirectToProjectBuilder} />
								</div>
								{(filterBy !== null) ?
									this.displayFilteredProjects(projects)
									: 	projects.map((p, i) => (
										<div key={i} className={classes.gridItem} onClick={() => this.onChangeSelectedProject(p)}>
											<ProjectCard
												project={p}
												openModal={() => this.setState({ selectedProject: p, openUpdateProjectNameModal: true })}
												openImageModal={() => this.setState({ selectedProject: p, openUpdateProjectImageModal: true })}
												openEraseProjectModal={() => this.setState({ selectedProject: p, openEraseProjectModal: true })}
											/>
										</div>
									))
								}
								{config.lastbasic?.demoProjectId && this.displayDemoProject(demoProject)}
							</div>
							{this.displayLimboStage()}
							<UpdateProjectNameModal
								open={!!openUpdateProjectNameModal}
								project={selectedProject}
								onRequestSubmit={() => this.setState({ openUpdateProjectNameModal: false }, () => { this.refetchProjects();})}
								onRequestClose={() => this.setState({ openUpdateProjectNameModal: false })}
								onSecondarySubmit={() => this.setState({ openUpdateProjectNameModal: false })}
							/>
							<UpdateProjectImageModal
								open={!!openUpdateProjectImageModal}
								project={selectedProject}
								onRequestSubmit={() => this.setState({ openUpdateProjectImageModal: false }, () => { this.refetchProjects();})}
								onRequestClose={() => this.setState({ openUpdateProjectImageModal: false })}
								onSecondarySubmit={() => this.setState({ openUpdateProjectImageModal: false })}
							/>
							<EraseProjectModal
								open={!!openEraseProjectModal}
								project={selectedProject}
								onRequestSubmit={() => this.setState({ openEraseProjectModal: false }, () => { this.refetchProjects();})}
								onRequestClose={() => this.setState({ openEraseProjectModal: false })}
								onSecondarySubmit={() => this.setState({ openEraseProjectModal: false })}
							/>
							<StartProjectModal
								open={!!startNewProject}
								onRequestClose={() => this.setState({ startNewProject: false })}
							/>
							<SubmitProjectModal
								open={!!openSubmitProjectModal}
								project={readyToSubmit}
								onRequestSubmit={() => this.setState({ openSubmitProjectModal: false }, () => { this.refetchProjects();})}
								onRequestClose={() => this.setState({ openSubmitProjectModal: false })}
							/>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ClientProjects.propTypes = {
	userData: PropTypes.object.isRequired,
};

ClientProjects.defaultProps = {
	userData: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ClientProjects);
