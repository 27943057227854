import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';

import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import { GET_HOW_MANY_REFERRED, } from '../../../providers/queries';
import { userInfo } from '../../../utils';

import classes from './referral.module.scss';
import inviteIllustration from './assets/invite.svg';

export default function ReferralPage() {
	const [linkCopied, setLinkCopied] = useState(false);

	const userData = userInfo.getUserInfo();
	const type = (userData && userData.maker) ? 'inventor' : 'expert';
	const otherType = (type === 'inventor') ? 'expert' : 'inventor';

	const referralCode = userData ? userData.referralCode : null;
	const inviteLink = `https://lastbasic.com/invite/${referralCode || 'expert'}`;

	function onCopy() {
		navigator.clipboard.writeText(inviteLink);

		setLinkCopied(true);

		setTimeout(() => {
			setLinkCopied(false);
		}, 1_500);
	}

	function openSocialLink(source) {
		const left = screen.width;
		const top = screen.height;
	
		let url;
		if (source === 'facebook') {
			const content = encodeURIComponent(inviteLink);
			url = `https://www.facebook.com/sharer/sharer.php?u=${content}`;
		} else if (source === 'twitter') {
			const content = encodeURIComponent('Create an account in @LastBasic using my link! ' + inviteLink);
			url = `https://twitter.com/intent/tweet?text=${content}`;
		} else if (source === 'email') {
			const subject = encodeURIComponent('I\'m inviting you to LastBasic!');
			const content = encodeURIComponent('Hi! I am inviting you to create an account in LastBasic, a platform for inventors and experts to create hardware prototypes. Use my link to sign up! ' + inviteLink);
			url = `mailto:?to=&subject=${subject}&body=${content}`;
			return window.open(url, '_blank');
		} else {
			throw new Error('Unknown source');
		}

		window.open(url, '', `width=500, height=500, top=${(top / 2) - 250}, left=${(left / 2) - 250}`);
	}

	// No approved for referral
	if (!referralCode) {
		document.title = 'Platform - LastBasic';

		return (
			<>
				<h2>Page not available yet</h2>
			</>
		);
	}

	// Document title
	document.title = `Invite an ${type} or an ${otherType} - LastBasic`;

	return (
		<div className={classes.referralWrapper}>
			<div className={classes.referrals}>
				<div className={classes.illustration}>
					<img src={inviteIllustration}></img>
				</div>

				<div className={classes.title}>
					<h2>Invite a friend today</h2>
				</div>
				<div className={classes.descriptionWrap}>
					<div className={classes.description}>
						Bring another {userData.maker ? 'inventor' : 'expert'} like you, or an {otherType} onto the LastBasic platform
					</div>
				</div>

				<div className={classes.sharing}>
					<div className={classes.linkWrapper}>
						<div className={classes.link}>
							<input type='text' value={inviteLink} readOnly />
							<div className={classes.copy} onClick={onCopy}>
								<ContentCopyIcon fontSize='inherit' />
							</div>
						</div>
					</div>

					<div className={classes.linkCopiedMessage}>{linkCopied && 'Link copied into your clipboard!'}</div>
				</div>
				
				<div className={classes.socialLinks}>
					<div className={classes.socialLink} onClick={() => openSocialLink('facebook')}>
						<FacebookIcon fontSize='inherit' /> Facebook
					</div>
					<div className={classes.socialLink} onClick={() => openSocialLink('twitter')}>
						<TwitterIcon fontSize='inherit' /> Twitter
					</div>
					<div className={classes.socialLink} onClick={() => openSocialLink('email')}>
						<EmailIcon fontSize='inherit' /> Email
					</div>
				</div>

				<ErrorBoundaryQuery query={GET_HOW_MANY_REFERRED}>
					{({ data }) => {
						if (!data || !data.getNumberReferred) return null;

						const totalReferred = data.getNumberReferred;
						const one = (totalReferred === 1);

						return (
							<div className={classes.referredPeopleWrapper}>
								<div className={classes.referredPeople}>
									<span style={{ fontWeight: 700 }}>{totalReferred}</span> friend{one ? ' has' : 's have'} already created an account using your link
								</div>
							</div>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		</div>
	);
}

ReferralPage.propTypes = {
	userData: PropTypes.object.isRequired,
};

ReferralPage.defaultProps = {
	userData: {},
};
