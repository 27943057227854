import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

function withRouter (Component) {
	const Wrapper = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const [searchParams] = useSearchParams();

		return (
			<Component
				location={location}
				match={{
					params
				}}
				navigate={navigate}
				searchParams={searchParams}
				{...props}
			/>
		);
	};
  
	return Wrapper;
}

export default withRouter;
