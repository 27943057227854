import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SkeletonPlaceholder } from 'carbon-components-react';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';
import ProjectWithParticipationInfo from '../../../../components/project/project-user-is-participant';

import classes from './project-list.module.scss';
import { PROJECT } from '../../../../constants';

class ParticipatingProjectList extends Component {
	constructor(props) {
		super(props);

		const { page, pageSize } = this.props;

		this.state = {
			page,
			pageSize,
		};
	}

	displayParticipatingProjects(projects) {
		const filteredProjects = projects.filter(p => (p.state !== PROJECT.STATE.CLIENT_ONBOARDING && p.state !== PROJECT.STATE.PROJECT_SET_UP && p.state !== PROJECT.STATE.CLOSED));
		const finishedProjects = projects.filter(p => (p.state === PROJECT.STATE.CLOSED));

		return (
			<Fragment>
				{filteredProjects && filteredProjects.length > 0 &&
					<div className={classes.blockContent}>
						<div className={classes.filtersContainer}>
							<p className={classes.littleText}>Active projects I&apos;m involved</p>
						</div>
						<div className={classes.gridList}>
							{filteredProjects.map((project, i) => (
								<div key={i} className={classes.gridItem}>
									<ProjectWithParticipationInfo project={project} />
								</div>
							))
							}
							{filteredProjects.length <= 2 &&
								<div className={classes.gridItem}>
									<Link to={'/explore'} style={{textDecoration: 'none'}}>
										<div className={classes.projectInvolvedSkeleton}>
											<h5>Explore projects</h5>
										</div>
									</Link>
								</div>
							}
						</div>
					</div>
				}
				{finishedProjects && finishedProjects.length > 0 &&
					<div className={classes.blockContent}>
						<div className={classes.filtersContainer}>
							<p className={classes.littleText}>Projects I&apos;ve participated</p>
						</div>
						<div className={classnames([classes.gridList, classes.horizontalScrolling])}>
							{finishedProjects.map((project, i) => (
								<div key={i} className={classes.gridItem}>
									<ProjectWithParticipationInfo project={project} />
								</div>
							))
							}
						</div>
					</div>
				}
			</Fragment>
		);
	}

	render() {
		const { query: queryParam, variables } = this.props;
		const { page, pageSize } = this.state;

		const queryVariables = {
			...variables,
			page,
			pageSize,
		};

		// to be defined
		// window.Froged('track', 'scroll_experts_projects_list');

		return (
			<ErrorBoundaryQuery query={queryParam} variables={queryVariables}>
				{({ loading, error, data }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.gridList}>
								<SkeletonPlaceholder className={classnames([classes.gridItem])} />
								<SkeletonPlaceholder className={classnames([classes.gridItem])} />
							</div>
						</div>
					);

					// No data provided
					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const projects = (result && result.projects) || [];
					const totalCount = (result && result.totalCount) || 0;
					const participatingProjects = projects;

					if (!totalCount) {
						return (
							<Link to="/explore" style={{ textDecoration: 'none' }}>
								<div className={classes.contestOption}>
									<small>You are not participating in any contest yet. Check out our projects to join them!</small>
								</div>
							</Link>
						);
					}

					return (
						participatingProjects.length > 0 &&
							this.displayParticipatingProjects(participatingProjects)
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ParticipatingProjectList.defaultProps = {
	query: {},
	variables: {},
	page: 0,
	pageSize: 20,
};

ParticipatingProjectList.propTypes = {
	query: PropTypes.object.isRequired,
	variables: PropTypes.object,
	page: PropTypes.number,
	pageSize: PropTypes.number,
};

export default ParticipatingProjectList;
