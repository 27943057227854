import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronRight16 } from '@carbon/icons-react';

import withRouter from '../../components/route/with-router';

import classes from './extra-option.module.scss';

class ExtraOption extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {
		const { name, description, icon, onClick, redirectTo, submitText, urlType } = this.props;
		
		const content = (
			<div
				className={classes.extraBlock}
				onClick={onClick}
			>
				<span className={classes.icon}>{icon}</span>
				<h5 className={classes.title}>{name}</h5>
				<small className={classes.altText}>{description}</small>
				<div className={classes.bottomAction}>
					<div className={classes.actionGroup}>
						<h6 style={{color: '#0062ff'}}>{submitText}</h6>
						<ChevronRight16 />
					</div>
				</div>
			</div>
		);

		if (urlType === 'new-page') {
			return (
				<a
					href={redirectTo}
					target={'_blank'}
					rel="noreferrer"
					style={{textDecoration: 'none'}}
				>
					{content}
				</a>
			);
		} else if (urlType === 'self') {
			return (
				<Link
					to={redirectTo ? { pathname: redirectTo } : '#'}
					style={{textDecoration: 'none'}}
				>
					{content}
				</Link>
			);
		} else {
			return content;
		}
	}
}

ExtraOption.propTypes = {
	icon: PropTypes.object,
	name: PropTypes.string,
	description: PropTypes.string,
	onClick: PropTypes.func,
	redirectTo: PropTypes.string,
	submitText: PropTypes.string,
	urlType: PropTypes.string,
};

ExtraOption.defaultProps = {
	icon: null,
	name: '',
	description: '',
	onClick: () => {},
	redirectTo: '',
	submitText: '',
	urlType: '',
};

export default withRouter(ExtraOption);
