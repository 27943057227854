import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {
	InlineNotification,
	BreadcrumbSkeleton,
	SkeletonPlaceholder,
	SkeletonText,
	InlineLoading,
	TextInput,
	Select,
	SelectItem,
	SelectItemGroup,
	Tag,
	Breadcrumb,
	BreadcrumbItem,
	Button
} from 'carbon-components-react';
import { Locked16 } from '@carbon/icons-react';
import { Mutation } from '@apollo/client/react/components';

import { GET_MY_PROFILE_INFO } from '../../../../providers/queries';
import { UPDATE_EXPERT_INFO } from '../../../../providers/mutations';

import { serverAPI, userInfo, strings, dates } from '../../../../utils';
import { EXPERT, COUNTRIES } from '../../../../constants';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import classes from './personal-information.module.scss';

class PersonalInformation extends Component {
	constructor(props) {
		super(props);

		this.refetchProfileInfo = () => {};
		this.state = {
			userInfo: userInfo.getUserInfo(),
			editAboutSection: false,
			editProfessionalSection: false,
			updatingAboutExpertInfo: false,
			updatingAboutExpertInfoSuccessfully: false,
			firstName: null,
			lastName: null,
			expertType: null,
			expertExpertise: null,
			expertSecondaryExpertise: [],
			yearsExperience: null,
			countryResidence: null,
			selectedCountry: null,
		};
	}

	componentDidMount() {
		document.title = 'Personal information - LastBasic';
	}

	getUserNameInitials = (profile) => {
		const { firstName, lastName } = this.state;

		const first = firstName !== null ? firstName : profile.firstName;
		const last = lastName !== null ? lastName : profile.lastName;

		return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
	};
	
	getUserFullname = (profile) => {
		return (profile.firstName || '') + ' ' + (profile.lastName || '');
	};

	onChangeFirstName(e) {
		this.setState({ firstName: e.target.value });
	}

	onChangeLastName(e) {
		this.setState({ lastName: e.target.value });
	}

	onChangeCountry(e) {
		const value = e.target.value;
		const countries = COUNTRIES.getEntries();

		if (!value) {
			return this.setState({
				selectedCountry: null,
			});
		}

		const selectedCountry = countries[value].name;
		this.setState({
			selectedCountry,
		});
	}

	onChangeExpertise(e) {
		const value = e.target.value;
		if (!value) {
			return this.setState({
				expertExpertise: null,
			});
		}

		const expertExpertise = EXPERT.stringToExpertise(value);
		this.setState({
			expertExpertise,
		});
	}

	onChangeSecondaryExpertise(e) {
		const value = e.target.value;
		if (!value) {
			return this.setState({
				expertSecondaryExpertises: [],
			});
		}

		const expertSecondaryExpertises = EXPERT.stringToExpertise(value);

		this.setState({
			expertSecondaryExpertises
		});
	}

	onUpdateAboutExpertInfo = async (e, updateExpertInformation) => {
		const { userData: { user } } = this.props;
		const { firstName, lastName } = this.state;

		e.preventDefault();
		this.setState({ updatingAboutExpertInfo: true });

		let error;
		try {
			const result = await updateExpertInformation({
				variables: {
					information: {
						firstName: firstName !== null ? firstName : user.firstName,
						lastName: lastName !== null ? lastName : user.lastName,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating expert info');
			this.setState({
				firstName: null,
				lastName: null,
				expertInfoEditingError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					expertInfoEditingError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating expert info');
			this.setState({ updatingAboutExpertInfoSuccessfully: true, updatingAboutExpertInfo: false });
			setTimeout(() => {
				this.setState({
					updatingAboutExpertInfoSuccessfully: false,
					editAboutSection: false,
				});
				this.refetchProfileInfo();
			}, 3000);
		}
		this.setState({ updatingAboutExpertInfo: false });
	};

	onUpdateExpertInfo = async (e, updateExpertInformation) => {
		const { userData: { user } } = this.props;
		const { expert } = user;
		const { expertType, expertExpertise, yearsExperience, selectedCountry, expertSecondaryExpertises } = this.state;

		e.preventDefault();
		this.setState({ updatingProfessionalExpertInfo: true });

		let error;
		try {
			const result = await updateExpertInformation({
				variables: {
					information: {
						countryResidence: selectedCountry !== null ? selectedCountry : expert.countryResidence,
						expertExpertise: expertExpertise !== null ? expertExpertise : expert.expertExpertise,
						expertSecondaryExpertises: expertSecondaryExpertises.length > 0 ? [expertSecondaryExpertises] : expert.expertSecondaryExpertises,
						expertType: expertType !== null ? expertType : expert.expertType,
						yearsExperience: yearsExperience !== null ? yearsExperience : expert.yearsExperience,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating expert info');
			this.setState({
				countryResidence: null,
				expertType: null,
				expertExpertise: null,
				expertSecondaryExpertises: [],
				yearsExperience: null,
				expertInfoEditingError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					expertInfoEditingError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating expert info');
			this.setState({ updatingProfessionalExpertInfoSuccessfully: true, updatingProfessionalExpertInfo: false });
			setTimeout(() => {
				this.setState({
					updatingProfessionalExpertInfoSuccessfully: false,
					editProfessionalSection: false,
				});
				this.refetchProfileInfo();
			}, 3000);
		}
		this.setState({ updatingProfessionalExpertInfo: false });
	};

	getAvatarUrl(userId) {
		return serverAPI.getAvatarUrl(userId);
	}

	render() {
		const { userData: {user} } = this.props;
		const {
			editAboutSection, editProfessionalSection,
			firstName, lastName,
			updatingAboutExpertInfo,
			updatingAboutExpertInfoSuccessfully, updatingProfessionalExpertInfo,
			updatingProfessionalExpertInfoSuccessfully,
			expertInfoEditingError, userInfo,
		} = this.state;

		const memberSince = dates.formattedNewDate(user.createdAt);

		return (
			<ErrorBoundaryQuery query={GET_MY_PROFILE_INFO}>
				{({ loading, error, data, refetch: refetchProfileInfo }) => {
					this.refetchProfileInfo = refetchProfileInfo;

					if (loading) {
						return (
							<div className={classes.profileContainer}>
								<BreadcrumbSkeleton className={classes.breadcrumbBox}/>
								<SkeletonText style={{ width: '100px'}} />
								<div className={classnames([classes.section, classes.summaryBlock])}>
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
								</div>
								<div className={classes.section}>
									<SkeletonText style={{ width: '100px'}} />
									<SkeletonText style={{ width: '300px'}} />
									<SkeletonText style={{ width: '300px'}} />
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const profile = (data && data.myProfileInfo ) || [];

					const { expert } = profile;
					const userAvatar = userInfo && userInfo.hasAvatar;

					return (
						<div className={classes.profileContainer}>
							<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
								<BreadcrumbItem>
									<Link to="/account/settings">Account</Link>
								</BreadcrumbItem>
								<BreadcrumbItem isCurrentPage href="#">
								Personal information
								</BreadcrumbItem>
							</Breadcrumb>

							<h2 className={classes.title}>Personal information</h2>
							<p>Review and update your personal information</p>
							<div className={classes.personalInfoContent}>
								<div className={classes.avatarContainer}>
									{userAvatar && user.hasAvatar
										?	<img src={this.getAvatarUrl(user.id)} className={classes.avatarImg} alt='User avatar' />
										:	<div className={classes.profileAvatar}>
											{this.getUserNameInitials(profile)}
										</div>
									}
									<h4>{this.getUserFullname(profile)}</h4>
									{expert && <Tag type="blue" style={{ borderRadius: '4px'}}>Expert</Tag>}
									<small style={{ margin: '16px 0'}}>Member since: {memberSince}</small>
								</div>
								<div className={classes.informationBox}>
									{expert &&
										<Mutation mutation={UPDATE_EXPERT_INFO}>
											{(updateExpertInformation, { error }) => (
												<>
													{error && (
														<InlineNotification
															className={classes.notification}
															kind="error"
															lowContrast
															hideCloseButton={false}
															title="Error"
															subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																? error.graphQLErrors[0].message
																: (error.networkError)
																	? error.networkError.message
																	: error.message}
														/>
													)}
													{expertInfoEditingError &&
														<InlineNotification
															className={classes.notification}
															kind="error"
															lowContrast
															hideCloseButton={false}
															title="Error"
															subtitle={expertInfoEditingError}
														/>
													}
													<div className={classes.section}>
														<div style={{ display: 'flex', justifyContent: 'space-between'}}>
															<h4 className={classes.secondaryTitle}>About</h4>
															{editAboutSection === false &&
																<Button
																	className={classes.btn}
																	onClick={() => this.setState({ editAboutSection: true })}
																	size='sm'
																	kind='secondary'>
																		Edit
																</Button>
															}
														</div>
														<div className={classes.infoElements}>
															<h5>First name</h5>
															{editAboutSection
																?	<TextInput
																	id="first-name-input"
																	className={classes.textInput}
																	type="text"
																	value={firstName !== null ? firstName : profile.firstName}
																	onChange={(e) => this.onChangeFirstName(e)}
																	required
																/>
																:	<p>{profile.firstName}</p>
															}
														</div>
														<div className={classes.infoElements}>
															<h5>Last name</h5>
															{editAboutSection
																?	<TextInput
																	id="last-name-input"
																	className={classes.textInput}
																	type="text"
																	value={lastName !== null ? lastName : profile.lastName}
																	onChange={(e) => this.onChangeLastName(e)}
																	required
																/>
																:	<p>{profile.lastName}</p>
															}
														</div>
														<div className={classes.infoElements}>
															<h5 style={{ display: 'flex', alignItems: 'center'}}>Email <Locked16 style={{marginLeft: '4px'}} /></h5>
															<p>{profile.email}</p>
														</div>
														{editAboutSection &&
															<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '36px 0'}}>
																<Button
																	className={classes.btn}
																	style={{ marginRight: '4px'}}
																	onClick={() => this.setState({ editAboutSection: false })}
																	disabled={updatingAboutExpertInfo || updatingAboutExpertInfoSuccessfully}
																	size='sm'
																	kind='ghost'>
																		Cancel
																</Button>
																{updatingAboutExpertInfo || updatingAboutExpertInfoSuccessfully ? (
																	<InlineLoading
																		style={{ marginLeft: '1rem', width: '200px' }}
																		description={updatingAboutExpertInfoSuccessfully ? 'Info updated successfully' : 'Updating info...'}
																		status={updatingAboutExpertInfoSuccessfully ? 'finished' : 'active'}
																	/>
																) : (
																	<Button
																		className={classes.btn}
																		onClick={(e) => this.onUpdateAboutExpertInfo(e, updateExpertInformation)}
																		size='sm'
																		kind='primary'>
																			Save
																	</Button>
																)}
															</div>
														}
													</div>
													{ expert && expert.expertType && expert.expertExpertise &&
													<>
														<div className={classes.divider}></div>
														<div className={classes.section}>
															<div style={{ display: 'flex', justifyContent: 'space-between'}}>
																<h4 className={classes.secondaryTitle}>Professional background</h4>
																{editProfessionalSection === false &&
																	<Button
																		className={classes.btn}
																		onClick={() => this.setState({ editProfessionalSection: true })}
																		size='sm'
																		kind='secondary'>
																			Edit
																	</Button>
																}
															</div>
															<div className={classes.infoElements}>
																<h5>Country of residence</h5>
																{editProfessionalSection ? (
																	<Select
																		id="select-country"
																		hideLabel={true}
																		defaultValue={expert.countryResidence && COUNTRIES.getEntryByCountry(expert.countryResidence)}
																		onChange={(e) => this.onChangeCountry(e)}
																	>
																		<SelectItemGroup label="Choose a country">
																			{COUNTRIES.getEntries().map((c, i) => (
																				<SelectItem text={c.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} value={i} key={i} />
																			))}
																		</SelectItemGroup>
																	</Select>
																) : (
																	<p>{strings.capitalizeFirstLetter(expert.countryResidence)}</p>
																)}
															</div>
															<div className={classes.infoElements}>
																<h5>Main expertise</h5>
																{editProfessionalSection ? (
																	<Select
																		id="select-expertise"
																		hideLabel={true}
																		defaultValue={EXPERT.expertiseToString(expert.expertExpertise)}
																		onChange={(e) => this.onChangeExpertise(e)}
																	>
																		<SelectItemGroup label="Choose an expertise">
																			{Object.values(EXPERT.EXPERT_EXPERTISE).map((c, i) => (
																				<SelectItem text={EXPERT.expertiseToString(c)} value={EXPERT.expertiseToString(c)} key={i} />
																			))}
																		</SelectItemGroup>
																	</Select>
																) : (
																	<p>{EXPERT.expertiseToString(expert.expertExpertise)}</p>
																)}
															</div>
															<div className={classes.infoElements}>
																<h5>Secondary expertise</h5>
																{editProfessionalSection ? (
																	<Select
																		id="select-secondary-expertise"
																		hideLabel={true}
																		defaultValue={expert.expertSecondaryExpertises.length > 0 ? EXPERT.expertiseToString(expert.expertSecondaryExpertises) : 'placeholder-item'}
																		onChange={(e) => this.onChangeSecondaryExpertise(e)}
																	>
																		<SelectItem
																			disabled
																			hidden
																			value="placeholder-item"
																			text="Choose an option"
																		/>
																		<SelectItemGroup label="Choose a secondary expertise">
																			{Object.values(EXPERT.EXPERT_EXPERTISE).map((c, i) => (
																				<SelectItem text={EXPERT.expertiseToString(c)} value={EXPERT.expertiseToString(c)} key={i} />
																			))}
																		</SelectItemGroup>
																	</Select>
																) : (
																	<p>{expert.expertSecondaryExpertises.length > 0 ? EXPERT.expertiseToString(expert.expertSecondaryExpertises[0]) : 'N/A'}</p>
																)}
															</div>
															<div className={classes.infoElements}>
																<h5 style={{ display: 'flex', alignItems: 'center'}}>Expert type <Locked16 style={{marginLeft: '4px'}} /></h5>
																<p>{strings.capitalizeFirstLetter(expert.expertType)}</p>
															</div>
															<div className={classes.infoElements}>
																<h5 style={{ display: 'flex', alignItems: 'center'}}>Years of experience <Locked16 style={{marginLeft: '4px'}} /></h5>
																<p>{expert.yearsExperience === null ? EXPERT.yearOfExperienceToString(EXPERT.YEARS_EXPERIENCE.Y_0_STUDENT) : EXPERT.yearOfExperienceToString(expert.yearsExperience)}</p>
															</div>
															{editProfessionalSection &&
																<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '36px 0'}}>
																	<Button
																		className={classes.btn}
																		style={{ marginRight: '4px'}}
																		onClick={() => this.setState({ editProfessionalSection: false })}
																		disabled={updatingProfessionalExpertInfo || updatingProfessionalExpertInfoSuccessfully}
																		size='sm'
																		kind='ghost'>
																			Cancel
																	</Button>
																	{updatingProfessionalExpertInfo || updatingProfessionalExpertInfoSuccessfully ? (
																		<InlineLoading
																			style={{ marginLeft: '1rem', width: '200px' }}
																			description={updatingProfessionalExpertInfoSuccessfully ? 'Info updated successfully' : 'Updating info...'}
																			status={updatingProfessionalExpertInfoSuccessfully ? 'finished' : 'active'}
																		/>
																	) : (
																		<Button
																			className={classes.btn}
																			onClick={(e) => this.onUpdateExpertInfo(e, updateExpertInformation)}
																			size='sm'
																			kind='primary'>
																				Save
																		</Button>
																	)}
																</div>
															}
														</div>
													</>
													}
												</>
											)}
										</Mutation>
									}
								</div>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

PersonalInformation.defaultProps = {
	userData: {}
};

PersonalInformation.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(PersonalInformation);
