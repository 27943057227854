import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import Rating from '@mui/material/Rating';
import {
	InlineNotification,
	InlineLoading,
	Modal,
	Button,
	TextArea,
	Form
} from 'carbon-components-react';

import { GIVE_FEEDBACK_TO_PROPOSAL } from '../../providers/mutations';
import { serverAPI } from '../../utils';
import KEYS from '../../glossary';

import classes from './edit-feedback-modal.module.scss';

class EditFeedbackModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			rating: 0,
			pros: '',
			cons: '',
			keepFeatures: '',
			removeFeatures: '',
			sendingFeedback: false,
			success: false,
			errorValidatingFeedback: null,
		};
	}

	onSetRating = (e) => {
		this.setState({ rating: parseInt(e.target.value) });
	};

	onChangeFeedbackPros = (e) => {
		this.setState({ pros: e.target.value });
	};

	onChangeFeedbackCons = (e) => {
		this.setState({ cons: e.target.value });
	};

	onChangeFeedbackKeepFeatures = (e) => {
		this.setState({ keepFeatures: e.target.value });
	};

	onChangeFeedbackRemoveFeatures = (e) => {
		this.setState({ removeFeatures: e.target.value });
	};

	async onSubmitFeedbackToProposal(e, proposal, giveFeedbackToProposalMutation) {
		const { onRequestSubmit } = this.props;
		const { rating, pros, cons, keepFeatures, removeFeatures } = this.state;

		e.preventDefault();
		let error;
		this.setState({ sendingFeedback: true });

		try {
			const result = await giveFeedbackToProposalMutation({
				variables: {
					proposalId: proposal.id,
					feedback: {
						rating: rating,
						info: {
							pros: pros,
							cons: cons,
							keepFeatures: keepFeatures,
							removeFeatures: removeFeatures
						}
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			this.setState({ errorValidatingFeedback: error.toString() });
			setTimeout(() => {
				this.setState({ errorValidatingFeedback: null });
			}, 3000);
		} else {
			console.log('Success giving feedback to proposal');
			this.setState({ success: true, sendingFeedback: false });
			setTimeout(() => {
				onRequestSubmit();
			}, 3000);
		}
		this.setState({ sendingFeedback: false });
	}

	getProposalThumbnailUrl(proposal, userData) {
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	render() {
		const { proposal, onRequestClose, userData, onClickPreviewProposal, displayImage, ...restProps } = this.props;
		const { rating, pros, cons, sendingFeedback, success, errorValidatingFeedback } = this.state;

		const fileVersion = proposal.files[0].versions[0];
		const f = fileVersion.file;

		return (
			<Mutation mutation={GIVE_FEEDBACK_TO_PROPOSAL}>
				{(giveFeedbackToProposalMutation, { error }) => {
					return (
						<Modal
							passiveModal={true}
							hasScrollingContent
							aria-label={KEYS.proposal_feedback_modal_label}
							primaryButtonText={sendingFeedback ? 'Sending feedback ...' : KEYS.submit_feedback}
							secondaryButtonText={KEYS.cancel}
							modalHeading={KEYS.proposal_feedback_modal_label}
							onRequestClose={onRequestClose}
							onRequestSubmit={(e) => this.onSubmitFeedbackToProposal(e, proposal, giveFeedbackToProposalMutation)}
							{...restProps}>
							{error && (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton={false}
									title="Error"
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							)}
							<Form>
								{displayImage &&
									<div className={classes.imgContainer}>
										<img
											className={classes.proposalImg}
											src={this.getProposalThumbnailUrl(proposal, userData)}
											alt={KEYS.proposal_overview_alt}
											onClick={() => onClickPreviewProposal(f)}
										/>
									</div>
								}
								<div className={classes.modalContent}>
									<div className={classes.contestContent}>
										<h5>{KEYS.proposal_feedback_modal_ratings}<small>*</small></h5>
										<p>{KEYS.proposal_feedback_modal_rating_description}</p>
										<div className={classes.ratingHearts}>
											<Rating
												name="simple-controlled"
												value={rating}
												onChange={(e) => this.onSetRating(e)}
											/>
										</div>
									</div>
									<div className={classes.contestContent}>
										<h5>{KEYS.proposal_feedback_modal_pros}<small>*</small></h5>
										<p>{KEYS.proposal_feedback_modal_pros_description}</p>
										<div>
											<TextArea
												id="feedback-pros-input"
												labelText=""
												helperText="Required. Minimum 8 characters"
												invalid={(errorValidatingFeedback !== null && pros.length < 8) ? true : false}
												invalidText={errorValidatingFeedback}
												minLength="8"
												cols={30}
												rows={2}
												required
												placeholder={KEYS.proposal_feedback_modal_pros_textarea_placeholder}
												onChange={(e) => this.onChangeFeedbackPros(e)}
											/>
										</div>
									</div>
									<div className={classes.contestContent}>
										<h5>{KEYS.proposal_feedback_modal_cons}<small>*</small></h5>
										<p>{KEYS.proposal_feedback_modal_cons_description}</p>
										<div>
											<TextArea
												id="feedback-cons-input"
												labelText=""
												helperText="Required. Minimum 8 characters"
												invalid={(errorValidatingFeedback !== null && cons.length < 8) ? true : false}
												invalidText={errorValidatingFeedback}
												minLength="8"
												cols={30}
												rows={2}
												required
												placeholder={KEYS.proposal_feedback_modal_cons_textarea_placeholder}
												onChange={(e) => this.onChangeFeedbackCons(e)}
											/>
										</div>
									</div>
									<div className={classes.contestContent}>
										<h5>{KEYS.proposal_keepFeatures_title}</h5>
										<p></p>
										<div>
											<TextArea
												id="feedback-keep-features-input"
												labelText=""
												cols={30}
												rows={2}
												invalidText={KEYS.proposal_feedback_modal_invalid_value}
												placeholder={'Anything you want us to know..'}
												onChange={(e) => this.onChangeFeedbackKeepFeatures(e)}
											/>
										</div>
									</div>
									<div className={classes.contestContent}>
										<h5>{KEYS.proposal_removeFeatures_title}</h5>
										<p></p>
										<div>
											<TextArea
												id="feedback-remove-features-input"
												labelText=""
												cols={30}
												rows={2}
												invalidText={KEYS.proposal_feedback_modal_invalid_value}
												placeholder={'Anything you want us to know..'}
												onChange={(e) => this.onChangeFeedbackRemoveFeatures(e)}
											/>
										</div>
									</div>
								</div>
								<div className={classes.btnBox}>
									<Button type="submit" className={classes.btn} onClick={onRequestClose} kind="secondary" disabled={sendingFeedback || success}>
										{KEYS.cancel}
									</Button>
									{sendingFeedback || success ? (
										<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={success ? 'Feedback sent successfully' : 'Sending feedback ...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorValidatingFeedback
										? 	<InlineLoading
											style={{ marginLeft: '1rem', width: '200px' }}
											description={'Error validating feedback'}
											status={'error'}
										/>
										:	(
											<Button className={classes.btn} onClick={(e) => this.onSubmitFeedbackToProposal(e, proposal, giveFeedbackToProposalMutation)}>{KEYS.submit_feedback}</Button>
										)}
								</div>
							</Form>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

EditFeedbackModal.propTypes = {
	open: PropTypes.bool.isRequired,
	proposal: PropTypes.object.isRequired,
	userData: PropTypes.object,
	onRequestClose: PropTypes.func.isRequired,
	onSecondarySubmit: PropTypes.func.isRequired,
	onRequestSubmit: PropTypes.func.isRequired,
	onClickPreviewProposal: PropTypes.func,
	displayImage: PropTypes.bool,
};

EditFeedbackModal.defaultProps = {
	open: false,
	proposal: {},
	userData: {},
	onRequestClose: () => {},
	onSecondarySubmit: () => {},
	onRequestSubmit: () => {},
	onClickPreviewProposal: () => {},
	displayImage: true,
};

export default EditFeedbackModal;