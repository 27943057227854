import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TooltipIcon, TooltipDefinition } from 'carbon-components-react';

import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Chip from '@mui/material/Chip';

import { CONTEST, FILE, PROJECT, PROPOSAL } from '../../../../constants';
import { dates, serverAPI, strings } from '../../../../utils';

import classes from './blocks.module.scss';

class ProjectFiles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filter: 'all'
		};
	}

	render() {
		const { project } = this.props;
		const { filter } = this.state;

		const contests = project.contests;
		const sketchContest = contests.filter(c => c.type === CONTEST.TYPES.SKETCH);
		const modelContest = contests.filter(c => c.type === CONTEST.TYPES.MODEL);
		const electricalContest = contests.filter(c => c.type === CONTEST.TYPES.ELECTRICAL);
		const mechanicalContest = contests.filter(c => c.type === CONTEST.TYPES.MECHANICAL);
		const prototypingContest = contests.filter(c => c.type === CONTEST.TYPES.PROTOTYPING);

		return (
			<div>
				{this.renderFilters(contests)}
				<div style={{display: 'flex', gap: '4px', flexWrap: 'wrap', margin: '16px 0'}}>
					{filter === 'all' && (CONTEST.getTotalContestSharedFiles(contests) > 0 ? this.renderAllContestsFiles(contests) : <small>No files attached.</small>)}
					{(filter === 'sketch') && (CONTEST.hasSharedFiles(sketchContest) ? sketchContest.map((contest) => this.renderContestSharedFiles(contest)) : <small>No files attached.</small>)}
					{(filter === 'model') && (CONTEST.hasSharedFiles(modelContest) ? modelContest.map((contest) => this.renderContestSharedFiles(contest)) : <small>No files attached.</small>)}
					{(filter === 'electrical') && (CONTEST.hasSharedFiles(electricalContest) ? electricalContest.map((contest) => this.renderContestSharedFiles(contest)) : <small>No files attached.</small>)}
					{(filter === 'mechanical') && (CONTEST.hasSharedFiles(mechanicalContest) ? mechanicalContest.map((contest) => this.renderContestSharedFiles(contest)) : <small>No files attached.</small>)}
					{(filter === 'prototyping') && (CONTEST.hasSharedFiles(prototypingContest) ? prototypingContest.map((contest) => this.renderContestSharedFiles(contest)) : <small>No files attached.</small>)}
				</div>
			</div>
		);
	}

	renderFilters(contests) {
		const { filter } = this.state;
		const activeContests = contests.filter(c => c.active === true);
		const totalAllFiles = PROPOSAL.getSharedFilesApproved(activeContests);

		return (
			<div style={{display: 'flex', gap: '8px', margin: '16px 0', flexWrap: 'wrap'}}>
				<Chip label={`All (${totalAllFiles})`} variant={filter !== 'all' ? 'outlined' : ''} onClick={() => this.setState({filter: 'all'})} />
				{activeContests.map((contest, i) => (
					<Chip key={i} label={strings.capitalizeFirstLetter(contest.type)} variant={filter !== contest.type ? 'outlined' : ''} onClick={() => this.setState({filter: contest.type})} />
				))}
			</div>
		);
	}

	renderAllContestsFiles(contests) {
		return contests.map((contest) => this.renderContestSharedFiles(contest));
	}

	renderContestSharedFiles(contest) {
		const { onOpenPreviewFile } = this.props;

		const rows = [];
		if (contest) {
			const { sharedFiles } = contest;

			for (const sharedFile of sharedFiles) {
				if (sharedFile.approvalStatus === FILE.APPROVAL_STATUS.APPROVED) {
					const f = sharedFile.file;
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);

					const newRow = (
						<div
							className={classes.elementBox}
							style={{ cursor: 'pointer' }}
							onClick={() => onOpenPreviewFile(f)}
						>
							<div className={classes.naming}>
								<AttachFileIcon
									sx={{width: 20, height: 20 }}
									style={{color: '#454A50'}}
								/>
								<div style={{margin: '0 8px'}}>
									<h6 className={classes.fileName}>
										{f.name !== null
											? (f.name.length > 20)
												?	<TooltipDefinition
													tooltipText={strings.capitalizeFirstLetter(f.name)}
													className={classes.tooltipText}
												>{f.name.substring(0, 17) + '...'}</TooltipDefinition>
												: f.name
											: 'Shared file'
										}
									</h6>
									<small>{dates.formattedNewDateShort(f.createdAt)}</small>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div
									className={classes.phaseBubble}
									style={{ backgroundColor: CONTEST.getPhaseColorWithContestType(contest.type) }}
								>
									{contest.type === PROJECT.CONTEST_TYPES.MODEL
										?	'3D'
										:	contest.type.substring(0, 2).toUpperCase()
									}
								</div>
								<TooltipIcon tooltipText="Download file">
									<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
										<FileDownloadOutlined
											sx={{width: 22, height: 22 }}
											style={{fill: '#0062ff', margin: '4px', verticalAlign: 'middle'}}
										/>
									</a>
								</TooltipIcon>
							</div>
						</div>
					);
					rows.push(newRow);
				}
			}
		}
		return rows.length > 0 && rows.map(r => r);
	}
}

ProjectFiles.defaultProps = {
	project: {},
	userData: {},
	onOpenPreviewFile() {}
};

ProjectFiles.propTypes = {
	project: PropTypes.object.isRequired,
	userData: PropTypes.object,
	onOpenPreviewFile: PropTypes.func,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectFiles);
