const QUESTION_TYPES = {
	TEXT: 'text',
	OPTIONS: 'options',
};

function canWriteCustomAnswer(option) {
	if (option === 'Other') return true;
	if (option.startsWith('Other:')) return true;
	if (option.toLowerCase().indexOf('write here') !== -1) return true;

	return false;
}

function isAtLeastOneQuestionAnswered (questions) {
	if (!questions || !Array.isArray(questions)) return false;
	for (const question of questions) {
		if (question.answer.length > 0) {
			return true;
		}
	}
	return false;
}

function areAllQuestionsAnswered (questions) {
	if (!questions || !Array.isArray(questions)) return false;
	for (const question of questions) {
		if (question.answer.length === 0) {
			return false;
		}
	}
	return true;
}

function areItemsWithoutFeedback (items) {
	if (!items || !Array.isArray(items)) return false;
	for (const item of items) {
		if (item !== 0) {
			return false;
		}
	}
	return true;
}

export default {
	QUESTION_TYPES,
	canWriteCustomAnswer,
	isAtLeastOneQuestionAnswered,
	areAllQuestionsAnswered,
	areItemsWithoutFeedback,
};
