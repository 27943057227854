import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
	SkeletonPlaceholder,
	Select,
	SelectItem,
	SelectItemGroup,
	Button,
	InlineLoading,
} from 'carbon-components-react';

import { PROJECT } from '../../../../constants';

import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';
import ProjectElement from '../../../../components/project/project-element';
import BackToTopButton from '../../../../components/elements/back-to-top-btn';

import classes from './project-list.module.scss';

class ProjectList extends Component {
	constructor(props) {
		super(props);

		const { page, pageSize } = this.props;

		this.state = {
			page,
			pageSize,
			filterBy: null,
			projectList: [],
		};
	}

	onChangeFilteredPhase = (e) => {
		const value = e.target.value;

		this.setState({ filterBy: value });
		if (!value) {
			return this.setState({
				filterBy: null,
			});
		}
	};

	filterProjects(projects) {
		const { filterBy } = this.state;
		const filteredProjects = projects.filter(p => p.state === filterBy);

		return (
			filteredProjects.map((project, i) => (
				<div key={i} className={classes.gridItem}>
					<ProjectElement project={project} />
				</div>
			))
		);
	}

	loadMoreItems(fetchMore, queryVariables, keyName) {
		const { pageSize } = this.state;
		this.setState({onLoadingMoreProjects: true});

		const newPageSize = pageSize + 9;
		const moreItems = fetchMore({
			variables: {
				...queryVariables,
				pageSize: newPageSize,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				if (!fetchMoreResult[keyName] || fetchMoreResult[keyName].projects.length === 0) {
					return previousResult;
				}

				const newProjects = fetchMoreResult[keyName].projects;
				this.setState({
					projectList: newProjects,
					pageSize: newPageSize,
					onLoadingMoreProjects: false,
				});
				window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth'});

				const allProjects = [previousResult.length > 0 ? previousResult[keyName].projects : {}, ...newProjects];
				const totalCount = fetchMoreResult[keyName].totalCount;

				return {
					[keyName]: {
						__typename: 'ProjectList',
						projects: allProjects,
						totalCount,
					}
				};
			}
		}).catch((err) => {
			// Ignore error
			console.log('Error: ', err);
		});
		return moreItems;
	}

	render() {
		const { query: queryParam, variables } = this.props;
		const { page, pageSize, filterBy, projectList, onLoadingMoreProjects } = this.state;

		const queryVariables = {
			...variables,
			page,
			pageSize,
		};

		window.Froged('track', 'scroll_projects_list');

		return (
			<ErrorBoundaryQuery query={queryParam} variables={queryVariables}>
				{({ loading, error, data, fetchMore }) => {
					if (error) return (
						<div>Error: {error.message}</div>
					);
					if (loading) return (
						<div className={classes.loadingContainer}>
							<div className={classes.gridList}>
								<SkeletonPlaceholder className={classnames([classes.gridItem, classes.cardSkeleton])} />
								<SkeletonPlaceholder className={classnames([classes.gridItem, classes.cardSkeleton])} />
								<SkeletonPlaceholder className={classnames([classes.gridItem, classes.cardSkeleton])} />
							</div>
						</div>
					);

					// No data provided
					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const projects = (result && result.projects) || [];
					const totalCount = (result && result.totalCount) || 0;

					if (!totalCount) {
						return (<p>No projects found</p>);
					}

					const completeList = (projectList.length > projects.length) ? projectList : projects;
					const hasMoreItems = completeList.length < totalCount;
					const areAllItemsListed = totalCount && totalCount > 9;
					const phases = [...PROJECT.getContestStates(), PROJECT.STATE.CLOSED];

					return (
						<>
							<div className={classes.filterPhaseBox}>
								<Select
									id="filter-by-phase-selector"
									labelText=""
									light
									defaultValue={filterBy && filterBy}
									onChange={(e) => this.onChangeFilteredPhase(e)}>
									<SelectItem
										value=""
										text="All phases"
									/>
									<SelectItemGroup label="Contest phases">
										{phases.map((p, i) => (
											<SelectItem text={PROJECT.stateToString(p)} value={p} key={i} />
										))}
									</SelectItemGroup>
								</Select>
							</div>
							<div className={classes.gridList}>
								{(filterBy !== null) ?
									this.filterProjects(completeList)
									: 	completeList.map((project, i) => (
										<div key={i} className={classes.gridItem}>
											<ProjectElement project={project} />
										</div>
									))
								}
							</div>
							<div className={classnames(classes.loadingContainer, classes.btnBottom)}>
								{hasMoreItems ? (
									(onLoadingMoreProjects) 
										?	<InlineLoading description='Loading more projects...' />
										:	<Button
											kind='secondary'
											aria-label='Load more items'
											onClick={() => this.loadMoreItems(fetchMore, queryVariables, keyName)}
											style={{borderRadius: '4px', width: '236px', justifyContent: 'center', padding: '0'}}
										>
											Load more
										</Button>
								) : (areAllItemsListed) &&
									<>
										<p style={{ margin: '16px' }}>
											{/* eslint-disable-next-line */}
											Yay 🎉 You have seen it all!
										</p>
										<BackToTopButton />
									</>
								}
							</div>
						</>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

ProjectList.defaultProps = {
	query: {},
	variables: {},
	page: 0,
	pageSize: 9,
};

ProjectList.propTypes = {
	query: PropTypes.object.isRequired,
	variables: PropTypes.object,
	page: PropTypes.number,
	pageSize: PropTypes.number,
};

export default ProjectList;
