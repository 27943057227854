import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Favorite32, FavoriteFilled32 } from '@carbon/icons-react';

import classes from './heart.module.scss';

class Heart extends Component {
	constructor(props) {
		super(props);
		
		const { active } = this.props;
		
		this.state = {
			active,
		};
	}
	
	onClick = (e) => {
		e.preventDefault();
		
		const { onClick } = this.props;
		this.setState({ active: !this.state.active }, () => {
			onClick(e, this.state.active);
		});
	};
	
	render() {
		const { active } = this.state;
		
		return (
			<div className={classes.circle + (active ? ' active' : '')}
				onClick={this.onClick}>
				{active ? (
					<FavoriteFilled32 className={classes.icon} />							
				) : (
					<Favorite32 className={classes.icon} />						
				)}
			</div>
		);
	}
}

Heart.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

Heart.defaultProps = {
	active: false,
};

export default Heart;
