import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classes from '../new-project.module.scss';

import Pager from '../components/pager';

import image1 from '../assets/1.svg';
import image2 from '../assets/2.svg';
import image3 from '../assets/3.svg';

import sendInsight from '../../../../utils/insights';

export default function OfferingStep({ onNext }) {
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		const tokensLocalStorage = window.localStorage.getItem('TOKENS');
		if (tokensLocalStorage) {
			try {
				const payload = JSON.parse(tokensLocalStorage);

				const payloadUserId = payload?.user?.id;
				if (payloadUserId) setUserId(payloadUserId);
			} catch (e) { console.error(e); }
		}

		sendInsight('view', 'new_project_offering_page', null, '', '');
	}, []);

	return (
		<>
			<Pager
				steps={[
					{ name: 'Offering', active: true },
					{ name: 'Details', active: false },
					{ name: 'Validation', active: false },
				]}
			/>
			{/* <h2 className={classes.title}>Create a new <span className={classes.yellowUnderline}>project</span></h2> */}
			<h2 className={classes.title}>Creating your <span className={classes.yellowUnderline}>ideas</span><br/>with LastBasic</h2>
			
			<div className={classes.textWrapper}>
				<div className={classes.text}>
					<div className={classes.textBlock}>
						<div className={classes.textImage} style={{ backgroundImage: `url(${image1})` }}></div>
						<div>
							<div className={classes.textBlockTitle}>Having an idea might be easy but executing it <span className={classes.bold}>is hard</span></div>
							<div className={classes.textBlockDescription}>You need to take into account sketches, 3D models, the mechanical design...</div>
						</div>
					</div>

					<div className={classes.textBlock}>
						<div className={classes.textImage} style={{ backgroundImage: `url(${image2})` }}></div>
						<div>
							<div className={classes.textBlockTitle}>At LastBasic we help you <span className={classes.bold}>create your prototype</span></div>
							<div className={classes.textBlockDescription}>For a fixed fee, we bring talented experts that will upload their proposals though all the necessary engineering phases</div>
						</div>
					</div>

					<div className={classes.textBlock}>
						<div className={classes.textImage} style={{ backgroundImage: `url(${image3})` }}></div>
						<div>
							<div className={classes.textBlockTitle}>You will receive a working prototype, up to <span className={classes.bold}>10x more economical</span></div>
							<div className={classes.textBlockDescription}>LastBasic will deliver a high quality physical prototype of your idea, made with your selected favorite proposals from top experts</div>
						</div>
					</div>
				</div>
			</div>


			<div className={classes.buttonWrapper}>
				<div className={classes.button} onClick={onNext}>Create a prototype</div>
			</div>

			<div className={classes.buttonWrapper}>
				<div className={classes.otherButton} onClick={() => {
					window.open('https://lastbasic.typeform.com/to/iv75Pk3J' + (userId ? '#user_id=' + userId : ''), '_blank');
					sendInsight('click', 'new_project_another_thing_link', null, '', '');
				}}>I want another thing</div>
			</div>
		</>
	);
}

OfferingStep.propTypes = {
	onNext: PropTypes.func,
};
