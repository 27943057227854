import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loading, Modal } from 'carbon-components-react';

import Config from '../../config';
import classes from './preview-file-modal.module.scss';

class SalesCallModal extends Component {
	render() {
		const { open, ...rest } = this.props;
		
		return (
			<Modal
				passiveModal
				className={classes.previewFileModal}
				open={open}
				modalLabel="Set a call"
				modalHeading="Meet us"
				{...rest}
			>
				{open && (
					<Fragment>
						<Loading
							className={classes.loading}
							withOverlay={false} />
						<iframe title="Have a call with us" src={Config.hubspot.scheduleMeeting} />
					</Fragment>
				)}
			</Modal>
		);
	}
}

SalesCallModal.propTypes = {
	open: PropTypes.bool,
};

SalesCallModal.defaultProps = {
	open: false,
};

export default SalesCallModal;
