import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loading, Modal } from 'carbon-components-react';

import Config from '../../config';
import classes from './preview-file-modal.module.scss';

class PreviewFormModal extends Component {
	render() {
		const { open, ...rest } = this.props;
		
		return (
			<Modal
				passiveModal
				className={classes.previewFileModal}
				open={open}
				modalHeading="Legal information form"
				{...rest}
			>
				{open && (
					<Fragment>
						<Loading
							className={classes.loading}
							withOverlay={false} />
						<iframe title="Fill legal information" src={Config.typeform.forms.paymentInfo} />
					</Fragment>
				)}
			</Modal>
		);
	}
}

PreviewFormModal.propTypes = {
	open: PropTypes.bool,
};

PreviewFormModal.defaultProps = {
	open: false,
};

export default PreviewFormModal;