import dayjs from 'dayjs';

function prettifyDate(input) {
	const date = new Date(input);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
}

function formattedDate(dateInput) {
	return dayjs(dateInput).format('DD MMMM YYYY');
}

function formattedNewDate(dateInput) {
	const newDate = new Date(parseInt(dateInput));
	return formattedDate(newDate);
}

function formattedShortDate(dateInput) {
	return dayjs(dateInput).format('DD/MM/YY');
}

function formattedNewDateShort(dateInput) {
	const newDate = new Date(parseInt(dateInput));
	return formattedShortDate(newDate);
}

function substractDays(input, days = 1) {
	const date = new Date(input);
	return new Date(date.setDate(date.getDate() - days));
}

function addDays(input, days = 1) {
	const date = new Date(input);
	return new Date(date.setDate(date.getDate() + days));
}

export default {
	prettifyDate,
	formattedDate,
	formattedNewDate,
	formattedNewDateShort,
	substractDays,
	addDays,
};
