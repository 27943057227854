import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './card-action.module.scss';

class UpsaleAction extends Component {
	constructor (props) {
		super(props);

		this.state = {
		};
	}

	render() {
		const { type, title, optionNumber, onClick } = this.props;
		// type: light or dark
		// title: string

		return (
			<>
				<div className={classnames([classes.box, classes.blockBox, type === 'dark' && classes.isMain])} onClick={onClick}>
					<div className={classes.contentBlock} >
						<small style={{ textTransform: 'capitalize'}}>Option {optionNumber}</small>
						<h5 style={{ color: '#dd0031'}}>{title}</h5>
					</div>
				</div>
			</>
		);
	}
}

UpsaleAction.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	optionNumber: PropTypes.number,
	onClick: PropTypes.func,
};

UpsaleAction.defaultProps = {
	type: 'light',
	title: '',
	optionNumber: 1,
	onClick() {},
};

export default UpsaleAction;
