import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import {
	InlineNotification,
	BreadcrumbSkeleton,
	SkeletonPlaceholder,
	SkeletonText,
	InlineLoading,
	TextInput,
	Select,
	SelectItem,
	SelectItemGroup,
	Tag,
	Breadcrumb,
	BreadcrumbItem,
	Button
} from 'carbon-components-react';
import { Locked16 } from '@carbon/icons-react';
import { Mutation } from '@apollo/client/react/components';
import ErrorBoundaryQuery from '../../../../components/graphql/queries-with-errors';

import { GET_MY_PROFILE_INFO } from '../../../../providers/queries';
import { UPDATE_MAKER_INFO } from '../../../../providers/mutations';

import { serverAPI, userInfo, strings, dates } from '../../../../utils';
import { COUNTRIES } from '../../../../constants';
import KEYS from '../../../../glossary';

import classes from './personal-information.module.scss';

class MakerPersonalInformation extends Component {
	constructor(props) {
		super(props);

		this.refetchProfileInfo = () => {};
		this.state = {
			userInfo: userInfo.getUserInfo(),
			editAboutSection: false,
			editAddressSection: false,
			updatingAboutMakerInfo: false,
			updatingAboutMakerInfoSuccessfully: false,
			firstName: null,
			lastName: null,
			countryResidence: null,
			selectedCountry: null,
			address: null,
			city: null,
			postalCode: null,
			phoneNumber: null,
			updatingMakerDeliveryAddressInfo: false,
			updatingMakerDeliveryAddressInfoSuccessfully: false,
		};
	}

	componentDidMount() {
		document.title = 'Personal information - LastBasic';
	}

	getUserNameInitials = (profile) => {
		const { firstName, lastName } = this.state;

		const first = firstName !== null ? firstName : profile.firstName;
		const last = lastName !== null ? lastName : profile.lastName;

		return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
	};

	getUserFullname = (profile) => {
		return (profile.firstName || '') + ' ' + (profile.lastName || '');
	};

	onChangeFirstName(e) {
		this.setState({ firstName: e.target.value });
	}

	onChangeLastName(e) {
		this.setState({ lastName: e.target.value });
	}

	onChangeCountry(e, countries) {
		const value = e.target.value;
		if (!value) {
			return this.setState({
				selectedCountry: null,
			});
		}

		const selectedCountry = countries[value].name;
		this.setState({
			selectedCountry,
		});
	}

	getAvatarUrl(userId) {
		return serverAPI.getAvatarUrl(userId);
	}

	onChangeAddress(e) {
		this.setState({ address: e.target.value });
	}

	onChangeCity(e) {
		this.setState({ city: e.target.value });
	}

	onChangeZipCode(e) {
		this.setState({ postalCode: e.target.value });
	}

	onChangePhoneContact(e) {
		this.setState({ phoneNumber: e.target.value });
	}

	onUpdateMakerInfo = async (e, updateMakerInformation) => {
		const { userData: { user } } = this.props;
		const { maker } = user;
		const { selectedCountry, address, city, postalCode, phoneNumber } = this.state;

		e.preventDefault();
		this.setState({ updatingMakerDeliveryAddressInfo: true });

		const addressInfo = {
			country: selectedCountry !== null ? selectedCountry : maker.countryResidence,
			street: address !== null ? address : maker.deliveryAddress.street,
			city: city !== null ? city : maker.deliveryAddress.city,
			postalCode: postalCode !== null ? parseInt(postalCode) : maker.deliveryAddress.postalCode,
			phoneNumber: phoneNumber !== null ? phoneNumber.toString() : maker.deliveryAddress.phoneNumber,
		};

		let error;
		try {
			const result = await updateMakerInformation({
				variables: {
					information: {
						deliveryAddress: addressInfo,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating maker info');
			this.setState({
				selectedCountry: null,
				address: null,
				city: null,
				postalCode: null,
				phoneNumber: null,
				makerInfoEditingError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					makerInfoEditingError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating maker info');
			this.setState({ updatingMakerDeliveryAddressInfoSuccessfully: true, updatingMakerDeliveryAddressInfo: false });
			setTimeout(() => {
				this.setState({
					updatingMakerDeliveryAddressInfoSuccessfully: false,
					editAddressSection: false,
				});
				this.refetchProfileInfo();
			}, 3000);
		}
		this.setState({ updatingMakerDeliveryAddressInfo: false });
	};

	onUpdateAboutMakerInfo = async (e, updateMakerInformation) => {
		const { userData: { user } } = this.props;
		const { firstName, lastName } = this.state;

		e.preventDefault();
		this.setState({ updatingAboutMakerInfo: true });

		let error;
		try {
			const result = await updateMakerInformation({
				variables: {
					information: {
						firstName: firstName !== null ? firstName : user.firstName,
						lastName: lastName !== null ? lastName : user.lastName,
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error updating maker info');
			this.setState({
				firstName: null,
				lastName: null,
				makerInfoEditingError: error.toString(),
			});
			setTimeout(() => {
				this.setState({
					makerInfoEditingError: null,
				});
			}, 3000);
		} else {
			console.log('Success updating maker info');
			this.setState({ updatingAboutMakerInfoSuccessfully: true, updatingAboutMakerInfo: false });
			setTimeout(() => {
				this.setState({
					updatingAboutMakerInfoSuccessfully: false,
					editAboutSection: false,
				});
				this.refetchProfileInfo();
			}, 3000);
		}
		this.setState({ updatingAboutMakerInfo: false });
	};

	render() {
		const { userData: {user} } = this.props;
		const {
			editAboutSection,
			firstName,
			lastName,
			updatingAboutMakerInfo,
			userInfo,
			editAddressSection,
			address,
			city,
			postalCode,
			phoneNumber,
			updatingMakerDeliveryAddressInfo,
			updatingMakerDeliveryAddressInfoSuccessfully,
			makerInfoEditingError,
			updatingAboutMakerInfoSuccessfully
		} = this.state;

		const memberSince = dates.formattedNewDate(user.createdAt);

		return (
			<ErrorBoundaryQuery query={GET_MY_PROFILE_INFO}>
				{({ loading, error, data, refetch: refetchProfileInfo }) => {
					this.refetchProfileInfo = refetchProfileInfo;

					if (loading) {
						return (
							<div className={classes.profileContainer}>
								<BreadcrumbSkeleton className={classes.breadcrumbBox}/>
								<SkeletonText style={{ width: '100px'}} />
								<div className={classnames([classes.section, classes.summaryBlock])}>
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
									<SkeletonPlaceholder style={{ width: '100px'}} />
								</div>
								<div className={classes.section}>
									<SkeletonText style={{ width: '100px'}} />
									<SkeletonText style={{ width: '300px'}} />
									<SkeletonText style={{ width: '300px'}} />
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const profile = (data && data.myProfileInfo ) || {};

					const { maker } = profile;
					const userAvatar = userInfo && userInfo.hasAvatar;

					return (
						<div className={classes.profileContainer}>
							<Breadcrumb noTrailingSlash className={classes.breadcrumbBox}>
								<BreadcrumbItem>
									<Link to="/account/settings">Account</Link>
								</BreadcrumbItem>
								<BreadcrumbItem isCurrentPage href="#">
									Personal information
								</BreadcrumbItem>
							</Breadcrumb>

							<h2 className={classes.title}>Personal information</h2>
							<p>Review and update your personal information</p>
							<div className={classes.personalInfoContent}>
								<div className={classes.avatarContainer}>
									{userAvatar && user.hasAvatar ? (
										<img src={this.getAvatarUrl(user.id)} className={classes.avatarImg} alt='User avatar' />
									) : (
										<div className={classes.profileAvatar}>
											{this.getUserNameInitials(profile)}
										</div>
									)}
									<h4>{this.getUserFullname(profile)}</h4>
									{maker && (
										<Tag type="gray" style={{ borderRadius: '4px'}}>Maker</Tag>
									)}
									<small style={{ margin: '16px 0'}}>Member since: {memberSince}</small>
								</div>
								<div className={classes.informationBox}>
									{maker &&
									<Mutation mutation={UPDATE_MAKER_INFO}>
										{(updateMakerInformation, { error }) => (
											<Fragment>
												{error && (
													<InlineNotification
														className={classes.notification}
														kind="error"
														lowContrast
														hideCloseButton={false}
														title="Error"
														subtitle={(error.graphQLErrors && error.graphQLErrors.length)
															? error.graphQLErrors[0].message
															: (error.networkError)
																? error.networkError.message
																: error.message}
													/>
												)}
												{makerInfoEditingError &&
													<InlineNotification
														className={classes.notification}
														kind="error"
														lowContrast
														hideCloseButton={false}
														title="Error"
														subtitle={makerInfoEditingError}
													/>
												}
												<div className={classes.section}>
													<div style={{ display: 'flex', justifyContent: 'space-between'}}>
														<h4 className={classes.secondaryTitle}>About</h4>
														{editAboutSection === false &&
															<Button
																className={classes.btn}
																onClick={() => this.setState({ editAboutSection: true })}
																size='sm'
																kind='secondary'>
																{KEYS.edit}
															</Button>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>First name</h5>
														{editAboutSection
															?	<TextInput
																id="first-name-input"
																className={classes.textInput}
																type="text"
																value={firstName !== null ? firstName : profile.firstName}
																onChange={(e) => this.onChangeFirstName(e)}
																required
															/>
															:	<p>{profile.firstName}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>Last name</h5>
														{editAboutSection
															?	<TextInput
																id="last-name-input"
																className={classes.textInput}
																type="text"
																value={lastName !== null ? lastName : profile.lastName}
																onChange={(e) => this.onChangeLastName(e)}
																required
															/>
															:	<p>{profile.lastName}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5 style={{ display: 'flex', alignItems: 'center'}}>Email <Locked16 style={{marginLeft: '4px'}} /></h5>
														<p>{profile.email}</p>
													</div>
													{editAboutSection &&
														<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '36px 0'}}>
															<Button
																className={classes.btn}
																style={{ marginRight: '4px'}}
																onClick={() => this.setState({ editAboutSection: false })}
																disabled={updatingAboutMakerInfo || updatingAboutMakerInfoSuccessfully}
																size='sm'
																kind='ghost'>
																{KEYS.cancel}
															</Button>
															{updatingAboutMakerInfo || updatingAboutMakerInfoSuccessfully ? (
																<InlineLoading
																	description={updatingAboutMakerInfoSuccessfully ? 'Info updated successfully' : 'Updating info...'}
																	status={updatingAboutMakerInfoSuccessfully ? 'finished' : 'active'}
																	style={{ marginLeft: '1rem', width: '200px' }}
																/>
															) : (
																<Button
																	className={classes.btn}
																	onClick={(e) => this.onUpdateAboutMakerInfo(e, updateMakerInformation)}
																	size='sm'
																	kind='primary'>
																	{KEYS.save}
																</Button>
															)}
														</div>
													}
												</div>
												<div className={classes.divider}></div>
												<div className={classes.section}>
													<div style={{ display: 'flex', justifyContent: 'space-between'}}>
														<h4 className={classes.secondaryTitle}>Delivery address</h4>
														{editAddressSection === false &&
															<Button
																className={classes.btn}
																onClick={() => this.setState({ editAddressSection: true })}
																size='sm'
																kind='secondary'>
																{KEYS.edit}
															</Button>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>Country</h5>
														{editAddressSection
															?	<Select
																id="select-country"
																hideLabel={true}
																defaultValue={COUNTRIES.getEntryByCountry(maker.countryResidence)}
																onChange={(e) => this.onChangeCountry(e, COUNTRIES)}
															>
																<SelectItemGroup label="Choose a country">
																	{COUNTRIES.getEntries().map((c, i) => (
																		<SelectItem text={c.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} value={i} key={i} />
																	))}
																</SelectItemGroup>
															</Select>
															:	<p>{strings.capitalizeFirstLetter(maker.countryResidence)}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>Address</h5>
														{editAddressSection
															?	<TextInput
																id="address-input"
																className={classes.textInput}
																type="text"
																value={address}
																onChange={(e) => this.onChangeAddress(e)}
																required
															/>
															:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.street : 'N/A'}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>City</h5>
														{editAddressSection
															?	<TextInput
																id="city-input"
																className={classes.textInput}
																type="text"
																value={city}
																onChange={(e) => this.onChangeCity(e)}
																required
															/>
															:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.city : 'N/A'}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>Zip code</h5>
														{editAddressSection
															?	<TextInput
																id="zip-code-input"
																className={classes.textInput}
																type="number"
																value={postalCode}
																onChange={(e) => this.onChangeZipCode(e)}
																required
															/>
															:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.postalCode : 'N/A'}</p>
														}
													</div>
													<div className={classes.infoElements}>
														<h5>Contact phone number</h5>
														{editAddressSection
															?	<TextInput
																id="phone-contact-input"
																className={classes.textInput}
																type="number"
																value={phoneNumber}
																onChange={(e) => this.onChangePhoneContact(e)}
																required
															/>
															:	<p>{maker.deliveryAddress !== null ? maker.deliveryAddress.phoneNumber : 'N/A'}</p>
														}
													</div>
													{editAddressSection &&
														<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '36px 0'}}>
															<Button
																className={classes.btn}
																style={{ marginRight: '4px'}}
																onClick={() => this.setState({ editAddressSection: false })}
																disabled={updatingMakerDeliveryAddressInfo || updatingMakerDeliveryAddressInfoSuccessfully}
																size='sm'
																kind='ghost'>
																{KEYS.cancel}
															</Button>
															{updatingMakerDeliveryAddressInfo || updatingMakerDeliveryAddressInfoSuccessfully ? (
																<InlineLoading
																	description={updatingMakerDeliveryAddressInfoSuccessfully ? 'Info updated successfully' : 'Updating info...'}
																	status={updatingMakerDeliveryAddressInfoSuccessfully ? 'finished' : 'active'}
																	style={{ marginLeft: '1rem', width: '200px' }}
																/>
															) : (
																<Button
																	className={classes.btn}
																	onClick={(e) => this.onUpdateMakerInfo(e, updateMakerInformation)}
																	size='sm'
																	kind='primary'>
																	{KEYS.save}
																</Button>
															)}
														</div>
													}
												</div>
											</Fragment>
										)}
									</Mutation>
									}
								</div>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

MakerPersonalInformation.defaultProps = {
	userData: {}
};

MakerPersonalInformation.propTypes = {
	userData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(MakerPersonalInformation);
