import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
	InlineNotification,
	Modal,
	Tag,
	FileUploader,
	FileUploaderItem,
	ToastNotification,
	TooltipIcon,
	NotificationActionButton,
	Button,
	OverflowMenu,
	OverflowMenuItem,
} from 'carbon-components-react';
import {
	Information16,
	DotMark24,
	DocumentAdd24,
	Receipt24
} from '@carbon/icons-react';
import { Mutation } from '@apollo/client/react/components';

import PreviewFileModal from '../../../../../components/modals/preview-file-modal';
import ProcessModal from '../../../../../components/modals/process-modal';
import ProposalFeedbackContent from '../../../../../components/project/proposal-feedback-content';
import FileIcon from '../../../../../components/icons/file-icon';

import {
	CREATE_PROPOSAL,
	ADD_FILE_TO_PROPOSAL,
	ADD_SHAREDFILE_TO_CONTEST
} from '../../../../../providers/mutations';
import { CONTEST, PROJECT, PROPOSAL, FILE } from '../../../../../constants';
import { dates, serverAPI } from '../../../../../utils';
import KEYS from '../../../../../glossary';

import classes from './tabs.module.scss';

class EntriesTab extends Component {
	constructor (props) {
		super(props);

		this.inputFileRef = React.createRef();

		const { project } = props;
		const contest = PROJECT.getCurrentContest(project);
		const haveWinningProposal = contest && CONTEST.hasWinner(contest);

		this.state = {
			uploading: null,
			uploadingSharedFile: null,
			uploadError: null,
			previewFile: null,
			uploadSuccess: false,
			openModalWithProposalFeedback: false,
			haveWinningProposal: haveWinningProposal,
			feedback: null,
			lbComments: null,
			propFile: null,
			isWinningProposal: false,
			selectedProposal: null,
			showRejectionSharedFileComment: null,
			openProcessModal: false,
		};
	}

	async onClickUpload(e, uploadFileMutation, createProposalMutation) {
		const { project, contest, onFileUploaded, user } = this.props;
		const { target: { validity, files: [file] } } = e;
		const contestType = contest.type;

		if (!validity.valid) return;
		const fileUrl = URL.createObjectURL(file);

		const emptyProposalId = PROPOSAL.hasFilesInProposal(contest, user.id);

		this.setState({
			uploading: {
				filename: file.name,
				size: file.size,
				src: fileUrl,
			}
		}, async () => {
			if (emptyProposalId === false) {
				const proposal = await createProposalMutation({
					variables: { projectId: project.id, contestType: contestType }
				});

				if (proposal && proposal.data && proposal.data.createProposal) {
					const proposalId = proposal.data.createProposal.id;

					const result = await uploadFileMutation({
						variables: { proposalId: proposalId, file }
					});

					if (result && result.data && result.data.addFileToProposal) {
						this.setState({
							uploadSuccess: true,
						});
						onFileUploaded();
					} else {
						this.setState({
							uploadError: true,
						});
					}
				}
			} else {
				const result = await uploadFileMutation({
					variables: { proposalId: emptyProposalId, file }
				});

				if (result && result.data && result.data.addFileToProposal) {
					this.setState({
						uploadSuccess: true,
					});
					onFileUploaded();
				} else {
					this.setState({
						uploadError: true,
					});
				}
			}
			this.setState({
				uploading: null,
			});
		});
	}

	async onClickUploadSharedFiles(e, project, uploadSharedFileMutation) {
		const currentContestType = PROJECT.getContestTypeByState(project.state);
		const { onSharedFileUploaded } = this.props;
		const { target: { validity, files: [file] } } = e;

		if (!validity.valid) return;

		this.setState({
			uploadingSharedFile: {
				filename: file.name,
				size: file.size,
			}
		}, async () => {
			const result = await uploadSharedFileMutation({
				variables: { id: project.id, contestType: currentContestType, name: file.name, file }
			});

			if (result && result.data && result.data.addSharedFile) {
				this.setState({
					uploadSuccess: true,
				});
				onSharedFileUploaded();
			} else {
				this.setState({
					uploadError: true,
				});
			}

			this.setState({
				uploadingSharedFile: null,
			});
		});
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onClosePreviewFileAndReopenProposalFeedback() {
		this.setState({ previewFile: null, openModalWithProposalFeedback: true });
	}

	onShowRejectionSharedFileComment(f, sharedFileRejectedComment) {
		this.setState({ showRejectionSharedFileComment: {f: f, comment: sharedFileRejectedComment} });
	}

	renderSharedFiles(project, user, projectIsInPrototypingPhase) {
		const state = project.state;
		const currentContest = PROJECT.getCurrentContest(project);
		const { sharedFiles } = currentContest;
		const { showRejectionSharedFileComment } = this.state;

		const hasWinner = CONTEST.hasWinner(currentContest);
		const proposalUserId = PROPOSAL.getWinningProposalUserId(currentContest);

		const rows = [];
		if (hasWinner && user.id === proposalUserId) {
			for (const sharedFile of sharedFiles) {
				const f = sharedFile.file;
				// const fileApproved = sharedFile.approved; // depreciated
				const approvalStatus = sharedFile.approvalStatus;
				const sharedFileRejectedComment = approvalStatus === FILE.APPROVAL_STATUS.REJECTED && sharedFile.rejectionComment;
				const downloadUrl = serverAPI.getDownloadUrlByFile(f);

				const newRow = (
					<>
						<div className={classes.elementBox} key={f.id} style={{ border: `2px solid ${CONTEST.getPhaseColor(state)}`}}>
							<div className={classes.naming}>
								{projectIsInPrototypingPhase
									?	<Receipt24 />
									:	<DocumentAdd24 />
								}
								<small>
									<div style={{ display: 'flex', flexDirection: 'column'}}>
										<h6>{projectIsInPrototypingPhase ? 'Invoice' : PROJECT.getStateByContestTypeShort(currentContest.type)}</h6>
										<small>{f.name !== null ? f.name.substring(0, 20) : `File ${f.id}`.substring(0, 20) + '...'}</small>
									</div>
								</small>
							</div>
							<div className={classes.characteristics}>
								<div className={classes.inputCharBox}>
									{f.createdAt !== null &&
									<span>{dates.formattedNewDate(f.createdAt)}</span>
									}
								</div>
								<div className={classes.checkbox}>
									{approvalStatus === FILE.APPROVAL_STATUS.APPROVED
										?   <Tag type="green" className={classes.statusTag}>{approvalStatus}</Tag>
										:   approvalStatus === FILE.APPROVAL_STATUS.REJECTED
											?	<Tag type="red" className={classes.statusTag}>{approvalStatus}</Tag>
											:	<Tag type="gray" className={classes.statusTag}>Pending approval</Tag>
									}
								</div>
								<OverflowMenu style={{ marginLeft: '8px' }} direction="bottom" flipped light >
									<OverflowMenuItem itemText={KEYS.open_file} onClick={() => this.onOpenPreviewFile(f)} />
									{approvalStatus === FILE.APPROVAL_STATUS.REJECTED &&
									<OverflowMenuItem itemText={'Show comments'} onClick={() => this.onShowRejectionSharedFileComment(f, sharedFileRejectedComment)} />
									}
									<OverflowMenuItem itemText={KEYS.download_file} href={downloadUrl} target="_blank" rel="noopener noreferrer" />
								</OverflowMenu>
							</div>
						</div>
						{(showRejectionSharedFileComment !== null && showRejectionSharedFileComment.f === f && sharedFileRejectedComment) &&
							<small style={{color: '#df3d3b'}}>LastBasic rejection comment: {showRejectionSharedFileComment.comment}</small>
						}
					</>
				);
				rows.push(newRow);
			}
		}

		return rows.map(r => r);
	}

	displayUploadSharedFileOption(project) {
		const { uploadingSharedFile } = this.state;

		return (
			<Mutation mutation={ADD_SHAREDFILE_TO_CONTEST}>
				{(uploadSharedFileMutation, { error }) => {
					if (error) return (
						<InlineNotification
							className={classes.notification}
							kind="error"
							lowContrast
							hideCloseButton
							title="Error"
							subtitle={(error.graphQLErrors && error.graphQLErrors.length)
								? error.graphQLErrors[0].message
								: (error.networkError)
									? error.networkError.message
									: error.message}
						/>
					);
					return uploadingSharedFile ?
						<FileUploaderItem
							name={uploadingSharedFile.filename}
							errorSubject={KEYS.files_error_exceeds_limit}
							status='uploading'
						/>
						:	<FileUploader
							buttonKind="secondary"
							buttonLabel={KEYS.add_more_files}
							labelDescription={KEYS.add_more_files_description}
							style={{borderRadius: '4px'}}
							onChange={(e) => this.onClickUploadSharedFiles(e, project, uploadSharedFileMutation)}
						/>;
				}}
			</Mutation>
		);
	}

	renderFiles(project, user) {
		const state = project.state;
		const currentContest = PROJECT.getCurrentContest(project);
		const { proposals, selectedProposal } = currentContest;
		const projectIsInPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST;

		const hasWinner = selectedProposal !== null ? true : false;

		const rows = [];
		for (const proposal of proposals) {
			if (user.id === proposal.user.id) {
				for (const proposalFile of proposal.files) {
					const fileVersion = proposalFile.versions[0];
					const f = fileVersion.file;
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);
					const feedback = proposal.feedback;
					const isWinningProposal = selectedProposal && (selectedProposal.id === proposal.id);

					const fileApprovalStatus = fileVersion.approvedStatus === 'yes' ? 'APPROVED' : (fileVersion.approvedStatus === 'no' && fileVersion.comment) ? 'REJECTED' : 'PENDING';
					const winnerStatus = (isWinningProposal && hasWinner) ? 'WINNER' : ((fileVersion.approvedStatus === 'no' && fileVersion.comment) || (!isWinningProposal && hasWinner)) ? 'NOT WINNER' : 'PENDING VOTATION';

					const newRow = (
						<div className={classnames([classes.elementBox, isWinningProposal && classes.winningProposal])} key={f.id}>
							<div className={classes.naming}>
								<FileIcon extension={f.extension !== null ? f.extension : f.id} />
								<small className={classes.fileName}>
									{fileVersion.approvedStatus === 'yes'
										? 	<div className={classes.flexColumn}>
											<h6>{projectIsInPrototypingPhase ? 'QUOTATION' : 'PROPOSAL'} #{proposal.number}</h6>
											<small>{f.name !== null ? f.name.substring(0, 20) : `File ${f.id}`.substring(0, 20)}</small>
										</div>
										: f.name !== null ? f.name : `File ${f.id}`.substring(0, 20)
									}
								</small>
							</div>
							<div className={classes.characteristics}>
								<div className={classes.inputCharBox}>
									<TooltipIcon tooltipText={PROJECT.stateToString(state)}>
										<div className={classes.phaseBubble} style={{ backgroundColor: CONTEST.getPhaseColor(state) }}>
											{state === PROJECT.STATE.MODEL_CONTEST
												?	'3D'
												:	state.substring(0, 2).toUpperCase()
											}
										</div>
									</TooltipIcon>
									{f.createdAt !== null &&
										<span>{dates.formattedNewDate(f.createdAt)}</span>
									}
								</div>
								<div className={classnames([classes.checkbox, classes.checkboxInline])}>
									{(isWinningProposal && hasWinner)
										?	<Tag type="green" className={classes.statusTag}>Winning proposal</Tag>
										:	<>
											<TooltipIcon tooltipText={`File approval - ${fileApprovalStatus}`}>
												{fileVersion.approvedStatus === 'yes'
													?	<DotMark24 style={{ fill: '#24a148' }}/>
													:	(fileVersion.approvedStatus === 'no' && fileVersion.comment)
														?	<DotMark24 style={{ fill: '#df3d3b' }}/>
														:	<DotMark24 style={{ fill: '#e1e4e8' }}/>
												}
											</TooltipIcon>
											<TooltipIcon tooltipText={`Winning proposal - ${winnerStatus}`}>
												{(isWinningProposal && hasWinner)
													?	<DotMark24 style={{ fill: '#24a148' }}/>
													:	((fileVersion.approvedStatus === 'no' && fileVersion.comment) || (!isWinningProposal && hasWinner))
														?	<DotMark24 style={{ fill: '#df3d3b' }}/>
														:	<DotMark24 style={{ fill: '#e1e4e8' }}/>
												}
											</TooltipIcon>
										</>
									}
								</div>
							</div>
							<div className={ classnames([classes.actions, classes.moreOptions])}>
								{fileVersion.approvedStatus === 'yes' ? (
									feedback !== null ?
										<Button
											className={classnames([classes.btn, classes.fileBtn])}
											onClick={() => this.setState({ openModalWithProposalFeedback: true, feedback: feedback, propFile: f, isWinningProposal: isWinningProposal, selectedProposal: proposal })}
											size='sm'
											kind='tertiary'
										>
											{KEYS.show_feedback}
										</Button>
										:   <TooltipIcon tooltipText={KEYS.proposal_status_pending_client_feedback_tooltip}>
											<Tag type="teal" className={classes.statusTag}>{KEYS.proposal_status_pending_client_feedback}</Tag>
										</TooltipIcon>
								) : ((fileVersion.approvedStatus === 'no' && fileVersion.comment) &&
									<Button
										className={classnames([classes.btn, classes.fileBtn])}
										onClick={() => this.setState({ openModalWithProposalFeedback: true, lbComments: fileVersion.comment, selectedProposal: proposal })}
										kind='secondary'>
										{KEYS.show_comments}
									</Button>
								)}
								<OverflowMenu style={{ marginLeft: '8px' }} direction="bottom" flipped light >
									<OverflowMenuItem itemText={KEYS.open_file} onClick={() => this.onOpenPreviewFile(f)} />
									<OverflowMenuItem itemText={KEYS.download_file} href={downloadUrl} target="_blank" rel="noopener noreferrer" />
								</OverflowMenu>
							</div>
						</div>
					);
					rows.push(newRow);
				}
			}
		}

		return rows.map(r => r);
	}

	renderPastPhases(project, user, statesExpertIsParticipating) {
		return statesExpertIsParticipating
			.filter(s => s !== project.state)
			.map(state => (
				<div key={state}>
					{this.renderPastFile(project, user, state)}
				</div>
			));
	}

	renderPastFile(project, user, contest) {
		const state = contest;
		const pastContest = PROJECT.getContestByState(project, state);
		const { proposals, selectedProposal } = pastContest;

		const hasWinner = selectedProposal !== null ? true : false;

		const rows = [];
		for (const proposal of proposals) {
			if (user.id === proposal.user.id) {
				for (const proposalFile of proposal.files) {
					const fileVersion = proposalFile.versions[0];
					const f = fileVersion.file;
					const downloadUrl = serverAPI.getDownloadUrlByFile(f);
					const feedback = proposal.feedback;
					const isWinningProposal = selectedProposal && (selectedProposal.id === proposal.id);

					const fileApprovalStatus = fileVersion.approvedStatus === 'yes' ? 'APPROVED' : (fileVersion.approvedStatus === 'no' && fileVersion.comment) ? 'REJECTED' : 'PENDING';
					const winnerStatus = (isWinningProposal && hasWinner) ? 'WINNER' : ((fileVersion.approvedStatus === 'no' && fileVersion.comment) || (!isWinningProposal && hasWinner)) ? 'NOT WINNER' : 'PENDING VOTATION';

					const newRow = (
						<div className={classnames([classes.elementBox, isWinningProposal && classes.winningProposal])} key={f.id}>
							<div className={classes.naming}>
								<FileIcon extension={f.extension !== null ? f.extension : f.id} />
								<small className={classes.fileName}>
									{(fileVersion.approvedStatus === 'yes') ? (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<h6>PROPOSAL #{proposal.number}</h6>
											<small>{f.name !== null ? f.name.substring(0, 20) : `File ${f.id}`.substring(0, 20)}</small>
										</div>
									) : (
										(f.name !== null) ? f.name : `File ${f.id}`.substring(0, 20)
									)}
								</small>
							</div>
							<div className={classes.characteristics}>
								<div className={classes.inputCharBox}>
									<TooltipIcon tooltipText={PROJECT.stateToString(state)}>
										<div className={classes.phaseBubble} style={{ backgroundColor: CONTEST.getPhaseColor(state) }}>
											{state === PROJECT.STATE.MODEL_CONTEST
												?	'3D'
												:	state.substring(0, 2).toUpperCase()
											}
										</div>
									</TooltipIcon>
									{f.createdAt !== null &&
										<span>{dates.formattedNewDate(f.createdAt)}</span>
									}
								</div>
								<div className={classnames([classes.checkbox, classes.checkboxInline])}>
									{(isWinningProposal && hasWinner)
										?	<Tag type="green" className={classes.statusTag}>Winning proposal</Tag>
										:	<>
											<TooltipIcon tooltipText={`File approval - ${fileApprovalStatus}`}>
												{fileVersion.approvedStatus === 'yes'
													?	<DotMark24 style={{ fill: '#24a148' }}/>
													:	(fileVersion.approvedStatus === 'no' && fileVersion.comment)
														?	<DotMark24 style={{ fill: '#df3d3b' }}/>
														:	<DotMark24 style={{ fill: '#e1e4e8' }}/>
												}
											</TooltipIcon>
											<TooltipIcon tooltipText={`Winning proposal - ${winnerStatus}`}>
												{(isWinningProposal && hasWinner)
													?	<DotMark24 style={{ fill: '#24a148' }}/>
													:	((fileVersion.approvedStatus === 'no' && fileVersion.comment) || (!isWinningProposal && hasWinner))
														?	<DotMark24 style={{ fill: '#df3d3b' }}/>
														:	<DotMark24 style={{ fill: '#e1e4e8' }}/>
												}
											</TooltipIcon>
										</>
									}
								</div>
							</div>
							<div className={ classnames([classes.actions, classes.moreOptions])}>
								{fileVersion.approvedStatus === 'yes' ? (
									feedback !== null ?
										<Button
											className={classnames([classes.btn, classes.fileBtn])}
											onClick={() => this.setState({ openModalWithProposalFeedback: true, feedback: feedback, propFile: f, isWinningProposal: isWinningProposal, selectedProposal: proposal })}
											size='sm'
											kind='tertiary'
										>
											{KEYS.show_feedback}
										</Button>
										:   <TooltipIcon tooltipText={KEYS.proposal_status_pending_client_feedback_tooltip}>
											<Tag type="teal" className={classes.statusTag}>{KEYS.proposal_status_pending_client_feedback}</Tag>
										</TooltipIcon>
								) : ((fileVersion.approvedStatus === 'no' && fileVersion.comment) &&
									<Button
										className={classnames([classes.btn, classes.fileBtn])}
										onClick={() => this.setState({ openModalWithProposalFeedback: true, lbComments: fileVersion.comment, selectedProposal: proposal })}
										kind='secondary'>
										{KEYS.show_comments}
									</Button>
								)}
								<OverflowMenu direction="bottom" flipped light  style={{ marginLeft: '8px' }}>
									<OverflowMenuItem itemText={KEYS.open_file} onClick={() => this.onOpenPreviewFile(f)} />
									<OverflowMenuItem itemText={KEYS.download_file} href={downloadUrl} target="_blank" rel="noopener noreferrer" />
								</OverflowMenu>
							</div>
						</div>
					);
					rows.push(newRow);
				}
			}
		}

		return rows.map(r => r);
	}

	displayProposalFiles(project, user, hasProposalinContest, projectIsInPrototypingPhase) {
		const { isAParticipantExpert, statesExpertIsParticipating } = this.props;
		const currentContest = PROJECT.getCurrentContest(project);
		const { proposals } = currentContest !== null && currentContest;
		const isUserProposalWithFile = proposals ? PROPOSAL.isUserProposalWithFile(proposals, user.id) : false;

		return (
			<>
				{hasProposalinContest &&
					<div>
						<h5>
							{projectIsInPrototypingPhase
								? 'Active quotations'
								: 'Active proposals'
							}
						</h5>
						{(proposals.length > 0 && isUserProposalWithFile) ? (
							this.renderFiles(project, user)
						) : (
							<div className={classes.overviewMessageBox}>
								<Information16 />
								<small>{projectIsInPrototypingPhase ? KEYS.quotation_empty : KEYS.proposal_empty}</small>
							</div>
						)}
					</div>
				}
				{(isAParticipantExpert && !!project.state) &&
					<div>
						<h5>Past proposals</h5>
						{this.renderPastPhases(project, user, statesExpertIsParticipating)}
					</div>
				}
			</>
		);
	}

	displayUploadInvoiceForSuppliers(project) {
		const { uploadingSharedFile } = this.state;
		const currentContest = PROJECT.getCurrentContest(project);
		const { sharedFiles } = currentContest;
		const hasASharedFileApproved = sharedFiles && PROPOSAL.hasASharedFileApproved(sharedFiles);

		return (
			(sharedFiles.length === 0 || sharedFiles.length > 0 && !hasASharedFileApproved) &&
			<Mutation mutation={ADD_SHAREDFILE_TO_CONTEST}>
				{(uploadSharedFileMutation, { error }) => {
					if (error) return (
						<InlineNotification
							className={classes.notification}
							kind="error"
							lowContrast
							hideCloseButton
							title="Error"
							subtitle={(error.graphQLErrors && error.graphQLErrors.length)
								? error.graphQLErrors[0].message
								: (error.networkError)
									? error.networkError.message
									: error.message}
						/>
					);
					return uploadingSharedFile ?
						<FileUploaderItem
							name={uploadingSharedFile.filename}
							errorSubject={KEYS.files_error_exceeds_limit}
							status='uploading'
						/>
						:	<FileUploader
							buttonKind="secondary"
							buttonLabel={'Add your invoice'}
							onChange={(e) => this.onClickUploadSharedFiles(e, project, uploadSharedFileMutation)}
						/>;
				}}
			</Mutation>
		);
	}


	render() {
		const {
			project,
			user,
			contest,
			contestAvailable,
			hasProposal,
			onOpenContest,
			userData: { token },
		} = this.props;

		const {
			uploading,
			uploadError,
			previewFile,
			uploadSuccess,
			openModalWithProposalFeedback,
			haveWinningProposal,
			feedback,
			lbComments,
			propFile,
			isWinningProposal,
			selectedProposal,
			openProcessModal,
		} = this.state;

		const hasProposalinContest =  (hasProposal !== null && 'id' in hasProposal) ? true : false;
		const projectIsFinished = project.state === PROJECT.STATE.CLOSED;
		const projectIsInPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST;
		const winningProposal = haveWinningProposal && contest.selectedProposal;
		const hasWinnerAndTheWinnerIsThisUser = contest && CONTEST.hasWinnerAndTheWinnerIsThisUser(contest, user.id);

		return (
			<div className={classes.tabContentContainer}>
				{uploadError && (
					<ToastNotification
						className={classes.toastNotification}
						title={KEYS.upload_failed}
						subtitle={<span>{KEYS.entries_notification_upload_file_error}</span>}
						caption=""
						timeout={10000}
					/>
				)}
				{projectIsFinished
					?	<h3 className={classes.title}>Review all your entries for this project</h3>
					:	<h3 className={classes.title}>Your workspace to organize your files</h3>
				}
				<div className={classes.content}>
					{!projectIsFinished &&
					<>
						<div className={classes.contentBlock}>
							<div className={classes.contentTitle}>
								<h4>
									{projectIsInPrototypingPhase
										? KEYS.my_quotations
										: KEYS.my_proposals
									}
								</h4>
								<p>
									{projectIsInPrototypingPhase
										? KEYS.quotation_text
										: KEYS.proposals_text
									}
								</p>
							</div>
						</div>
						<div>
							{uploadSuccess && (
								<InlineNotification
									className={classes.notification}
									kind="success"
									lowContrast
									iconDescription="Success"
									title={KEYS.upload_success}
									subtitle={<span>{KEYS.entries_notification_upload_file_success}</span>}
									timeout={5000}
								/>
							)}
							{(contestAvailable && hasProposalinContest &&
							!haveWinningProposal && contest.availableApprovedProposals > 0)
								? (
									<Mutation mutation={CREATE_PROPOSAL}>
										{(createProposalMutation) => (
											<Mutation mutation={ADD_FILE_TO_PROPOSAL}>
												{(uploadFileMutation, { error }) => {
													return uploading ? (
														<div className={classes.uploadContainerPreview}>
															{/*
																// Preview files uploading --> works on images, not pdf files
																<img src={uploading.src} className={classes.previewImgFile} alt='Preview file' />
															*/}
															<FileUploaderItem
																name={uploading.filename}
																status='uploading'
															/>
														</div>
													) : (
														<div className={classes.flexColumn} style={{ flexDirection: 'column-reverse', alignItems: 'center' }}>
															{projectIsInPrototypingPhase &&
																<small
																	className={classes.tooltip}
																	onClick={() => this.setState({ openProcessModal: true })}
																>
																	Learn the process
																</small>
															}
															<div className={classes.uploadContainer}>
																<div className={classes.uploadBlock}>
																	<div
																		className={classes.fileUploaderContainer}
																		onClick={() => this.inputFileRef.current.click()}
																	>
																		{KEYS.drag_and_drop_text}
																	</div>
																	<input
																		id="proposal-file-uploader"
																		name="proposal-file-uploader"
																		ref={this.inputFileRef}
																		type="file"
																		accept=".pdf"
																		onChange={(e) => this.onClickUpload(e, uploadFileMutation, createProposalMutation)}
																		style={{ display: 'none' }}
																	/>
																</div>
																{error && (
																	<InlineNotification
																		className={classes.notification}
																		kind="error"
																		lowContrast
																		hideCloseButton
																		title="Error"
																		subtitle={(error.graphQLErrors && error.graphQLErrors.length)
																			? error.graphQLErrors[0].message
																			: (error.networkError)
																				? error.networkError.message
																				: error.message}
																	/>
																)}
															</div>
														</div>
													);
												}}
											</Mutation>
										)}
									</Mutation>
								) : ((contestAvailable && !hasProposalinContest)
									?	<InlineNotification
										kind="info"
										lowContrast
										hideCloseButton
										iconDescription="Info"
										subtitle={<span>{KEYS.notification_join_contest_to_upload_files}</span>}
										actions={<NotificationActionButton onClick={onOpenContest}>{KEYS.open_contest}</NotificationActionButton>}
										title=""
									/>
									: hasProposalinContest && contest.availableApprovedProposals === 0 && !haveWinningProposal
										?	<InlineNotification
											kind="info"
											lowContrast
											hideCloseButton
											iconDescription="Info"
											subtitle={<span>Thank you for your contribution. You have reach the validated proposals per participant.</span>}
											title=""
										/>
										:	<InlineNotification
											kind="info"
											lowContrast
											hideCloseButton
											iconDescription="Info"
											subtitle={<span>{KEYS.notification_files_contest_close}</span>}
											title=""
										/>
								)}
						</div>
					</>
					}
					<div className={classes.container}>
						{project.contests &&
							<>
								<div className={classes.contentBlock}>
									<div className={classes.contentTitle}>
										<h4>{KEYS.all_documents}</h4>
										<p>{KEYS.all_documents_text}</p>
									</div>
								</div>
								{this.displayProposalFiles(project, user, hasProposalinContest, projectIsInPrototypingPhase)}
							</>
						}
					</div>
					{hasWinnerAndTheWinnerIsThisUser && (
						<div className={classes.container}>
							{projectIsInPrototypingPhase
								?  <>
									<div className={classes.contentBlock}>
										<div className={classes.contentTitle}>
											<h4>Upload your invoice</h4>
											<p>As your quotation has been selected by the inventor, we need you to submit the invoice to start the payment process.</p>
										</div>
									</div>
									{this.displayUploadInvoiceForSuppliers(project)}
									{this.renderSharedFiles(project, user, projectIsInPrototypingPhase)}
								</>
								:	<>
									<div className={classes.contentBlock}>
										<div className={classes.contentTitle}>
											<h4>{KEYS.shared_files}</h4>
											<p>{KEYS.shared_files_text}</p>
										</div>
									</div>
									<h5 style={{ margin: '16px 0' }}>Your Proposal #{winningProposal.number} files:</h5>
									{this.renderSharedFiles(project, user, projectIsInPrototypingPhase)}
									{this.displayUploadSharedFileOption(project)}
								</>
							}
						</div>
					)}
				</div>
				<PreviewFileModal
					open={!!previewFile}
					file={previewFile}
					onRequestClose={() => this.onClosePreviewFile()}
					goBackToProposal={
						(propFile !== null && feedback !== null)
							? () => this.onClosePreviewFileAndReopenProposalFeedback()
							: () => {}
					}
				/>
				{openModalWithProposalFeedback &&
					<Modal
						passiveModal={true}
						open={openModalWithProposalFeedback}
						modalLabel={(lbComments !== null) ? 'LastBasic Review' : 'Proposal Feedback'}
						onRequestClose={() => this.setState({openModalWithProposalFeedback: false, feedback: null, lbComments: null})}
					>
						{lbComments !== null ? (
							<div className={classes.modalContent}>
								<div className={classes.imgContainer}>
									<img
										className={classes.proposalImg}
										src={serverAPI.getProposalThumbnailUrl(selectedProposal.id, token.accessToken)}
										alt='Proposal thumbnail'
										onClick={() => this.setState({ openModalWithProposalFeedback: false, previewFile: hasProposal })}
									/>
								</div>
								<div className={classes.contentElementsBox}>
									<h5>{KEYS.proposal_feedback_comment}</h5>
									<p>{lbComments}</p>
								</div>
							</div>
						) : (feedback !== null) ? (
							<ProposalFeedbackContent
								proposal={selectedProposal}
								feedback={feedback}
								isAQuotation={projectIsInPrototypingPhase && PROPOSAL.isProposalFromThisContest(selectedProposal, contest)}
								isWinningProposal={isWinningProposal}
								displayOnPublic={false}
								closeAndUploadNewFiles={() => this.setState({openModalWithProposalFeedback: false, feedback: null, lbComments: null})}
								onPreviewProposalFile={() => this.setState({ openModalWithProposalFeedback: false, previewFile: propFile })}
							/>
						) : null}
					</Modal>
				}
				{openProcessModal &&
					<ProcessModal
						open={!!openProcessModal}
						onRequestClose={() => this.setState({ openProcessModal: false })}
					/>
				}
			</div>
		);
	}
}

EntriesTab.defaultProps = {
	project: {},
	user: {},
	contest: {},
	hasProposal: {},
	statesExpertIsParticipating: {},
	userData: {},
	contestAvailable: false,
	isAParticipantExpert: false,
	onFileUploaded() {},
	onSharedFileUploaded() {},
	onOpenContest() {},
	goBackToProposal() {},
};

EntriesTab.propTypes = {
	project: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	hasProposal: PropTypes.object.isRequired,
	statesExpertIsParticipating: PropTypes.array,
	userData: PropTypes.object,
	contestAvailable: PropTypes.bool.isRequired,
	isAParticipantExpert: PropTypes.bool,
	onFileUploaded: PropTypes.func.isRequired,
	onSharedFileUploaded: PropTypes.func.isRequired,
	onOpenContest: PropTypes.func,
	goBackToProposal: PropTypes.func,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(EntriesTab);
