import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Accordion,
	AccordionItem,
	Tag
} from 'carbon-components-react';
import { Information16, TrophyFilled32 } from '@carbon/icons-react';

import KEYS from '../../../../../glossary';
import { CONTEST, PROJECT, PROPOSAL } from '../../../../../constants';
import { serverAPI } from '../../../../../utils';
import ContestIcon from '../../../../../components/project/contest-icon';

import classes from './project-overview.module.scss';

class ProjectOverview extends Component {
	getProposalThumbnailUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	renderProposal(proposal, contest, onClickProposal) {
		const { feedback } = proposal;
		const isWinner = CONTEST.isWinningProposal(contest, proposal);

		for (const proposalFile of proposal.files) {
			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			if (!approvedAndPublished) continue;

			const { file } = fileVersion;

			return (
				<div className={classes.proposalContainer} key={file.id}
					onClick={() => onClickProposal(file, feedback)}>
					<img
						className={classes.thumbnailImg}
						src={this.getProposalThumbnailUrl(proposal)}
						alt={KEYS.proposal_overview_alt}
					/>
					{isWinner && (
						<div className={classes.winnerContainer}>
							<TrophyFilled32 style={{ fill: '#ffc637' }} />
						</div>
					)}
				</div>
			);
		}
	}

	renderItem(state) {
		const { project, onClickProposal } = this.props;
		const contest = PROJECT.getContestByState(project, state);
		const isContestOpen = contest && CONTEST.isContestOpen(contest, project);
		const contestHasEndedAndHasAWinningProposal = contest && (CONTEST.isContestEnded(contest) && CONTEST.hasWinner(contest));

		return (
			<div className={classes.overviewCategoryBox}>
				{(isContestOpen || (contest && contest.proposals.length === 0)) && (
					<div className={classes.overviewMessageBox}>
						{project.state === state
							?	<>
								<Information16 />
								<small>{KEYS.proposal_open}</small>
							</>
							:	!contestHasEndedAndHasAWinningProposal
								?	<>
									<Information16 />
									<small>Contest not open yet.</small>
								</>
								:
								<>
									<Information16 />
									<small>You and other experts creating proposals.</small>
								</>
						}
					</div>
				)}
				{contestHasEndedAndHasAWinningProposal &&
					contest.proposals.map(proposal => (
						this.renderProposal(proposal, contest, onClickProposal)
					))}
			</div>
		);
	}

	renderProjectOverviewTitle(project, onClickOpenPhaseProposals) {
		const contest = PROJECT.getContestByState(project, onClickOpenPhaseProposals);
		const hasAWinningProposal = contest && PROPOSAL.hasAWinningProposal(contest);
		const isContestOpen = contest && CONTEST.isContestOpen(contest, project);

		return (
			<div className={classes.accorditionTitle}>
				<div className={classes.accordionBlock}>
					<ContestIcon state={PROJECT.getStateByContestType(contest.type)} size={20} active={false} />
					{contest
						?	<h5>Proposals of {contest.type}</h5>
						:	<h5>Select a phase to display their proposals</h5>
					}
				</div>
				{contest && contest.proposals.length === 0
					?	<Tag type="gray" className={classes.statusTag}>Not started</Tag>
					:	hasAWinningProposal
						?	<Tag type="red" className={classes.statusTag}>Contest ended</Tag>
						:	isContestOpen
							?	<Tag type="green" className={classes.statusTag}>Open contest</Tag>
							:	<Tag type="blue" className={classes.statusTag}>Closed contest</Tag>
				}
			</div>
		);
	}

	render() {
		const { project, onClickOpenPhaseProposals } = this.props;

		return (
			<Accordion align='start' className={classes.accordion}>
				<AccordionItem
					key={onClickOpenPhaseProposals}
					title={this.renderProjectOverviewTitle(project, onClickOpenPhaseProposals)}
					open={onClickOpenPhaseProposals.length > 0 ? true : false}
				>
					{this.renderItem(onClickOpenPhaseProposals)}
				</AccordionItem>
			</Accordion>
		);
	}
}

ProjectOverview.propTypes = {
	project: PropTypes.object.isRequired,
	onClickProposal: PropTypes.func,
	userData: PropTypes.object.isRequired,
	onClickOpenPhaseProposals: PropTypes.string,
	onPreviewProposalFile: PropTypes.func
};

ProjectOverview.defaultProps = {
	userData: {},
	project: {},
	onClickProposal() {},
	onClickOpenPhaseProposals: '',
	onPreviewProposalFile() {}
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectOverview);
