import Config from '../../config';
import { auth } from '../../utils';

function _getUserInfo () {
	const tokenData = auth.getValidStoredTokens();
	const userInfo = tokenData.user;

	return userInfo;
}

async function addContactsTag () {
	const { host, wid, apiKey } = Config.froged.api;
	const tagName = 'tag';

	try {
		const response = await fetch(`${host}/ws/${wid}/contacts-tags`, {
			method: 'POST',
			headers: {
				apikey: apiKey,
			},
			params: {
				color: '#2ECC71',
				name: tagName
			}
		});

		if (!response || !response.data) return false;
		
		return true;
	} catch (err) {
		return false;
	}
}

async function getContactId (userId) {
	const contactList = await getContactsList();
	if (!contactList) return null;
	const contactIdFiltered = contactList.filter(c => c.userId === userId);

	return contactIdFiltered[0].id;
}

async function getContactInfo () {
	const { apiKey, host, wid } = Config.froged.api;
	const userInfo = _getUserInfo();
	const userId = userInfo.id;

	try {
		const response = await fetch(`${host}/ws/${wid}/contacts/userid/${userId}`, {
			headers: {
				apikey: apiKey,
			}
		});
		const result = await response.json();

		if (!result || !result.data) return false;

		return result.data.contact;
	} catch (err) {
		return null;
	}
}

async function getContactsList () {
	const { host, wid, apiKey } = Config.froged.api;

	try {
		const response = await fetch(`${host}/ws/${wid}/contacts`, {
			method: 'PATCH',
			headers: {
				apikey: apiKey
			}
		});

		const result = await response.json();
		if (!result || !result.data) return null;

		return result.data.contacts;
	} catch (err) {
		return null;
	}
}

export default {
	addContactsTag,
	getContactId,
	getContactInfo,
	getContactsList,
};
