import { gql } from '@apollo/client';
import { userFragment } from '../fragments/user';
import { fileFragment } from '../fragments/file';

const proposalFragment = gql`
	${fileFragment}
	${userFragment}
	fragment FeedbackInfoDetails on FeedbackInfo {
		pros,
		cons,
		keepFeatures,
		removeFeatures
	}
	fragment FeedbackDetails on Feedback {
		rating,
		info {
			...FeedbackInfoDetails
		}
	}
	fragment ProposalFileVersionDetails on ProposalFileVersion {
		file {
			...FileDetails
		},
		comment,
		approvedStatus,
		published
	}
	fragment ProposalFileDetails on ProposalFile {
		versions {
			...ProposalFileVersionDetails
		}
	}
	fragment ProposalDetails on Proposal {
		id,
		number,
		user {
			...UserDetails
		},
		files {
			...ProposalFileDetails
		},
		feedback {
			...FeedbackDetails
		}
	}
`;

export {
	proposalFragment,
};