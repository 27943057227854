import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Cad24, Csv24, DocumentBlank24, Jpg24, Pdf24, Png24, Svg24, Txt24, Xls24, Xml24, Zip24
} from '@carbon/icons-react';

class FileIcon extends Component {
	renderIcon(extension) {
		switch (extension.toLowerCase().slice(1)) {
			case 'cad':
				return <Cad24 />;
			case 'csv':
				return <Csv24 />;
			case 'jpeg':
			case 'jpg':
				return <Jpg24 />;
			case 'pdf':
				return <Pdf24 />;
			case 'png':
				return <Png24 />;
			case 'svg':
				return <Svg24 />;
			case 'txt':
				return <Txt24 />;
			case 'xls':
				return <Xls24 />;
			case 'xml':
				return <Xml24 />;
			case 'zip':
				return <Zip24 />;
			default:
				return <DocumentBlank24 />;
		}
	}
	
	render() {
		const { extension } = this.props;
		
		return (
			<div>
				{this.renderIcon(extension)}
			</div>
		);
	}
}

FileIcon.defaultProps = {
	extension: null,
};

FileIcon.propTypes = {
	extension: PropTypes.string.isRequired,
};

export default FileIcon;
