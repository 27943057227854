import { Mutation } from '@apollo/client/react/components';
import {
	Security20,
	SettingsAdjust16,
	CheckmarkFilled16,
	RadioButton16
} from '@carbon/icons-react';
import {
	Button,
	FileUploaderItem,
	InlineLoading,
	InlineNotification,
	Modal,
	OverflowMenu,
	OverflowMenuItem,
	SkeletonPlaceholder,
	SkeletonText,
	Tag,
	TagSkeleton,
	TooltipDefinition,
	TooltipIcon,
} from 'carbon-components-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import IosShare from '@mui/icons-material/IosShare';
import Settings from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import LockIcon from '@mui/icons-material/Lock';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import Alert from '@mui/material/Alert';
import ActionBtn from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import ClientProjectPage from '../../clients/project';
import ContestIcon from '../../../components/project/contest-icon';
import RawMaterialImage from '../../../components/project/raw-material-image';
import withRouter from '../../../components/route/with-router';
import Timeline from '../../../components/timeline/timeline';
import PhaseViewSetupQuestions from '../../../components/project/setup-questions/phase-view-setup-questions';
import PhaseSetupQuestions from '../../../components/project/setup-questions/phase-setup-questions';
import ProposalFeedback from '../../../components/project/proposal-feedback-content';
import PreviewFileModal from '../../../components/modals/preview-file-modal';
import UpdateProjectImageModal from '../../../components/modals/update-project-image-modal';
import UpdateProjectNameModal from '../../../components/modals/update-project-name-modal';
import SalesCallModal from '../../../components/modals/sales-call-modal';
import ScrollIndicator from '../../../components/elements/scroll-indicator';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import config from '../../../config';
import { PROJECT, CONTEST, BRIEFING } from '../../../constants';
import KEYS from '../../../glossary';
import { GET_PROJECT, GET_PROJECT_EXTRA_INFO } from '../../../providers/queries';
import { ADD_RAW_MATERIAL, CONFIRM_PROJECT_RAW_MATERIALS, REMOVE_RAW_MATERIAL } from '../../../providers/mutations';
import { serverAPI, strings, userInfo, FormUtils } from '../../../utils';
import sendInsight from '../../../utils/insights';

import WinningProposals from './blocks/winning-proposals';
import ProjectUpdates from './blocks/updates';
import ProjectFeasibility from './blocks/pending-feasibility';
import ProjectFiles from './blocks/project-files';
import ValidationChip from './blocks/validation-chip';

import ALEX_AVATAR from './assets/alex-avatar.png';
import ALEX_SIGNATURE from './assets/alex-signature.png';

const PROJECT_MATERIAL_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';
const PROJECT_IMAGE_PLACEHOLDER = '/assets/images/logo-lb-project.png';

import classes from './project.module.scss';

class ProjectView extends Component {
	constructor (props) {
		super(props);
		this.inputFileRef = React.createRef();
		this.refetchProject = () => {};

		this.state = {
			openContestModal: false,
			openProposalFeedbackModal: false,
			proposalFeedback: null,
			proposalFile: null,
			previewFile: null,
			selectedProposal: null,
			userInfo: userInfo.getUserInfo(),
			showProjectImages: false,
			openUpdateProjectImageModal: false,
			uploadingRawMaterialFile: null,
			errorRemovingRawMaterial: null,
			removingRawMaterial: null,
			newRawMaterialFiles: [],
			openWidget: false,
			openShareModal: false,
			hoverShareProject: false,
			showMoreDetails: false,
			openSetupQuestionsModal: false,
			openWelcomeModal: null,
			viewMoreFiles: false,
			showRawMaterials: false,
			projectData: null,
			openUpdateProjectNameModal: false,
			confirmingRawMaterials: false,
		};
	}

	componentDidMount() {
		document.title = 'Project - LastBasic';
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	render() {
		const { match } = this.props;
		const {
			openProposalFeedbackModal,
			proposalFeedback,
			previewFile,
			proposalFile,
			selectedProposal,
			// userInfo,
			// showProjectImages,
			openUpdateProjectImageModal,
			openUpdateProjectNameModal,
			openWidget,
			openShareModal,
			openSetupQuestionsModal,
			openWelcomeModal,
			projectData,
		} = this.state;

		let { id } = match.params;

		const demoProjectId = config.lastbasic.demoProjectId;
		const isDemo = demoProjectId && ((id === 'demo') || (id === demoProjectId));
		id = isDemo ? demoProjectId : id;

		const showExampleProjectBanner = projectData?.paid === false;
		const showDemoProjectBanner = isDemo || (projectData?.paid === false);

		return (
			<div className={classes.projectViewPage} style={{ marginTop: (showDemoProjectBanner ? '30px' : '0px') }}>
				{showExampleProjectBanner && (
					<div className={classes.demoProjectBanner}>How does a paid project look like? <Link to={'/project/demo'} style={{ color: 'inherit', textDecoration: 'underline' }}>See a demo project</Link></div>
				)}

				{showDemoProjectBanner && (
					<div className={classes.demoProjectBanner} style={(isDemo ? { backgroundColor: 'rgb(246, 246, 246)', color: '#787874' } : {})}>This is a demo project</div>
				)}

				<ErrorBoundaryQuery query={GET_PROJECT} variables={{ id: id }}>
					{({ loading, error, data, refetch: refetchProject }) => {
						this.refetchProject = refetchProject;

						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<div className={classes.betweenBlock} style={{ alignItems: 'end'}}>
										<div>
											<SkeletonText style={{width: '300px'}} />
											<SkeletonText style={{width: '200px'}} />
										</div>
										<div style={{ display: 'flex' }}>
											<SkeletonText style={{width: '100px', marginRight: '8px'}}/>
											<SkeletonText style={{width: '100px'}}/>
										</div>
									</div>
									<div className={classes.contentBlock}>
										<SkeletonPlaceholder className={classes.imgSkeleton} />
									</div>
									<div className={classnames(classes.containerSkeleton, classes.betweenBlock)}>
										<div style={{width: '500px'}}>
											<SkeletonText style={{width: '100%'}}/>
											<SkeletonText style={{width: '100%'}}/>
										</div>
										<div className={classes.tags}>
											<TagSkeleton style={{ height: '20px'}} />
											<TagSkeleton style={{ height: '20px'}} />
											<TagSkeleton style={{ height: '20px'}} />
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<div className={classes.betweenBlock}>
											<div style={{width: '500px'}}>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
											</div>
											<div style={{width: '500px'}}>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
												<SkeletonText style={{width: '100%'}}/>
											</div>
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<div style={{ display: 'flex'}}>
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<SkeletonText/>
										<SkeletonText/>
										<SkeletonText/>
										<SkeletonText/>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const project = data && data.project;
						if (!project) {
							return (<div>No project</div>);
						}

						if (!projectData) {
							this.setState({ projectData: project });
						}

						const projectCreationDate = new Date(parseInt(project.createdAt));
						if (projectCreationDate < new Date('1990-01-01')) {
							return <ClientProjectPage/>;
						}

						const welcomeThreshold = new Date(parseInt(project.createdAt) + (30 * 60 * 60 * 1000));
						const welcomeLocalKey = 'welcome_' + project.id;
						if (welcomeThreshold > new Date() && openWelcomeModal === null && !window.localStorage.getItem(welcomeLocalKey)) {
							this.setState({ openWelcomeModal: true });
							window.localStorage.setItem(welcomeLocalKey, 1);
							sendInsight('view', 'alex_welcome_message_project_page', null, project.id, `:owner:${project.owner.id}:`);
						}

						const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
						const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
						const projectIsPendingPayment = project.feasibility === PROJECT.FEASIBILITY.YES && !project.paid;
						const projectIsInActivePhase = project.state !== PROJECT.STATE.CLOSED && project.state !== PROJECT.STATE.CLIENT_ONBOARDING;
						const projectIsInLobby = (projectIsPendingFeasibility || projectIsNonFeasible || (projectIsPendingPayment && !projectIsInActivePhase));
						const projectIsFeasibleAndPaid = project.feasibility === PROJECT.FEASIBILITY.YES && project.paid;
						const projectIsMakingOnboarding = projectIsFeasibleAndPaid && project.state === PROJECT.STATE.CLIENT_ONBOARDING;

						document.title = `${project.name} - LastBasic`;

						// Next steps
						const briefingCompleted = project && project.briefingCompletionPercentaje && project.briefingCompletionPercentaje > 50;
						const projectPaid = project && project.paid;
						const timelineSelected = true; /* To-do */

						// Send view insight
						sendInsight('view', 'project_page', null, project.id, `:owner:${project.owner.id}:`);

						return (
							<div>
								<ScrollIndicator />
								{/* Project main header */}
								<div className={classes.betweenBlock} style={{ alignItems: 'end'}}>
									{this.displayProjectMainHeader(project)}
								</div>
								{/* Project images block */}
								<div className={classes.contentBlock}>
									{this.displayProjectImages(project, projectIsInLobby)}
								</div>
								{/* Package info block */}
								<div className={classes.betweenBlock} style={{ alignItems: 'center', gap: '15px', marginBottom: '-15px' }}>
									{/* Views, Claps and data */}
									<div className={classes.projectTopStatuses}>
										{/* Project state */}
										<div className={classes.projectTopStatus}>
											<div className={classes.projectTopStatusTitle}>Status</div>
											<div
												className={classes.projectTopStatusBubble}
												style={{ backgroundColor: CONTEST.getPhaseColorWithAllPhases(project) }}
											>
												{PROJECT.stateToStatus(project.state)}
											</div>
										</div>

										{/* Progress */}
										<div className={classes.projectTopStatus}>
											<div className={classes.projectTopStatusTitle}>Progress</div>
											<div className={classes.projectTopStatusProgressValue}>
												<span style={{ color: '#3E3E3E', fontSize: '22px', marginTop: '5px', position: 'relative' }}>
													<div className={classes.projectTopStatusProgressIconMask}></div>
													<DownloadingOutlinedIcon fontSize='inherit' color='inherit' />
												</span>
												{this.calculateProgress(project)}%
											</div>
										</div>

										{/* Claps */}
										{this.displayClaps(project)}
									</div>
									{this.displayFeaturesTags(project)}
								</div>
								<div className={classes.horizontalDivider}></div>
								{/* Dashboard block */}
								{/* {isExpert &&
									<>
										<div>
											<h4>Dashboard</h4>
											{this.renderClientsActivity()}
										</div>
										<div className={classes.horizontalDivider}></div>
									</>
								} */}

								{/* Pending feasibility block */}
								{/* {(projectIsInLobby) && (
									<>
										{this.displayPendingFeasibilityBlock(project)}
										<div className={classes.horizontalDivider}></div>
									</>
								} */}

								{/* Project lobby set up notification */}
								{projectIsInLobby &&
									<div>
										{this.displayProjectSetUpBlock(project)}
										<div className={classes.horizontalDivider}></div>
									</div>
								}

								{/* Next steps */}
								{projectIsInLobby && !projectIsNonFeasible && (
									<div>
										<h4 className={classes.secondaryTitle}>Next steps</h4>
										<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>
											Complete the setup so we can begin prototyping your idea
										</div>
										<div className={classes.nextSteps}>
											<div className={classes.step} style={{ backgroundColor: (timelineSelected ? '#D9D9D930' : 'transparent') }}>
												{ timelineSelected && <div className={classes.stepDone}><CheckIcon /></div> }
												<div className={classes.stepNumber}>1</div>
												<div className={(timelineSelected ? classes.lockedStep : '')}>
													<div className={classes.stepDescription}>Plan your prototyping journey with the timeline templates adapted to your project needs</div>
													<div
														className={classes.stepAction + ' ' + (timelineSelected ? classes.lockedStep : '')}
														onClick={() => {
															if (!timelineSelected) window.location.href = `/project/${project.id}/timeline`;
														}}
														style={{ display: 'none '}}
													>
														Set up timeline
													</div>
													<span style={{ fontSize: '14px', opacity: 0.4}}>
														<br/>Automatically planned based<br/>on your briefing
													</span>
												</div>
											</div>

											<div className={classes.step} style={{ backgroundColor: (briefingCompleted ? '#D9D9D930' : 'transparent') }}>
												{briefingCompleted && (
													<div className={classes.stepDone}><CheckIcon /></div>
												)}
												<div className={classes.stepNumber}>2</div>
												<div>
													<div
														className={classes.stepDescription}
														style={briefingCompleted ? {opacity: 0.3} : {}}
													>
														Add more information to your project so the experts can work better around your idea
													</div>
													<div
														className={classes.stepAction}
														onClick={async () => {
															await sendInsight('click', 'project_page_settings_step', null, project.id, `:owner:${project.owner.id}:`);
															window.location.href = `/project-builder/${project.id}`;
														}}
													>
														Complete project details
													</div>
												</div>
											</div>

											<div className={classes.step} style={{ backgroundColor: (projectPaid ? '#D9D9D930' : 'transparent') }}>
												{projectPaid && (
													<div className={classes.stepDone}><CheckIcon /></div>
												)}
												<div className={classes.stepNumber}>3</div>
												<div className={(projectPaid ? classes.lockedStep : '')}>
													<div className={classes.stepDescription}>
														Start getting proposals for your idea by unlocking now your project journey
													</div>
													<div
														className={classes.stepAction + ' ' + (timelineSelected ? classes.lockedStep : '')}
													>
														<Link to={`/project/${project.id}/unlock`} style={{ textDecoration: 'none', color: 'inherit' }}>
															Unlock project
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								{projectIsMakingOnboarding && (
									<>
										{this.displayProjectOnboardingList(project)}
										<div className={classes.horizontalDivider}></div>
									</>
								)}

								{!projectIsNonFeasible && (
									<div>
										{/* Project details block */}
										{this.displayProjectDetails(project)}

										<div className={classes.horizontalDivider}></div>

										<div>
											<h4 className={classes.secondaryTitle}>Contests</h4>
											<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>These are the contests designers and engineers will participate in</div>
											{this.displayNewContests(project, projectIsInLobby)}
										</div>

										{/* <div>
											<h4 className={classes.secondaryTitle}>Winning proposals</h4>
											<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>All the experts&apos; proposals you have liked the most</div>
											{this.displayWinningProposals(project, projectIsInLobby)}
										</div>
										*/}
										<div>
											<h4 className={classes.secondaryTitle}>Prototype files</h4>
											<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>All the files generated for you to download</div>
											{this.displayProjectFiles(project, projectIsInLobby)}
										</div>

										{/* Project timeline block */}
										<div>
											<div style={{display: 'flex', alignItems: 'baseline'}}>
												<h4 className={classes.secondaryTitle}>Timeline</h4>
												<Link
													to={`/project/${project.id}/timeline`}
													style={{ display:'none', textDecoration: 'none', color: '#313131' }}
												>
													<span
														className={classes.secondaryLink}
														style={{marginLeft: '8px'}}
													>
														Edit
													</span>
												</Link>
											</div>
											<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>Visualize the journey of your invention</div>
											<Timeline
												project={project}
												locked={false}
											/>
											<div className={classes.horizontalDivider}></div>
										</div>

										<div>
											<h4 className={classes.secondaryTitle}>Updates</h4>
											<div className={classes.secondaryTitleDescription} style={{ marginBottom: '20px' }}>Your project milestones</div>
											{this.displayProjectUpdates(project)}
											<div className={classes.horizontalDivider}></div>
										</div>
									</div>
								)}

								<div>
									{this.displayInventorsInfo(project)}
								</div>

								{/* Modals */}
								{openProposalFeedbackModal && (
									<Modal
										passiveModal={true}
										open={openProposalFeedbackModal}
										modalLabel={KEYS.proposal_feedback_modal_label}
										onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
									>
										{proposalFeedback ?
											<ProposalFeedback
												proposal={selectedProposal}
												feedback={proposalFeedback}
												displayOnPublic={true}
												onPreviewProposalFile={() => this.setState({ openProposalFeedbackModal: false, previewFile: proposalFile })}
											/>
											: <p>{KEYS.proposal_feedback_modal_no_data}</p>
										}
									</Modal>
								)}
								<PreviewFileModal
									open={!!previewFile}
									file={previewFile}
									onRequestClose={() => this.onClosePreviewFile()}
									goBackToProposal={false}
									goBackToContest={false}
								/>
								<UpdateProjectNameModal
									open={!!openUpdateProjectNameModal}
									project={project}
									onRequestSubmit={() => this.setState({ openUpdateProjectNameModal: false }, () => { this.refetchProject(); })}
									onRequestClose={() => this.setState({ openUpdateProjectNameModal: false })}
									onSecondarySubmit={() => this.setState({ openUpdateProjectNameModal: false })}
								/>
								<UpdateProjectImageModal
									open={!!openUpdateProjectImageModal}
									project={project}
									onRequestSubmit={() => this.setState({ openUpdateProjectImageModal: false }, () => { this.refetchProject(); })}
									onRequestClose={() => this.setState({ openUpdateProjectImageModal: false })}
									onSecondarySubmit={() => this.setState({ openUpdateProjectImageModal: false })}
								/>
								<SalesCallModal
									open={!!openWidget}
									onRequestClose={() => this.setState({ openWidget: false })}
								/>
								<Modal
									passiveModal={true}
									open={!!openShareModal}
									modalLabel={'Share project'}
									onRequestClose={() => this.setState({ openShareModal: false })}
								>
									<h5 className={classes.titleSpace}>Share {project.name} project</h5>
									<div>
										<p>You need to turn on permissions in order to make your project visible for anybody.</p>
									</div>
								</Modal>
								<Modal
									passiveModal={true}
									open={!!openSetupQuestionsModal}
									modalLabel={'Set up questions'}
									onRequestClose={() => this.setState({ openSetupQuestionsModal: false })}
								>
									<div className={classes.contentTitle}>
										<h4>More details</h4>
										<p>Tell us more about your project for further understanding.</p>
									</div>
									<div>
										<h5>Onboarding questions</h5>
										<PhaseSetupQuestions
											project={project}
											phase={PROJECT.STATE.CLIENT_ONBOARDING}
											onQuestionsAnswered={() => this.setState({ openSetupQuestionsModal: false }, () => { this.refetchProject(); })}
										/>
									</div>
								</Modal>
								<Modal
									passiveModal={true}
									open={!!openWelcomeModal}
									onRequestClose={() => this.setState({ openWelcomeModal: false })}
									size={'sm'}
								>
									<div style={{ fontSize: '16px', padding: '20px' }}>
										<div>
											Hi! I’m Alejandro, nice to meet you!
											<br/><br/>
											2 years ago we launched LastBasic for inventors like you: people with ideas to create beautiful prototypes.
											<br/><br/>
											During this time, we have created the best tools for inventors in a unique process where experts from all over the world participate in.
											<br/><br/>
											It has been great to see hundreds of prototypes being created here. And we hope to see yours too!
											<br/><br/>
											I would love to talk to you about your idea and see how we can make it happen. I will contact you soon. I am also available to you if you prefer to contact me first.
											<br/><br/>
											Meanwhile you can explore your new project page, and even start your prototyping journey right away by <Link style={{ color: 'inherit' }} to={'./unlock'}>unlocking your project</Link>.
											<br/><br/>
											Have a great day!
											<br/>
											Alex
										</div>
										<div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
											<div className={classes.alexAvatar} style={{ backgroundImage: `url(${ALEX_AVATAR})`}}></div>
											<div className={classes.alexSignature} style={{ backgroundImage: `url(${ALEX_SIGNATURE})`}}></div>
										</div>
										<div style={{ marginTop: '15px' }}>
											Alejandro Turell, CEO at LastBasic
										</div>
										<div style={{ opacity: 0.5 }}>
											alex@lastbasic.com
											<br/>
											+34 627 833 564
										</div>
									</div>
								</Modal>
							</div>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}

	displayProjectMainHeader(project) {
		const { userData: {user}} = this.props;
		const { maker } = user;
		const { hoverShareProject } = this.state;

		const isInventor = maker !== null;
		const projectSolution = project.productSolution;

		const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const isMobileBig = (width < 768);

		return (
			<>
				<div className={classes.titleBox}>
					<h3 className={classes.titleSpace}>{strings.capitalizeFirstLetter(project.name)}</h3>
					<div style={{display: 'flex', alignItems: 'baseline'}}>
						{(projectSolution.length > 52)
							? <TooltipDefinition tooltipText={strings.capitalizeFirstLetter(projectSolution)} direction="bottom">
								<span>{strings.capitalizeFirstLetter(projectSolution.substring(0, 48)) + '...'}</span>
							</TooltipDefinition>
							: <span>{strings.capitalizeFirstLetter(projectSolution)}</span>
						}
						{/* <span>Your project pitch in 140 characters...</span>
						<Link
							to={`/project-builder/${project.id}`}
							style={{textDecoration: 'none', color: '#313131'}}
						>
							<span
								className={classes.secondaryLink}
								style={{marginLeft: '8px'}}
							>
								Edit
							</span>
						</Link> */}
					</div>
				</div>
				<div className={classnames(classes.spaceBetween, classes.projectActionBtns)} style={{position: 'relative'}}>
					<span
						className={classes.link}
						// onClick={() => this.setState({ openShareModal: true })}
						// onClick={() => window.Froged('message', 'Hi, I am interested in sharing my project to the world.')}
						onClick={(e) => this.setState({ hoverShareProject: e.currentTarget })}
					>
						<IosShare
							sx={{ fontSize: 16 }}
							style={{marginRight: '8px', color: '#313131'}}
						/>
							Share
					</span>
					{isInventor && !project.paid && project.feasibility !== PROJECT.FEASIBILITY.NO &&
							<Link
								to={`/project-builder/${project.id}`}
								style={{textDecoration: 'none', color: '#313131'}}
								onClick={() => {
									sendInsight('click', 'project_page_settings_top', null, project.id, `:owner:${project.owner.id}:`);
								}}
							>
								<span className={classes.link}>
									<Settings
										sx={{ fontSize: 16 }}
										style={{marginRight: '8px'}}
									/>
									Settings
								</span>
							</Link>
					}
					<OverflowMenu
						style={{ display: 'none' }}
						className={classes.overflowMenu}
						renderIcon={SettingsAdjust16}
						direction="bottom"
						flipped
						light
					>
						<OverflowMenuItem
							itemText={'Change old version'}
							onClick={() => window.location.replace(`/project/${project.id}/old_version=true`)}
						/>
					</OverflowMenu>
				</div>

				{isMobileBig &&
					<OverflowMenu
						className={classes.overflowMenu}
						renderIcon={SettingsAdjust16}
						direction="bottom"
						flipped
						light
					>
						<OverflowMenuItem
							itemText={'Share'}
							onClick={(e) => this.setState({ hoverShareProject: e.currentTarget })}
							disabled
						/>
						{isInventor && !project.paid &&
							<OverflowMenuItem
								itemText={'Settings'}
								onClick={() => window.location.replace(`/project-builder/${project.id}`)}
							/>
						}
						<OverflowMenuItem
							itemText={'Change old version'}
							onClick={() => window.location.replace(`/project/${project.id}/old_version=true`)}
							disabled
						/>
					</OverflowMenu>
				}

				<Popover
					open={hoverShareProject}
					anchorEl={hoverShareProject}
					onClose={() => this.setState({ hoverShareProject: null })}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div className={classes.shareMessageBox}>
						<h5 className={classes.alternativeTitle}>Share outside LastBasic</h5>
						<div style={{margin: '15px 0'}}>
							<span style={{color: '#3e3e3e', lineHeight: '1.2'}}>
								Here you will be able to get a project sharing link so your investors, friends or followers can see your progress.
							</span>
						</div>
						{ project.feasibility !== PROJECT.FEASIBILITY.NO &&
							<div className={classes.bottomText}>
								<LockIcon sx={{ width: '12px', height: '12px' }} style={{marginRight: '4px', color: '#C7C7C7'}} />
								<span style={{fontSize: '13px'}}>
									{project.paid ? (
										<>Contact us to enable this feature</>
									) : (
										<><Link style={{ color: 'inherit' }} to={'./unlock'}>Unlock project</Link> to enable</>
									)}
								</span>
							</div>
						}
					</div>
				</Popover>
			</>
		);
	}

	displayProjectImage(project, onlyImg) {
		return (
			<img
				className={onlyImg ? classes.projectMainImg : classes.projectMainImgConfig}
				src={this.displayProjectImageUrl(project)}
				alt={KEYS.project_cover_alt}
			/>
		);
	}

	displayProjectImageUrl(project) {
		const { image } = project;
		if (!image) return PROJECT_IMAGE_PLACEHOLDER;
		return serverAPI.getDisplayUrlByFile(image);
	}

	displayProjectImages(project, projectIsInLobby) {
		const {
			showRawMaterials,
			uploadingRawMaterialFile,
			errorRemovingRawMaterial,
			removingRawMaterial,
			newRawMaterialFiles,
			confirmingRawMaterials
		} = this.state;

		let { rawMaterials } = project;
		rawMaterials = ([...rawMaterials, ...newRawMaterialFiles])
			.filter(r => r != null)
			.map(r => ({
				id: r.id,
				name: r.name,
				fileType: r.mimeType,
				url: serverAPI.getThumbnailUrlByFile(r),
			}));

		const projectIsFeasibleAndPaid = project.feasibility === PROJECT.FEASIBILITY.YES && project.paid;
		const projectIsMakingOnboarding = projectIsFeasibleAndPaid && project.state === PROJECT.STATE.CLIENT_ONBOARDING;
		const areRawMaterialsConfirmed = PROJECT.hasStarted(project);
		const shouldShowConfirmRawMaterialsButton = rawMaterials.length > 0 && !areRawMaterialsConfirmed;

		return (
			<>
				<div style={{position: 'relative'}}>
					{this.displayProjectImage(project, false)}
					{projectIsInLobby &&
						<div className={classes.newIn}>New in</div>
					}
					<div
						className={classes.photosAction}
						style={{bottom: '56px'}}
						onClick={() => this.setState({showRawMaterials: !showRawMaterials})}
					>
						{showRawMaterials
							?	<CloseFullscreenRoundedIcon fontSize="small" style={{ color: '#3e3e3e', marginRight: '4px'}} />
							:	<AddToPhotosRoundedIcon fontSize="small" style={{ color: '#3e3e3e', marginRight: '4px'}} />
						}
						<span>Raw materials</span>
					</div>
					<div
						className={classes.photosAction}
						onClick={() => this.setState({openUpdateProjectImageModal: true})}
					>
						<AutorenewRoundedIcon fontSize="small" style={{ color: '#3e3e3e', marginRight: '4px'}} />
						<span>Change image</span>
					</div>
				</div>
				{showRawMaterials && (
					<>
						<div className={classes.contentBlock}>
							<div className={classes.spaceBetween}>
								<h5 className={classes.titleSpace}>Raw materials</h5>
								<span
									className={classes.secondaryLink}
									onClick={() => this.setState({ showRawMaterials: false })}
								>
									Hide materials
								</span>
							</div>
							<div>
								<div className={classes.spaceBetween}>
									{areRawMaterialsConfirmed ? (
										<Alert
											severity="success"
											style={{ margin: '16px 0' }}
										>
											Thank your for uploading this content. Now it&apos;s our turn to review it. Stay tuned to your inbox for next steps.
										</Alert>
									) : (
										<>
											<Mutation mutation={ADD_RAW_MATERIAL}>
												{(uploadRawMaterialFileMutation) => {
													return uploadingRawMaterialFile ? (
														<FileUploaderItem
															name={uploadingRawMaterialFile.filename}
															errorSubject={KEYS.files_error_exceeds_limit}
															status='uploading'
														/>
													) : (
														<div
															className={classes.materialCard}
															style={{ border: 'none', cursor: 'auto', margin: '8px 0' }}
														>
															<div
																className={classes.uploaderBox}
																onClick={() => this.inputFileRef.current.click()}
																style={{ textAlign: 'center' }}
															>
																Upload new file
															</div>
															<input
																id="proposal-file-uploader"
																name="proposal-file-uploader"
																ref={this.inputFileRef}
																type="file"
																accept="image/*, .pdf"
																onChange={(e) => this.onClickUploadRawMaterialFile(e, project, uploadRawMaterialFileMutation)}
																style={{ display: 'none' }}
															/>
														</div>
													);
												}}
											</Mutation>
											{shouldShowConfirmRawMaterialsButton && (
												<Mutation mutation={CONFIRM_PROJECT_RAW_MATERIALS}>
													{(confirmProjectRawMaterials) => {
														return (
															<Button
																disabled={confirmingRawMaterials}
																onClick={() => this.onClickConfirmRawMaterials(
																	project,	
																	confirmProjectRawMaterials
																)}
																size='md'
															>
																Confirm raw materials
															</Button>
														);
													}}
												</Mutation>
											)}
										</>
									)}
								</div>
								<div>
									{rawMaterials.length === 0 ? (
										<small>This project has no extra materials.</small>
									) : (
										<div className={classes.materialList}>
											<Mutation mutation={REMOVE_RAW_MATERIAL}>
												{(removeRawMaterialMutation) => {
													return (
														rawMaterials.map((material, i) => (
															<div className={classes.rawMaterialContainer} key={i}>
																<div className={classes.materialCard}>
																	<RawMaterialImage
																		src={material.url}
																		className={classes.cardImg}
																		alt={material.name}
																		onClick={material.fileType === 'application/zip'
																			?	() => this.onOpenPreviewFile(material)
																			:	undefined
																		}
																	/>
																	<div className={classes.cardName}>
																		<h6 className={classes.name}>{material.name ? material.name.substring(0, 20) : 'No name'}</h6>
																		{(removingRawMaterial !== null && (removingRawMaterial.remove && removingRawMaterial.fileId === material.id)) ? (
																			<InlineLoading status="active" style={{ width: '20px'}} />
																		) : (
																			<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
																				<OverflowMenuItem
																					href={this.downloadFile(material)}
																					itemText="Download"
																					rel="noopener noreferrer"
																					target="_blank"
																				/>
																				<OverflowMenuItem
																					disabled={!projectIsMakingOnboarding}
																					hasDivider
																					isDelete
																					itemText="Delete"
																					onClick={(e) => this.openDeleteFile(e, material, project, removeRawMaterialMutation)}
																				/>
																			</OverflowMenu>
																		)}
																	</div>
																</div>
																{(errorRemovingRawMaterial !== null && errorRemovingRawMaterial.error && (errorRemovingRawMaterial.fileId === material.id)) &&
																	<small style={{ margin: '0 16px' }}>{errorRemovingRawMaterial.error}</small>
																}
															</div>
														))
													);
												}}
											</Mutation>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={classes.horizontalDivider} style={{marginBottom: '20px'}}></div>
					</>
				)}
			</>
		);
	}

	/*showAllProjectImages(project) {
		const { userData: { token } } = this.props;
		const {
			uploadingRawMaterialFile,
			errorRemovingRawMaterial,
			removingRawMaterial,
			newRawMaterialFiles,
		} = this.state;

		const rawMaterial = {
			name: 'Client Sketch',
			url: serverAPI.getPublicSketchUrl(project.id, token.accessToken),
		};

		let { rawMaterials } = project;
		rawMaterials = [...rawMaterials, ...newRawMaterialFiles];
		rawMaterials = rawMaterials.map(rawMaterial => {
			return {
				id: rawMaterial.id,
				name: rawMaterial.name,
				fileType: rawMaterial.mimeType,
				url: serverAPI.getThumbnailUrlByFile(rawMaterial),
			};
		});

		return (
			<div className={classes.allImagesContainer}>
				<div className={classes.allImagesContent}>
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						<h4>{project.name} images</h4>
						<div
							style={{cursor: 'pointer', textDecoration: 'underline' }}
							onClick={() => this.setState({showProjectImages: false})}
						>
							X Close
						</div>
					</div>
					<div className={classes.contentBlock}>
						<h5 className={classes.titleSpace}>Main image</h5>
						{this.displayProjectImage(project)}
					</div>
					<div className={classes.horizontalDivider}></div>
					<div className={classes.contentBlock}>
						<h5 className={classes.titleSpace}>Secondary images</h5>
						<div>
							<Mutation mutation={ADD_RAW_MATERIAL}>
								{(uploadRawMaterialFileMutation) => {
									return uploadingRawMaterialFile ? (
										<FileUploaderItem
											name={uploadingRawMaterialFile.filename}
											errorSubject={KEYS.files_error_exceeds_limit}
											status='uploading'
										/>
									) : (
										<div className={classes.materialCard} style={{border: 'none', textAlign: 'center'}}>
											<div
												className={classes.uploaderBox}
												onClick={() => this.inputFileRef.current.click()}
											>
												Upload new file
											</div>
											<input
												id="proposal-file-uploader"
												name="proposal-file-uploader"
												ref={this.inputFileRef}
												type="file"
												accept="image/*, .pdf"
												onChange={(e) => this.onClickUploadRawMaterialFile(e, project, uploadRawMaterialFileMutation)}
												style={{ display: 'none' }}
											/>
										</div>
									);
								}}
							</Mutation>
							<div className={classes.materialList}>
								{project.productSketch !== null &&
									<div className={classes.materialCard}>
										<RawMaterialImage
											src={rawMaterial.url}
											className={classes.cardImg}
											onClick={() => this.onOpenPreviewFile(rawMaterial.url)}
										/>
										<div className={classes.cardName}>
											<h5 className={classes.name}>{KEYS.first_sketch_file.substring(0, 20)}</h5>
											<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
												<OverflowMenuItem itemText="Download" href={rawMaterial.url} target="_blank" rel="noopener noreferrer" />
												<OverflowMenuItem isDelete disabled itemText="Delete" />
											</OverflowMenu>
										</div>
									</div>
								}
								<Mutation mutation={REMOVE_RAW_MATERIAL}>
									{(removeRawMaterialMutation) => {
										return (
											rawMaterials.map((material, i) => (
												<div className={classes.rawMaterialContainer} key={i}>
													<div className={classes.materialCard}>
														<RawMaterialImage
															src={material.url}
															className={classes.cardImg}
															alt={material.name}
															onClick={material.fileType === 'application/zip'
																?	() => this.onOpenPreviewFile(material)
																:	undefined
															}
														/>
														<div className={classes.cardName}>
															<h5 className={classes.name}>{material.name.substring(0, 20)}</h5>
															{(removingRawMaterial !== null && (removingRawMaterial.remove && removingRawMaterial.fileId === material.id))
																?	<InlineLoading status="active" style={{ width: '20px'}} />
																:	<OverflowMenu size="sm" direction="top" flipped light style={{ marginLeft: '8px' }}>
																	<OverflowMenuItem itemText="Download" href={this.downloadFile(material)} target="_blank" rel="noopener noreferrer" />
																	<OverflowMenuItem hasDivider isDelete itemText="Delete" onClick={(e) => this.openDeleteFile(e, material, project, removeRawMaterialMutation)} />
																</OverflowMenu>
															}
														</div>
													</div>
													{(errorRemovingRawMaterial !== null && (errorRemovingRawMaterial.error && errorRemovingRawMaterial.fileId === material.id)) &&
														<small style={{ margin: '0 16px' }}>{errorRemovingRawMaterial.error}</small>
													}
												</div>
											))
										);
									}}
								</Mutation>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}*/

	onClickUploadRawMaterialFile = async (e, project, uploadRawMaterialFileMutation) => {
		const { target: { validity, files: [file] } } = e;

		if (!validity.valid) return;

		this.setState({
			uploadingRawMaterialFile: {
				filename: file.name,
				size: file.size,
			}
		}, async () => {
			const result = await uploadRawMaterialFileMutation({
				variables: { projectId: project.id, name: file.name, file }
			});

			if (result && result.data && result.data.addRawMaterial) {
				const { newRawMaterialFiles } = this.state;
				newRawMaterialFiles.push({
					id: result.data.addRawMaterial.id,
					name: result.data.addRawMaterial.name,
				});
				this.setState({
					uploadSuccess: true,
					newRawMaterialFiles: newRawMaterialFiles,
				});
			} else {
				this.setState({
					uploadError: true,
				});
			}
			this.setState({
				uploadingRawMaterialFile: null
			});
			setTimeout(() => {
				this.setState({
					uploadSuccess: false,
					uploadError: false
				});
			}, 5000);
		});
	};

	async openDeleteFile(e, material, project, removeRawMaterialMutation) {
		e.preventDefault();

		this.setState({
			removingRawMaterial: {
				fileId: material.id,
				remove: true
			}
		});

		let error;
		try {
			const result = await removeRawMaterialMutation({
				variables: {
					projectId: project.id,
					fileId: material.id,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error deleting file');
			this.setState({
				errorRemovingRawMaterial: {
					error: error.toString(),
					fileId: material.id
				}
			});
			setTimeout(() => {
				this.setState({ errorRemovingRawMaterial: null });
			}, 3000);
		} else {
			console.log('Success deleting file');
			this.refetchProject();
			this.setState({ removingRawMaterial: null, showRawMaterials: true });
		}
		this.setState({ removingRawMaterial: null });
	}
	
	async onClickConfirmRawMaterials (project, confirmRawMaterialsMutation) {
		this.setState({
			confirmingRawMaterials: true
		}, async () => {
			let error;
			try {
				const result = await confirmRawMaterialsMutation({
					variables: {
						projectId: project.id,
					}
				});
				if (!result) error = new Error('Something went wrong');
			} catch (e) {
				error = e;
			}
			
			if (!error) {
				this.refetchProject();
			}
		});
	}

	downloadFile(file) {
		return serverAPI.getDownloadUrlByFile(file);
	}

	displayTag(name = '') {
		return (
			<div className={classes.tag}>#{name.toLowerCase()}</div>
		);
	}

	displayFeaturesTags(project) {
		return (
			<div className={classes.tags}>
				{ project.prototypeSize && this.displayTag(PROJECT.getSizeToString(project.prototypeSize)) }
				{ project.hasIOT && this.displayTag(KEYS.has_iot) }
				{ project.hasActuators && this.displayTag(KEYS.has_actuators) }
				{ project.electronics && this.displayTag(KEYS.electronics) }
				{ project.productEnvironment &&
					this.displayTag(PROJECT.getEnvironmentToString(project.productEnvironment))
				}
			</div>
		);
	}

	renderClientsActivity() {
		return (
			<div className={classes.secondaryBtnContainer}>
				<div>
					<h5 className={classes.subtitle}>Your activity</h5>
				</div>
				<div className={classes.activityContainer}>
					{this.displayPersonalActivity()}
				</div>
			</div>
		);
	}

	displayPersonalActivity() {
		const { userData: {user} } = this.props;
		const { expert } = user;
		const statistics = expert && expert.statistics;

		return (
			<div className={classes.experienceBlock}>
				<div>
					<h3>{statistics.joinedContests}</h3>
					<small>Joined contests</small>
				</div>
				<div className={classes.divider}></div>
				<div>
					<h3>{statistics.sentProposals}</h3>
					<small>Sent proposals</small>
				</div>
				<div className={classes.divider}></div>
				<div>
					<h3>{statistics.winningSubmissions}</h3>
					<small>Won proposals</small>
				</div>
			</div>
		);
	}

	displayProjectOnboardingList(project) {
		const { userData: {user} } = this.props;
		const projectHasName = project && project.name;
		const userHasProjectImage = project && project.image;

		const phaseObject = project && PROJECT.getStateByPhase(project, PROJECT.STATE.CLIENT_ONBOARDING);
		const questions = phaseObject && phaseObject.setupQuestions;
		const projectHasSetUpQuestionsCompleted = questions && FormUtils.isAtLeastOneQuestionAnswered(questions);
		const projectHasRawMaterials = project && project.rawMaterials.length > 0;
		const projectDetailsAreCompleted = project && project.briefingCompletionPercentaje && project.briefingCompletionPercentaje > 90;
		const userHasAvatar = user && user.hasAvatar;

		return (
			<div style={{ margin: '36px 0' }}>
				<h4 className={classes.secondaryTitle}>Onboarding</h4>
				<div
					className={classes.secondaryTitleDescription}
					style={{ marginBottom: '20px' }}
				>
					Setup your project with all the information below in order to start the creation process of your new prototype
				</div>
				<div className={classes.listContainer}>
					<ul>
						<li className={classes.listItem}>
							{projectHasName ? (
								<CheckmarkFilled16 className={classes.iconFilled} />
							) : (
								<RadioButton16 className={classes.iconPending} />
							)}
							<span
								className={classes.linkTextAction}
								onClick={() => this.setState({openUpdateProjectNameModal: true})}
							>
								Set a project name
							</span>
						</li>
						<li className={classes.listItem}>
							{userHasProjectImage ? (
								<CheckmarkFilled16 className={classes.iconFilled} />
							) : (
								<RadioButton16 className={classes.iconPending} />
							)}
							<span
								className={classes.linkTextAction}
								onClick={() => this.setState({openUpdateProjectImageModal: true})}
							>
								Choose your project image
							</span>
						</li>
					</ul>
					<ul>
						<li className={classes.listItem}>
							{projectDetailsAreCompleted
								?	<CheckmarkFilled16 className={classes.iconFilled} />
								:	<RadioButton16 className={classes.iconPending} />
							}
							<span
								className={(projectDetailsAreCompleted ? classes.linkTextDisabled : classes.linkTextAction)}
								onClick={() => (projectDetailsAreCompleted ? {} : window.open(`/project-builder/${project.id}`, '_self'))}
							>
								Complete all project details
							</span>
						</li>
						<li className={classes.listItem}>
							{projectHasSetUpQuestionsCompleted
								?	<CheckmarkFilled16 className={classes.iconFilled} />
								:	<RadioButton16 className={classes.iconPending} />
							}
							<span
								className={(projectHasSetUpQuestionsCompleted ? classes.linkTextDisabled : classes.linkTextAction)}
								onClick={() => (projectHasSetUpQuestionsCompleted ? {} : this.setState({openSetupQuestionsModal: true}))}
							>
								Fill initial set up questions
							</span>
						</li>
					</ul>
					<ul>
						<li className={classes.listItem}>
							{projectHasRawMaterials
								?	<CheckmarkFilled16 className={classes.iconFilled} />
								:	<RadioButton16 className={classes.iconPending} />
							}
							<span
								className={classes.linkTextAction}
								onClick={() => this.setState({showRawMaterials: true}, window.scrollTo({top: 200, behavior: 'smooth'}))}
							>
								Add additional materials
							</span>
						</li>
						<li className={classes.listItem}>
							{userHasAvatar
								?	<CheckmarkFilled16 className={classes.iconFilled} />
								:	<RadioButton16 className={classes.iconPending} />
							}
							<span
								className={classes.linkTextAction}
								onClick={() => window.open('/account/settings', '_self')}
							>
								Upload your profile avatar
							</span>
						</li>
					</ul>
				</div>
			</div>
		);
	}

	displayPendingFeasibilityBlock(project) {
		return (
			<ProjectFeasibility project={project} />
		);
	}

	displayProjectSetUpBlock(project) {
		const projectIsPendingFeasibility = project.feasibility === PROJECT.FEASIBILITY.PENDING;
		const projectIsNonFeasible = project.feasibility === PROJECT.FEASIBILITY.NO;
		const projectIsFeasible = project.feasibility === PROJECT.FEASIBILITY.YES;
		const projectIsPendingPayment = projectIsFeasible && !project.paid;

		const projectStatus = projectIsPendingFeasibility
			? 'being validated'
			: projectIsNonFeasible ? null //'not feasible'
				: projectIsFeasible ? null // 'feasible'
					: projectIsPendingPayment ? 'pending payment'
						: '';

		// const hasInvoice = project && project.setupInvoice ? true : false;

		return (
			<div className={classes.setUpBox}>
				{/* <div className={classes.spaceBetween} style={{marginBottom: '16px'}}>
					<h4>Set up</h4>
					{projectIsFeasible &&
                        <span className={classes.linkModal}
                        	onClick={() => window.Froged('doc', '624587ec62d09fd9e43be18a')}>
                        	<Link to='#'>
                                About this process
                        	</Link>
                        </span>
					}
				</div> */}
				<div className={classes.spaceBetween}>
					<p className={classes.feasibilityMessages} style={{marginRight: '36px'}}>
						{projectStatus &&
							<div style={{ marginRight: '10px' }}> 
								Your project is <strong>{projectStatus}</strong>
								{' '}
							</div>
						}

						<div>
							<ValidationChip projectId={project.id} mainFeasibility={project.feasibility}/>
						</div>

						<div>
							{projectIsNonFeasible &&
								<Link to={'/new/project'} style={{color: '#df3d3b'}}>
									Create a new project {/*  // KEYS.project_feasibility_non_feasible_message_option_A */}
								</Link>
							}

							{projectIsFeasible &&
								<Link to={`/project/${project.id}/unlock`} style={{color: '#df3d3b'}}>
									Unlock your project
								</Link>
							}
						</div>
					</p>
					{/* {(projectIsPendingFeasibility || projectIsNonFeasible || projectIsPendingFeasibility) && */}
					<Button
						className={classnames(classes.btn, classes.chatBtn)}
						onClick={() => this.setState({ openWidget: true })}
						size='sm'
						kind='primary'>
						<div style={{ display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
							<PeopleAltIcon fontSize={'inherit'} />
							Let&apos;s meet
						</div>
					</Button>
					{/* } */}
					{/* {projectIsFeasible &&
                        <Button
                        	className={classnames(classes.btn, classes.large)}
                        	onClick={hasInvoice ? () => this.completePayment(project.setupInvoice.url) : ''}
                        	size='sm'
                        	kind='secondary'>
                        	{hasInvoice
                        		? 'Pay and start my journey'
                        		: 'Wait for payment'
                        	}
                        </Button>
					} */}
				</div>
			</div>
		);
	}

	completePayment(invoiceUrl) {
		window.open(invoiceUrl, '_blank');
	}

	displayProjectDetails(project) {
		const { showMoreDetails } = this.state;

		const { userData: { user } } = this.props;
		const { maker } = user;

		const isInventor = maker !== null;

		return (
			<div>
				<div className={classes.betweenBlock} style={{ alignItems: 'end'}}>
					<div className={classes.flexColumn}>
						<div style={{display: 'flex', alignItems: 'baseline'}}>
							<h4 className={classes.secondaryTitle}>Details</h4>
							{isInventor && !project.paid && project.feasibility !== PROJECT.FEASIBILITY.NO &&
								<Link
									to={`/project-builder/${project.id}`}
									style={{textDecoration: 'none', color: '#313131'}}
								>
									<span
										className={classes.secondaryLink}
										style={{marginLeft: '8px'}}
									>
										Edit
									</span>
								</Link>
							}
						</div>
						<div className={classes.secondaryTitleDescription} style={{ marginBottom: '0px' }}>Your invention in detail</div>
					</div>
					<a
						className={classes.link}
						href={((project && project.paid) || 1 === 1  ? this.downloadProjectBriefing(project) : '#')}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FileDownloadOutlined
							fontSize="small"
							style={{color: '#313131'}}
						/>
					</a>
				</div>
				<div className={classes.projectInfoContainer}>
					<div className={classes.infoContainer}>
						<div className={classes.contentBlock}>
							<h5 className={classes.titleSpace}>Product need</h5>
							<p>{project.productNeed}</p>
						</div>
						<div className={classes.contentBlock}>
							<h5 className={classes.titleSpace}>Product solution</h5>
							<p>{project.productSolution}</p>
						</div>
						<div className={classes.contentBlock} style={{ display: project?.competitionExample ? 'block' : 'none' }}>
							<h5 className={classes.titleSpace}>References</h5>
							{this.renderProjectReferences(project)}
						</div>
					</div>
					{this.displayProjectPriorities(project)}
				</div>
				{showMoreDetails ? (
					this.renderProjectExtras(project)
				) : (
					<div style={{ display: 'flex', justifyContent: 'end'}}>
						<span
							className={classes.secondaryLink}
							onClick={() => this.setState({ showMoreDetails: true })}
						>
							More
						</span>
					</div>
				)}
			</div>
		);
	}

	displayProjectPriorities(project) {
		return (
			<div style={{width: '100%'}}>
				<ErrorBoundaryQuery query={GET_PROJECT_EXTRA_INFO} variables={{ projectId: project.id }}>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<div className={classes.contentBlock}>
										<SkeletonText style={{width: '200px'}} />
										<div className={classes.lists}>
											<div className={classes.list}>
												<SkeletonText style={{width: '250px'}}/>
												<SkeletonText style={{width: '250px'}}/>
												<SkeletonText style={{width: '250px'}}/>
											</div>
											<div className={classes.list}>
												<SkeletonText style={{width: '250px'}}/>
												<SkeletonText style={{width: '250px'}}/>
												<SkeletonText style={{width: '250px'}}/>
											</div>
										</div>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const briefing = data && data.getProjectBriefing;
						const fields = briefing.fields;

						if (!fields) {
							return (
								<Alert
									severity="info"
									style={{margin: '16px 0', height: 'fit-content'}}
									action={
										<Link
											to={`/project-builder/${project.id}`}
											style={{textDecoration: 'none', color: '#0062ff'}}
										>
											<ActionBtn color="inherit" size="small" style={{textTransform: 'none'}}>
												Complete project info
											</ActionBtn>
										</Link>
									}
								>
									{/* <AlertTitle>Missing information</AlertTitle> */}
									Please add more information to your project
								</Alert>
							);
						}

						const hasPriorities = !!fields.priorities;
						const mechanicalPriorities = hasPriorities && fields.priorities.filter(p => p.area === 'mechanical');
						const electricalPriorities = hasPriorities && fields.priorities.filter(p => p.area === 'electrical');

						const phaseObject = PROJECT.getStateByPhase(project, PROJECT.STATE.CLIENT_ONBOARDING);
						const questions = phaseObject && phaseObject.setupQuestions;
						const doOnboardingQuestionsHaveAnswer = questions && FormUtils.isAtLeastOneQuestionAnswered(questions);

						return (
							<>
								<div className={classes.contentBlock}>
									<h5 className={classes.titleSpace}>Priorities</h5>
									{hasPriorities ? (
										<div className={classes.lists}>
											<div className={classes.list}>
												{mechanicalPriorities.map((priority, i) => (
													<div key={i} className={classes.listItem}>
														<TooltipIcon tooltipText="Mechanical priority">
															<div className={classes.colorBubble} style={{ backgroundColor: '#FF7DD3' }}></div>
															<p style={{ textAlign: 'start' }}>{priority.description}</p>
														</TooltipIcon>
													</div>
												))}
											</div>
											<div className={classes.list}>
												{electricalPriorities.map((priority, i) => (
													<div key={i} className={classes.listItem}>
														<TooltipIcon tooltipText="Electrical priority">
															<div className={classes.colorBubble} style={{ backgroundColor: '#FFE926' }}></div>
															<p style={{ textAlign: 'start' }}>{priority.description}</p>
														</TooltipIcon>
													</div>
												))}
											</div>
										</div>
									) : (
										<Link
											to={`/project-builder/${project.id}`}
											style={{textDecoration: 'none'}}
										>
											<span className={classes.secondaryLink}>
												Set up your project priorities
											</span>
										</Link>
									)}
								</div>
								{!doOnboardingQuestionsHaveAnswer &&
									<div className={classes.contentBlock}>
										<h5 className={classes.titleSpace}>General project set up</h5>
										<div style={{marginTop: '12px'}}>
											<span
												className={classes.secondaryLink}
												onClick={() => this.setState({ openSetupQuestionsModal: true })}
											>
												Let&apos;s us know about your project goals and priorities
											</span>
										</div>
									</div>
								}
							</>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}

	renderProjectExtras(project) {
		return (
			<ErrorBoundaryQuery query={GET_PROJECT_EXTRA_INFO} variables={{ projectId: project.id }}>
				{({ loading, error, data }) => {

					if (loading) {
						return (
							<div className={classes.loadingContainer}>
								<div className={classes.contentBlock}>
									<SkeletonText style={{width: '200px'}} />
									<div className={classes.betweenBlock}>
										<div style={{ display: 'flex'}}>
											<SkeletonPlaceholder className={classes.imagePreviewSkeleton} />
											<SkeletonPlaceholder className={classes.imagePreviewSkeleton} />
											<SkeletonPlaceholder className={classes.imagePreviewSkeleton} />
										</div>
										<div>
											<SkeletonText style={{width: '300px'}}/>
											<SkeletonText style={{width: '500px'}}/>
										</div>
									</div>
								</div>
								<div className={classes.contentBlock}>
									<SkeletonText style={{width: '200px'}} />
									<div className={classes.betweenBlock}>
										<div>
											<SkeletonText style={{width: '500px'}}/>
										</div>
										<div>
											<SkeletonText style={{width: '300px'}}/>
											<SkeletonText style={{width: '500px'}}/>
										</div>
									</div>
								</div>
								<div className={classes.contentBlock}>
									<SkeletonText style={{width: '200px'}} />
									<div className={classes.betweenBlock}>
										<div>
											<SkeletonText style={{width: '500px'}}/>
											<SkeletonText style={{width: '500px'}}/>
										</div>
										<div>
											<SkeletonText style={{width: '300px'}}/>
											<SkeletonText style={{width: '500px'}}/>
										</div>
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const briefing = data && data.getProjectBriefing;
					const fields = briefing.fields;

					if (!fields) {
						return (
							<Alert
								severity="info"
								style={{margin: '16px 0'}}
								action={
									<Link
										to={`/project-builder/${project.id}`}
										style={{textDecoration: 'none', color: '#0062ff'}}
									>
										<ActionBtn color="inherit" size="small" style={{textTransform: 'none'}}>
											Complete project info
										</ActionBtn>
									</Link>
								}
							>
								<AlertTitle>Missing information</AlertTitle>
								Please add more information to your project
							</Alert>
						);
					}

					const phaseObject = PROJECT.getStateByPhase(project, PROJECT.STATE.CLIENT_ONBOARDING);
					const questions = phaseObject && phaseObject.setupQuestions;
					const doOnboardingQuestionsHaveAnswer = questions && FormUtils.isAtLeastOneQuestionAnswered(questions);

					return (
						<>
							<div className={classes.projectInfoContainer}>
								<div className={classes.infoContainer}>
									<div className={classes.contentBlock}>
										<h5 className={classes.titleSpace}>Look and feel</h5>
										{this.displayLookAndFeel(fields)}
									</div>
									<div className={classes.contentBlock}>
										<h5 className={classes.titleSpace}>Technology & parts</h5>
										<div className={classes.flexColumn}>
											{fields.number_of_parts
												? <p className={classes.previewText}>Number of parts: {BRIEFING.getNumberPartsText(fields.number_of_parts)}</p>
												: <div className={classes.addInfoBtn}><Link
													to={`/project-builder/${project.id}`}
													style={{color: '#0062ff'}}
												>
													+ Add number of parts
												</Link>
												</div>
											}
											{fields.electronic_design !== null &&
												<p className={classes.previewText}>Electronic design: {BRIEFING.getElectronicDesignText(fields.electronic_design)}</p>
											}
											{fields.parts &&
												<div className={classes.chipSelector}>
													{fields.parts.map((part, i) => (
														<p key={i} className={classes.chipPreview}>{strings.capitalizeFirstLetter(part)}</p>
													))}
												</div>
											}
											{fields.power_sources &&
												<div className={classes.chipSelector}>
													{fields.power_sources.map((power, i) => (
														<p key={i} className={classnames(classes.chipPreview, classes.areBatteries)}>{BRIEFING.getPowerSourceText(power)}</p>
													))}
												</div>
											}
											{fields.sensors &&
												<div className={classes.chipSelector}>
													{fields.sensors.map((sensor, i) => (
														<p key={i} className={classnames(classes.chipPreview, classes.areSensors)}>{BRIEFING.getSensorText(sensor)}</p>
													))}
												</div>
											}
										</div>
									</div>
									<div className={classes.contentBlock}>
										<h5 className={classes.titleSpace}>Business</h5>
										<div className={classes.flexColumn}>
											{fields.project_stage
												? <p className={classes.previewText}>Project stage: {BRIEFING.getProjectStageText(fields.project_stage)}</p>
												: <div className={classes.addInfoBtn}>
													<Link
														to={`/project-builder/${project.id}`}
														style={{color: '#0062ff'}}
													>
														+ Add project stage
													</Link>
												</div>
											}
											{fields.intention
												? <p className={classes.previewText}>Intention: {BRIEFING.getIntentionsText(fields.intention)}</p>
												: <div className={classes.addInfoBtn}>
													<Link
														to={`/project-builder/${project.id}`}
														style={{color: '#0062ff'}}
													>
														+ Add project intention
													</Link>
												</div>
											}
										</div>
									</div>
								</div>
								<div style={{width: '100%'}}>
									{doOnboardingQuestionsHaveAnswer && this.displayProjectOnboardingAnswers(project)}
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'end'}}>
								<span
									className={classes.secondaryLink}
									onClick={() => this.setState({ showMoreDetails: false })}
								>
									Less
								</span>
							</div>
						</>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayLookAndFeel(fields) {
		const size = fields.size;
		const looks = fields.looks;
		const solidity = fields.solidity;
		const funcionality = fields.function;

		return (
			<div style={{ marginTop: '8px' }}>
				{size &&
					<div className={classes.imagePreviewWrapper}>
						<div
							className={classes.imagePreview}
							style={{ backgroundImage: `url(${BRIEFING.getSizeImage(size)})`}}
						></div>
						<div className={classes.imagePreviewTitle}>{size}</div>
					</div>
				}
				{looks &&
					<div className={classes.imagePreviewWrapper}>
						<div className={classes.imagePreview} style={{ backgroundImage: `url(${BRIEFING.getLooksImage(looks)})` }}></div>
						<div className={classes.imagePreviewTitle}>{looks}</div>
					</div>
				}
				{solidity &&
					<div className={classes.imagePreviewWrapper}>
						<div className={classes.imagePreview} style={{ backgroundImage: `url(${BRIEFING.getSolidityImage(solidity)})` }}></div>
						<div className={classes.imagePreviewTitle}>{solidity}</div>
					</div>
				}
				{funcionality &&
					<div className={classes.imagePreviewWrapper}>
						<div className={classes.imagePreview} style={{ backgroundImage: `url(${BRIEFING.getFunctionImage(funcionality)})` }}></div>
						<div className={classes.imagePreviewTitle}>{funcionality}</div>
					</div>
				}
			</div>
		);
	}

	renderProjectReferences(project) {
		const reference = project.competitionExample;

		return (
			reference === null ? (
				<Link
					to={`/project-builder/${project.id}`}
					style={{ textDecoration: 'none' }}
				>
					<span className={classes.secondaryLink}>
						Add a reference
					</span>
				</Link>
			) : (
				<a href={reference} target='_blank' rel='noreferrer'>{reference}</a>
			)
		);
	}

	renderProjectBriefing(project) {
		const { userData } = this.props;
		const { token } = userData;
		const briefingUrl = serverAPI.getPublicBriefingUrl(project.id, token.accessToken);

		return (
			<a href={briefingUrl} target='_blank' rel='noreferrer'>Open project briefing as a pdf</a>
		);
	}

	downloadProjectBriefing(project) {
		const { userData } = this.props;
		const { token } = userData;
		const briefingUrl = serverAPI.getPublicBriefingUrl(project.id, token.accessToken);

		return briefingUrl;
	}

	displayContests(project) {
		const { viewMoreFiles } = this.state;
		const contests = project.contests;
		const contestOrder = project.contestOrder ? project.contestOrder : PROJECT.getContestTypes();
		const orderedContests = contests.sort((a, b) => contestOrder.indexOf(a.type) - contestOrder.indexOf(b.type));

		return (
			<>
				<div className={classes.contestsContainer}>
					{orderedContests.filter(c => c.active).map((contest, i) => (
						<Link
							key={i}
							style={{textDecoration: 'none'}}
							to={contest.active ? `/project/${project.id}/contest/${contest.id}` : '#'}
						>
							<div className={classes.contestBox} style={!contest.active ? {cursor: 'not-allowed'} : viewMoreFiles ? {height: '100%', alignItems: 'start'} : {}}>
								{(contest.proposals || []).map(proposal => {
									return this.renderWinningProposal(proposal, contest);
								})}
								<div className={classes.contestContent}>
									<div style={{ display: 'flex'}}>
										<div style={{display: 'inline-block'}}>
											<h4 className={classes.titleSpace} style={!contest.active ? {color: '#C7C7C7'} : {}}>{strings.capitalizeFirstLetter(contest.type)}</h4>
											{contest.active
												?	<small style={{color: '#717171'}}>{CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type))}</small>
												:	<div style={{display: 'flex', alignItems: 'center'}}>
													<small style={{color: '#C7C7C7'}}>Inactive phase</small>
													<LockIcon sx={{ width: '12px', height: '12px' }} style={{marginLeft: '4px', color: '#C7C7C7'}} />
												</div>
											}
										</div>
									</div>
									<div className={classes.contestBlockInfo}>
										{((CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) === 'Open contest') ||
										(CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) === 'Winner selection'))
											?	<Badge color="primary" variant="dot">
												<ContestIcon
													state={PROJECT.getStateByContestType(contest.type)}
													size={26}
													color={contest.active ? CONTEST.getPhaseColorWithContestType(contest.type) : ''}
												/>
											</Badge>
											:	<ContestIcon
												state={PROJECT.getStateByContestType(contest.type)}
												size={26}
												color={contest.active ? CONTEST.getPhaseColorWithContestType(contest.type) : ''}
											/>
										}
										{(CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) === 'Completed') &&
											this.renderContestSupportingFiles(contest)
										}
									</div>
								</div>
							</div>
						</Link>
					))}
				</div>
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	displayNewContests(project, projectIsInLobby) {
		const contests = project.contests;
		const contestOrder = project.contestOrder ? project.contestOrder : PROJECT.getAllContestTypes();
		const orderedContests = project.state !== PROJECT.STATE.CLOSED
			?	contests.sort((a, b) => contestOrder.indexOf(b.type) - contestOrder.indexOf(a.type)).filter(c => c.active)
			:	contests.sort((a, b) => contestOrder.indexOf(b.type) + contestOrder.indexOf(a.type)).filter(c => c.active);
		const filteredOrderedContests = project.state !== PROJECT.STATE.CLOSED
			?	orderedContests.filter(c => contestOrder.indexOf(c.type) <= contestOrder.indexOf(PROJECT.getContestTypeByState(project.state)))
			:	orderedContests;

		return (
			<>
				<div className={classes.newContestsContainer}>
					{filteredOrderedContests.map((contest, i) => (
						<div className={classnames(classes.contestBox, classes.contestList)} key={i}>
							<div style={{position: 'relative'}}> {/* className={classes.proposalsContainer} */}
								{/* {contest.proposals.length > 0 &&
									contest.proposals.map((proposal) => this.renderAllContestsProposals(proposal))
								} */}
								{/* {contest.proposals.length > 0 && this.renderContestProposalsThumbnails(contest)} */}
								{contest.proposals.length === 0 ? (
									<Link
										to={contest.active ? `/project/${project.id}/contest/${contest.id}` : '#'}
										style={{textDecoration: 'none'}}
									>
										<div className={classes.emptyProposal}>
											<small>Setup is pending</small>
										</div>
									</Link>
								) : (
									this.renderContestProposalsGrid(contest)
								)}
								<Link
									to={contest.active ? `/project/${project.id}/contest/${contest.id}` : '#'}
									style={{textDecoration: 'none'}}
								>
									<Tag
										className={classes.phaseTag}
										type="cool-gray"
										style={{backgroundColor: CONTEST.getPhaseColor(PROJECT.getStateByContestType(contest.type))}}
									>
										{PROJECT.stateToStringShort(PROJECT.getStateByContestType(contest.type))}
									</Tag>
								</Link>
							</div>
							<Link
								to={contest.active ? `/project/${project.id}/contest/${contest.id}` : '#'}
								style={{textDecoration: 'none', width: '100%'}}
							>
								<div className={classes.contestContent} style={{padding: '16px'}}>
									<div className={classes.flexColumn}>
										<small style={{color: '#7A7A7A', marginBottom: '8px'}}>Participants</small>
										<div className={classes.contributorsBlock}>
											{(CONTEST.getContestContributorsWithId(contest).length > 0 && CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) !== 'Not open yet') ? (
												<>
													{CONTEST.getContestContributorsWithId(contest).slice(-3).map((contributor, i) => (
														<Avatar
															key={i}
															className={classes.contributor}
															sx={{ height: '36px', width: '36px' }}
															src={serverAPI.getAvatarUrl(contributor)}
														/>
													))}
													{CONTEST.getContestContributorsWithId(contest).length > 3 &&
														<Avatar
															className={classes.contributor}
															sx={{ height: '36px', width: '36px' }}
														>
															+{CONTEST.getContestContributorsWithId(contest).length - 3}
														</Avatar>
													}
												</>
											) : (
												<small style={{background: 'none', textTransform: 'uppercase', marginTop: '10px'}}>Pending to join</small>
											)}
										</div>
									</div>
									<div className={classes.contestBlockInfo}>
										<div className={classes.flexColumn}>
											<small style={{color: '#7A7A7A', marginBottom: '8px'}}>Status</small>
											<Tag
												type="cyan"
												className={classes.statusTag}
												style={CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) === 'Completed'
													? {background: '#00943B'}
													: CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type)) !== 'Not open yet'
														?	{background: '#FF9F46'}
														:	{background: '#CCC'}
												}
											>
												{contest.active
													?	CONTEST.getContestPhaseState(project, PROJECT.getStateByContestType(contest.type))
													:	'Inactive phase'
												}
											</Tag>
										</div>
									</div>
								</div>
							</Link>
						</div>
					))}
					{filteredOrderedContests.length === 0 &&
						<div className={classes.contestProposalsContainer}>
							<div className={classes.lockContainer}>
								{projectIsInLobby ? (
									<>
										<div className={classes.lockedIcon}>
											<LockIcon color='inherit' fontSize='inherit' />
										</div>
										<p>You will see all the phases when you <Link to={'./unlock'}>unlock the project</Link></p>
									</>
								) : (
									<p>No phase contests ready yet</p>
								)}
							</div>
						</div>
					}
				</div>
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	renderWinningProposal(proposal, contest) {
		const isWinner = CONTEST.isWinningProposal(contest, proposal);
		if (!isWinner) return null;

		for (const proposalFile of proposal.files) {
			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			if (!approvedAndPublished) continue;

			return (
				<img
					className={classes.winningProposalImg}
					src={this.getProposalThumbnailUrl(proposal)}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
					}}
					alt={KEYS.winning_proposal}
				/>
			);
		}
	}

	renderAllContestsProposals(proposal) {
		for (const proposalFile of proposal.files) {

			const fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
			const approvedAndPublished = fileVersion.approvedStatus === 'yes' && fileVersion.published === true;

			if (!approvedAndPublished) continue;

			return (
				<img
					className={classes.contestProposalImg}
					src={this.getProposalThumbnailUrl(proposal)}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
					}}
					alt={KEYS.winning_proposal}
				/>
			);
		}
	}

	renderContestProposalsThumbnails(contest) {
		const proposals = contest.proposals;
		const winningProposal = contest.selectedProposal ? proposals.find(p => p.id === contest.selectedProposal.id) : null;
		const restProposals = winningProposal
			? proposals.filter(p => p.id !== winningProposal.id)
			: proposals;
		const approvedRestProposals = restProposals.filter(p => p.feedback !== null);
		
		return (
			<Swiper
				autoplay={true}
				loop={true}
				modules={[Pagination]}
				pagination={{ clickable: true }}
				slidesPerView={1}
				spaceBetween={10}
			>
				{winningProposal && (
					<SwiperSlide>
						<div style={{position: 'relative'}}>
							<img
								className={classes.contestProposalImg}
								src={this.getProposalThumbnailUrl(winningProposal)}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
								}}
								alt={KEYS.winning_proposal}
							/>
							<StarsRoundedIcon
								className={classes.winnerTag}
								sx={{ width: '30px', height: '30px'}}
								style={{ fill: '#CCC', borderRadius: '50%' }}
							/>
						</div>
					</SwiperSlide>
				)}
				{approvedRestProposals.map((proposal, i) => (
					<SwiperSlide key={i}>
						<img
							className={classes.contestProposalImg}
							src={this.getProposalThumbnailUrl(proposal)}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
							}}
							alt={KEYS.winning_proposal}
						/>
					</SwiperSlide>
				))}
				{(!winningProposal && approvedRestProposals.length === 0) && (
					<div className={classes.emptyProposal}>
						<small>No proposals</small>
					</div>
				)}
			</Swiper>
		);
	}

	renderContestProposalsGrid(contest) {
		const proposals = contest.proposals;
		const winningProposal = contest.selectedProposal ? proposals.find(p => p.id === contest.selectedProposal.id) : null;
		const restProposals = winningProposal
			? proposals.filter(p => p.id !== winningProposal.id)
			: proposals;
		const approvedRestProposals = restProposals.filter(p => p.feedback !== null);

		return (
			<div className={classes.flexColumn}>
				{winningProposal ? (
					<div style={{position: 'relative'}}>
						<img
							className={classes.contestProposalImg}
							src={this.getProposalThumbnailUrl(winningProposal)}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
							}}
							alt={KEYS.winning_proposal}
							style={{borderRadius: '8px 8px 0px 0px', padding: '2px'}}
						/>
						<StarsRoundedIcon
							className={classes.winnerTag}
							sx={{ width: '30px', height: '30px'}}
							style={{ fill: '#FAFAFA', borderRadius: '50%' }}
						/>
					</div>
				) : (
					<div className={classes.contestProposalImg} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<small>No winning proposal yet</small>
					</div>
				)}
				<div className={classes.allProposalsSmall}>
					{approvedRestProposals.map((proposal, i) => (
						<img
							key={i}
							className={classnames(classes.contestProposalImg, classes.secondaryImgs)}
							src={this.getProposalThumbnailUrl(proposal)}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = PROJECT_MATERIAL_PLACEHOLDER;
							}}
							alt={KEYS.winning_proposal}
						/>
					))}
				</div>
			</div>
		);
	}

	getProposalThumbnailUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken);
	}

	getProposalThumbnailUrlWithId(proposalId) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposalId, token.accessToken);
	}

	renderContestSupportingFiles(contest) {
		const { sharedFiles } = contest;
		const getTotalFiles = CONTEST.getTotalSupportingFiles(sharedFiles);

		return (
			<small style={{marginTop: '16px'}}>
				{getTotalFiles === 0 ? (
					'Pending files'
				) : (getTotalFiles === 1) ? (
					`${getTotalFiles} file included`
				) : (
					`${getTotalFiles} files included`
				)}
			</small>
		);
	}

	displayWinningProposals(project, projectIsInLobby) {
		const { userData: { user } } = this.props;
		const contests = project.contests;
		const allContestAreEmpty = CONTEST.areAllContestsProposalsEmpty(contests);

		return (
			<>
				{allContestAreEmpty ? (
					<div className={classnames(classes.contestProposalsContainer, classes.contestsContainer)}>
						<div className={classes.lockContainer}>
							{projectIsInLobby ? (
								<>
									<div className={classes.lockedIcon}><LockIcon color='inherit' fontSize='inherit' /></div>
									<p>You will receive high quality proposals once you <Link to={'./unlock'}>unlock the project</Link></p>
								</>
							) : (
								<p>No winning proposals yet</p>
							)}
						</div>
					</div>
				) : (
					<WinningProposals
						project={project}
						user={user}
						disabled={projectIsInLobby}
						onClickProposal={(proposal, file, feedback) => this.setState({ openProposalFeedbackModal: true, proposalFeedback: feedback, proposalFile: file, selectedProposal: proposal })}
					/>
				)}
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	displayProjectFiles(project, projectIsInLobby) {
		// const { userData: { user } } = this.props;
		const contests = project.contests;
		const allSharedFilesAreEmpty = CONTEST.areAllSharedFilesEmpty(contests);
		const allContestAreEmpty = CONTEST.areAllContestsProposalsEmpty(contests);

		return (
			<>
				{(projectIsInLobby || (allSharedFilesAreEmpty && allContestAreEmpty)) ? (
					<div className={classnames(classes.contestProposalsContainer, classes.contestsContainer)}>
						<div className={classes.lockContainer}>
							{projectIsInLobby ? (
								<>
									<div className={classes.lockedIcon}><LockIcon color='inherit' fontSize='inherit' /></div>
									<p>You will see all the prototype-ready files when you <Link to={'./unlock'}>unlock the project</Link></p>
								</>
							) : (
								<p>No prototype-ready files yet</p>
							)}
						</div>
					</div>
				) : (
					<>
						{/* <h5 style={{marginBottom: '-26px'}}>Winning proposals</h5>
						<WinningProposals
							project={project}
							user={user}
							disabled={projectIsInLobby}
							onClickProposal={(proposal, file, feedback) => this.setState({ openProposalFeedbackModal: true, proposalFeedback: feedback, proposalFile: file, selectedProposal: proposal })}
						/> */}
						<h5 className={classes.titleSpace}>Supporting files</h5>
						<ProjectFiles
							project={project}
							onOpenPreviewFile={(f) => this.onOpenPreviewFile(f)}
						/>
					</>
				)}
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	displayProjectUpdates(project) {
		return (
			<ProjectUpdates projectId={project.id} />
		);
	}

	displayInventorsInfo(project) {
		const { owner } = project;
		const { maker } = owner;

		return (
			<>
				<h4 className={classes.secondaryTitle}>About the inventor</h4>
				<div className={classnames(classes.betweenBlock, classes.contestsContainer)}>
					<div>
						<div style={{display: 'flex', alignItems: 'flex-start'}}>
							<Avatar
								sx={{ height: '60px', width: '60px' }}
								style={{ marginRight: '16px' }}
								src={serverAPI.getAvatarUrl(owner.id)}
							>
								{this.getUserInitials(owner)}
							</Avatar>
							<div style={{ marginTop: '5px' }}>
								<h5 style={{ fontSize: '18px' }}>
									{owner.firstName}
									{(owner.firstName?.length === 1) && '.'}
									{' '}
									{owner.lastName}
									{(owner.lastName?.length === 1) && '.'}
								</h5>
								<small style={{ color: '#717171', fontSize: '16px' }}>
									{maker.countryResidence}
								</small>
							</div>
						</div>
					</div>
					<div className={classes.bottomBadge}>
						<Security20 style={{ fill: '#717171' }} />
						<small style={{ marginLeft: '8px', color: '#717171' }}>Verified inventor</small>
					</div>
				</div>
			</>
		);
	}

	getUserInitials = (user) => {
		const name = strings.capitalizeFirstLetter(user.firstName);
		const secondLetter = (user.lastName || '').charAt(0).toUpperCase();
		const initials = name + secondLetter;
		return initials;
	};

	displayProjectOnboardingAnswers(project) {
		return (
			<PhaseViewSetupQuestions
				noAnswersMessage="Need to complete the project onboarding."
				onboardingQuestionsDone={() => this.refetchProject()}
				phase={PROJECT.STATE.CLIENT_ONBOARDING}
				projectId={project.id}
			/>
		);
	}

	calculateProgress(project) {
		if (!project) return 0;
		const contests = (project.contests || []).map(contest => contest.active);

		let value = 0;
		for (const contest of contests) {
			if (contest.selectedProposal) {
				value += ((1 / contests.length) * 100);
			}
		}

		return Math.max(15, value);
	}

	displayClaps(project) {
		const { hoverProjectVisibility } = this.state;

		const avatars = [];

		avatars.push(<Avatar sx={{ height: '25px', width: '25px' }} alt="Alex Turell" src="https://api.lastbasic.com/public/user/5ed121ee3b482e7bfb0d1798/avatar" />);
		
		if (project.feasibility === PROJECT.FEASIBILITY.YES) {
			avatars.push(<Avatar sx={{ height: '25px', width: '25px' }} alt="Steve Janssens" src="https://api.lastbasic.com/public/user/5ef09301f206d2221061f349/avatar" />);
		}

		if (project.paid) {
			avatars.push(<Avatar sx={{ height: '25px', width: '25px' }} alt="Luis O'Cleiry" src="https://api.lastbasic.com/public/user/607553fe2aa4e6ae452ca800/avatar" />);
		}

		return (
			<>
				<div className={classes.projectTopStatus}>
					<div className={classes.projectTopStatusTitle}>Claps</div>
					<div
						className={classes.projectTopStatusAvatars}
						onClick={(e) => this.setState({ hoverProjectVisibility: e.currentTarget })}
					>
						<AvatarGroup max={10} spacing={'small'}>
							{avatars}
						</AvatarGroup>
						<div className={classes.projectTopStatusAvatarsNames}>
							{avatars.length === 1 ? 'Alex' : avatars.length} clapped
						</div>
					</div>
				</div>

				<Popover
					open={hoverProjectVisibility}
					anchorEl={hoverProjectVisibility}
					onClose={() => this.setState({ hoverProjectVisibility: null })}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					sx={{ marginTop: '5px' }}
				>
					<div className={classes.shareMessageBox} style={{ width: '270px' }}>
						<h5 className={classes.alternativeTitle}>Project visibility</h5>
						<div style={{ margin: '10px 0 15px 0' }}>
							<span style={{ color: '#3e3e3e' }}>
								<div className={classes.projectVisibilityCheckboxes}>
									<div className={classes.visibilityOption}>
										<div className={classes.visibilityCheckbox}>
											<div className={classes.visibilityCheckboxCheck}></div>
										</div>
										<div className={classes.visibilityLabel}>LastBasic&apos;s reviewers</div>
									</div>

									<div className={classes.visibilityOption}>
										<div className={classes.visibilityCheckbox} style={{ opacity: (project.paid ? 1 : 0.3) }}>
											{project.paid && <div className={classes.visibilityCheckboxCheck}></div>}
										</div>
										<div className={classes.visibilityLabel}>Experts (+500 users)</div>
									</div>

									<div className={classes.visibilityOption}>
										<div className={classes.visibilityCheckbox} style={{ opacity: 0.3 }}></div>
										<div className={classes.visibilityLabel}>Community (+6000 users)</div>
									</div>

									<div className={classes.visibilityOption}>
										<div className={classes.visibilityCheckbox} style={{ opacity: 0.3 }}></div>
										<div className={classes.visibilityLabel}>Partners/Investors (+50 users)</div>
									</div>
								</div>
							</span>
						</div>
						{project.feasibility !== PROJECT.FEASIBILITY.NO &&
							<div className={classes.bottomText}>
								<LockIcon sx={{ width: '12px', height: '12px' }} style={{marginRight: '4px', color: '#C7C7C7'}} />
								<span style={{fontSize: '13px'}}>
									{project.paid ? (
										<>Contact us to edit</>
									) : (
										<><Link style={{ color: 'inherit' }} to={'./unlock'}>Unlock project</Link> to edit</>
									)}
								</span>
							</div>
						}
					</div>
				</Popover>
			</>
		);
	}
}

ProjectView.propTypes = {
	userData: PropTypes.object,
	match: PropTypes.object,
	refetchProject: PropTypes.func,
};

ProjectView.defaultProps = {
	refetchProject() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(ProjectView));
