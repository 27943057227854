import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tag } from 'carbon-components-react';

import classes from './card-action.module.scss';

class AlternativeOption extends Component {
	constructor (props) {
		super(props);

		this.state = {
			onClickCommunity: false,
		};
	}

	render() {
		const { type, title, icon, onClick, soon } = this.props;
		// type: primary or secondary
		// title: string
		const { onClickCommunity } = this.state;

		return (
			<div className={classnames([classes.quickBlock, type === 'secondary' && classes.alternativeBlock])}
				onClick={soon ? () => this.setState({ onClickCommunity: !onClickCommunity }) : onClick}>
				{icon}
				<h6 style={type === 'secondary' ? {color: '#7145d6'} : {}}>{title}</h6>
				{soon &&
					<Tag type='blue' className={classnames([classes.alphaTag, onClickCommunity && classes.onShake])}>Soon</Tag>
				}
			</div>
		);
	}
}

AlternativeOption.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.object,
	onClick: PropTypes.func,
	soon: PropTypes.bool,
};

AlternativeOption.defaultProps = {
	type: 'optional',
	title: '',
	icon: {},
	onClick () {},
	soon: false,
};

export default AlternativeOption;
