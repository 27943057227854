import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'carbon-components-react';
import { CheckmarkFilled32, RadioButton32, ChevronRight32, ChevronLeft16 } from '@carbon/icons-react';

import Config from '../../config';
import withRouter from '../../components/route/with-router';

import classes from '../../pages/clients/internal-pages/start-new-project/new-project.module.scss';

class NewProjectOption extends Component {
	constructor(props) {
		super(props);

		this.state = {
			packageSelected: null,
			proceedToBriefing: false,
		};
	}

	componentDidMount() {
		document.title = 'LastBasic | New project';
		this.cleanParams();
	}

	cleanParams() {
		const { navigate } = this.props;

		return navigate({
			search: ''
		});
	}

	setSearchParams() {
		const { navigate } = this.props;
		const { packageSelected } = this.state;

		return navigate({
			search: '?' + new URLSearchParams({package: packageSelected.package, price: packageSelected.price}).toString()
		});
	}

	goBack() {
		this.setState({
			proceedToBriefing: false,
			packageSelected: null
		});
		this.cleanParams();
	}

	render() {
		const { packageSelected, proceedToBriefing } = this.state;

		return (
			<div className={classnames((!proceedToBriefing) && [classes.contentBlock, classes.frameBox])}>
				{(packageSelected && proceedToBriefing)
					?	<div className={classes.flexColumn}>
						<div className={classes.returnAction}>
							<ChevronLeft16 />
							<span onClick={() => this.goBack()}>Go back</span>
						</div>
						<div className={classes.bottomBtnContainer} style={{alignItems: 'end'}}>
							<div className={classes.selectedPackageBlock}>
								<div className={classes.flexColumn}>
									<span style={{textAlign: 'center'}}>You&apos;ve selected</span>
									<h4>{packageSelected.package === 'just-mechanics' ? 'Just mechanics' : 'With electronics'}</h4>
								</div>
								<div style={{ display: 'flex', alignItems: 'baseline'}}>
									<h2>{packageSelected.price}</h2>
								</div>
							</div>
						</div>
						<iframe
							title="Start a project"
							src={`${Config.typeform.forms.projectBriefing}#price=${packageSelected.price}&package=${packageSelected.package}`}
						/>
					</div>
					:	this.displayPackages()
				}
			</div>
		);
	}

	displayPackages() {
		const { packageSelected } = this.state;

		return (
			<div>
				<h4>Please select your ideal package</h4>
				<small className={classes.link} onClick={() => this.openVerifyDoc()}>Verify if your idea can be developed</small>
				<div className={classes.packagesList}>
					<div className={classes.package} onClick={() => this.setState({ packageSelected: {package: 'just-mechanics', price: '4495'} })}>
						<div className={classes.titleContainer}>
							<div>
								<h3 className={classes.packageTitle}>Just Mechanics</h3>
								<p>Starting at $4,495 USD</p>
							</div>
							{packageSelected && packageSelected.package === 'just-mechanics'
								?	<CheckmarkFilled32 className={classnames([classes.selectIcon, classes.successColor])} />
								:	<RadioButton32 className={classnames([classes.selectIcon, classes.pendingColor])} />
							}
						</div>
						<ul style={{ listStyleType: 'disc', margin: '16px'}}>
							<li><p>Perfect for prototypes</p></li>
							<li><p>Great if you don&apos;t need electronics</p></li>
						</ul>
						<img style={{width: '196px'}} src="https://lastbasic.com/app/themes/lastbasic/dist/images/just-mechanics.png" />
					</div>
					<div className={classes.package} onClick={() => this.setState({ packageSelected: {package: 'with-electronics', price: '7995'} })}>
						<div className={classes.titleContainer}>
							<div>
								<h3 className={classes.packageTitle}>With electronics</h3>
								<p>Starting at $7,995 USD</p>
							</div>
							{packageSelected && packageSelected.package === 'with-electronics'
								?	<CheckmarkFilled32 className={classnames([classes.selectIcon, classes.successColor])} />
								:	<RadioButton32 className={classnames([classes.selectIcon, classes.pendingColor])} />
							}
						</div>
						<ul style={{ listStyleType: 'disc', margin: '16px'}}>
							<li><p>Perfect for IoT</p></li>
							<li><p>Great if you need electronics</p></li>
						</ul>
						<img style={{width: '196px'}} src="https://lastbasic.com/app/themes/lastbasic/dist/images/inventionwshadow.png" />
					</div>
				</div>
				{packageSelected &&
					<div className={classes.bottomBtnContainer}>
						<Button
							renderIcon={ChevronRight32}
							kind='primary'
							className={classes.btn}
							onClick={() => this.setState({proceedToBriefing: true}, () => this.setSearchParams())}
						>
								Continue to validation
						</Button>
					</div>
				}
			</div>
		);
	}

	openVerifyDoc() {
		window.Froged('doc', '628f5ce56c16b7c80b2ad819');
	}
}

NewProjectOption.propTypes = {
	navigate: PropTypes.func.isRequired,
};

export default withRouter(NewProjectOption);
