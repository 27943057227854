import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import classes from './elements.module.scss';

const theme = createTheme({
	palette: {
	  primary: {
			main: '#0062ff',
	  },
	  secondary: {
			main: '#3e3e3e',
	  }
	},
});

class LBButton extends Component {
	render() {
		const { title, type, disabled, onClick, style } = this.props;

		return (
			<ThemeProvider theme={theme}>
				<Button
					variant={type === 'primary' ? 'contained' : ''}
					color={type}
					className={classnames(classes.button, type === 'secondary' && classes.secondary)}
					/* style={type === 'secondary' ? {textTransform: 'lowercase'} : width ? {width: width} : {}} */
					style={style}
					disabled={disabled}
					onClick={onClick}
				>
					{title}
				</Button>
			</ThemeProvider>
		);
	}
}

LBButton.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	width: PropTypes.string,
	style: PropTypes.object,
};

LBButton.defaultProps = {
};

export default LBButton;
