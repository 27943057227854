import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';

import dateUtils from '../../utils/dates';

import { SkeletonPlaceholder } from 'carbon-components-react';

import classes from './timeline.module.scss';

import validationTimeline from './configurations/timelines.json';

const WEEK_SIZE = 100;

export default function Timeline({ project, locked, showToday, showLinks, forceShowTimeline, style, showEta }) {
	const [maxDatesSizePixels, setMaxDatesSizePixels] = useState(0);
	const [maxTimelineSizePixels, setMaxTimelineSizePixels] = useState(0);
	const [evensNumber, setEvensNumber] = useState(0);
	const [numberOfWeeksNumbers, setNumberOfWeeksNumbers] = useState(0);
	const [deliveryTime, setDeliveryTime] = useState(null);

	const [todayPositionWeek, setTodayPositionWeek] = useState(null);

	const [items, setItems] = useState(null);

	const [timelineRef] = useState(createRef());

	if (!project) {
		return (
			<div className={classes.timeline}>
				<SkeletonPlaceholder style={{ width: '100%', height: '250px' }} />
			</div>
		);
	}

	useEffect(() => {
		if (!project) return;

		const timelineItems = validationTimeline[forceShowTimeline || project.type || 'prototype'];

		const items = forceShowTimeline ? timelineItems : positionateItems(project, timelineItems);
		setItems(items);

		let maxSize = 0;
		let manyWeeks = 0;
		for (const item of items) {
			const size = (item.startDate * WEEK_SIZE + 20) + item.dateSize * WEEK_SIZE;
			if (maxSize < size) maxSize = size + 60;

			const weekSize = item.startDate + item.dateSize;
			if (manyWeeks < (weekSize)) manyWeeks = weekSize;
		}

		const maxDatesSizesCalc = manyWeeks;

		setMaxDatesSizePixels((maxDatesSizesCalc > 10 ? (maxDatesSizesCalc + 1) * WEEK_SIZE : 11 * WEEK_SIZE) + 30);
		setMaxTimelineSizePixels((items.length * 33) + 50);
		setEvensNumber(manyWeeks + 1);
		setNumberOfWeeksNumbers((manyWeeks < 10 ? manyWeeks + 10 : manyWeeks + 2));

		// Calculate today's position. Week 0 is project creation
		const projectCreationDate = project.createdAt;
		const positionInWeeks = calculatePositionInWeeks(new Date(parseInt(projectCreationDate)), new Date());
		setTodayPositionWeek(positionInWeeks);

		// Must-fix
		/* setTimeout(() => {
			const leftPx = calculateWeekPosition(positionInWeeks);
			const timelinePosition = leftPx - 110;
			timelineRef.current.scrollLeft = (timelinePosition < 0 ? 0 : timelinePosition);
		}, 1000);*/

	}, [project]);

	function calculatePositionInWeeks(referenceDate, date) {
		const diffTime = Math.abs(date - referenceDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

		const oneDayOfWeek = 1 / 7;
		const position = oneDayOfWeek * diffDays - oneDayOfWeek;

		return position;
	}

	function generateDates(numberOfDates = 10, separationInPixels = WEEK_SIZE) {
		const dates = [...Array(parseInt(numberOfDates)).keys()];

		return dates.map(date => {
			const unit = date + 1;

			return (
				<div key={unit} className={classes.date} style={{ left: (date * separationInPixels) + 'px' }}>
					<div className={classes.dateContent + ' ' + ((todayPositionWeek >= date &&  todayPositionWeek < date + 1) && showToday ? classes.selectedDate : '')}>Week {unit}</div>
				</div>
			);
		});
	}

	function generateRow(content) {
		return <div className={classes.row}>{content}</div>;
	}

	function generateRows(contents = []) {
		if (!contents) return;

		const render = contents.map(content => {
			return generateRow(generateItem(content.title, content.emoji, content.startDate, content.dateSize, content.tag));
		});
		return render;
	}

	function generateEvens(numberOfWeeks = 0) {
		let size = parseInt(numberOfWeeks / 1);
		size = (size < 10 ? 11 : size);

		const weeks = [...Array(size).keys()];
		return weeks.map(week => {
			if (week % 2 === 0) {
				return <div key={week} className={classes.evenWeek} style={{ left: (week * WEEK_SIZE * 1 + 20) + 'px', width: 1 * WEEK_SIZE + 'px'}} />;
			} else {
				return <></>;
			}
		});
	}

	function calculateWeekPosition(week, bubble = false) {
		if (week > numberOfWeeksNumbers) return false;

		let position = week * WEEK_SIZE + (bubble ? - 4.5 : 0) + 20 + 'px';
		return position;
	}

	function getStateDates(project, state) {
		const today = new Date();
		const startDate = project?.states?.[state]?.startDate && new Date(parseInt(project.states[state].startDate));
		const endDate = project?.states?.[state]?.endDate && new Date(parseInt(project.states[state].endDate)) || (startDate && today.setDate(today.getDate() + 3));

		let dateSize;

		if (startDate && endDate) {
			dateSize = calculatePositionInWeeks(startDate, endDate);
		}

		return {
			startDate,
			endDate,
			dateSize,
		};
	}

	function getItemLastDay(items, name) {
		if (!name) name = items[items.length - 1].name;
		const item = items.find(item => item.name === name);
		if (!item) return null;

		// Add number of weeks in dateSize to startDate
		if (typeof item.startDateTime !== Date) {
			item.startDateTime = new Date(item.startDateTime);
		}

		return new Date(item.startDateTime.setDate(item.startDateTime.getDate() + item.dateSize * 7));
	}

	function filterItems(project, items) {
		const activeContests = project.contests.filter(c => c.active).map(c => c.type);

		const newItems = items.filter(item => {
			if(['validation', 'onboarding', 'delivery'].includes(item.name)) return true;
			return activeContests.includes(item.name);
		});

		return newItems;
	}

	function positionateItems(project, items = []) {
		items = filterItems(project, items);

		const positionatedItems = [];
		const projectCreatedAt = project && project.createdAt && new Date(parseInt(project.createdAt));

		const today = new Date();
		const paidDateTime = project?.setupInvoice?.paidAt && new Date(parseInt(project.setupInvoice.paidAt));
		const paidDate = paidDateTime || today.setDate(today.getDate() + 1);

		let hasDelivery = false;

		for (const item of items) {
			const name = item.name;

			if (name === 'validation') {
				item.startDate = 0;
				item.dateSize = 1.1;
				item.startDateTime = projectCreatedAt;
				positionatedItems.push(item);
			}

			if (name === 'onboarding') {
				item.startDateTime = paidDate || new Date();
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);
				item.dateSize = 1.2;
				positionatedItems.push(item);
			}

			if (name === 'sketch') {
				const stateDates = getStateDates(project, 'sketch_contest');
				item.startDateTime = stateDates.startDate || getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				item.dateSize = stateDates.dateSize || item.dateSize;

				positionatedItems.push(item);
			}

			if (name === 'model') {
				const stateDates = getStateDates(project, 'model_contest');

				item.startDateTime = stateDates.startDate || getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				item.dateSize = stateDates.dateSize || item.dateSize;

				positionatedItems.push(item);
			}

			if (name === 'electrical') {
				const stateDates = getStateDates(project, 'electrical_contest');

				item.startDateTime = stateDates.startDate || getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				item.dateSize = stateDates.dateSize || item.dateSize;

				positionatedItems.push(item);
			}

			if (name === 'mechanical') {
				const stateDates = getStateDates(project, 'mechanical_contest');

				item.startDateTime = stateDates.startDate || getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				item.dateSize = stateDates.dateSize || item.dateSize;

				positionatedItems.push(item);
			}

			if (name === 'prototyping') {
				const stateDates = getStateDates(project, 'prototyping_contest');

				item.startDateTime = stateDates.startDate || getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				item.dateSize = stateDates.dateSize || item.dateSize;

				positionatedItems.push(item);

				hasDelivery = true;
			}

			if (name === 'delivery' && hasDelivery) {
				item.startDateTime = getItemLastDay(positionatedItems);
				item.startDate = calculatePositionInWeeks(projectCreatedAt, item.startDateTime);

				positionatedItems.push(item);
			}
		}

		// Get last day to be a estimated delivery
		let calculatedDeliveryTime;
		if (positionatedItems.length && hasDelivery) {
			const lastItem = positionatedItems[positionatedItems.length - 1];
			if (lastItem.name !== 'validation') {
				calculatedDeliveryTime = getItemLastDay(positionatedItems, lastItem.name);
			}
		}

		setDeliveryTime(calculatedDeliveryTime);

		return positionatedItems;
	}

	function generateItem(title, emoji, date, dateSize, tag) {
		return (
			<>
				<div className={classes.item} style={{ left: ((date) * WEEK_SIZE + 20)+ 'px', width: ((dateSize) * WEEK_SIZE) + 'px', cursor: (showLinks ? 'pointer' : 'inherit') }}></div>

				<div className={classes.itemContents}>
					<div className={classes.itemContent} style={{ left: ((date) * WEEK_SIZE + 20)+ 'px' }}>
						<div className={classes.itemContentTextWrapper}>
							<div className={classes.itemContentEmoji}>
								<div className={classes.itemEmojiWrapper}>
									<div className={classes.itemEmojiOuter}>
										{emoji || '?'}
									</div>
								</div>
							</div>
							<div className={classes.itemContentText}>{title}</div>
						</div>

						{ tag &&
							<div className={classes.itemContentTagWrap}>
								<div className={classes.itemContentTagOuter}>
									<div className={classes.itemContentTag} style={{ color: tag.color, background: tag.background }}>{tag.title}</div>
								</div>
							</div>
						}
					</div>
				</div>
			</>
		);
	}

	return (
		<div style={{ margin: '16px 0' }}>
			<div ref={timelineRef} className={classes.timeline} style={{ minHeight: maxTimelineSizePixels, ...(locked ? { overflowX: 'hidden' } : {}), ...style }} >
				{ locked &&
					<div className={classes.locked}>
						<div className={classes.lockedButton}>Set up project&apos;s timeline</div>
					</div>
				}
				<div className={classes.verticality}>
					{/* Gray backgrounds */}
					{ generateEvens(evensNumber) }

					{/* Today line indicator */}
					{ showToday && calculateWeekPosition(todayPositionWeek) && <div className={classes.todayLine} style={{ left: calculateWeekPosition(todayPositionWeek) }} /> }
				</div>

				<div className={classes.horizontality}>
					{ generateRows(items)}
				</div>

				<div className={classes.dateSection} style={{ width: `${maxDatesSizePixels}px`}}>
					<div className={classes.dateWrapper}>
						{ generateDates(numberOfWeeksNumbers, WEEK_SIZE) }
					</div>

					{ showToday && calculateWeekPosition(todayPositionWeek) && <div className={classes.bubble} style={{ left: calculateWeekPosition(todayPositionWeek, true) }}></div> }
				</div>
			</div>

			{ showEta && deliveryTime &&
				<div className={classes.bottomArea}>
					<div className={classes.eta}><span style={{ opacity: 0.7, display: 'none' }}>📦</span> Estimated delivery: {dateUtils.prettifyDate(deliveryTime)}</div>
				</div>
			}
		</div>
	);
}

Timeline.propTypes = {
	project: PropTypes.object,
	locked: PropTypes.bool,
	showToday: PropTypes.bool,
	showLinks: PropTypes.bool,
	forceShowTimeline: PropTypes.string,
	style: PropTypes.object,
	showEta: PropTypes.bool,
};

Timeline.defaultProps = {
	project: null,
	locked: false,
	showToday: true,
	showLinks: false,
	forceShowTimeline: null,
	style: {},
	showEta: true,
};
