import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tag } from 'carbon-components-react';
import { ChevronRight16 } from '@carbon/icons-react';

import classes from './case-study.module.scss';

class CaseStudy extends Component {

	render() {
		const { name, category, description, img, urlLink } = this.props;
		return (
			<div className={classes.caseStudy}>
				<div className={classes.topContainer}>
					<small className={classes.littleText}>Case study</small>
					<Tag type='gray' className={classes.tag}>{category}</Tag>
				</div>
				<h4>{name}</h4>
				<img src={img} className={classes.caseImg} />
				<div className={classes.bottomContainer}>
					<p>{description}</p>
					<div className={classes.actionBox}
						style={{ marginTop: '26px' }}
						onClick={() => window.open(urlLink)} target='_blank'
					>
						<h5 className={classes.link}>Open case study</h5>
						<ChevronRight16 className={classes.link} />
					</div>
				</div>
			</div>
		);
	}
}

CaseStudy.defaultProps = {
	name: '',
	category: '',
	description: '',
	urlLink: '',
	img: '',
};

CaseStudy.propTypes = {
	name: PropTypes.string,
	category: PropTypes.string,
	description: PropTypes.string,
	urlLink: PropTypes.string,
	img: PropTypes.string,
};

export default CaseStudy;
