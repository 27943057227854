import { DocumentTasks20, Download20, DataCheck20, Template20 } from '@carbon/icons-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PreviewFileModal from '../../../../../components/modals/preview-file-modal';
import { PROJECT } from '../../../../../constants';
import KEYS from '../../../../../glossary';

import classes from './tabs.module.scss';

const guidelines = [
	{
		contestType: PROJECT.CONTEST_TYPES.SKETCH,
		name: 'Sketch contest guide',
		url: 'https://drive.google.com/file/d/1sgdqaYjFsNSSLRvxDSREWK91uKvwZBoC/preview',
	},
	{
		contestType: PROJECT.CONTEST_TYPES.MODEL,
		name: '3D Model contest guide',
		url: 'https://drive.google.com/file/d/1CzKwZAvtC8XZj16gOgEmx6wpRqrdrFY1/preview',
	},
	{
		contestType: PROJECT.CONTEST_TYPES.ELECTRICAL,
		name: 'Electrical engineering contest guide',
		url: 'https://drive.google.com/file/d/1S0ZYBPmtkYLYgRswz34FnsejYBRb6-OV/preview',
		integrationUrl: 'https://drive.google.com/file/d/1lC-5uvl51Y-dzcmWyd1zaGkLpITZLOx7/preview',
	},
	{
		contestType: PROJECT.CONTEST_TYPES.MECHANICAL,
		name: 'Mechanical engineering contest guide',
		url: 'https://drive.google.com/file/d/1CzAUmqAVoVM0gmpfc6vvLolk9Sw1OfxP/preview',
		integrationUrl: 'https://drive.google.com/file/d/1jmPQ_sPNQv4ifIynA_Y2CFLXwyupnKsf/preview',
	},
	{
		contestType: PROJECT.CONTEST_TYPES.PROTOTYPING,
		name: 'Prototyping contest guide',
		url: 'https://drive.google.com/file/d/1I9ZfrUPSN6y6b7rvBZ4qGloJdfB0s18c/preview',
	},
];

const templates = [
	{
		contestType: 'sketch',
		url: 'https://drive.google.com/file/d/124NApvsUH5KeS9dUluctjjrf_TMiBlEC/preview',
	},
	{
		contestType: 'model',
		url: 'https://drive.google.com/file/d/1HUfK8Pc5FBohHH9Z6ZBBR5sdWTWYGDbL/preview',
	},
	{
		contestType: 'electrical',
		url: 'https://drive.google.com/file/d/1Lijg3C2QMVPiHuu_gCOeGAvObU20P76R/preview',
		otherUrls: [
			{   name: 'Schematic template',
				url: 'https://drive.google.com/file/d/1UyypQBloMlituR4VmEFm5u0WEvvT--Xs/preview',
			},
			{   name: 'Firmware control guide template',
				url: 'https://drive.google.com/file/d/17obmMw1ke07VKAWns4mZgACxvGZ_LRE6/preview',
			},
		],
	},
	{
		contestType: 'mechanical',
		url: 'https://drive.google.com/file/d/1T_gJxXvHMG7iDwvtUHurpowQjwr6Ti3R/preview',
		otherUrls: [
			{   name: 'SOP template',
				url: 'https://drive.google.com/file/d/1soyklfGTComAANQK6tYG43267KThxZ51/preview',
			},
			{   name: 'CMF template',
				url: 'https://drive.google.com/file/d/1AgwZ_GlQAFmhZXXCMZXxBw5vmQUCE75U/preview',
			},
			{   name: 'CAD view template',
				url: 'https://drive.google.com/file/d/1dAHuHPGIpQqoSZREGMPmlHMRwcnpr4Fk/preview',
			},
		],
	},
	{
		contestType: 'prototyping',
		url: 'https://drive.google.com/file/d/1H_iPp-ysh2vHm3sEpg7e5cED8y4Manik/preview',
		otherUrls: [
			{   name: 'User guide template',
				url: 'https://drive.google.com/file/d/1_t8AK2wAxgAXU5B18udkrLOu3I1xCIq8/preview',
			},
			{   name: 'Build document template',
				url: 'https://drive.google.com/file/d/1t9fEe1a7iNCOtjMec8Tzx5eq6o2gxljq/preview',
			},
		],
	},
];

class GuidelinesTab extends Component {
	constructor (props) {
		super(props);

		this.state = {
			previewFile: null,
		};
	}

	onOpenPreviewFile(file, url) {
		this.setState({ previewFile: { src: url } });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	showActiveGuideline(guidelines, lastContestType) {
		const guideline = guidelines.filter(guide => guide.contestType === lastContestType);

		return (
			<>
				<div className={classes.elementBox}>
					<div className={classes.naming}>
						<DocumentTasks20 />
						<p>{KEYS.contest_guide_file}</p>
					</div>
					<div className={classnames([classes.actions, classes.fileAction])}>
						<span className={classes.link} onClick={() => this.onOpenPreviewFile(guideline[0], guideline[0].url)}>{KEYS.open_file}</span>
						<a href={guideline[0].url} target="_blank" rel="noopener noreferrer"><Download20 /></a>
					</div>
				</div>
				{guideline[0].integrationUrl !== undefined &&
					<div className={classes.elementBox} key={guideline[0].name}>
						<div className={classes.naming}>
							<DataCheck20 />
							<p>{KEYS.integration_guide_file}</p>
						</div>
						<div className={classnames([classes.actions, classes.fileAction])}>
							<span className={classes.link} onClick={() => this.onOpenPreviewFile(guideline[0], guideline[0].integrationUrl)}>{KEYS.open_file}</span>
							<a href={guideline[0].integrationUrl} target="_blank" rel="noopener noreferrer"><Download20 /></a>
						</div>
					</div>
				}
			</>
		);
	}

	showActiveTemplates(templates, lastContestType) {
		const template = templates.filter(template => template.contestType === lastContestType);
		const otherTemplates = template[0].otherUrls;

		return (
			<>
				<div className={classes.elementBox}>
					<div className={classes.naming}>
						<Template20 />
						<p>{KEYS.contest_template_file}</p>
					</div>
					<div className={classnames([classes.actions, classes.fileAction])}>
						<span className={classes.link} onClick={() => this.onOpenPreviewFile(template[0], template[0].url)}>{KEYS.open_file}</span>
						<a href={template[0].url} target="_blank" rel="noopener noreferrer"><Download20 /></a>
					</div>
				</div>
				{(otherTemplates !== undefined) && (
					<>
						{otherTemplates.map((t, i) => (
							<div className={classes.elementBox} key={i}>
								<div className={classes.naming}>
									<Template20 />
									<p>{t.name}</p>
								</div>
								<div className={classnames([classes.actions, classes.fileAction])}>
									<span className={classes.link} onClick={() => this.onOpenPreviewFile(t, t.url)}>{KEYS.open_file}</span>
									<a href={t.url} target="_blank" rel="noopener noreferrer"><Download20 /></a>
								</div>
							</div>
						))}
					</>
				)}
			</>
		);
	}

	render() {
		const { project, statesExpertIsParticipating } = this.props;
		const { previewFile } = this.state;
		const newState = statesExpertIsParticipating.slice(-1)[0] || project.state;
		const lastContestType = PROJECT.getContestTypeByState(newState);

		return (
			<div className={classes.tabContentContainer}>
				<h3 className={classes.title}>Download your phase templates to get on with it</h3>
				<div className={classes.materialsContent}>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{PROJECT.stateToStringShort(newState)} guidelines</h4>
							<p>{KEYS.guidelines_text}</p>
						</div>
						{this.showActiveGuideline(guidelines, lastContestType)}
					</div>
					<div className={classes.contentBlock}>
						<div className={classes.contentTitle}>
							<h4>{PROJECT.stateToStringShort(newState)} templates</h4>
							<p>{KEYS.templates_text}</p>
						</div>
						{this.showActiveTemplates(templates, lastContestType)}
					</div>
					<PreviewFileModal
						open={!!previewFile}
						onRequestClose={() => this.onClosePreviewFile()}
						src={previewFile && previewFile.src}
					/>
				</div>
			</div>
		);
	}
}

GuidelinesTab.defaultProps = {
	project: {},
	statesExpertIsParticipating: [],
	user: {},
};

GuidelinesTab.propTypes = {
	project: PropTypes.object.isRequired,
	statesExpertIsParticipating: PropTypes.Array,
	user: PropTypes.object.isRequired,
};

export default GuidelinesTab;
