const LocalStore = window.localStorage;

export const SELECTED_PROJECT = 'SELECTED_PROJECT';

const getProject = () => {
	try {
		let strProject = LocalStore.getItem(SELECTED_PROJECT);
		if (strProject) return JSON.parse(strProject);
		
		return null;
	} catch (error) {
		return null;
	}
};

const storeProject = (project) => {
	try {
		LocalStore.setItem(SELECTED_PROJECT, JSON.stringify(project));
		return true;
	} catch (error) {
		return false;
	}
};

const removeProject = () => {
	try {
		LocalStore.removeItem(SELECTED_PROJECT);
		return true;
	} catch (error) {
		return false;
	}
};

export default {
	getProject,
	storeProject,
	removeProject,
};
