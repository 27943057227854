import React from 'react';
import PropTypes from 'prop-types';

const SketchingIconAlt = (props) => {
	const { size, active, color, type } = props;

	return (
		<svg id="sketch-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color !== '' || type === 'color' ? '#02B2DA' : active || type === 'white' ? '#fff' : '#C7C7C7'}>
			<path d="M29.707,19.293l-3-3a.9994.9994,0,0,0-1.414,0L16,25.5859V30h4.4141l9.2929-9.293A.9994.9994,0,0,0,29.707,19.293ZM19.5859,28H18V26.4141l5-5L24.5859,23ZM26,21.5859,24.4141,20,26,18.4141,27.5859,20Z"/>
			<path d="M17,15H11a3,3,0,0,0-3,3v1h2V18a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v1h2V18A3,3,0,0,0,17,15Z"/>
			<path d="M14,14a4,4,0,1,0-4-4A4,4,0,0,0,14,14Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,14,8Z"/>
			<path d="M12,30H6a2.0021,2.0021,0,0,1-2-2V4A2.0021,2.0021,0,0,1,6,2H22a2.0021,2.0021,0,0,1,2,2V14H22V4H6V28h6Z"/>
		</svg>
	);
};

SketchingIconAlt.propTypes = {
	active: PropTypes.bool,
	size: PropTypes.number,
	color: PropTypes.string,
	type: PropTypes.string,
};

SketchingIconAlt.defaultProps = {
	active: false,
	size: 20,
	color: '',
	type: 'white',
};

export default SketchingIconAlt;