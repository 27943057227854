import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import Grid from '@mui/material/Grid';
import { Button, InlineNotification, TextInput } from 'carbon-components-react';

import withRouter from '../../../components/route/with-router';
import { CREATE_PASSWORD_MUTATION } from '../../../providers/mutations';
import * as actions from '../../../reducers/user/actions';

import classes from './pages.module.scss';

const LB_LOGO_URL = '/assets/images/lastbasic-logo.png';

class CreatePasswordPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorForm: '',
			password: '',
			repeatPassword: ''
		};
	}

	onChangePassword(event) {
		this.setState({ password: event.target.value });
	}

	onChangeRepeatPassword(event) {
		this.setState({ repeatPassword: event.target.value });
	}

	async onSubmit(e, createPasswordMutation) {
		e.preventDefault();
		
		const { createPassword, searchParams } = this.props;
		const { password, repeatPassword } = this.state;
		
		const token = searchParams.get('token');
		if (!token) return false;

		// Validate password
		if (password !== repeatPassword) {
			this.setState({ errorForm: 'Passwords must be the same' });
			return;
		} else if (password.length <= 5) {
			this.setState({ errorForm: 'Password must be at least 6 chars' });
			return;
		} else {
			this.setState({ errorForm: '' });
		}

		return createPassword(createPasswordMutation, { token, password });
	}
	
	render() {
		const { searchParams } = this.props;
		const { errorForm, password, repeatPassword } = this.state;
		
		const token = searchParams.get('token');
		const welcomeBeta = searchParams.get('welcomeBeta');
		if (!token) return <Navigate to="/" />;
		
		return (
			<div className={classes.root}>
				<Mutation mutation={CREATE_PASSWORD_MUTATION}>
					{(createPasswordMutation, { data, error, loading }) => (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
							className={classes.parentGrid}
						>
							<div className={classes.brandContainer}>
								<img className={classes.logo} src={LB_LOGO_URL} alt="LastBasic logo" />
							</div>
							<Grid item>
								<div className={classes.cardContent}>
									<h2 className={classes.title}>
										Create new password
									</h2>
									{error && (
										<InlineNotification
											className={classes.notification}
											kind="error"
											title=""
											subtitle={(error.graphQLErrors && error.graphQLErrors.length)
												? error.graphQLErrors[0].message
												: (error.networkError)
													? error.networkError.message
													: error.mesage}
											hideCloseButton={true}
											lowContrast={true}
										/>
									)}
									{errorForm && (
										<InlineNotification
											className={classes.notification}
											kind="error"
											title=""
											subtitle={errorForm}
											hideCloseButton={true}
											lowContrast={true}
										/>
									)}
									{data && (
										<Navigate to="/login?passwordCreated" />
									)}
									{welcomeBeta && (
										<div style={{ fontSize: '14px', marginBottom: '25px'}}>
											Welcome to the LastBasic Beta Test!
											<br /><br />
											Please create your credentials here for access to our new platform.
											<br /><br />
											<ul>
												<li>
													- Please use the <strong>same email address</strong> associated with LB account.
												</li>
												<li>
													- Your <strong>password</strong> must have <strong>between 6 and 20 characters (numbers and letters)</strong>
												</li>
											</ul>
											<br />
											These credentials will stay active during beta testing as well as when the platform goes officially LIVE soon.
											<br /><br />
											Thank you for being an important part of the LB Community.
										</div>
									)}
									<form className={classes.form} onSubmit={(e) => this.onSubmit(e, createPasswordMutation)}>
										<TextInput.PasswordInput
											id="current-password-input"
											className={classes.textInput}
											labelText="Password"
											type="password"
											value={password}
											autoComplete="current-password"
											onChange={(e) => this.onChangePassword(e)}
											required
										/>
										<TextInput.PasswordInput
											id="repeat-password-input"
											className={classes.textInput}
											labelText="Repeat Password"
											type="password"
											value={repeatPassword}
											autoComplete="repeat-password"
											onChange={(e) => this.onChangeRepeatPassword(e)}
											required
										/>
										<Button
											className={classes.submitButton}
											type="submit"
											disabled={loading}>
											{loading ? 'Loading...' : 'Submit'}
										</Button>
									</form>
								</div>
							</Grid>
						</Grid>
					)}
				</Mutation>
			</div>
		);
	}
}

CreatePasswordPage.propTypes = {
	createPassword: PropTypes.func.isRequired,
	searchParams: PropTypes.object.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string,
	}),
};

CreatePasswordPage.defaultProps = {
	createPassword() {},
	user: {},
};

const mapStateToProps = ({ user }) => ({
	user,
});

const mapDispatchToProps = dispatch => ({
	createPassword: (mutation, data) => dispatch(actions.createPassword(mutation, data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(CreatePasswordPage));
