import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, InlineNotification } from 'carbon-components-react';
import ContestSetupQuestions from '../project/setup-questions/contest-setup-questions';

import { PROJECT } from '../../constants';
import { FormUtils } from '../../utils';

import classes from './modals.module.scss';

class ContestSetUpModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
		};
	}

	render() {
		const { open, project, nextContestToOpen, onRequestClose, onRequestSubmit } = this.props;
		const contestObject = PROJECT.getContestByState(project, nextContestToOpen);
		const setupQuestions = contestObject !== undefined && contestObject.setupQuestions;
		const doesSetupQuestionsHasAnswer = setupQuestions && FormUtils.isAtLeastOneQuestionAnswered(setupQuestions);

		return (
			<Modal
				open={open}
				passiveModal={true}
				modalLabel={PROJECT.stateToString(nextContestToOpen)}
				modalHeading={'Contest set up'}
				onRequestSubmit={onRequestSubmit}
				onRequestClose={onRequestClose}
				aria-label="Contest set up modal"
			>
				<div>
					{!doesSetupQuestionsHasAnswer &&
						<InlineNotification
							className={classes.notification}
							style={{ marginTop: '0'}}
							kind="warning"
							lowContrast
							hideCloseButton={false}
							title="Important information"
							subtitle="It's important you help us to set up your next contest in order to have a better understanding of what you are looking for."
						/>
					}
					<div className={classes.projectStatus}>
						<p>In order to start preparing the contest for <strong>{PROJECT.stateToString(nextContestToOpen)}</strong>, answer these questions:</p>
						<div className={classes.projectStatusBox} style={{ margin: '16px 0'}}>
							{doesSetupQuestionsHasAnswer
								?	<InlineNotification
									kind="info"
									className={classes.notification}
									lowContrast
									hideCloseButton
									iconDescription="Info"
									subtitle={<span>You already fill this options.</span>}
									title=""
								/>
								:	<ContestSetupQuestions
									contest={contestObject}
									onQuestionsAnswered={() => onRequestClose()}
								/>
							}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

ContestSetUpModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	userData: PropTypes.object,
	nextContestToOpen: PropTypes.string,
	onRequestSubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

ContestSetUpModal.defaultProps = {
	open: false,
	project: {},
	userData: {},
	nextContestToOpen: '',
	onRequestSubmit() {},
	onRequestClose() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ContestSetUpModal);