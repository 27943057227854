import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SkeletonText, InlineNotification } from 'carbon-components-react';
import { Notification20, Information16 } from '@carbon/icons-react';

import { GET_PROJECT_UPDATES } from '../../../../../providers/queries';
import KEYS from '../../../../../glossary';
import { PROJECT } from '../../../../../constants';
import ErrorBoundaryQuery from '../../../../../components/graphql/queries-with-errors';

import classes from './tabs.module.scss';
import { dates } from '../../../../../utils';

class UpdatesTab extends Component {
	render() {
		const { projectId } = this.props;

		return (
			<ErrorBoundaryQuery query={GET_PROJECT_UPDATES} variables={{ id: projectId }}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<div className={classes.tabContentContainer}>
								<SkeletonText className={classes.subtitle} style={{ width: '300px' }} />
								<div className={classes.contentBlock}>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText style={{ height: '50px' }} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<div className={classes.tabContentContainer}>
								<h3 className={classes.title}>{KEYS.tab_updates}</h3>
								<InlineNotification
									className={classes.notification}
									kind="error"
									title=""
									lowContrast
									hideCloseButton
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							</div>
						);
					}

					const project = data && data.project;
					const updates = project.updates;
					const sortedUpdates = updates.slice().sort((a, b) => b.createdAt - a.createdAt);

					return (
						<div className={classes.tabContentContainer}>
							<h3 className={classes.title}>{KEYS.tab_updates}</h3>
							<div className={classes.materialsContent}>
								<div className={classes.contentBlock}>
									<div className={classes.contentTitle}>
										<h4>Project roadmap</h4>
									</div>
									{updates && (
										<div className={classes.updatesContentContainer}>
											{sortedUpdates.map((update, i) => (
												<div className={classes.updateBox} key={i}>
													<div className={classes.naming}>
														<Notification20 />
														<p>
															{(update.details !== null && Object.keys(update.details).includes('title')) ? update.details.title : PROJECT.getUpdateString(update.type)}
															{update.details !== null
																?   Object.keys(update.details).includes('title')
																	?   `: ${update.details.description}`
																	:   `: ${PROJECT.getUpdatesDetails(Object.values(update.details)[0])}`
																:   ''
															}
														</p>
													</div>
													<div className={classes.actions}>
														<strong>{dates.formattedNewDate(update.createdAt)}</strong>
													</div>
												</div>
											))}
										</div>
									)}
									{updates.length === 0 && (
										<div className={classes.updates}>
											<Information16 />
											<small>{KEYS.project_updates_empty}</small>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}
}

UpdatesTab.defaultProps = {
	projectId: '',
};

UpdatesTab.propTypes = {
	projectId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(UpdatesTab);
