import { PROJECT, PROPOSAL } from '.';
// eslint-disable-next-line no-unused-vars
import { dates } from './../utils';
import KEYS from '../glossary';

export default {
	TYPES: PROJECT.CONTEST_TYPES,
	PROJECT_PHASE: PROJECT.STATE,
	MAX_VALID_PROPOSALS: {
		SKETCH: 8,
		MODEL: 5,
		ELECTRICAL: 2,
		MECHANICAL: 2,
		PROTOTYPING: 2,
	},
	MAX_VALID_PROPOSALS_PER_USER: {
		SKETCH: 8,
		MODEL: 5,
		ELECTRICAL: 2,
		MECHANICAL: 2,
		PROTOTYPING: 2,
	},
	CONTEST_PRIZES: {
		SKETCH: '200 EUR',
		MODEL: '300 EUR',
		ELECTRICAL: '500 EUR',
		MECHANICAL: '500 EUR',
		PROTOTYPING: '500 EUR',
	},
	CONTEST_REWARDS: {
		SKETCH: '20 EUR',
		MODEL: '30 EUR',
		ELECTRICAL: '40 EUR',
		MECHANICAL: '40 EUR',
		PROTOTYPING: '40 EUR',
	},
	CONTEST_CREDITS: {
		SKETCH: '20 credits',
		MODEL: '30 credits',
		ELECTRICAL: '40 credits',
		MECHANICAL: '40 credits',
		PROTOTYPING: '0 credits',
	},
	CONTEST_CARD_CONFIRMATION: {
		DRAFT: 'draft',
		PENDING: 'pending',
		NO: 'no',
		YES: 'yes',
	},
	CONTEST_SETUP_QUESTION_TYPES: {
		TEXT: 'text',
		OPTIONS: 'options',
	},
	CONTEST_STEP_STATUSES: {
		PENDING: 'pending',
		IN_PROGRESS: 'in_progress',
		DONE: 'done',
	},
	CONTEST_STEP_UPDATE_TYPES: {
		INFORMATION: 'information',
		ALERT: 'alert',
	},
	CONTEST_STEP_TYPES: {
		INFORMATIVE: 'informative',
		CONTEST: 'contest',
	},
	APPROVED_STATUS: {
		PENDING: 'pending',
		YES: 'yes',
		NO: 'no',
	},
	getContestMaxValidProposals: function (contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return this.MAX_VALID_PROPOSALS.SKETCH;
			case this.TYPES.MODEL:
				return this.MAX_VALID_PROPOSALS.MODEL;
			case this.TYPES.ELECTRICAL:
				return this.MAX_VALID_PROPOSALS.ELECTRICAL;
			case this.TYPES.MECHANICAL:
				return this.MAX_VALID_PROPOSALS.MECHANICAL;
			case this.TYPES.PROTOTYPING:
				return this.MAX_VALID_PROPOSALS.PROTOTYPING;
			default:
				return this.MAX_VALID_PROPOSALS.SKETCH;
		}
	},
	getContestPrizes: function (contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return this.CONTEST_PRIZES.SKETCH;
			case this.TYPES.MODEL:
				return this.CONTEST_PRIZES.MODEL;
			case this.TYPES.ELECTRICAL:
				return this.CONTEST_PRIZES.ELECTRICAL;
			case this.TYPES.MECHANICAL:
				return this.CONTEST_PRIZES.MECHANICAL;
			case this.TYPES.PROTOTYPING:
				return this.CONTEST_PRIZES.PROTOTYPING;
			default:
				return this.CONTEST_PRIZES.SKETCH;
		}
	},
	getContestRewards: function (contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return this.CONTEST_REWARDS.SKETCH;
			case this.TYPES.MODEL:
				return this.CONTEST_REWARDS.MODEL;
			case this.TYPES.ELECTRICALT:
				return this.CONTEST_REWARDS.ELECTRICAL;
			case this.TYPES.MECHANICAL:
				return this.CONTEST_REWARDS.MECHANICAL;
			case this.TYPES.PROTOTYPING:
				return this.CONTEST_REWARDS.PROTOTYPING;
			default:
				return this.CONTEST_REWARDS.SKETCH;
		}
	},
	getContestCredits: function (contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return this.CONTEST_CREDITS.SKETCH;
			case this.TYPES.MODEL:
				return this.CONTEST_CREDITS.MODEL;
			case this.TYPES.ELECTRICALT:
				return this.CONTEST_CREDITS.ELECTRICAL;
			case this.TYPES.MECHANICAL:
				return this.CONTEST_CREDITS.MECHANICAL;
			case this.TYPES.PROTOTYPING:
				return this.CONTEST_CREDITS.PROTOTYPING;
			default:
				return 'N/A';
		}
	},
	hasProposalByUser: function (contest, user) {
		return !!contest.proposals.find(p => p.user.id === user.id);
	},
	getOpenRound: function (contest) {
		if (!contest.rounds) return null;
		const today = new Date();
		const oneDay = 24 * 60 * 60 * 1000;

		return contest.rounds.find(r => {
			return r.startDate <= today && (r.endDate - today / oneDay) >= today;
		});
	},
	getLastRound: function (contest) {
		if (!contest.rounds || !contest.rounds.length) return false;
		const rounds = contest.rounds.length > 1
			?	contest.rounds[contest.rounds.length - 1]
			: 	contest.rounds[0];
		return rounds;
	},
	isContestStarting: function (contest, project) {
		// Same state
		const sameState = (PROJECT.getContestTypeByState(project.state) === contest.type);
		// Active contest
		const isContestActive = contest.active;
		// Open Round
		const openRound = this.getOpenRound(contest);
		// Contest card is pending
		const isContestCardPending = openRound && (openRound.card.clientConfirmed === this.CONTEST_CARD_CONFIRMATION.PENDING);

		return sameState && isContestActive && !!openRound && isContestCardPending;
	},
	// for experts
	isContestJoinable: function (contest, project) {
		// Same state
		const sameState = (PROJECT.getContestTypeByState(project.state) === contest.type);
		// Active contest
		const isContestActive = contest.active;
		// Open Round
		const openRound = this.getOpenRound(contest);
		// Contest card is confirmed
		const isContestCardConfirmed = openRound && (openRound.card.clientConfirmed === this.CONTEST_CARD_CONFIRMATION.YES);
		// Has available slots
		const hasAvailableSlots = contest.availableSlots > 0;

		return sameState && isContestActive && !!openRound && isContestCardConfirmed && hasAvailableSlots;
	},
	// for clients
	isContestOpen: function (contest, project) {
		// Same state
		const sameState = (PROJECT.getContestTypeByState(project.state) === contest.type);
		// Active contest
		const isContestActive = contest.active;
		// Open Round
		const openRound = this.getOpenRound(contest);
		// Contest card is confirmed
		const isContestCardConfirmed = openRound && (openRound.card.clientConfirmed === this.CONTEST_CARD_CONFIRMATION.YES);

		return sameState && isContestActive && !!openRound && isContestCardConfirmed;
	},
	isContestEnded: function (contest) {
		const lastRound = this.getLastRound(contest);
		if (!lastRound) return false;
		const today = new Date();
		return lastRound.endDate <= today;
	},
	getContestDaysLeft: function (endDate) {
		const oneDay = 24 * 60 * 60 * 1000;
		const today = new Date();
		return Math.ceil((endDate - today) / oneDay);
	},
	getContestDaysDuration: function (contestRound) {
		const oneDay = 24 * 60 * 60 * 1000;
		const newEndDate = contestRound && (contestRound.endDate - oneDay);
		const endDate = contestRound && new Date(parseInt(newEndDate));
		const startDate = new Date(parseInt(contestRound.startDate));

		return parseInt(Math.round(endDate.getTime() - startDate.getTime()) / oneDay);
	},
	isWinningProposal: function (contest, proposal) {
		if (!proposal || !contest) return false;
		const { selectedProposal } = contest;
		if (!selectedProposal) return false;
		return (selectedProposal.id === proposal.id);
	},
	hasWinner: function (contest) {
		const { selectedProposal } = contest;
		return !!selectedProposal;
	},
	hasWinnerAndTheWinnerIsThisUser: function (contest, userId) {
		const { selectedProposal } = contest;
		return !!selectedProposal && (userId === selectedProposal.user.id);
	},
	hasRound: function (contest) {
		const { rounds } = contest;
		return rounds && rounds.length > 0;
	},
	getPhaseAdvancement: function (contest, project) {
		const isContestOpen = this.isContestOpen(contest, project);
		const contestCardIsApprovedByClient = PROJECT.isContestCardApprovedByClient(contest);
		const contestHasEnded = this.isContestEnded(contest);
		const contestHasWinner = this.hasWinner(contest);

		// PR phase apply only
		const contestSteps = contest.type === this.TYPES.PROTOTYPING && contest.steps;
		const stepsCompleted = contest.type === this.TYPES.PROTOTYPING && PROJECT.getCompletedSteps(contestSteps);
		const contestStepsAreCompleted = stepsCompleted === contestSteps.length ? true : false;
		const isAStepPending = !contestStepsAreCompleted && (stepsCompleted < contestSteps.length && stepsCompleted > 0);

		if ((contest.type === this.TYPES.PROTOTYPING && contestHasWinner && !contestStepsAreCompleted && !isAStepPending)
		|| (contest.type !== this.TYPES.PROTOTYPING && isContestOpen && contestCardIsApprovedByClient)) {
			return 25;
		} else if (
			(contest.type === this.TYPES.PROTOTYPING && !contestStepsAreCompleted && isAStepPending)
			|| (contest.type !== this.TYPES.PROTOTYPING && contestHasEnded && !contestHasWinner && contestCardIsApprovedByClient)) {
			return 66;
		} else if (
			(contest.type === this.TYPES.PROTOTYPING && contestStepsAreCompleted)
			|| (contest.type !== this.TYPES.PROTOTYPING && contestHasEnded && contestHasWinner && contestCardIsApprovedByClient)) {
			return 100;
		} else {
			return 0;
		}
	},
	getPrototypingPhaseAdvancement: function (contest) {
		const contestHasWinner = this.hasWinner(contest);
		const steps = contest.steps || [];
		const stepsCompleted = PROJECT.getCompletedSteps(steps);
		const contestStepsAreCompleted = stepsCompleted === steps.length ? true : false;
		const isAStepPending = !contestStepsAreCompleted && (stepsCompleted < steps.length && stepsCompleted > 0);

		if (contestHasWinner && !contestStepsAreCompleted && !isAStepPending) {
			return 25;
		} else if (!contestStepsAreCompleted && isAStepPending) {
			return 66;
		} else if (contestStepsAreCompleted) {
			return 100;
		} else {
			return 0;
		}
	},
	getContestPhaseState: function (project, state) {
		if (!state) state = project.state;
		const contest = project && PROJECT.getContestByState(project, state);
		const isOpen = contest && this.isContestOpen(contest, project);
		const isEnded = contest && this.isContestEnded(contest);
		const hasAWinningProposal = contest && this.hasWinner(contest);

		if (isOpen) {
			return 'Open contest';
		} else if (isEnded && hasAWinningProposal) {
			return 'Completed';
		} else if (isEnded && !hasAWinningProposal) {
			return 'Winner selection';
		} else {
			return 'Not open yet';
		}
	},
	getContestPhaseStateByContest: function (project, contest) {
		const isOpen = contest && this.isContestOpen(contest, project);
		const isEnded = contest && this.isContestEnded(contest);
		const hasAWinningProposal = contest && this.hasWinner(contest);

		if (isOpen) {
			return 'Open contest';
		} else if (isEnded && hasAWinningProposal) {
			return 'Completed';
		} else if (isEnded && !hasAWinningProposal) {
			return 'Winner selection';
		} else {
			return 'Not open yet';
		}
	},
	getPhaseColor: function (phase) {
		switch (phase) {
			case this.PROJECT_PHASE.DRAFT:
				return '#D7DADE';
			case this.PROJECT_PHASE.SKETCH_CONTEST:
				return '#02B2DA';
			case this.PROJECT_PHASE.MODEL_CONTEST:
				return '#D9B41E';
			case this.PROJECT_PHASE.ELECTRICAL_CONTEST:
				return '#3AA936';
			case this.PROJECT_PHASE.MECHANICAL_CONTEST:
				return '#EA4E1D';
			case this.PROJECT_PHASE.PROTOTYPING_CONTEST:
				return '#682384';
			case this.PROJECT_PHASE.CLOSED:
				return '#df3d3b';
			default:
				return '#e1e4e8';
		}
	},
	getPhaseColorWithAllPhases: function (project) {
		const phase = project.state;

		switch (phase) {
			case this.PROJECT_PHASE.DRAFT:
				return '#D7DADE';
			case this.PROJECT_PHASE.CLIENT_ONBOARDING:
				return '#24a148';
			case this.PROJECT_PHASE.PROJECT_SET_UP:
				return '#FFA201';
			case this.PROJECT_PHASE.SKETCH_CONTEST:
				return '#02B2DA';
			case this.PROJECT_PHASE.MODEL_CONTEST:
				return '#D9B41E';
			case this.PROJECT_PHASE.ELECTRICAL_CONTEST:
				return '#3AA936';
			case this.PROJECT_PHASE.MECHANICAL_CONTEST:
				return '#EA4E1D';
			case this.PROJECT_PHASE.PROTOTYPING_CONTEST:
				return '#682384';
			case this.PROJECT_PHASE.CLOSED:
				return '#df3d3b';
			default:
				return '#D7DADE';
		}
	},
	getPhaseColorWithContestType: function (contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return '#02B2DA';
			case this.TYPES.MODEL:
				return '#D9B41E';
			case this.TYPES.ELECTRICAL:
				return '#3AA936';
			case this.TYPES.MECHANICAL:
				return '#EA4E1D';
			case this.TYPES.PROTOTYPING:
				return '#682384';
			default:
				return '#e1e4e8';
		}
	},
	// getPhaseRequirements: function (project, contestType) {
	// 	switch (contestType) {
	// 		case this.TYPES.SKETCH:
	// 			return {
	// 				PRODUCT_SIZE: PROJECT.getProjectSizeDetailed(project.prototypeSize),
	// 				PRODUCT_APPEARANCE: project.productAppearance,
	// 				PRODUCT_SKETCH: project.productSketch && 'Has initial sketch',
	// 				DESIGN_DETAILS: project.designDetails === PROJECT.DESIGN_DETAILS.MORE_DETAILS ? 'Go into more details with maker' : 'Freedom to design',
	// 				PRODUCT_SPECIAL: project.special,
	// 			};
	// 		case this.TYPES.MODEL:
	// 			return {
	// 				PRODUCT_SENSE: PROJECT.getProjectSizeDetailed(project.prototypeSize),
	// 				PRODUCT_UX: project.productUx,
	// 				PRODUCT_UX2: project.productUx2,
	// 				PROTOTYPE_MATERIAL: project.prototypeMaterial !== null && `${project.prototypeMaterial} material`,
	// 			};
	// 		case this.TYPES.ELECTRICAL:
	// 			return {
	// 				PRODUCT_CONTROL: project.productControl.length > 0 ? `Product control: ${project.productControl}` : false,
	// 				PRODUCT_POWER: project.productPower.length > 0 ? project.productPower : false,
	// 				HAS_IOT: project.hasIOT && 'IoT',
	// 				BATTERY_LIFE: project.batteryLife && project.batteryLife,
	// 				HAS_ACTUATORS: project.hasActuators && 'Actuators',
	// 				ELECTRONICS: project.electronics && 'Electronics',
	// 			};
	// 		case this.TYPES.MECHANICAL:
	// 			return {
	// 				PRODUCT_SIZE: PROJECT.getProjectSizeDetailed(project.prototypeSize),
	// 				PRODUCT_ENVIRONMENT: project.productEnvironment,
	// 			};
	// 		case this.TYPES.PROTOTYPING:
	// 			return {
	// 				PRODUCT_SIZE: PROJECT.getProjectSizeDetailed(project.prototypeSize),
	// 				PROTOTYPE_MATERIAL: project.prototypeMaterial !== null && `${project.prototypeMaterial} material`,
	// 				PRODUCT_SPECIAL: project.special,
	// 			};
	// 		default:
	// 			return false;
	// 	}
	// },
	getTotalContestsCompleted: function (contests) {
		let totalContestsCompleted = 0;
		for (const contest of contests) {
			if (contest.selectedProposal !== null) {
				totalContestsCompleted++;
			}
		}
		return totalContestsCompleted;
	},
	getTotalActivePhases: function (contests) {
		let totalActivePhases = 0;
		for (const contest of contests) {
			if (contest.active === true) {
				totalActivePhases++;
			}
		}
		return totalActivePhases;
	},
	getContestTypeDescription(contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return KEYS.phase_contest_details_sketch_description_alt;
			case this.TYPES.MODEL:
				return KEYS.phase_contest_details_model_description_alt;
			case this.TYPES.ELECTRICAL:
				return KEYS.phase_contest_details_electrical_description_alt;
			case this.TYPES.MECHANICAL:
				return KEYS.phase_contest_details_mechanical_description_alt;
			case this.TYPES.PROTOTYPING:
				return KEYS.phase_contest_details_prototyping_description_alt;
			default:
				return '';
		}
	},
	getPhaseConsiderations(contestType) {
		switch (contestType) {
			case this.TYPES.SKETCH:
				return [
					'Do the proposals address the potential user and how you want them to interact with your prototype?',
					'Do the proposals achieve the desired look and feel of your design?',
					'Do the experts understand your design vision and have included all your must-have features?'
				];
			case this.TYPES.MODEL:
				return [
					'Do the proposals address your technical requirements regarding how the user interacts with your prototype?',
					'Do the proposals achieve and communicate clearly the desired size and finish of your prototype vision? ',
					'Do the experts understand your design vision and have included all your must-have features?'
				];
			case this.TYPES.ELECTRICAL:
				return [
					'Do the proposals address your technical requirements regarding device communication, user experience interfaces, and device power consumption?',
					'Do the proposals achieve the desired look and feel of your winning sketch design? ',
					'Do the experts understand your design vision and have included all your must-have features?'
				];
			case this.TYPES.MECHANICAL:
				return [
					'Do the proposals allow you to see how many parts are required to achieve your design intent?',
					'Do the proposals clearly communicate the final color, material, and finish for making your prototype?',
					'Do the proposals maintain the desired feature set that was defined and refined through the previous development steps?'
				];
			case this.TYPES.PROTOTYPING:
				return [
					'Do you understand the level of complexity to produce your prototype in 1 unit?',
					'Do you have a plan for how you will test your prototype when you receive your 1st prototype?',
					'What shipping address would you like the LastBasic team to ship your prototype to?'
				];
			default:
				return [];
		}
	},
	areAllSharedFilesEmpty: function(contests) {
		let hasFile = 0;
		for (const contest of contests) {
			if (contest.sharedFiles.length > 0) {
				hasFile++;
			}
		}
		return hasFile === 0;
	},
	areAllContestsProposalsEmpty: function(contests) {
		for (const contest of contests) {
			if (contest.proposals.length > 0 && PROPOSAL.hasAWinningProposal(contest)) {
				return false;
			}
		}
		return true;
	},
	getTotalContestSharedFiles: function (contests) {
		let supportingFiles = 0;
		for (const contest of contests) {
			const { sharedFiles } = contest;
			for (const sharedFile of sharedFiles) {
				const fileApproved = sharedFile.approvalStatus;
				if (fileApproved === 'approved') {
					supportingFiles++;
				}
			}
		}
		return supportingFiles;
	},
	hasSharedFiles: function (contest) {
		const { sharedFiles } = contest[0];
		if (sharedFiles.length === 0) return false;
		for (const sharedFile of sharedFiles) {
			const fileApproved = sharedFile.approvalStatus;
			if (fileApproved === 'approved') {
				return true;
			}
		}
		return false;
	},
	getTotalSupportingFiles: function (sharedFiles) {
		let supportingFiles = 0;
		for (const sharedFile of sharedFiles) {
			const fileApproved = sharedFile.approvalStatus;
			if (fileApproved === 'approved') {
				supportingFiles++;
			}
		}
		return supportingFiles;
	},
	getContestContributorsWithId: function (contest) {
		const proposals = contest.proposals;
		let contestContributors = [];
		for (const proposal of proposals) {
			for (const file of proposal.files) {
				for (const version of file.versions) {
					if (version.approvedStatus === this.APPROVED_STATUS.YES && version.published === true) {
						contestContributors.push(proposal.user.id);
					}
				}
			}
		}
		return contestContributors;
	},
};
