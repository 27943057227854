import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	Loading,
	Modal,
	Button,
	InlineLoading,
	InlineNotification
} from 'carbon-components-react';

import { CHANGE_PAYOUT_STATUS } from '../../providers/mutations';

import classes from './preview-file-modal.module.scss';

class PreviewInvoiceModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			sendingConfirmatingRequest: false,
			success: false,
			errorConfirmingInvoice: null,
		};
	}

	async requestConfirmationInvoice(e, payoutId, changePayoutStatus, status) {
		const { onRequestSubmit } = this.props;

		const requestOnInvoice = status === true ? 'approved' : 'pending';

		e.preventDefault();
		let error;
		this.setState({ sendingConfirmatingRequest: true });

		try {
			const result = await changePayoutStatus({
				variables: {
					payoutId: payoutId,
					status: requestOnInvoice,
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			this.setState({ errorConfirmingInvoice: error.toString() });
			setTimeout(() => {
				this.setState({ errorConfirmingInvoice: null });
			}, 3000);
		} else {
			this.setState({ success: true, sendingConfirmatingRequest: false });
			setTimeout(() => {
				this.setState({ success: false });
				onRequestSubmit();
			}, 3000);
		}
		this.setState({ sendingConfirmatingRequest: false });
	}

	render() {
		const { open, invoiceData, onSecondarySubmit, onRequestClose, ...rest } = this.props;
		const { sendingConfirmatingRequest, success, errorConfirmingInvoice } = this.state;

		return (
			<Mutation mutation={CHANGE_PAYOUT_STATUS}>
				{(changePayoutStatus, { error }) => {
					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}
					return (
						<Modal
							passiveModal={true}
							className={classes.previewInvoiceModal}
							open={open}
							modalHeading="Preview invoice"
							// modalLabel="Need to confirm invoice in order to proceed payment"
							onRequestClose={onRequestClose}
							{...rest}
						>
							<div>
								<Fragment>
									<Loading
										className={classes.loading}
										withOverlay={false} />
									<iframe title="Preview invoice" src={invoiceData.url} />
								</Fragment>
								<div className={classes.invoiceBtnBox}>
									<Button type="submit" className={classes.btn} onClick={onSecondarySubmit} kind="secondary" disabled={sendingConfirmatingRequest || success}>
										Update legal info for new invoice generation
									</Button>
									{sendingConfirmatingRequest || success ? (
										<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={success ? 'Invoice confirmed successfully' : 'Confirming invoice ...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorConfirmingInvoice
										? 	<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={'Error confirming invoice'}
											status={'error'}
										/>
										:	(
											<Button className={classes.btn} onClick={(e) => this.requestConfirmationInvoice(e, invoiceData.payoutId, changePayoutStatus, true)}>Confirm invoice</Button>
										)}
								</div>
							</div>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

PreviewInvoiceModal.propTypes = {
	open: PropTypes.bool,
	invoiceData: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

PreviewInvoiceModal.defaultProps = {
	open: false,
	invoiceData: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
};

export default PreviewInvoiceModal;