import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Button, InlineNotification, TextInput } from 'carbon-components-react';
import { Mutation } from '@apollo/client/react/components';

import { FORGOT_PASSWORD_MUTATION } from '../../../providers/mutations';
import * as actions from '../../../reducers/user/actions';

import classes from './pages.module.scss';

const LB_LOGO_URL = '/assets/images/lastbasic-logo.png';

class ForgotPasswordPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorForm: null,
			email: null,
		};
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	onSubmit = async (e, forgotPasswordMutation) => {
		e.preventDefault();
		
		const { forgotPassword } = this.props;
		const { email } = this.state;

		this.setState({ errorForm: null });

		return forgotPassword(forgotPasswordMutation, {
			username: email
		});
	};

	render() {
		const {
			errorForm,
		} = this.state;
		
		return (
			<div className={classes.root}>
				<Mutation mutation={FORGOT_PASSWORD_MUTATION}>
					{(forgotPasswordMutation, { data, error, loading }) => {
						return (
							<Grid
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center"
								className={classes.parentGrid}
							>
								<div className={classes.brandContainer}>
									<img className={classes.logo} src={LB_LOGO_URL} alt="LastBasic logo" />
								</div>
								<Grid item>
									<div className={classes.cardContent}>
										<h2 className={classes.title}>
											Forgot password?
										</h2>
										{error && (
											<InlineNotification
												className={classes.notification}
												kind="error"
												lowContrast
												hideCloseButton
												title=""
												subtitle={(error.graphQLErrors && error.graphQLErrors.length)
													? error.graphQLErrors[0].message
													: (error.networkError)
														? error.networkError.message
														: error.message}
											/>
										)}
										{errorForm && (
											<InlineNotification
												className={classes.notification}
												kind="error"
												lowContrast
												hideCloseButton
												title=""
												subtitle={errorForm}
											/>
										)}
										{data && (
											<InlineNotification
												className={classes.notification}
												kind="success"
												lowContrast
												hideCloseButton
												title=""
												subtitle={'We have sent you a mail.'}
											/>
										)}
										<form className={classes.form} onSubmit={(e) => this.onSubmit(e, forgotPasswordMutation)}>
											<TextInput
												id="email-input"
												className={classes.textInput}
												labelText="Email"
												type="email"
												name="email"
												onChange={this.onChange}
												required
											/>
											<div className={classes.btnBox}>
												<Button
													className={classes.submitButton}
													type="submit"
													disabled={loading}>
													{loading ? 'Loading...' : 'Restore password'}
												</Button>
											</div>
											<Link
												className={classes.additionalLinks}
												to="/login">
													Go back to login
											</Link>
										</form>
									</div>
								</Grid>
							</Grid>
						);
					}}
				</Mutation>
			</div>
		);
	}
}

ForgotPasswordPage.propTypes = {
	forgotPassword:	PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string,
	}),
};

ForgotPasswordPage.defaultProps = {
	forgotPassword() {},
	user: {},
};

const mapStateToProps = ({ user }) => ({
	user,
});

const mapDispatchToProps = dispatch => ({
	forgotPassword: (mutation, data) => dispatch(actions.forgotPassword(mutation, data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ForgotPasswordPage);
