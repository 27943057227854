import { PROJECT } from '.';

export default {
	TYPES: PROJECT.CONTEST_TYPES,
	PAYOUT_TYPES: {
		WALLET_CONVERSION: 'wallet_conversion',
		OTHER: 'other',
	},
	PAYOUT_STATUS: {
		PENDING: 'pending',
		APPROVED: 'approved',
		PAID: 'paid',
		PAYMENT_RECEIVED: 'payment_received',
		CANCELED: 'canceled',
	},
	LB_CREDIT_TRANSACTION_TYPES: {
		PROPOSAL_APPROVED: 'proposal_approved',
		CREDIT_CONVERSION: 'credit_conversion',
		OTHER: 'other',
	},
	MIN_CREDIT_UNTIL_PAYMENT: 400,
	CREDIT_TRANSACTION_CONVERSION_CONCEPT: 'Credit converted',
	WALLET_TRANSACTION_TYPES: {
		PAYOUT_WALLET_AUTOMATIC_TRANSACTION: 'payout_wallet_automatic_transaction',
		WALLET_CONVERSION: 'wallet_conversion',
		CREDIT_CONVERSION: 'credit_conversion',
		CONTEST_WON: 'contest_won',
		OTHER: 'other',
	},
	WALLET_TRANSACTION_PAYOUT_CONCEPT: 'Payout',
	WINNER_PROPOSAL_CONCEPT: 'Winning proposal',
	PROPOSAL_CREDITS: {
		SKETCH: '20',
		MODEL: '30',
		ELECTRICAL: '40',
		MECHANICAL: '40',
		PROTOTYPING: '40',
	},
	getTransactionType: function(type) {
		switch (type) {
			case this.LB_CREDIT_TRANSACTION_TYPES.PROPOSAL_APPROVED:
				return 'Proposal credit';
			case this.LB_CREDIT_TRANSACTION_TYPES.OTHER:
				return 'Other';
			case this.WALLET_TRANSACTION_TYPES.CONTEST_WON:
				return 'Contest won';
			case this.WALLET_TRANSACTION_TYPES.CREDIT_CONVERSION:
				return 'Credit conversion';
			case this.WALLET_TRANSACTION_TYPES.PAYOUT_WALLET_AUTOMATIC_TRANSACTION:
				return 'Other'; // Not all automatic transactions are credit conversions
			default:
				return 'Other';
		}
	},
	getTypeColorTag: function(type) {
		const transactionType = this.getTransactionType(type);
		switch (transactionType) {
			case 'Proposal credit':
				return 'teal';
			case 'Credit conversion':
				return 'blue';
			case 'Other':
				return 'gray';
			case 'Contest won':
				return 'green';
			default:
				return 'gray';
		}
	},
	getStatusColorTag: function(status) {
		switch (status) {
			case this.PAYOUT_STATUS.APPROVED:
				return 'blue';
			case this.PAYOUT_STATUS.PENDING:
				return 'gray';
			case this.PAYOUT_STATUS.PAID:
				return 'cyan';
			case this.PAYOUT_STATUS.CANCELED:
				return 'red';
			case this.PAYOUT_STATUS.PAYMENT_RECEIVED:
				return 'green';
			default:
				return 'gray';
		}
	},
	getTotalGrossEarnings: function(payouts) {
		let earnings = [];
		payouts.forEach(payout => {
			return earnings.push(payout.amount);
		});
		return earnings.reduce(function(a, b) { return a + b; }, 0);
	},
	getTotalNetEarnings: function(payouts) {
		let earnings = [];
		payouts.forEach(payout => {
			return earnings.push(payout.netAmount);
		});
		return earnings.reduce(function(a, b) { return a + b; }, 0);
	},
	getLastCreditsConverted: function(creditTransactions) {
		let count = 0;
		for (const creditTransaction of creditTransactions) {
			if (creditTransaction.type === this.LB_CREDIT_TRANSACTION_TYPES.CREDIT_CONVERSION) {
				return creditTransaction.amount.toString().slice(1);
			}
		}
		return count;
	},
	getTotalInvoices: function(payouts) {
		let count = 0;
		for (const payout of payouts) {
			if (payout.status !== this.PAYOUT_STATUS.CANCELED) {
				count++;
			}
		}
		return count;
	},
	getCreditsAmount: function(type) {
		switch (type) {
			case this.TYPES.SKETCH:
				return this.PROPOSAL_CREDITS.SKETCH;
			case this.TYPES.MODEL:
				return this.PROPOSAL_CREDITS.MODEL;
			case this.TYPES.ELECTRICAL:
				return this.PROPOSAL_CREDITS.ELECTRICAL;
			case this.TYPES.MECHANICAL:
				return this.PROPOSAL_CREDITS.MECHANICAL;
			case this.TYPES.PROTOTYPING:
				return this.PROPOSAL_CREDITS.PROTOTYPING;
			default:
				return this.PROPOSAL_CREDITS.SKETCH;
		}
	},
};