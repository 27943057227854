import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { List20 } from '@carbon/icons-react';
import CardAction from '../../../components/project/card-action';
import AlternativeOption from '../../../components/project/alternative-option';
import CaseStudy from '../../../components/project/case-study';
import LBButton from '../../../components/elements/lb-button';
import withRouter from '../../../components/route/with-router';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import KEYS from '../../../glossary';

import classes from './start.module.scss';

const STEPS = {
	ABOUT: 'about-us',
	COMPARISON: 'comparison',
	FAQS: 'faqs',
	WHAT_WE_DO: 'what-we-do',
	RESOURCES: 'resources',
	GET_IN_TOUCH: 'get-in-touch',
	LETS_GO: 'lets-go',
};

class StartPage extends Component {
	constructor (props) {
		super(props);

		this.state = {
			activeStep: 0,
		};
	}

	componentDidMount() {
		document.title = 'Getting started - LastBasic';
		this.setInitialStepsParams();
	}

	async setInitialStepsParams() {
		const { location, navigate } = this.props;
		const { activeStep } = this.state;

		const searchParams = location.search;
		const queryString = searchParams.substring(searchParams.lastIndexOf('step='));
		const stepIndex = queryString.includes(STEPS.LETS_GO)
			? 	6
			:	queryString.includes(STEPS.GET_IN_TOUCH)
				?	5
				:	queryString.includes(STEPS.RESOURCES)
					?	4
					:	queryString.includes(STEPS.WHAT_WE_DO)
						?	3
						:	queryString.includes(STEPS.FAQS)
							?	2
							:	queryString.includes(STEPS.COMPARISON)
								?	1
								:	0;
		const stepActiveTab = (activeStep === 6)
			? 	STEPS.LETS_GO
			:	(activeStep === 5)
				?	STEPS.GET_IN_TOUCH
				:	(activeStep === 4)
					?	STEPS.RESOURCES
					:	(activeStep === 3)
						?	STEPS.WHAT_WE_DO
						:	(activeStep === 2)
							?	STEPS.FAQS
							:	(activeStep === 1)
								?	STEPS.COMPARISON
								:	STEPS.ABOUT;

		this.setState({ activeStep: stepIndex });

		return navigate({
			search: '?' + new URLSearchParams({ step: stepActiveTab}).toString()
		});
	}

	openEditPersonalDataModal() {
		this.setState({
			openEditPersonalDataModal: true
		});
	}

	render() {
		const { activeStep } = this.state;

		const steps = [
			'About LastBasic',
			'LastBasic vs. traditional agencies',
			'FAQs',
			'What we do and don’t do',
			'Resources',
			'Get in touch',
			'It’s your turn'
		];

		return (
			<div className={classes.container} style={{position: 'relative'}}>
				<h2 className={classes.title}>Getting started</h2>
				{this.displaySteps(steps)}
				<div>
					{activeStep === 0 &&
						<div className={classes.accordion}>
							<h4>About LastBasic</h4>
							<h5 className={classes.subtitle}>At LastBasic we are passionate inventors</h5>
							<p>
								Developing technology and hardware products has been historically difficult, expensive, and complex.
								At LastBasic, we want to change that. We strongly believe that everyone has the potential to contribute to the evolution of technology with their ideas.
							</p>
							<p className={classes.subtitle}>
								Our mission is to build a global meeting point for technology interaction and creation.
								We are accomplishing this mission by building a community of creators, designers, engineers, and makers who all believe product development should be more accessible, open, and inclusive.
								We believe in creating connections and meeting points that will facilitate the free flowing exchange of ideas, market trends, and in turn boost entrepreneurial spirit to all those involved.
							</p>
							<p className={classes.subtitle}>
								At LastBasic, we want your idea to become a reality.
							</p>
							<div>
								<h5 className={classes.subtitle}>We are not alone, we count with a growing community of experts and partners</h5>
								<div className={classes.elementsContainer}>
									<img src='/assets/images/decelera_logo.png' className={classes.partnersLogo} />
									<img src='/assets/images/thinx_logo.png' className={classes.partnersLogo} />
									<img src='/assets/images/gohub_logo.png' className={classes.partnersLogo} />
								</div>
							</div>
						</div>
					}
					{activeStep === 1 &&
						<div className={classes.accordion}>
							<h4>LastBasic vs. traditional agencies</h4>
							<h5 className={classes.subtitle}>Inventors across the globe are choosing LastBasic over traditional agencies to create their prototypes</h5>
							<p>As an online platform with a community of experts bidding competitively to help with your invention, we offer a whole host of benefits for inventors.</p>
							{this.displayCards()}
						</div>
					}
					{activeStep === 2 &&
						<div className={classes.accordion}>
							<h4>Getting started with your project</h4>
							<p className={classes.subtitle}>Start getting familiar with our open innovation model.</p>
							<ul>
								<li className={classes.listItem} onClick={() => window.Froged('doc', '619e58f5c0760e0df7b72559')}>
									<Link to='#'>
										What to expect from a Prototype?
									</Link>
								</li>
								<li className={classes.listItem} onClick={() => window.Froged('doc', '623de7af7b152a10bcc9c18a')}>
									<Link to='#'>
										How to start: onboarding you
									</Link>
								</li>
								<li className={classes.listItem} onClick={() => window.Froged('doc', '626430ac3dfba9d107b9c6b7')}>
									<Link to='#'>
										What should I check for in each phase?
									</Link>
								</li>
								<li className={classes.listItem} onClick={() => window.Froged('doc', '625c0c7158750989c5584c7e')}>
									<Link to='#'>
										Expert Deliverables
									</Link>
								</li>
								<li className={classes.listItem} onClick={() => window.open('https://lastbasic.froged.help/docs/en/client-faqs')}>
									<Link to='#'>
										Other FAQs
									</Link>
								</li>
							</ul>
						</div>
					}
					{activeStep === 3 &&
						<div className={classes.accordion}>
							<h4>What we do and don’t do</h4>
							<h5 className={classes.subtitle}>What ideas can we turn into prototypes?</h5>
							<img src='/assets/images/what-we-do-and-dont.png' className={classes.dontImg} />
						</div>
					}
					{activeStep === 4 &&
						<div className={classes.accordion}>
							<h4>Resources</h4>
							<div>
								<h5 className={classes.subtitle}>Check our case studies</h5>
								<p>With over 700 ideas shared with us, we are continually facilitating innovation.</p>
								<div className={classes.caseStudiesList}>
									{/* <CaseStudy
										name='Affirmation Smart Mirror'
										category='IoT'
										description='A mirror that displays motivational quotes and affirmations via a smartphone app.'
										img='/assets/images/affirmation-mirror.png'
										urlLink='https://lastbasic.com/case-studies/affirmation-smart-mirror'
									/> */}
									<CaseStudy
										name='TrueMe Pebbles'
										category='Wellness'
										description='A self help stress and anxiety reduction device for those who suffer with anxiety or other panic issues.'
										img='/assets/images/pebbles.jpeg'
										urlLink='https://lastbasic.com/case-studies/trueme-pebbles'
									/>
									<CaseStudy
										name='Bitcoin Desk Clock'
										category='Crypto'
										description='A desk clock that always shows the latest Bitcoin price for crypto obsessed individuals.'
										img='/assets/images/bitcoin-clock.jpeg'
										urlLink='https://lastbasic.com/case-studies/bitcoin-desk-clock'
									/>
									<CaseStudy
										name='Delivery Food Sensor'
										category='Home delivery'
										description='The prototype set to change the way we order food online.'
										img='/assets/images/food-sensor.jpeg'
										urlLink='https://lastbasic.com/case-studies/delivery-food-sensor'
									/>
								</div>
							</div>
							<div style={{margin: '36px 0'}}>
								<h5 className={classes.subtitle}>Explore our knowledge hub</h5>
								<p>You will find helpful info for your project development.</p>
								<div style={{ margin: '16px 0'}}>
									<a
										href={'https://lastbasic.com/knowledge-hub'}
										target='_blank'
										rel="noreferrer"
										style={{ textDecoration: 'none' }}
									>
										<AlternativeOption
											type="primary"
											title="Explore knowledge hub"
											icon={<List20 />}
										/>
									</a>
								</div>
							</div>
						</div>
					}
					{activeStep === 5 &&
						<div className={classes.accordion}>
							<h4>Get in touch</h4>
							<div>
								<h5 className={classes.subtitle}>We are here to help you</h5>
								<p>If your case requires it, set a call with a sales representative to help you with your project building.</p>
							</div>
							<div className={classes.caseStudiesList}>
								<CardAction
									type={'optional'}
									title={'Set a call'}
									action={'set-call'}
								/>
								<CardAction
									type={'optional'}
									title={'Open chat'}
									action={'open-chat'}
								/>
							</div>
						</div>
					}
					{activeStep === 6 &&
					<>
						<div className={classes.accordion}>
							<h4>Let&apos;s go</h4>
							<div>
								<h5 className={classes.subtitle}>We are thrilled to receive new ideas and help you turn it into reality</h5>
								<p>We know how difficult it is to create a prototype from scratch, but we were once at your feet and want to make it easier for you to turn your idea a reality.</p>
							</div>
						</div>
						<div className={classes.bottomBlock}>
							<Link to='/new/project' style={{ textDecoration: 'none' }}>
								<div className={classes.actionOption}>
									<small>Ready to start? Start creating with us!</small>
								</div>
							</Link>
						</div>
					</>
					}
				</div>
				{activeStep === steps.length - 1
					?	<div className={classnames([classes.btnContainer, classes.unique])}>
						<LBButton
							title={'Start project'}
							type={'primary'}
							onClick={() => this.handleComplete()}
						/>
					</div>
					:	activeStep === 0
						?	<div className={classnames([classes.btnContainer, classes.unique])}>
							<LBButton
								title={KEYS.next}
								type={'primary'}
								onClick={() => this.handleNext(activeStep)}
							/>
						</div>
						:
						<div className={classes.btnContainer}>
							<LBButton
								title={KEYS.previous}
								type={'secondary'}
								disabled={activeStep === 0}
								onClick={() => this.handleBack(activeStep)}
							/>
							<LBButton
								title={KEYS.next}
								type={'primary'}
								onClick={() => this.handleNext(activeStep)}
							/>
						</div>
				}
			</div>
		);
	}

	handleNext(activeStep) {
		const { navigate } = this.props;

		const newStep = activeStep + 1;
		const stepActiveTab = (newStep === 6)
			? 	STEPS.LETS_GO
			:	(newStep === 5)
				?	STEPS.GET_IN_TOUCH
				:	(newStep === 4)
					?	STEPS.RESOURCES
					:	(newStep === 3)
						?	STEPS.WHAT_WE_DO
						:	(newStep === 2)
							?	STEPS.FAQS
							:	(newStep === 1)
								?	STEPS.COMPARISON
								:	STEPS.ABOUT;

		this.setState({ activeStep: newStep });

		return navigate({
			search: '?' + new URLSearchParams({ step: stepActiveTab }).toString()
		});
	}

	handleBack(activeStep) {
		const { navigate } = this.props;

		const newStep = activeStep - 1;
		const stepActiveTab = (newStep === 6)
			? 	STEPS.LETS_GO
			:	(newStep === 5)
				?	STEPS.GET_IN_TOUCH
				:	(newStep === 4)
					?	STEPS.RESOURCES
					:	(newStep === 3)
						?	STEPS.WHAT_WE_DO
						:	(newStep === 2)
							?	STEPS.FAQS
							:	(newStep === 1)
								?	STEPS.COMPARISON
								:	STEPS.ABOUT;

		this.setState({ activeStep: newStep });

		return navigate({
			search: '?' + new URLSearchParams({ step: stepActiveTab }).toString()
		});
	}

	handleComplete() {
		window.location.href = '/project-builder';
	}

	displaySteps(steps) {
		const { activeStep } = this.state;

		return (
			<div style={{margin: '36px 0'}}>
				<Stepper activeStep={activeStep} alternativeLabel style={{flexWrap: 'wrap'}}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}

	displayCards() {
		const cards = [
			{
				title: 'Cheaper than anyone',
				text: 'Our sharing economy model makes us very competitive with prices being up to 75% lower than any engineering agency.',
				color: '#20448F'
			},
			{
				title: 'Competitive pitches',
				text: 'Experts will come to you with their pitch and approach for your product idea. You get to choose who to work with.',
				color: '#FFB2AB'
			},
			{
				title: 'Professionals you can trust',
				text: 'Our community of experts have been vetted having proven their worthy of working for you with industry accreditations and qualifications.',
				color: '#7FD99A'
			},
		];

		return (
			<div className={classes.elementsContainer}>
				{cards.map((card, i) => (
					<div key={i} className={classes.card} style={{background: card.color}}>
						<h2 style={card.color === '#20448F' ? {color: '#FFF'} : {}}>{card.title}</h2>
						<small style={card.color === '#20448F' ? {color: '#FFF'} : {}}>{card.text}</small>
					</div>
				))}
			</div>
		);
	}
}

StartPage.propTypes = {
	userData: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	maker: PropTypes.object,
};

StartPage.defaultProps = {
	userData: {},
	maker: {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default withRouter(connect(
	mapStateToProps,
)(withRouter(StartPage)));
