import { gql } from '@apollo/client';

const imagesFragment = gql`
	fragment ProjectImageLibraryImage on ProjectImageLibraryImage {
		id,
		source,
		urls {
			...ProjectImageLibraryImageUrls
		},
		credits {
			...ProjectImageLibraryImageCredits
		}
	}
	fragment ProjectImageLibraryImageUrls on ProjectImageLibraryImageUrls {
		regular,
		small,
	}
	fragment ProjectImageLibraryImageCredits on ProjectImageLibraryImageCredits {
		name,
		url,
	}
`;

export {
	imagesFragment,
};
