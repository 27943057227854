import { ChevronLeft16 } from '@carbon/icons-react';
import { Loading, Modal } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import KEYS from '../../glossary';
import { serverAPI } from '../../utils';

import classes from './preview-file-modal.module.scss';

class PreviewFileModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			isLoading: true,
		};
	}

	iframeLoaded = () => {
		this.setState({
			isLoading: false,
		});
	};

	render() {
		const {
			file,
			goBackToContest,
			goBackToProposal,
			open,
			...rest
		} = this.props;

		const { isLoading } = this.state;

		const iframeSrc = (file && file.id) ? (
			serverAPI.getPreviewUrlByFile(file)
		) : (file && file.url) ? (
			file.url
		) : (
			file
		);

		return (
			<Modal
				className={classes.previewFileModal}
				modalHeading="Document preview"
				open={open}
				passiveModal
				{...rest}
			>
				{open && (
					<Fragment>
						{!!goBackToContest &&
							<div className={classes.returnActionModal} onClick={goBackToContest}>
								<ChevronLeft16 />
								<span>{KEYS.contest_modal_go_back}</span>
							</div>
						}

						{!!goBackToProposal &&
							<div className={classes.returnActionModal} onClick={goBackToProposal}>
								<ChevronLeft16 />
								<span>Go back to proposal feedback</span>
							</div>
						}

						{isLoading &&
							<Loading
								className={classes.loading}
								withOverlay={false}
							/>
						}

						<iframe title="Document preview" src={iframeSrc} onLoad={this.iframeLoaded}/>
					</Fragment>
				)}
			</Modal>
		);
	}
}

PreviewFileModal.propTypes = {
	file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	goBackToContest: PropTypes.func,
	goBackToProposal: PropTypes.func,
	open: PropTypes.bool,
};

PreviewFileModal.defaultProps = {
	file: null,
	goBackToContest: () => {},
	goBackToProposal: () => {},
	open: false,
};

export default PreviewFileModal;
