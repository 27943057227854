import React from 'react';
import PropTypes from 'prop-types';

const PrototypingIconAlt = (props) => {
	const { size, active, color, type } = props;

	return (
		<svg id="prototyping-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color !== '' || type === 'color' ? '#682384' : active || type === 'white' ? '#fff' : '#C7C7C7'}>
			<path d="M16,24a.9967.9967,0,0,1-.4741-.12l-13-7L3.4741,15.12,16,21.8643,28.5259,15.12l.9482,1.7607-13,7A.9967.9967,0,0,1,16,24Z" transform="translate(0 0)"/>
			<path d="M16,30a.9967.9967,0,0,1-.4741-.12l-13-7L3.4741,21.12,16,27.8643,28.5259,21.12l.9482,1.7607-13,7A.9967.9967,0,0,1,16,30Z" transform="translate(0 0)"/>
			<path d="M16,18a.9967.9967,0,0,1-.4741-.12l-13-7a1,1,0,0,1,0-1.7607l13-7a.9982.9982,0,0,1,.9482,0l13,7a1,1,0,0,1,0,1.7607l-13,7A.9967.9967,0,0,1,16,18ZM5.1094,10,16,15.8643,26.8906,10,16,4.1358Z" transform="translate(0 0)"/>
		</svg>
	);
};

PrototypingIconAlt.propTypes = {
	active: PropTypes.bool,
	size: PropTypes.number,
	color: PropTypes.string,
	type: PropTypes.string,
};

PrototypingIconAlt.defaultProps = {
	active: false,
	size: 20,
	color: '',
	type: 'white',
};

export default PrototypingIconAlt;
