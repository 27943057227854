import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import classnames from 'classnames';
import {
	InlineNotification,
	// Modal,
	TagSkeleton,
	SkeletonPlaceholder,
	SkeletonText,
	ButtonSkeleton,
	Button,
	ProgressIndicator,
	ProgressStep,
	InlineLoading,
	Tag,
	TextArea,
} from 'carbon-components-react';
import {
	DotMark16,
	Information16,
	TaskView24, TaskView32,
	Close32, Document20,
	Time20, Hashtag20,
} from '@carbon/icons-react';
import ReactMarkdown from 'react-markdown';

import { PROJECT, CONTEST, PROPOSAL } from '../../../constants';
import { GET_CONTEST, GET_PHASE_STEPS } from '../../../providers/queries';
import {
	CONFIRM_CONTEST_CARD,
	ADD_CONTEST_REVIEW
} from '../../../providers/mutations';
import withRouter from '../../../components/route/with-router';

import { serverAPI, strings, dates, userInfo, FormUtils } from '../../../utils';
import KEYS from './../../../glossary';

import ContestSetupQuestions from '../../../components/project/setup-questions/contest-setup-questions';
import ViewSetupQuestions from '../../../components/project/setup-questions/view-setup-questions';
import RequestChangeContestCardModal from '../../../components/modals/request-change-contest-card-modal';
import SelectWinningProposalConfirmationModal from '../../../components/modals/select-winning-proposal-confirmation-modal';
import EditFeedbackModal from '../../../components/modals/edit-feedback-modal';
import ContestCardImage from '../../../components/project/contest-card-image';
import ContestStep from '../../../components/contest/contest-step';
// import ProposalFeedback from '../../../components/project/proposal-feedback-content';
import PreviewFileModal from '../../../components/modals/preview-file-modal';
import ScrollIndicator from '../../../components/elements/scroll-indicator';
import ErrorBoundaryQuery from '../../../components/graphql/queries-with-errors';
import RatingValue from '../../../components/project/rating-value';

import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import LockIcon from '@mui/icons-material/Lock';

import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';

import classes from './contest.module.scss';

import sendInsight from '../../../utils/insights';
const PROPOSAL_PLACEHOLDER = '/assets/images/contest-card-placeholder.jpg';

class ContestView extends Component {
	constructor (props) {
		super(props);
		this.inputFileRef = React.createRef();
		this.refetchContest = () => {};

		this.state = {
			openContestModal: false,
			openProposalFeedbackModal: false,
			proposalFeedback: null,
			proposalFile: null,
			previewFile: null,
			selectedProposal: null,
			userInfo: userInfo.getUserInfo(),
			currentContestStep: 1,
			confirmedContest: null,
			contestSetUpCompleted: false,
			sendingConfirmContestRound: false,
			successConfirmingContest: false,
			errorConfirmingContestRound: null,
			requestChangeContestCard: false,
			openContestCard: false,
			showTrophy: {show: false, number: 0},
			selectWinningProposalConfirmationModal: false,
			selectWinningProposalData: null,
			selectedWinningProposalId: null,
			qualityOfProposal: 0,
			successOfTheWinner: 0,
			processesUnderstanding: 0,
			teamSupport: 0,
			comments: '',
			filter: 'all',
		};
	}

	componentDidMount() {
		document.title = 'Contest page - LastBasic';
	}

	onOpenPreviewFile(file) {
		this.setState({ previewFile: file });
	}

	onClosePreviewFile() {
		this.setState({ previewFile: null });
	}

	onSubmitWinningProposalActions(contest) {
		this.setState({
			selectWinningProposalConfirmationModal: false,
			winnerSelectedConfirmation: {contestType: contest.type},
		});
		this.refetchContest();

		setTimeout(() => {
			this.setState({
				showPhaseFeedbackBoxModal: true
			});
		}, 5000);
	}

	onCloseSelectWinningProposalConfirmationModal = () => {
		this.setState({ selectWinningProposalConfirmationModal: false });
	};

	render() {
		const { match, userData } = this.props;
		const {
			openProposalFeedbackModal,
			selectWinningProposalConfirmationModal,
			selectWinningProposalData,
			selectedWinningProposalId,
			proposalFeedback,
			previewFile,
			// proposalFile,
			// selectedProposal,
			// userInfo,
			requestChangeContestCard,
			// openContestCard,
		} = this.state;
		const { id, projectId } = match.params;

		return (
			<div className={classes.contestViewPage}>
				<ErrorBoundaryQuery query={GET_CONTEST} variables={{ id: id }}>
					{({ loading, error, data, refetch: refetchContest }) => {
						this.refetchContest = refetchContest;

						if (loading) {
							return (
								<div className={classes.loadingContainer}>
									<div className={classes.betweenBlock} style={{ alignItems: 'end'}}>
										<div style={{display: 'flex', alignItems: 'center'}}>
											<SkeletonPlaceholder className={classes.projectThumbnail} />
											<div className={classes.flexColumn}>
												<SkeletonText style={{width: '300px'}} />
												<SkeletonText style={{width: '200px'}} />
											</div>
										</div>
										<ButtonSkeleton />
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<div className={classes.betweenBlock}>
											<div>
												<div>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
												</div>
												<div>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
												</div>
											</div>
											<div>
												<div>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
												</div>
												<div>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
													<SkeletonText style={{width: '500px'}}/>
												</div>
											</div>
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<div className={classes.tags}>
											<TagSkeleton style={{ height: '20px'}} />
											<TagSkeleton style={{ height: '20px'}} />
											<TagSkeleton style={{ height: '20px'}} />
										</div>
										<div style={{ display: 'flex'}}>
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
											<SkeletonPlaceholder className={classes.proposalSkeleton} />
										</div>
									</div>
									<div className={classes.horizontalDivider}></div>
									<div className={classes.containerSkeleton}>
										<SkeletonText style={{width: '200px'}} />
										<div className={classes.betweenBlock}>
											<div>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
											</div>
											<div>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
											</div>
											<div>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
												<SkeletonText style={{width: '300px'}}/>
											</div>
										</div>
									</div>
								</div>
							);
						}

						if (error) {
							return (
								<InlineNotification
									className={classes.notification}
									kind="error"
									lowContrast
									hideCloseButton
									title=""
									subtitle={(error.graphQLErrors && error.graphQLErrors.length)
										? error.graphQLErrors[0].message
										: (error.networkError)
											? error.networkError.message
											: error.message}
								/>
							);
						}

						const contest = data && data.getContest;
						if (!contest || (projectId && contest.project && contest.project.id !== projectId)) {
							return (<div>No contest</div>);
						}

						const project = contest.project;
						const projectIsInPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST && contest.type === PROJECT.CONTEST_TYPES.PROTOTYPING;
						const contestIsPrototyping = contest.type === PROJECT.CONTEST_TYPES.PROTOTYPING;
						// const projectIsFinished = project.state === PROJECT.STATE.CLOSED;

						sendInsight('view', 'contest_page_' + contest.type, null, project.id, null);

						return (
							<div>
								<ScrollIndicator />
								{/* Contest main header */}
								{this.displayContestHeader(contest)}

								<div className={classes.horizontalDivider}></div>

								{/* Proposals block */}
								{(!projectIsInPrototypingPhase && !contestIsPrototyping) && this.displayContestProposals(contest)}

								{/* Steps block */}
								{(projectIsInPrototypingPhase || contestIsPrototyping) && this.displayPrototypingSteps(contest)}

								<div className={classes.horizontalDivider}></div>

								{/* Contest details block */}
								{this.displayContestDetails(contest, project)}

								{/* Contest summary block */}
								{this.displayContestSummary(contest)}

								{/* Contest general info block */}
								{this.displayContestExpectations(contest)}

								{/* Modals */}
								{/* {openProposalFeedbackModal &&
									<Modal
										passiveModal={true}
										open={openProposalFeedbackModal}
										modalLabel={KEYS.proposal_feedback_modal_label}
										onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
									>
										{proposalFeedback ?
											<ProposalFeedback
												proposal={selectedProposal}
												feedback={proposalFeedback}
												displayOnPublic={true}
												onPreviewProposalFile={() => this.setState({ openProposalFeedbackModal: false, previewFile: proposalFile })}
											/>
											: <p>{KEYS.proposal_feedback_modal_no_data}</p>
										}
									</Modal>
								} */}
								{openProposalFeedbackModal && proposalFeedback && !proposalFeedback.feedback && (
									<EditFeedbackModal
										open={openProposalFeedbackModal}
										userData={userData}
										// onClickPreviewProposal={onClickPreviewProposal}
										onRequestClose={() => this.setState({ openProposalFeedbackModal: false })}
										onSecondarySubmit={() => this.setState({ openProposalFeedbackModal: false })}
										onRequestSubmit={() => this.setState({ openProposalFeedbackModal: false }, () => { this.refetchContest();})}
										proposal={proposalFeedback}
									/>
								)}
								{selectWinningProposalConfirmationModal && (
									<SelectWinningProposalConfirmationModal
										open={selectWinningProposalConfirmationModal}
										proposal={selectWinningProposalData}
										proposalId={selectedWinningProposalId}
										onRequestSubmit={() => this.onSubmitWinningProposalActions(contest)}
										onSecondarySubmit={this.onCloseSelectWinningProposalConfirmationModal}
										onRequestClose={this.onCloseSelectWinningProposalConfirmationModal}
									/>
								)}
								<PreviewFileModal
									open={!!previewFile}
									file={previewFile}
									onRequestClose={() => this.onClosePreviewFile()}
									goBackToProposal={false}
									goBackToContest={false}
								/>
								{requestChangeContestCard && (
									<RequestChangeContestCardModal
										open={requestChangeContestCard}
										contest={contest}
										onRequestSubmit={() => this.setState({ requestChangeContestCard: false, openContestCard: false }, () => { this.refetchContest();})}
										onSecondarySubmit={() => this.setState({ requestChangeContestCard: false })}
										onRequestClose={() => this.setState({ requestChangeContestCard: false })}
									/>
								)}
							</div>
						);
					}}
				</ErrorBoundaryQuery>
			</div>
		);
	}

	displayContestHeader(contest) {
		// const { userData: {user}} = this.props;
		const contestType = contest.type;
		const project = contest.project;

		// const isExpert = user.expert !== null;
		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		const contestRound = contest && CONTEST.getLastRound(contest);
		const { card } = contestRound !== undefined && contestRound;
		const { clientConfirmed } = card !== undefined && card;
		const contestPendingConfirmation = clientConfirmed !== CONTEST.CONTEST_CARD_CONFIRMATION.YES;
		const contestIsConfirmed = clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES;

		return (
			<div className={classes.spaceBetween}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					{this.displayProjectImage(project)}
					<div className={classes.flexColumn}>
						<Link
							to={`/project/${project.id}`}
							className={classes.breadcrumb}
						>
							<h5 style={{color:'#df3d3b'}}>{strings.capitalizeFirstLetter(project.name)}</h5>
						</Link>
						<h3>{strings.capitalizeFirstLetter(PROJECT.getStateByContestTypeShort(contestType))}</h3>
					</div>
				</div>
				{(!contestRound && contestPendingConfirmation) && this.displayContestClosedStatus()}
				{(contestRound && contestPendingConfirmation) && this.displayPendingContestStatus()}
				{(contestIsConfirmed && !hasAWinningProposal) && this.displayActiveContestStatus()}
				{(contestIsConfirmed && contestHasEnded && hasAWinningProposal) && this.displayWinnerStatus(contest)}
				{/* {isExpert &&
					<Button
						className={classes.btn}
						// onClick={() => this.openContest()}
					>
						{KEYS.project_status_join_contest}
					</Button>
				} */}
			</div>
		);
	}

	displayProjectImage(project) {
		return (
			<Link
				to={`/project/${project.id}`}
				style={{textDecoration: 'none'}}
			>
				<img
					className={classes.projectThumbnail}
					src={this.renderProjectImageUrl(project)}
					alt={'Project thumbnail'}
				/>
			</Link>
		);
	}

	renderProjectImageUrl(project) {
		const { image } = project;
		const DefaultImg = '/assets/images/logo-lb-project.png';

		if (!image) return DefaultImg;
		return serverAPI.getThumbnailUrlByFile({id: image});
	}

	displayPendingContestStatus() {
		return (
			<div className={classes.contestStatus}>
				<h5>Status</h5>
				<p>Pending contest confirmation</p>
			</div>
		);
	}

	displayContestClosedStatus() {
		return (
			<div className={classes.contestStatus}>
				<h5>Status</h5>
				<p>Contest not open yet</p>
			</div>
		);
	}

	displayActiveContestStatus() {
		return (
			<div className={classes.contestStatus}>
				<h5>Status</h5>
				<p>In competition</p>
			</div>
		);
	}

	displayWinnerStatus(contest) {
		const project = contest.project;
		const projectIsInPrototypingPhase = project.state === PROJECT.STATE.PROTOTYPING_CONTEST && contest.type === PROJECT.CONTEST_TYPES.PROTOTYPING;

		return (
			<div className={classes.contestStatus}>
				<h5>Status</h5>
				{projectIsInPrototypingPhase
					?	<p>Phase completed</p>
					:	<p>Contest has a winner</p>
				}
			</div>
		);
	}

	displayContestDetails(contest, project) {
		const contestType = contest && PROJECT.getStateByContestTypeShort(contest.type);
		const contestRound = contest && CONTEST.getLastRound(contest);
		const { card } = contestRound !== undefined && contestRound;

		const projectState = PROJECT.getStateByContestType(contest.type);
		const isActiveContest = project.state === projectState;
		const activePhaseStatus = CONTEST.getContestPhaseStateByContest(project, contest);
		const contestIsReady = isActiveContest && (activePhaseStatus === 'Not open yet' || activePhaseStatus === 'Open contest');

		return (
			<>
				<div className={classes.spaceBetween}>
					<h4 className={classes.secondaryTitle}>Contest details</h4>
				</div>
				<div>
					{(contestIsReady || activePhaseStatus === 'Completed' || activePhaseStatus === 'Winner selection')
						?	this.displayContestDetailsElements(contest, contestRound, card, contestType)
						:	this.displayContestLockedMessage()
					}
				</div>
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	displayContestLockedMessage() {

		return (
			<div className={classnames(classes.contentBlock, classes.contestProposalsContainer)}>
				<div className={classes.lockContainer}>
					<div className={classes.lockedIcon}>
						<AccessTimeRoundedIcon color='inherit' fontSize='inherit' />
					</div>
					<p style={{textAlign: 'center'}}>Contest is not open yet</p>
				</div>
			</div>
		);
	}

	displayContestDetailsElements(contest, contestRound, card, contestType) {
		const { currentContestStep } = this.state;

		const {
			clientConfirmed,
			requestedChanges,
		} = card !== undefined && card;

		const currentStep = clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES ? 3 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.PENDING ? 2 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.NO ? 2 : clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.DRAFT ? 1 : currentContestStep;

		const newEndDate = contestRound && new Date(parseInt(contestRound.endDate));
		const endDate = contestRound && dates.substractDays(newEndDate, 1);

		const newStartDate = contestRound && new Date(parseInt(contestRound.startDate));
		const startDate = (contestRound && newStartDate !== undefined) && dates.addDays(newStartDate, 1);

		const contestDuration = contestRound && CONTEST.getContestDaysDuration(contestRound);

		const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const isMobile = (width < 600);

		return (
			<div className={classnames([classes.contestStepsBlock, classes.contentBlock])}>
				{(currentStep !== 3) &&
					<ProgressIndicator
						currentIndex={(currentStep === currentContestStep) ? currentContestStep : currentStep}
						// className={classes.stepsContainer}
						vertical={!isMobile}
					>
						<h5 className={classes.stepTitle}>Follow this steps to open the contest:</h5>
						<ProgressStep label={'Set up'} />
						<ProgressStep label={'Review'} />
						<ProgressStep label={'Confirm'} />
					</ProgressIndicator>
				}
				{/* Contest in draft */}
				{(currentContestStep === 1 && currentStep === 1) &&
				(clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.DRAFT || card === undefined) &&
					this.renderContestDraft(contest)
				}
				{/* Contest card rejected */}
				{clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.NO && requestedChanges.length > 0 &&
					this.renderContestCardRejected(requestedChanges)
				}
				{/* Contest pending confirmation */}
				{(currentContestStep === 2 || currentStep === 2) &&
				clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.PENDING &&
					this.renderContestPendingConfirmation(contest, contestRound, contestDuration, startDate, endDate, card)
				}
				{/* Contest confirmed */}
				{(currentContestStep === 3 || (currentContestStep === 1 && currentStep === 3)) &&
				clientConfirmed === CONTEST.CONTEST_CARD_CONFIRMATION.YES &&
					this.renderContestConfirmed(contest, contestType, contestRound, contestDuration, startDate, endDate, card)
				}
			</div>
		);
	}

	renderContestDraft(contest) {
		const { contestSetUpCompleted } = this.state;

		const setupQuestions = contest !== undefined && contest.setupQuestions;
		const doesSetupQuestionsHasAnswer = setupQuestions && FormUtils.areAllQuestionsAnswered(setupQuestions);

		return (
			<div style={{maxWidth: '800px', width: '100%'}}>
				<div>
					<h5 className={classes.titleSpace} style={{marginTop: '0'}}>Contest set up</h5>
					{((!doesSetupQuestionsHasAnswer) && (setupQuestions !== undefined && setupQuestions.length > 0)) &&
						<p>In order to be more aligned with your project, you need to fill out these questions to help us make the most accurate contest for your project.</p>
					}
				</div>
				{(contestSetUpCompleted || doesSetupQuestionsHasAnswer)
					?	<Alert
						severity="success"
						style={{margin: '16px 0', width: 'fit-content'}}
					>
							Thanks for give us more info about your project. Soon you will be able to see the contest info to review it and confirm it.
					</Alert>
					:	<div className={classes.projectStatusBox} style={{ margin: '16px 0', flexDirection: 'column'}}>
						<ContestSetupQuestions
							contest={contest}
							onRefresh={() => this.refetchContest()}
							onQuestionsAnswered={() => this.refetchContest()}
						/>
					</div>
				}
				{(contestSetUpCompleted || doesSetupQuestionsHasAnswer) &&
					<div>
						{setupQuestions.length > 0 && <span className={classnames(classes.labelTitle, classes.titleSpace)}>Your answers</span>}
						<ViewSetupQuestions
							questions={setupQuestions}
							noAnswersMessage={null}
						/>
					</div>
				}
			</div>
		);
	}

	renderContestCardRejected(requestedChanges) {
		return (
			<div style={{maxWidth: '800px', width: '100%'}}>
				<h5 className={classes.titleSpace} style={{marginTop: '0'}}>Contest info</h5>
				<Alert
					severity="info"
					style={{margin: '16px 0', width: 'fit-content'}}
				>
					We are updating the contest based on your request.
				</Alert>
				<div className={classes.projectStatusBox} style={{flexDirection: 'column'}}>
					<h5 className={classes.titleSpace}>Your requested changes</h5>
					<div className={classes.clientFeedbackComment}>
						{requestedChanges[0] !== undefined && requestedChanges[0].comment}
					</div>
				</div>
			</div>
		);
	}

	renderContestPendingConfirmation(contest, contestRound, contestDuration, startDate, endDate, card) {
		const {
			sendingConfirmContestRound,
			successConfirmingContest,
			errorConfirmingContestRound
		} = this.state;

		const {
			specialAttention,
			mainImage,
			secondaryImages
		} = card !== undefined && card;

		const mainImageUrl = mainImage !== undefined && serverAPI.getPreviewUrlByFile(mainImage);

		return (
			<div style={{maxWidth: '800px', width: '100%'}}>
				<div>
					<h5 className={classes.titleSpace} style={{marginTop: '0'}}>Contest info</h5>
					<p>Find all the info related to the contest that will be opened to experts.</p>
				</div>
				<div className={classes.projectStatusBox}>
					<div className={classes.infoContainer} style={{width: '500px'}}>
						<div className={classes.marginBottom}>
							<div className={classes.flexCenter}>
								<h5 className={classes.titleSpace}>Contest dates and duration</h5>
							</div>
							<div className={classes.contextContent}>
								{(contestRound !== undefined) ? (
									<p className={classes.contestText}>
										This contest will start on <strong><em>{dates.formattedDate(startDate)}</em></strong> and ends on <strong><em>{dates.formattedDate(endDate)}</em></strong> with an expected duration of <strong><em>{contestDuration} {contestDuration > 1 ? 'days' : 'day'}</em></strong>.
									</p>
								) : (
									<p className={classes.contestText}>
										This contest is not opened yet.
									</p>
								)}
							</div>
						</div>
						{specialAttention &&
							<div className={classes.marginBottom}>
								<div className={classes.flexCenter}>
									<h5 className={classes.titleSpace}>What experts should pay special attention to</h5>
								</div>
								<div className={classes.listSpecialAttention}>
									<ReactMarkdown>
										{specialAttention}
									</ReactMarkdown>
								</div>
							</div>
						}
					</div>
					<div style={{ width: '500px'}}>
						{(mainImage || secondaryImages.length > 0) &&
							<div className={classes.marginBottom}>
								<div className={classes.flexCenter}>
									<h5 className={classes.titleSpace}>Useful files</h5>
								</div>
								<div className={classes.imagesContainer}>
									{mainImage &&
										<div className={classes.thumbnailContainer}>
											<ContestCardImage
												className={classes.contestThumbnail}
												src={mainImageUrl}
												onClick={() => this.onOpenPreviewFile(mainImage)}
											/>
										</div>
									}
									{secondaryImages.length > 0 &&
										secondaryImages.map((img, i) => (
											<div className={classes.thumbnailContainer} key={i}
												onClick={() => this.onOpenPreviewFile(img)}
											>
												<ContestCardImage
													className={classes.contestThumbnail}
													src={serverAPI.getPreviewUrlByFile(img)}
													type={'secondary'}
												/>
											</div>
										))}
								</div>
							</div>
						}
					</div>
				</div>
				<div className={classes.btnBox}>
					<Button
						className={classes.btn}
						style={{marginRight: '16px'}}
						onClick={() => this.onClickRequestChangeContestCard()}
						kind="secondary"
						disabled={sendingConfirmContestRound || successConfirmingContest}>
							Request change
					</Button>
					{sendingConfirmContestRound || successConfirmingContest ? (
						<InlineLoading
							description={successConfirmingContest ? 'Contest confirmed successfully' : 'Confirming contest...'}
							status={successConfirmingContest ? 'finished' : 'active'}
							style={{ margin: '0 1rem', width: '200px' }}
						/>
					) : errorConfirmingContestRound
						? 	<InlineLoading
							description={'Error confirming contest'}
							status={'error'}
							style={{ margin: '0 1rem', width: '200px' }}
						/>
						:	(
							<Mutation mutation={CONFIRM_CONTEST_CARD}>
								{(confirmContestRoundMutation) => (
									<Button
										className={classes.btn}
										onClick={() => this.onConfirmContestRound(contest, confirmContestRoundMutation)}
									>
										Confirm and open the contest
									</Button>
								)}
							</Mutation>
						)}
				</div>
			</div>
		);
	}

	renderContestConfirmed(contest, contestType, contestRound, contestDuration, startDate, endDate, card) {
		const { confirmedContest } = this.state;

		const {
			specialAttention,
			mainImage,
			secondaryImages
		} = card !== undefined && card;

		const mainImageUrl = mainImage !== undefined && serverAPI.getPreviewUrlByFile(mainImage);

		return (
			<div style={{width: '100%'}}>
				<div>
					{(confirmedContest !== null && confirmedContest.contestId === contest.id) &&
						<Alert
							severity="success"
							style={{margin: '16px 0', width: 'fit-content'}}
						>
							Congrats! 🎉 We have just created a new contest for your project.
						</Alert>
					}
				</div>
				<div className={classes.contestStatusBox}>
					<div style={{ width: '100%'}}>
						{contestRound.card.description &&
							<div className={classes.marginBottom}>
								<div className={classes.flexCenter}>
									<h5 className={classes.titleSpace}>General project description</h5>
								</div>
								<div className={classnames([classes.contextContent])}>
									<p>{contestRound.card.description}</p>
								</div>
							</div>
						}
						<div className={classes.marginBottom}>
							<div className={classes.flexCenter}>
								<h5 className={classes.titleSpace}>Important dates and duration</h5>
							</div>
							<div className={classnames([classes.contextContent, classes.colorBlock])}>
								<p style={{color: '#3e3e3e'}}>
									<strong><em>{dates.formattedDate(startDate)}</em></strong> - <strong><em>{dates.formattedDate(endDate)}</em></strong>
								</p>
								<p>Expected duration: <strong><em>{contestDuration} {contestDuration > 1 ? 'days' : 'day'}</em></strong></p>
							</div>
						</div>
						{specialAttention && (
							<div className={classes.marginBottom}>
								<div className={classes.flexCenter}>
									<h5 className={classes.titleSpace}>What experts should pay special attention to</h5>
								</div>
								<div className={classnames([classes.listSpecialAttention])}>
									<ReactMarkdown>
										{specialAttention}
									</ReactMarkdown>
								</div>
							</div>
						)}
					</div>
					<div style={{ width: '100%'}}>
						{(mainImage || secondaryImages.length > 0) &&
							<div className={classes.marginBottom}>
								<div className={classes.flexCenter}>
									<h5 className={classes.titleSpace}>Extra files</h5>
								</div>
								<div className={classes.imagesContainer}>
									{mainImage &&
										<div className={classes.thumbnailContainer}>
											<ContestCardImage
												className={classes.contestThumbnail}
												src={mainImageUrl}
												onClick={() => this.onOpenPreviewFile(mainImage)}
											/>
										</div>
									}
									{secondaryImages.length > 0 &&
										secondaryImages.map((img, i) => (
											<div className={classes.thumbnailContainer} key={i}
												onClick={() => this.onOpenPreviewFile(img)}
											>
												<ContestCardImage
													className={classes.contestThumbnail}
													src={serverAPI.getPreviewUrlByFile(img)}
													type={'secondary'}
												/>
											</div>
										))}
								</div>
							</div>
						}
						<div className={classes.marginBottom}>
							<div className={classes.flexCenter}>
								<h5 className={classes.titleSpace}>Contest card</h5>
							</div>
							<ul>
								<li className={classes.listItem} onClick={() => window.Froged('doc', '6262c984617dab4f9b1e6ba1')}>
									<Link to='#'>
										What is a Contest Card?
									</Link>
								</li>
							</ul>
							<div style={{margin: '26px 16px'}}>
								<Button
									className={classnames([classes.btn, classes.aBtn])}
									href={this.onGetContestCardPdfUrl(contest)}
									kind='secondary'
									size='sm'
									target="_blank" rel="noopener noreferrer"
								>
									Preview contest as a contest card
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async onClickRequestChangeContestCard() {
		this.setState({ requestChangeContestCard: true });
	}

	async onConfirmContestRound(contest, confirmContestRoundMutation) {
		const contestRound = contest && CONTEST.getLastRound(contest);

		this.setState({ sendingConfirmContestRound: true });

		let error;
		try {
			const result = await confirmContestRoundMutation({
				variables: {
					contestId: contest.id,
					roundId: contestRound.id,
					confirmation: {
						approved: true
					}
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error confirming contest');
			this.setState({ errorConfirmingContestRound: error.toString() });
			setTimeout(() => {
				this.setState({ errorConfirmingContestRound: null });
			}, 3000);
		} else {
			console.log('Success confirming contest');
			this.setState({ successConfirmingContest: true, sendingConfirmContestRound: false});
			setTimeout(() => {
				this.refetchContest();
				this.setState({ confirmedContest: {confirmed: true, contestId: contest.id} });
			}, 1500);
		}

		this.setState({ confirmedContest: null, successConfirmingContest: true });
	}

	onGetContestCardPdfUrl(contest) {
		return serverAPI.getContestCardPdfUrl(contest);
	}

	displayContestProposals(contest) {
		const project = contest.project;
		const state = project.state;
		const contestCardIsApprovedByClient = contest && PROJECT.isContestCardApprovedByClient(contest);

		return (
			<>
				<div className={classes.spaceBetween}>
					<h4 className={classes.secondaryTitle}>Proposals</h4>
					{contestCardIsApprovedByClient &&
						<span className={classes.linkModal} onClick={() => window.Froged('doc', '62629b0b4f3af575738fce35')}>
							<Link to='#' style={{textDecoration: 'none'}}>
								How to rate proposals
							</Link>
						</span>
					}
				</div>
				{this.renderContestProposals(project, state, contest)}
			</>
		);
	}

	renderContestFilters(proposals, contestHasEnded, hasAWinningProposal) {
		const { filter } = this.state;
		const totalApprovedProposals = proposals && PROPOSAL.getTotalApprovedAndPublishedProposals(proposals);
		const totalPendingReviewProposals = proposals && PROPOSAL.getTotalPendingReviewFiles(proposals);
		// const totalInVotationProposals = proposals && PROPOSAL.getTotalVotationProposals(proposals, contestHasEnded, hasAWinningProposal);
		const isWinner = hasAWinningProposal;

		return (
			<div className={classes.contentBlock} style={{display: 'flex', gap: '8px' }}>
				<Chip label={`All (${totalApprovedProposals})`} variant={filter !== 'all' ? 'outlined' : ''} onClick={() => this.setState({filter: 'all'})} />
				<Chip label={totalPendingReviewProposals > 0 ? `New (${totalPendingReviewProposals})` : 'New'} variant={filter !== 'new' ? 'outlined' : ''} onClick={() => this.setState({filter: 'new'})} />
				{/* <Chip label={totalPendingReviewProposals > 0 ? `Pending feedback (${totalPendingReviewProposals})` : 'Pending feedback'} variant={filter !== 'pending' ? 'outlined' : ''} onClick={() => this.setState({filter: 'feedback'})} /> */}
				{/* <Chip label={totalInVotationProposals > 0 ? `In votation (${totalInVotationProposals})` : 'In votation'} variant={filter !== 'votation' ? 'outlined' : ''} onClick={() => this.setState({filter: 'votation'})} /> // Pending selection */}
				<Chip label={isWinner ? 'Winner (1)' : 'Winner'} variant={filter !== 'winner' ? 'outlined' : ''} onClick={() => this.setState({filter: 'winner'})} />
			</div>
		);
	}

	renderContestProposals(project, state, contest) {
		const { filter } = this.state;
		if (!state) state = project.state;
		const selectedProposal = contest.selectedProposal !== null && contest.selectedProposal.id;

		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);
		const selectWinnerProposal = (contestHasEnded && !hasAWinningProposal && contest.proposals.length > 0) ? true : false;
		const giveProposalFeedbackBeforeSelectingWinner = selectWinnerProposal && PROPOSAL.hasPendingReviewFile(contest.proposals);
		const contestCardIsApprovedByClient = contest && PROJECT.isContestCardApprovedByClient(contest);
		const contestNotApprovedNeitherProposals = !contestCardIsApprovedByClient && contest.proposals.length === 0;

		const totalApprovedProposals = contest.proposals && PROPOSAL.getTotalApprovedAndPublishedProposals(contest.proposals);
		const hasPendingReviewFile = contest.proposals && PROPOSAL.hasPendingReviewFile(contest.proposals);
		const hasVotationProposals = contest.proposals && PROPOSAL.hasVotationProposals(contest.proposals, contestHasEnded, hasAWinningProposal);

		const hasApprovedProposals = contest.proposals.length > 0 && totalApprovedProposals > 0;

		return (
			<div className={classes.contentBlock}>
				{(contestCardIsApprovedByClient && contest.proposals.length > 0) && this.renderContestFilters(contest.proposals, contestHasEnded, hasAWinningProposal)}
				<div className={contestNotApprovedNeitherProposals && classes.contestProposalsContainer}>
					{(contestNotApprovedNeitherProposals) &&
						<div className={classes.lockContainer}>
							<div className={classes.lockedIcon}>
								<LockIcon color='inherit' fontSize='inherit' />
							</div>
							{ project && !project.paid &&
								<p style={{textAlign: 'center'}}>You will receive high quality proposals once you <Link to={`/project/${project.id}/unlock`}>unlock the project</Link></p>
							}

							{ project && project.paid &&
								<p style={{textAlign: 'center'}}>You will receive high quality proposals once the contest is confirmed and ready to start</p>
							}
						</div>
					}
					{(contestCardIsApprovedByClient && contest.proposals.length === 0) && (
						<>
							{project.state === state ? (
								<>
									<Alert
										severity="info"
										style={{margin: '16px 0', width: 'fit-content'}}
									>
										{KEYS.proposals_waiting_alert}
									</Alert>
									<div className={classnames([classes.proposalCard, classes.skeletonProposal])}>
										<div className={classes.imageCardSkeleton}></div>
										<div className={classes.proposalCardContent}>
											<div className={classes.proposalHeader}>
												<h4>{KEYS.proposal_title} #</h4>
												<Tag type={'blue'} style={{borderRadius: '4px'}}>{'In progress'}</Tag>
											</div>
											<RatingValue rating={0} />
											<div className={classes.feedbackToggle} style={{ marginTop: '36px' }}></div>
										</div>
									</div>
								</>
							) : (
								<div className={classes.overviewMessageBox}>
									<Information16 />
									<small>{KEYS.close_contest}: {KEYS.proposal_empty}</small>
								</div>
							)}
						</>
					)}
					{(selectWinnerProposal && (filter == 'votation' || filter === 'all') && hasApprovedProposals) && (
						<Alert
							severity="info"
							style={{margin: '16px 0', width: 'fit-content'}}
							// onClose={() => {}}
						>
							Please select the winning proposal by clicking inside of it.
						</Alert>
					)}
					{(giveProposalFeedbackBeforeSelectingWinner && (filter == 'feedback' || filter === 'all')) && (
						<Alert
							severity="info"
							style={{margin: '16px 0', width: 'fit-content'}}
							// onClose={() => {}}
						>
							You need to provide feedback on all proposals in order to select a winner.
						</Alert>
					)}
					<div className={classes.proposalsContainer}>
						{(filter === 'all') && (
							hasApprovedProposals
								?	contest.proposals.map((proposal, i) => (
									this.renderProposalFiles(proposal, i, selectedProposal, contestHasEnded, hasAWinningProposal, project.id, contest.id, contest)
								))
								: (contestCardIsApprovedByClient && contest.proposals.length > 0) &&
								<div className={classnames(classes.contentBlock, classes.contestProposalsContainer)} style={{height: '336px'}}>
									<div className={classes.lockContainer}>
										<div className={classes.lockedIcon}>
											<InboxOutlinedIcon color='inherit' fontSize='inherit' />
										</div>
										<p style={{textAlign: 'center'}}>There are no proposals.</p>
									</div>
								</div>
						)}
						{(filter === 'new') && (
							(totalApprovedProposals > 0 && hasPendingReviewFile)
								?	contest.proposals.map((proposal, i) => (
									this.renderPendingFeedbackProposals(proposal, i, project.id, contest.id)
								))
								:	<div className={classnames(classes.contentBlock, classes.contestProposalsContainer)} style={{height: '336px'}}>
									<div className={classes.lockContainer}>
										<div className={classes.lockedIcon}>
											<InboxOutlinedIcon color='inherit' fontSize='inherit' />
										</div>
										<p style={{textAlign: 'center'}}>There are no new proposals.</p>
									</div>
								</div>
						)}
						{(filter === 'votation') && (
							(totalApprovedProposals > 0 && hasVotationProposals)
								?	contest.proposals.map((proposal, i) => (
									this.renderInVotationProposals(proposal, i, project.id, contest.id)
								))
								:	<div className={classnames(classes.contentBlock, classes.contestProposalsContainer)} style={{height: '336px'}}>
									<div className={classes.lockContainer}>
										<div className={classes.lockedIcon}>
											<InboxOutlinedIcon color='inherit' fontSize='inherit' />
										</div>
										<p style={{textAlign: 'center'}}>There are not proposals in votation.</p>
									</div>
								</div>
						)}
						{(filter === 'winner') && (
							(totalApprovedProposals > 0 && hasAWinningProposal)
								?	contest.proposals.map((proposal, i) => (
									this.renderWinningProposal(proposal, i, selectedProposal, project.id, contest.id, contest)
								))
								:	<div className={classnames(classes.contentBlock, classes.contestProposalsContainer)} style={{height: '336px'}}>
									<div className={classes.lockContainer}>
										<div className={classes.lockedIcon}>
											<InboxOutlinedIcon color='inherit' fontSize='inherit' />
										</div>
										<p style={{textAlign: 'center'}}>There are no winning proposals.</p>
									</div>
								</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	renderPendingFeedbackProposals(proposal, i, projectId, contestId) {
		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;
			
			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;

			return (
				approvedAndPublished &&
				<Link to={`/project/${projectId}/contest/${contestId}/proposal/${proposal.id}`} style={{textDecoration: 'none'}} key={i}>
					<div className={classes.proposalCard}>
						<div className={classes.imageContainer}>
							<img
								key={f.id}
								className={classes.proposalImg}
								src={proposalUrl}
								alt='Proposal'
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = PROPOSAL_PLACEHOLDER;
								}}
							/>
						</div>
						<div className={classes.proposalCardContent}>
							<div className={classes.proposalHeader}>
								<h4>{KEYS.proposal_title} #{proposal.number}</h4>
								<Tag type={'blue'} style={{borderRadius: '4px'}}>
									Pending review
								</Tag>
							</div>
							<small>{KEYS.not_ranked}</small>
						</div>
					</div>
				</Link>
			);
		}
	}

	renderInVotationProposals(proposal, i, projectId, contestId) {
		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;
			
			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;
			const feedback = proposal.feedback;

			return (
				approvedAndPublished &&
				<Link to={`/project/${projectId}/contest/${contestId}/proposal/${proposal.id}`} style={{textDecoration: 'none'}} key={i}>
					<div className={classes.proposalCard}>
						<div className={classes.imageContainer}>
							<img
								key={f.id}
								className={classes.proposalImg}
								src={proposalUrl}
								alt='Proposal'
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = PROPOSAL_PLACEHOLDER;
								}}
							/>
						</div>
						<div className={classes.proposalCardContent}>
							<div className={classes.proposalHeader}>
								<h4>{KEYS.proposal_title} #{proposal.number}</h4>
							</div>
							{feedback && <RatingValue rating={feedback.rating} />}
						</div>
					</div>
				</Link>
			);
		}
	}

	renderWinningProposal(proposal, i, selectedProposalId, projectId, contestId, contest) {
		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;
			
			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;
			const feedback = proposal.feedback;

			const isWinningProposal = proposal.id === selectedProposalId;
			const hasSupportingFiles = isWinningProposal && contest.sharedFiles.length > 0;
			const approveSharedFiles = hasSupportingFiles && CONTEST.getTotalSupportingFiles(contest.sharedFiles);

			return (
				(approvedAndPublished && isWinningProposal) &&
				<Link to={`/project/${projectId}/contest/${contestId}/proposal/${proposal.id}`} style={{textDecoration: 'none'}} key={i}>
					<div className={classes.proposalCard}>
						<div className={classes.imageContainer}>
							<img
								key={f.id}
								className={classes.proposalImg}
								src={proposalUrl}
								alt='Proposal'
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = PROPOSAL_PLACEHOLDER;
								}}
							/>
						</div>
						<div className={classes.proposalCardContent}>
							<div className={classes.proposalHeader}>
								<h4>{KEYS.proposal_title} #{proposal.number}</h4>
								<Tag style={{borderRadius: '4px', backgroundColor: '#ffc637', color: '#fff'}}>
									Winner
								</Tag>
							</div>
							{feedback && <RatingValue rating={feedback.rating} />}
							{isWinningProposal &&
								<div style={{display: 'flex', justifyContent: 'end'}}>
									<small>
										{hasSupportingFiles
											?	`Include ${approveSharedFiles}`
											:	'Pending'
										} supporting files</small>
								</div>
							}
						</div>
					</div>
				</Link>
			);
		}
	}

	renderProposalFiles(proposal, i, selectedProposalId, contestHasEnded, hasAWinningProposal, projectId, contestId, contest) {
		for (const proposalFile of proposal.files) {
			const proposalUrl = this.getProposalUrl(proposal);

			const fileVersion = proposalFile.versions[0];
			const f = fileVersion.file;
			
			const approvedAndPublished = fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.YES && fileVersion.published === true;
			const feedback = proposal.feedback;
			const isWinningProposal = proposal.id === selectedProposalId;
			const hasSupportingFiles = isWinningProposal && contest.sharedFiles.length > 0;
			const approveSharedFiles = hasSupportingFiles && CONTEST.getTotalSupportingFiles(contest.sharedFiles);

			return (
				approvedAndPublished &&
				<Link to={`/project/${projectId}/contest/${contestId}/proposal/${proposal.id}`} style={{textDecoration: 'none'}} key={i}>
					<div className={classes.proposalCard}>
						<div className={classes.imageContainer}>
							<img
								key={f.id}
								className={classes.proposalImg}
								src={proposalUrl}
								alt='Proposal'
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = PROPOSAL_PLACEHOLDER;
								}}
							/>
						</div>
						<div className={classes.proposalCardContent}>
							<div className={classes.proposalHeader}>
								<h4>{KEYS.proposal_title} #{proposal.number}</h4>
								<Tag type={feedback ? 'green' : 'blue'} style={isWinningProposal ? {borderRadius: '4px', backgroundColor: '#ffc637', color: '#fff'} : {borderRadius: '4px'}}>
									{isWinningProposal
										? 'Winner'
										: feedback
											? 'Ranked'
											: 'Pending review'
									}
								</Tag>
							</div>
							{feedback ?
								<RatingValue rating={feedback.rating} />
								: <small>{KEYS.not_ranked}</small>
							}
							{isWinningProposal &&
								<div style={{display: 'flex', justifyContent: 'end'}}>
									<small>
										{hasSupportingFiles
											?	`Include ${approveSharedFiles}`
											:	'Pending'
										} supporting files</small>
								</div>
							}
						</div>
					</div>
				</Link>
			);
		}
	}

	getProposalUrl(proposal) {
		const { userData } = this.props;
		const { token } = userData;
		return serverAPI.getProposalThumbnailUrl(proposal.id, token.accessToken, 1000);
	}

	displayPrototypingSteps(contest) {
		const contestCardIsApprovedByClient = contest && PROJECT.isContestCardApprovedByClient(contest);

		return (
			<>
				<div className={classes.spaceBetween}>
					<h4 className={classes.secondaryTitle}>Steps</h4>
					{contestCardIsApprovedByClient &&
						<span className={classes.linkModal} onClick={() => window.Froged('doc', '628e2ae5f774e6ee763c7799')}>
							<Link to='#' style={{textDecoration: 'none'}}>
								What to expect
							</Link>
						</span>
					}
				</div>
				{this.renderPrototypingActiveSteps(contest)}
			</>
		);
	}

	renderPrototypingActiveSteps(contest) {

		return (
			<ErrorBoundaryQuery query={GET_PHASE_STEPS} variables={{ id: contest.id }}>
				{({ loading, error, data }) => {

					if (loading) {
						return (
							<div className={classes.contestContentBlock}>
								<div>
									<SkeletonText style={{ width: '150px' }} />
									<SkeletonText style={{ width: '100px' }} />
								</div>
								<div className={classes.updatesContentContainer}>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
									<div className={classes.contentContainer}>
										<SkeletonText className={classes.updateBox} />
									</div>
								</div>
							</div>
						);
					}

					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								title=""
								lowContrast
								hideCloseButton
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}

					const keys = Object.keys(data);
					if (keys.length === 0) return null;
					const keyName = keys[0];

					const result = data[keyName];
					const res = (result && result.steps) || [];
					const steps = res.sort((a, b) => a.order - b.order);

					const activeStep = PROJECT.getActiveStep(steps);
					const stepsLength = steps && steps.length + 1;

					return (
						<div style={{margin: '16px 0'}}> {/* // className={classes.contestContentBlock}  */}
							<>
								<h5 className={classes.titleSpace}>Track the last mile of your prototype</h5>
								{stepsLength > 1 &&
									<small>{stepsLength} steps included</small>
								}
							</>
							{stepsLength > 1
								?	<>
									<ContestStep
										contest={contest}
										title={'Quotation selection'}
										type={'contest'}
										step={'1'}
										status={'in_progress'} // pending, in_progress, done
										activeStep={activeStep}
										onClickPreviewProposal={(f) => this.onOpenPreviewFile(f)}
										openProposalFeedbackModal={(quotation) => this.setState({ openQuotationFeedbackModal: true, proposalFeedback: quotation })}
									/>
									{steps.map((step, i) => (
										<ContestStep
											key={i}
											contest={contest}
											title={step.title}
											description={step.description}
											type={'informative'}
											step={step.order+1}
											status={step.status} // pending, in_progress, done
											activeStep={activeStep+1}
											updates={step.updates} // update type information, alert
										/>
									))}
								</>
								:	<div className={classes.experienceBlock}>
									<div className={classes.alignContainer}>
										<Information16 style={{ marginRight: '6px' }} />
										<p>Soon you will see the proper steps defined for your project in this phase.</p>
									</div>
								</div>
							}
						</div>
					);
				}}
			</ErrorBoundaryQuery>
		);
	}

	displayContestSummary(contest) {
		const contestHasEnded = contest && CONTEST.isContestEnded(contest);
		const hasAWinningProposal = contest && CONTEST.hasWinner(contest);

		return (
			(contestHasEnded && hasAWinningProposal) &&
			<>
				<div className={classes.spaceBetween}>
					<h4 className={classes.secondaryTitle}>Summary</h4>
				</div>
				{this.displayPhaseSummaryBoxes(contest)}
				{this.displayUserPhaseFeedback(contest)}
				<div className={classes.horizontalDivider}></div>
			</>
		);
	}

	displayPhaseSummaryBoxes(contest) {
		const isPrototypingPhase = contest.type === PROJECT.CONTEST_TYPES.PROTOTYPING;
		if (isPrototypingPhase) return;
		const contestHasEnded = contest && CONTEST.isContestEnded(contest);

		const contestRound = (contest && contestHasEnded) && CONTEST.getLastRound(contest);

		const contestProposalsPresented = contest && PROPOSAL.getTotalApprovedAndPublishedProposals(contest.proposals);
		const winningProposalNumber = contest && PROPOSAL.getWinningProposalNumber(contest);
		const contestDuration = contestRound && CONTEST.getContestDaysDuration(contestRound);

		return (
			(contestHasEnded && !isPrototypingPhase) &&
			<div className={classnames(classes.summaryBlock, classes.contentBlock)}>
				<h5>General statistics</h5>
				<div className={classes.experienceBlock}>
					<div className={classes.statisticBox}>
						<small>Proposals</small>
						<div className={classes.elementsSummaryBox}>
							<Document20 />
							<h3>{contestProposalsPresented}</h3>
						</div>
					</div>
					<div className={classes.statisticBox}>
						<small>Days</small>
						<div className={classes.elementsSummaryBox}>
							<Time20 />
							<h3>{contestDuration}</h3>
						</div>
					</div>
					<div className={classes.statisticBox}>
						<small>Winning proposal</small>
						<div className={classes.elementsSummaryBox}>
							<Hashtag20 />
							<h3>{winningProposalNumber}</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}

	displayUserPhaseFeedback(contest) {
		const clientReview = contest.clientReview;
		const filteredClientReviewWithText = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.TEXT);
		const filteredClientReviewWithRating = clientReview && clientReview.filter(review => review.type === PROJECT.CLIENT_REVIEW_TYPES.RATING);

		return (
			(clientReview !== undefined && clientReview.length > 0)
				?	<div style={{maxWidth: '426px', width: '100%'}}>
					<div className={classes.contentBlock}>
						<h5>Your phase feedback</h5>
						{filteredClientReviewWithRating.map((review, i) => (
							<div className={classes.clientFeedbackRating} key={i}>
								<small>{review.title}</small>
								<RatingValue rating={review.value} type={'line'} />
							</div>
						))}
						{(filteredClientReviewWithText.length > 0 && filteredClientReviewWithText[0].value !== '-') &&
							<div>
								<small>Comments</small>
								<div className={classes.clientFeedbackComment}>
									{filteredClientReviewWithText[0].value}
								</div>
							</div>
						}
					</div>
				</div>
				:	<div>
					<h5>Phase feedback</h5>
					{this.displayPhaseFeedbackRatingVotation(contest)}
				</div>
		);
	}

	displayPhaseFeedbackRatingVotation(contest) {
		const {
			errorAddingPhaseFeedback, showPhaseFeedbackBoxModal,
			// eslint-disable-next-line no-unused-vars
			sendingPhaseFeedback, showNextContestBoxModal,
			qualityOfProposal, successOfTheWinner,
			processesUnderstanding, teamSupport,
			comments, success
		} = this.state;

		const items = [qualityOfProposal, successOfTheWinner, processesUnderstanding, teamSupport];
		const areItemsWithoutFeedback = FormUtils.areItemsWithoutFeedback(items);

		return (
			<div className={classes.experienceBlock}>
				{showPhaseFeedbackBoxModal
					? 	<Mutation mutation={ADD_CONTEST_REVIEW}>
						{(addPhaseFeedbackMutation) => (
							<>
								<div className={classnames([classes.projectStatus, classes.shadow, classes.phaseFeedbackRating])}>
									<div className={classes.projectTitle}>
										<div>
											<small>{strings.capitalizeFirstLetter(contest.type)}</small>
											<h4>Give us your feedback</h4>
										</div>
										<TaskView24 style={{ margin: '8px' }} />
									</div>
									{errorAddingPhaseFeedback &&
									<InlineNotification
										className={classes.notification}
										kind="error"
										lowContrast
										hideCloseButton={false}
										title=""
										subtitle={errorAddingPhaseFeedback}
									/>
									}
									<div className={classes.projectStatusBox} style={{flexDirection: 'column'}}>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Quality of proposals</p>
											<Rating
												name={qualityOfProposal}
												value={qualityOfProposal}
												icon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												emptyIcon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												onChange={(e) => this.onSetRatingToQualityOfProposal(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Success of the winner</p>
											<Rating
												name={successOfTheWinner}
												value={successOfTheWinner}
												icon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												emptyIcon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												onChange={(e) => this.onSetRatingToSuccessOfTheWinner(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Processes understanding</p>
											<Rating
												name={processesUnderstanding}
												value={processesUnderstanding}
												icon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												emptyIcon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												onChange={(e) => this.onSetRatingToProcessesUnderstanding(e)}
											/>
										</div>
										<div className={classnames([classes.projectDetails, classes.marginBottom])}>
											<p>Team support</p>
											<Rating
												name={teamSupport}
												value={teamSupport}
												icon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												emptyIcon={<HorizontalRuleRoundedIcon fontSize="inherit" />}
												onChange={(e) => this.onSetRatingToTeamSupport(e)}
											/>
										</div>
										<div className={classes.marginBottom}>
											<p style={{marginBottom: '8px'}}>Comments</p>
											<TextArea
												className={classes.textInput}
												labelText={'Please specify. Apply if necessary.'}
												type="text"
												cols={30}
												rows={1}
												value={comments}
												onChange={(e) => this.onSetExtraComment(e, comments)}
												id={comments}
											/>
										</div>
										<div>
											{sendingPhaseFeedback || success ? (
												<InlineLoading
													description={success ? 'Feedback sent successfully' : 'Sending feedback ...'}
													status={success ? 'finished' : 'active'}
													style={{ marginLeft: '1rem', width: '200px' }}
												/>
											) : errorAddingPhaseFeedback
												? 	<InlineLoading
													description={'Error validating feedback'}
													status={'error'}
													style={{ marginLeft: '1rem', width: '200px' }}
												/>
												:	(
													<Button
														className={classes.btnAlternative}
														onClick={() => this.onAddPhaseFeedbackMutation(contest, addPhaseFeedbackMutation)}
														disabled={areItemsWithoutFeedback}
														size='sm'
														kind='primary'>
														Submit feedback
													</Button>
												)}
										</div>
									</div>
								</div>
								<div className={classnames([classes.feedbackBtn, classes.shadow])}
									onClick={() => this.setState({ showPhaseFeedbackBoxModal: !showPhaseFeedbackBoxModal})}
								>
									<Close32 style={{ margin: '4px' }} />
								</div>
							</>
						)}
					</Mutation>
					:	<>
						<Button
							className={classes.btnAlternative}
							onClick={() => this.setState({ showPhaseFeedbackBoxModal: !showPhaseFeedbackBoxModal, showNextContestBoxModal: false})}
						>
							Give phase feedback
						</Button>
						<div className={classes.feedbackBtn}
							onClick={() => this.setState({ showPhaseFeedbackBoxModal: !showPhaseFeedbackBoxModal, showNextContestBoxModal: false})}>
							<TaskView32 style={{ margin: '4px' }} />
						</div>
					</>
				}
			</div>
		);
	}

	onSetRatingToQualityOfProposal(e) {
		this.setState({ qualityOfProposal: e.target.value });
	}

	onSetRatingToSuccessOfTheWinner(e) {
		this.setState({ successOfTheWinner: e.target.value });
	}

	onSetRatingToProcessesUnderstanding(e) {
		this.setState({ processesUnderstanding: e.target.value });
	}

	onSetRatingToTeamSupport(e) {
		this.setState({ teamSupport: e.target.value });
	}

	onSetExtraComment(e) {
		this.setState({ comments: e.target.value });
	}

	async onAddPhaseFeedbackMutation(contest, addPhaseFeedbackMutation) {
		const {
			qualityOfProposal,
			successOfTheWinner,
			processesUnderstanding,
			teamSupport,
			comments
		} = this.state;

		this.setState({ sendingPhaseFeedback: true });

		let error;
		try {
			const result = await addPhaseFeedbackMutation({
				variables: {
					contestId: contest.id,
					feedback: [
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Quality of proposals',
							value: qualityOfProposal,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Success of the winner',
							value: successOfTheWinner,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Processes understanding',
							value: processesUnderstanding,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.RATING,
							title: 'Team support',
							value: teamSupport,
						},
						{
							type: PROJECT.CLIENT_REVIEW_TYPES.TEXT,
							title: 'Comments',
							value: comments !== '' ? comments : 'N/S',
						}
					]
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		if (error) {
			console.log('Error adding phase feedback');
			this.setState({ errorAddingPhaseFeedback: error.toString() });
			setTimeout(() => {
				this.setState({ errorAddingPhaseFeedback: null });
			}, 3000);
		} else {
			console.log('Success adding phase feedback');
			this.setState({ success: true, sendingPhaseFeedback: false });
			setTimeout(() => {
				this.refetchContest();
			}, 3000);
		}
		this.setState({ sendingPhaseFeedback: false });
	}

	displayContestExpectations(contest) {
		const contestType = contest.type;

		return (
			<>
				<div className={classes.spaceBetween}>
					<h4 className={classes.secondaryTitle}>Things to know</h4>
					<span className={classes.linkModal} onClick={() => window.Froged('doc', '626430ac3dfba9d107b9c6b7')}>
						<Link to='#' style={{textDecoration: 'none'}}>
							What should I check for in each phase?
						</Link>
					</span>
				</div>
				<div className={classnames(classes.betweenBlock, classes.contentBlock)}>
					<div className={classes.flexColumn} style={{width: '400px'}}>
						<h5 className={classes.titleSpace}>What does it consist of</h5>
						<div style={{display: 'flex', justifyContent: 'start', margin: '16px 0'}}>
							{this.renderContestTypeDescription(contestType)}
						</div>
					</div>
					<div className={classes.flexColumn} style={{width: '300px'}}>
						<h5 className={classes.titleSpace}>Phase considerations</h5>
						<div style={{display: 'flex', justifyContent: 'start', margin: '16px 0'}}>
							{this.renderPhaseConsiderations(contestType)}
						</div>
					</div>
					<div className={classes.flexColumn} style={{width: '300px'}}>
						<h5 className={classes.titleSpace}>Contest policy</h5>
						<div style={{display: 'flex', justifyContent: 'start', margin: '16px 0'}}>
							{this.renderContestPolicy(contestType)}
						</div>
					</div>
				</div>
			</>
		);
	}

	renderContestTypeDescription(contestType) {
		const phaseDescription = CONTEST.getContestTypeDescription(contestType);

		return (
			<p>{phaseDescription}</p>
		);
	}

	renderPhaseConsiderations(contestType) {
		const considerations = CONTEST.getPhaseConsiderations(contestType);

		return (
			<ul>
				{considerations.map((item, i) => (
					<li key={i} style={{display: 'flex', alignItems: 'center', margin: '4px 0px'}}>
						<p style={{marginLeft: '8px', fontStyle: 'italic'}}>{ i + 1 }. {item}</p>
					</li>
				))}
			</ul>
		);
	}

	renderContestPolicy(contestType) {
		const slots = CONTEST.getContestMaxValidProposals(contestType);
		const policies = [
			'A contest round is made to be opened for the experts community.',
			'Any validated expert can participate.',
			`This phase has a maximum of ${slots} participants.`,
			'Only one round would be open available till the maximum slots are filled.'
		];

		return (
			<ul>
				{policies.map((item, i) => (
					<li key={i} style={{display: 'flex', alignItems: 'center', margin: '4px 0px'}}>
						<p>
							<DotMark16 style={{color: '#D9D9D9', minWidth: '15px', minHeight: '15px', verticalAlign: '-3px', marginRight: '2px' }} />
							{item}
						</p>
					</li>
				))}
				<span
					className={classnames(classes.linkModal, classes.linkPosition)}
					onClick={() => window.Froged('message', `Hi, I am willing to open a new contest round for the ${contestType} phase.`)}
				>
					Not conformed? Ask for a new round!
				</span>
			</ul>
		);
	}
}

ContestView.propTypes = {
	userData: PropTypes.object,
	match: PropTypes.object,
	refetchContest: PropTypes.func
};

ContestView.defaultProps = {
	refetchContest() {},
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(withRouter(ContestView));
