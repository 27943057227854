import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import {
	Modal,
	Button,
	InlineLoading,
	InlineNotification,
	TextArea,
} from 'carbon-components-react';

import { UPDATE_MY_PROJECT } from '../../providers/mutations';

import classes from './modals.module.scss';

class UpdateProjectNameModal extends Component {
	constructor (props) {
		super(props);

		this.state = {
			sendingProjectNameUpdate: false,
			success: false,
			errorUpdatingProjectName: null,
			newName: null,
		};
	}

	async updateProjectName(e, projectId, updateMyProjectMutation) {
		const { onRequestSubmit } = this.props;
		const { newName } = this.state;

		e.preventDefault();
		let error;

		try {
			const result = await updateMyProjectMutation({
				variables: {
					projectId: projectId,
					fields: {
						name: newName
					},
				}
			});
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}
		if (error) {
			this.setState({ errorUpdatingProjectName: error.toString() });
			setTimeout(() => {
				this.setState({ errorUpdatingProjectName: null });
			}, 3000);
		} else {
			this.setState({ success: true, sendingProjectNameUpdate: false });
			setTimeout(() => {
				this.setState({ success: false });
				onRequestSubmit();
			}, 2000);
		}
		this.setState({ sendingProjectNameUpdate: false });
	}

	onChangeProjectName(e) {
		this.setState({ newName: e.target.value });
	}

	countWords(str) {
		return str.trim().split(/\s+/).length;
	}

	isNameValid() {
		const { newName } = this.state;

		let isValid = false;
		const newNameWords = newName !== null ? this.countWords(newName) : -1;

		if (newNameWords < 5 && newNameWords > 0) {
			isValid = true;
		} else {
			isValid = false;
		}

		return isValid;
	}

	render() {
		const { open, project, onSecondarySubmit, onRequestClose, ...rest } = this.props;
		const { sendingProjectNameUpdate, success, errorUpdatingProjectName, newName } = this.state;

		const projectName = project !== null ? project.name : 'Project xx';
		const isSubmitButtonDisabled = !this.isNameValid() || sendingProjectNameUpdate;

		return (
			<Mutation mutation={UPDATE_MY_PROJECT}>
				{(updateMyProjectMutation, { error }) => {
					if (error) {
						return (
							<InlineNotification
								className={classes.notification}
								kind="error"
								lowContrast
								hideCloseButton
								title=""
								subtitle={(error.graphQLErrors && error.graphQLErrors.length)
									? error.graphQLErrors[0].message
									: (error.networkError)
										? error.networkError.message
										: error.message}
							/>
						);
					}
					return (
						<Modal
							passiveModal={true}
							open={open}
							modalHeading="Change name"
							modalLabel={`Project ${projectName}`}
							onRequestClose={onRequestClose}
							{...rest}
						>
							<div className={classes.modalContent}>
								<InlineNotification
									kind="info"
									lowContrast
									iconDescription="Info"
									subtitle={'Take into account that this name would be visible for your project and we recommend it be sufficiently attractive for experts to join your project’s contests.'}
									title=""
								/>
								<TextArea
									id="update-project-name-input"
									className={classes.textInput}
									type="text"
									rows={1}
									value={newName !== null ? newName : projectName}
									placeholder={`Current name: ${projectName}`}
									invalid={newName !== null ? isSubmitButtonDisabled : false}
									invalidText={'Name could be maximum 4 words'}
									onChange={(e) => this.onChangeProjectName(e)}
									required
								/>
								<div className={classes.btnBox}>
									<Button
										type="submit"
										className={classes.btn}
										onClick={onSecondarySubmit}
										kind="secondary"
										disabled={sendingProjectNameUpdate || success}>
											Cancel
									</Button>
									{sendingProjectNameUpdate || success ? (
										<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={success ? 'Project name updated successfully' : 'Updating project name...'}
											status={success ? 'finished' : 'active'}
										/>
									) : errorUpdatingProjectName
										? 	<InlineLoading
											style={{ margin: '0 1rem', width: '200px' }}
											description={'Error updating project name'}
											status={'error'}
										/>
										:	(
											<Button
												className={classes.btn}
												disabled={isSubmitButtonDisabled}
												onClick={(e) => this.updateProjectName(e, project.id, updateMyProjectMutation)}>
													Update project name
											</Button>
										)}
								</div>
							</div>
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

UpdateProjectNameModal.propTypes = {
	open: PropTypes.bool,
	project: PropTypes.object,
	onRequestSubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,
	onRequestClose: PropTypes.func,
};

UpdateProjectNameModal.defaultProps = {
	open: false,
	project: {},
	onRequestSubmit() {},
	onSecondarySubmit() {},
	onRequestClose() {},
};

export default UpdateProjectNameModal;
