export default {
	basePath: '',
	lastbasic: {
		api: {
			host: process.env.REACT_APP_LASTBASIC_API_HOST || 'http://localhost:8022',
		},
		web: {
			host: process.env.REACT_APP_LASTBASIC_WEB_HOST || 'http://localhost:3001'
		},
		platform: {
			host: window.location.protocol + '//' + window.location.host,
		},
		pushNotifications: {
			publicVapidKey: process.env.REACT_APP_LASTBASIC_PUBLIC_VAPID_KEY || 'BFtENIO1HwHuICaCnvh1PVaArayzHldpI1xbdSwLV9Do9kd7sJF_LzFhIU0GkA4m4c5mpzsAPAYmixXttIZ24Sc',
		},
		community: {
			host: 'https://community.lastbasic.com',
		},
		demoProjectId: process.env.REACT_APP_LASTBASIC_DEMO_PROJECT_ID,
	},
	eversign: {
		forms: {
			ndaUrl: 'https://lastbasic.eversign.com/embedded/9444f5c5e42e44b0aa3c6765a220e9a6',
		},
	},
	typeform: {
		forms: {
			paymentInfo: process.env.REACT_APP_TYPEFORM_FORMS_PAYMENT_INFO || 'https://lastbasic.typeform.com/to/jrPRDvqJ',
			projectBriefing: process.env.REACT_APP_TYPEFORM_FORMS_PROJECT_BRIEFING || 'https://lastbasic.typeform.com/to/mEjIzNd4',
			expertBriefing: process.env.REACT_APP_TYPEFORM_FORMS_EXPERT_BRIEFING || 'https://lastbasic.typeform.com/to/go9M01',
			expertInformation: process.env.REACT_APP_TYPEFORM_FORMS_EXPERT_INFORMATION || 'https://lastbasic.typeform.com/to/AqyCJXAp'
		}
	},
	rollbar: {
		enabled: !!process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'local',
		accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '0595ad7e29684736968fe7da1d874a02',
		environment: process.env.REACT_APP_ENVIRONMENT || 'local',
	},
	froged: {
		api: {
			host: 'https://api.froged.com',
			wid: '4wz1nw',
			apiKey: 'qx0MYtfukZVWdvrzyqgZyP1w2VpUOGeeZ1VZbWkYH1YI1eaesr'
		}
	},
	hubspot: {
		scheduleMeeting: 'https://meetings.hubspot.com/luisocleiry?embed=true'
	}
};
