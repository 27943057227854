import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Checkbox,
	InlineLoading,
	InlineNotification,
	RadioButtonGroup,
	RadioButton,
	TextArea
} from 'carbon-components-react';
import { Information16 } from '@carbon/icons-react';

import { FormUtils } from '../../../utils';

import classes from './setup-questions.module.scss';

class SetupQuestions extends Component {
	constructor (props) {
		super(props);

		const { questions } = props;
		this.questions = questions;

		// Skip answered questions
		const defaultQuestionOrder = this.getNextPendingQuestionOrder();

		this.state = {
			errorMessage: null,
			questionOrder: defaultQuestionOrder,
			answeredQuestions: [],
			isTextInputInvalid: false,
			sendingAnswers: false,
			success: false,
		};
	}

	getNextPendingQuestionOrder() {
		let questionOrder = (this.state) ? this.state.questionOrder + 1 : 0;
		while (
			questionOrder < this.questions.length
			&& this.questions[questionOrder].answer.length > 0
		) {
			questionOrder++;
		}
		return questionOrder;
	}

	componentDidMount() {
		const { onQuestionsAnswered, onRefresh } = this.props;
		onRefresh();
		if (this.hasFinished()) onQuestionsAnswered();
	}

	hasFinished() {
		const { questionOrder } = this.state;

		// Has no questions
		if (this.questions.length === 0) return true;

		// Are all questions answered
		if (FormUtils.areAllQuestionsAnswered(this.questions)) return true;

		// Is last step
		if (questionOrder === this.questions.length) return true;

		return false;
	}

	displayQuestion(question) {
		const { maxSelectable, minSelectable, options, type } = question;

		const isRequired = minSelectable && (minSelectable >= 1);

		return (
			<div style={{margin: '16px 8px' }}>
				<h5>
					{question.question}
					{isRequired ? '*' : ''}
				</h5>
				<div>
					{isRequired && (
						<small className={classes.legend}>* Required</small>
					)}
				</div>
				{type === FormUtils.QUESTION_TYPES.OPTIONS ? (
					(maxSelectable === 1) ? (
						this.displayRadioOptions(question, options)
					) : (
						this.displayCheckboxOptions(question, options)
					)
				) : ((type === FormUtils.QUESTION_TYPES.TEXT) && (
					this.displayTextOption(question)
				))}
			</div>
		);
	}

	displayRadioOptions(question, options) {
		const { answeredQuestions, sendingAnswers, isTextInputInvalid } = this.state;

		const foundQuestion = answeredQuestions.find(q => q.id === question.id);
		const answer = foundQuestion && foundQuestion.answer;
		const selectedOption = answer ? answer[0] : null;

		let showTextArea = false;

		return (
			<Fragment>
				<RadioButtonGroup
					className={classes.radioButtonGroup}
					legendText="Select only one option"
					orientation={'vertical'}
					name={`question-${question.id}-answers`}
					valueSelected={selectedOption}
				>
					{options.map((option, i) => {
						const identifier = `question-${question.id}-option-${i}`;
						const isSelected = (answer) ? answer.includes(option) : false;
						const isOtherOption = FormUtils.canWriteCustomAnswer(option);
						showTextArea = showTextArea || (isSelected && isOtherOption);

						return (
							<RadioButton
								key={identifier}
								labelText={option}
								value={option}
								disabled={sendingAnswers}
								onClick={(e) => this.onRadioOptionChange(e, question, option)}
								id={identifier}
							/>
						);
					})}
				</RadioButtonGroup>
				{showTextArea && (
					<div style={{ margin: '16px', width: '100%' }}>
						<TextArea
							labelText=""
							helperText="Minimum 8 characters"
							minLength="8"
							cols={30}
							rows={4}
							required
							disabled={sendingAnswers}
							placeholder='Tell us here...'
							invalid={isTextInputInvalid}
							invalidText={'Text must be minimum 8 characters'}
							onChange={(e) => this.onChangeText(e, question)}
						/>
					</div>
				)}
			</Fragment>
		);
	}

	onRadioOptionChange(e, question, option) {
		// Add new question with option
		const newQuestion = {
			id: question.id,
			answer: [option]
		};
		return this.setState({ answeredQuestions: [newQuestion] });
	}

	displayCheckboxOptions(question, options) {		
		const { maxSelectable, minSelectable } = question;
		const { answeredQuestions, sendingAnswers, isTextInputInvalid, otherText } = this.state;

		const foundQuestion = answeredQuestions.find(q => q.id === question.id);
		const answer = foundQuestion && foundQuestion.answer;
		const showClearOptions = answer && answer.length > 0;

		return (
			<div style={{ margin: '8px 0' }}>
				<span className={classes.labelSelection}>
					{(minSelectable > 1) && maxSelectable ? (
						`You need to select minimum ${minSelectable} and maximum ${maxSelectable} options`
					) : (
						(minSelectable > 1) ? (
							`You need to select minimum ${minSelectable} options`
						) : (
							maxSelectable && (
								`You can select maximum ${maxSelectable} options`
							)
						)
					)}
				</span>
				{showClearOptions && (
					<small
						className={classes.smallAnchor}
						onClick={() => this.setState({ answeredQuestions: [] })}
					>
						Clear options
					</small>
				)}
				{options.map((option, i) => {
					const identifier = `question-${question.id}-option-${i}`;
					const isSelected = (answer) ? answer.includes(option) : false;
					const isOtherOption = FormUtils.canWriteCustomAnswer(option);
					const showTextArea = isSelected && isOtherOption;

					return (
						<Fragment key={identifier}>
							<Checkbox
								labelText={option}
								checked={isSelected}
								disabled={sendingAnswers}
								onChange={(...args) => this.onCheckboxOptionChange(args[2], question, option)}
								id={identifier}
							/>
							{showTextArea && (
								<div style={{ margin: '16px' }}>
									<TextArea
										labelText=""
										helperText="Minimum 8 characters"
										minLength="8"
										cols={30}
										rows={4}
										required
										value={otherText}
										disabled={sendingAnswers}
										placeholder='Tell us here...'
										invalid={isTextInputInvalid}
										invalidText={'Text must be minimum 8 characters'}
										onChange={(e) => this.onChangeText(e, question)}
									/>
								</div>
							)}
						</Fragment>
					);
				})}
			</div>
		);
	}

	onCheckboxOptionChange(e, question, option) {
		const { answeredQuestions } = this.state;

		const foundQuestion = answeredQuestions.find(q => q.id === question.id);
		if (!foundQuestion) {
			// Add new question with option
			const newQuestion = {
				id: question.id,
				answer: [option]
			};
			return this.setState({ answeredQuestions: [newQuestion] });
		}

		const { answer } = foundQuestion;
		const doesQuestionHaveThisOption = answer.includes(option);
		if (doesQuestionHaveThisOption) {
			// Remove option
			const sameQuestionRepeated = {
				id: question.id,
				answer: answer.filter(a => a !== option)
			};
			return this.setState({ answeredQuestions: [sameQuestionRepeated] });
		}

		// Limit the selection
		const { maxSelectable } = question;
		const hasReachLimitOption = answer.length === maxSelectable;
		if (hasReachLimitOption) {
			e.preventDefault();
			return false;
		}

		// Add option
		const sameQuestion = {
			id: question.id,
			answer: answer.concat(option)
		};
		return this.setState({ answeredQuestions: [sameQuestion] });
	}

	displayTextOption(question) {
		const { sendingAnswers, isTextInputInvalid, answeredQuestions } = this.state;
		const foundQuestion = answeredQuestions.find(q => q.id === question.id);
		const answerText = foundQuestion !== undefined ? foundQuestion.answer : '';

		return (
			<div style={{ margin: '16px' }}>
				<TextArea
					labelText=""
					helperText="Minimum 8 characters"
					minLength="8"
					cols={30}
					rows={4}
					required
					value={answerText}
					disabled={sendingAnswers}
					placeholder='Tell us here...'
					invalid={isTextInputInvalid}
					invalidText={'Text must be minimum 8 characters'}
					onChange={(e) => this.onChangeText(e, question)}
				/>
			</div>
		);
	}

	onChangeText(e, question) {
		e.preventDefault();

		const textComment = e.target.value;
		this.setState({
			isTextInputInvalid: (textComment.length < 8)
		});

		const { type } = question;
		const isOnlyTextType = (type === FormUtils.QUESTION_TYPES.TEXT);
		if (isOnlyTextType) {
			const answeredQuestion = {
				id: question.id,
				answer: [textComment]
			};
			return this.setState({ answeredQuestions: [answeredQuestion] });
		}

		const { answeredQuestions } = this.state;
		const foundQuestion = answeredQuestions.find(q => q.id === question.id);
		// Question must already be in the answered question list
		if (!foundQuestion) return;
		foundQuestion.otherText = textComment;
		return this.setState({ answeredQuestions: [foundQuestion] });
	}

	onConfirm = async (e) => {
		e.preventDefault();

		const { onQuestionsAnswered, submitAnswers } = this.props;
		const { answeredQuestions } = this.state;

		this.setState({ sendingAnswers: true });

		let error;
		try {
			const result = await submitAnswers(
				answeredQuestions.map(q => {
					return {
						id: q.id,
						answer: q.answer.map(a => {
							if (FormUtils.canWriteCustomAnswer(a)) return `Other: ${q.otherText}`;
							return a;
						}),
					};
				})
			);
			if (!result) error = new Error('Something went wrong');
		} catch (e) {
			error = e;
		}

		let isLastQuestion;
		if (error) {
			this.setState({
				errorMessage: error.message,
				sendingAnswers: false,
			});
		} else {
			// Skip answered questions
			const newQuestionOrder = this.getNextPendingQuestionOrder();
			isLastQuestion = newQuestionOrder === this.questions.length;

			this.setState({
				questionOrder: newQuestionOrder,
				answeredQuestions: [],
				success: true,
				sendingAnswers: false,
			});
		}

		setTimeout(() => {
			this.setState({
				errorMessage: null,
				success: false,
			});

			if (isLastQuestion) {
				onQuestionsAnswered();
			}
		}, 1000);
	};

	isValid() {
		const { answeredQuestions, questionOrder } = this.state;

		let isValid = false;

		const selectedQuestion = this.questions[questionOrder];
		const foundQuestion = answeredQuestions.find(q => q.id === selectedQuestion.id);		
		const { type } = selectedQuestion;

		// Check current question
		if (type === FormUtils.QUESTION_TYPES.OPTIONS) {
			const answerCount = (foundQuestion && foundQuestion.answer) ? foundQuestion.answer.length : 0;

			const isOtherOptionSelected = (foundQuestion && foundQuestion.answer)
				? foundQuestion.answer.some(FormUtils.canWriteCustomAnswer)
				: false;
			const isTextInputInvalid = (isOtherOptionSelected)
				? (!foundQuestion.otherText || foundQuestion.otherText.length < 8)
				: false;

			isValid = (
				selectedQuestion.minSelectable <= answerCount
				&& answerCount <= selectedQuestion.maxSelectable
				&& (!isOtherOptionSelected || !isTextInputInvalid)
			);
		} else if (type === FormUtils.QUESTION_TYPES.TEXT) {
			const textComment = (foundQuestion && foundQuestion.answer)
				? foundQuestion.answer[0]
				: '';
			const isTextInputInvalid = (textComment.length < 8);

			isValid = !isTextInputInvalid;
		}

		return isValid;
	}

	render() {
		const { allQuestionsAnsweredMessage, completedMessage, noQuestionsMessage } = this.props;
		const { errorMessage, questionOrder, sendingAnswers, success } = this.state;

		const hasQuestions = this.questions.length > 0;
		if (!hasQuestions) {
			return (
				<div className={classes.messageIcon}>
					<Information16 />
					<small>{noQuestionsMessage}</small>
				</div>
			);
		}

		const areAllQuestionsAnswered = FormUtils.areAllQuestionsAnswered(this.questions);
		if (areAllQuestionsAnswered) {
			return (
				<InlineNotification
					kind="info"
					className={classes.notification}
					lowContrast
					hideCloseButton
					title=""
					iconDescription="Info"
					subtitle={<span>{allQuestionsAnsweredMessage}</span>}
				/>
			);
		}

		const isLastStep = questionOrder === this.questions.length;
		if (isLastStep) {
			return (
				<InlineNotification
					kind="info"
					className={classes.notification}
					lowContrast
					hideCloseButton
					title=""
					iconDescription="Info"
					subtitle={<span><p>{completedMessage}</p></span>}
				/>
			);
		}

		const selectedQuestion = this.questions[questionOrder];
		const isSubmitButtonDisabled = !this.isValid() || sendingAnswers;

		return (
			<Fragment>
				<small>Question {questionOrder + 1} out of {this.questions.length}</small>
				{errorMessage &&
					<InlineNotification
						className={classes.notification}
						kind="error"
						lowContrast
						hideCloseButton={false}
						title=""
						subtitle={errorMessage}
					/>
				}
				{this.displayQuestion(selectedQuestion)}
				<div className={classes.btnBox}>
					{errorMessage ? (
						<InlineLoading
							description={'Error setting up contest answer'}
							status={'error'}
							style={{ margin: '0 1rem', width: '200px' }}
						/>
					) : (sendingAnswers || success) ? (
						<InlineLoading
							description={success ? 'Answer submitted successfully' : 'Submitting answer...'}
							status={success ? 'finished' : 'active'}
							style={{ margin: '0 1rem', width: '200px' }}
						/>
					) : (
						<Button
							className={classes.btn}
							disabled={isSubmitButtonDisabled}
							onClick={(e) => this.onConfirm(e)}
						>
							Submit answer
						</Button>
					)}
				</div>
			</Fragment>
		);
	}
}

SetupQuestions.propTypes = {
	questions: PropTypes.array.isRequired,
	submitAnswers: PropTypes.func.isRequired,
	onQuestionsAnswered: PropTypes.func,
	onRefresh: PropTypes.func,
	allQuestionsAnsweredMessage: PropTypes.string,
	completedMessage: PropTypes.string,
	noQuestionsMessage: PropTypes.string,
};

SetupQuestions.defaultProps = {
	questions: [],
	submitAnswers() {},
	onQuestionsAnswered() {},
	onRefresh() {},
	allQuestionsAnsweredMessage: 'You already answered all questions.',
	completedMessage: 'Awesome, you have finished all the questions.',
	noQuestionsMessage: 'There are no questions.',
};

export default SetupQuestions;