import React, { Component, Fragment } from 'react';
import { auth, serverAPI } from '../../utils';

class FrogedCustomerSupportScript extends Component {
	componentDidMount() {
		const tokens = auth.getValidStoredTokens();
		if (!tokens) return;
		
		const { user } = tokens;

		const isExpert = user.expert !== null;
		const isClient = user.maker !== null;

		window.Froged('set', {
			userId: user.id,
			email: user.email,
			firstname: user.firstName,
			lastname: user.lastName,
			name: `${user.firstName} ${user.lastName}`,
			avatar: serverAPI.getAvatarUrl(user.id),
			is_client: isClient,
			is_expert: isExpert,
			...(isExpert && {
				country: user.expert.countryResidence,
				expert_expertise: user.expert.expertExpertise,
				expert_type: user.expert.expertType,
				newsletter_subscription: user.expert.subscribeNewsletter
			}),
			...(isClient && {
				country: user.maker.countryResidence,
				newsletter_subscription: user.maker.subscribeNewsletter
			})
		}
		);
	}

	render() {
		return (
			<Fragment>
			</Fragment>
		);
	}
}

export default FrogedCustomerSupportScript;