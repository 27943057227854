import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ProjectList from './components/project-list';
import AlternativeOption from '../../../components/project/alternative-option';

import {
	GET_AVAILABLE_AND_CLOSED_PROJECTS,
	GET_FAVORITE_PROJECTS,
} from '../../../providers/queries';

import { Credentials20, Account20 } from '@carbon/icons-react';

import classes from './projects-page.module.scss';

const CONTENT_TYPE = {
	ALL: 'all',
	FAVORITES: 'favorites',
};

class ProjectsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedContentType: CONTENT_TYPE.ALL,
			switchToFavorites: false,
		};
	}

	componentDidMount() {
		document.title = 'Explore projects - LastBasic';
	}

	render() {
		const { switchToFavorites } = this.state;

		return (
			<div className={classes.projectList}>
				<h2 className={classes.title}>Explore projects</h2>
				{this.renderSecondaryActions()}
				{/* {this.renderContestOption()} */}
				<div className={classes.filtersContainer} style={{ display: 'none' }}>
					<div>
						<h4>
							{switchToFavorites ? 'Your favorite projects' : 'All projects'}
						</h4>
						<small
							className={classes.switcher}
							onClick={switchToFavorites
								?	() => this.selectFilter(CONTENT_TYPE.ALL)
								:	() => this.selectFilter(CONTENT_TYPE.FAVORITES)
							}
						>
							{switchToFavorites ? 'See all projects' : 'See only your favorites'}
						</small>
					</div>
				</div>
				{this.renderProjectLists()}
			</div>
		);
	}

	renderSecondaryActions() {
		return (
			<div className={classes.secondaryBtnContainer} style={{ flexDirection: 'row', display: 'none' }}>
				{/* <Link to="/contests" style={{ textDecoration: 'none' }}>
				<AlternativeOption
					type="primary"
					title="My involvement with LB"
					icon={<Collaborate20 />}
					soon={true}
				/>
				</Link> */}
				<Link to="/roles-and-responsibilities" style={{ textDecoration: 'none' }}>
					<AlternativeOption
						type="primary"
						title="My role and responsibilities"
						icon={<Credentials20 />}
					/>
				</Link>
				<AlternativeOption
					type="secondary"
					title="Academy"
					icon={<Account20 />}
					soon={true}
				/>
			</div>
		);
	}

	renderContestOption() {
		return (
			<div className={classes.secondaryBtnContainer}>
				<Link to="/contests" style={{ textDecoration: 'none' }}>
					<div className={classes.contestOption}>
						<small>Not convinced about the projects? Discover them through their open contests!</small>
					</div>
				</Link>
			</div>
		);
	}

	selectFilter(contentType) {
		this.setState({
			selectedContentType: contentType,
			switchToFavorites: contentType === CONTENT_TYPE.FAVORITES ? true : false
		});
	}

	renderProjectLists = () => {
		const { selectedContentType } = this.state;

		switch (selectedContentType) {
			case CONTENT_TYPE.FAVORITES:
				return <ProjectList query={GET_FAVORITE_PROJECTS} />;
			case CONTENT_TYPE.ALL:
				return <ProjectList query={GET_AVAILABLE_AND_CLOSED_PROJECTS} />;
			default:
				return <ProjectList query={GET_AVAILABLE_AND_CLOSED_PROJECTS} />;
		}
	};
}

ProjectsPage.propTypes = {
	userData: PropTypes.object.isRequired
};

ProjectsPage.defaultProps = {
	userData: {}
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

export default connect(
	mapStateToProps,
)(ProjectsPage);
